import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Link} from 'react-router-dom'
import { GetInstructorbyId } from '../../../Redux/Action/instructorAction'
import { Col, Container, Row } from 'react-bootstrap'
import * as CountryStateCity from 'country-state-city'
import {
  ArrowLeftOutlined
} from '@ant-design/icons';
const InstructorDetails = () => {
  const dispatch = useDispatch()
  var newURL = window.location

  var splitURL = newURL.href.split('/')

  const instid = splitURL[4]

  const instructordetails = useSelector((state) => {
    return state.login.getaInstructorIdlist
  })

  useEffect(() => {
    // debugger;
    dispatch(GetInstructorbyId(instid))
  }, [dispatch, instid])

  const [countryName, setCountryName] = useState('')
  const [stateName, setStateName] = useState('')

  useEffect(() => {
    if (instructordetails && instructordetails.message && instructordetails.message.Country) {
      const country = CountryStateCity.Country.getCountryByCode(instructordetails.message.Country)
      const state = CountryStateCity.State.getStateByCodeAndCountry(instructordetails.message.state, instructordetails.message.Country)

      const fetchedCountryName = country ? country.name : instructordetails.message.Country || ''
      const fetchedStateName = state ? state.name : instructordetails.message.state || ''

      setCountryName(fetchedCountryName)
      setStateName(fetchedStateName)
    } else {
      // Handle the case when data is not available by setting "NA"
      setCountryName('NA')
      setStateName('NA')
    }
  }, [instructordetails])
  return (
    <div className='instructor-lisss'>
      <Container fluid>
        <Row>
          <Col lg={10}>
            <div>
              <div>
                <div className='card mb-5 ' id='kt_profile_details_view'>
                  <div className='card-header cursor-pointer'>
                    <div className='card-title m-0'>
                    <Link  to='/alluserlist' style={{fontSize:'20px', color:'#FF763F', fontWeight: 'bold'}}><ArrowLeftOutlined /></Link><h3 className='fw-bold m-0'>Profile Details</h3>
                    </div>
                  </div>{' '}
                  <div className='card-body p-9'>
                    <>
                      <div className='d-flex align-items-center fs-4 fw-bold mb-5'>
                        {' '}
                        Contact Details
                      </div>
                      <div className='row mb-7'>
                        <label className='col-lg-4 fw-semibold text-muted'>Full Name</label>
                        <div className='col-lg-8 fv-row'>
                          <span className='fw-semibold text-gray-800 fs-6'>
                            {instructordetails.message && instructordetails.message.FirstName
                              ? instructordetails.message.FirstName
                              : 'NA'}
                            {instructordetails.message && ` ${instructordetails.message.Surname}`}
                          </span>
                        </div>
                      </div>
                      {/* <div className='row mb-7'>
                        <label className='col-lg-4 fw-semibold text-muted'>
                        Telephone
                          <i
                            className='fas fa-exclamation-circle ms-1 fs-7'
                            data-bs-toggle='tooltip'
                            title='Phone number must be active'
                          ></i>
                        </label>
                        <div className='col-lg-8 d-flex align-items-center'>
                          <span >
                            {instructordetails.message && instructordetails.message.PhoneNumber}{' '}
                          </span>
                        </div>
                      </div> */}
                      <div className='row mb-7'>
                        <label className='col-lg-4 fw-semibold text-muted'>Email</label>
                        <div className='col-lg-8 fv-row'>
                          <span>
                            {instructordetails.message && instructordetails.message.Email
                              ? instructordetails.message.Email
                              : 'NA'}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-7'>
                        <label className='col-lg-4 fw-semibold text-muted'>Day Rate(£)</label>
                        <div className='col-lg-8 fv-row'>
                          <span>
                            {instructordetails.message && instructordetails.message.dayRate
                              ? instructordetails.message.dayRate
                              : 'NA'}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-7'>
                        <label className='col-lg-4 fw-semibold text-muted'>Country</label>
                        <div className='col-lg-8'>
                          <span> {countryName === 'undefined' ? '' : countryName}</span>
                        </div>
                      </div>
                      {stateName && stateName != 'undefined' ? (
                        <div className='row mb-7'>
                          <label className='col-lg-4 fw-semibold text-muted fs-6'>
                            State/County
                          </label>
                          <div className='col-lg-8'>
                            <span>{stateName}</span>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {instructordetails.message &&
                      instructordetails.message.City &&
                      instructordetails.message.City != 'undefined' &&
                      instructordetails.message.City != null ? (
                        <div className='row mb-7'>
                          <label className='col-lg-4 fw-semibold text-muted fs-6'>City</label>
                          <div className='col-lg-8'>
                            <span >
                              {' '}
                              {instructordetails.message && instructordetails.message.City}
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      <div className='row mb-7'>
                        <label className='col-lg-4 fw-semibold text-muted'>MTM ID</label>
                        <div className='col-lg-8'>
                          <span className='fs-6 text-gray-800'>
                            {' '}
                            {instructordetails.message && instructordetails.message.mtm_id
                              ? instructordetails.message.mtm_id
                              : 'NA'}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-7'>
                        <label className='col-lg-4 fw-semibold text-muted'>CompTIA ID</label>
                        <div className='col-lg-8'>
                          <span className=' fs-6 text-gray-800'>
                            {' '}
                            {instructordetails.message && instructordetails.message.comptia_id
                              ? instructordetails.message.comptia_id
                              : 'NA'}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-7'>
                        <label className='col-lg-4 fw-semibold text-muted'>Other IDs</label>
                        <div className='col-lg-8'>
                          <span className=' fs-6 text-gray-800'>
                            {' '}
                            {instructordetails.message && instructordetails.message.other_id
                              ? instructordetails.message.other_id
                              : 'NA'}
                          </span>
                        </div>
                      </div>
                      <div className='d-flex align-items-center fs-4 fw-bold mb-5'>
                        {' '}
                        Teaching Details
                      </div>
                      <div className='row mb-7'>
                        <label className='col-lg-4 fw-semibold text-muted'>
                          Qualifications / Accreditation
                        </label>
                        <div className='col-lg-8'>
                          <span className=' fs-6 text-gray-800'>
                            {' '}
                            {instructordetails.message && instructordetails.message.qualification
                              ? instructordetails.message.qualification
                              : 'NA'}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-7'>
                        <label className='col-lg-4 fw-semibold text-muted'>
                          Years of teaching experience
                        </label>
                        <div className='col-lg-8'>
                          <span className=' fs-6 text-gray-800'>
                            {' '}
                            {instructordetails.message && instructordetails.message.experiance
                              ? instructordetails.message.experiance
                              : 'NA'}
                          </span>
                        </div>
                      </div>
                      <div className='d-flex align-items-center fs-4 fw-bold mb-5'>
                        Bank Details
                      </div>
                      <div className='row mb-7'>
                        <label className='col-lg-4 fw-semibold text-muted'>Company Name</label>
                        <div className='col-lg-8 fv-row'>
                          <span className='fw-semibold text-gray-800 fs-6'>
                            {instructordetails.message && instructordetails.message.Company_Name
                              ? instructordetails.message.Company_Name
                              : 'NA'}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-7'>
                        <label className='col-lg-4 fw-semibold text-muted'>
                          Bank account / IBAN numbers
                        </label>
                        <div className='col-lg-8'>
                          <span className=' fs-6 text-gray-800'>
                            {' '}
                            {instructordetails.message && instructordetails.message.IBANNumber
                              ? instructordetails.message.IBANNumber
                              : 'NA'}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-7'>
                        <label className='col-lg-4 fw-semibold text-muted'>Account Name</label>
                        <div className='col-lg-8'>
                          <span className=' fs-6 text-gray-800'>
                            {' '}
                            {instructordetails.message && instructordetails.message.AccountName
                              ? instructordetails.message.AccountName
                              : 'NA'}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-7'>
                        <label className='col-lg-4 fw-semibold text-muted'>
                          Account Number / IBAN
                        </label>
                        <div className='col-lg-8'>
                          <span className=' fs-6 text-gray-800'>
                            {' '}
                            {instructordetails.message && instructordetails.message.AccountNumber
                              ? instructordetails.message.AccountNumber
                              : 'NA'}
                          </span>
                        </div>
                      </div>{' '}
                    </>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default InstructorDetails
