import axios from 'axios'
import swal from 'sweetalert'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {
  getbundlebyidslice,
  getbundleslice,
  getBundleCourseslice,
  boughtbundleslice,
  getBundlesByUserIdslice,
  getcheckbundleslice,
  getBundledetailsslice,
  getuserdatesslice,
  getuserSessionslice,
} from '../Slice/authSlice'
import {Navigate} from 'react-router-dom'

export const CreateBundleAction = (formData, navigate) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',

        Authorization: `Bearer ${token}`,
      },
    }

    const CreateBundle = process.env.REACT_APP_BACKEND_API + '/creatBundle'

    const res = await axios.post(CreateBundle, formData, config)

    if (res.status === 200) {
      swal({
        title: 'Success',
        text: 'Bundle Created',
        icon: 'success',
        buttons: {
          cancel: {
            text: 'OK',
            value: null,
            visible: true,
            className: 'swal-button--confirm cyberbtnallpage', 
          },
        },
      })
      navigate('/bundlelist')
    }
    dispatch(res)
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e.response.data.message, 'error')
    }
  }
}

export const UpdateBundleAction = (formData, bundle_slug, navigate) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const updateCourseid = `${process.env.REACT_APP_BACKEND_API}/updateBundle/${bundle_slug}`
    const res = await axios.put(updateCourseid, formData, config)
    // console.log(res);

    if (res.status === 200) {
      swal({
        title: 'Success',
        text: 'Bundle Updated',
        icon: 'success',
        buttons: {
          cancel: {
            text: 'OK',
            value: null,
            visible: true,
            className: 'swal-button--confirm cyberbtnallpage', 
          },
        },
      })
      navigate('/bundlelist')
    }
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e.response.data.message, 'error')
    }
  }
}

export const DeleteBundleAction = (bundle_slug) => async (dispatch) => {
  try {
    // //debugger;
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const confirmResult = await swal({
      title: 'Delete Bundle',
      text: 'Are you sure you want to delete this Bundle?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          value: null,
          visible: true,
          className: 'swal-button--cancel cybercancelbtn', 
        },
        confirm: {
          text: 'Delete',
          value: true,
          visible: true,
          className: 'swal-button--confirm swal-button--danger cyberbtnallpage', 
        },
      },
      dangerMode: true,
    })

    if (confirmResult) {
      const deleteuser = `${process.env.REACT_APP_BACKEND_API}/deleteBundle/${bundle_slug}`
      const res = await axios.delete(deleteuser, config, '')

      if (res.status == 200) {
        swal({
          title: 'Success',
          text: 'Bundle Deleted',
          icon: 'success',
          buttons: {
            cancel: {
              text: 'OK',
              value: null,
              visible: true,
              className: 'swal-button--confirm cyberbtnallpage', 
            },
          },
        }).then(() => {
          window.location.reload()
        })
      }
    }
  } catch (e) {
    // console.log(e)
  }
}

export const GetBundleaction = (bundle_slug) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getbundle = `${process.env.REACT_APP_BACKEND_API}/getsingleBundle/${bundle_slug}`
    const res = await axios.get(getbundle, config)
    await dispatch(getbundlebyidslice(res))
  } catch (e) {
    // if (e?.response?.data.message) {
    //   swal("error", e?.response?.data?.message, "error");
    // }
    // console.log(e)
  }
}

export const GetallBundlaction = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getbundle = process.env.REACT_APP_BACKEND_API + '/getAllBundles'
    const res = await axios.get(getbundle, config, '')

    await dispatch(getbundleslice(res))
  } catch (e) {
    // if (e?.response?.data.message) {
    //   swal("error", e?.response?.data?.message, "error");
    // }
    console.log(e)
  }
}

export const GetBundleCourseaction = (bundleCode) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getbundle = `${process.env.REACT_APP_BACKEND_API}/getCoursesByBundleId/${bundleCode}`
    const res = await axios.get(getbundle, config)
    await dispatch(getBundleCourseslice(res))
  } catch (e) {
    // if (e?.response?.data.message) {
    //   swal('Error', e?.response?.data?.message, 'error')
    // }
    // console.log(e)
  }
}

export const BoughtBundleAction = (studentid) => async (dispatch) => {
  //debugger
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getuserid = `${process.env.REACT_APP_BACKEND_API}/boughtBundle/${studentid}`

    const res = await axios.get(getuserid, config)

    await dispatch(boughtbundleslice(res))
  } catch (e) {
    // console.log(e)
  }
}

export const BoughtBundleByUserAction = (studentid) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getuserid = `${process.env.REACT_APP_BACKEND_API}/buyBundlebyUserId/${studentid}`

    const res = await axios.get(getuserid, config)

    await dispatch(getBundlesByUserIdslice(res))
  } catch (e) {
    // console.log(e)
  }
}

export const CheckuserBundleAction = (bundleCode) => async (dispatch) => {
  let useridd = localStorage.getItem('userid')
  let category = localStorage.getItem('category')

  //debugger
  try {
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const checkcoursesbundle = `${process.env.REACT_APP_BACKEND_API}/checkusercourse/${useridd}/${bundleCode}/${category}`
    const res = await axios.get(checkcoursesbundle, config)
    localStorage.setItem('message', res?.data?.message)

    await dispatch(getcheckbundleslice(res))

    if (res.status === 200) {
      // console.log(res)
    }
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e.response.data.message, 'error')
    }
  }
}

export const UpdatebundleAction = (formData, courseId) => async (dispatch) => {
  try {
    // //debugger
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const updateBundleid = `${process.env.REACT_APP_BACKEND_API}/updateCourse/${courseId}`
    const res = await axios.put(updateBundleid, formData, config)
    // console.log(res)

    if (res.status === 200) {
      swal({
        title: 'Success',
        text: 'Bundle Updated',
        icon: 'success',
        buttons: {
          cancel: {
            text: 'OK',
            value: null,
            visible: true,
            className: 'swal-button--confirm cyberbtnallpage', 
          },
        },
      }).then(() => {
        window.location.reload()
      })
    }
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e.response.data.message, 'error')
    }
  }
}

export const GetallbundleactionAction = (bundle_slug) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getbundledetails = `${process.env.REACT_APP_BACKEND_API}/getbundle/${bundle_slug}`

    const res = await axios.get(getbundledetails, config)

    await dispatch(getBundledetailsslice(res))
  } catch (e) {
    // console.log(e)
  }
}

export const StudentAction = (formData, Bundle_code, navigate) => async (dispatch) => {
  try {
    //debugger
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',

        Authorization: `Bearer ${token}`,
      },
    }

    const studentdates = process.env.REACT_APP_BACKEND_API + '/studentDates'

    const res = await axios.post(studentdates, formData, config)
    res.data.type = 'Bundle_dates'

    if (res.status === 200) {
      localStorage.setItem('type', 'Bundle_dates')
      if (localStorage.getItem('authToken')) {
        navigate(`/insidebuycourse?/${Bundle_code}`)
      } else {
        navigate(`/signup?/${Bundle_code}`)
      }
    }

    dispatch(res)
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e?.response?.data?.message, 'error')
    }
  }
}

export const GetUserDatesction = (course_code, id) => async (dispatch) => {
  try {
    //debugger
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getuserdate = `${process.env.REACT_APP_BACKEND_API}/studentCoursesDates/${course_code}/${id}`

    const res = await axios.get(getuserdate, config, '')

    await dispatch(getuserdatesslice(res))
  } catch (e) {
    console.log(e)
  }
}

export const BookSessionAction = (formData, instid, setLoading) => async (dispatch) => {
  try {
    //debugger
    setLoading(true)
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',

        Authorization: `Bearer ${token}`,
      },
    }

    const getbookSession = `${process.env.REACT_APP_BACKEND_API}/bookSession/${instid}`

    const res = await axios.post(getbookSession, formData, config)

    if (res.status === 200) {
      swal({
        title: 'Success',
        text: 'Session Details Updated',
        icon: 'success',
        buttons: {
          cancel: {
            text: 'OK',
            value: null,
            visible: true,
            className: 'swal-button--confirm cyberbtnallpage',
          },
        },
      }).then(()=>{
        window.location.reload()
      })
    }
  } catch (e) {
    swal({
      title: 'Error',
      text: e.response ? e.response.data.error : 'An unexpected error occurred',
      icon: 'error',
      buttons: {
        cancel: {
          text: 'OK',
          value: null,
          visible: true,
          className: 'swal-button--confirm cyberbtnallpage',
        },
      },
    });
  } finally {
    setLoading(false)
  }
}

export const GetUserSessionction = (id) => async (dispatch) => {
  try {
    //debugger
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getuserdate = `${process.env.REACT_APP_BACKEND_API}/session/${id}`

    const res = await axios.get(getuserdate, config, '')

    await dispatch(getuserSessionslice(res))
  } catch (e) {
    console.log(e)
  }
}

// BookSessionAction
