import {useEffect, useState, useRef} from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import Navbar from '../Navbar/Navbar'

import {useForm} from 'react-hook-form'
import ReactPlayer from 'react-player'

import {PlayCircleOutlined} from '@ant-design/icons'
import Tablelinetab from './Tablelinetab'
import '../Courses/Courses.css'
import './Tablelinetab.css'
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo'
import {
  AllcourseDetailAction,
  GetallLesson_idAction,
  UpdateVideoTimer,
  VideoProgressAction,
} from '../../Redux/Action/createChapterAction'

import {useDispatch, useSelector} from 'react-redux'

import Footer from '../Footer/Footer'

function Learning() {
  const playerRef = useRef(null)

  const [expanded, setExpanded] = useState(null)

  const [currentTime, setCurrentTime] = useState(0)

  const [duration, setDuration] = useState(0)
  const [progressPercentage, setProgressPercentage] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const [selectedLesson, setSelectedLesson] = useState(null)
  const [lessonStatus, setLessonStatus] = useState({})
  const [selectedLessonId, setSelectedLessonId] = useState(null)
  const [selectedChapterId, setSelectedChapterId] = useState(null)

  const dispatch = useDispatch()

  var newURL = window.location

  var splitURL = newURL.href.split('/')
  const course_id = splitURL[4]
  //update Video

  const {
    formData,
    setValue,

    formState: {errors},
  } = useForm()

  const updatevide_progress = useSelector((state) => {
    return state.login.getvideoprogess_list
  })

  const userId = localStorage.getItem('userid')

  useEffect(() => {
    // debugger;
    if (userId) {
      setValue('userId', userId)
      setValue('chapterId', selectedLesson?.chapterId || '')
      setValue('lessonId', selectedLessonId || '')
      setValue('progress', currentTime || '')
      setValue('duration', duration || '')
      setValue('progressPercentage', progressPercentage || '')
      setIsPlaying(false)
    }
  }, [
    userId,
    selectedLesson,
    selectedLessonId,
    currentTime,
    duration,
    progressPercentage,
    setValue,
    setIsPlaying,
  ])

  useEffect(() => {
    if (userId && selectedLessonId) {
      dispatch(VideoProgressAction(userId, selectedLessonId))
    }
  }, [dispatch, userId, selectedLessonId])
  useEffect(() => {
    // debugger
    if (selectedLesson) {
      setDuration(selectedLesson.duration)
    }
  }, [selectedLesson])

  useEffect(() => {
    if (isPlaying) {
      const timerId = setInterval(() => {
        setCurrentTime((prevTime) => prevTime + 1)
      }, 1000) // Update currentTime every 1 second

      return () => {
        clearInterval(timerId)
      }
    }
  }, [isPlaying])

  const handleProgress = (progress) => {
    setCurrentTime(progress.playedSeconds)

    if (progress && progress.loadedSeconds > 0) {
      setDuration(progress.loadedSeconds)
      setProgressPercentage((progress.playedSeconds / progress.loadedSeconds) * 100)
    }
  }

  useEffect(() => {
    if (duration > 0) {
      setProgressPercentage((currentTime / duration) * 100)
    }
  }, [currentTime, duration])

  useEffect(() => {
    // Load initial video data and set duration
    if (selectedLesson) {
      setDuration(selectedLesson.duration)
    }
  }, [selectedLesson])

  const handlePlay = () => {
    // debugger
    // setIsPlaying(true)
    const progressValues = updatevide_progress.progress
    const progressValuesend = updatevide_progress.endTime

    if (playerRef.current && progressValues !== null) {
      playerRef.current.seekTo(progressValues)
    } else {
      const userId = localStorage.getItem('userid')
    }

    setIsPlaying(true)
  }
  const handleReplay = (lesson) => {
    playerRef.current.seekTo(0)

    setLessonStatus((prevStatus) => ({
      ...prevStatus,
      [lesson.id]: false,
    }))
  }
  const handleVideoEnd = () => {
    // Set the lesson as complete
    setLessonStatus((prevStatus) => ({
      ...prevStatus,
      [selectedLessonId]: true,
    }))

    // Call a function to play the next lesson or handle completion
    playNextLesson()
  }

  const playNextLesson = () => {
    const currentLessonIndex = getcoursealldetails.chapters
      .flatMap((chapter) => chapter.lessons)
      .findIndex((lesson) => lesson.id === selectedLessonId)

    const allLessons = getcoursealldetails.chapters.flatMap((chapter) => chapter.lessons)

    if (currentLessonIndex >= 0 && currentLessonIndex < allLessons.length - 1) {
      // Play the next lesson within the chapters
      const nextLesson = allLessons[currentLessonIndex + 1]
      setSelectedLessonId(nextLesson.id)
      setSelectedLesson(nextLesson)
      setIsPlaying(true)
    } else {
      const currentChapterIndex = getcoursealldetails.chapters.findIndex(
        (chapter) => chapter.id === selectedChapterId
      )
      let nextChapterIndex = currentChapterIndex + 1

      // Skip chapters without lessons
      while (
        nextChapterIndex < getcoursealldetails.chapters.length &&
        getcoursealldetails.chapters[nextChapterIndex].lessons.length === 0
      ) {
        nextChapterIndex++
      }

      if (nextChapterIndex < getcoursealldetails.chapters.length) {
        // Move to the next chapter's first lesson
        const nextChapter = getcoursealldetails.chapters[nextChapterIndex]
        const firstLessonOfNextChapter = nextChapter.lessons[0]
        setSelectedChapterId(nextChapter.id)
        setSelectedLessonId(firstLessonOfNextChapter.id)
        setSelectedLesson(firstLessonOfNextChapter)
        setIsPlaying(true)
      } else {
        // All chapters and lessons are completed
        // You can handle this case or show a message
      }
    }
  }

  const handlePause = () => {
    const currentTime = playerRef.current.getCurrentTime()
    localStorage.setItem('videoCurrentTime', JSON.stringify(currentTime))

    const endDuration = duration - currentTime
    localStorage.setItem('videoEndDuration', JSON.stringify(endDuration))
    const userId = localStorage.getItem('userid')
    const formData = new FormData()
    formData.append('userId', userId)
    formData.append('chapterId', selectedLesson.chapterId)
    formData.append('lessonId', selectedLessonId)
    formData.append('progress', currentTime)
    formData.append('duration', duration)
    formData.append('pro  gressPercentage', progressPercentage)
    setIsPlaying(false)

    dispatch(UpdateVideoTimer(formData))

    // updatevide_progress(userId, selectedLessonId, currentTime);
  }
  const handleLessonClick = (lesson) => {
    setSelectedLessonId(lesson.id)
    setSelectedLesson(lesson)
  }

  const getcoursealldetails = useSelector((state) => {
    // debugger
    return state.login.getallcoursedeatils_list
  })

  useEffect(() => {
    dispatch(AllcourseDetailAction(course_id))
  }, [dispatch, course_id])

  if (!getcoursealldetails || !getcoursealldetails.chapters) {
    return <div>Loading...</div>
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null)

    const chapterIndex = parseInt(panel.replace('panel', '')) - 1

    if (getcoursealldetails.data && getcoursealldetails.data.length > 0) {
      const selectedChapterSlug = getcoursealldetails.data[chapterIndex].chapter_slug

      dispatch(GetallLesson_idAction(selectedChapterSlug))
    }
  }

  return (
    <div>
      <div
        className='navbar navbar-expand-lg d-flex justify-content-between'
        style={{backgroundColor: 'black', paddingTop: '3rem'}}
      >
        <Navbar />
      </div>

      <div className='row'>
        <div
          className='col-md-3'
          style={{
            border: '1px solid #fff',
            background: '#fff',
            maxHeight: '800px',
            overflowY: 'auto',
            border: '1px solid rgb(255, 255, 255)',
            background: 'rgb(255, 255, 255)',

            background: '#fff',

            zIndex: '1',
          }}
        >
          <div class='accordion' id='accordionExample' style={{padding: '0px'}}>
            <div class='accordion-item' style={{padding: '0px'}}>
              <h2
                style={{
                  padding: '20px 10px',
                  background: '#fff',
                  borderBottom: '1px solid #ddd',
                  margin: '0',
                }}
              >
                Course Content
              </h2>
            </div>

            <div className='hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh' style={{padding: '0px'}}>
              {getcoursealldetails.chapters.map((chapter, index) => (
                <Accordion
                  key={index}
                  expanded={expanded === `panel${index + 1}`}
                  onChange={handleChange(`panel${index + 1}`)}
                  style={{paddingBottom: '10px', paddingTop: '10px', background: '#f7f9fa'}}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index + 1}-content`}
                    id={`panel${index + 1}-header`}
                    className={`accordion-header ${expanded === `panel${index + 1}` ? 'open' : ''}`}
                    style={{
                      color: '#2d2f31!important;',
                      fontFamily: 'var(--font-stack-heading)',
                      fontWeight: '700',
                      lineHeight: '1.2',
                      letterSpacing: '-.02rem',
                      fontSize: '1.1rem',
                      paddingTop: '8px',
                      padding: '0px',
                    }}
                  >
                    Chapter {index + 1}:{chapter.chaptername}
                    {chapter.id}
                  </AccordionSummary>

                  <AccordionDetails style={{padding: '0px'}}>
                    <div>
                      {chapter.lessons.map((lesson, lessonIndex) => (
                        <div
                          key={lessonIndex}
                          onClick={() => handleLessonClick(lesson)}
                          className={selectedLessonId === lesson.id ? 'highlighted-lesson' : ''}
                          style={{cursor: 'pointer'}}
                        >
                          <Typography
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              paddingTop: '1rem',
                              paddingLeft: '2rem',
                            }}
                          >
                            <div>
                              <div className='form-check'>
                                <input
                                  type='checkbox'
                                  className='form-check-input'
                                  checked={lessonStatus[lesson.id] || false}
                                  disabled={lessonStatus[lesson.id]}
                                  defaultChecked={false}
                                />
                              </div>
                            </div>

                            {/* {item.lessonname} */}
                            {lesson.lessonname}
                            {lesson.id}
                          </Typography>

                          <div>
                            <span style={{marginLeft: '5rem'}}>
                              <OndemandVideoIcon style={{fontSize: '15px'}} />
                              2min
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </div>
        </div>

        <div className='col-md-9'>
          <div className='video_learning'>
            {selectedLesson ? (
              <div>
                <ReactPlayer
                  ref={playerRef}
                  url={selectedLesson.vdo_link}
                  id={selectedLesson.id}
                  controls={true}
                  width='100%'
                  height='500px'
                  playing={true}
                  onPlay={handlePlay}
                  onPause={handlePause}
                  onProgress={handleProgress}
                  onEnded={handleVideoEnd}
                  className='p-0'
                  style={{borderRadius: '8px'}}
                  playIcon={<PlayCircleOutlined className='h2 p-0' />}
                />
                <div style={{display: 'flex', color: 'red', gap: '5rem'}}>
                  <div>Current Time: {currentTime} seconds</div>
                  <div>Duration: {duration} seconds</div>
                  <div>Progress: {progressPercentage.toFixed(2)}%</div>
                  <div>{selectedLesson.id}</div>
                </div>
              </div>
            ) : (
              <div>No lesson selected video</div>
            )}

            <Tablelinetab />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default Learning
