import React, {useState, useEffect} from 'react'
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined'
import DownloadDoneOutlinedIcon from '@mui/icons-material/DownloadDoneOutlined'
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined'

import Footer from '../Footer/Footer'

import {InsidebuycourseAction} from '../../Redux/Action/courseAction'

import {GetUserDatesction} from '../../Redux/Action/bundleAction'

import {useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import FormData from 'form-data'
import {useForm} from 'react-hook-form'
import StripePay from '../Signup/StripePay/Stripepay'
import Navbar from '../Navbar/Navbar'
import paypall from '../../assets/images/paypall.png'
import str from '../../assets/images/str.png'
import Loader from '../Loader/loader'
import Spinner from 'react-bootstrap/Spinner'
function Insidebuycourse() {
  const dispatch = useDispatch()

  const navigate = useNavigate()

  var newURL = window.location

  var splitURL = newURL.href.split('/')

  const course_code = splitURL[4]

  const [selectedOption, setSelectedOption] = useState('1')

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value)
  }

  const title = localStorage.getItem('title')
  const startDate = localStorage.getItem('startDate')
  const endDate = localStorage.getItem('endDate')

  const [loading, setLoading] = useState(false)
  const handleSubmit2 = (event) => {
    event.preventDefault()

    let paymethod

    if (selectedOption === 'paypal') {
      paymethod = 1
    } else if (selectedOption === 'stripe') {
      paymethod = 2
    }
  }

  const {
    handleSubmit,

    formState: {errors},
  } = useForm()

  const onSubmit = () => {
    // debugger
    const formData = new FormData()
    setLoading(true)

    formData.append('course_code', course_code)
    formData.append('paid_price', localStorage.getItem('price'))

    formData.append('paymethod', selectedOption)
    formData.append('id', localStorage.getItem('id'))

    if (userDates.type == 'Bundle_dates') {
      formData.append('MicrosoftCourseId', userDates.MicrosoftCourseId)
      formData.append('CompTIACourseId', userDates.CompTIACourseId)
      formData.append('MicrosoftStartDate', userDates.microsoftStartDate)
      formData.append('MicrosoftEndDate', userDates.microsoftEndDate)
      formData.append('CompTIABootcampStartDate', userDates.comptiaStartDate)
      formData.append('CompTIABootcampEndDate', userDates.comptiaEndDate)
      formData.append('Bundle_code', userDates.Bundle_code)
      formData.append('coursetype', 'bundle')
      formData.append('MicrosoftTimezone', localStorage.getItem('MicrosoftTimezone'))
      formData.append('CompTIATimezone', localStorage.getItem('CompTIATimezone'))
      formData.append('MicrosoftdatesId', localStorage.getItem('MicrosoftdatesId'))
      formData.append('CompTIAdatesId', localStorage.getItem('CompTIAdatesId'))
    } else if (localStorage.getItem('type') == 'Elearning') {
      formData.append('CourseId', userDates.CourseId)
      formData.append('CourseStartDate', startDate)
      formData.append('CourseEndDate', endDate)
      formData.append('coursetype', 'Elearning')
      formData.append('courseTimezone', localStorage.getItem('courseTimezone'))
    } else {
      formData.append('CourseId', userDates.CourseId)
      formData.append('CourseStartDate', startDate)
      formData.append('CourseEndDate', endDate)
      formData.append('coursetype', 'course')
      formData.append('courseTimezone', localStorage.getItem('courseTimezone'))
      formData.append('courseDatesId', localStorage.getItem('courseDatesId'))
    }
    dispatch(InsidebuycourseAction(formData, navigate, setSelectedOption, setLoading))
  }

  const userDates = useSelector((state) => state.login.getuserDateslist)

  const [cardComplete, setCardComplete] = useState(false)
  const userid = localStorage.getItem('userid')

  useEffect(() => {
    dispatch(GetUserDatesction(course_code, userid))
  }, [dispatch, userid])

  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    let timer
    if (selectedOption === '2' && cardComplete) {
      setIsLoading(true)
      setIsButtonDisabled(true)
      timer = setTimeout(() => {
        setIsButtonDisabled(false)
        setIsLoading(false)
      }, 1500)
    } else if (selectedOption === '1'){
      setIsButtonDisabled(false)
    } else {
      setIsButtonDisabled(true)
      setIsLoading(false)
      clearTimeout(timer)
    }

    return () => clearTimeout(timer)
  }, [selectedOption, cardComplete])

  return (
    <div className={loading ? 'blur-form' : ''}>
      <Navbar />

      <div className='banner-content'>
        <h1
          className='banner-title'
          style={{
            position: 'absolute',
            top: '30%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            color: 'white',
            fontSize: '30px',
          }}
        >
          Checkout
        </h1>
      </div>
      <div className='container' style={{paddingBottom: '3rem'}}>
        <div className='row'>
          <div className='col-md-2'></div>
          <div className='col-md-8'>
            {loading && (
              <div className='loader-container'>
                <Loader />
              </div>
            )}

            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{padding: '2rem', borderRadius: '.375rem'}}
            >
              <div className='Insidebuy_course right_course'>
                <div className='row'>
                  <div className='col-md-6 col-sm-12'>
                    <div className='card bg-light shadow-none'>
                      <div className='card-body'>
                        {userDates.type == 'Bundle_dates' ? (
                          <div>
                            <h3>Your Order</h3>
                            <div>
                              <h3 className='mb-10 pyment_text'>
                                Bundle : {localStorage.getItem('Name')}
                              </h3>
                              <div>
                                <div>
                                  <h3>Microsoft Bootcamp:</h3>
                                  <h6>Course: {userDates.MicrosoftCourseDetails.title}</h6>
                                  <h6>
                                    Dates: {localStorage.getItem('userMicrosoftStartDate')} -{' '}
                                    {localStorage.getItem('userMicrosoftEndDate')}
                                  </h6>
                                </div>
                                <div>
                                  <h3>CompTIA Bootcamp:</h3>
                                  <h6>Course: {userDates.CompTIACourseDetails.title}</h6>
                                  <h6>
                                    Dates: {localStorage.getItem('userMicrosoftStartDate')} -{' '}
                                    {localStorage.getItem('userMicrosoftEndDate')}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : userDates.type == 'course_dates' ? (
                          <div>
                            <div>
                              <h3 className='pyment_text'>Your Order</h3>

                              <div>
                                <div>
                                  <h5>Course: {title}</h5>
                                  <h5>
                                    Dates: {localStorage.getItem('UserstartDate')} -{' '}
                                    {localStorage.getItem('UserendDate')}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className='mb-5 pb-10'>
                              <div className='text mb-5'>
                                <h3 className='pyment_text mb-5'>Your Order</h3>
                                <h4 style={{color: '#ff763f'}}>Course: {title}</h4>
                                <h4 style={{color: '#ff763f'}} className=' mb-5'>
                                  Validity: 1 Year
                                </h4>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className='col-md-12 mt-10'>
                          <h4 className='total-price d-flex justify-content-between'>
                            <span>Sub Total: </span>£{localStorage.getItem('price')}
                          </h4>
                          <h4 className='total-price d-flex justify-content-between'>
                            <span>Tax: </span>£0
                          </h4>
                          <hr />
                          <h4 className='total-price d-flex justify-content-between'>
                            <span> Total: </span>£{localStorage.getItem('price')}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6 col-sm-12 pt-10'>
                    <div className='card'>
                      <div className='card-body'>
                        <h3 className='pyment_text'>Payment method</h3>
                        <form onSubmit={handleSubmit2} style={{paddingTop: '1rem'}}>
                          <label htmlFor='paypal' style={{paddingTop: '1rem', fontSize: '13px'}}>
                            <div style={{display: 'flex', gap: '1rem'}}>
                              <div>
                                <input
                                  type='radio'
                                  name='paymentOption'
                                  value='1'
                                  id='1'
                                  checked={selectedOption === '1'}
                                  onChange={handleOptionChange}
                                  style={{fontSize: '20px', marginTop: '10px'}}
                                />
                              </div>
                              <div style={{display: 'flex', gap: '1rem'}}>
                                <div>
                                  <img src={paypall} alt='img' style={{width: '120px'}} />
                                </div>
                              </div>
                            </div>
                          </label>

                          <label
                            htmlFor='stripe'
                            style={{
                              fontSize: '13px',
                              display: 'flex',
                              paddingBottom: '1rem',
                              gap: '1rem',
                            }}
                          >
                            <input
                              type='radio'
                              name='paymentOption'
                              value='2'
                              checked={selectedOption === '2'}
                              onChange={handleOptionChange}
                            />
                            <div style={{display: 'flex', gap: '0rem'}}>
                              <div>
                                <img src={str} alt='img' style={{width: '80px'}} />
                              </div>
                            </div>
                          </label>

                          {selectedOption === '2' && (
                            <StripePay setCardComplete={setCardComplete} />
                          )}
                        </form>
                        <div className='text-end mt-10'>
                          <button
                            type='submit'
                            className='btn cyberbtnallpage'
                            disabled={isButtonDisabled}
                          >
                            {isLoading && (
                              <div>
                                {' '}
                                <Spinner
                                  as='span'
                                  animation='border'
                                  role='status'
                                  aria-hidden='true'
                                  variant='secondary'
                                />
                              </div>
                            )}
                            { !isLoading && (
                              <div>
                                Proceed
                              </div>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className='col-md-2'></div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Insidebuycourse
