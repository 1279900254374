import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import {useDispatch, useSelector} from 'react-redux'

import {useNavigate} from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown'
import {FaEdit, FaTrash, FaPlus} from 'react-icons/fa'
import {Tooltip} from 'antd'
import {
  GetallTestimonialsAction,
  TestimonialDeleteAction,
} from '../../../Redux/Action/testimonialAction'
import {AiOutlineLeft, AiOutlineRight} from 'react-icons/ai'

const AllTestimonial = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const Testimonials = useSelector((state) => {
    return state.login.gettestimoniallist
  })

  const allTestimonial = Testimonials.careers || []

  useEffect(() => {
    // debugger;
    dispatch(GetallTestimonialsAction())
  }, [dispatch])

  const deletehandler = (props) => {
    dispatch(TestimonialDeleteAction(props))
  }

  function nextPage() {
    if (currentPage !== nPage) {
      setCurrentPage(currentPage + 1)
    }
  }

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  function changeCPage(id) {
    setCurrentPage(id)
  }

  const [currentPage, setCurrentPage] = useState(1)

  const recordsPerPage = 10

  const lastIndex = currentPage * recordsPerPage

  const firstIndex = lastIndex - recordsPerPage

  const records = allTestimonial.slice(firstIndex, lastIndex)

  const nPage = Math.ceil(allTestimonial.length / recordsPerPage)

  const number = [...Array(nPage + 1).keys()].slice(1)

  return (
    <div>
      <div className='purchase-history'>
        <div className='card'>
          <div className='card-header border-0 pt-6'>
            <div className='card-title'>
              <h3 className='fw-bold m-0'>Testimonials List</h3>
            </div>
            <div class='card-toolbar'>
              <div class='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                <Link to='/add_testimonial' type='button' class='btn cyberbtnallpage'>
                  <AddIcon /> Add Testimonial
                </Link>
              </div>
            </div>
          </div>

          <div className='tab-content'>
            <div className='table-responsive'>
              <table className='table table-row-bordered align-middle gy-4 gs-9'>
                <thead className='border-bottom border-gray-200 fs-6 text-gray-600 fw-bold bg-light bg-opacity-75'>
                  <tr class='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                    <td className='min-w-150px'>#</td>
                    <td className='min-w-200px'>Name</td>
                    <td className='min-w-200px'>Designation</td>
                    {/* <td className='min-w-150px'>LTI Version</td> */}
                    {/* <td className='min-w-150px'>Pulickey Set</td> */}
                    <td className='min-w-150px'>Status</td>
                    <td className='min-w-150px'>Action</td>
                  </tr>
                </thead>
                <tbody className='fw-semibold text-gray-600'>
                  {records.map((item, index) => {
                    return (
                      <tr>
                        <td>{firstIndex + index + 1}</td>

                        <td>{item.name}</td>
                        <td>{item.designation}</td>
                        {/* <td style={{width:"50%"}}>{item.pulickeyset}</td> */}
                        <td>{item.status}</td>
                        <td>
                          <div style={{fontSize: '15px'}}>
                            <Tooltip title='Edit'>
                              <button
                                onClick={() => navigate(`/edit_testimonial/${item.id}`)}
                                style={{
                                  border: 'none',
                                  backgroundColor: 'white', // Set the background color to white
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '18px',
                                    paddingRight: '5px',
                                    color: 'rgb(126, 130, 153)',
                                  }}
                                >
                                  <FaEdit />
                                </span>
                              </button>
                            </Tooltip>

                            <Tooltip title='Delete'>
                              <Link to='#' onClick={() => deletehandler(item.id)}>
                                <span
                                  style={{
                                    fontSize: '18px',
                                    paddingRight: '5px',
                                    color: 'rgb(126, 130, 153)',
                                  }}
                                >
                                  <FaTrash />
                                </span>
                              </Link>
                            </Tooltip>
                          </div>

                          <div
                            class='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
                            data-kt-menu='true'
                          >
                            <div class='menu-item px-3'></div>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            <nav className='w-0 pb-5'>
              <ul className='pagination'>
                <li className='page-item'>
                  <Link to='#' className='page-link' onClick={prePage}>
                    <AiOutlineLeft />
                  </Link>
                </li>
                {number.map((n, i) => (
                  <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                    <Link to='#' className='page-link' onClick={() => changeCPage(n)}>
                      {n}
                    </Link>
                  </li>
                ))}
                <li className='page-item'>
                  <Link to='#' className='page-link' onClick={nextPage}>
                    <AiOutlineRight />
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AllTestimonial
