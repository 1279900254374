import React, {useState, useEffect} from 'react'

// import Homee from "../../routes/Homee";

import './Login.css'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {useNavigate} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {LoginAction} from '../../Redux/Action/authAction'
import FormData from 'form-data'
import {useForm} from 'react-hook-form'
import * as Yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import Loader from '../Loader/loader'

const formSchema = Yup.object().shape({
  email: Yup.string().email().required('Email is required'),

  password: Yup.string().required('Password is required'),
  // .min(4, 'Password length should be at least 6 characters')
  // .max(25, 'Password cannot exceed more than 25 characters'),
})

const Login = () => {
  const dispatch = useDispatch()
  // debugger
  const [formError, setFormError] = useState('')

  const [formSuccess, setFormSuccess] = useState('')

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const {
    register,
    handleSubmit,

    formState: {errors},
  } = useForm({
    mode: 'all',
    resolver: yupResolver(formSchema),
  })
  useEffect(() => {
    if (formSuccess) {
      navigate('/course')
    }
  }, [])
  const onSubmit = (data) => {
    const formData = new FormData()
    setLoading(true)
    // debugger
    // setLoading(true)
    formData.append('email', data.email)
    formData.append('password', data.password)
    dispatch(LoginAction(formData, navigate, setFormError, setFormSuccess, setLoading))
  }
  return (
    <div>
      <div>
        <div className='banner-content'>
          <h1
            className='Login_title banner-title'
            style={{
              position: 'absolute',
              top: '30%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
              color: 'white',
              fontSize: '30px',
            }}
          >
            Login
          </h1>
        </div>
        <div className='container fluid' style={{}}>
          {loading && (
            <div className='loader-container'>
              <Loader />
            </div>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='row login_row'>
              <div
                className='login_box'
                style={{boxShadow: '0 0 20px 0 rgb(0 0 0 / 10%)', padding: '2rem'}}
              >
                <div className='col-12'>
                  <div className='form-group mt-2'>
                    <label htmlFor='inputEmail' className='form-label'>
                      Email
                    </label>
                    <input
                      type='email'
                      className='form-control'
                      id='inputEmail'
                      style={{
                        border: '1px solid grey',
                        padding: '10px',
                        paddingBottom: '10px',
                        marginBottom: '5px',
                      }}
                      placeholder='example@gmail.com'
                      {...register('email', {
                        required: 'Email address is required',
                      })}
                    />
                    {errors.email && (
                      <p className='alert_danger ' role='alert'>
                        {errors.email?.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className='col-12'>
                  <div className='form-group mt-2' style={{paddingBottom: '2rem'}}>
                    <label htmlFor='inputPassword' className='form-label'>
                      Password
                    </label>
                    <input
                      type='password'
                      className='form-control'
                      id='inputPassword'
                      style={{border: '1px solid grey', padding: '10px', marginBottom: '5px'}}
                      placeholder='password'
                      {...register('password', {required: true})}
                      aria-invalid={errors.password ? 'true' : 'false'}
                    />
                    {errors.password && (
                      <p className='alert_danger' role='alert'>
                        {errors.password.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className='col-12 text-center' style={{marginTop: '0rem'}}>
                  <ToastContainer
                    position='bottom-center'
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme='light'
                  />
                  <button
                    className='btn mt-0 w-50 mt-sm-4 button cyberbtnallpage'
                    type='submit'
                    id='login'
                    // style={{
                    //   backgroundColor: '#ff763f',
                    //   padding: '10px',
                    //   color: 'white',
                    // }}
                  >
                    Login
                  </button>
                  {formError && (
                    <p className='alert_danger' role='alert'>
                      {formError}
                    </p>
                  )}
                </div>
                <div
                  className='intro text-center'
                  style={{
                    paddingTop: '2rem',
                    paddingBottom: '2rem',
                    cursor: 'pointer',
                    fontSize: '15px',
                  }}
                >
                  <span className='forgotclss' onClick={() => navigate('/forgotpwd')}>
                    Forgot your password? Click here
                  </span>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Login
