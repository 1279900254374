import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import benefitsIcon from '../../../../../assets/BundleImages/bundle_icon1.svg'
import pricingIcon from '../../../../../assets/BundleImages/bundle_icon3.svg'
import { GetBundleaction } from '../../../../../Redux/Action/bundleAction'

import BundlyBuyBtn from '../BundlyBuyBtn'

const OverviewNew = () => {
  const dispatch = useDispatch()
  var newURL = window.location
  // console.log(newURL, 'newURLnewURL')

  var splitURL = newURL.href.split('/')
  // console.log(splitURL, 'splitURL')
  const bundle_slug = useParams()

  const getbundle = useSelector((state) => {
    return state.login.getbundlebyid
  })
  let cdata = ''

  useEffect(() => {
    // debugger;
    dispatch(GetBundleaction(bundle_slug.bundle_slug))
  }, [dispatch, bundle_slug])
  return (
    <div>
      <div className='container'>
        <div className='row'>
          <div className='col-md-8'>
            <div className='bundle-left-sec'>
              <div className='bundle-overview p-5'>
                <h2 className='heading-blue'>Overview</h2>
                {getbundle &&
                  getbundle.data &&
                  getbundle.data.length > 0 &&
                  getbundle.data[0].bundle_description && (
                    <p
                      className='bundle_detailsprag'
                      dangerouslySetInnerHTML={{
                        __html: `${getbundle.data[0].bundle_description}`,
                      }}
                    />
                  )}
              </div>

              <div className='bundle-keyTopics p-5'>
                <h2 className='heading-blue'>What’s included in the Course Bundle:</h2>
                {getbundle &&
                  getbundle.data &&
                  getbundle.data.length > 0 &&
                  getbundle.data[0].includes && (
                    <p
                      className='bundle_detailsprag'
                      dangerouslySetInnerHTML={{
                        __html: `${getbundle.data[0].includes}`,
                      }}
                    />
                  )}
                {getbundle && getbundle.data && getbundle.data.length > 0 && getbundle.data[0].bundle_pdf ? (
                  <p><a
                    href={getbundle.data[0].bundle_pdf}
                    style={{color: "#264ACA"}}
                    download={getbundle.data[0].bundle_pdf}
                  >
                    Download </a>the full course syllabus to find out more!
                  </p>
                ) : (
                  <p>Download the full course syllabus to find out more!</p>
                )}
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='bundle-right-sec'>
              <div className='bundle-benefits p-5'>
                <div className='heading-icon d-flex'>
                  <img src={benefitsIcon} alt='bundle price' className='feature-icon' />
                  <div className='feature-content'>
                    <h2 className='heading-orange'>Course Bundle Benefits</h2>
                    {getbundle &&
                      getbundle.data &&
                      getbundle.data.length > 0 &&
                      getbundle.data[0].benefits && (
                        <p
                          className='bundle_detailsprag'
                          dangerouslySetInnerHTML={{
                            __html: `${getbundle.data[0].benefits}`,
                          }}
                        />
                      )}
                  </div>
                </div>

                <div className='heading-icon d-flex'>
                  <img src={pricingIcon} alt='bundle price' className='feature-icon' />
                  <div className='feature-content'>
                    <h2 className='heading-orange'>Total Course Bundle Price</h2>
                    <BundlyBuyBtn />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OverviewNew
