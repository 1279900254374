import React from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import {GetallBundlaction} from '../../Redux/Action/bundleAction'
import {useDispatch, useSelector} from 'react-redux'
import {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import course from '../../PrivateImage/img/course.png'
import time from '../../PrivateImage/img/time.svg'
import badge from '../../PrivateImage/img/badge.svg'
import bolt from '../../PrivateImage/img/bolt.svg'
import arrow from '../../PrivateImage/img/button-arrow.svg'
const Allbundles = () => {
  const navigate = useNavigate()
  const [message, setMessage] = useState('')

  const dispatch = useDispatch()

  const allbundle = useSelector((state) => {
    return state.login.getbundlelist
  })

  // console.log(allbundle.Published, 'uuuuuuuuuuuuuuuuuuuuuuuuuu')

  const bundle = allbundle.Published || []
  // console.log(bundle, 'datadatadatadatadatadatadata')

  useEffect(() => {
    dispatch(GetallBundlaction())
  }, [dispatch])

  let vdata = ''
  return (
    <div>
      <Navbar />
      <div class='heading text-center'>
        <span>CYBERSECURITY TRAINING COURSES</span>
        <h2>
          Beginners. Intermediaries. Professionals. <br /> Our courses create leaders out of all.
        </h2>
      </div>
      <div>
        <div className='container'>
          <div class='tab-content' id='nav-tabContent'>
            <div
              class='tab-pane fade active show'
              id='nav-all'
              role='tabpanel'
              aria-labelledby='nav-all-tab'
            >
              <ul class='course-grid'>
                {bundle.map((item) => {
                  return (
                    <li class='item'>
                      <div class='wrapper'>
                        <div class='image-wrapper'>
                          <img src={item.ImageLink} alt='course' />
                        </div>
                        <div class='details'>
                          <h5>{item.Name}</h5>
                          <div class='list-item'>
                            {' '}
                            <img src={time} /> {item.duration}
                          </div>
                          <div class='list-item'>
                            {' '}
                            <img src={badge} /> Microsoft SC 200 certification
                          </div>
                          <div class='list-item'>
                            {' '}
                            <img src={bolt} /> Cyberheads Bolt On
                          </div>
                        </div>
                        <div class='button-group' style={{justifyContent: 'end'}}>
                          <Link
                            to={`/bundle_details/${item.bundle_slug}/${item.bundle_code}`}
                            class='button primary'
                            onClick={() => {
                              localStorage.setItem('paid_price', item.price)
                            }}
                          >
                            VIEW COURSE
                          </Link>
                        </div>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Allbundles
