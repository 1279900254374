import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {GetAllUserCount} from '../../../../Redux/Action/admindashboardAction'

import {PiUsers} from 'react-icons/pi'
import '../Sections/section.css'

const Totaluser = () => {
  const dispatch = useDispatch()
  // debugger

  const alluser = useSelector((state) => {
    return state.login.getallusercountlist
  })

  useEffect(() => {
    dispatch(GetAllUserCount())
  }, [dispatch])

  //
  return (
    <div>
      <div className='dashboards_userlist' style={{marginRight: '6px'}}>
        <div className='card card-flush userlist h-md-100 mb-5 mb-xl-10 w-100%'>
          <div className='card-header pt-5 pb-4' style={{borderLeft: '3px solid #FF763F'}}>
            <div className='card-title d-flex flex-column'>
              <div className='d-flex align-items-center'>
                <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>
                  {alluser.totalUsers}
                </span>

                <span className='badge badge-light-success fs-base'></span>
              </div>
              <span className='text-gray-400 pt-1 fw-semibold fs-2'>Total Students</span>
              <div className='d-flex fw-semibold align-items-center'>
                <div className='bullet w-8px h-3px rounded-2 bg-grey me-3'></div>
                <div className='text-gray-500 flex-grow-1 me-4'>Active</div>
                <div className='fw-bolder text-gray-700 text-xxl-end'>{alluser.activeUser}</div>
              </div>
              <div className='d-flex fw-semibold align-items-center'>
                <div className='bullet w-8px h-3px rounded-2 bg-grey me-3'></div>
                <div className='text-gray-500 flex-grow-1 me-4'>Deactive</div>
                <div className='fw-bolder text-gray-700 text-xxl-end'>{alluser.Deactivateuser}</div>
              </div>
            </div>
            <div>
              <PiUsers style={{fontSize: '3.5rem', marginTop: '100px', color: '#FF763F'}} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Totaluser
