import React, {useEffect, useState} from 'react'

import AddIcon from '@mui/icons-material/Add'
import {useDispatch, useSelector} from 'react-redux'

import {GetallCourseDatesAction} from '../../../Redux/Action/courseAction'

import {FaEdit, FaRegCalendarAlt, FaTrash, FaPlus} from 'react-icons/fa'

import {useParams} from 'react-router-dom'
import './Course.css'
import {Link, useNavigate} from 'react-router-dom'
import {Tooltip} from 'antd'

import {AiOutlineLeft, AiOutlineRight} from 'react-icons/ai'
import {FilterMatchMode} from 'primereact/api'
import {MultiSelect} from 'primereact/multiselect'
import {Toolbar} from 'primereact/toolbar'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'

export default function CourseDates() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [filters, setFilters] = useState({
    global: {value: null, matchMode: FilterMatchMode.CONTAINS},
    title: {value: null, matchMode: FilterMatchMode.CONTAINS},
    start_datetime: {value: null, matchMode: FilterMatchMode.IN},
    end_datetime: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
    times: {value: null, matchMode: FilterMatchMode.IN},
    timezone: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
    // instructor: {value: null, matchMode: FilterMatchMode.IN}
  })

  const columns = [
    {field: 'title', header: 'Course Name'},
    {field: 'start_datetime', header: 'Start Date'},
    {field: 'end_datetime', header: 'End Date'},
    {field: 'times', header: 'Times'},
    {field: 'timezone', header: 'Timezone'},
    // {field: 'instructorsNames', header: 'Instructor'},
  ]

  const onColumnToggle = (event) => {
    let selectedColumns = event.value
    let orderedSelectedColumns = columns.filter((col) =>
      selectedColumns.some((sCol) => sCol.field === col.field)
    )

    setVisibleColumns(orderedSelectedColumns)
  }

  const [loading, setLoading] = useState(true)
  const [globalFilterValue, setGlobalFilterValue] = useState('')
  const [visibleColumns, setVisibleColumns] = useState(columns)

  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    let _filters = {...filters}

    _filters['global'].value = value

    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const leftToolbarTemplate = () => {
    return (
      <MultiSelect
        value={visibleColumns}
        options={columns}
        optionLabel='header'
        onChange={onColumnToggle}
        className='w-full sm:w-20rem'
        display='chip'
      />
    )
  }

  const courses = useSelector((state) => {
    const Courses = state.login.courseDatesList.data || []

    const CoursesDates = [
      ...Courses.map((item, index) => ({
        ...item,
        SNo: index + 1,
      })),
    ]

    return CoursesDates
  })

  useEffect(() => {
    dispatch(GetallCourseDatesAction()).then(() => {
      setLoading(false)
    })
  }, [dispatch])

  console.log(courses, 'coursescoursescoursescourses')

  const actionTemplate = (rowData) => {
    return (
      <div style={{display: 'flex', gap: '0px', marginLeft: '-2rem'}}>
        <div style={{fontSize: '15px'}}>
          <Tooltip title='Edit Course'>
            <Link to={`/edit_meeting/${rowData.meetingId}`}>
              <span
                style={{
                  fontSize: '18px',
                  paddingRight: '5px',
                  color: 'rgb(126, 130, 153)',
                }}
              >
                <FaEdit />
              </span>
            </Link>
          </Tooltip>
        </div>
      </div>
    )
  }

  const instructorsNamesTemplate = (rowData) => {
    return <span>{rowData.instructorsNames.join(', ')}</span>
  }

  return (
    <div>
      <Toolbar className='mb-4' left={leftToolbarTemplate} />
      <DataTable
        value={courses}
        paginator
        rows={20}
        tableStyle={{minWidth: '100%'}}
        dataKey='id'
        filters={filters}
        filterDisplay='row'
        className='card'
        globalFilterFields={['title', 'start_datetime', 'end_datetime', 'times', 'timezone']}
        emptyMessage='No courses found.'
        rowClassName='table-row'
      >
        <Column field='SNo' header='#' sortable />
        {visibleColumns.map((col) => (
          <Column
            sortable
            key={col.field}
            field={col.field}
            header={col.header}
            filter
            style={{minWidth: '15rem'}}
            filterPlaceholder={`Search by ${col.header}`}
          />
        ))}
        <Column
          field='instructorsNames'
          header='Instructors'
          body={instructorsNamesTemplate}
          exportable={false}
          style={{minWidth: '15rem'}}
        />

        <Column
          field={(row) => (row.startUrl ? row.startUrl.slice(0, 50) : '')}
          header='Instructor Url'
          filterPlaceholder='Search by status'
          style={{minWidth: '12rem'}}
        />

        <Column
          field='joinUrl'
          header='Student Url'
          filterPlaceholder='Search by status'
          style={{minWidth: '12rem'}}
        />

        <Column
          field='salesCount'
          header='No of Sales'
          filterPlaceholder='Search by status'
          style={{minWidth: '12rem'}}
        />

        <Column
          field='id'
          header='Action'
          body={actionTemplate}
          exportable={false}
          style={{marginLeft: '-12rem'}}
        />
      </DataTable>
    </div>
  )
}
