import React from 'react'
import Overview from './Overview'
import './Tablelinetab.css'
import Question from './Question'
import Notbook from "./Notbook"
import Transcripts from './Transcripts'
import Resources from './Resources'

const Tablelinetab = () => {
  return (
    <div>
      <ul
        class='nav nav-tabs'
        style={{dispaly: 'flex', paddingTop: '30px', borderBottom: '1px solid grey', gap: '2rem'}}
      >
        {/* , justifyContent: "center" */}

        <li class='nav-item'>
          <a class='nav-link active' data-bs-toggle='tab' href='#home'>
            overview
          </a>
        </li>
        <li class='nav-item'>
          <a class='nav-link' data-bs-toggle='tab' href='#profile'>
            Q&A
          </a>
        </li>
        <li class='nav-item'>
          <a class='nav-link' data-bs-toggle='tab' href='#contact'>
            Notebook
          </a>
        </li>
        <li class='nav-item'>
          <a class='nav-link' data-bs-toggle='tab' href='#trnctiom'>
            Transcripts
          </a>
        </li>
        <li class='nav-item'>
          <a class='nav-link' data-bs-toggle='tab' href='#resourse'>
            Resource
          </a>
        </li>
      </ul>

      <div class='tab-content'>
        <div class='tab-pane active' id='home'>
          <div class='col p-4'>
            <Overview />
          </div>
        </div>
        <div class='tab-pane' id='profile'>
          <div class='col p-4' style={{minHeight:"500px"}}>
           <Question/>
          </div>
        </div>
        <div class='tab-pane' id='contact'>
          <div class='col p-4'><Notbook/></div>
        </div>
        <div class='tab-pane' id='trnctiom'>
          <div class='col p-4'><Transcripts/></div>
        </div>
        <div class='tab-pane' id='resourse'>
          <div class='col p-4'><Resources/></div>
        </div>
      </div>
    </div>
  )
}

export default Tablelinetab
