import {useNavigate} from 'react-router-dom'

import React, {useEffect, useState} from 'react'

import Sectioncourse from './Sectioncourse'
import Certifiedcourse from './Certifiedcourse'
import Coursetestimonal from './Coursetestimonal'

import BundletabTestimonial from '../Bundle/BundletabTestimonial/BundletabTestimonial'
import Cta from '../Cta/Cta'
import './Courses.css'

import {GetallCourseAction, CheckusercourseAction} from '../../Redux/Action/courseAction'

import {useDispatch, useSelector} from 'react-redux'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'

import swal from 'sweetalert'
import {useLocation} from 'react-router-dom'

const Courses = (imageUrl) => {
  const navigate = useNavigate()
  const [message, setMessage] = useState('')

  const dispatch = useDispatch()

  const Allcourse = useSelector((state) => {
    return state.login.getcourseslist
  })
  const allcourse = Allcourse.Published || []

  var newURL = window.location
  var splitURL = newURL.href.split('/')

  const titlename = splitURL[3]

  useEffect(() => {
    dispatch(GetallCourseAction())
  }, [dispatch])

  useEffect(() => {
    dispatch(CheckusercourseAction())
  }, [dispatch])

  const location = useLocation()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const message = searchParams.get('message')
    if (message) {
      swal({
        title: 'Success!',
        text: 'Payment successful. Thank you for your purchase!',
        icon: 'success',
        buttons: {
          cancel: {
            text: 'OK',
            value: null,
            visible: true,
            className: 'swal-button--confirm cyberbtnallpage',
          },
        },
      }).then(() => {
        const newUrl = window.location.pathname
        window.history.pushState({}, '', newUrl)
      })
    }
  }, [location.search])

  return (
    <div>
      <Navbar />
      <div className='responsvive_text'>
        <h2 className='text-white' style={{fontSize: '30px'}}>
          Courses
        </h2>
      </div>
      <Sectioncourse />
      <div className='pt-20'>
        <BundletabTestimonial />
      </div>
      <Coursetestimonal />

      <Certifiedcourse />
      <Cta />
      <Footer />
    </div>
  )
}

export default Courses

// import {useNavigate} from 'react-router-dom'

// import React, {useEffect, useState} from 'react'

// import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined'

// import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'

// import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined'
// import Sectioncourse from './Sectioncourse'
// import Certifiedcourse from './Certifiedcourse'

// import './Courses.css'

// import {GetallCourseAction, CheckusercourseAction} from '../../Redux/Action/courseAction'

// import {useDispatch, useSelector} from 'react-redux'
// import Navbar from '../Navbar/Navbar'
// import Footer from '../Footer/Footer'

// const Courses = (imageUrl) => {
//   const navigate = useNavigate()
//   const [message, setMessage] = useState('')

//   const dispatch = useDispatch()

//   const Allcourse = useSelector((state) => {
//     return state.login.getcourseslist
//   })
//   const allcourse = Allcourse.Published || []

//   var newURL = window.location
//   var splitURL = newURL.href.split('/')

//   const titlename = splitURL[3]

//   useEffect(() => {
//     dispatch(GetallCourseAction())
//   }, [dispatch])

//   const chekcourses = useSelector((state) => {
//     return state.login.checkcourseslist
//   })

//   useEffect(() => {
//     dispatch(CheckusercourseAction())
//   }, [dispatch])

//   const learningprev = (props) => {
//     dispatch(CheckusercourseAction(props, navigate, setMessage))
//   }

//   const Comptia = (props) => {
//     dispatch(CheckusercourseAction(props, navigate, setMessage))
//   }

//   return (
//     <div>
//       <Navbar />
//       <Sectioncourse />
//       <div className='banner-content'>
//         <h1
//           className='banner-title'
//           style={{
//             position: 'absolute',
//             top: '30%',
//             left: '50%',
//             transform: 'translate(-50%, -50%)',
//             textAlign: 'center',
//             color: 'white',
//             fontSize: '30px',
//           }}
//         >
//           Course
//         </h1>
//       </div>
//       <div className='container'>
//         <div className='row'>
//           <div
//             class='tab-pane fade active show'
//             id='nav-all'
//             role='tabpanel'
//             aria-labelledby='nav-all-tab'
//           >
//             <ul class='course-grid'>
//               {allcourse.map((course, index) => {
//                 const courseCategory =
//                   course.type === '1'
//                     ? 'CompTIA Bootcamp'
//                     : course.type === '2'
//                     ? 'Microsoft Bootcamp'
//                     : 'CompTIA elearning'

//                 const truncatedDescription =
//                   course.description.length > 100
//                     ? `${course.description.slice(0, 55)}...`
//                     : course.description
//                 return (
//                   <li class='item' key={index}>
//                     <div class='wrapper'>
//                       <div class='image-wrapper'>
//                         <img src={course.image_link} alt='' />
//                       </div>
//                       <div class='details'>
//                         <h5
//                           style={{
//                             cursor: 'pointer',
//                           }}
//                           onClick={() => {
//                             localStorage.setItem('paid_price', course.paid_price)
//                             localStorage.setItem('category', course.category)

//                             if (localStorage.getItem('userid') !== null) {
//                               if (course.category === 'comptia') {
//                                 Comptia({
//                                   userId: localStorage.getItem('userid'),
//                                   coursecode: course.course_code,
//                                   coursid: course.courseslug,
//                                   category: course.category,
//                                 })
//                               } else {
//                                 learningprev({
//                                   userId: localStorage.getItem('userid'),
//                                   coursecode: course.course_code,
//                                   coursid: course.courseslug,
//                                 })
//                               }
//                             } else {
//                               navigate(`/learningprev?/${course.course_code}/${course.courseslug}`)
//                             }
//                           }}
//                         >
//                           {course.title}
//                         </h5>

//                         <div class='list-item'>
//                           {' '}
//                           <AttachMoneyOutlinedIcon
//                             style={{color: '#264ACA', fontSize: ' 32px'}}
//                           />{' '}
//                           {course.paid_price}
//                         </div>
//                         <div class='list-item'>
//                           {' '}
//                           <DescriptionOutlinedIcon
//                             style={{color: '#264ACA', fontSize: ' 32px'}}
//                           />{' '}
//                           <p dangerouslySetInnerHTML={{__html: truncatedDescription}} />
//                         </div>
//                         <div class='list-item'>
//                           {' '}
//                           <CategoryOutlinedIcon
//                             style={{color: '#264ACA', fontSize: ' 32px'}}
//                           />{' '}
//                           {courseCategory}
//                         </div>
//                       </div>
//                       <div class='button-group justify-content-end'>
//                         {/* <a href='#' class='button white'>
//                           <img src='img/button-arrow.svg' alt='' /> SYLLABUS
//                         </a> */}
//                         {/* <a
//                           class='button primary'
//                           onClick={() => {
//                             localStorage.setItem('paid_price', course.paid_price)
//                             localStorage.setItem('category', course.category)

//                             if (localStorage.getItem('userid') !== null) {
//                               if (course.category === 'comptia') {
//                                 Comptia({
//                                   userId: localStorage.getItem('userid'),
//                                   coursecode: course.course_code,
//                                   coursid: course.courseslug,
//                                   category: course.category,
//                                 })
//                               } else {
//                                 learningprev({
//                                   userId: localStorage.getItem('userid'),
//                                   coursecode: course.course_code,
//                                   coursid: course.courseslug,
//                                 })
//                               }
//                             } else {
//                               navigate(`/learningprev?/${course.course_code}/${course.courseslug}`)
//                             }
//                           }}
//                         >
//                           VIEW COURSE
//                         </a> */}

//                         <a
//                           className='button primary'
//                           onClick={() => {
//                             if (course.Course_Category === 'CompTIA eLearning') {
//                               navigate(
//                                 `/comptia-learning?/${course.course_code}/${course.courseslug}#learning`
//                               )
//                             } else if (course.Course_Category === 'CompTIA Bootcamp') {
//                               navigate(
//                                 `/compitia_bootcamp?/${course.course_code}/${course.courseslug}#bootcamp`
//                               )
//                             } else if (course.Course_Category === 'Microsoft Bootcamp') {
//                               navigate(
//                                 `/microsoft-bootcamp?/${course.course_code}/${course.courseslug}#microsoft`
//                               )
//                             }
//                           }}
//                         >
//                           VIEW COURSE
//                         </a>
//                       </div>
//                     </div>
//                   </li>
//                 )
//               })}
//             </ul>
//           </div>
//         </div>
//       </div>{' '}
//       <Certifiedcourse />
//       <Footer />
//     </div>
//   )
// }

// export default Courses
