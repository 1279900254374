import React, {useState, useRef, useMemo, useEffect} from 'react'
import JoditEditor from 'jodit-react'
import {Link, useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {GetallCoursesBundlesAction} from '../../../Redux/Action/courseAction'
import {CreateCareerAction} from '../../../Redux/Action/careerAction'
import {useForm, Controller} from 'react-hook-form'
import Loader from '../../../components/Loader/loader'

import CountryFlag from 'react-country-flag'
import {Country} from 'country-state-city'

const AddCareer = ({placeholder}) => {
  const [loading, setLoading] = useState(false)
  const [countries, setCountries] = useState([])
  const [about_role, Setabout_role] = useState('')
  const [who_suited, Setwho_suited] = useState('')
  const [why_consider, Setwhy_consider] = useState('')
  const [jobs, setJobs] = useState([''])
  const [certifications, setCertifications] = useState([''])
  const [skills, setSkills] = useState([''])
  const [careerpaths, setCareerPaths] = useState([''])
  const [salaries, setSalaries] = useState([{country: '', amount: ''}])
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({
    courseIds: [],
    bundles: {},
  })

  const handleCheckboxChange = (id, value) => {
    if (id.includes('course')) {
      setSelectedCheckboxes((prevSelected) => {
        const updatedCourseIds = value.checked
          ? [...prevSelected.courseIds, value.id]
          : prevSelected.courseIds.filter((courseId) => courseId !== value.id)

        return {
          ...prevSelected,
          courseIds: updatedCourseIds,
        }
      })
    } else {
      setSelectedCheckboxes((prevSelected) => {
        const updatedBundles = {...prevSelected.bundles}

        if (value.checked) {
          updatedBundles[value.id] = value.Name
        } else {
          delete updatedBundles[value.id]
        }

        return {
          ...prevSelected,
          bundles: updatedBundles,
        }
      })
    }
  }

  const navigate = useNavigate()

  const dispatch = useDispatch()

  /*---- Add More Handle Functions ---- */
  const handleAddMoreSalary = () => {
    setSalaries([...salaries, {country: '', amount: ''}])
  }

  const handleAddMoreCertification = () => {
    setCertifications([...certifications, ''])
  }

  const handleAddMoreJob = () => {
    setJobs([...jobs, ''])
  }

  const handleAddMoreSkills = () => {
    setSkills([...skills, ''])
  }

  const handleAddMoreCareerpath = () => {
    setCareerPaths([...careerpaths, ''])
  }

  /*---- Remove Handle Functions ---- */
  const handleRemoveCertification = (index) => {
    const newHobbies = [...certifications]
    newHobbies.splice(index, 1)
    setCertifications(newHobbies)
  }

  const handleRemoveJob = (index) => {
    const newHobbies = [...jobs]
    newHobbies.splice(index, 1)
    setJobs(newHobbies)
  }

  const handleRemoveSkills = (index) => {
    const newSkills = [...skills]
    newSkills.splice(index, 1)
    setSkills(newSkills)
  }

  const handleRemoveCareerpath = (index) => {
    const newCps = [...careerpaths]
    newCps.splice(index, 1)
    setCareerPaths(newCps)
  }

  const handleRemoveSalary = (index) => {
    const newSalaries = [...salaries]
    newSalaries.splice(index, 1)
    setSalaries(newSalaries)
  }

  /*---- Change Handle Functions ---- */

  const handleSalaryChange = (index, field, value) => {
    const newSalaries = [...salaries]
    newSalaries[index][field] = value
    setSalaries(newSalaries)
  }

  const handleCertificationChange = (index, value) => {
    const newCertifications = [...certifications]
    newCertifications[index] = value
    setCertifications(newCertifications)
  }

  const handleJobChange = (index, value) => {
    const newJobs = [...jobs]
    newJobs[index] = value
    setJobs(newJobs)
  }

  const handleSkillsChange = (index, value) => {
    const newSkills = [...skills]
    newSkills[index] = value
    setSkills(newSkills)
  }

  const handleCareerpathChange = (index, value) => {
    const newCps = [...careerpaths]
    newCps[index] = value
    setCareerPaths(newCps)
  }

  const isSubmitDisabled = () => {
    // Disable the submit button if no salary is entered
    return salaries.every((salary) => salary.amount.trim() === '' && salary.country.trim() === '')
  }
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: {errors},
  } = useForm()

  const onSubmit = (data) => {
    //debugger
    const formData = new FormData()
    setLoading(true)

    formData.append('title', data.title)
    formData.append('category', data.category)
    formData.append('about_role', data.about_role)
    formData.append('who_suited', data.who_suited)
    formData.append('why_consider', data.why_consider)
    formData.append('jobs', JSON.stringify(jobs))
    formData.append('certifications', JSON.stringify(certifications))
    formData.append('skills', JSON.stringify(skills))
    formData.append('recommendedCourses', JSON.stringify(selectedCheckboxes))
    formData.append('status', data.status)
    formData.append('careerpaths', JSON.stringify(careerpaths))
    if (salaries.length > 0) {
      formData.append('salaries', JSON.stringify(salaries))
    }

    dispatch(CreateCareerAction(formData, navigate, setLoading))
  }

  useEffect(() => {
    dispatch(GetallCoursesBundlesAction())
  }, [dispatch])

  const CourseBundle = useSelector((state) => state.login.getallCourseBundleslist)

  useEffect(() => {
    // Fetch the country list when the component mounts
    const countryList = Country.getAllCountries().map((country) => ({
      code: country.isoCode,
      name: country.name,
    }))
    setCountries(countryList)
  }, [])

  return (
    <div>
      <div id='kt_app_content' class='app-content flex-column-fluid'>
        {loading && (
          <div className='loader-container'>
            <Loader />
          </div>
        )}
        <form
          id='kt_ecommerce_add_product_form'
          class='form d-flex flex-column flex-lg-row'
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className='course_category col-md-4'>
            <div class='d-flex flex-column gap-7 gap-lg-10 w-100 mb-7 me-lg-10'>
              <div class='card card-flush py-4'>
                <div class='card-header'>
                  <div class='card-title'>
                    <h2>Role Category</h2>
                  </div>
                </div>

                <div class='card-body pt-0'>
                  <select
                    class='form-select mb-2'
                    data-control='select2'
                    data-hide-search='true'
                    data-placeholder='Select an option'
                    id='kt_ecommerce_add_category_status_select'
                    {...register('category', {
                      required: true,
                    })}
                  >
                    aria-invalid={errors.category ? 'true' : 'false'}
                    <option value='' disabled selected>
                      Select{' '}
                    </option>
                    <option value='1'>Junior Roles</option>
                    <option value='2'>Mid Roles</option>
                    <option value='3'>Senior/Exec Roles</option>
                  </select>
                  {errors.category?.category === 'required' && (
                    <p  className='alert_danger' role='alert'>
                      This is required field
                    </p>
                  )}
                </div>
              </div>
              <div class='card card-flush py-4'>
                <div class='card-header'>
                  <div class='card-title'>
                    <h2>Status</h2>
                  </div>
                </div>

                <div class='card-body pt-0'>
                  <select
                    class='form-select mb-2'
                    data-control='select2'
                    data-hide-search='true'
                    data-placeholder='Select an option'
                    {...register('status', {
                      required: true,
                    })}
                  >
                    aria-invalid={errors.status ? 'true' : 'false'}
                    <option value='Published'>Published</option>
                    <option value='Draft' selected='selected' default>
                      Draft
                    </option>
                  </select>
                  {errors.status?.status === 'required' && (
                    <p className='alert_danger' role='alert'>
                      This is required field
                    </p>
                  )}
                </div>
              </div>
              <div class='card card-flush py-4'>
                <div class='card-header'>
                  <div class='card-title'>
                    <h2>Career Path</h2>
                  </div>
                </div>

                <div class='card-body pt-0'>
                  <div className='mt-5 bg-light p-3'>
                    {careerpaths.map((cp, index) => (
                      <div key={index} className='d-flex align-items-center justify-content-center'>
                        <input
                          type='text'
                          class='form-control mb-2'
                          placeholder='Enter Career Path'
                          value={cp}
                          onChange={(e) => handleCareerpathChange(index, e.target.value)}
                          style={{marginRight: '10px'}}
                          rules={{required: true}}
                        />
                        {index !== 0 && (
                          <button
                            className='btn btn-sm btn-danger'
                            type='button'
                            onClick={() => handleRemoveCareerpath(index)}
                          >
                            Remove
                          </button>
                        )}
                      </div>
                    ))}
                    <button
                      className='btn btn-sm cyberbtnallpage'
                      type='button'
                      onClick={handleAddMoreCareerpath}
                    >
                      Add More
                    </button>
                  </div>
                </div>
              </div>

              <div class='card card-flush py-4'>
                <div class='card-header'>
                  <div class='card-title'>
                    <h2>Recommended Courses</h2>
                  </div>
                </div>

                <div class='card-body pt-0'>
                  <div className='mt-2 bg-light p-3'>
                    <ul>
                      <p>Select Recommended Courses</p>

                      {CourseBundle?.Bundles?.map((bundle, index) => (
                        <li className='form-check' key={`bundle-${index}`}>
                          <input
                            type='checkbox'
                            className='form-check-input'
                            id={`checkboxBundle-${index}`}
                            value={bundle.id}
                            checked={selectedCheckboxes.bundles[bundle.id] !== undefined}
                            onChange={() =>
                              handleCheckboxChange(`bundle${bundle.id}`, {
                                ...bundle,
                                checked: !selectedCheckboxes.bundles[bundle.id],
                              })
                            }
                          />
                          <label className='form-label mt-0' htmlFor={`checkboxBundle-${index}`}>
                            {bundle.Name}
                          </label>
                        </li>
                      ))}
                      {CourseBundle?.Courses?.map((course, index) => (
                        <li className='form-check' key={`course-${index}`}>
                          <input
                            type='checkbox'
                            className='form-check-input'
                            value={course.id}
                            id={`checkboxCourse-${index}`}
                            checked={selectedCheckboxes.courseIds.includes(course.id)}
                            onChange={() =>
                              handleCheckboxChange(`course${course.id}`, {
                                ...course,
                                checked: !selectedCheckboxes.courseIds.includes(course.id),
                              })
                            }
                          />
                          <label className='form-label mt-0' htmlFor={`checkboxCourse-${index}`}>
                            {course.title}
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-8'>
            <div class='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
              <div class='tab-content'>
                <div
                  class='tab-pane fade show active'
                  id='kt_ecommerce_add_product_general'
                  role='tab-panel'
                >
                  <div class='d-flex flex-column gap-7 gap-lg-10'>
                    <div class='card card-flush py-4'>
                      <div class='card-body pt-0'>
                        <div class='mb-10 fv-row'>
                          <label class='required form-label'>Role Name</label>

                          <input
                            type='text'
                            name='title'
                            class='form-control mb-2'
                            placeholder='Role Name'
                            aria-invalid={errors.title ? 'true' : 'false'}
                            {...register('title', {required: true})}
                          />
                          {errors.title?.type === 'required' && (
                            <p className='alert_danger' role='alert'>
                              This is required field
                            </p>
                          )}
                        </div>

                        <div>
                          <label class='required form-label'>About The Role:</label>

                          <Controller
                            control={control}
                            name='about_role'
                            defaultValue=''
                            rules={{required: true}}
                            render={({field}) => {
                              return (
                                <JoditEditor
                                  ref={field.ref}
                                  value={field.value}
                                  placeholder='start typing'
                                  aria-invalid={errors.about_role ? 'true' : 'false'}
                                  tabIndex={1} // tabIndex of textarea
                                  
                                  onBlur={(newContent) => {
                                    if(newContent === '<p><br></p>')
                                    newContent = ''
                                    Setabout_role(newContent);

                                    field.onChange(newContent)
                                  }}
                                  onChange={(newContent) => {
                                  }}
                                />
                              )
                            }}
                          />
                          {errors.about_role?.type === 'required' && (
                            <p className='alert_danger' role='alert'>
                              This is required field
                            </p>
                          )}
                          
                        </div>
                        <div>
                          <label class='required form-label'>Who's it suited for:</label>

                          <Controller
                            control={control}
                            name='who_suited'
                            defaultValue=''
                            rules={{required: true}}
                            render={({field}) => {
                              return (
                                <JoditEditor
                                  ref={field.ref}
                                  value={field.value}
                                  placeholder='start typing'
                                  aria-invalid={errors.who_suited ? 'true' : 'false'}
                                  tabIndex={1} // tabIndex of textarea
                                  
                                  onBlur={(newContent) => {
                                    if(newContent === '<p><br></p>')
                                    newContent = ''
                                    Setwho_suited(newContent);

                                    field.onChange(newContent)
                                  }}
                                  onChange={(newContent) => {
                                  }}
                                />
                              )
                            }}
                          />
                          {errors.who_suited?.type === 'required' && (
                            <p className='alert_danger' role='alert'>
                              This is required field
                            </p>
                          )}
                          
                        </div>
                        <div>
                          <label class='required form-label'>Why Should you Consider it?</label>

                          <Controller
                            control={control}
                            name='why_consider'
                            defaultValue=''
                            rules={{required: true}}
                            render={({field}) => {
                              return (
                                <JoditEditor
                                  ref={field.ref}
                                  value={field.value}
                                  placeholder='start typing'
                                  aria-invalid={errors.why_consider ? 'true' : 'false'}
                                  tabIndex={1} // tabIndex of textarea
                                  
                                  onBlur={(newContent) => {
                                    if(newContent === '<p><br></p>')
                                    newContent = ''
                                    Setwhy_consider(newContent);

                                    field.onChange(newContent)
                                  }}
                                  onChange={(newContent) => {
                                  }}
                                />
                              )
                            }}
                          />
                          {errors.why_consider?.type === 'required' && (
                            <p className='alert_danger' role='alert'>
                              This is required field
                            </p>
                          )}
                          
                        </div>
                        <div className='mt-10 bg-light p-3'>
                          <label class='form-label'>Common Job Titles</label>
                          {jobs.map((job, index) => (
                            <div
                              key={index}
                              className='d-flex align-items-center justify-content-center'
                            >
                              <input
                                type='text'
                                class='form-control mb-2'
                                placeholder='Enter Job Title'
                                value={job}
                                onChange={(e) => handleJobChange(index, e.target.value)}
                                style={{marginRight: '10px'}}
                              />
                              {index !== 0 && (
                                <button
                                  className='btn btn-sm btn-danger'
                                  type='button'
                                  onClick={() => handleRemoveJob(index)}
                                >
                                  Remove
                                </button>
                              )}
                            </div>
                          ))}
                          <button
                            className='btn btn-sm cyberbtnallpage'
                            type='button'
                            onClick={handleAddMoreJob}
                          >
                            Add More
                          </button>
                        </div>
                        <div className='mt-10 bg-light p-3'>
                          <label class='form-label'>Top Skills Requested</label>
                          {skills.map((skill, index) => (
                            <div
                              key={index}
                              className='d-flex align-items-center justify-content-center'
                            >
                              <input
                                type='text'
                                class='form-control mb-2'
                                placeholder='Enter Skill'
                                value={skill}
                                onChange={(e) => handleSkillsChange(index, e.target.value)}
                                style={{marginRight: '10px'}}
                              />
                              {index !== 0 && (
                                <button
                                  className='btn btn-sm btn-danger'
                                  type='button'
                                  onClick={() => handleRemoveSkills(index)}
                                >
                                  Remove
                                </button>
                              )}
                            </div>
                          ))}
                          <button
                            className='btn btn-sm cyberbtnallpage'
                            type='button'
                            onClick={handleAddMoreSkills}
                          >
                            Add More
                          </button>
                        </div>
                        <div className='mt-10 bg-light p-3'>
                          <label class='form-label'>Top Certifications Requested</label>
                          {certifications.map((certi, index) => (
                            <div
                              key={index}
                              className='d-flex align-items-center justify-content-center'
                            >
                              <input
                                type='text'
                                class='form-control mb-2'
                                placeholder='Enter Certifications Title'
                                value={certi}
                                onChange={(e) => handleCertificationChange(index, e.target.value)}
                              />
                              {index !== 0 && (
                                <button
                                  className='btn btn-sm btn-danger'
                                  type='button'
                                  onClick={() => handleRemoveCertification(index)}
                                >
                                  Remove
                                </button>
                              )}
                            </div>
                          ))}
                          <button
                            className='btn btn-sm cyberbtnallpage'
                            type='button'
                            onClick={handleAddMoreCertification}
                          >
                            Add More
                          </button>
                        </div>
                        <div className='mt-10 bg-light p-3'>
                          <label class='form-label'>Typical Annual Salary</label>
                          {salaries.map((salary, index) => (
                            <div
                              className='d-flex align-items-center justify-content-center mb-5'
                              key={index}
                            >
                              <CountryFlag
                                countryCode={salary.country ? salary.country : 'GB'}
                                svg
                                style={{marginRight: '10px', width: '40px', height: '40px'}}
                                title={salary.country}
                              />
                              <select
                                className='form-select'
                                value={salary.country}
                                onChange={(e) =>
                                  handleSalaryChange(index, 'country', e.target.value)
                                }
                                style={{width: '200px', marginRight: '10px'}}
                              >
                                <option value='' disabled>
                                  Select Country
                                </option>
                                {countries.map((country) => (
                                  <option key={country.code} value={country.code}>
                                    {country.name}
                                  </option>
                                ))}
                              </select>

                              <input
                                className='form-control'
                                type='text'
                                placeholder='Amount'
                                value={salary.amount}
                                onChange={(e) =>
                                  handleSalaryChange(index, 'amount', e.target.value)
                                }
                                style={{marginRight: '10px'}}
                                // onKeyPress={(e) => {
                                //   const isNumeric = /^[0-9]$/
                                //   if (!isNumeric.test(e.key)) {
                                //     e.preventDefault()
                                //   }
                                // }}
                              />
                              {index !== 0 && (
                                <button
                                  className='btn btn-danger'
                                  type='button'
                                  onClick={() => handleRemoveSalary(index)}
                                >
                                  {/* <FaTrash /> */}
                                  Remove
                                </button>
                              )}
                            </div>
                          ))}
                          <button
                            className='btn btn-sm cyberbtnallpage'
                            type='button'
                            onClick={handleAddMoreSalary}
                          >
                            Add More
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class='d-flex justify-content-end'>
                <Link
                  to='/allWebCareers'
                  id='kt_ecommerce_add_product_cancel'
                  class='btn cybercancelbtn me-5'
                >
                  Cancel
                </Link>

                <button
                  type='submit'
                  id='kt_ecommerce_add_product_submit'
                  className='btn cyberbtnallpage'
                >
                  <span class='indicator-label'>Save Changes</span>
                  <span class='indicator-progress'>
                    Please wait...
                    <span class='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddCareer
