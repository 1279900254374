import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import './Careers.css'
// import {GetAllPublishedCareersAction} from 'path-to-your-actions' // Import your action creator

import {GetAllPublishedCareersAction} from '../../Redux/Action/careerAction'
import CountryFlag from 'react-country-flag'
import {Country} from 'country-state-city'
import {Link, useNavigate} from 'react-router-dom'
import careerpath from '../../PrivateImage/bundleimg/careerpath.png'

import bundlesq from '../../PrivateImage/bundleimg/coursebund.png'
import selfpac from '../../PrivateImage/bundleimg/selfpac.png'
import bootcamp from '../../PrivateImage/bundleimg/bootcamp.png'

import time from '../../PrivateImage/img/time.svg'
import badge from '../../PrivateImage/img/badge.svg'

import ChallengeLabs from '../../PrivateImage/img/ChallengeLabs.svg'
import Comptia from '../../PrivateImage/img/Comptia.svg'
import Mentoring from '../../PrivateImage/img/Mentoring.svg'
import ExamVoucher from '../../PrivateImage/img/ExamVoucher.svg'
import {FaAngleDown} from 'react-icons/fa6'
import Flickity from 'react-flickity-component'
import 'flickity/css/flickity.css'

import {AiOutlineLeft, AiOutlineRight} from 'react-icons/ai'
const CircleContent = () => {
  const [activeCircle, setActiveCircle] = useState(1)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const allcareers = useSelector((state) => state.login.getAllPublishedCareerslist)
  const circles = allcareers.careers || []

  // console.log(circles, 'circlescirclescirclescirclescirclescirclescircles')

  useEffect(() => {
    dispatch(GetAllPublishedCareersAction())
  }, [dispatch])

  const handleCircleClick = (index) => {
    setActiveCircle(index)
  }

  // Define the default country code
  const defaultCountryCode = 'US' // Replace 'US' with your desired default country code

  // Set the default selectedCountry and selectedAmount states
  const [selectedCountry, setSelectedCountry] = useState(
    localStorage.getItem('selectedCountry') || defaultCountryCode
  )
  const [selectedAmount, setSelectedAmount] = useState(
    localStorage.getItem('selectedAmount') || ''
  )

  // Update the selected country and amount when needed
  const handleCountryChange = (event) => {
    const newCountry = event.target.value
    setSelectedCountry(newCountry)

    // Fetch the corresponding amount and update localStorage
    const newAmount =
      circles[activeCircle]?.salaries.find((salary) => salary.country === newCountry)?.amount ||
      null
    setSelectedAmount(newAmount)
    localStorage.setItem('selectedCountry', newCountry)
    localStorage.setItem('selectedAmount', newAmount)
  }

  // Fetch and set the initial amount based on the initially selected country
  useEffect(() => {
    const initialAmount =
      circles[activeCircle]?.salaries.find((salary) => salary.country === selectedCountry)
        ?.amount || null
    setSelectedAmount(initialAmount)
    localStorage.setItem('selectedAmount', initialAmount)
  }, [activeCircle, selectedCountry, circles])

  // const courses = circles[activeCircle]?.courses

  const courses = circles[activeCircle]?.courses || []
  // console.log('Coursesddddddddddddddddddddddddddddd for Active Circle:', courses)

  // console.log(
  //   'All Courses:',
  //   circles.map((circle) => circle.courses)
  // )
  // console.log('Active Circle Courses:', courses)

  const careerPaths = circles[activeCircle]?.careerpaths || []

  // console.log(careerPaths, 'kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkyugujhgbujkuhnk')

  // Check if there is at least one data point

  const positions = [
    {top: '73%', left: '14%'},
    {top: '70%', left: '24%'},
    {top: '65%', left: '34%'},
    {top: '73%', left: '47%'},
    {top: '93%', left: '59%'},
    {top: '79%', left: '74%'},
    {top: '28%', left: '88%'},
  ]

  // console.log(positions, 'kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk')
  const [currentPage, setCurrentPage] = useState(1)
  const recordsPerPage = 9
  const lastIndex = currentPage * recordsPerPage
  const firstIndex = lastIndex - recordsPerPage
  const currentRecords = courses.slice(firstIndex, lastIndex)

  const nPage = Math.ceil(courses.length / recordsPerPage)
  const [selectedRole, setSelectedRole] = useState('');

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedRole(selectedValue);
    setActiveCircle(selectedValue)
    // Add any additional logic or actions you want to perform on change
    // For example, you can call a function, update state, etc.
    // Your additional logic here...
  };
  const nextPage = () => {
    if (currentPage < nPage) {
      setCurrentPage(currentPage + 1)
    }
  }

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const dynamicContentRef = useRef(null)

  useEffect(() => {
    // Scroll to the corresponding Dynamic-Content section when activeCircle changes
    if (dynamicContentRef.current) {
      dynamicContentRef.current.scrollIntoView({behavior: 'smooth',  block: 'center',  inline: 'start'})
    }
  }, [activeCircle])

  const tabCount = circles[activeCircle]?.tabs.length || 1

  const flickityRef = useRef(null)

  useEffect(() => {
    flickityRef.current = new Flickity('.your-slider-container', {
      contain: true,
      wrapAround: true,
      // Add other Flickity options as needed
    })
  }, [])

  const determineCategory = (category) => {
    switch (category) {
      case 1:
        return 'JuniorRoles'
      case 2:
        return 'MidRoles'
      case 3:
        return 'SeniorExecRoles'
      default:
        return 'junior'
    }
  }
  const getOffsetHeight = (content) => {
    const tempDiv = document.createElement('div')
    tempDiv.innerHTML = content
    document.body.appendChild(tempDiv)
    const height = tempDiv.offsetHeight
    document.body.removeChild(tempDiv)
    return height
  }

  const categoryClasses = {
    JuniorRoles: 'careers-circlebluesmall',
    MidRoles: 'careers-circleorange',
    SeniorExecRoles: 'careers-circlegreysmall',
  }
  return (
    <div>
      <div className='Careers-section '>
        <span style={{textAlign: 'center'}}>Unlock a wide spectrum of career opportunities</span>
      </div>
      <div className='Careers-dis'>
        <p>
          Cyberheads can introduce you to the untapped potential of the cyber and digital security
          job market. Every year as the industry evolves, it creates more roles & more space for
          professionals to upskill themselves and find their niche. Here’s a look at all the career
          progressions you could choose from.
        </p>
      </div>
      <div className='swipe_careers'>
        <span className="mb-3" style={{ font: 'normal normal normal 16px/28px Sora'}}>Select your role and find out more</span>
      </div>
      <div className='careers-dropdown 	d-md-none d-sm-block d-xs-block'>
        <select className="form-select w-100" value={selectedRole} onChange={handleChange}>
          <option value=''>Select a role</option>
          {circles.map((circle, index) => (
            <option key={index} value={index}>
              {circle.title}
            </option>
          ))}
        </select>
      </div>
      <div className='scrollable-container d-none d-md-block d-sm-none d-xs-none' style={{overflowX: 'auto'}} >
        <div className='circleoverflow' style={{width: '100vw'}}>
          <div className='circles-row d-flex justify-content-center align-items-center'>
            {circles.slice(0, 1).map((circle, index) => {
              const category = determineCategory(circle.category)

              return (
                <div className='d-flex justify-content-center align-items-center' key={index}>
                  <div className='careers-circlewhitesmall'></div>
                  <div
                    className={`careers-circle ${categoryClasses[category]} ${
                      activeCircle === index ? 'active-circle' : ''
                    }`}
                    onClick={() => handleCircleClick(index)}
                    style={{pointerEvents: index % 0 === 0 ? 'none' : 'auto'}}
                  >
                    <div className='d-flex align-items-center' style={{flexDirection: 'column'}}>
                      <span className='dynamictitle-careers'>{circle.title}</span>
                      {activeCircle === index && (
                        <FaAngleDown className='arrow-down' style={{fontSize: '30px'}} />
                      )}
                    </div>
                  </div>
                  <div className='careers-circlewhitesmall'></div>
                </div>
              )
            })}
          </div>

          <div className='circles-row d-flex justify-content-center align-items-center'>
            {circles.slice(1, 6).map((circle, index) => {
              const category = determineCategory(circle.category)

              return (
                <div
                  key={index}
                  className={`careers-circle ${categoryClasses[category]} ${
                    activeCircle === index + 1 ? 'active-circle' : ''
                  }`}
                  onClick={() => handleCircleClick(index + 1)}
                >
                  <div className='d-flex align-items-center' style={{flexDirection: 'column'}}>
                    <span className='dynamictitle-careers'>{circle.title}</span>
                    {activeCircle === index + 1 && (
                      <FaAngleDown className='arrow-down' style={{fontSize: '30px'}} />
                    )}
                  </div>
                </div>
              )
            })}
          </div>

          <div className='circles-row d-flex justify-content-center align-items-center'>
            {circles.slice(6, 13).map((circle, index) => {
              const category = determineCategory(circle.category)
              let sizeClass = ''

              if (index === 0 || index === 6) {
                sizeClass = 'circle-xs'
              } else if (index === 1 || index === 5) {
                sizeClass = 'circle-sm'
              } else if (index === 2 || index === 4) {
                sizeClass = 'circle-lg'
              } else {
                sizeClass = 'circle-md'
              }

              return (
                <div
                  key={index + 6}
                  className={`careers-circle ${categoryClasses[category]} ${sizeClass}  ${
                    activeCircle === index + 6 ? 'active-circle' : ''
                  }`}
                  onClick={() => handleCircleClick(index + 6)}
                >
                  <div className='d-flex align-items-center' style={{flexDirection: 'column'}}>
                    <span className='dynamictitle-careers'>{circle.title}</span>
                    {activeCircle === index + 6 && (
                      <FaAngleDown className='arrow-down' style={{fontSize: '30px'}} />
                    )}
                  </div>
                </div>
              )
            })}
          </div>

          <div className='circles-row d-flex justify-content-center align-items-center'>
            <div className='careers-circlewhitesmall'></div>
            {circles.slice(13, 18).map((circle, index) => {
              const category = determineCategory(circle.category)
              let sizeClass = ''
              if (index === 0 || index === 4) {
                sizeClass = 'circle-sm'
              } else if (index === 1 || index === 3) {
                sizeClass = 'circle-lg'
              } else {
                sizeClass = 'circle-md'
              }
              return (
                <div
                  key={index + 13}
                  className={`careers-circle ${categoryClasses[category]}  ${sizeClass} ${
                    activeCircle === index + 13 ? 'active-circle' : ''
                  }`}
                  onClick={() => handleCircleClick(index + 13)}
                >
                  <div className='d-flex align-items-center' style={{flexDirection: 'column'}}>
                    <span className='dynamictitle-careers'>{circle.title}</span>
                    {activeCircle === index + 13 && (
                      <FaAngleDown className='arrow-down' style={{fontSize: '30px'}} />
                    )}
                  </div>
                </div>
              )
            })}
            <div className='careers-circlewhitesmall'></div>
          </div>

          <div className='circles-row d-flex justify-content-center align-items-center'>
            {circles.slice(18, 25).map((circle, index) => {
              const category = determineCategory(circle.category)
              let sizeClass = ''
              if (index === 0 || index === 6) {
                sizeClass = 'circle-xs'
              } else if (index === 1 || index === 5) {
                sizeClass = 'circle-sm'
              } else if (index === 2 || index === 4) {
                sizeClass = 'circle-lg'
              } else {
                sizeClass = 'circle-md'
              }
              return (
                <div
                  key={index + 18}
                  className={`careers-circle ${categoryClasses[category]} ${sizeClass} ${
                    activeCircle === index + 18 ? 'active-circle' : ''
                  }`}
                  onClick={() => handleCircleClick(index + 18)}
                >
                  <div className='d-flex align-items-center' style={{flexDirection: 'column'}}>
                    <span className='dynamictitle-careers'>{circle.title}</span>
                    {activeCircle === index + 18 && (
                      <FaAngleDown className='arrow-down' style={{fontSize: '30px'}} />
                    )}
                  </div>
                </div>
              )
            })}
          </div>

          {circles.length > 25 && (
            <div className='circles-row d-flex justify-content-center align-items-center'>
              <div className='careers-circlewhitesmall'></div>
              {circles.slice(25, 30).map((circle, index) => {
                const category = determineCategory(circle.category)
                let sizeClass = ''
                if (index === 0 || index === 4) {
                  sizeClass = 'circle-xs'
                } else if (index === 1 || index === 3) {
                  sizeClass = 'circle-sm'
                } else {
                  sizeClass = 'circle-lg'
                }
                return (
                  <div
                    key={index + 25}
                    className={`careers-circle ${categoryClasses[category]} ${sizeClass}  ${
                      activeCircle === index + 25 ? 'active-circle' : ''
                    }`}
                    onClick={() => handleCircleClick(index + 25)}
                  >
                    <div className='d-flex align-items-center' style={{flexDirection: 'column'}}>
                      <span className='dynamictitle-careers'>{circle.title}</span>
                      {activeCircle === index + 25 && (
                        <FaAngleDown className='arrow-down' style={{fontSize: '30px'}} />
                      )}
                    </div>
                  </div>
                )
              })}
              <div className='careers-circlewhitesmall'></div>
            </div>
          )}
        </div>
      </div>

      <div className='Dynamic-Content pt-20' ref={dynamicContentRef}>
        <p className='text-center' style={{fontSize: '38px'}}>
          {circles[activeCircle]?.title}
        </p>
      </div>

      <section className='careers-tab pt-50'>
        <div className='container'>
          <nav>
            <div
              className='nav nav-tabs d-flex justify-content-around '
              id='nav-tab'
              role='tablist'
              style={{'--tab-count': tabCount}}
            >
              {circles[activeCircle]?.tabs.map((tab, index) => (
                <button
                  key={index}
                  className={`nav-link ${index === 0 ? 'active' : ''}`}
                  data-bs-toggle='tab'
                  data-bs-target={`#nav-${index}`}
                  type='button'
                  role='tab'
                  aria-controls={`nav-${index}`}
                  aria-selected={index === 0 ? 'true' : 'false'}
                >
                  {tab.title}
                </button>
              ))}
            </div>
          </nav>
        </div>
        <div className='hrlincareer row'>
          <div className='col-lg-12 col-md-8' style={{borderBottom: '1px solid grey'}}></div>
        </div>

        <div
          className='tab_bankgound_class'
          style={{
            background: '#F7F7F7',
            minHeight: `calc(${circles[activeCircle]?.tabs.reduce(
              (acc, tab) => acc + getOffsetHeight(tab.content),
              0
            )}px + 10rem)`,
          }}
        >
          <div className='tab-content pt-10' id='nav-tabContent'>
            {circles[activeCircle]?.tabs.map((tab, index) => (
              <div
                key={index}
                className={`tab-pane fade ${index === 0 ? 'active show' : ''} container`}
                id={`nav-${index}`}
                role='tabpanel'
                aria-labelledby={`nav-${index}-tab`}
              >
                <div
                  className='career-pargraph'
                  ref={(el) => el && (el.style.height = 'auto')}
                  dangerouslySetInnerHTML={{__html: tab.content}}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* {/ TYPICAL SALARY COMMON JOB TITLES sections /} */}
      <div>
        <div style={{background: 'white'}}>
          <div className=' '>
            <div
              className=''
              style={{
                display: 'flex',
                paddingBottom: '5rem',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div>
                <div className='TypicalSalary' style={{display: 'flex', gap: '5rem'}}>
                  <p className='text-start' style={{fontSize: '20px', fontWeight: '600'}}>
                    TYPICAL SALARY
                  </p>

                  <div className='careers_pagecoutry'>
                    <div className='d-flex align-items-center justify-content-center mb-5'>
                      <CountryFlag
                        countryCode={selectedCountry || 'GB'}
                        svg
                        style={{marginRight: '10px', width: '40px', height: '40px'}}
                        title={selectedCountry}
                      />
                      <select
                        className='form-select country_select'
                        value={selectedCountry}
                        onChange={handleCountryChange}
                        style={{}}
                      >
                        {circles[activeCircle]?.salaries.map((salary, index) => (
                          <option key={index} value={salary.country}>
                            {Country.getCountryByCode(salary.country)?.name || salary.country}
                          </option>
                        ))}
                      </select>
                    </div>

                    {/* Always display the selected amount, regardless of whether a country is selected */}
                    <div className='digit'>
                      <p className='digit_pargarph'>
                        {localStorage.getItem('selectedAmount') !== 'null'
                          ? localStorage.getItem('selectedAmount')
                          : ''}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p
              className='career-digit '
              style={{font: 'normal normal normal 18px/22px Sora', color: '#707070'}}
            >
              *The salary can vary depending on several factors, such as the size of the
              organization, location, level of experience, & the specific responsibilities of the
              role.
            </p>
          </div>
        </div>
        <div style={{background: '#F7F7F7', paddingBottom: '13rem'}}>
          <div className='container'>
            <div className='row pt-20'>
              <div className='col-md-4'>
                <span className='career-title'>COMMON JOB TITLES</span>
                {circles[activeCircle]?.jobs.map((job, index) => (
                  <ul className='careersul' key={index}>
                    <li className='careersli'>{job}</li>
                    <br />
                  </ul>
                ))}
              </div>

              <div className='col-md-4'>
                <span className='career-title'>REQUESTED CERTIFICATIONS</span>
                {circles[activeCircle]?.certifications.map((certification, index) => (
                  <ul className='careersul' key={index}>
                    <li className='careersli'>
                      {' '}
                      <span
                        style={{width: '100%'}}
                        dangerouslySetInnerHTML={{__html: certification.trim()}}
                      />{' '}
                    </li>
                    <br />
                  </ul>
                ))}
              </div>
              <div className='col-md-4'>
                <span className='career-title'>TOP SKILLS REQUIRED</span>
                {circles[activeCircle]?.skills.map((skill, index) => (
                  <ul className='careersul' key={index}>
                    <li className='careersli'>{skill}</li>
                    <br />
                  </ul>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {/ SECURITY CAREER PATH /} */}

      <div className='pt-20'>
        <div className='security-careerspath'>
          <p className='text-center pb-10' style={{fontSize: '38px'}}>
            SECURITY CAREER PATH
          </p>
        </div>
        <div className='career_pathline' style={{width: '100vw', overflowX: 'auto'}}>
          <div className='career-path' style={{position: 'relative'}}>
            <img className='zigzag-line' src={careerpath} alt='img' />

            {careerPaths.map((careerpath, index) => (
              <div
                key={index}
                className={`blackcircle blackcircle${index + 1}`}
                style={{
                  position: 'absolute',
                  top: positions[index]?.top,
                  left: positions[index]?.left,
                }}
              >
                <h3 className='subtitle' style={{marginTop: index % 2 === 0 ? '26px' : '-55px'}}>
                  {careerpath}
                </h3>
              </div>
            ))}
          </div>
        </div>

        <div className='swipe_careers'>
          <span style={{paddingTop: '4rem'}}>
            Swipe right to see more <AiOutlineRight />{' '}
          </span>
        </div>
      </div>

      <div className='recomanded_careers'>
        <div className='pt-20'>
          <div className='container'>
            <div className='contentCareers-section2'>
              <p className='Recommended text-center pb-10' style={{fontSize: '38px'}}>
                Recommended Courses
              </p>
            </div>
            <ul className='course-grid'>
              {(currentRecords || []).map((item) => (
                <li className='item' key={item.id}>
                  <div className='wrapper position-relative'>
                    {item.Coursetype === 'Bundles' && (
                      <img
                        src={bundlesq}
                        alt='course'
                        className='position-absolute start-0'
                        style={{width: '170px', top: '20px'}}
                      />
                    )}
                    {item.Coursetype === 'Courses' && (
                      <img
                        src={item.Course_Category === 'CompTIA eLearning' ? selfpac : bootcamp}
                        alt='course'
                        className='position-absolute start-0'
                        style={{width: '170px', top: '20px'}}
                      />
                    )}

                    <div className='image-wrapper'>
                      <img
                        src={item.Coursetype === 'Courses' ? item.image_link : item.ImageLink || ''}
                        alt='course'
                      />
                    </div>

                    <div className='details'>
                      <h5>{item.Coursetype === 'Courses' ? item.title : item.Name || ''}</h5>

                      <div className='list-item'>
                        {' '}
                        <img src={time} alt='time' />
                        {item.duration ? (
                          <div dangerouslySetInnerHTML={{__html: item.duration}} />
                        ) : (
                          'NA'
                        )}
                      </div>
                      {item.Coursetype === 'Bundles' && (
                        <>
                          {item.certifications && item.certifications.length > 0 && (
                            <div>
                              {item.certifications.split('|').map((certification, index) => (
                                <div key={index} className='list-item'>
                                  <img src={badge} alt='certification' />{' '}
                                  <span
                                    style={{width: '100%'}}
                                    dangerouslySetInnerHTML={{__html: certification?.trim()}}
                                  />
                                </div>
                              ))}
                            </div>
                          )}

                          <div className='list-item'>
                            <img src={Comptia} alt='certification' /> CompTIA Labs, Learn & Practice
                          </div>
                          <div className='list-item'>
                            <img src={ExamVoucher} alt='certification' /> CompTIA Exam Voucher
                          </div>
                          <div className='list-item'>
                            <img src={ChallengeLabs} alt='certification' /> Skillable Challenge Labs
                          </div>
                          <div className='list-item'>
                            <img src={Mentoring} alt='certification' /> Cyberheads Mentoring
                          </div>
                        </>
                      )}

                      {parseInt(item.type) === 2 && (
                        <div className='list-item'>
                          <img src={badge} alt='badge' />

                          {(() => {
                            const trimmedCertifications = item.certifications
                            return (
                              <span
                                style={{width: '100%'}}
                                dangerouslySetInnerHTML={{__html: trimmedCertifications.trim()}}
                              />
                            )
                          })()}
                        </div>
                      )}

                      {parseInt(item.type) === 1 && (
                        <>
                          {(() => {
                            const trimmedCertifications = item.certifications
                              ? item.certifications.trim()
                              : ''

                            return (
                              <>
                                <div className='list-item'>
                                  <img src={badge} alt='badge' />{' '}
                                  <span
                                    style={{width: '100%'}}
                                    dangerouslySetInnerHTML={{
                                      __html: trimmedCertifications.trim(),
                                    }}
                                  />
                                </div>
                                <div className='list-item'>
                                  <img src={Comptia} alt='bolt' /> CompTIA Labs, Learn & Practice
                                </div>
                                <div className='list-item'>
                                  <img src={ExamVoucher} alt='bolt' /> CompTIA Exam Voucher
                                </div>
                              </>
                            )
                          })()}
                        </>
                      )}

                      {parseInt(item.type) === 3 && (
                        <>
                          {(() => {
                            const trimmedCertifications = item.certifications
                              ? item.certifications.trim()
                              : ''

                            return (
                              <>
                                <div className='list-item'>
                                  <img src={badge} alt='badge' />
                                  <span
                                    style={{width: '100%'}}
                                    dangerouslySetInnerHTML={{
                                      __html: trimmedCertifications.trim(),
                                    }}
                                  />
                                </div>
                                <div className='list-item'>
                                  <img src={Comptia} alt='bolt' /> CompTIA Labs, Learn & Practice
                                </div>
                                <div className='list-item'>
                                  <img src={ExamVoucher} alt='bolt' /> CompTIA Exam Voucher
                                </div>
                              </>
                            )
                          })()}
                        </>
                      )}
                    </div>
                    <div className='button-group' style={{justifyContent: 'end'}}>
                      {item.Coursetype === 'Courses' && (
                        <button
                          className='button white'
                          style={{border: '0px'}}
                          onClick={() => {
                            if (item.Course_Category === 'CompTIA eLearning') {
                              navigate(
                                `/comptia-learning?/${item.course_code}/${item.courseslug}#learning`
                              )
                            } else if (item.Course_Category === 'CompTIA Bootcamp') {
                              navigate(
                                `/compitia_bootcamp?/${item.course_code}/${item.courseslug}#bootcamp`
                              )
                            } else if (item.Course_Category === 'Microsoft Bootcamp') {
                              navigate(
                                `/microsoft-bootcamp?/${item.course_code}/${item.courseslug}#microsoft`
                              )
                            }
                          }}
                        >
                          VIEW COURSE
                        </button>
                      )}

                      {item.Coursetype === 'Bundles' && (
                        <Link
                          to={`/bundle_details/${item.bundle_slug}/${item.bundle_code}#bundletitle`}
                          className='button white'
                          onClick={() => {
                            localStorage.setItem('paid_price', item.price)
                            localStorage.setItem('Name', item.Name)
                          }}
                        >
                          VIEW COURSE
                        </Link>
                      )}

                      {item.Coursetype === 'Courses' && (
                        <button
                          className='button primary'
                          style={{border: '0px'}}
                          onClick={() => {
                            if (item.Course_Category === 'CompTIA eLearning') {
                              navigate(
                                `/comptia-learning?/${item.course_code}/${item.courseslug}#learning`
                              )
                            } else if (item.Course_Category === 'CompTIA Bootcamp') {
                              navigate(
                                `/compitia_bootcamp?/${item.course_code}/${item.courseslug}#bootcamp`
                              )
                            } else if (item.Course_Category === 'Microsoft Bootcamp') {
                              navigate(
                                `/microsoft-bootcamp?/${item.course_code}/${item.courseslug}#microsoft`
                              )
                            }
                          }}
                        >
                          BUY NOW
                        </button>
                      )}

                      {item.Coursetype === 'Bundles' && (
                        <Link
                          to={`/bundle_details/${item.bundle_slug}/${item.bundle_code}#bundletitle`}
                          className='button primary'
                          onClick={() => {
                            localStorage.setItem('paid_price', item.price)
                            localStorage.setItem('Name', item.Name)
                          }}
                        >
                          BUY NOW
                        </Link>
                      )}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            <nav style={{paddingBottom: '2rem'}}>
              <ul className='pagination'>
                <li className='page-item'>
                  <Link to='#' className='page-link' onClick={prevPage}>
                    <AiOutlineLeft />
                  </Link>
                </li>
                {[...Array(nPage).keys()].map((n, i) => (
                  <li
                    className={`page-item ${currentPage === n + 1 ? 'active' : ''}`}
                    key={i}
                    style={{color: 'orange'}}
                  >
                    <Link to='#' className='page-link' onClick={() => setCurrentPage(n + 1)}>
                      {n + 1}
                    </Link>
                  </li>
                ))}
                <li className='page-item'>
                  <Link to='#' className='page-link' onClick={nextPage}>
                    <AiOutlineRight />
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>

      <div className='Responsive_careers ' style={{paddingTop: '2rem'}}>
        <div className='d-flex'>
          <p style={{fontSize: '30px'}}>Recommended Courses</p>
        </div>
        <Flickity
          className={'your-slider-container'}
          options={{
            contain: true,
            wrapAround: true,
          }}
        >
          {(currentRecords || []).map((item) => (
            <ul className='course-grid'>
              <li className='item' key={item.id}>
                <div className='wrapper position-relative'>
                  {item.Coursetype === 'Bundles' && (
                    <img
                      src={bundlesq}
                      alt='course'
                      className='position-absolute start-0'
                      style={{width: '170px', top: '20px'}}
                    />
                  )}
                  {item.Coursetype === 'Courses' && (
                    <img
                      src={item.category === 'platform' ? selfpac : bootcamp}
                      alt='course'
                      className='position-absolute start-0'
                      style={{width: '170px', top: '20px'}}
                    />
                  )}

                  <div className='image-wrapper'>
                    <img
                      src={item.Coursetype === 'Courses' ? item.image_link : item.ImageLink || ''}
                      alt='course'
                    />
                  </div>

                  <div className='details'>
                    <h5 className='recomanded_course'>
                      {item.Coursetype === 'Courses' ? item.title : item.Name || ''}
                    </h5>

                    <div className='list-item'>
                      {' '}
                      <img src={time} alt='time' />
                      {item.duration ? (
                        <div dangerouslySetInnerHTML={{__html: item.duration}} />
                      ) : (
                        'NA'
                      )}
                    </div>
                    {item.Coursetype === 'Bundles' && (
                      <>
                        {item.certifications && item.certifications.length > 0 && (
                          <div>
                            {item.certifications.split('|').map((certification, index) => (
                              <div key={index} className='list-item'>
                                <img src={badge} alt='certification' />
                                <span
                                  className='recomanded_course'
                                  style={{width: '100%'}}
                                  dangerouslySetInnerHTML={{__html: certification.trim()}}
                                />{' '}
                                {/* {certification.trim()} */}
                              </div>
                            ))}
                          </div>
                        )}
                        <div className='list-item recomanded_course'>
                          <img src={Comptia} alt='certification' /> CompTIA Network+ Certification
                        </div>
                        <div className='list-item recomanded_course'>
                          <img src={ChallengeLabs} alt='certification' /> CompTIA Labs, Learn &
                          Practice
                        </div>
                        <div className='list-item recomanded_course'>
                          <img src={ExamVoucher} alt='certification' /> CompTIA Exam Voucher
                        </div>
                        <div className='list-item recomanded_course'>
                          <img src={ChallengeLabs} alt='certification' /> Skillable Challenge Labs
                        </div>
                        <div className='list-item recomanded_course'>
                          <img src={Mentoring} alt='certification' /> Cyberheads Mentoring
                        </div>
                      </>
                    )}

                    {item.category === 'Microsoft Bootcamp' && (
                      <div className='list-item'>
                        <img src={badge} alt='badge' />

                        {(() => {
                          const trimmedCertifications = item.certifications
                          return (
                            <span
                              style={{width: '100%'}}
                              dangerouslySetInnerHTML={{__html: trimmedCertifications.trim()}}
                            />
                          )
                        })()}
                      </div>
                    )}
                    {item.category === 'comptia' && (
                      <>
                        <div className='list-item'>
                          <img src={Comptia} alt='badge' /> CompTIA Network+ Certification
                        </div>
                        <div className='list-item'>
                          <img src={ChallengeLabs} alt='bolt' /> CompTIA Labs, Learn & Practice
                        </div>
                        <div className='list-item'>
                          <img src={ExamVoucher} alt='bolt' /> CompTIA Exam Voucher
                        </div>
                      </>
                    )}

                    {item.category === 'platform' && (
                      <div>
                        {(() => {
                          const trimmedCertifications = item.certifications
                          return (
                            <>
                              <div className='list-item'>
                                <img src={badge} alt='badge' />{' '}
                                <span
                                  style={{width: '100%'}}
                                  dangerouslySetInnerHTML={{__html: trimmedCertifications.trim()}}
                                />
                              </div>
                              <div className='list-item'>
                                <img src={ChallengeLabs} alt='bolt' /> CompTIA Labs, Learn &
                                Practice
                              </div>
                              <div className='list-item'>
                                <img src={ExamVoucher} alt='bolt' /> CompTIA Exam Vouche
                              </div>
                            </>
                          )
                        })()}
                      </div>
                    )}
                  </div>
                  <div className='button-group' style={{justifyContent: 'end'}}>
                    {item.Coursetype === 'Courses' && (
                      <button
                        style={{border: '0px'}}
                        className='button white'
                        onClick={() => {
                          if (item.category === 'platform') {
                            navigate(
                              `/comptia-learning?/${item.course_code}/${item.courseslug}#learning`
                            )
                          } else if (item.category === 'comptia') {
                            navigate(
                              `/compitia_bootcamp?/${item.course_code}/${item.courseslug}#bootcamp`
                            )
                          } else if (item.category === 'comptia') {
                            navigate(
                              `/microsoft-bootcamp?/${item.course_code}/${item.courseslug}#microsoft`
                            )
                          }
                        }}
                      >
                        VIEW COURSE
                      </button>
                    )}

                    {item.Coursetype === 'Bundles' && (
                      <Link
                        to={`/bundle_details/${item.bundle_slug}/${item.bundle_code}#bundletitle`}
                        className='button white'
                        onClick={() => {
                          localStorage.setItem('paid_price', item.price)
                          localStorage.setItem('Name', item.Name)
                        }}
                      >
                        VIEW COURSE
                      </Link>
                    )}

                    {item.Coursetype === 'Courses' && (
                      <button
                        style={{border: '0px'}}
                        className='button primary'
                        onClick={() => {
                          if (item.category === 'platform') {
                            navigate(
                              `/comptia-learning?/${item.course_code}/${item.courseslug}#learning`
                            )
                          } else if (item.category === 'comptia') {
                            navigate(
                              `/compitia_bootcamp?/${item.course_code}/${item.courseslug}#bootcamp`
                            )
                          } else if (item.category === 'comptia') {
                            navigate(
                              `/microsoft-bootcamp?/${item.course_code}/${item.courseslug}#microsoft`
                            )
                          }
                        }}
                      >
                        BUY NOW
                      </button>
                    )}

                    {item.Coursetype === 'Bundles' && (
                      <Link
                        to={`/bundle_details/${item.bundle_slug}/${item.bundle_code}#bundletitle`}
                        className='button primary'
                        onClick={() => {
                          localStorage.setItem('paid_price', item.price)
                          localStorage.setItem('Name', item.Name)
                        }}
                      >
                        BUY NOW
                      </Link>
                    )}
                  </div>
                </div>
              </li>
            </ul>
          ))}
        </Flickity>
      </div>
      {/* <div>
        <img src={career} alt='img' />
      </div> */}
    </div>
  )
}

export default CircleContent
