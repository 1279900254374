import React, {useEffect, useState} from 'react'

import {useDispatch, useSelector} from 'react-redux'
import {GetAllPublishedTestimonial} from '../../Redux/Action/testimonialAction'
import Groupblu from '../../PrivateImage/bundleimg/Groupblu.png'
import Grouporg from '../../PrivateImage/bundleimg/Grouporg.png'

const Coursetestimonal = () => {
  const dispatch = useDispatch()

  const Testimonials = useSelector((state) => {
    return state.login.getAllPublishedTestimonialslist
  })

  const allTestimonial = Testimonials.careers || []

  useEffect(() => {
    // debugger;
    dispatch(GetAllPublishedTestimonial())
  }, [dispatch])

  return (
    <div>
      <div className='desktop_testimonal'>
        <section class='testimonial-main'>
          <div class='container-fluid '>
            <h3 className='testi-heading'>TESTIMONIALS</h3>
            <h2 style={{fontSize: '3rem', fontWeight: '400'}}>Why Students Love Cyberheads</h2>

            <div id='course-testimonials' class='carousel slide' data-bs-ride='carousel'>
              <ol class='carousel-indicators'>
                {allTestimonial.map((item, index) => {
                  let activeClass = ''
                  if (index === 0) {
                    activeClass = 'active'
                  }
                  return (
                    <button
                      key={index}
                      type='button'
                      data-bs-target='#course-testimonials'
                      data-bs-slide-to={index}
                      class={`carousel-indicator ${activeClass}`}
                      aria-current='true'
                      aria-label={`Slide ${index}`}
                    ></button>
                  )
                })}
              </ol>
              <div class='carousel-inner'>
                {allTestimonial.map((item, index) => {
                  let activeClass = ''
                  if (index === 0) {
                    activeClass = 'active'
                  }
                  return (
                    <div className={`carousel-item ${activeClass}`} key={index}>
                      <h5
                        className='testi-descp'
                        dangerouslySetInnerHTML={{__html: item.long_description}}
                      ></h5>
                      <span className='testi-name w-100'>- {item.name} -</span>
                      <label className='testi-designation w-100'>{item.designation}</label>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className='responsive_testmonalil'>
        <section class='testimonial-main'>
          <div class='container-fluid '>
            <h3 className='testi-heading'>TESTIMONIALS</h3>
            <h2 style={{fontSize: '2rem', fontWeight: '400'}}>Why Students Love Cyberheads</h2>

            <div id='testimonial' class='carousel slide' data-bs-ride='carousel'>
              <ol class='carousel-indicators'>
                {allTestimonial.map((item, index) => {
                  let activeClass = ''
                  if (index === 0) {
                    activeClass = 'active'
                  }
                  return (
                    <button
                      key={index}
                      type='button'
                      data-bs-target='#testimonial'
                      data-bs-slide-to={index}
                      class={`carousel-indicator ${activeClass}`}
                      aria-current='true'
                      aria-label={`Slide ${index}`}
                    ></button>
                  )
                })}
              </ol>
              <div>
                <img className='responsive_testmonalilimgblu' src={Groupblu} alt='img' />
              </div>

              <div class='carousel-inner'>
                {allTestimonial.map((item, index) => {
                  let activeClass = ''
                  if (index === 0) {
                    activeClass = 'active'
                  }
                  return (
                    <div className={`carousel-item ${activeClass}`} key={index}>
                      <h5
                        className='testi-descp'
                        dangerouslySetInnerHTML={{__html: item.long_description}}
                      ></h5>
                      <span className='testi-name w-100'>- {item.name} -</span>
                      <label className='testi-designation w-100'>{item.designation}</label>
                    </div>
                  )
                })}
              </div>

              <div style={{position: 'relative', overflow: 'hidden'}}>
                <img
                  className='responsive_testmonalilorg'
                  src={Grouporg}
                  alt='img'
                  style={{
                    position: 'relative',
                    left: '15rem',

                    top: '-12rem',
                  }}
                />
                {/* Other content in your component */}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Coursetestimonal
