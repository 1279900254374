import React, {useEffect, useState, useRef} from 'react'
import {Container} from 'react-bootstrap'

// import inst from "../../asserts/images/angular.jpg";
import {useDispatch, useSelector} from 'react-redux'
import {
  GetInstructorAvaibilityCoursesDetails,
  GetRangeByInstructorId,
  UpdateStatusAvaibilityByAdmin,
  GetInstructorAvaibilityReservedDetails,
  AddInstructorAvailableDatesAction,
  sendMeetingAction,
} from '../../../Redux/Action/instructorAction'
import {Calendar, DateObject} from 'react-multi-date-picker'

import './custom.css'
import {useParams} from 'react-router-dom'

import {Link} from 'react-router-dom'

const InstructorAvaibility = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [clickedStart, setClickedStart] = useState(null)
  const [clickedEnd, setClickedEnd] = useState(null)
  const [selectedRanges, setSelectedRanges] = useState([])
  const [reservedValues, setReservedValues] = useState([])
  const bookButtonIdRef = useRef(null)

  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const ReservedRange = useSelector((state) => state.login.getaInstructorAvaibilityReservedlist)

  const [values, setValues] = useState([])
  const currentURLRef = useRef(window.location.href)
  const [selectedInstructorId, setSelectedInstructorId] = useState(null)
  const [currentURLS, setCurrentURLS] = useState(null)

  const handleBookButtonClick = (id) => {
    setSelectedInstructorId(id)
    setIsModalOpen(true)
  }
  const courseId = useParams()
  const handleCancel = () => {
    setSelectedInstructorId(null)
    setIsModalOpen(false)
    window.location.reload()
  }
  var newURL = window.location

  var splitURL = newURL.href.split('/')

  //   const courseId = splitURL[4]

  const instid = splitURL[5]

  const dispatch = useDispatch()

  const courseavaibilitydetails = useSelector((state) => {
    return state.login.getaInstructorAvaibilityAdminlist
  })

  useEffect(() => {
    dispatch(GetInstructorAvaibilityCoursesDetails(courseId))
  }, [dispatch])

  const message = courseavaibilitydetails.message || []

  const data = message.map((entry) => entry)
  
  useEffect(() => {
    dispatch(GetRangeByInstructorId(selectedInstructorId))
      .then(() => setIsDataLoaded(true))
      .catch((error) => {
        setIsDataLoaded(true)
      })
  }, [dispatch, selectedInstructorId])

  useEffect(() => {
    dispatch(GetInstructorAvaibilityReservedDetails(selectedInstructorId))
      .then(() => setIsDataLoaded(true))
      .catch((error) => {
        setIsDataLoaded(true)
      })
  }, [dispatch])

  const Range = useSelector((state) => state.login.getallRangelist)

  useEffect(() => {
    if (Range.data && Array.isArray(Range.data)) {
      const startDateArray = Range.data.map((item) => item.start_date)
      const endDateArray = Range.data.map((item) => item.end_date)

      const dynamicStartDate = startDateArray.map((date) =>
        new DateObject(date).format('YYYY/MM/DD')
      )

      const dynamicEndDate = endDateArray.map((date) => new DateObject(date).format('YYYY/MM/DD'))

      const inService = dynamicStartDate.map((startDate, index) => [
        startDate,
        dynamicEndDate[index],
      ])

      setValues((prevValues) => [...prevValues, ...inService])
    }
  }, [Range.data])

  useEffect(() => {
    if (ReservedRange.data && Array.isArray(ReservedRange.data)) {
      const startDateArray = ReservedRange.data.map((item) => item.start_date)
      const endDateArray = ReservedRange.data.map((item) => item.end_date)

      const dynamicReservedStartDate = startDateArray.map((date) =>
        new DateObject(date).format('YYYY/MM/DD')
      )

      const dynamicReservedEndDate = endDateArray.map((date) =>
        new DateObject(date).format('YYYY/MM/DD')
      )

      const reserved = dynamicReservedStartDate.map((startDate, index) => [
        startDate,
        dynamicReservedEndDate[index],
      ])

      setReservedValues((prevReservedValues) => [...prevReservedValues, ...reserved])
    }
  }, [ReservedRange.data])

  const currentDate = new Date().toISOString().split('T')[0]
  const expiary = new Date(
    new Date(currentDate).getFullYear(),
    new Date(currentDate).getMonth() + 6,
    new Date(currentDate).getDate()
  )
    .toISOString()
    .slice(0, 10)

  const disabledDates = Array.from({length: 31}, (_, index) => {
    const date = new Date()
    date.setDate(date.getDate() - index - 1)
    return new DateObject(date)
  })

  const [selectedItemIds, setSelectedItemIds] = useState([])

  const handleChange = (id) => {
    setSelectedItemIds((prevSelectedItems) => {
      if (prevSelectedItems.includes(id)) {
        return prevSelectedItems.filter((itemId) => itemId !== id)
      } else {
        return [...prevSelectedItems, id]
      }
    })
  }

  const onSubmit = () => {
    if (clickedStart && clickedEnd) {
      const formData = {
        start_date: [],
        end_date: [],
        instructor_id: selectedInstructorId,
        Course_id: courseId,
      }

      const startDateObject = new DateObject(clickedStart)
      const endDateObject = new DateObject(clickedEnd)

      formData.start_date.push(startDateObject.format('YYYY-MM-DD'))
      formData.end_date.push(endDateObject.format('YYYY-MM-DD'))

      dispatch(AddInstructorAvailableDatesAction(formData, instid))
    }
  }

  const [formvalue, setFormValue] = useState('')
  const [forvaluefield, setFormValueField] = useState('')
  const [isModalOpenmetting, setIsModalOpenmetting] = useState(false)
  const [meetingId, setMeetingId] = useState(null)
  const updateMeeting = () => {
    //debugger
    const formData = new FormData()

    formData.append('meetingLink', formvalue)
    dispatch(sendMeetingAction(formData, meetingId))
  }

  const handlemeetingClick = (id) => {
    setMeetingId(id.id)
    setFormValueField(id.meetingLink)
    setIsModalOpenmetting(true)
  }

  const handlemeetingCancel = () => {
    setMeetingId(null)
    setIsModalOpenmetting(false)
    // window.location.reload()
  }

  useEffect(() => {
    setFormValue(forvaluefield)
  }, [forvaluefield])
  return (
    <div>
      <div>
        <li className='loream' style={{listStyle: 'none'}}>
          <div class='circle blue'></div>
          <span class='unbooked' style={{fontWeight: '700'}}>
            Available For Training -
          </span>
          The dates instructors are available for training
        </li>
        <li className='loream' style={{listStyle: 'none'}}>
          {' '}
          <div class='circle grey'></div>
          <span class='greyyy' style={{fontWeight: '700'}}>
            Unavailable For Training -
          </span>
          The grey dates
        </li>

        <li className='loream' style={{listStyle: 'none'}}>
          <div class='circle green'></div>
          <span class='booked' style={{fontWeight: '700'}}>
            Booked For Training -
          </span>{' '}
          The dates allocated/agreed for instructors to offer training
        </li>
      </div>

      <div
        className='rmdp-wrapper rmdp-shadow custom-calendar'
        style={{
          zIndex: 0,
          marginLeft: '0px',
          fontSize: '30px',
        }}
      >
        <>
          <div
            className='rmdp-month-name rmdp-header'
            style={{
              fontSize: '30px',
              padding: '0px',
              margin: '0px',
            }}
          >
            
          </div>
        </>
      </div>

      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            paddingTop: '2rem',
            gap: '1rem',
          }}
        >
          <button
            className='btn cybercancelbtn me-2'
            style={{alignItems: 'center'}}
            onClick={handleCancel}
          >
            Cancel
          </button>

          <button className='btn cyberbtnallpage' style={{alignItems: 'center'}} onClick={onSubmit}>
            Book Avaibility
          </button>
        </div>
      </div>
    </div>
  )
}

export default InstructorAvaibility
