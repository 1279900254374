import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

import {useDispatch, useSelector} from 'react-redux'
import AddIcon from '@mui/icons-material/Add'
import {useNavigate} from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown'
import {FaEdit, FaTrash, FaPlus} from 'react-icons/fa'
import {Tooltip} from 'antd'
import {GetAllLtiToolsAction, LTIDeleteAction} from '../../../Redux/Action/ltiToolsAction'
import {FilterMatchMode} from 'primereact/api'
import {MultiSelect} from 'primereact/multiselect'
import {Toolbar} from 'primereact/toolbar'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'

export default function AllTools() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [filters, setFilters] = useState({
    global: {value: null, matchMode: FilterMatchMode.CONTAINS},
    name: {value: null, matchMode: FilterMatchMode.CONTAINS},
  })

  const columns = [
    {field: 'name', header: 'Name'},
  ]

  const onColumnToggle = (event) => {
    let selectedColumns = event.value
    let orderedSelectedColumns = columns.filter((col) =>
      selectedColumns.some((sCol) => sCol.field === col.field)
    )

    setVisibleColumns(orderedSelectedColumns)
  }

  const [loading, setLoading] = useState(true)
  const [globalFilterValue, setGlobalFilterValue] = useState('')
  const [visibleColumns, setVisibleColumns] = useState(columns)

  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    let _filters = {...filters}

    _filters['global'].value = value

    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const tools = useSelector((state) => {
    const Alltools = state.login.ltitoolslist || []
    const toolData = Alltools.map((item, index) => ({
      ...item,
      SNo: index + 1,
    }))

    return toolData
  })

  console.log(tools,"toolsstoolss")

  useEffect(() => {
    dispatch(GetAllLtiToolsAction()).then(() => {
      setLoading(false)
    })
  }, [dispatch])

  const deleteHandler = (props) => {
    dispatch(LTIDeleteAction(props))
  }

  const rightToolbarTemplate = () => {
    return (
      <div class='card-toolbar'>
        <div class='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
          <Link to='/create_tools' type='button' class='btn cyberbtnallpage'>
            <AddIcon /> Add Tool
          </Link>
        </div>
      </div>
    )
  }

  const leftToolbarTemplate = () => {
    return (
      <MultiSelect
        value={visibleColumns}
        options={columns}
        optionLabel='header'
        onChange={onColumnToggle}
        className='w-full sm:w-20rem'
        display='chip'
      />
    )
  }

  const actionTemplate = (rowData) => {
    return (
      <div style={{display: 'flex', gap: '0px', marginLeft: '-2rem'}}>
        <div style={{fontSize: '15px'}}>
          <Tooltip title='Edit'>
            <button
              onClick={() => navigate(`/edit_lti/${rowData.id}`)}
              style={{
                border: 'none',
                backgroundColor: 'white', // Set the background color to white
              }}
            >
              <span
                style={{
                  fontSize: '18px',
                  paddingRight: '5px',
                  color: 'rgb(126, 130, 153)',
                }}
              >
                <FaEdit />
              </span>
            </button>
          </Tooltip>

          <Tooltip title='Delete'>
            <Link to='#' onClick={() => deleteHandler(rowData.id)}>
              <span
                style={{
                  fontSize: '18px',
                  paddingRight: '5px',
                  color: 'rgb(126, 130, 153)',
                }}
              >
                <FaTrash />
              </span>
            </Link>
          </Tooltip>
        </div>
      </div>
    )
  }


  return (
    <div>
      <Toolbar className='mb-4' left={leftToolbarTemplate} right={rightToolbarTemplate} />
      <DataTable
        value={tools}
        paginator
        rows={20}
        tableStyle={{minWidth: '100%'}}
        dataKey='id'
        filters={filters}
        filterDisplay='row'
        className='card'
        globalFilterFields={['name']}
        emptyMessage='No Tools found.'
        rowClassName='table-row'
      >
        <Column field='SNo' header='#' sortable />
        {visibleColumns.map((col) => (
          <Column
            sortable
            key={col.field}
            field={col.field}
            header={col.header}
            filter
            style={{minWidth: '15rem'}}
            filterPlaceholder={`Search by ${col.header}`}
          />
        ))}
        <Column
          field='url'
          header='URL'
          body={(rowData) => rowData.url.slice(0, 125)}
          style={{minWidth: '15rem'}}
          //filterPlaceholder='Search by Category'
        />
        <Column
          field='ltiversion'
          header='LTI Version'
          filterPlaceholder='Search by Version'
          style={{minWidth: '12rem'}}
        />
        <Column
          field='clientID'
          header='Client Id'
          filterPlaceholder='Search by Id'
          style={{minWidth: '12rem'}}
        />
        <Column
          field='id'
          header='Action'
          body={actionTemplate}
          exportable={false}
          style={{marginLeft: '-12rem'}}
        />
      </DataTable>
    </div>
  )
}

// const AllTools = () => {
//   const dispatch = useDispatch()
//   const navigate = useNavigate()

//   const tools = useSelector((state) => {
//     return state.login.ltitoolslist
//   })

//   useEffect(() => {
//     // debugger;
//     dispatch(GetAllLtiToolsAction())
//   }, [dispatch])

//   const deletehandler = (props) => {
//     dispatch(LTIDeleteAction(props))
//   }

//   function nextPage() {
//     if (currentPage !== nPage) {
//       setCurrentPage(currentPage + 1)
//     }
//   }

//   function prePage() {
//     if (currentPage !== 1) {
//       setCurrentPage(currentPage - 1)
//     }
//   }

//   function changeCPage(id) {
//     setCurrentPage(id)
//   }

//   const [currentPage, setCurrentPage] = useState(1)

//   const recordsPerPage = 10

//   const lastIndex = currentPage * recordsPerPage

//   const firstIndex = lastIndex - recordsPerPage

//   const records = tools.slice(firstIndex, lastIndex)

//   const nPage = Math.ceil(tools.length / recordsPerPage)

//   const number = [...Array(nPage + 1).keys()].slice(1)

//   return (
//     <div>
//       <div className='purchase-history'>
//         <div className='card'>
//           <div className='card-header border-0 pt-6'>
//             <div className='card-title'>
//               <h3 className='fw-bold m-0'>Tools List</h3>
//             </div>
//             <div class='card-toolbar'>
//               <div class='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
//                 <Link to='/create_tools' type='button' class='btn cyberbtnallpage'>
//                   <AddIcon /> Add Tool
//                 </Link>
//               </div>
//             </div>
//           </div>

//           <div className='tab-content'>
//             <div className='table-responsive'>
//               <table className='table table-row-bordered align-middle gy-4 gs-9'>
//                 <thead className='border-bottom border-gray-200 fs-6 text-gray-600 fw-bold bg-light bg-opacity-75'>
//                   <tr>
//                     <td className='min-w-150px'>#</td>
//                     <td className='min-w-250px'>Name</td>
//                     <td className='min-w-150px'>URL</td>
//                     <td className='min-w-150px'>LTI Version</td>
//                     {/* <td className='min-w-150px'>Pulickey Set</td> */}
//                     <td className='min-w-150px'>Client ID</td>
//                     <td className='min-w-150px'>Action</td>
//                   </tr>
//                 </thead>
//                 <tbody className='fw-semibold text-gray-600'>
//                   {records.map((item, index) => {
//                     return (
//                       <tr>
//                         <td>{firstIndex + index + 1}</td>

//                         <td>{item.name}</td>
//                         <td>{item.url}</td>
//                         <td>{item.ltiversion}</td>
//                         {/* <td style={{width:"50%"}}>{item.pulickeyset}</td> */}
//                         <td>{item.clientID}</td>
//                         <td>
//                           <div style={{fontSize: '15px'}}>
//                             <Tooltip title='Edit'>
//                               <button
//                                 onClick={() => navigate(`/edit_lti/${item.id}`)}
//                                 style={{
//                                   border: 'none',
//                                   backgroundColor: 'white', // Set the background color to white
//                                 }}
//                               >
//                                 <span
//                                   style={{
//                                     fontSize: '18px',
//                                     paddingRight: '5px',
//                                     color: 'rgb(126, 130, 153)',
//                                   }}
//                                 >
//                                   <FaEdit />
//                                 </span>
//                               </button>
//                             </Tooltip>

//                             <Tooltip title='Delete'>
//                               <Link to='#' onClick={() => deletehandler(item.id)}>
//                                 <span
//                                   style={{
//                                     fontSize: '18px',
//                                     paddingRight: '5px',
//                                     color: 'rgb(126, 130, 153)',
//                                   }}
//                                 >
//                                   <FaTrash />
//                                 </span>
//                               </Link>
//                             </Tooltip>
//                           </div>
//                         </td>
//                       </tr>
//                     )
//                   })}
//                 </tbody>
//               </table>
//             </div>
//             <nav className='w-0 pb-5'>
//               <ul className='pagination'>
//                 <li className='page-item'>
//                   <Link to='#' className='page-link' onClick={prePage}>
//                     Prev
//                   </Link>
//                 </li>
//                 {number.map((n, i) => (
//                   <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
//                     <Link to='#' className='page-link' onClick={() => changeCPage(n)}>
//                       {n}
//                     </Link>
//                   </li>
//                 ))}
//                 <li className='page-item'>
//                   <Link to='#' className='page-link' onClick={nextPage}>
//                     Next
//                   </Link>
//                 </li>
//               </ul>
//             </nav>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default AllTools
