import React from 'react'

import maleimg from '../../../../../assets/images/maleimg.jpg'

const Mentoring = () => {
  return (
    <div className='row'>
      <div className='col-md-4'>
        <div className='card'>
          <div className='d-flex text-center  flex-column justify-content-center p-10'>
            <div>
              <img
                src={maleimg}
                alt='Profile'
                style={{
                  border: 'double 3px transparent',
                  borderRadius: '50%',
                  backgroundImage:
                    'linear-gradient(white, white), linear-gradient(to right, rgba(28, 50, 94, 1), rgba(255, 118, 63, 1))',
                  backgroundOrigin: 'border-box',
                  backgroundClip: 'content-box, border-box',
                  width: '150px',
                  height: '150px',
                  maxWidth: '200px',
                }}
              />
            </div>

            <h3>Instructor1</h3>

            <p style={{textAlign: 'center', maxWidth: '400px', margin: '0 auto'}}>
              It was popularized in the 1960s with the release of Letraset sheets containing Lorem
              Ipsum passages, and more recently with desktop publishing software like Aldus
              PageMaker including versions of Lorem Ipsum
            </p>

            <div className='buttons' style={{paddingTop: '1rem'}}>
              <a
                href='https://calendly.com/cyberheads-mentoring'
                target='_blank'
                rel='noopener noreferrer'
              >
                <button
                  className='btn btn-sm bg-secondary m-0 mb-5 mt-5'
                  style={{border: '0px', maxWidth: '50%'}}
                >
                  Book Session
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='col-md-4'>
        <div className='card'>
          <div className='d-flex text-center  flex-column justify-content-center p-10'>
            <div>
              <img
                src={maleimg}
                alt='Profile'
                style={{
                  border: 'double 3px transparent',
                  borderRadius: '50%',
                  backgroundImage:
                    'linear-gradient(white, white), linear-gradient(to right, rgba(28, 50, 94, 1), rgba(255, 118, 63, 1))',
                  backgroundOrigin: 'border-box',
                  backgroundClip: 'content-box, border-box',
                  width: '150px',
                  height: '150px',
                  maxWidth: '200px',
                }}
              />
            </div>

            <h3>Instructor2</h3>

            <p style={{textAlign: 'center', maxWidth: '400px', margin: '0 auto'}}>
              It was popularized in the 1960s with the release of Letraset sheets containing Lorem
              Ipsum passages, and more recently with desktop publishing software like Aldus
              PageMaker including versions of Lorem Ipsum
            </p>

            <div className='buttons' style={{paddingTop: '1rem'}}>
              <a
                href='https://calendly.com/cyberheads-mentoring'
                target='_blank'
                rel='noopener noreferrer'
              >
                <button
                  className='btn btn-sm bg-secondary m-0 mb-5 mt-5'
                  style={{border: '0px', maxWidth: '50%'}}
                >
                  Book Session
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='col-md-4'>
        <div className='card'>
          <div className='d-flex text-center  flex-column justify-content-center p-10'>
            <div>
              <img
                src={maleimg}
                alt='Profile'
                style={{
                  border: 'double 3px transparent',
                  borderRadius: '50%',
                  backgroundImage:
                    'linear-gradient(white, white), linear-gradient(to right, rgba(28, 50, 94, 1),rgba(255, 118, 63, 1))',
                  backgroundOrigin: 'border-box',
                  backgroundClip: 'content-box, border-box',
                  width: '150px',
                  height: '150px',
                  maxWidth: '200px',
                }}
              />
            </div>

            <h3>Instructor3</h3>

            <p style={{textAlign: 'center', maxWidth: '400px', margin: '0 auto'}}>
              It was popularized in the 1960s with the release of Letraset sheets containing Lorem
              Ipsum passages, and more recently with desktop publishing software like Aldus
              PageMaker including versions of Lorem Ipsum
            </p>

            <div className='buttons' style={{paddingTop: '1rem'}}>
              <a
                href='https://calendly.com/cyberheads-mentoring'
                target='_blank'
                rel='noopener noreferrer'
              >
                <button
                  className='btn btn-sm bg-secondary m-0 mb-5 mt-5'
                  style={{border: '0px', maxWidth: '50%'}}
                >
                  Book Session
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Mentoring
