import React, { useState, useEffect } from 'react'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'

export const CheckoutForm = ({ setCardComplete }) => {
  const stripe = useStripe()
  const elements = useElements()
  const [id, setId] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const cardOnChange = async (event) => {
    if (event.complete) {
      setCardComplete(true)
      setErrorMessage('')
    } else {
      setCardComplete(false)
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    })

    if (!error) {
      const { id } = paymentMethod
      setId(id)
    } else {
      setErrorMessage(error.message)
    }
  }

  useEffect(() => {
    if (id) {
       localStorage.setItem('id', id)
    }
  }, [id])

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (setCardComplete) {
      console.log('Proceeding with form submission...')
    } else {
      console.log('Please fill in the card details.')
    }
  }

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        paddingTop: '1rem',
        backgroundColor: '#f8f8f8',
        color: 'black',
        padding: '20px',
        borderRadius: '8px',

        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      }}
    >
      <label
        htmlFor='my-card-element'
        style={{ fontSize: '16px', marginBottom: '8px', display: 'block' }}
      >
        Enter Card Details      </label>
      <div
        style={{
          border: '1px solid #ccc',
          borderRadius: '4px',
          padding: '1rem',
          backgroundColor: 'white',
        }}
      >
        <CardElement
          id='my-card-element'
          onChange={(e) => {
            cardOnChange(e)
          }}
          options={{
            hidePostalCode: true,
            style: {
              base: {
                fontSize: '16px',
                iconColor: 'black',
                fontWeight: '500',
                color: 'black',
                '::placeholder': {
                  color: 'black',
                },
              },
              invalid: {
                color: 'red',
              },
            },
            classes: { base: 'my-card-element' },
          }}
        />
      </div>
      {errorMessage && (
        <div className='alert_danger mt-10' style={{ color: 'red', fontSize: '14px' }}>
          {errorMessage}
        </div>
      )}
      {!setCardComplete && (
        <div style={{ color: 'red', marginTop: '10px', fontSize: '14px' }}>
          Please fill in the card details.
        </div>
      )}
    </form>
  )
}
