import React, {useEffect, useState, useRef} from 'react'
import {Container} from 'react-bootstrap'

// import inst from "../../asserts/images/angular.jpg";
import {useDispatch, useSelector} from 'react-redux'
import {
  GetInstructorAvaibilityCoursesDetails,
  GetRangeByInstructorId,
  GetInstructorAvaibilityReservedDetails,
  AddInstructorAvailableDatesAction,
  sendMeetingAction,
} from '../../../Redux/Action/instructorAction'
import {Calendar, DateObject} from 'react-multi-date-picker'
import moment from 'moment-timezone'
import {Tab, Row, Col, Nav} from 'react-bootstrap-v5'
import Instructorsearch from './Instructorsearch'

import {Modal} from 'antd'
import './custom.css'

import {Link} from 'react-router-dom'

const CheckInstrcutorAvaibility = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [clickedStart, setClickedStart] = useState(null)
  const [clickedEnd, setClickedEnd] = useState(null)
  const [selectedRanges, setSelectedRanges] = useState([])
  const [reservedValues, setReservedValues] = useState([])
  const bookButtonIdRef = useRef(null)

  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const ReservedRange = useSelector((state) => state.login.getaInstructorAvaibilityReservedlist)

  const [values, setValues] = useState([])
  const currentURLRef = useRef(window.location.href)
  const [selectedInstructorId, setSelectedInstructorId] = useState(null)
  const [currentURLS, setCurrentURLS] = useState(null)

  const handleBookButtonClick = (id) => {
    setSelectedInstructorId(id)
    setIsModalOpen(true)
  }

  const handleCancel = () => {
    setSelectedInstructorId(null)
    setIsModalOpen(false)
    window.location.reload()
  }
  var newURL = window.location

  var splitURL = newURL.href.split('/')

  const courseId = splitURL[4]

  const instid = splitURL[5]

  const dispatch = useDispatch()

  const courseavaibilitydetails = useSelector((state) => {
    return state.login.getaInstructorAvaibilityAdminlist
  })

  useEffect(() => {
    dispatch(GetInstructorAvaibilityCoursesDetails(courseId))
  }, [dispatch])

  const message = courseavaibilitydetails.message || []
  const data = message.map((entry) => entry)

  useEffect(() => {
    dispatch(GetRangeByInstructorId(selectedInstructorId))
      .then(() => setIsDataLoaded(true))
      .catch((error) => {
        setIsDataLoaded(true)
      })
  }, [dispatch, selectedInstructorId])

  useEffect(() => {
    dispatch(GetInstructorAvaibilityReservedDetails(selectedInstructorId))
      .then(() => setIsDataLoaded(true))
      .catch((error) => {
        setIsDataLoaded(true)
      })
  }, [dispatch])

  const Range = useSelector((state) => state.login.getallRangelist)

  useEffect(() => {
    if (Range.data && Array.isArray(Range.data)) {
      const startDateArray = Range.data.map((item) => item.start_date)
      const endDateArray = Range.data.map((item) => item.end_date)

      const dynamicStartDate = startDateArray.map((date) =>
        new DateObject(date).format('YYYY/MM/DD')
      )

      const dynamicEndDate = endDateArray.map((date) => new DateObject(date).format('YYYY/MM/DD'))

      const inService = dynamicStartDate.map((startDate, index) => [
        startDate,
        dynamicEndDate[index],
      ])

      setValues((prevValues) => [...prevValues, ...inService])
    }
  }, [Range.data])

  useEffect(() => {
    if (ReservedRange.data && Array.isArray(ReservedRange.data)) {
      const startDateArray = ReservedRange.data.map((item) => item.start_date)
      const endDateArray = ReservedRange.data.map((item) => item.end_date)

      const dynamicReservedStartDate = startDateArray.map((date) =>
        new DateObject(date).format('YYYY/MM/DD')
      )

      const dynamicReservedEndDate = endDateArray.map((date) =>
        new DateObject(date).format('YYYY/MM/DD')
      )

      const reserved = dynamicReservedStartDate.map((startDate, index) => [
        startDate,
        dynamicReservedEndDate[index],
      ])

      setReservedValues((prevReservedValues) => [...prevReservedValues, ...reserved])
    }
  }, [ReservedRange.data])

  const currentDate = new Date().toISOString().split('T')[0]
  const expiary = new Date(
    new Date(currentDate).getFullYear(),
    new Date(currentDate).getMonth() + 6,
    new Date(currentDate).getDate()
  )
    .toISOString()
    .slice(0, 10)

  const disabledDates = Array.from({length: 31}, (_, index) => {
    const date = new Date()
    date.setDate(date.getDate() - index - 1)
    return new DateObject(date)
  })

  const [selectedItemIds, setSelectedItemIds] = useState([])

  const handleChange = (id) => {
    setSelectedItemIds((prevSelectedItems) => {
      if (prevSelectedItems.includes(id)) {
        return prevSelectedItems.filter((itemId) => itemId !== id)
      } else {
        return [...prevSelectedItems, id]
      }
    })
  }

  const onSubmit = () => {
    if (clickedStart && clickedEnd) {
      const formData = {
        start_date: [],
        end_date: [],
        instructor_id: selectedInstructorId,
        Course_id: courseId,
      }

      const startDateObject = new DateObject(clickedStart)
      const endDateObject = new DateObject(clickedEnd)

      formData.start_date.push(startDateObject.format('YYYY-MM-DD'))
      formData.end_date.push(endDateObject.format('YYYY-MM-DD'))

      dispatch(AddInstructorAvailableDatesAction(formData, instid))
    }
  }

  const [formvalue, setFormValue] = useState('')
  const [forvaluefield, setFormValueField] = useState('')
  const [isModalOpenmetting, setIsModalOpenmetting] = useState(false)
  const [meetingId, setMeetingId] = useState(null)
  const updateMeeting = () => {
    //debugger
    const formData = new FormData()

    formData.append('meetingLink', formvalue)
    dispatch(sendMeetingAction(formData, meetingId))
  }

  const handlemeetingClick = (id) => {
    setMeetingId(id.id)
    setFormValueField(id.meetingLink)
    setIsModalOpenmetting(true)
  }

  const handlemeetingCancel = () => {
    setMeetingId(null)
    setIsModalOpenmetting(false)
    // window.location.reload()
  }

  useEffect(() => {
    setFormValue(forvaluefield)
  }, [forvaluefield])

  return (
    <div>
      <Tab.Container id='left-tabs-example' defaultActiveKey='techerkey'>
        <Row>
          <Col md={12} style={{display: 'flex', justifyContent: 'center'}}>
            <Nav variant='pills'>
              <Nav.Item className='mb-5'>
                <Nav.Link eventKey='techerkey'>Available Instructor</Nav.Link>
              </Nav.Item>

              <Nav.Item className='mb-5'>
                <Nav.Link eventKey='instructor'>Search Instructor</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Tab.Content>
              <Tab.Pane eventKey='techerkey'>
                <div>
                  <div>
                    <Container fluid>
                      <Row>
                        <Col md={12}>
                          <div className='teacher'>
                            {courseavaibilitydetails.count === 0 ? (
                              <div
                                class='d-flex flex-column flex-root'
                                id='kt_app_root'
                                style={{marginTop: '15%'}}
                              >
                                <div class='d-flex flex-column flex-center flex-column-fluid'>
                                  <div class='d-flex flex-column flex-center text-center p-10'>
                                    <div class='card card-flush w-lg-650px py-5'>
                                      <div
                                        class='card-body py-15 py-lg-20'
                                        style={{height: '26rem', marginTop: '69px'}}
                                      >
                                        <h2 class='fw-bolder fs-2hx text-gray-900 mb-4'>
                                          No Tecaher Available for this Course
                                        </h2>

                                        <div class='mb-0' style={{marginTop: '50px'}}>
                                          <Link
                                            to='/Allcourse'
                                            class='btns btn-sm '
                                            style={{
                                              color: 'white',

                                              width: '108px',
                                              padding: '10px',
                                              borderRadius: '5px',
                                              fontWeight: 500,
                                              fontSize: '12px',
                                              backgroundColor: 'black',
                                            }}
                                          >
                                            RETURN TO BACK
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div>
                                {data.map((item) => {
                                  const storedTimezone = localStorage.getItem('timezone')

                                  let message

                                  if (storedTimezone === null || storedTimezone === undefined) {
                                    message = 'You have not set a timezone.'
                                    // Display a message or take any other appropriate action here
                                  } else {
                                    const itemTimezone = item.instructor_info.timezone
                                    //console.log(itemTimezone, 'hhhhhhhhhhhhhhhhhhhhhsss')
                                    if (!moment.tz.zone(itemTimezone)) {
                                      message = 'Timezone not Set'
                                    } else {
                                      const currentDateTime = moment()
                                      const storedDateTime = currentDateTime.tz(storedTimezone)
                                      const storedDateTimeValue = new Date(storedDateTime._d)
                                      const itemDateTime = currentDateTime.tz(itemTimezone)
                                      const itemDateTimeValue = new Date(itemDateTime._d)
                                      const storedHour = storedDateTimeValue.getHours()
                                      const storedMinutes = storedDateTimeValue.getMinutes()
                                      const itemHour = itemDateTimeValue.getHours()
                                      const itemMinutes = itemDateTimeValue.getMinutes()

                                      let hourDiff
                                      let minuteDiff
                                      if (
                                        storedHour > itemHour ||
                                        (storedHour === itemHour && storedMinutes > itemMinutes)
                                      ) {
                                        hourDiff = storedHour - itemHour
                                        minuteDiff = storedMinutes - itemMinutes
                                        if (minuteDiff < 0) {
                                          hourDiff -= 1
                                          minuteDiff += 60
                                        }
                                        message = `${hourDiff} hours ${minuteDiff} minutes behind.`
                                      } else if (
                                        storedHour < itemHour ||
                                        (storedHour === itemHour && storedMinutes < itemMinutes)
                                      ) {
                                        hourDiff = itemHour - storedHour
                                        minuteDiff = itemMinutes - storedMinutes
                                        if (minuteDiff < 0) {
                                          hourDiff -= 1
                                          minuteDiff += 60
                                        }
                                        message = `${hourDiff} hours ${minuteDiff} minutes ahead.`
                                      } else {
                                        message = 'Same Timezone as you'
                                      }
                                    }
                                  }
                                  return (
                                    <Row style={{marginBottom: '30px'}}>
                                      <Col lg={4}>
                                        <div key={item.id}>
                                          <div class='card' style={{height: '342px'}}>
                                            <div class='card-body d-flex flex-center flex-column pt-12 p-9'>
                                              <div class='symbol symbol-65px symbol-circle mb-5'>
                                                <img
                                                  src={
                                                    item.instructor_info.profileUrl
                                                      ? item.instructor_info.profileUrl
                                                      : item.instructor_info.profilePic
                                                  }
                                                  alt='imagesdfsf'
                                                />

                                                <div class='bg-success position-absolute border border-4 border-body h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3'></div>
                                              </div>

                                              <a
                                                href='#'
                                                class='fs-4 text-gray-800 text-hover-primary fw-bold mb-0'
                                              >
                                                {item.instructor_info.FirstName}{' '}
                                                {item.instructor_info.Surname}
                                              </a>

                                              <div class='fw-semibold text-gray-400'>
                                                {item.instructor_info.Email}
                                              </div>
                                              <div class='fw-semibold text-gray-400 mb-6'>
                                                {/* Timezone: {item.instructor_info.timezone} {message} */}
                                              </div>
                                              <div class='d-flex flex-center flex-wrap'>
                                                <div
                                                  class='border border-gray-300 border-dashed rounded min-w-100px py-3 px-4 mx-2 mb-3'
                                                  style={{textAlign: 'center'}}
                                                >
                                                  <div class='fs-6 fw-bold text-gray-700'>
                                                    $14,560
                                                  </div>
                                                  <div class='fw-semibold text-gray-400'>
                                                    Earnings
                                                  </div>
                                                </div>

                                                <div
                                                  class='border border-gray-300 border-dashed rounded min-w-100px py-3 px-4 mx-2 mb-3 '
                                                  style={{textAlign: 'center'}}
                                                >
                                                  <div class='fs-6 fw-bold text-gray-700'>23</div>
                                                  <div class='fw-semibold text-gray-400'>
                                                    Courses Delivered
                                                  </div>
                                                </div>
                                              </div>

                                              <div
                                                className='book-view'
                                                style={{
                                                  display: 'flex',
                                                  gap: '30px',
                                                  marginTop: '13px',
                                                }}
                                              >
                                                <button
                                                  className='btn cyberbtnallpage'
                                                  style={{
                                                    color: 'white',

                                                    width: '108px',
                                                    padding: '10px',
                                                    borderRadius: '5px',
                                                    fontWeight: 500,
                                                  }}
                                                  onClick={() => {
                                                    window.location.href = `/user-availabilityset/${item.instructor_info.id}`
                                                  }}
                                                >
                                                  Book
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </Col>
                                      <Col lg={6}>
                                        <div className='avai' style={{width: '81rem'}}>
                                          <div class='card mb-5 '>
                                            <div
                                              class='card-header border-0 cursor-pointer'
                                              role='button'
                                              data-bs-toggle='collapse'
                                              data-bs-target='#kt_account_notifications'
                                              aria-expanded='true'
                                              aria-controls='kt_account_notifications'
                                            >
                                              <div class='card-title m-0'>
                                                <h3 class='fw-bold m-0'>Booked Dates</h3>
                                              </div>
                                            </div>

                                            <div
                                              id='kt_account_settings_notifications'
                                              class='collapse show'
                                            >
                                              <div
                                                class='card-body border-top px-9 pt-3 pb-4'
                                                style={{
                                                  minHeight: '270px',
                                                  maxHeight: '270px',
                                                  overflowY: 'auto',
                                                }}
                                              >
                                                <div class='table-responsive'>
                                                  {item.availability_count === 0 ? (
                                                    <div
                                                      className='fw-semibold text-gray-600 fs-2 mb-8 d-block'
                                                      style={{
                                                        paddingTop: '100px',
                                                        paddingBottom: '50px',
                                                        textAlign: 'center',
                                                      }}
                                                    >
                                                      No Bookings yet
                                                    </div>
                                                  ) : (
                                                    <table
                                                      class='table table-row-dashed border-gray-300 align-middle gy-6'
                                                      style={{marginTop: '0px'}}
                                                    >
                                                      <tbody class='fs-6 fw-semibold'>
                                                        {item.availability
                                                          .reduce((rows, availability, index) => {
                                                            if (index % 2 === 0) {
                                                              rows.push([])
                                                            }
                                                            rows[rows.length - 1].push(availability)
                                                            return rows
                                                          }, [])
                                                          .map((row, rowIndex) => (
                                                            <tr key={rowIndex}>
                                                              {row.map((availability) => (
                                                                <td
                                                                  key={availability.id}
                                                                  style={{
                                                                    maxHeight: '4em',
                                                                    overflow: 'hidden',
                                                                  }}
                                                                >
                                                                  <div className='orm-check form-check-custom form-check-solid'>
                                                                    {new Date(
                                                                      availability.start_date
                                                                    ).toLocaleString('en-US', {
                                                                      year: 'numeric',
                                                                      month: 'long',
                                                                      day: 'numeric',
                                                                    })}{' '}
                                                                    -{' '}
                                                                    {new Date(
                                                                      availability.end_date
                                                                    ).toLocaleString('en-US', {
                                                                      year: 'numeric',
                                                                      month: 'long',
                                                                      day: 'numeric',
                                                                    })}
                                                                    {availability.status ===
                                                                    'Booked' ? (
                                                                      <>
                                                                        <button
                                                                          className='btn cyberbtnallpage'
                                                                          style={{
                                                                            color: 'white',
                                                                            marginLeft: '10px',
                                                                            width: '80px',
                                                                            padding: '10px',
                                                                            borderRadius: '5px',
                                                                            fontWeight: 500,
                                                                          }}
                                                                          onClick={() =>
                                                                            handlemeetingClick(
                                                                              availability
                                                                            )
                                                                          }
                                                                        >
                                                                          Link
                                                                        </button>
                                                                        <button
                                                                          className='btn cyberbtnallpage'
                                                                          style={{
                                                                            color: 'white',
                                                                            marginLeft: '5px',
                                                                            width: '80px',
                                                                            padding: '10px',
                                                                            borderRadius: '5px',
                                                                            fontWeight: 500,
                                                                          }}
                                                                          onClick={() => {
                                                                            window.location.href = `/TranferBookings/${courseId}/${item.instructor_info.id}/${availability.id}`
                                                                          }}
                                                                        >
                                                                          Transfer
                                                                        </button>

                                                                        <Modal
                                                                          title='Meeting Link'
                                                                          centered
                                                                          open={isModalOpenmetting}
                                                                          onCancel={
                                                                            handlemeetingCancel
                                                                          }
                                                                          footer={null}
                                                                        >
                                                                          <div>
                                                                            <div>
                                                                              <form
                                                                                style={{
                                                                                  padding: '2rem',
                                                                                  borderRadius:
                                                                                    '.375rem',
                                                                                }}
                                                                              >
                                                                                <div>
                                                                                  <label>
                                                                                    Meeting Link
                                                                                  </label>
                                                                                  <input
                                                                                    type='text'
                                                                                    className='form-control mb-4'
                                                                                    value={
                                                                                      formvalue
                                                                                    }
                                                                                    defaultValue={
                                                                                      availability.meetingLink
                                                                                    }
                                                                                    onChange={(e) =>
                                                                                      setFormValue(
                                                                                        e.target
                                                                                          .value
                                                                                      )
                                                                                    }
                                                                                  />
                                                                                </div>

                                                                                <button
                                                                                  style={{
                                                                                    textAlign:
                                                                                      'center',
                                                                                    width: ' 100%',
                                                                                    color: '#ffff',
                                                                                    fontSize:
                                                                                      '1rem',
                                                                                    borderRadius:
                                                                                      '.375rem',
                                                                                    backgroundColor:
                                                                                      '#FF763F',
                                                                                    textTransform:
                                                                                      'capitalize',
                                                                                    border: 'none',
                                                                                  }}
                                                                                  type='submit'
                                                                                  className=' btn-warning'
                                                                                  disableRipple
                                                                                  onClick={(e) => {
                                                                                    e.preventDefault() // Prevent form submission
                                                                                    updateMeeting()
                                                                                    setFormValue('')
                                                                                    setFormValueField(
                                                                                      ''
                                                                                    )
                                                                                  }}
                                                                                >
                                                                                  submit
                                                                                </button>
                                                                              </form>
                                                                            </div>
                                                                          </div>
                                                                        </Modal>
                                                                      </>
                                                                    ) : (
                                                                      ''
                                                                    )}
                                                                  </div>
                                                                </td>
                                                              ))}
                                                            </tr>
                                                          ))}
                                                      </tbody>
                                                    </table>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  )
                                })}
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>

            <Tab.Content>
              <Tab.Pane eventKey='instructor'>
                <div>
                  <Instructorsearch />
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  )
}

export default CheckInstrcutorAvaibility
