import React from "react";
import "./rating.css";
const Rating = () => {
  return (
    <div class="container">
      <div class="row">
        <div class="col-md-3">
        <div class="col-xs-12 col-md-6 text-center">
                <h3 class="rating-num" style={{fontSize:"25px"}}>5.1</h3>
                <div class="rating" style={{fontSize:"15px"}}>
                  <span class="fa fa-star " style={{fontSize:"15px"}}></span>
                  <span class="fa fa-star "style={{fontSize:"15px"}}></span>
                  <span class="fa fa-star "style={{fontSize:"15px"}}></span>
                  <span class="fa fa-star "style={{fontSize:"15px"}}></span>
                  <span class="fa fa-star "style={{fontSize:"15px"}}></span>
                  <span class="fa fa-star-half-empty"></span>
                </div>
                <div>
                  <span class="fa fa-user" style={{fontSize:"1px"}}></span>125888 total votes
                </div>
              </div>
        </div>
        <div class="col-md-6">
          <div class="well well-sm">
            <div class="row">
             
              <div class="">
                <div class="row rating-desc">

                  <div class="col-xs-3 col-md-3 text-right">5 star</div>
                  <div class="col-xs-8 col-md-9">
                    <div class="progress" style={{ display: "flex" }}>
                      <div
                        class="progress-bar progress-bar-success"
                        role="progressbar"
                        aria-valuenow="20"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: "100vw" }}
                      ></div>
                      <div>
                        <span class="sr-only">80%</span>
                      </div>
                    </div>
                  </div>


                  <div class="col-xs-3 col-md-3 text-right">4 star</div>
                  <div class="col-xs-8 col-md-9" style={{ display: "flex" }}>
                    <div class="progress">
                      <div
                        class="progress-bar progress-bar-success"
                        role="progressbar"
                        aria-valuenow="28"
                        aria-valuemin="0"
                        aria-valuemax="100"
                         style={{ width: "100vw" }}
                      ></div>

                      <div>
                        <span class="sr-only">50%</span>
                      </div>
                    </div>
                  </div>



                  <div class="col-xs-3 col-md-3 text-right">5 star</div>
                  <div class="col-xs-8 col-md-9">
                    <div class="progress" style={{ display: "flex" }}>
                      <div
                        class="progress-bar progress-bar-success"
                        role="progressbar"
                        aria-valuenow="20"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: "100vw" }}
                      ></div>
                      <div>
                        <span class="sr-only">80%</span>
                      </div>
                    </div>
                  </div>


                  <div class="col-xs-3 col-md-3 text-right">5 star</div>
                  <div class="col-xs-8 col-md-9">
                    <div class="progress" style={{ display: "flex" }}>
                      <div
                        class="progress-bar progress-bar-success"
                        role="progressbar"
                        aria-valuenow="20"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: "100vw" }}
                      ></div>
                      <div>
                        <span class="sr-only">80%</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-3 col-md-3 text-right">5 star</div>
                  <div class="col-xs-8 col-md-9">
                    <div class="progress" style={{ display: "flex" }}>
                      <div
                        class="progress-bar progress-bar-success"
                        role="progressbar"
                        aria-valuenow="20"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: "100vw" }}
                      ></div>
                      <div>
                        <span class="sr-only">80%</span>
                      </div>
                    </div>
                  </div>
                  
                </div>
                {/* <!-- end row --> */}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3"></div>
      </div>
    </div>
  );
};

export default Rating;
