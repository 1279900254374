import React, {useEffect} from 'react'

import {useDispatch, useSelector} from 'react-redux'
// import { TopCoursesAction } from "../../../../../Redux/Action/AdminDashboaardAction";
import {TopCoursesAction} from '../../../../../Redux/Action/admindashboardAction'
import {Chart} from 'react-google-charts'

const Coursesaleschart = () => {
  const dispatch = useDispatch()
  const topCourses = useSelector((state) => state.login.getTopCoursesList)

  useEffect(() => {
    dispatch(TopCoursesAction())
  }, [dispatch])

  const data = [
    ['Course', 'Purchase Count'],
    ...topCourses.map((course) => [course.title, course.purchase_count]),
  ]

  const options = {
    title: '',
    is3D: true,
    legend: {
      position: 'right',
      alignment: 'end',
    },
    chartArea: {},
    // colors: ['red', '#FF4136', '#2ECC40', '#FF851B', '#39CCCC', '#B10DC9', '#FFDC00'],
  }

  return (
    <div className='card h-100'>
      <div className='card-header'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold text-gray-800'>Courses Sales</span>
        </h3>
      </div>
      <div className='card-body'>
        <Chart chartType='PieChart' data={data} options={options} width={'100%'} height={'350px'} />
      </div>
    </div>
  )
}

export default Coursesaleschart
