import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  InstructorCoursesInvoiceAction,
  InstructorInvoiceUpdateAction,
  GenerteInvoiceAction,
  InstructorCoursesComplitionUpdateAction,
  GetInstructorbyId,
} from '../../../Redux/Action/instructorAction'
import {useForm} from 'react-hook-form'
import './instructorDashboardMain.css'
import {Modal} from 'antd'
import {IconButton} from '@mui/material'
import {CopyToClipboard} from 'react-copy-to-clipboard'

import LaunchIcon from '@mui/icons-material/Launch'

import {zakTokenAction} from '../../../Redux/Action/instructorAction'
import moment from 'moment-timezone';

const MyShedule = () => {
  const instid = localStorage.getItem('userid')

  const dispatch = useDispatch()
  const [isInvoiceClicked, setIsInvoiceIsClicked] = useState([])

  const instructorInvoiceCourse = useSelector((state) => {
    return state.login.getInstructorInvoiceCoursesList
  })

  const classes = instructorInvoiceCourse.upcomingCourses

  const instructorInvoiceCourses = classes || []

  useEffect(() => {
    dispatch(InstructorCoursesInvoiceAction(instid))
  }, [dispatch])

  const handleInvoiceClick = (invoice) => {
    let invoiceClick = isInvoiceClicked.slice()

    invoiceClick[invoice.id] = true
    setIsInvoiceIsClicked(invoiceClick)
  }

  const {
    formState: {errors},
  } = useForm()

  const calculateSubtotal = (daysWorkedValue, dayRate) => {
    return daysWorkedValue * dayRate
  }

  const calculateTax = (subtotal, paymentType) => {
    return subtotal * (paymentType === 'local' ? 0.2 : 0)
  }

  const calculateTotalAmount = (subtotal, tax) => {
    return subtotal + tax
  }

  const onSubmits = (coursedata) => {
    const formData = new FormData()
    const rsideDate = new Date()
    const course = coursedata.Course_id

    formData.append('Course_id', course)
    formData.append('raisedate', rsideDate)
    formData.append('roleid', localStorage.getItem('roleid'))
    formData.append('staffId', localStorage.getItem('userid'))
    formData.append('name', localStorage.getItem('FirstName'))

    formData.append('courseName', coursedata.title)
    formData.append('delieveryDate', coursedata.end_date)
    formData.append('rate', instructordetails.message && instructordetails.message.dayRate)

    formData.append('paymentType', selectedOption)

    formData.append('bankName', instructordetails.message && instructordetails.message.AccountName)
    formData.append(
      'accNumber',
      instructordetails.message && instructordetails.message.AccountNumber
    )
    formData.append('ibanNumber', instructordetails.message && instructordetails.message.IBANNumber)
    formData.append(
      'Company_Name',
      instructordetails.message && instructordetails.message.Company_Name
    )

    const subtotal = calculateSubtotal(
      Number(daysWorkedValue),
      Number(instructordetails.message && instructordetails.message.dayRate)
    )

    formData.append('subtotal', subtotal)
    const tax = calculateTax(subtotal, paymentType)

    formData.append('tax', tax)
    const totalAmount = calculateTotalAmount(subtotal, tax)
    formData.append('total', totalAmount)

    formData.append('daysWorked', daysWorkedValue)

    dispatch(GenerteInvoiceAction(formData))
  }

  const invoiceUpdateSubmit = (item) => {
    const Id = item.id

    dispatch(InstructorInvoiceUpdateAction(Id))
  }

  const [isModalOpen, setIsModalOpen] = useState(false)
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const showModal = () => {
    setIsModalOpen(true)
  }
  const [isClicked, setIsClicked] = useState([])
  const handleClick = (item) => {
    let clicked = isClicked.slice()

    clicked[item.id] = true
    setIsClicked(clicked)
  }

  const onSubmit = (item) => {
    const Id = item.id

    dispatch(InstructorCoursesComplitionUpdateAction(Id))
  }

  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false)
  const [coursedata, setCoursedata] = useState('')

  const handleInvoiceCancel = () => {
    setIsInvoiceModalOpen(false)
  }

  const showInvoiceModal = (course) => {
    setIsInvoiceModalOpen(true)
    setCoursedata(course)
  }

  const [selectedOption, setSelectedOption] = useState('international')
  const [daysWorkedValue, setDaysWorkedValue] = useState('')

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value)
  }

  let percentage = 0
  if (selectedOption === 'local') {
    percentage = 20
  }

  const firstname = localStorage.getItem('FirstName')
  const surname = localStorage.getItem('Surname')

  const [paymentType, setPaymentType] = useState('international')
  const [daysWorked, setDaysWorked] = useState(0)

  const handlePaymentTypeChange = (event) => {
    setPaymentType(event.target.value)
  }

  const handleDaysWorkedChange = (event) => {
    setDaysWorked(Number(event.target.value))
  }

  const instructordetails = useSelector((state) => {
    return state.login.getaInstructorIdlist
  })

  useEffect(() => {
    dispatch(GetInstructorbyId(instid))
  }, [dispatch])

  const courses = [
    {meetingLink: 'link1'},
    {meetingLink: 'link2'},
  ]

  const [copyStatuses, setCopyStatuses] = useState(new Array(courses.length).fill(''))

  const handleCopy = (index) => {
    return () => {
      setCopyStatuses((prevStatuses) => {
        const newStatuses = [...prevStatuses]
        newStatuses[index] = 'Copied'
        setTimeout(() => {
          newStatuses[index] = ''
          setCopyStatuses(newStatuses)
        }, 2000)
        return newStatuses
      })
    }
  }

  const handleButtonClick = (meetingLink) => {
    dispatch(zakTokenAction(meetingLink))
  }

  function isWithin15MinutesOfStart(startDate) {
    const startDateTime = moment(startDate, 'YYYY-MM-DD');
    const currentDate = moment().startOf('day');
    return currentDate.isSameOrAfter(startDateTime, 'day');
}

function combineDateTime(date, time) {

  const [year, month, day] = date.split('-');
  const [hours, minutes] = time.split(':');
  const combinedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:00`;

  return combinedDateTime;
}

const userLocale = navigator.language || 'en-UK'
let asiaTimezone = Intl.DateTimeFormat(userLocale).resolvedOptions().timeZone

function formatDate(dateString, timezone) {
  if (dateString) {
    const inputDate = moment.tz(dateString, 'YYYY-MM-DD HH:mm:ss', timezone);
    const localDate = inputDate.clone().local();
  
    return localDate.format('YYYY-MM-DD HH:mm:ss');
  } else {
    return null
  }
}

const formatDateString = (date) => {
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true 
  };
  return new Date(date).toLocaleDateString('en-GB', options);
};

  return (
    <div>
      {' '}
      <div
        style={{
          borderBottom: '2px solid #181c32',
          paddingBottom: '10px',
          paddingTop: '10px',
          fontWeight: 'bold',
          fontSize: '17px',
          textAlign: 'center',
        }}
      >
        UPCOMING CLASSES
      </div>
      <div style={{paddingTop: '30px'}}>
        <div className='table-responsive'>
          <table className='table align-middle table-row-bordered table-row-solid gy-4 gs-9'>
            {' '}
            <thead className='border-gray-200 fs-5 fw-semibold bg-lighten'>
              <tr>
                <th className='min-w-50px'>#</th>
                <th className='min-w-250px'>Course</th>

                <th className='min-w-150px'> Date</th>
                <th className='min-w-100px'>Zoom Call</th>
                <th className='min-w-100px'>Status</th>

                <th className='min-w-100px'>Class</th>
                <th className='min-w-100px'>Invoice Status</th>
              </tr>
            </thead>
            <tbody className='fw-6 fw-semibold text-gray-600'>
              {instructorInvoiceCourses.length === 0 ? ( 
                <tr>
                  <td colSpan='8' className='text-center'>
                    NO UPCOMING CLASSES
                  </td>
                </tr>
              ) : (
                <>
                  {instructorInvoiceCourses.map((course, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{course.title}</td>

                      <td>
        
                      {formatDateString(formatDate(combineDateTime(course.start_date, course.startTime), course.Timezone))}
                        -
                      {formatDateString(formatDate(combineDateTime(course.end_date, course.endTime), course.Timezone))}
                        
                      </td>

                      {course.meetingLink === null ? (
                        <td>NA</td>
                      ) : (
                        <td>
                          <div className='ml-auto'>
                            <button
                              type='button'
                              className='btn btn-sm fw-bold cyberbtnallpage'
                              onClick={() => {
                                if (isWithin15MinutesOfStart(course.start_date, course.startTime)) {
                                  window.open(course.meetingLink, '_blank');                                
                                }
                              }}
                              disabled={
                                !isWithin15MinutesOfStart(course.start_date, course.startTime)
                              }
                            >
                              <LaunchIcon /> Launch
                            </button>
                          </div>
                          {copyStatuses[index] && (
                            <span style={{color: 'green', marginLeft: '5px'}}>
                              {copyStatuses[index]}
                            </span>
                          )}
                        </td>
                      )}
                      {course.CompletedOn === null ? <td>Yet To Start</td> : <td>Completed</td>}

                      {course.CourseStatus === null ? (
                        <div className='d-flex text-center' style={{paddingRight: '60px'}}>
                          <div className='d-flex flex-center'>
                            <div className='ml-auto'>
                              <button
                                type='button'
                                className='btn btn-sm fw-bold cyberbtnallpage '
                                onClick={() => {
                                  handleClick(course)
                                  onSubmit(course)
                                }}
                              >
                                Mark as Completed
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div>
                          {course.InvoiceStatus === null ? (
                            <button
                              type='button'
                              className='btn btn-sm btn-secondary'
                              onClick={() => {
                                showInvoiceModal(course)
                              }}
                            >
                              Generate Invoice
                            </button>
                          ) : (
                            'Invoice generated'
                          )}
                        </div>
                      )}

                      <td>Not Generated</td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
            
            <Modal
              title='Generate Invoice'
              centered
              open={isInvoiceModalOpen}
              onCancel={handleInvoiceCancel}
              footer={null}
            >
              <div style={{width: '500px'}}>
                <div>
                  <form style={{padding: '2rem', borderRadius: '.375rem'}}>
                    <div class='form-group row'>
                      <label for='staticEmail' class='col-sm-4 col-form-label'>
                        Instructor Name
                      </label>
                      <div class='col-sm-8'>
                        <input
                          type='text'
                          readonly
                          class='form-control-plaintext text-gray-400 pt-1 fw-semibold fs-2'
                          id='staticEmail'
                          value={firstname + ' ' + surname}
                        />
                      </div>
                    </div>

                    <div class='form-group row'>
                      <label for='staticEmail' class='col-sm-4 col-form-label'>
                        Payment Type
                      </label>
                      <div class='col-sm-4' style={{marginTop: '10px'}}>
                        <div class='custom-control custom-radio custom-control-inline'>
                          <input
                            type='radio'
                            id='customRadioInline1'
                            name='customRadioInline1'
                            class='custom-control-input'
                            value='international'
                            checked={selectedOption === 'international'}
                            onChange={handleOptionChange}
                            style={{marginRight: '5px'}}
                          />
                          <label class='custom-control-label' for='customRadioInline1'>
                            International
                          </label>
                        </div>
                      </div>
                      <div class='col-sm-4' style={{marginTop: '10px'}}>
                        <div class='custom-control custom-radio custom-control-inline'>
                          <input
                            type='radio'
                            id='customRadioInline2'
                            name='customRadioInline1'
                            class='custom-control-input'
                            value='local'
                            checked={selectedOption === 'local'}
                            onChange={handleOptionChange}
                            style={{marginRight: '5px'}}
                          />
                          <label class='custom-control-label' for='customRadioInline2'>
                            UK
                          </label>
                        </div>
                      </div>
                    </div>

                    <div class='form-group row'>
                      <label for='staticEmail' class='col-sm-4 col-form-label'>
                        Day Rate
                      </label>
                      <div class='col-sm-8' style={{marginTop: '10px'}}>
                        <div class='col-sm-8'>
                          {instructordetails.message && instructordetails.message.dayRate}
                        </div>
                      </div>
                    </div>

                    <div>
                      <div class='form-group row'>
                        <label for='staticEmail' class='col-sm-4 col-form-label'>
                          Bank Name
                        </label>
                        <div class='col-sm-8' style={{marginTop: '10px'}}>
                          {' '}
                          {instructordetails.message && instructordetails.message.AccountName}{' '}
                        </div>
                      </div>

                      <div class='form-group row'>
                        <label for='staticEmail' class='col-sm-4 col-form-label'>
                          Account Number
                        </label>
                        <div class='col-sm-8' style={{marginTop: '10px'}}>
                          {instructordetails.message && instructordetails.message.AccountNumber}{' '}
                        </div>
                      </div>

                      <div class='form-group row'>
                        <label for='staticEmail' class='col-sm-4 col-form-label'>
                          IBAN Number
                        </label>
                        <div class='col-sm-8' style={{marginTop: '10px'}}>
                          {instructordetails.message && instructordetails.message.IBANNumber}{' '}
                        </div>
                      </div>
                      <div class='form-group row'>
                        <label for='staticEmail' class='col-sm-4 col-form-label'>
                          Company/Account Name
                        </label>
                        <div class='col-sm-8' style={{marginTop: '10px'}}>
                          {instructordetails.message && instructordetails.message.Company_Name}{' '}
                        </div>
                      </div>
                    </div>

                    <div class='form-group row'>
                      <label for='staticEmail' class='col-sm-4 col-form-label'>
                        Course Name Delivered
                      </label>
                      <div class='col-sm-8' style={{marginTop: '10px'}}>
                        {coursedata.title}
                      </div>
                    </div>

                    <div class='form-group row'>
                      <label for='staticEmail' class='col-sm-4 col-form-label'>
                        Course Delivery Dates
                      </label>
                      <div class='col-sm-8' style={{marginTop: '10px'}}>
                        {coursedata.end_date}
                      </div>
                    </div>

                    <div class='form-group row' style={{paddingBottom: '20px'}}>
                      <label class='col-sm-4 col-form-label'>Days Worked</label>
                      <div class='col-sm-8'>
                        <input
                          type='text'
                          class='form-control'
                          daysWorkedValue
                          placeholder='Days Worked'
                          name='Days Worked'
                          value={daysWorkedValue}
                          onChange={(e) => setDaysWorkedValue(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className='form-group row' style={{marginBottom: '10px'}}>
                      <label className='col-sm-4 col-form-label'>Subtotal</label>
                      <div className='col-sm-8'>
                        <input
                          type='text'
                          className='form-control'
                          value={`$${calculateSubtotal(
                            Number(daysWorkedValue),
                            Number(instructordetails.message && instructordetails.message.dayRate)
                          ).toFixed(2)}`}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className='form-group row' style={{marginBottom: '10px'}}>
                      <label className='col-sm-4 col-form-label'>Tax</label>
                      <div className='col-sm-8'>
                        <input
                          type='text'
                          className='form-control'
                          value={`$${calculateTax(
                            calculateSubtotal(
                              Number(daysWorkedValue),
                              Number(instructordetails.message && instructordetails.message.dayRate)
                            ),
                            selectedOption
                          ).toFixed(2)}`}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className='form-group row' style={{marginBottom: '10px'}}>
                      <label className='col-sm-4 col-form-label'>Total Amount</label>
                      <div className='col-sm-8'>
                        <input
                          type='text'
                          className='form-control'
                          value={`$${calculateTotalAmount(
                            calculateSubtotal(
                              Number(daysWorkedValue),
                              Number(instructordetails.message && instructordetails.message.dayRate)
                            ),
                            calculateTax(
                              calculateSubtotal(
                                Number(daysWorkedValue),
                                Number(
                                  instructordetails.message && instructordetails.message.dayRate
                                )
                              ),
                              selectedOption
                            )
                          ).toFixed(2)}`}
                          readOnly
                        />
                      </div>
                    </div>

                    <button
                      style={{
                        textAlign: 'center',
                        width: ' 100%',
                        color: '#ffff',
                        fontSize: '1rem',
                        borderRadius: '.375rem',
                        backgroundColor: '#FF763F',
                        textTransform: 'capitalize',
                        border: 'none',
                        padding: '10px',
                      }}
                      type='submit'
                      className=' btn-warning'
                      disableRipple
                      onClick={() => {
                        onSubmits(coursedata)
                        invoiceUpdateSubmit(coursedata)
                        handleInvoiceClick(coursedata)
                      }}
                    >
                      submit
                    </button>
                  </form>
                </div>
              </div>
            </Modal>
          </table>
        </div>
      </div>
    </div>
  )
}

export default MyShedule
