/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth'
// import {Languages} from './Languages'
import { MenuComponent } from '../../../assets/ts/components'
import axios from 'axios'
import swal from 'sweetalert'
import admin from '../../../../assets/images/default.png'

const HeaderUserMenu: FC = () => {
  const { currentUser, logout } = useAuth()

  const studentid = localStorage.getItem('userid')
  interface UserDetails {
    id: number
    PrefixMrMissMrs: string
    FirstName: string
    Surname: string
    Email: string
    PhoneNumber: string
    Token: string
    Country: string
    City: string
    state: string
    timezone: string
    roleid: number
    AgeBracket: string
    YearsOfCyberSecurityExperience: string
    EducationLevel: string
    PurposeOfTakingTheCourse: string
    InterestedInCyberheadRole: string
    EmploymentStatus: string
    Password: string
    is_verified: number
    Role: string
    Rating: null | number
    HowYouGotToKnow: string
    profilePic: string
    profileUrl: string
    fileName: null | string
    fileUrl: null | string
    fileKey: null | string
    courseCreate: null | string
    courseRead: null | string
    courseUpdate: null | string
    courseDelete: null | string
    payCreate: null | string
    payRead: null | string
    payUpdate: null | string
    payDelete: null | string
    roleCreate: null | string
    roleRead: null | string
    roleUpdate: null | string
    roleDelete: null | string
    ltiCreate: null | string
    ltiRead: null | string
    ltiUpdate: null | string
    ltiDelete: null | string
    userStatus: null | string
  }
  const [userdetails, setUserDetails] = useState<UserDetails | null>(null)

  useEffect(() => {
    //debugger
    const token = localStorage.getItem('authToken')

    // Include the token in the headers
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }

    axios
      .get(`https://backend.cyberheads.in/getUser/${studentid}`, {headers})
      .then((response) => {
        setUserDetails(response.data.message)
      })
      .catch((error) => {
        console.error('Error fetching user details:', error)
      })
  }, [])
  useEffect(() => {
    MenuComponent.reinitialization()
  })

  const logoutHandler = async () => {
    const confirmResult = await swal({
      title: 'Log Out',
      text: 'Are you sure you want to log out?',

      buttons: {
        cancel: {
          text: 'No',
          value: null,
          visible: true,
          className: 'swal-button--cancel cybercancelbtn',
        },
        confirm: {
          text: 'Yes',
          value: true,
          visible: true,
          className: 'swal-button--confirm cyberbtnallpage',
        },
      },
      dangerMode: false,
    })
    if (confirmResult) {
      localStorage.clear()
      window.location.reload()
    }
  }
  if (!userdetails) {
    return <div>Loading...</div>
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center' style={{ width: '200px' }}>
          <div className='symbol symbol-50px me-5'>
            {/* <img alt='Logo' src={toAbsoluteUrl('/media/avatars/300-1.jpg')} /> */}

            {userdetails.profileUrl ? (
              <img
                alt='Profile Pic'
                src={userdetails.profileUrl ? userdetails.profileUrl : userdetails.profilePic}
              />
            ) : (
              <img src={admin}
                alt='Default Profile'
                title='My Profile'
                />
            )}
          </div>
          <div style={{ fontSize: '12px' }}>
            <div className='email-container'>
              <span className='fw-bold text-muted text-hover-primary' style={{ width: '100%' }}>
                {userdetails.Email}
              </span>
            </div>
          </div>

          <div className='d-flex flex-column' style={{ fontSize: '12px' }}>
            <div className='fw-bolder d-flex align-items-center'>
              {currentUser?.first_name} {currentUser?.first_name}
            </div>
            <br />
            <a href='#' className='fw-bold text-muted text-hover-primary'>
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to='/My_profile' className='menu-link px-5'>
          My Profile
        </Link>
      </div>

      <div className='separator my-2'></div>

      {/* <Languages /> */}

      <div className='menu-item px-5'>
        <span onClick={logoutHandler} className='menu-link px-5'>
          Log Out
        </span>
      </div>
    </div>
  )
}

export { HeaderUserMenu }
