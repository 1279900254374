import axios from 'axios'

import swal from 'sweetalert'

import {
  getInstructorAdminListslice,
  getInsstrructorCoursesslice,
  getavailibilityInstslice,
  getInsstrructorIdslice,
  getRangeslice,
  getInsstrructorAvaibilityReservedslice,
  getInsstrructorAvaibilityCoursesslice,
  getInstructorCoursesslice,
  getInstructorInvoiceCoursesslice,
  getInstructorCalanderDatesslice,
  getCalanderCourseSheduleslice,
  getallInvoiceslice,
  getAllinvoicesslice,
  getavailibilityyInstslice,
  getallteachercourseslice,
  getInstructorFilterslice,
  getAllAvaibilityslice,
} from '../Slice/authSlice'

export const GetInstructorAdminList = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getinsid = `${process.env.REACT_APP_BACKEND_API}/getallinstructorlist`

    const res = await axios.get(getinsid, config)

    await dispatch(getInstructorAdminListslice(res))
  } catch (e) {}
}

export const DeleteInstructorAction = (props) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const confirmResult = await swal({
      title: 'Delete Instructor',
      text: 'Are you sure you want to delete this Instructor?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          value: null,
          visible: true,
          className: 'swal-button--cancel cybercancelbtn', 
        },
        confirm: {
          text: 'Delete',
          value: true,
          visible: true,
          className: 'swal-button--confirm swal-button--danger cyberbtnallpage', 
        },
      },
      dangerMode: true,
    })

    if (confirmResult) {
      const deleteuserIns = `${process.env.REACT_APP_BACKEND_API}/deleteInstructor/${props}`
      const res = await axios.delete(deleteuserIns, config)

      if (res.status === 200) {
        swal('Success', res.data.message, 'success').then(() => {
          window.location.reload()
        })
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export const GetInstructorCoursesDetails = (instid) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getinsid = `${process.env.REACT_APP_BACKEND_API}/instructorCoursesdetails/${instid}`

    const res = await axios.get(getinsid, config)

    await dispatch(getInsstrructorCoursesslice(res))
  } catch (e) {}
}

export const GetInstructorbyId = (instid) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getinsid = `${process.env.REACT_APP_BACKEND_API}/instructordetails/${instid}`

    const res = await axios.get(getinsid, config)

    await dispatch(getInsstrructorIdslice(res))
  } catch (e) {}
}

export const UpdateInstructorByidAction = (formData, instid, navigate) => async (dispatch) => {
  try {
    debugger
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const updateInstructorid = `${process.env.REACT_APP_BACKEND_API}/updateInstructor/${instid}`
    const res = await axios.put(updateInstructorid, formData, config)

    if (res.status === 200) {
      swal('Success', res.data.message, 'success')
      //navigate('/alluserlist')

      const roleid = localStorage.getItem('roleid')
      if (roleid == 1 || roleid == 2) {
        navigate('/alluserlist')
      } else if (roleid === '4') {
        //navigate('/My_profile')
        window.location.reload()
      }
    }
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e?.response?.data?.message, 'error')
    }
  }
}

export const UpdateInstructoRangeByidAction = (formData, instid) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const updateInstructorid = `${process.env.REACT_APP_BACKEND_API}/updateAvaibility/${instid}`
    const res = await axios.put(updateInstructorid, formData, config)

    if (res.status === 200) {
      // window.location.reload()
      swal('Success', res.data.message, 'success')
      .then(() => {
        window.location.reload();
      });
    }
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e?.response?.data?.message, 'error')
    }
  }
}

export const updateSingleDateAvaibility = (formData, instid) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const updateInstructorid = `${process.env.REACT_APP_BACKEND_API}/updateSingleDateAvaibility/${instid}`
    const res = await axios.put(updateInstructorid, formData, config)

    if (res.status === 200) {
      // window.location.reload()
      swal('Success', res.data.message, 'success')
      .then(() => {
        window.location.reload();
      });
    }
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e?.response?.data?.message, 'error')
    }
  }
}


export const GetInstructorAvaibilityCoursesDetails = (courseId) => async (dispatch) => {
  try {
    // debugger;
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getinsid = `${process.env.REACT_APP_BACKEND_API}/avaibilitybyCourseId/${courseId}`

    const res = await axios.get(getinsid, config)
    await dispatch(getInsstrructorAvaibilityCoursesslice(res))
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e?.response?.data?.message, 'error')
    }
  }
}

export const GetRangeByInstructorId = (instid) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getRange = `${process.env.REACT_APP_BACKEND_API}/Date/${instid}`

    const res = await axios.get(getRange, config)
    await dispatch(getRangeslice(res))
  } catch (e) {
    console.log(e)
  }
}

export const GetInstructorAvaibilityReservedDetails = (instid) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getinsid = `${process.env.REACT_APP_BACKEND_API}/ResevedDate/${instid}`

    const res = await axios.get(getinsid, config)
    await dispatch(getInsstrructorAvaibilityReservedslice(res))
  } catch (e) {
    console.log(e)
  }
}

export const UpdateStatusAvaibilityByAdmin =
  (selectedItemIds, instructorId) => async (dispatch) => {
    try {
      const token = localStorage.getItem('authToken')
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }

      const updateuserid = `${process.env.REACT_APP_BACKEND_API}/updateStatusByAdmin/${selectedItemIds}/${instructorId}`

      const res = await axios.put(updateuserid, config)

      if (res.status === 200) {
        if (res.data.message === 'Status Updated') {
          const smallMessage = document.createElement('div')
          smallMessage.textContent = 'Approved'
          smallMessage.style.backgroundColor = 'green'
          smallMessage.style.color = 'white'
          smallMessage.style.padding = '10px'
          smallMessage.style.borderRadius = '5px'
          smallMessage.style.position = 'fixed'
          smallMessage.style.top = '50%'
          smallMessage.style.left = '50%'
          smallMessage.style.transform = 'translate(-50%, -50%)'
          smallMessage.style.zIndex = '9999'
          document.body.appendChild(smallMessage)
        }
        window.location.reload()
        console.log(res)
      }
    } catch (e) {
      console.log(e)
    }
  }

export const AddInstructorAvailableDatesAction = (formData, navigate) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const addAvailableDatesInstructor = `${process.env.REACT_APP_BACKEND_API}/teacherDate`

    const res = await axios.post(addAvailableDatesInstructor, formData, config)

    if (res.status === 200) {
      swal('Success', res.data.message, 'success')
    }

    dispatch(res())
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e?.response?.data?.message, 'error')
    }
  }
}

export const AddInstructorAvailableDatesuserlistAction =
  (formData, navigate) => async (dispatch) => {
    try {
      const token = localStorage.getItem('authToken')

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }

      const addAvailableDatesInstructor = `${process.env.REACT_APP_BACKEND_API}/teacherDate`

      const res = await axios.post(addAvailableDatesInstructor, formData, config)

      if (res.status === 200) {
        swal('Success', res.data.message, 'success')
      }

      dispatch(res())
    } catch (e) {
      if (e?.response?.data.message) {
        swal('Error', e?.response?.data?.message, 'error')
      }
    }
  }

export const GetInstructorAvailabilty = (instid) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getinsid = `${process.env.REACT_APP_BACKEND_API}/checkAvaibality/${instid}`

    const res = await axios.get(getinsid, config)

    await dispatch(getavailibilityInstslice(res))
  } catch (e) {
    console.log(e)
  }
}

export const GetInstructorAvailabiltyy = (selectedInstructorId) => async (dispatch) => {
  try {
    // debugger
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getinsid = `${process.env.REACT_APP_BACKEND_API}/checkAvaibality/${selectedInstructorId}`

    const res = await axios.get(getinsid, config)

    await dispatch(getavailibilityyInstslice(res))
  } catch (e) {
    console.log(e)
  }
}

export const AddInstructorAdminAction = (formData, navigate) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const addInstructor = `${process.env.REACT_APP_BACKEND_API}/instructoradd`

    const res = await axios.post(addInstructor, formData, config)


    if (res.status === 200) {
      swal('Success', res.data.message, 'success')
    }
    dispatch(res())
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e?.response?.data?.message, 'error')
    }
  }
}

export const InstructorCoursesAction = (instid) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getuserid = `${process.env.REACT_APP_BACKEND_API}/teacherCourses/${instid}`

    const res = await axios.get(getuserid, config, '')

    await dispatch(getInstructorCoursesslice(res))
  } catch (e) {
    console.log(e)
  }
}

export const InstructorCoursesInvoiceAction = (instid) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getuserid = `${process.env.REACT_APP_BACKEND_API}/teacherAllCourses/${instid}`

    const res = await axios.get(getuserid, config, '')

    await dispatch(getInstructorInvoiceCoursesslice(res))
  } catch (e) {
    console.log(e)
  }
}

export const InstructorCoursesComplitionUpdateAction = (Id) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const updateCourseStatus = `${process.env.REACT_APP_BACKEND_API}/updateCourseComplitionStatus/${Id}`
    const res = await axios.put(updateCourseStatus, config, '')

    if (res.status == 200) {
      swal('Success', res.data.message, 'success')
      window.location.reload()
    }
  } catch (e) {
    console.log(e)
  }
}

export const InstructorCalanderCoursesAction = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getuserid = `${process.env.REACT_APP_BACKEND_API}/nextSixMonth`

    const res = await axios.get(getuserid, config, '')

    await dispatch(getInstructorCalanderDatesslice(res))
  } catch (e) {
    console.log(e)
  }
}

export const InstructorCalanderSheduleClassAction = (instid, activeMonth) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getuserid = `${process.env.REACT_APP_BACKEND_API}/teacher/calendar/${instid}/${activeMonth}`

    const res = await axios.get(getuserid, config, '')

    await dispatch(getCalanderCourseSheduleslice(res))
  } catch (e) {
    console.log(e)
  }
}

export const GenerteInvoiceAction = (formData) => async (dispatch) => {
  try {
    // debugger
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const crestechap = `${process.env.REACT_APP_BACKEND_API}/invoiceInstructor`

    const res = await axios.post(crestechap, formData, config)
    if (res.status === 200) {
      swal('Success', res.data.message, 'success')
    }
    window.location.reload()

    dispatch(res())
  } catch (e) {
    console.log(e)
  }
}

export const InstructorInvoiceUpdateAction = (Id) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const updateCourseStatus = `${process.env.REACT_APP_BACKEND_API}/updateInvoiceStatus/${Id}`
    const res = await axios.put(updateCourseStatus, config, '')

    if (res.status == 200) {
      swal('Success', res.data.message, 'success')
      window.location.reload()
    }
  } catch (e) {
    console.log(e)
  }
}

export const Instructor_getallmemberinvoiceAction = (invoiceid) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getuserid = `${process.env.REACT_APP_BACKEND_API}/getallmemberinvoice/${invoiceid}`

    const res = await axios.get(getuserid, config, '')

    await dispatch(getallInvoiceslice(res))
  } catch (e) {
    console.log(e)
  }
}

export const sendMeetingAction = (formData, meetingId) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const updateCourseStatus = `${process.env.REACT_APP_BACKEND_API}/updateZoomLink/${meetingId}`
    const res = await axios.put(updateCourseStatus, formData, config)

    if (res.status === 200) {
      swal('Success', res.data.message, 'success')
    }
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e?.response?.data?.message, 'error')
    }
    console.log(e)
  }
}

export const sendReviwAction = (instid) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const updateCourseStatus = `${process.env.REACT_APP_BACKEND_API}/submitForReview/${instid}`
    const res = await axios.put(updateCourseStatus, config, '')

    if (res.status == 200) {
      swal('Success', res.data.message, 'success')
    }
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e?.response?.data?.message, 'error')
    }
    console.log(e)
  }
}

export const getAllinvoices = () => async (dispatch) => {
  try {
    // debugger;
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const allinvoices = process.env.REACT_APP_BACKEND_API + '/getAllinvoices'
    const res = await axios.get(allinvoices, '', config)

    await dispatch(getAllinvoicesslice(res))
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e?.response?.data?.message, 'error')
    }
  }
}

export const updateInstructor = async (formData, invoiceId) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const updateinvoices = process.env.REACT_APP_BACKEND_API + `/invoiceupdate/${invoiceId}`
    const res = await axios.put(updateinvoices, formData, config)
    if (res.status == 200) {
      swal('Success', res.data.message, 'success')
    }
    window.location.reload()
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e?.response?.data?.message, 'error')
    }
  }
}

export const FeedbackAction = (formData, navigate) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const feedback = `${process.env.REACT_APP_BACKEND_API}/feedback`

    const res = await axios.post(feedback, formData, config)

    if (res.status === 200) {
      swal('Success', res.data.message, 'success')

      .then(() => {
        window.location.reload();
      });
    }

    dispatch(res())
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e?.response?.data?.message, 'error')
    }
  }
}

export const zakTokenAction = (link) => async (dispatch) => {
  // debugger;/
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const feedback = `${process.env.REACT_APP_BACKEND_API}/zakToken?link=${link}`

    const res = await axios.get(feedback, config)

    if (res.status === 200) {
      //swal('success', res.data.message, 'success')

      window.open(res.data.link, '_blank')
    }

    dispatch(res())
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e?.response?.data?.message, 'error')
    }
  }
}

export const TeacherCoursesAction = (instid) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const teachercourse = `${process.env.REACT_APP_BACKEND_API}/teacherCourses/${instid}`

    const res = await axios.get(teachercourse, config, '')

    await dispatch(getallteachercourseslice(res))
  } catch (e) {
    console.log(e)
  }
}

export const TeacherDateAction = (formData, setLoading) => async (dispatch) => {
  try {
    // debugger
    setLoading(true)
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const teacherDate = `${process.env.REACT_APP_BACKEND_API}/teacherDate`

    const res = await axios.post(teacherDate, formData, config)

    if (res.status === 200) {
      swal('Success', res.data.message, 'success')
        .then(() => {
          window.location.reload();
        });
    }

    dispatch(res())
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e?.response?.data?.message, 'error')
    }
  } 
  finally {
    setLoading(false)
  }
}

export const singledateBookings = (formData, setLoading) => async (dispatch) => {
  try {
    debugger
    setLoading(true)
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const teacherDate = `${process.env.REACT_APP_BACKEND_API}/singledateBookings`

    const res = await axios.post(teacherDate, formData, config)

    if (res.status === 200) {
      swal('Success', res.data.message, 'success')
        .then(() => {
          window.location.reload();
        });
    }

    dispatch(res())
  } catch (e) {
    if (e.response && e.response.data && e.response.data.message) {
      swal('Error', e.response.data.message, 'error');
    }
  } 
  finally {
    setLoading(false)
  }
}

export const InstructorFilterAction = (courseId, startDate, endDate) => async (dispatch) => {
  try {
    // debugger
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getInstructorFilter = `${process.env.REACT_APP_BACKEND_API}/getInstructorFilter/${courseId}/${startDate}/${endDate}`

    const res = await axios.get(getInstructorFilter, config)

    await dispatch(getInstructorFilterslice(res))
  } catch (e) {
    console.log(e)
  }
}

export const UpdateBookedTeacherAction = (selectedbookId, formData) => async (dispatch) => {
  try {
    debugger
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const updateBookedTeacher = `${process.env.REACT_APP_BACKEND_API}/updateBookedTeacher/${selectedbookId}`
    const res = await axios.put(updateBookedTeacher, formData, config)

    if (res.status == 200) {
      swal('Success', res.data.message, 'success')
        .then(() => {
          window.location.reload();
        });
    }
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e?.response?.data?.message, 'error')
    }
  }
}

export const getAllAvaibilityAction = (selectedbookId) => async (dispatch) => {
  try {
    // debugger;
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getAllAvaibility = `${process.env.REACT_APP_BACKEND_API}/getAllAvaibilityById/${selectedbookId}`
    const res = await axios.get(getAllAvaibility, '', config)

    await dispatch(getAllAvaibilityslice(res))
  } catch (e) {
    console.log(e)
    if (e?.response?.data.message) {
      swal('Error', e?.response?.data?.message, 'error')
    }
  }
}


export const TransferBookingAction = (newId, prevId, BookingId, CourseId, navigate) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const transferBooking = `${process.env.REACT_APP_BACKEND_API}/transferBookings/${newId}/${prevId}/${BookingId}`
    const res = await axios.put(transferBooking, config, '')

    if (res.status == 200) {
      swal('Success', res.data.message, 'success')
      navigate(`/checkInstructorAvaibility/${CourseId}`)
    }
  } catch (e) {
    console.log(e)
  }
}