import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {Button} from '@mui/material'

import Form from 'react-bootstrap/Form'
import {useEffect} from 'react'

import {Link, useNavigate} from 'react-router-dom'

import {useDispatch, useSelector} from 'react-redux'
import FormData from 'form-data'
import {useForm} from 'react-hook-form'

import {useParams} from 'react-router-dom'

import {UpdateChapterAction} from '../../../../Redux/Action/createChapterAction'
import {GetallchapterEdit} from '../../../../Redux/Action/createChapterAction'
import CreateIcon from '@mui/icons-material/Create'
import "../coursedetails.css"
const EditChapter = () => {
  const dispatch = useDispatch()

  const { selectedInstructorId } = useParams();

  const navigate = useNavigate()

  const [show, setShow] = useState(false)
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm()

  var newURL = window.location

  var splitURL = newURL.href.split('/')

  const courseId = splitURL[4]



  const getallChapterid = useSelector((state) => {
  
    return state.login.getallchapteredit_list
  })




  useEffect(() => {

    const selectedInstructorId = localStorage.getItem('selectedInstructorId');
    
    dispatch(GetallchapterEdit(selectedInstructorId))
  }, [dispatch])

  useEffect(() => {
    

    setValue('chaptername', getallChapterid?.data && getallChapterid?.data[0].chaptername)

    setValue('desc', getallChapterid?.data && getallChapterid?.data[0].desc)
  }, [getallChapterid])

  const onSubmit = (data) => {

    const formData = new FormData()
    formData.append('chaptername', data.chaptername)
    formData.append('desc', data.desc)
    formData.append('course_id', courseId)

    dispatch(UpdateChapterAction(formData, selectedInstructorId))
  }


  const handleCancel = () => {
    setShow(false)
   
  }
  return (
    <div>
      <div>
        <Link to='#' onClick={() => setShow(true)}>
          <span className='text float-right  border-radius  p-0' style={{color: 'black'}}>
            <CreateIcon />
          </span>
        </Link>
      </div>
      <Modal show={show} onHide={() => setShow(false)} centered onClick={handleCancel}>
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              textTransform: 'capitalize',
              letterSpacing: '.2rem',
              fontWeight: 500,
              fontSize: '1rem',
            }}
          >
            Edit Chapter
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              padding: '2rem',
              borderRadius: '.375rem',
            }}
          >
            <div>
              <label>chaptername</label>
              <input
                className='form-control mb-4'
                type='text'
                name='chaptername'
                {...register('chaptername', {
                  required: 'This is required field',
                })}
                aria-invalid={errors.chaptername ? 'true' : 'false'}
              />
              {errors.chaptername && (
                <p className='alert_danger' role='alert' style={{marginTop: '1rem'}}>
                  {errors.chaptername?.message}
                </p>
              )}
            </div>
            <div>
              <label>desc</label>
              <input
                className='form-control mb-4'
                type='text'
                name='desc'
                {...register('desc', {
                  required: 'This is required field',
                })}
                aria-invalid={errors.desc ? 'true' : 'false'}
              />
              {errors.desc && (
                <p className='alert_danger' role='alert' style={{marginTop: '1rem'}}>
                  {errors.desc?.message}
                </p>
              )}
            </div>
            <Button
              style={{
                textAlign: 'right',
                width: ' 100%',
                color: '#ffff',
                fontSize: '.8rem',
                borderRadius: '.375rem',
                backgroundColor: 'rgb(81, 86, 190)',
                textTransform: 'capitalize',
                letterSpacing: '.2rem',
              }}
              type='submit'
              className=' btn-warning'
              disableRipple
            >
              save
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default EditChapter
