import React, {useEffect, useState} from 'react'
import {GetAllMeeting_byidAction} from '../../../Redux/Action/zoomAction'

import {useDispatch, useSelector} from 'react-redux'
import {CopyToClipboard} from 'react-copy-to-clipboard'

import {Link} from 'react-router-dom'

const Meetingsdetails = () => {
  const meetingid = localStorage.getItem('userid')

  const dispatch = useDispatch()

  const meetingdetails = useSelector((state) => {
    // debugger
    return state.login.getallmeeting_idList
  })

  useEffect(() => {
    // debugger
    dispatch(GetAllMeeting_byidAction(meetingid))
  }, [dispatch])
  let cdata = ''

  // const [copyStatuses, setCopyStatuses] = useState(new Array(meetingdetails.length).fill(''))
  const [startUrlCopyStatuses, setStartUrlCopyStatuses] = useState(
    new Array(meetingdetails.length).fill('')
  )
  const [joinUrlCopyStatuses, setJoinUrlCopyStatuses] = useState(
    new Array(meetingdetails.length).fill('')
  )

  // Function to handle copying for a specific meeting and URL type (start or join)
  const handleCopy = (item, setCopyStatuses) => {
    return () => {
      setCopyStatuses((prevStatuses) => {
        const newStatuses = [...prevStatuses]
        newStatuses[item] = 'Copied'
        setTimeout(() => {
          newStatuses[item] = ''
          setCopyStatuses(newStatuses)
        }, 2000) // Reset the message after 2 seconds
        return newStatuses
      })
    }
  }
  return (
    <div>
      {/* {Object.entries(meetingdetails).map(([key,course], i) => {
        if (key == 'data') {
          cdata = course
        }
      })}

      {Object.entries(cdata).map(([key, item], i) => {
        return (
          <div>
            <h1>{item.title}</h1>
            <a>{item.id}</a>
            <a>{item.time}</a>
           
            <a>{item.startUrl}</a>
            <div className='col-2 p-0 text-right'>
              <CopyToClipboard text={item.joinUrl} onCopy={() => setCopied(true)}>
                <div className='copy'>
                  <a>
                    <i
                      className='fa-sharp fa-solid fa-copy'
                      onMouseLeave={() => setCopied(false)}
                      disabled={
                        window.ethereum?.selectedAddress && localStorage.getItem('authToken')
                          ? false
                          : true
                      }
                    ></i>
                  </a>
                </div>
              </CopyToClipboard>
            </div>

           
          </div>
        )
      })} */}

      {/* <div className='myactivity'>
        <div class='card card-flush h-xl -100'>
         
          <div class='col-md-12'>
            <div class='bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5'>
              <div className=''>
                <div className='row' style={{display: 'flex', justifyContent: 'center'}}>
                  <div className='col-md-3'>
                    <div className='card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-100  mb-5 mb-xl-10 pb-20'>
                      <div className='card-header pt-5 d-flex justify-content-center align-items-center'>
                        {' '}
                     
                        <div className='card-title d-flex flex-column'>
                          <AddIcon style={{fontSize: '7rem'}} />
                          <Link to='/zoom'>
                            <span style={{fontSize: '20px'}}>Add Meetings</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-3'>
                    <div className='card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-100  mb-5 mb-xl-10 pb-20'>
                      <div className='card-header pt-5 '>
                        {Object.entries(meetingdetails).map(([key, course], i) => {
                          if (key == 'data') {
                            cdata = course
                          }
                        })}

                        {Object.entries(cdata).map(([key, item], i) => {
                          return (
                            <div className='card-title d-flex flex-column'>
                              <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2'></span>

                              <span className='text-black '>Title</span>
                              <span className='text-black'>Title</span>
                              <span className='text-black '>Title</span>
                              <span className='text-black '>Title</span>
                              <span className='text-black '>Title</span>
                              <span className='text-black '>Title</span>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class='card-body mt-n20'></div>
            </div>
          </div>
        </div>
      </div> */}

      <div className='myactivity'>
        {/* <!--begin::Heading--> */}

        <div class='col-md-12'>
          <div class='bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5'>
            <div className=''>
              <div className='row' style={{display: 'flex', justifyContent: 'center'}}>
                <div className='col-md-3' style={{marginBottom: ' 14px'}}></div>
                {Object.entries(meetingdetails).map(([key, course], i) => {
                  if (key == 'data') {
                    cdata = course
                  }
                })}

                {Object.entries(cdata).map(([key, item], i) => {
                  return (
                    <div className='col-md-12 mb-4'>
                      {' '}
                      {/* Add the 'mb-4' class to add a margin at the bottom */}
                      <div
                        className='card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-100  mb-5 mb-xl-10 pb-20 '
                        style={{borderTop: '2px solid #021F59'}}
                      >
                        <div className='card-header pt-5 border-bottom'>
                          <div className='card-title d-flex flex-column'>
                            <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2'></span>
                            <span className='text-start text-muted fw-bold fs-6 text-uppercase gs-0'>
                              Meeting Id: {item.meetingId}
                            </span>
                            <span className='text-start text-muted fw-bold fs-6 gs-0'>
                              Title: {item.title}
                            </span>
                            <span className='text-start text-muted fw-bold fs-6  gs-0'>
                              Time: {item.time}
                            </span>
                            <span className='text-start text-muted fw-bold fs-6  gs-0'>
                              Duration: {item.duration} minutes
                            </span>
                            <span className='text-start text-muted fw-bold fs-6  gs-0'>
                              Timezone: {item.Timezone}
                            </span>

                            <span
                              className='text-start text-muted fw-bold fs-6 gs-0'
                              style={{fontSize: '15px', display: 'flex'}}
                            >
                              <span> Join URL:</span>
                              <Link to='#'>
                                <div style={{display: 'flex'}}>
                                  {item.joinUrl}
                                  <CopyToClipboard
                                    text={item.joinUrl}
                                    onCopy={handleCopy(i, 'joinUrl', setJoinUrlCopyStatuses)}
                                  >
                                    <div className='copy'>
                                      <a>
                                        <i
                                          style={{fontSize: '20px'}}
                                          className={`fa-regular fa-copy`}
                                          disabled={
                                            !window.ethereum?.selectedAddress ||
                                            !localStorage.getItem('authToken')
                                          }
                                        ></i>
                                      </a>
                                    </div>
                                  </CopyToClipboard>
                                </div>
                                {joinUrlCopyStatuses[i] && (
                                  <span style={{color: 'green', marginLeft: '5px'}}>
                                    {joinUrlCopyStatuses[i]}
                                  </span>
                                )}
                              </Link>
                            </span>
                            <span
                              className='text-start text-muted fw-bold fs-6 gs-0'
                              style={{fontSize: '15px', display: 'flex'}}
                            >
                              <span>START URL:</span>
                              <Link to='#'>
                                <div style={{display: 'flex'}}>
                                  {item.startUrl.slice(0, 150)}
                                  <CopyToClipboard
                                    text={item.startUrl}
                                    onCopy={handleCopy(i, 'startUrl', setStartUrlCopyStatuses)}
                                  >
                                    <div className='copy'>
                                      <a>
                                        <i
                                          style={{fontSize: '20px'}}
                                          className={`fa-regular fa-copy`}
                                          disabled={
                                            !window.ethereum?.selectedAddress ||
                                            !localStorage.getItem('authToken')
                                          }
                                        ></i>
                                      </a>
                                    </div>
                                  </CopyToClipboard>
                                </div>
                                {startUrlCopyStatuses[i] && (
                                  <span style={{color: 'green', marginLeft: '5px'}}>
                                    {startUrlCopyStatuses[i]}
                                  </span>
                                )}
                              </Link>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>

            <div class='card-body mt-n20'></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Meetingsdetails
