import React from 'react'
import Navbar from './Navbar/Navbar'
import Blogspage from './Blog/Blogpage'
import Footer from './Footer/Footer'

const Blogpage = () => {
  return (
    <div>
      <Navbar />
      <Blogspage />

      <Footer />
    </div>
  )
}

export default Blogpage
