import React from 'react'
import Navbar from './Navbar/Navbar'
import Consultancypage from './Consultancy/Consultancypage'
import Footer from './Footer/Footer'

const Consultancypagee = () => {
  return (
    <div>
      <Navbar />
      <Consultancypage />

      <Footer />
    </div>
  )
}

export default Consultancypagee
