import React, {useEffect, useState} from 'react'

import {useDispatch, useSelector} from 'react-redux'

import {GetallCourseAction} from '../../../Redux/Action/courseAction'
import {UpdatebundleAction} from '../../../Redux/Action/bundleAction'

import {useParams} from 'react-router-dom'
import './addbundle.css'
import {Link} from 'react-router-dom'

const AddBundlecourse = () => {
  const dispatch = useDispatch()

  // const id = useParams()
  const bundle_code = useParams()

  const Allcourse = useSelector((state) => {
    return state.login.getcourseslist
  })

  const allcourse = Allcourse.Published || []

  useEffect(() => {
    dispatch(GetallCourseAction())
  }, [dispatch])

  function nextPage() {
    if (currentPage !== nPage) {
      setCurrentPage(currentPage + 1)
    }
  }

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  function changeCPage(id) {
    setCurrentPage(id)
  }

  const [currentPage, setCurrentPage] = useState(1)

  const recordsPerPage = 10

  const lastIndex = currentPage * recordsPerPage

  const firstIndex = lastIndex - recordsPerPage

  const records = allcourse.slice(firstIndex, lastIndex)

  const nPage = Math.ceil(allcourse.length / recordsPerPage)

  const number = [...Array(nPage + 1).keys()].slice(1)

  const handleAddButtonClick = (courseslug, action) => {
    //debugger
    const formData = new FormData()
    if (action == 'add') {
      formData.append('bundle_code', bundle_code.bundle_code)
    } else {
      formData.append('bundle_code', bundle_code.bundle_code)
      formData.append('Typee', 'remove')
    }

    dispatch(UpdatebundleAction(formData, courseslug))
  }

  return (
    <div className='user-list'>
      <div id='kt_app_content_container'>
        <div class='card'>
          <div class='card-header border-0 pt-6'>
            <div class='card-title'>
              <h3 class='fw-bold m-0'>Add courses to bundle </h3>
            </div>
          </div>

          <div class='card-body py-4'>
            <div className='table-responsive'>
              <table class='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>
                <thead>
                  <tr class='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                    <th class='min-w-125px'>#</th>
                    <th class='min-w-125px'>Course Name</th>
                    <th class='min-w-125px'>Category</th>

                    <th class='min-w-125px'>Status</th>
                    <th class='min-w-125px'>Price</th>
                    <th class='min-w-125px'>Action</th>
                  </tr>
                </thead>

                <tbody class='text-gray-600 fw-semibold'>
                  {records.map((course, index) => {
                    const courseId = course.id
                    localStorage.setItem('paid_price', courseId)

                    return (
                      <tr key={index}>
                        <td>{firstIndex + index + 1}</td>
                        <td>{course.title}</td>
                        <td>{course.Course_Category}</td>
                        <td>
                          <div className='badge badge-light' style={{color: '#7e8299'}}>
                            {course.status}
                          </div>
                        </td>
                        <td>${course.paid_price}</td>

                        {/* <td>
                          {course.Bundle_code == null ||
                          !JSON.parse(course.Bundle_code || '[]').includes(
                            bundle_code.bundle_code
                          ) ? (
                            <button
                              className='btn cyberbtnallpage'
                              onClick={() => handleAddButtonClick(course.courseslug, 'add')}
                            >
                              Add Course
                            </button>
                          ) : (
                            <button
                              onClick={() => handleAddButtonClick(course.courseslug, 'remove')}
                              className='btn btn-secondary'
                            >
                              Remove Course
                            </button>
                          )}
                        </td> */}

                        <td>
                          {(() => {
                            let isBundleIncluded = false

                            try {
                              const bundleCodes = JSON.parse(course.Bundle_code || '[]')
                              isBundleIncluded = bundleCodes.includes(bundle_code.bundle_code)
                            } catch (error) {
                              console.error('Error parsing Bundle_code:', error)
                            }

                            if (course.Bundle_code == null || !isBundleIncluded) {
                              return (
                                <button
                                  className='btn cyberbtnallpage'
                                  onClick={() => handleAddButtonClick(course.courseslug, 'add')}
                                >
                                  Add Course
                                </button>
                              )
                            } else {
                              return (
                                <button
                                  onClick={() => handleAddButtonClick(course.courseslug, 'remove')}
                                  className='btn btn-secondary'
                                >
                                  Remove Course
                                </button>
                              )
                            }
                          })()}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            <nav>
              <ul className='pagination'>
                <li className='page-item'>
                  <Link to='#' className='page-link' onClick={prePage}>
                    Prev
                  </Link>
                </li>
                {number.map((n, i) => (
                  <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                    <Link to='#' className='page-link' onClick={() => changeCPage(n)}>
                      {n}
                    </Link>
                  </li>
                ))}
                <li className='page-item'>
                  <Link to='#' className='page-link' onClick={nextPage}>
                    Next
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddBundlecourse
