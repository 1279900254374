import React, {useEffect, useState} from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import {Link, useNavigate} from 'react-router-dom'
import {ArrowLeftOutlined} from '@ant-design/icons'
import {useDispatch, useSelector} from 'react-redux'
import {
  GetInstructorAvaibilityCoursesDetails,
  TransferBookingAction,
} from '../../../Redux/Action/instructorAction'

const SwitchBookings = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  var newURL = window.location
  var splitURL = newURL.href.split('/')

  const CourseId = splitURL[4]
  const instid = splitURL[5]
  const BookingId = splitURL[6]

  const courseavaibilitydetails = useSelector((state) => {
    return state.login.getaInstructorAvaibilityAdminlist
  })
  const message = courseavaibilitydetails.message || []
  const data = message.map((entry) => entry)

  useEffect(() => {
    dispatch(GetInstructorAvaibilityCoursesDetails(CourseId))
  }, [dispatch])

  function formatDate(dateString) {
    const [year, month, day] = dateString.split('-')
    const formattedDate = `${day}/${month}/${year}`
    return formattedDate
  }

  const [newId, setnewId] = useState('')

  const handleInputChange = (newId) => {
    setnewId(newId)
  }

  const onSubmit = () => {
    dispatch(TransferBookingAction(newId, instid, BookingId, CourseId, navigate))
  }

  return (
    <div>
      <Container fluid>
        <div className='instructor-lisss'>
          {data.map((item, index) => {
            return item.instructor_info && item.instructor_info.id == instid ? (
              <Row key={index}>
                <Col lg={12}>
                  <div>
                    <div>
                      <div className='card mb-5 ' id='kt_profile_details_view'>
                        <div className='card-header cursor-pointer'>
                          <div className='card-title m-0'>
                            <Link
                              to={`/checkInstructorAvaibility/${CourseId}`}
                              style={{fontSize: '20px', color: '#FF763F', fontWeight: 'bold'}}
                            >
                              <ArrowLeftOutlined />
                            </Link>
                            <h3 className='fw-bold m-0'>Booking Details</h3>
                          </div>
                        </div>{' '}
                        <div className='card-body p-9'>
                          <>
                            {item.availability.map((booking, index) => {
                              return booking.id == BookingId ? (
                                <div>
                                  <div className='row mb-7'>
                                    <label className='col-lg-4 fw-semibold text-muted fs-6'>
                                      Course
                                    </label>
                                    <div className='col-lg-8 d-flex align-items-center'>
                                      <span>{booking.CourseName}</span>
                                    </div>
                                  </div>
                                  <div className='row mb-7'>
                                    <label className='col-lg-4 fw-semibold text-muted fs-6'>
                                      Booked Instructor
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                      <span>
                                        {item.instructor_info.FirstName}{' '}
                                        {item.instructor_info.Surname} (
                                        {item.instructor_info.timezone})
                                      </span>
                                    </div>
                                  </div>
                                  <div className='row mb-7'>
                                    <label className='col-lg-4 fw-semibold text-muted fs-6'>
                                      Date
                                    </label>
                                    <div className='col-lg-8 d-flex align-items-center'>
                                      <span>
                                        {formatDate(booking.start_date)} -{' '}
                                        {formatDate(booking.end_date)}
                                      </span>
                                    </div>
                                  </div>
                                  {/* <div className='row mb-7'>
                                    <label className='col-lg-4 fw-semibold text-muted fs-6'>
                                      End date
                                    </label>
                                    <div className='col-lg-8 d-flex align-items-center'>
                                      <span>{formatDate(booking.end_date)}</span>
                                    </div>
                                  </div> */}
                                </div>
                              ) : (
                                <></>
                              )
                            })}
                          </>
                        </div>
                        <div class='card-header border-0 pt-6'>
                          <div class='card-title'>
                            <h3 className='fw-bold m-0'>Transfer Bookings</h3>
                          </div>
                        </div>
                        <div class='card-body py-4'>
                          {data.map((item, index) =>
                            item.instructor_info && item.instructor_info.id != instid ? (
                              <div className='d-flex mb-7'>
                                <input
                                  className='form-check-input'
                                  type='radio'
                                  name='yesNoRadio'
                                  id='yesRadio'
                                  value='yes'
                                  //checked={yesNoValue === 'yes'}
                                  onChange={() => handleInputChange(item.instructor_info.id)}
                                />
                                <div className='d-flex flex-column col-lg-1 align-items-center'>
                                  <span style={{color: '#7e8299'}}>
                                    {item.instructor_info.FirstName} {item.instructor_info.Surname}
                                  </span>
                                </div>
                                <span style={{color: '#7e8299'}}>
                                  ({item.instructor_info.timezone})
                                </span>
                              </div>
                            ) : null
                          )}
                          <button
                            className='btn cyberbtnallpage'
                            style={{
                              color: 'white',
                              width: '100px',
                              padding: '10px',
                              borderRadius: '5px',
                              fontWeight: 500,
                              marginTop:'2rem'
                            }}
                            onClick={onSubmit}
                          >
                            Transfer
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            ) : null
          })}
        </div>
      </Container>
    </div>
  )
}

export default SwitchBookings
