import axios from 'axios'
import {getAllCountriesslice, getStateByCountryslice, getCityByStateslice} from '../Slice/authSlice'

export const GetAllCountriesAction = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getinsid = `${process.env.REACT_APP_BACKEND_API}/getallCountries`

    const res = await axios.get(getinsid, config)
    await dispatch(getAllCountriesslice(res))
  } catch (e) {
    console.log(e)
  }
}

export const GetStateByCountryAction = (selectedCountryISOCode) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getinsid = `${process.env.REACT_APP_BACKEND_API}/getState/${selectedCountryISOCode}`

    const res = await axios.get(getinsid, config)
    await dispatch(getStateByCountryslice(res))
  } catch (e) {
    console.log(e)
  }
}

export const GetCityByStateAction =
  (selectedCountryISOCode, selectedStateISOCode) => async (dispatch) => {

    try {
      const token = localStorage.getItem('authToken')

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }

      const getinsid = `${process.env.REACT_APP_BACKEND_API}/getCityByStateCode/${selectedCountryISOCode}/${selectedStateISOCode}`

      const res = await axios.get(getinsid, config)

      await dispatch(getCityByStateslice(res))
    } catch (e) {
      // console.log(e);
    }
  }
