import React from 'react'
import Navbar from './Navbar/Navbar'
import Blogdetailspage from './Blog/BlogDetailpage'
import Footer from './Footer/Footer'

const Blogdetail = () => {
  return (
    <div>
      <Navbar />
      <Blogdetailspage />

      <Footer />
    </div>
  )
}

export default Blogdetail
