import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {
  RecentCoursesAction,
  RecentSevenDaysCoursesAction,
  RecentMonthlyCoursesAction,
  todayRecentAction,
  yesterdayRecentAction,
} from '../../../../../Redux/Action/admindashboardAction'
import {Link} from 'react-router-dom'

const Selectrange = () => {
  const dispatch = useDispatch()
  const [selectedOption, setSelectedOption] = useState('all')

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value)
  }
  const recentCourses = useSelector((state) => {
    return state.login.getRecentCoursesList
  })

  useEffect(() => {
    dispatch(RecentCoursesAction())
  }, [dispatch])

  const recentSevenDaysCourses = useSelector((state) => {
    return state.login.getRecentSevenDaysCoursesList
  })

  useEffect(() => {
    dispatch(RecentSevenDaysCoursesAction())
  }, [dispatch])

  const recentMontlyCourses = useSelector((state) => {
    return state.login.getRecentMontlyCoursesList
  })

  useEffect(() => {
    dispatch(RecentMonthlyCoursesAction())
  }, [dispatch])

  const todayRecentCourses = useSelector((state) => {
    return state.login.gettodayRecentList
  })

  useEffect(() => {
    dispatch(todayRecentAction())
  }, [dispatch])

  const yesterdayRecentCourses = useSelector((state) => {
    return state.login.getyesterdayRecentList
  })

  useEffect(() => {
    dispatch(yesterdayRecentAction())
  }, [dispatch])

  // function formatDate(inputDate) {
  //   const dateObj = new Date(inputDate)
  //   const formattedDate = dateObj.toLocaleDateString('en-GB')

  //   return formattedDate
  // }

  function formatDate(inputDate) {
    const dateObj = new Date(inputDate)

    const day = dateObj.getDate()
    const month = dateObj.toLocaleString('default', {month: 'short'})
    const year = dateObj.getFullYear().toString().slice(-2)

    const formattedDate = `${day} ${month} ${year}`

    return formattedDate
  }

  return (
    <div>
      <div className='card card-flush h-xl-100'>
        <div className='card-header pt-7'>
          <h3 className='card-title align-items-start flex-column' style={{paddingBottom: '1rem'}}>
            <span className='card-label fw-bold text-gray-800'>Recently Purchased Courses</span>
          </h3>

          <div
            className='card-toolbar'
            style={{border: '1px solid #ccc', borderRadius: '4px', padding: '10px'}}
          >
            <select
              value={selectedOption}
              onChange={handleSelectChange}
              style={{
                border: 'none',
                outline: 'none',
                fontSize: '12px',
                backgroundColor: 'white',
                borderRadius: '4px',
                width: '100%',
              }}
            >
              <option value='all'>Select Date Range</option>
              <option value='today'>Today</option>
              <option value='yesterday'>Last 24 Hrs</option>
              <option value='7days'>Last 7 Days</option>
              <option value='monthly'>Last Month</option>
            </select>
          </div>
        </div>

        <div className='card-body pt-3 pb-4'>
          <div className='table-responsive'>
            <table className='table table-row-dashed align-middle gs-0 gy-4 my-0'>
              <thead>
                <tr className='fs-7 fw-bold text-gray-500 border-bottom-0'>
                  <th className='p-0'>#</th>
                  <th className='p-0 w-xxl-400px'>Course Name</th>
                  <th className='p-0 min-w-200px'>Student Name</th>
                  <th className='p-0 min-w-100px'>Price</th>
                  <th className='p-0 min-w-125px'>Date Purchased </th>
                </tr>
              </thead>
              {selectedOption === 'all' && (
                <>
                  <tbody>
                    {recentCourses.slice(0, 4).map((recentCourse, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              {recentCourse.title}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              {recentCourse.FirstName}
                            </div>
                          </div>
                        </td>
                        <td className='text-start'>
                          <span className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                          £{recentCourse.paid_price}
                          </span>
                        </td>
                        <td className='text-start'>
                          <span className='fw-semibold text-gray-800 d-block'>
                            {formatDate(recentCourse.paymenttime)}
                          </span>
                        </td>
                      </tr>
                    ))}
                    {recentCourses.length < 5 && (
                      <>
                        {Array(5 - recentCourses.length)
                          .fill('')
                          .map((_, index) => (
                            <tr key={index + recentCourses.length}>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                            </tr>
                          ))}
                      </>
                    )}

                    <tr>
                      <td colSpan='5' className='text-end'>
                        <Link
                          to='/AllRecent'
                          className='btn btn-sm fw-bold cyberbtnallpage'
                          style={{background: 'rgb(255, 118, 63)', color: 'white'}}
                        >
                          View All
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </>
              )}

              {selectedOption === 'today' && (
                <>
                  <tbody>
                    {todayRecentCourses.slice(0, 5).map((todayCourse, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              {todayCourse.title}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              {todayCourse.FirstName}
                            </div>
                          </div>
                        </td>
                        <td className='text-start'>
                          <span className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                          £{todayCourse.paid_price}
                          </span>
                        </td>
                        <td className='text-start'>
                          <span className='fw-semibold text-gray-400 d-block'>
                            {formatDate(todayCourse.paymenttime)}
                          </span>
                        </td>
                      </tr>
                    ))}
                    {todayRecentCourses.length < 5 && (
                      <>
                        {Array(5 - todayRecentCourses.length)
                          .fill('')
                          .map((_, index) => (
                            <tr key={index + todayRecentCourses.length}>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                            </tr>
                          ))}
                      </>
                    )}

                    <tr>
                      <td colSpan='5' className='text-end'>
                        <Link
                          to='/todayRecent'
                          className='btn btn-sm fw-bold '
                          style={{background: 'rgb(255, 118, 63)', color: 'white'}}
                        >
                          View All
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </>
              )}
              {selectedOption === 'yesterday' && (
                <>
                  <tbody>
                    {yesterdayRecentCourses.slice(0, 5).map((yesterdayrecentCourse, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              {yesterdayrecentCourse.title}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              {yesterdayrecentCourse.FirstName}
                            </div>
                          </div>
                        </td>
                        <td className='text-start'>
                          <span className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                          £{yesterdayrecentCourse.paid_price}
                          </span>
                        </td>
                        <td className='text-start'>
                          <span className='fw-semibold text-gray-400 d-block'>
                            {formatDate(yesterdayrecentCourse.paymenttime)}
                          </span>
                        </td>
                      </tr>
                    ))}
                    {yesterdayRecentCourses.length < 5 && (
                      <>
                        {Array(5 - yesterdayRecentCourses.length)
                          .fill('')
                          .map((_, index) => (
                            <tr key={index + yesterdayRecentCourses.length}>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                            </tr>
                          ))}
                      </>
                    )}

                    <tr>
                      <td colSpan='5' className='text-end'>
                        <Link
                          to='/yesterdayRecent'
                          className='btn btn-sm fw-bold '
                          style={{background: 'rgb(255, 118, 63)', color: 'white'}}
                        >
                          View All
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </>
              )}
              {selectedOption === '7days' && (
                <>
                  <tbody>
                    {recentSevenDaysCourses.slice(0, 5).map((recentSevendays, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              {recentSevendays.title}
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              {recentSevendays.FirstName}
                            </div>
                          </div>
                        </td>
                        <td className='text-start'>
                          <span className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                          £{recentSevendays.paid_price}
                          </span>
                        </td>
                        <td className='text-start'>
                          <span className='fw-semibold text-gray-400 d-block'>
                            {formatDate(recentSevendays.paymenttime)}
                          </span>
                        </td>
                      </tr>
                    ))}
                    {recentSevenDaysCourses.length < 5 && (
                      <>
                        {Array(5 - recentSevenDaysCourses.length)
                          .fill('')
                          .map((_, index) => (
                            <tr key={index + recentSevenDaysCourses.length}>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                            </tr>
                          ))}
                      </>
                    )}

                    <tr>
                      <td colSpan='5' className='text-end'>
                        <Link
                          to='/sevenDaysRecent'
                          className='btn btn-sm fw-bold '
                          style={{background: 'rgb(255, 118, 63)', color: 'white'}}
                        >
                          View All
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </>
              )}
              {selectedOption === 'monthly' && (
                <>
                  <tbody>
                    {recentMontlyCourses.slice(0, 5).map((recentMontly, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              {recentMontly.title}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              {recentMontly.FirstName}
                            </div>
                          </div>
                        </td>
                        <td className='text-start'>
                          <span className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                          £{recentMontly.paid_price}
                          </span>
                        </td>
                        <td className='text-start'>
                          <span className='fw-semibold text-gray-400 d-block'>
                            {formatDate(recentMontly.paymenttime)}
                          </span>
                        </td>
                      </tr>
                    ))}
                    {recentMontlyCourses.length < 5 && (
                      <>
                        {Array(5 - recentMontlyCourses.length)
                          .fill('')
                          .map((_, index) => (
                            <tr key={index + recentMontlyCourses.length}>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                            </tr>
                          ))}
                      </>
                    )}

                    <tr>
                      <td colSpan='5' className='text-end'>
                        <Link
                          to='/monthlyRecent'
                          className='btn btn-sm fw-bold '
                          style={{background: 'rgb(255, 118, 63)', color: 'white'}}
                        >
                          View All
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </>
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Selectrange
