import React from 'react'
import Navbar from './Navbar/Navbar'
import Aboutus from './AboutUs/Aboutus'
import Footer from './Footer/Footer'

const Aboutpage = () => {
  return (
    <div>
      <Navbar />
      <Aboutus />

      <Footer />
    </div>
  )
}

export default Aboutpage
