import {userlistslice} from '../Slice/authSlice'
import {
  getuseridslice,
  getStudentPaymentHistoryslice,
  getstudentlistslice,
  getparticipantslistslice,
  emailPreviewSlice
} from '../Slice/authSlice'

import swal from 'sweetalert'
import axios from 'axios'

export const LoginAction =
  (formData, navigate, setFormError, setFormSuccess, setLoading, setRole) => async (dispatch) => {
    try {
      setLoading(true)
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      const url = `${process.env.REACT_APP_BACKEND_API}/login`

      const res = await axios.post(url, formData, config)

      const data = res?.data

      localStorage.setItem('authToken', data?.Token)
      localStorage.setItem('userid', data?.userID)
      localStorage.setItem('roleid', data?.data?.roleid)
      localStorage.setItem('FirstName', data?.firstname)
      localStorage.setItem('Surname', data?.lastname)
      localStorage.setItem('timezone', data?.timezone)
      localStorage.setItem('forceReset', data?.forceReset)
      localStorage.setItem('forceResetOrg', data?.forceReset)
      const forceReset = data?.forceReset

      if (forceReset != null && forceReset != '0' && forceReset != '1') {
        localStorage.removeItem('authToken')
        window.location.replace('/changepwd')
      } else if (forceReset == '1') {
        if (res.status === 200) {
          const roleRoutes = {
            1: '/dashboard',
            2: '/dashboard',
            3: '/mydashboard',
            4: '/My_profile',
            5: '/Content_Writers',
            6: '/Corporates_Manager',
            7: '/Corporate_Employees',
          }
          localStorage.setItem('forceResetOrg', 1)
          const roleid = data?.data?.roleid
          const route = roleRoutes[roleid]

          if (route) {
            window.location.replace(route)
          } else {
          }
        }
      } else {
        if (res.status === 200) {
          const roleRoutes = {
            1: '/dashboard',
            2: '/dashboard',
            3: '/mydashboard',
            4: '/instructors',
            5: '/Content_Writers',
            6: '/Corporates_Manager',
            7: '/Corporate_Employees',
          }

          const roleid = data?.data?.roleid
          const route = roleRoutes[roleid]

          if (route) {
            window.location.replace(route)
          } else {
          }
        }
      }
      localStorage.setItem('forceReset', 0)
      dispatch()
    } catch (e) {
      if (e?.response?.data?.message) {
        swal('Error', e.response.data.message, 'error')
      } else {
      }
    } finally {
      setLoading(false)
    }
  }

export const LoginandPayAction = (formData, setLoading) => async (dispatch) => {
  try {
    // debugger
    setLoading(true)
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const loginandpay = process.env.REACT_APP_BACKEND_API + '/loginandpay'

    const res = await axios.post(loginandpay, formData, config)

    localStorage.setItem('authToken', res?.data.data?.Token)
    localStorage.setItem('roleid', res?.data.data?.roleid)
    localStorage.setItem('forceReset', res?.data.data?.forceReset)
    localStorage.setItem('userid', res?.data.data?.userID)

    if (res.status === 200) {
      if (res.data.paymethod === '1') {
        window.open(`${res.data.paymentLink}`, '_self')
      } else if (res.data.paymethod === '2') {
        window.location = res.data.redirect_url
      } else {
        window.location = '/Loginandpayment'
      }
    }
    dispatch()
  } catch (e) {
    if (e?.response?.data?.message) {
      swal('Error', e.response.data.message, 'error')
    } else {
    }
  } finally {
    setLoading(false)
  }
}

export const RegisterAction =
  (
    formData,

    setLoading
  ) =>
  async (dispatch) => {
    //debugger
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      const signupurl = process.env.REACT_APP_BACKEND_API + '/signup'
      const res = await axios.post(signupurl, formData, config)
      if (res.status === 200) {
        if (res.data.paymethod === '1') {
          window.open(`${res.data.paymentLink}`, '_self')
        } else {
          if (res.data.paymethod === '2') {
            window.location = res.data.redirect_url
          }
        }
      }
    } catch (e) {
      if (e?.response?.data?.message) {
        let responseMsg = ''
        console.log( e?.response , "Responsee")

        if(e?.response?.data?.message  == 'User Already Exists With this Email.'){
          const msg = e?.response?.data?.message;
          responseMsg = `${msg} <br/> <a href='/login'>Click here to login.</a>`
        }else{
          responseMsg = e?.response?.data?.message
        }
        swal({
          title: 'Error',
          icon: 'error',
          content: {
              element: 'div',
              attributes: {
                  innerHTML: responseMsg
              },
          },
      });
        setLoading(false)
      }
    }
  }

export const UserListAction = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const ulisturl = process.env.REACT_APP_BACKEND_API + '/getuserdetails'
    const res = await axios.get(ulisturl, '', config)

    await dispatch(userlistslice(res))
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e?.response?.data?.message, 'error')
    }
  }
}


export const ForgotPasswordAction = (formData, setLoading) => async (dispatch) => {
  try {
    setLoading(true);
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const forgoturl = process.env.REACT_APP_BACKEND_API + '/forgot-password';
    const res = await axios.post(forgoturl, formData, config);

    if (res.status === 200) {
      swal('Success', res.data.message, 'success').then(function () {
        window.location.reload();
      });
    } 
  } catch (error) {
    swal('Error', error.response.data.message || 'An error occurred', 'error');
  } finally {
    setLoading(false);
  }
};

export const ResetPasswordAction = (formData, userid, resettoken, navigate) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const forgoturl = `${process.env.REACT_APP_BACKEND_API}/reset-password/${userid}/${resettoken}`
    const res = await axios.post(forgoturl, formData, config)

    if (res.status === 200) {
      swal('Success', res.data.message, 'success').then(function () {
        navigate('/login')
      })
    }
  } catch (e) {
    // console.log(e)
    if (e?.response?.data.message) {
      swal('Error', e.response.data.message, 'error')
    }
  }
}
export const GetUseridAction = (instid) => async (dispatch) => {
  try {
    // debugger
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getuserid = `${process.env.REACT_APP_BACKEND_API}/getUser/${instid}`

    const res = await axios.get(getuserid, config, '')

    await dispatch(getuseridslice(res))
  } catch (e) {
    // console.log(e)
  }
}

export const UpdateuseridAction = (formData, id, navigate) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const updateuserid = `${process.env.REACT_APP_BACKEND_API}/updateUser/${id}`
    const res = await axios.put(updateuserid, formData, config)

    // dispatch(res());
    if (res.status === 200) {
      swal('Success', res.data.message, 'success').then(() => {})
      navigate('/alluserlist')
    }
  } catch (e) {
    // debugger;
    // console.log(e)
  }
}

export const DeleteUserAction = (props) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const deleteuser = `${process.env.REACT_APP_BACKEND_API}/deleteUser/${props}`
    const res = await axios.delete(deleteuser, config)

    dispatch(res())
    if (res.status === 200) {
      // console.log(res, 'res')
    }
  } catch (e) {
    // console.log(e)
  }
}

export const PaymentHistoryAction = (studentid) => async (dispatch) => {
  // debugger
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getuserid = `${process.env.REACT_APP_BACKEND_API}/getuserpaymentdetails/${studentid}`

    const res = await axios.get(getuserid, config)

    await dispatch(getStudentPaymentHistoryslice(res))
  } catch (e) {
    // console.log(e)
  }
}

export const UpdatemailAction = (token, formData, navigate) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const mailupdate = `${process.env.REACT_APP_BACKEND_API}/mailupdate/${token}`

    const res = await axios.post(mailupdate, formData, config)
    // console.log(res)

    dispatch(res())
    if (res.status === 200) {
      return res
    }
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e.response.data.message, 'error')
    }
  }
}

export const StudentListAction = () => async (dispatch) => {
  try {
    // debugger
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const ulisturl = process.env.REACT_APP_BACKEND_API + '/getstudentdetails'
    const res = await axios.get(ulisturl, '', config)

    await dispatch(getstudentlistslice(res))
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e?.response?.data?.message, 'error')
    }
  }
}

export const sendMailAction = (formData, setLoading) => async (dispatch) => {
  // debugger
  try {
    setLoading(true)
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const sendmail = `${process.env.REACT_APP_BACKEND_API}/sendmail`

    const res = await axios.post(sendmail, formData, config)

    if (res.status === 200) {
      // swal('Success', res.data.message, 'success').then(() => {})
      swal({
        title: 'Success',
        text: res.data.message,
        icon: 'success',
        buttons: {
          cancel: {
            text: 'OK',
            value: null,
            visible: true,
            className: 'swal-button--confirm cyberbtnallpage', 
          },
        },
      }).then(() => {
        // window.location.reload()
      })
    }
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e.response.data.message, 'error')
    }
  } finally {
    setLoading(false)
  }
}

export const PreviewEmailAction = (formData) => async (dispatch) => {
  //  debugger
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const sendmail = `${process.env.REACT_APP_BACKEND_API}/previewmail`

    const res = await axios.post(sendmail, formData, config)
    
    if (res.status === 200) {
     dispatch(emailPreviewSlice(res))
    }
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e.response.data.message, 'error')
    }
  }
}

export const changePasswordAction = (formData, navigate) => async (dispatch) => {
  // debugger
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const changePassword = `${process.env.REACT_APP_BACKEND_API}/changePassword`

    const res = await axios.post(changePassword, formData, config)

    if (res.status === 200) {
      localStorage.setItem('forceResetOrg', 1)
      swal('Success', res.data.message, 'success').then(() => {

        navigate('/login')

      })

    }
  } catch (e) {
    if (e?.response?.data.error) {
      swal('Error', e.response.data.error, 'error')
    }
  }
}

export const getParticipantsAction = (meetingId) => async (dispatch) => {
  // debugger
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getParticipants = `${process.env.REACT_APP_BACKEND_API}/meetingParticipants/${meetingId}`

    const res = await axios.get(getParticipants, config)

    await dispatch(getparticipantslistslice(res))
  } catch (e) {
    console.log(e)
  }
}

export const uploadCVaction = (id, formData) => async (dispatch) => {
  try {
    // debugger
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getuserdate = `${process.env.REACT_APP_BACKEND_API}/uploadCV/${id}`

    const res = await axios.post(getuserdate, formData, config, '')
    if (res.status === 200) {
    
      dispatch({ type: 'UPLOAD_SUCCESS', payload: res.data }); // Dispatch an action for successful upload

      // Show success message with swal and set uploading to false
      swal('success', res.data.message, 'success').then(() => {
        dispatch({ type: 'UPLOAD_COMPLETE' }); // Dispatch an action for upload completion
      });
    }
    
  } catch (e) {
   console.log(e)
  }
}
