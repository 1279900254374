import React from 'react'
import './Security.css'

const Securitypath = () => {
  return (
    <div>
      <h1>jjjjjjjjjjjj</h1>
    </div>
  )
}

export default Securitypath
