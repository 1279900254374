import React, {useEffect, useState} from 'react'

import AddIcon from '@mui/icons-material/Add'
import {useDispatch, useSelector} from 'react-redux'

import {GetallCourseAction, CourseDeleteAction} from '../../../Redux/Action/courseAction'

import {FaEdit, FaRegCalendarAlt, FaTrash, FaPlus} from 'react-icons/fa'

import {useParams} from 'react-router-dom'
import './Course.css'
import {Link, useNavigate} from 'react-router-dom'
import {Tooltip} from 'antd'

import {AiOutlineLeft, AiOutlineRight} from 'react-icons/ai'
import {FilterMatchMode} from 'primereact/api'
import {MultiSelect} from 'primereact/multiselect'
import {Toolbar} from 'primereact/toolbar'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'

export default function Allcourse() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [filters, setFilters] = useState({
    global: {value: null, matchMode: FilterMatchMode.CONTAINS},
    title: {value: null, matchMode: FilterMatchMode.CONTAINS},
    status: {value: null, matchMode: FilterMatchMode.IN},
    paid_price: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
    Course_Category: {value: null, matchMode: FilterMatchMode.IN}
  })

  const columns = [
    {field: 'title', header: 'Course Name'},
    {field: 'status', header: 'Status'},
    {field: 'paid_price', header: 'Price'},
    {field: 'Course_Category', header: 'Category'},
  ]

  const onColumnToggle = (event) => {
    let selectedColumns = event.value
    let orderedSelectedColumns = columns.filter((col) =>
      selectedColumns.some((sCol) => sCol.field === col.field)
    )

    setVisibleColumns(orderedSelectedColumns)
  }

  const [loading, setLoading] = useState(true)
  const [globalFilterValue, setGlobalFilterValue] = useState('')
  const [visibleColumns, setVisibleColumns] = useState(columns)

  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    let _filters = {...filters}

    _filters['global'].value = value

    setFilters(_filters)
    setGlobalFilterValue(value)
  }
  const courses = useSelector((state) => {
    const publishedCourses = state.login.getcourseslist?.Published || [];
    const draftCourses = state.login.getcourseslist?.Draft || [];
  
    const combinedCourses = [
      ...publishedCourses.map((item, index) => ({
        ...item,
        SNo: index + 1,
        status: 'Published',
      })),
      ...draftCourses.map((item, index) => ({
        ...item,
        SNo: publishedCourses.length + index + 1,
        status: 'Draft',
      }))
    ];
  
    return combinedCourses;
  });

  useEffect(() => {
    dispatch(GetallCourseAction()).then(() => {
      setLoading(false)
    })
  }, [dispatch])

  const deleteHandler = (props) => {
    dispatch(CourseDeleteAction(props))
  }

  const rightToolbarTemplate = () => {
    return (
      <div class='card-toolbar'>
        <div class='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
          <Link to='/addcourse' type='button' class='btn cyberbtnallpage'>
            <AddIcon /> Add Course
          </Link>
        </div>
      </div>
    )
  }

  const leftToolbarTemplate = () => {
    return (
      <MultiSelect
        value={visibleColumns}
        options={columns}
        optionLabel='header'
        onChange={onColumnToggle}
        className='w-full sm:w-20rem'
        display='chip'
      />
    )
  }

  const actionTemplate = (rowData) => {
    return (
      <div style={{display: 'flex', gap: '0px', marginLeft: '-3rem'}}>
        <div style={{fontSize: '15px'}}>
          <Tooltip title='Edit Course'>
            <Link to={`/api/instructor/course/edit/${rowData.courseslug}`}>
              <span
                style={{
                  fontSize: '18px',
                  paddingRight: '5px',
                  color: 'rgb(126, 130, 153)',
                }}
              >
                <FaEdit />
              </span>
            </Link>
          </Tooltip>

          <Tooltip title='Check Available Instructors'>
            <Link to={`/checkInstructorAvaibility/${rowData.id}`}>
              <span
                style={{
                  fontSize: '18px',
                  paddingRight: '5px',
                  color: 'rgb(126, 130, 153)',
                }}
              >
                <FaRegCalendarAlt />
              </span>
            </Link>
          </Tooltip>

          <Tooltip title='Delete Course'>
            <Link to='#' onClick={() => deleteHandler(rowData.courseslug)}>
              <span
                style={{
                  fontSize: '18px',
                  paddingRight: '5px',
                  color: 'rgb(126, 130, 153)',
                }}
              >
                <FaTrash />
              </span>
            </Link>
          </Tooltip>
        </div>
        <div
          class='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
          data-kt-menu='true'
        >
          <div class='menu-item px-3'></div>
        </div>
      </div>
    )
  }

  const CategoryFilter = (props) => {
    const options = ['CompTIA Bootcamp', 'Microsoft Bootcamp', 'CompTIA eLearning']

    return (
      <MultiSelect
        value={props.value}
        options={options}
        onChange={(e) => {
          props.filterApplyCallback(e.value)
        }}
        optionLabel=''
        placeholder='Any'
        className='p-column-filter'
        maxSelectedLabels={1}
        style={{minWidth: '10rem'}}
      />
    )
  }

  const StatusFilter = (props) => {
    const options = ['Published', 'Draft']

    return (
      <MultiSelect
        value={props.value}
        options={options}
        onChange={(e) => {
          props.filterApplyCallback(e.value)
        }}
        optionLabel=''
        placeholder='Any'
        className='p-column-filter'
        maxSelectedLabels={1}
        style={{minWidth: '10rem'}}
      />
    )
  }

  return (
    <div>
      <Toolbar className='mb-4' left={leftToolbarTemplate} right={rightToolbarTemplate}/>
      <DataTable
        value={courses}
        paginator
        rows={20}
        tableStyle={{minWidth: '100%'}}
        dataKey='id'
        filters={filters}
        filterDisplay='row'
        className='card'
        globalFilterFields={['title', 'status', 'paid_price', 'Course_Category']}
        emptyMessage='No courses found.'
        rowClassName='table-row'
      >
        <Column field='SNo' header='#' sortable />
        {visibleColumns.map((col) => (
          <Column
            sortable
            key={col.field}
            field={col.field}
            header={col.header}
            filter
            style={{minWidth: '15rem'}}
            filterPlaceholder={`Search by ${col.header}`}
            filterElement={(() => {
              switch (col.field) {
                case 'Course_Category':
                  return CategoryFilter
                case 'status':
                  return StatusFilter
                default:
                  return ''
              }
            })()}
          />
        ))}
        <Column
          field='id'
          header='Action'
          body={actionTemplate}
          exportable={false}
          style={{marginLeft: '-12rem'}}
        />
      </DataTable>
    </div>
  )
}
// const Allcourse = () => {
//   const dispatch = useDispatch()

//   const Allcourse = useSelector((state) => {
//     return state.login.getcourseslist
//   })

//   const allcourse = [...(Allcourse.Published || []), ...(Allcourse.Draft || [])]

//   useEffect(() => {
//     dispatch(GetallCourseAction())
//   }, [dispatch])

//   const deleteHandler = (props) => {
//     dispatch(CourseDeleteAction(props))
//   }

//   function nextPage() {
//     if (currentPage !== nPage) {
//       setCurrentPage(currentPage + 1)
//     }
//   }

//   function prePage() {
//     if (currentPage !== 1) {
//       setCurrentPage(currentPage - 1)
//     }
//   }

//   function changeCPage(id) {
//     setCurrentPage(id)
//   }

//   const [currentPage, setCurrentPage] = useState(1)

//   const recordsPerPage = 10

//   const lastIndex = currentPage * recordsPerPage

//   const firstIndex = lastIndex - recordsPerPage

//   const records = allcourse.slice(firstIndex, lastIndex)

//   const nPage = Math.ceil(allcourse.length / recordsPerPage)

//   const number = [...Array(nPage + 1).keys()].slice(1)

//   return (
//     <div className='user-list'>
//       <div id='kt_app_content_container'>
//         <div class='card'>
//           <div class='card-header border-0 pt-6'>
//             <div class='card-title'>
//               <h3 className='fw-bold m-0'>Courses List</h3>
//             </div>

//             <div class='card-toolbar'>
//               <div class='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
//                 <div class='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
//                   <div class='separator border-gray-200'></div>

//                   <div class='px-7 py-5' data-kt-user-table-filter='form'>
//                     <div class='mb-10'>
//                       <label class='form-label fs-6 fw-semibold'>Role:</label>
//                       <select
//                         class='form-select form-select-solid fw-bold'
//                         data-kt-select2='true'
//                         data-placeholder='Select option'
//                         data-allow-clear='true'
//                         data-kt-user-table-filter='role'
//                         data-hide-search='true'
//                       >
//                         <option></option>
//                         <option value='Administrator'>Administrator</option>
//                         <option value='Analyst'>Analyst</option>
//                         <option value='Developer'>Developer</option>
//                         <option value='Support'>Support</option>
//                         <option value='Trial'>Trial</option>
//                       </select>
//                     </div>

//                     <div class='mb-10'>
//                       <label class='form-label fs-6 fw-semibold'>Two Step Verification:</label>
//                       <select
//                         class='form-select form-select-solid fw-bold'
//                         data-kt-select2='true'
//                         data-placeholder='Select option'
//                         data-allow-clear='true'
//                         data-kt-user-table-filter='two-step'
//                         data-hide-search='true'
//                       >
//                         <option></option>
//                         <option value='Enabled'>Enabled</option>
//                       </select>
//                     </div>

//                     <div class='d-flex justify-content-end'>
//                       <button
//                         type='reset'
//                         class='btn btn-light btn-active-light-primary fw-semibold me-2 px-6'
//                         data-kt-menu-dismiss='true'
//                         data-kt-user-table-filter='reset'
//                       >
//                         Reset
//                       </button>
//                       <button
//                         type='submit'
//                         class='btn btn-primary fw-semibold px-6'
//                         data-kt-menu-dismiss='true'
//                         data-kt-user-table-filter='filter'
//                       >
//                         Apply
//                       </button>
//                     </div>
//                   </div>
//                 </div>

//                 <Link to='/addcourse' type='button' class='btn cyberbtnallpage'>
//                   <AddIcon /> Add Course
//                 </Link>
//               </div>

//               <div class='modal fade' id='kt_modal_export_users' tabIndex='-1' aria-hidden='true'>
//                 <div class='modal-dialog modal-dialog-centered mw-650px'>
//                   <div class='modal-content'>
//                     <div class='modal-header'>
//                       <h2 class='fw-bold'>Export Users</h2>

//                       <div
//                         class='btn btn-icon btn-sm btn-active-icon-primary'
//                         data-kt-users-modal-action='close'
//                       >
//                         <span class='svg-icon svg-icon-1'>
//                           <svg
//                             width='24'
//                             height='24'
//                             viewBox='0 0 24 24'
//                             fill='none'
//                             xmlns='http://www.w3.org/2000/svg'
//                           >
//                             <rect
//                               opacity='0.5'
//                               x='6'
//                               y='17.3137'
//                               width='16'
//                               height='2'
//                               rx='1'
//                               transform='rotate(-45 6 17.3137)'
//                               fill='currentColor'
//                             />
//                             <rect
//                               x='7.41422'
//                               y='6'
//                               width='16'
//                               height='2'
//                               rx='1'
//                               transform='rotate(45 7.41422 6)'
//                               fill='currentColor'
//                             />
//                           </svg>
//                         </span>
//                       </div>
//                     </div>

//                     <div class='modal-body scroll-y mx-5 mx-xl-15 my-7'>
//                       <form id='kt_modal_export_users_form' class='form' action='#'>
//                         <div class='fv-row mb-10'>
//                           <label class='fs-6 fw-semibold form-label mb-2'>Select Roles:</label>

//                           <select
//                             name='role'
//                             data-control='select2'
//                             data-placeholder='Select Link role'
//                             data-hide-search='true'
//                             class='form-select form-select-solid fw-bold'
//                           >
//                             <option></option>
//                             <option value='Teacher'>Teacher</option>
//                             <option value='Instructor'>Instructor</option>
//                             <option value='Employee'>Employee</option>
//                             <option value='Content Creater'>Content Creater</option>
//                           </select>
//                         </div>

//                         <div class='fv-row mb-10'>
//                           <label class='required fs-6 fw-semibold form-label mb-2'>
//                             Select Export Format:
//                           </label>

//                           <select
//                             name='format'
//                             data-control='select2'
//                             data-placeholder='Select Link format'
//                             data-hide-search='true'
//                             class='form-select form-select-solid fw-bold'
//                           >
//                             <option></option>
//                             <option value='excel'>Excel</option>
//                             <option value='pdf'>PDF</option>
//                             <option value='cvs'>CVS</option>
//                             <option value='zip'>ZIP</option>
//                           </select>
//                         </div>

//                         <div class='text-center'>
//                           <button
//                             type='reset'
//                             class='btn cybercancelbtn me-3'
//                             data-kt-users-modal-action='cancel'
//                           >
//                             Discard
//                           </button>
//                           <button
//                             type='submit'
//                             class='btn btn-primary cyberbtnallpage'
//                             data-kt-users-modal-action='submit'
//                           >
//                             <span class='indicator-label'>Submit</span>
//                             <span class='indicator-progress'>
//                               Please wait...
//                               <span class='spinner-border spinner-border-sm align-middle ms-2'></span>
//                             </span>
//                           </button>
//                         </div>
//                       </form>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div class='card-body py-4'>
//             <div className='table-responsive'>
//               <table class='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>
//                 <thead>
//                   <tr class='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
//                     <th>#</th>
//                     <th class='min-w-125px'>Course Name</th>
//                     <th class='min-w-125px'>Category</th>
//                     {/* <th class='min-w-125px'>Instructor</th>
//                   <th class='min-w-125px'>Lesson</th> */}
//                     <th class='min-w-125px'>Status</th>
//                     <th class='min-w-125px'>Price</th>
//                     <th class='min-w-125px'>Action</th>
//                   </tr>
//                 </thead>

//                 <tbody class='text-gray-600 fw-semibold'>
//                   {records.map((course, index) => {
//                     localStorage.setItem('paid_price', course.id)
//                     return (
//                       <tr key={index}>
//                         <td>{firstIndex + index + 1}</td>

//                         <td>
//                           {/* <div class='symbol symbol-square symbol-50px overflow-hidden me-3'>
//                           <div class='symbol-label'>
//                             <img src={course.image_link} alt='Emma Smith' class='w-100' />
//                           </div>
//                         </div> */}

//                           <div>
//                             {/* <Link class='badge ' to={`/course_details_ch_less/${course.courseslug}`}> */}
//                             <td style={{color: '#7e8299', fontSize: '14px'}}>{course.title}</td>
//                             {/* </Link> */}
//                           </div>
//                         </td>
//                         {/* <td>{course.}</td> */}
//                         <td>{course.Course_Category}</td>
//                         {/* <td>John</td>
//                       <td>Total lesson:34</td> */}

//                       <td>
//                         <div class='badge badge-light ' style={{color: '#7e8299'}}>
//                           {course.status}
//                         </div>
//                       </td>
//                       <td>£{course.paid_price}</td>
//                       {/* <td>{item.City}</td> */}

//                         <td>
//                           <div style={{fontSize: '15px'}}>
//                             <Tooltip title='Edit Course'>
//                               <Link to={`/api/instructor/course/edit/${course.courseslug}`}>
//                                 <span
//                                   style={{
//                                     fontSize: '18px',
//                                     paddingRight: '5px',
//                                     color: 'rgb(126, 130, 153)',
//                                   }}
//                                 >
//                                   <FaEdit />
//                                 </span>
//                               </Link>
//                             </Tooltip>
//                             {/* <Link to={`/avilable_Instructor/${course.id}`}> */}
//                             <Tooltip title='Check Available Instructors'>
//                               <Link to={`/checkInstructorAvaibility/${course.id}`}>
//                                 <span
//                                   style={{
//                                     fontSize: '18px',
//                                     paddingRight: '5px',
//                                     color: 'rgb(126, 130, 153)',
//                                   }}
//                                 >
//                                   <FaRegCalendarAlt />
//                                 </span>
//                               </Link>
//                             </Tooltip>
//                             {/* <Tooltip title='Add Chapters'>
//                               <Link to={`/course_details_ch_less/${course.courseslug}`}>
//                                 <span
//                                   style={{
//                                     fontSize: '18px',
//                                     paddingRight: '5px',
//                                     color: 'rgb(126, 130, 153)',
//                                   }}
//                                 >
//                                   <FaPlus />
//                                 </span>
//                               </Link>
//                             </Tooltip> */}
//                             <Tooltip title='Delete Course'>
//                               <Link to='#' onClick={() => deleteHandler(course.courseslug)}>
//                                 <span
//                                   style={{
//                                     fontSize: '18px',
//                                     paddingRight: '5px',
//                                     color: 'rgb(126, 130, 153)',
//                                   }}
//                                 >
//                                   <FaTrash />
//                                 </span>
//                               </Link>
//                             </Tooltip>
//                           </div>

//                           {/* <div class='dropdown'>
//                           <button
//                             class='btn btn-light btn-active-light-primary btn-sm'
//                             style={{paddingLeft: '2rem'}}
//                           >
//                             Actions <KeyboardArrowDownIcon />
//                           </button>

//                           <div class='dropdown-content'>
//                             <div class='text-center'>
//                               <Link to={`/api/instructor/course/edit/${course.courseslug}`}>
//                                 Edit
//                               </Link>
//                             </div>
//                             <div class='text-center'>
//                               <Link
//                                 to='#'
//                                 class='menu-link px-3'
//                                 onClick={() => deleteHandler(course.courseslug)}
//                               >
//                                 Delete
//                               </Link>
//                             </div>

//                             <div class='text-center'>
//                               <Link
//                                 to={`/checkInstructorAvaibility/${course.id}`}
//                                 class='menu-link px-3'
//                               >
//                                 Availability
//                               </Link>
//                             </div>
//                           </div>
//                         </div> */}
//                           <div
//                             class='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
//                             data-kt-menu='true'
//                           >
//                             <div class='menu-item px-3'></div>
//                           </div>
//                         </td>
//                       </tr>
//                     )
//                   })}
//                 </tbody>
//               </table>
//             </div>
//             <nav>
//               <ul className='pagination'>
//                 <li className='page-item'>
//                   <Link to='#' className='page-link' onClick={prePage}>
//                     <AiOutlineLeft />
//                   </Link>
//                 </li>
//                 {number.map((n, i) => (
//                   <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
//                     <Link to='#' className='page-link' onClick={() => changeCPage(n)}>
//                       {n}
//                     </Link>
//                   </li>
//                 ))}
//                 <li className='page-item'>
//                   <Link to='#' className='page-link' onClick={nextPage}>
//                     <AiOutlineRight />
//                   </Link>
//                 </li>
//               </ul>
//             </nav>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default Allcourse
