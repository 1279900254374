import {Container} from 'react-bootstrap'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Table from 'react-bootstrap/Table'

import React, {useEffect} from 'react'
import Caucasian from '../../../../../PrivateImage/bundleimg/Caucasian.png'

import {GetallbundleactionAction} from '../../../../../Redux/Action/bundleAction'

import {Link, useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'

function Section2() {
  const dispatch = useDispatch()
  var newURL = window.location

  var splitURL = newURL.href.split('/')

  let course = ''
  const bundle_slug = splitURL[5]

  const categorizedData = useSelector((state) => state.login.getBundleall_list.categorizedData)

  const microsoftBootcampData = categorizedData && categorizedData.Microsoft_Bootcamp

  const navigate = useNavigate()

  // const Comptia_Bootcamp = categorizedData && categorizedData.Comptia_Bootcamp
  useEffect(() => {
    // debugger;
    dispatch(GetallbundleactionAction(bundle_slug))
  }, [])
  return (
    <div>
      <Container style={{paddingLeft: '50px'}}>
        {microsoftBootcampData ? (
          microsoftBootcampData.map((item) => {
            const truncatedDescription =
              item.Delivery_Mechanism.length > 10000
                ? `${item.Delivery_Mechanism.slice(0, 10000)}...`
                : item.Delivery_Mechanism

            return (
              <div key={item.id}>
                <p
                  dangerouslySetInnerHTML={{__html: truncatedDescription}}
                  style={{
                    fontFamily: 'Sora, sans-serif',
                    fontSize: '16px',
                    paddingTop: '20px',
                  }}
                />
              </div>
            )
          })
        ) : (
          <div>
            <p>No data available</p>
          </div>
        )}
      </Container>
    </div>
  )
}

export default Section2
