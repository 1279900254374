import {FC} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTIcon, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  customIcon?: React.ReactNode;
  onClick?: () => void // Add the onClick prop here as a function

}

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  customIcon,
  onClick,// Add onClick to the destructured props
 
  
  hasBullet = false,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {app} = config

  return (
    <div className='menu-item'>
      <Link className={clsx('menu-link without-sub', {active: isActive})} to={to}
      onClick={onClick} // Add the onClick event handler here
      >
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
       {customIcon && 
          <span className='menu-icon'style={{color:'rgb(28, 50, 94)'}} >{customIcon}</span>
        }
        {/* {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
          <i className={clsx('bi fs-3', fontIcon)}></i>
        )} */}
        <span className='menu-title'>{title}</span>


        
      </Link>
      {children}
    </div>
  )
}

export {SidebarMenuItem}
