import React, {useState} from 'react'

import './Signup.css'

import {useDispatch, useSelector} from 'react-redux'

import {useForm, Controller} from 'react-hook-form'
import {RegisterAction} from '../../Redux/Action/authAction'

import {useEffect} from 'react'
import Footer from '../Footer/Footer'
import Loginandpay from '../LoginandPay/Loginandpay'
import StripePay from './StripePay/Stripepay'
import paypall from '../../assets/images/paypall.png'

import str from '../../assets/images/str.png'
import {useLocation} from 'react-router-dom'

import Loader from '../Loader/loader'
import TimezoneSelect from 'react-timezone-select'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import mr from '../../assets/images/female.jpg'
import admin from '../../assets/images/maleimg.jpg'

import {GetallbundleactionAction} from '../../Redux/Action/bundleAction'

import Navbar from '../Navbar/Navbar'
import {
  GetAllCountriesAction,
  GetStateByCountryAction,
  GetCityByStateAction,
} from '../../Redux/Action/locationAction'
import Spinner from 'react-bootstrap/Spinner'

const Payment = () => {
  const dispatch = useDispatch()
  const [password, setPassword] = useState('')
  const [formError, setFormError] = useState('')
  const [currentStep, setCurrentStep] = useState(1)

  const handleNext = () => {
    setCurrentStep(currentStep + 1)
  }

  const handlePrevious = () => {
    setCurrentStep(currentStep - 1)
  }

  // debugger
  const [loading, setLoading] = useState(false)

  const [selectedCountryISOCode, setSelectedCountryISOCode] = useState('')
  const [selectedStateISOCode, setSelectedStateISOCode] = useState('')
  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  )

  useEffect(() => {
    // Programmatically trigger a change after the component mounts
    setSelectedTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)
  }, [])
  const formattedTimezone = selectedTimezone?.value || ''

  var newURL = window.location

  var split = newURL.href.split('&')

  const bundle_code = split[1]
  const [selectedOption, setSelectedOption] = useState('1')

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value)
  }

  const handlePhoneNumberChange = (event) => {
    const inputPhoneNumber = event.target.value
    const numericPhoneNumber = inputPhoneNumber.replace(/\D/g, '')
    setValue('PhoneNumber', numericPhoneNumber)
  }
  const [cardComplete, setCardComplete] = useState(false)
  const handleSubmit2 = (event) => {
    event.preventDefault()
    let paymethod
    if (selectedOption === 'paypal') {
      paymethod = 1
    } else if (selectedOption === 'stripe') {
      paymethod = 2
    }
  }

  const handleBuyCourse = () => {
    localStorage.getItem('paid_price')
  }
  const image = admin

  const allCountries = useSelector((state) => {
    return state.login.getAllCountriesList
  })

  useEffect(() => {
    dispatch(GetAllCountriesAction())
  }, [dispatch])

  const state = useSelector((state) => {
    return state.login.getStateByCountrList
  })

  useEffect(() => {
    dispatch(GetStateByCountryAction(selectedCountryISOCode))
  }, [dispatch, selectedCountryISOCode])

  useEffect(() => {}, [state])

  const city = useSelector((state) => {
    return state.login.getCityByStateList
  })

  useEffect(() => {
    dispatch(GetCityByStateAction(selectedCountryISOCode, selectedStateISOCode))
  }, [dispatch, selectedCountryISOCode, selectedStateISOCode])

  useEffect(() => {}, [city])

  const handleChange = (e) => {
    const selectedValue = e.target.value
    setSelectedCountryISOCode(selectedValue)
  }

  const handleStateChange = (e) => {
    const selectedState = e.target.value
    setSelectedStateISOCode(selectedState)
  }

  const url = new URL(window.location.href)
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    getValues,
    formState: {errors},
  } = useForm()

  const course_code = localStorage.getItem('courseCode')

  const formData = localStorage.getItem('formData')

  const Data = JSON.parse(formData)
  const courseType = localStorage.getItem('type')
  const onSubmit = (data) => {
    const formData = new FormData()
    setLoading(true)
    debugger
    formData.append('PrefixMrMissMrs', Data.PrefixMrMissMrs)
    formData.append('FirstName', Data.FirstName)
    formData.append('Surname', Data.Surname)
    formData.append('Email', Data.Email)
    formData.append('PhoneNumber', Data.PhoneNumber)
    formData.append('Country', Data.Country)
    formData.append('state', Data.state)
    formData.append('timezone', Data.timezone)
    formData.append('City', Data.City)
    formData.append('AgeBracket', Data.AgeBracket)
    formData.append('YearsOfCyberSecurityExperience', Data.YearsOfCyberSecurityExperience)
    formData.append('InterestedInCyberheadRole', Data.InterestedInCyberheadRole)
    formData.append('Password', Data.Password)
    formData.append('EducationLevel', Data.EducationLevel)
    formData.append('PurposeOfTakingTheCourse', Data.PurposeOfTakingTheCourse)
    formData.append('EmploymentStatus', Data.EmploymentStatus)
    formData.append('HowYouGotToKnow', Data.HowYouGotToKnow)
    formData.append('Role', 'Student')
    formData.append('roleid', 3)
    formData.append('paymethod', selectedOption)
    formData.append('id', localStorage.getItem('id'))
    formData.append('profileUrl', Data.PrefixMrMissMrs === 'Mr' ? mr : image)

    if (courseType == 'Elearning') {
      formData.append('paid_price', localStorage.getItem('price'))
      formData.append('course_code', course_code)
      formData.append('coursetype', 'Elearning')
    } else if (courseType == 'course') {
      formData.append('paid_price', localStorage.getItem('price'))
      formData.append('course_code', course_code)
      formData.append('coursetype', localStorage.getItem('type'))
    } else if (courseType == 'bundle') {
      formData.append('paid_price', localStorage.getItem('price'))
      formData.append('course_code', Data.Bundle_code)
      formData.append('coursetype', localStorage.getItem('type'))
    }

    if (courseType == 'bundle') {
      formData.append('Bundle_code', Data.Bundle_code)
      formData.append('MicrosoftCourseId', Data.MicrosoftCourseId)
      formData.append('CompTIACourseId', Data.CompTIACourseId)
      formData.append('MicrosoftStartDate', localStorage.getItem('microsoftStartdate'))
      formData.append('MicrosoftEndDate', localStorage.getItem('microsoftEnddate'))
      formData.append('CompTIABootcampStartDate', localStorage.getItem('comptiaStartdate'))
      formData.append('CompTIABootcampEndDate', localStorage.getItem('comptiaEnddate'))
      formData.append('MicrosoftTimezone', localStorage.getItem('MicrosoftTimezone'))
      formData.append('CompTIATimezone', localStorage.getItem('CompTIATimezone'))
      formData.append('MicrosoftdatesId', localStorage.getItem('MicrosoftdatesId'))
      formData.append('CompTIAdatesId', localStorage.getItem('CompTIAdatesId'))
    } else if (courseType == 'course') {
      formData.append('Bundle_code', 0)
      formData.append('CourseId', Data.CourseId)
      formData.append('CourseStartDate', localStorage.getItem('startDate'))
      formData.append('CourseEndDate', localStorage.getItem('endDate'))
      formData.append('courseTimezone', localStorage.getItem('courseTimezone'))
      formData.append('courseDatesId', localStorage.getItem('courseDatesId'))
    }

    dispatch(RegisterAction(formData, setLoading))
  }

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const urlParams = searchParams.toString().split('&', '?')

  const Dates = {
    Bundle_code: split[1],
    MicrosoftCourseId: split[2],
    CompTIACourseId: split[3],
    MicrosoftStartDate: localStorage.getItem('microsoftStartdate'),
    MicrosoftEndDate: localStorage.getItem('microsoftEnddate'),
    CompTIABootcampStartDate: localStorage.getItem('comptiaStartdate'),
    CompTIABootcampEndDate: localStorage.getItem('comptiaEnddate'),
    MicrosoftTimezone: localStorage.getItem('MicrosoftTimezone'),
    CompTIATimezone: localStorage.getItem('CompTIATimezone'),
  }

  const categorizedData = useSelector((state) => state.login.getBundleall_list.categorizedData)

  const microsoftBootcampData = categorizedData && categorizedData.Microsoft_Bootcamp
  const Comptia_Bootcamp = categorizedData && categorizedData.Comptia_Bootcamp

  useEffect(() => {
    // debugger;
    dispatch(GetallbundleactionAction(bundle_code))
  }, [dispatch, bundle_code])

  const [passwordMatch, setPasswordMatch] = useState(true)
  const handleConfirmPasswordChange = (e) => {
    const confirmPasswordValue = e.target.value
    const passwordValue = getValues('Password')

    if (confirmPasswordValue === passwordValue) {
      setPasswordMatch(true)
    } else {
      setPasswordMatch(false)
    }
  }

  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    let timer
    if (selectedOption === '2' && cardComplete) {
      setIsLoading(true)
      setIsButtonDisabled(true)
      timer = setTimeout(() => {
        setIsButtonDisabled(false)
        setIsLoading(false)
      }, 1500)
    } else if (selectedOption === '1'){
      setIsButtonDisabled(false)
    } else {
      setIsButtonDisabled(true)
      setIsLoading(false)
      clearTimeout(timer)
    }

    return () => clearTimeout(timer)
  }, [selectedOption, cardComplete])

  return (
    <div style={{background: 'white'}}>
      <div className='banner-content'>
        <h1
          className='banner-title'
          style={{
            position: 'absolute',
            top: '30%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            color: 'white',
            fontSize: '30px',
          }}
        >
          Sign up
        </h1>
      </div>
      <Navbar />

      <div>
        <div className='signUp_section'>
          <div
            className='container container-md'
            // style={{zIndex: '99999999', boxShadow: '0 0 16px #e9e7e7', padding: '30px'}}
          >
            {loading && (
              <div className='loader-container'>
                <Loader />
              </div>
            )}
            <div className='row'>
              <div className='col-12'>
                <div className='card bg-light shadow-none'>
                  <div className='card-body'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className='row pt-10 '>
                        <div className='col-6 course_details_section'>
                          <div className='card mb-5'>
                            <div className='card-body'>
                              <h5>Your Order</h5>

                              {courseType == 'bundle' ? (
                                <div className='bundle_include_text'>
                                  <div>
                                    <div>
                                      <div className='mb-5'>
                                        <h3 className='mb-10'>
                                          Bundle: {localStorage.getItem('Name')}
                                        </h3>
                                        <h4>Microsoft Bootcamp:</h4>
                                        {microsoftBootcampData ? (
                                          microsoftBootcampData.map((course) => (
                                            <div key={course.id}>
                                              <h6>Course: {course.title}</h6>
                                            </div>
                                          ))
                                        ) : (
                                          <p>No data available</p>
                                        )}
                                        <h6>
                                          Dates: {localStorage.getItem('userMicrosoftStartDate')} -{' '}
                                          {localStorage.getItem('userMicrosoftEndDate')}
                                        </h6>
                                      </div>
                                      <div>
                                        <h4>CompTIA Bootcamp:</h4>
                                        {Comptia_Bootcamp ? (
                                          Comptia_Bootcamp.map((course) => (
                                            <div key={course.id}>
                                              <h6> Course: {course.title}</h6>
                                            </div>
                                          ))
                                        ) : (
                                          <p>No data available</p>
                                        )}
                                        <h6>
                                          {' '}
                                          Dates:{' '}
                                          {localStorage.getItem(
                                            'userCompTIABootcampStartDate'
                                          )} - {localStorage.getItem('userCompTIABootcampEndDate')}
                                        </h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : courseType == 'course' ? (
                                <div className='course_include_text'>
                                  <div>
                                    <h5>Course: {localStorage.getItem('title')}</h5>
                                    <h5>
                                      Dates: {localStorage.getItem('UserstartDate')} -{' '}
                                      {localStorage.getItem('UserendDate')}
                                    </h5>
                                    {/* <h5>Course: {localStorage.getItem('course_code')}</h5> */}
                                  </div>
                                </div>
                              ) : courseType == 'Elearning' ? (
                                <div className='course_include_text pt-8'>
                                  <div>
                                    <h4>
                                      <span>Course:</span> {localStorage.getItem('title')}
                                    </h4>
                                    <h4>
                                      <span>Validity:</span> 1 Year
                                    </h4>
                                  </div>
                                </div>
                              ) : (
                                <div>No Data Present</div>
                              )}

                              <div className='col-md-12 mt-10'>
                                <h4 className='total-price d-flex justify-content-between'>
                                  <span>Sub Total: </span>£{localStorage.getItem('price')}
                                </h4>
                                <h4 className='total-price d-flex justify-content-between'>
                                  <span>Tax: </span>£0
                                </h4>
                                <hr />
                                <h4 className='total-price d-flex justify-content-between'>
                                  <span> Total: </span>£{localStorage.getItem('price')}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-6'>
                          <div className='card'>
                            <div className='card-body'>
                              <h3>Payment Method</h3>
                              <div class='card_billing'>
                                <div style={{display: 'flex'}}></div>

                                <form onSubmit={handleSubmit2} style={{paddingTop: '1rem'}}>
                                  <label
                                    htmlFor='paypal'
                                    style={{paddingTop: '1rem', fontSize: '13px'}}
                                  >
                                    <div style={{display: 'flex', gap: '1rem'}}>
                                      <div>
                                        <input
                                          type='radio'
                                          name='paymentOption'
                                          value='1'
                                          id='1'
                                          checked={selectedOption === '1'}
                                          onChange={handleOptionChange}
                                          style={{fontSize: '20px', marginTop: '10px'}}
                                        />
                                      </div>
                                      <div>
                                        <div>
                                          <img src={paypall} alt='img' style={{width: '135px'}} />
                                        </div>
                                      </div>
                                    </div>
                                  </label>

                                  <label
                                    htmlFor='stripe'
                                    style={{
                                      fontSize: '13px',
                                      display: 'flex',
                                      paddingTop: '1rem',
                                      gap: '1rem',
                                    }}
                                  >
                                    <input
                                      type='radio'
                                      name='paymentOption'
                                      value='2'
                                      checked={selectedOption === '2'}
                                      onChange={handleOptionChange}
                                    />

                                    <div>
                                      <img src={str} alt='img' style={{width: '23%'}} />
                                    </div>
                                  </label>

                                  {selectedOption === '2' && (
                                    <StripePay setCardComplete={setCardComplete} />
                                  )}
                                </form>
                              </div>
                              <ToastContainer
                                position='bottom-right'
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme='light'
                              />
                              <div className='text-end mt-10'>
                                <button
                                  type='submit'
                                  className='btn cyberbtnallpage'
                                  disabled={isButtonDisabled}
                                >
                                  {isLoading && (
                                    <div>
                                      {' '}
                                      <Spinner
                                        as='span'
                                        animation='border'
                                        role='status'
                                        aria-hidden='true'
                                        variant='secondary'
                                      />
                                    </div>
                                  )}
                                  {!isLoading && <div>Proceed</div>}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className=''
                          style={{display: 'flex', alignItems: 'center', justifyContent: 'end'}}
                        ></div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Payment
