import React, {useEffect, useState} from 'react'
import {useRef} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import Navbar from '../../Navbar/Navbar'
import Footer from '../../Footer/Footer'
import BundlyBuyBtn from './BundlesPages/BundlyBuyBtn'

import {useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'

import BundleCoursedetails from '../BundleDetails/BundleCoursedetails'
import {
  GetBundleaction,
  GetallbundleactionAction,
  StudentAction,
} from '../../../Redux/Action/bundleAction'
import {Modal} from 'antd'

import Table from 'react-bootstrap/Table'
import './Bundle.css'
function BundleDetails() {
  const dispatch = useDispatch()
  var newURL = window.location

  const [open, setOpen] = useState(false)

  const showModal = () => {
    setOpen(true)
  }

  const hideModal = () => {
    setOpen(false)
  }
  const navigate = useNavigate()
  var splitURL = newURL.href.split('/')

  const bundle_slug = splitURL[4]
  const Bundle_code = splitURL[5]

  const getbundle = useSelector((state) => {
    return state.login.getbundlebyid
  })
  let cdata = ''
  useEffect(() => {
    //debugger;
    dispatch(GetBundleaction(bundle_slug))
  }, [dispatch, bundle_slug])

  const categorizedData = useSelector((state) => state.login.getBundleall_list.categorizedData)

  const microsoftBootcampData = categorizedData && categorizedData.Microsoft_Bootcamp
  const Comptia_Bootcamp = categorizedData && categorizedData.Comptia_Bootcamp

  useEffect(() => {
    // debugger;
    dispatch(GetallbundleactionAction(bundle_slug))
  }, [dispatch, bundle_slug])

  const [selectedMicrosoftStartDate, setSelectedMicrosoftStartDate] = useState(null)
  const [selectedMicrosoftEndDate, setSelectedMicrosoftEndDate] = useState(null)
  const [selectedCompTIABootcampStartDate, setSelectedCompTIABootcampStartDate] = useState(null)
  const [selectedCompTIABootcampEndDate, setSelectedCompTIABootcampEndDate] = useState(null)
  const [MicrosoftDateCourseId, setSelectedMicrosoftDateCourseId] = useState(null)
  const [CompTIABootcampDateCourseId, setCompTIABootcampDateCourseId] = useState(null)
  const [selectedPrice, setSelectedBundlePrice] = useState(null)
  const [selectedTimezone, setSelectedtimezone] = useState(null)

  localStorage.setItem('price', selectedPrice)

  const {
    register,
    handleSubmit,

    formState: {errors},
  } = useForm()
  const handleMicrosoftDateChange = (courseId, formattedStartDate, formattedEndDate) => {
    setSelectedMicrosoftStartDate(formattedStartDate)
    setSelectedMicrosoftEndDate(formattedEndDate)
    setSelectedMicrosoftDateCourseId(courseId)
  }

  const handleCompTIABootcampDateChange = (
    courseId,
    formattedStartDate,
    formattedEndDate,
    asiaTimezone
  ) => {
    setSelectedCompTIABootcampStartDate(formattedStartDate)
    setSelectedCompTIABootcampEndDate(formattedEndDate)
    setCompTIABootcampDateCourseId(courseId)
    setSelectedtimezone(asiaTimezone)
  }
  const studentId = localStorage.getItem('userid')

  const authToken = localStorage.getItem('authToken')
  const data = {
    Bundle_code: Bundle_code,
    MicrosoftCourseId: MicrosoftDateCourseId,
    CompTIACourseId: CompTIABootcampDateCourseId,
    selectedMicrosoftStartDate: selectedMicrosoftStartDate,
    selectedMicrosoftEndDate: selectedMicrosoftEndDate,
    selectedCompTIABootcampStartDate: selectedCompTIABootcampStartDate,
    selectedCompTIABootcampEndDate: selectedCompTIABootcampEndDate,
  }

  const isFormFilled = () => {
    return (
      data.Bundle_code &&
      data.MicrosoftCourseId &&
      data.CompTIACourseId &&
      data.selectedMicrosoftStartDate &&
      data.selectedMicrosoftEndDate &&
      data.selectedCompTIABootcampStartDate &&
      data.selectedCompTIABootcampEndDate
    )
  }

  const onSubmit = () => {
    //debugger
    const formData = new FormData()
    formData.append('studentId', studentId)
    formData.append('coursetype', 'bundle')
    formData.append('Bundle_code', Bundle_code)
    formData.append('MicrosoftCourseId', MicrosoftDateCourseId) // Include Microsoft course ID
    formData.append('CompTIACourseId', CompTIABootcampDateCourseId) // Include CompTIA Bootcamp course ID
    formData.append('MicrosoftStartDate', selectedMicrosoftStartDate)
    formData.append('MicrosoftEndDate', selectedMicrosoftEndDate)
    formData.append('CompTIABootcampStartDate', selectedCompTIABootcampStartDate)
    formData.append('CompTIABootcampEndDate', selectedCompTIABootcampEndDate)
    formData.append('timezone', selectedTimezone)

    dispatch(StudentAction(formData, Bundle_code, navigate, onSubmit))
  }
  const bundletitleRef = useRef()

  const scrollToBundletitle = () => {
    const element = document.getElementById('bundletitle')
    if (element) {
      element.scrollIntoView({behavior: 'smooth'})
    }
  }

  useEffect(() => {
    scrollToBundletitle()
  }, [])

  localStorage.setItem('microsoftStartdate', selectedMicrosoftStartDate)
  localStorage.setItem('microsoftEnddate', selectedMicrosoftEndDate)
  localStorage.setItem('comptiaStartdate', selectedCompTIABootcampStartDate)
  localStorage.setItem('comptiaEnddate', selectedCompTIABootcampEndDate)
  localStorage.setItem('type', 'bundle')
  localStorage.setItem('courseCode', Bundle_code)
  localStorage.setItem('timezone', selectedTimezone)

  return (
    <div style={{background: 'white'}} id='bundletitle'>
      <div className='parent-container'>
        <Navbar />
      </div>

      <div
        className='bundlecourse BundleDeytails'
        style={{
          position: 'absolute',
          top: '40%',
          left: '35%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'start',
          color: 'white',
          fontSize: '30px',
        }}
      >
        {getbundle &&
          Object.entries(getbundle).map(([key, course], i) => {
            if (key == 'data') {
              cdata = course
            }
          })}

        {cdata &&
          Object.entries(cdata).map(([key, item], index) => {
            return (
              <div>

              </div>
            )
          })}

        <Modal
          title='Dates Available'
          open={open}
          onCancel={hideModal}
          footer={null}
          centered
          wrapClassName='custom-modal'
          // style={{width: '100vw', overflowX: 'auto'}}
        >
          <div>
            <div className='container' style={{display: 'flex', flexDirection: 'column'}}>
              <div className='Mylearning' style={{width: '100%'}}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div style={{border: '1px solid #f4f4f4'}}>
                    <div
                      className='book-view'
                      style={{
                        display: 'flex',
                        gap: '10px',
                        padding: '5px',
                        borderRadius: '4px',
                        background: 'rgb(38, 74, 202))',
                      }}
                    >
                      <h4 class='card-title align-items-start flex-column'>
                        <span class='card-label fw-bold text-800' style={{color: '#fff'}}>
                          Microsoft Bootcamp
                        </span>
                      </h4>
                    </div>

                    <Table
                      bordered
                      id='Elevenplusyear_castomer'
                      style={{
                        tableLayout: 'fixed',
                      }}
                    >
                      <tbody style={{border: '1px solid #CECFCF', fontSize: '15px'}}>
                        <tr>
                          <td width={40}></td>
                          <th>Start Date & Time</th>
                          <th>End Date & Time</th>
                          <th>Timezone</th>
                        </tr>
                        {microsoftBootcampData ? (
                          microsoftBootcampData.map((course) => (
                            <React.Fragment key={course.id}>
                              {course.date_ranges &&
                                course.date_ranges.map((dateRange, index) => {
                                  const userLocale = navigator.language || 'en-US'

                                  const asiaTimezone =
                                    Intl.DateTimeFormat(userLocale).resolvedOptions().timeZone
                                  const options = {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    //second: 'numeric',
                                    timeZone: asiaTimezone,
                                    //timeZoneName: 'short',
                                  }

                                  const formattedStartDate = new Intl.DateTimeFormat(
                                    'en-UK',
                                    options
                                  ).format(new Date(dateRange.start_datetime))
                                  const formattedEndDate = new Intl.DateTimeFormat(
                                    'en-UK',
                                    options
                                  ).format(new Date(dateRange.end_datetime))

                                  return (
                                    <tr key={index} style={{border: '1px solid #CECFCF'}}>
                                      <td colSpan='2'>
                                        <input
                                          type='radio'
                                          name={`dateType_${course.id}`}
                                          value={formattedStartDate} // Use the formatted date as the value
                                          checked={
                                            selectedMicrosoftStartDate === formattedStartDate
                                          } // Check against the formatted date
                                          onChange={() =>
                                            handleMicrosoftDateChange(
                                              course.id,
                                              dateRange.start_datetime,
                                              dateRange.end_datetime,
                                              asiaTimezone
                                            )
                                          } // Pass courseId and formattedDate
                                        />
                                        &nbsp; &nbsp;
                                        {formattedStartDate}
                                      </td>
                                      <td>{formattedEndDate}</td>
                                      <td>{asiaTimezone}</td>
                                    </tr>
                                  )
                                })}
                            </React.Fragment>
                          ))
                        ) : (
                          <tr>
                            <td colSpan='2'>No dates available</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>

                    <div className='Mylearning' style={{width: '100%'}}>
                      <div style={{border: '1px solid #f4f4f4'}}>
                        <div
                          className='book-view'
                          style={{
                            display: 'flex',
                            gap: '10px',
                            padding: '5px',
                            borderRadius: '4px',
                            background: 'rgb(38, 74, 202)',
                          }}
                        >
                          <h4 class='card-title align-items-start flex-column'>
                            <span class='card-label fw-bold text-800' style={{color: '#fff'}}>
                              CompTIA Bootcamp
                            </span>
                          </h4>
                        </div>

                        <Table
                          bordered
                          id='Elevenplusyear_castomer'
                          style={{
                            tableLayout: 'fixed',
                            maxHeight: '300px', // Set the maximum height as needed
                            overflowY: 'auto', // Add a vertical scrollbar when content overflows
                          }}
                        >
                          <tbody
                            style={{
                              border: '1px solid #CECFCF',
                              fontSize: '15px',
                            }}
                          >
                            <tr>
                              <td width={40}></td>
                              <th>Start Date & Time</th>
                              <th>End Date & Time</th>
                              <th>Timezone</th>
                            </tr>
                            {Comptia_Bootcamp ? (
                              Comptia_Bootcamp.map((course) => (
                                <React.Fragment key={course.id}>
                                  {course &&
                                    course.date_ranges.map((dateRange, index) => {
                                      const userLocale = navigator.language || 'en-US'

                                      const asiaTimezone =
                                        Intl.DateTimeFormat(userLocale).resolvedOptions().timeZone
                                      const options = {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                        hour: 'numeric',
                                        minute: 'numeric',
                                        //second: 'numeric',
                                        timeZone: asiaTimezone,
                                        //timeZoneName: 'short',
                                      }

                                      const formattedStartDate = new Intl.DateTimeFormat(
                                        'en-UK',
                                        options
                                      ).format(new Date(dateRange.start_datetime))
                                      const formattedEndDate = new Intl.DateTimeFormat(
                                        'en-UK',
                                        options
                                      ).format(new Date(dateRange.end_datetime))

                                      return (
                                        <tr key={index} style={{border: '1px solid #CECFCF'}}>
                                          <td colSpan='2'>
                                            <input
                                              type='radio'
                                              name={`dateType_${course.id}`}
                                              value={formattedStartDate}
                                              checked={
                                                selectedCompTIABootcampStartDate ===
                                                formattedStartDate
                                              }
                                              onChange={() =>
                                                handleCompTIABootcampDateChange(
                                                  course.id,
                                                  dateRange.start_datetime,
                                                  dateRange.end_datetime,
                                                  asiaTimezone
                                                )
                                              } // Pass courseId and formattedDate
                                            />
                                            &nbsp; &nbsp;
                                            {formattedStartDate}
                                          </td>

                                          <td>{formattedEndDate}</td>
                                          <td>{asiaTimezone}</td>
                                        </tr>
                                      )
                                    })}
                                </React.Fragment>
                              ))
                            ) : (
                              <tr>
                                <td colSpan='2'>No dates available</td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                  {/* <div style={{paddingLeft: '83%'}}>
                    <button class='buttonlaunch button b-0px' type='submit'>
                      submit
                    </button>
                  </div> */}

                  <div>
                    {authToken && isFormFilled() ? (
                      <button className='buttonlaunch button b-0px'>
                        Submit
                      </button>
                    ) : isFormFilled() ? (
                      <Link
                        to={`/signup?&${data.Bundle_code}&${data.MicrosoftCourseId}&${data.CompTIACourseId}&${data.selectedMicrosoftStartDate}&${data.selectedMicrosoftEndDate}&${data.selectedCompTIABootcampStartDate}&${data.selectedCompTIABootcampEndDate}`}
                        className='buttonlaunch button b-0px'
                      >
                        Submit
                      </Link>
                    ) : (
                      <div>
                        <span style={{width: '200px', fontSize: '15px'}}>
                          Please Select The Dates.
                        </span>
                        <button
                          className='buttonlaunch button b-0px'
                          disabled
                          style={{
                            width: '20%',
                            justifyContent: 'end',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal>
      </div>

      <BundleCoursedetails />
      <Footer />
    </div>
  )
}

export default BundleDetails
