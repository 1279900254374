import React, {Component, useEffect, useState} from 'react'

import Footer from '../Footer/Footer'

import {useDispatch, useSelector} from 'react-redux'
import {ForgotPasswordAction} from '../../Redux/Action/authAction'
import {useForm} from 'react-hook-form'
import Navbar from '../Navbar/Navbar'
import Loader from '../Loader/loader'

const ForgotPassword = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm()

  const OnSubmit = (data) => {
    const formData = new FormData()
    setLoading(true)
    formData.append('email', data.email)

    dispatch(ForgotPasswordAction(formData, setLoading))
  }

  return (
    <div>
      <div>
        <div className='banner-content'>
          <h1
            className='banner-title'
            style={{
              position: 'absolute',
              top: '30%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
              color: 'white',
              fontSize: '30px',
            }}
          >
            Forget Password
          </h1>
        </div>
      </div>
      <Navbar />

      <>
        <div className='container fluid'>
          {loading && (
            <div className='loader-container'>
              <Loader />
            </div>
          )}
          <form onSubmit={handleSubmit(OnSubmit)}>
            <div className='row login_row'>
              <div className='login_box'>
                <div
                  className='intro algin-center'
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '-6rem',
                  }}
                ></div>
                <div className='col-12' style={{paddingTop: '5rem'}}>
                  <div className='form-group mt-3'>
                    <label for='inputFirstName' class='form-label'>
                      Email
                    </label>
                    <input
                      type='email'
                      className='form-control'
                      id='email'
                      style={{border: '1px solid grey'}}
                      placeholder='example@gmail.com'
                      {...register('email', {
                        required: 'This is required field',
                      })}
                      aria-invalid={errors.email ? 'true' : 'false'}
                    />
                    {errors.email && (
                      <p className='alert_danger' role='alert'>
                        {errors?.email?.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className='col-12 text-center'>
                  <button
                    className='btn mt-3 mt-sm-4 cyberbtnallpage'
                    type='submit'
                    // style={{background: '#FF763F', color: 'white'}}
                  >
                    <i className='icon-paper-plane mr-2' />
                    Send Reset Code
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </>
      <Footer />
    </div>
  )
}

export default ForgotPassword
