import swal from 'sweetalert'
import axios from 'axios'
import {getmeetingslice, getmeeting_byidslice, getMeetingByIdSlice} from '../Slice/authSlice'

export const zoomMeetingAction = (formData) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const zoomMeeting = process.env.REACT_APP_BACKEND_API + '/zoom/meetings'
    const res = await axios.post(zoomMeeting, formData, config)
    if (res.status === 200) {
      swal({
        title: 'Success',
        text: 'Meeting Created',
        icon: 'success',
        buttons: {
          cancel: {
            text: 'OK',
            value: null,
            visible: true,
            className: 'swal-button--confirm cyberbtnallpage', 
          },
        },
      })
    }
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e.response.data.message, 'error')
    }
  }
}

export const GetAllMeetingAction = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getuserid = `${process.env.REACT_APP_BACKEND_API}/meetings`

    const res = await axios.get(getuserid, config)

    await dispatch(getmeetingslice(res))
  } catch (e) {
    console.log(e)
  }
}

export const GetAllMeeting_byidAction = (meetingid) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getallmeeting_id = `${process.env.REACT_APP_BACKEND_API}/usermeetings/${meetingid}`

    const res = await axios.get(getallmeeting_id, config)

    await dispatch(getmeeting_byidslice(res))
  } catch (e) {
    console.log(e)
  }
}

export const deleteMeeting = (meetingId) => async (dispatch) => {
  try {
    // debugger
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const confirmResult = await swal({
      title: 'Delete Meeting',
      text: 'Are you sure you want to delete this Meeting?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          value: null,
          visible: true,
          className: 'swal-button--cancel cybercancelbtn', 
        },
        confirm: {
          text: 'Delete',
          value: true,
          visible: true,
          className: 'swal-button--confirm swal-button--danger cyberbtnallpage', 
        },
      },
      dangerMode: true,
    })
    if (confirmResult) {
      const deletemeeting = `${process.env.REACT_APP_BACKEND_API}/delete-meeting/${meetingId}`
      const res = await axios.delete(deletemeeting, config)

      if (res.status === 200) {
        swal({
          title: 'Success',
          text: 'Meeting Deleted',
          icon: 'success',
          buttons: {
            cancel: {
              text: 'OK',
              value: null,
              visible: true,
              className: 'swal-button--confirm cyberbtnallpage', 
            },
          },
        }).then(() => {
          window.location.reload()
        })
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export const DeleteBulkMettingAction = (meetingIdsToDelete) => async (dispatch) => {
  try {
    // debugger
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const confirmResult = await swal({
      title: 'Delete Meeting',
      text: 'Are you sure you want to delete this Meeting?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          value: null,
          visible: true,
          className: 'swal-button--cancel cybercancelbtn', 
        },
        confirm: {
          text: 'Delete',
          value: true,
          visible: true,
          className: 'swal-button--confirm swal-button--danger cyberbtnallpage', 
        },
      },
      dangerMode: true,
    })
    if (confirmResult) {
      const deletemeeting = `${process.env.REACT_APP_BACKEND_API}/delete-bulkmeeting/${meetingIdsToDelete}`
      const res = await axios.delete(deletemeeting, config)

      if (res.status === 200) {
        swal({
          title: 'Success',
          text: 'Meeting Deleted',
          icon: 'success',
          buttons: {
            cancel: {
              text: 'OK',
              value: null,
              visible: true,
              className: 'swal-button--confirm cyberbtnallpage', 
            },
          },
        }).then(() => {
          window.location.reload()
        })
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export const UpdateZoomMeetingAction = (formData, courseId, navigate) => async (dispatch) => {
  try {
    // debugger
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const updateCourseid = `${process.env.REACT_APP_BACKEND_API}/updateCourse/${courseId.courseId}`
    const res = await axios.put(updateCourseid, formData, config)
   

    if (res.status === 200) {
      swal({
        title: 'Success',
        text: 'Meeting Updated',
        icon: 'success',
        buttons: {
          cancel: {
            text: 'OK',
            value: null,
            visible: true,
            className: 'swal-button--confirm cyberbtnallpage', 
          },
        },
      })
      navigate('/Allcourse')
    }
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e.response.data.message, 'error')
    }
  }
}

export const GetMeeting_byidAction = (meetingid) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getallmeeting_id = `${process.env.REACT_APP_BACKEND_API}/getMeetingByMeetingId/${meetingid}`

    const res = await axios.get(getallmeeting_id, config)

    await dispatch(getMeetingByIdSlice(res))
  } catch (e) {
    console.log(e)
  }
}
