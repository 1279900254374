import React, {useEffect, useState} from 'react'

import pricingIcon from '../../../../../assets/BundleImages/bundle_icon3.svg'
import deliveryIcon from '../../../../../assets/BundleImages/bundle_icon4.svg'
import validityIcon from '../../../../../assets/BundleImages/bundle_icon5.svg'
import resourcesIcon from '../../../../../assets/BundleImages/bundle_icon6.svg'
import voucherIcon from '../../../../../assets/BundleImages/bundle_icon7.svg'
import labsIcon from '../../../../../assets/BundleImages/bundle_icon8.svg'
import {GetallbundleactionAction, GetBundleaction} from '../../../../../Redux/Action/bundleAction'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import {useParams} from 'react-router-dom'
import BundlyBuyBtn from '../BundlyBuyBtn'

const Boltchalenge = () => {
  const dispatch = useDispatch()
  var newURL = window.location
  var splitURL = newURL.href.split('/')

  const bundle_slug = splitURL[4]

  const getbundle = useSelector((state) => {
    return state.login.getbundlebyid
  })

  useEffect(() => {
    dispatch(GetBundleaction(bundle_slug))
  }, [dispatch, bundle_slug])

  useEffect(() => {
    dispatch(GetallbundleactionAction(bundle_slug))
  }, [dispatch, bundle_slug])

  const challengeLabs = useSelector((state) => state.login.getBundleall_list.challengeLabs)

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-md-8'>
          <div className='bundle-left-sec'>
            <div className='bundle-overview p-5'>
              <h2 className='heading-blue'>Overview</h2>
              {challengeLabs && challengeLabs.length > 0 ? (
                challengeLabs.map((course, index) => {
                  return (
                    <div key={`overview${index}`}>
                      <div
                        className='bundle_detailsprag'
                        dangerouslySetInnerHTML={{__html: course?.description}}
                      ></div>
                    </div>
                  )
                })
              ) : (
                <div>
                  <p>No data available</p>
                </div>
              )}
            </div>

            <div className='bundle-keyTopics p-5'>
              <h2 className='heading-blue'>What’s included:</h2>

              {challengeLabs && challengeLabs.length > 0 ? (
                challengeLabs.map((course, index) => {
                  return (
                    <div
                      className='bundle_detailsprag'
                      key={`includes${index}`}
                      dangerouslySetInnerHTML={{__html: course?.learnings}}
                    ></div>
                  )
                })
              ) : (
                <div>
                  <p>No data available</p>
                </div>
              )}
              {getbundle &&
              getbundle.data &&
              getbundle.data.length > 0 &&
              getbundle.data[0].bundle_pdf ? (
                <p>
                  <a
                    href={getbundle.data[0].bundle_pdf}
                    style={{color: '#264ACA'}}
                    download={getbundle.data[0].bundle_pdf}
                  >
                    Download{' '}
                  </a>
                  the full course syllabus to find out more!
                </p>
              ) : (
                <p>Download the full course syllabus to find out more!</p>
              )}
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='bundle-right-sec'>
            <div className='bundle-benefits p-5'>
              <div className='heading-icon d-flex'>
                <img src={deliveryIcon} alt='bundle price' className='feature-icon' />
                <div className='feature-content'>
                  <h2 className='heading-orange'>Delivery Mechanism</h2>
                  {challengeLabs && challengeLabs.length > 0 ? (
                    challengeLabs.map((course, index) => {
                      return (
                        <div key={`delivery${index}`}>
                          <div
                            className='bundle_detailsprag'
                            dangerouslySetInnerHTML={{__html: course?.Delivery_Mechanism}}
                          ></div>
                        </div>
                      )
                    })
                  ) : (
                    <div>
                      <p>No data available</p>
                    </div>
                  )}
                </div>
              </div>

              <div className='heading-icon d-flex'>
                <img src={validityIcon} alt='bundle price' className='feature-icon' />
                <div className='feature-content'>
                  <h2 className='heading-orange'>Validity</h2>
                  {challengeLabs && challengeLabs.length > 0 ? (
                    challengeLabs.map((course, index) => {
                      return (
                        <div key={`delivery${index}`}>
                          <div
                            className='bundle_detailsprag'
                            dangerouslySetInnerHTML={{__html: course?.duration}}
                          ></div>
                        </div>
                      )
                    })
                  ) : (
                    <div>
                      <p>No data available</p>
                    </div>
                  )}
                </div>
              </div>
              <div className='heading-icon d-flex'>
                <img src={labsIcon} alt='bundle price' className='feature-icon' />
                <div className='feature-content'>
                  <h2 className='heading-orange'>Hands-on Lab Training? </h2>
                  {challengeLabs && challengeLabs.length > 0 ? (
                    challengeLabs.map((course, index) => {
                      console.log(course, 'coursecoursecoursecourse')
                      if (course?.handsonlab == 'No' || course?.handsonlab == null) {
                        return <li key={`handsonlab${index}`}>No.</li>
                      } else {
                        return (
                          <li className='bundle_detailsprag' key={`handsonlab${index}`}>
                            Yes.
                          </li>
                        )
                      }
                    })
                  ) : (
                    <div>
                      <p>No data available</p>
                    </div>
                  )}
                </div>
              </div>
              <div className='heading-icon d-flex'>
                <img src={resourcesIcon} alt='bundle price' className='feature-icon' />
                <div className='feature-content'>
                  <h2 className='heading-orange'>Resources Available: </h2>
                  {challengeLabs && challengeLabs.length > 0 ? (
                    challengeLabs.map((course, index) => {
                      return (
                        <div key={`resources${index}`}>
                          <div
                            className='bundle_detailsprag'
                            dangerouslySetInnerHTML={{__html: course?.resources}}
                          ></div>
                        </div>
                      )
                    })
                  ) : (
                    <div>
                      <p>No data available</p>
                    </div>
                  )}
                </div>
              </div>
              <div className='heading-icon d-flex'>
                <img src={voucherIcon} alt='bundle price' className='feature-icon' />
                <div className='feature-content'>
                  <h2 className='heading-orange'>Exam Voucher Included? </h2>
                  <ul>
                    {challengeLabs && challengeLabs.length > 0 ? (
                      challengeLabs.map((course, index) => {
                        if (course?.vouchers == 'false' || course?.vouchers == null) {
                          return <li key={`handsonlab${index}`}>No.</li>
                        } else {
                          return (
                            <li className='bundle_detailsprag' key={`handsonlab${index}`}>
                              Yes.
                            </li>
                          )
                        }
                      })
                    ) : (
                      <li>
                        <p>No data available</p>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
              <div className='heading-icon d-flex'>
                <img src={pricingIcon} alt='bundle price' className='feature-icon' />
                <div className='feature-content'>
                  <h2 className='heading-orange'>Total Course Bundle Price</h2>
                  <BundlyBuyBtn />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Boltchalenge
