/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'

import {App} from '../App'
import {Insidebuycourse} from '../../components/index'
import Homepage from '../../components/Homepage'
import Courses from '../../components/Courses/Courses'
import Aboutpage from '../../components/Aboutpage'
import Blogpage from '../../components/Blog'
import Blogdetail from '../../components/Blogdetail'
import Comptiapage from '../../components/Compita/Comptiapage'
import Consultancypagee from '../../components/Consultancypagee'
import Loginpage from '../../components/Loginpage'
import Careers from '../../components/Careers/Careers'
import EmailVerfication from '../../components/Signup/EmailVerfication/EmailVerfication'
import Commingsoon from '../../components/Commingsoon/Commingsoon'
import Banner from '../../components/Navbar/Banner'
import Payment from '../../components/Signup/SignupPayment'
import {
  Previewlearning,
  Learning,
  Compita,
  Signup,
  Verifymail,
  Allbundles,
  ForgotPassword,
  ResetPassword,
  ChangePassword,
  Comptia,
} from '../../components/index'
import {Loginandpayment, BundleDetails} from '../../components/index'
import Pagenotfound from '../../components/Pagenotfound/Pagenotfound'

import {
  Compitiabootcamp,
  Comptialearning,
  Microsoftbootcamp,
} from '../../components/Courses/Categorycourse/index'
import swal from 'sweetalert'
import InactivityTimer from '../../Dashboard/utils/InactivityTimer'
const {PUBLIC_URL} = process.env

const isAuthenticated = localStorage.getItem('authToken')
const roleidd = localStorage.getItem('roleid')

const inactivityDuration = 15 * 60 * 1000;
const handleLogout = () => {
  localStorage.removeItem('authToken')
  localStorage.removeItem('forceResetOrg')
  localStorage.removeItem('forceReset')
  localStorage.clear();
  console.log(localStorage, "locaStoe=rage");
  swal({
    title: 'Login Again',
    text: 'You have been idle for more than 15 mins. Please login again to continue.!',
    icon: 'warning',
    closeOnClickOutside: false,
    buttons: {
      cancel: {
        text: 'OK',
        value: null,
        visible: true,
        className: 'swal-button--confirm cyberbtnallpage',
      },
    },
  }).then(() => {
    window.location.href = '/login'
  })
 
}

const determineDashboard = () => {
  switch (roleidd) {
    case '1':
    case '2':
      return '/dashboard'
    case '3':
      return '/my_profile'
    case '4':
      return '/My_profile'
    case '5':
      return '/Content_Writers'
    case '6':
      return '/Corporates_Manager'
    case '7':
      return '/Corporate_Employees'
    default:
      return '/login'
  }
}
const AppRoutes: FC = () => {
  return (
    <BrowserRouter basename={PUBLIC_URL}>
        {isAuthenticated && (
      <InactivityTimer onLogout={handleLogout} inactivityDuration={inactivityDuration} />
      )}
      <Routes>
        <Route element={<App />}>
          <Route path='/' element={<Homepage />} />
          <Route path='/payment' element={<Payment />} />
          <Route path='/aboutus' element={<Aboutpage />} />
          <Route path='/blogs' element={<Blogpage />} />
          <Route path='/blogdetail/:blog_slug' element={<Blogdetail />} />
          <Route path='/course' element={<Courses />} />
          <Route path='/Consultancy' element={<Consultancypagee />} />
          <Route
            path='/login'
            element={
              isAuthenticated ? <Navigate to={determineDashboard()} replace /> : <Loginpage />
            }
          />

          <Route path='/Careers' element={<Careers />} />
          <Route path='/Compita' element={<Comptiapage />} />
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='/Pagenotfound' element={<Pagenotfound />} />
          <Route path='/learningprev' element={<Previewlearning />} />
          <Route path='/Learning' element={<Learning />} />
          <Route path='/bundle_details/:bundle_slug/:bundle_code' element={<BundleDetails />} />

          <Route
            path='/signup'
            element={isAuthenticated ? <Navigate to='/dashboard' replace /> : <Signup />}
          />

          <Route path='/Loginandpayment' element={<Loginandpayment />} />

          <Route path='/success' element={<Verifymail />} />
          <Route path='/Comptia' element={<Comptia />} />
          <Route path='/faq' element={<Commingsoon />} />
          <Route path='/Banner' element={<Banner />} />

          {/* //course page */}
          <Route path='/compitia_bootcamp' element={<Compitiabootcamp />} />
          <Route path='/comptia-learning' element={<Comptialearning />} />
          <Route path='/microsoft-bootcamp' element={<Microsoftbootcamp />} />
          <Route path='/verified/:token' element={<EmailVerfication />} />
          <Route path='/All_bundles' element={<Allbundles />} />
          <Route path='/changepwd' element={<ChangePassword />} />
          {/* <Route
            path='/changepwd'
            element={isAuthenticated ? <Navigate to='/dashboard' replace /> : <ChangePassword />}
          /> */}
          {/* <Route path='/forgotpwd' element={<ForgotPassword />} /> */}
          <Route
            path='/forgotpwd'
            element={isAuthenticated ? <Navigate to='/dashboard' replace /> : <ForgotPassword />}
          />
          <Route path='/insidebuycourse' element={<Insidebuycourse />} />
          {/* <Route
            path='/insidebuycourse'
            element={isAuthenticated ? <Insidebuycourse /> : <Navigate to='/' replace />}
          /> */}

          <Route path='/reset-password/:id/:token' element={<ResetPassword />} />
          {isAuthenticated ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
            </>
          ) : (
            <>
              {/* <Route path='/' element={<AuthPage />} /> */}
              <Route path='*' element={<Navigate to='/' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
