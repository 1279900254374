import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'

import {UpdateuserPasswordAction} from '../../../../../Redux/Action/profileAction'

import {useForm} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'

const SignInMethod = () => {
  const studentid = localStorage.getItem('userid')

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({})

  const [isResetClicked, setIsResetClicked] = useState(false)

  const handleResetClick = () => {
    setIsResetClicked(true)
  }

  const onSubmit = (data) => {
    const formData = new FormData()

    formData.append('currentPassword', data.currentPassword)
    formData.append('newPassword', data.newPassword)
    formData.append('confirmPassword', data.confirmPassword)
    dispatch(UpdateuserPasswordAction(formData, studentid, navigate))
  }

  const handleCancelClick = () => {
    // Perform your desired action here
    window.location.reload() // Reload the current page
  }

  return (
    <div>
      <div className='card mb-5 mb-xl-10 shadow-none'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_signin_method'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bold m-0'>Reset Password</h3>
          </div>
        </div>

        <div id='kt_account_settings_signin_method' className='collapse show'>
          <div className='card-body border-top p-9'>
            <div>
              <div className='col-lg-4'>
                <div className='fv-row mb-0' style={{marginBottom: '15px'}}>
                  <label for='currentpassword' className='form-label fs-6 fw-bold mb-3'>
                    Current Password
                  </label>
                  <input
                    type='password'
                    className='form-control form-control-lg form-control-solid'
                    name='currentpassword'
                    id='currentpassword'
                    {...register('currentPassword', {
                      required: 'This is required field',
                    })}
                    aria-invalid={errors.currentPassword ? 'true' : 'false'}
                  />
                  {errors.currentPassword && (
                    <p
                      className='alert_danger'
                      role='alert'
                      style={{marginTop: '1rem', color: 'crimson'}}
                    >
                      {errors.currentPassword?.message}
                    </p>
                  )}
                </div>
              </div>

              <div className='col-lg-4'>
                <div className='fv-row mb-0' style={{marginBottom: '15px'}}>
                  <label for='newpassword' className='form-label fs-6 fw-bold mb-3'>
                    New Password
                  </label>
                  <input
                    type='password'
                    className='form-control form-control-lg form-control-solid'
                    name='newpassword'
                    id='newpassword'
                    {...register('newPassword', {
                      required: 'This is required field',
                    })}
                    aria-invalid={errors.newPassword ? 'true' : 'false'}
                  />
                  {errors.newPassword && (
                    <p
                      className='alert_danger'
                      role='alert'
                      style={{marginTop: '1rem', color: 'crimson'}}
                    >
                      {errors.newPassword?.message}
                    </p>
                  )}
                </div>
              </div>

              <div className='col-lg-4'>
                <div className='fv-row mb-0' style={{marginBottom: '15px'}}>
                  <label for='confirmpassword' className='form-label fs-6 fw-bold mb-3'>
                    Confirm New Password
                  </label>
                  <input
                    type='password'
                    className='form-control form-control-lg form-control-solid'
                    name='confirmpassword'
                    id='confirmpassword'
                    {...register('confirmPassword', {
                      required: 'This is required field',
                    })}
                    aria-invalid={errors.confirmPassword ? 'true' : 'false'}
                  />
                  {errors.confirmPassword && (
                    <p
                      className='alert_danger'
                      role='alert'
                      style={{marginTop: '1rem', color: 'crimson'}}
                    >
                      {errors.confirmPassword?.message}
                    </p>
                  )}
                </div>
              </div>

              {/* {!isResetClicked && (
                <div id="kt_signin_password_button" className="ms-auto">
                  <button
                    className="btn btn-light btn-active-light-primary"
                    onClick={handleResetClick}
                  >
                    Reset Password
                  </button>
                </div>
              )} */}

              <div>
                <div className='d-flex ' style={{paddingTop: '2rem'}}>
                  <button
                    id='kt_password_submit'
                    type='button'
                    className='btn cyberbtnallpage me-2'
                    onClick={handleSubmit(onSubmit)}
                  >
                    Update Password
                  </button>
                  <Link to=''>
                    <button
                      id='kt_password_cancel'
                      type='button'
                      className='btn cybercancelbtn me-2'
                      style={{
                        backgroundColor: 'white',
                      }}
                      onClick={handleCancelClick}
                    >
                      Cancel
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>{' '}
        </div>
      </div>
    </div>
  )
}

export default SignInMethod
