import React, {useState, useEffect} from 'react'
import ReactApexChart from 'react-apexcharts'
import {getMonthlySales} from '../../../../Redux/Action/admindashboardAction'
import {useDispatch, useSelector} from 'react-redux'
import moment from 'moment'
import {MdFilterAltOff} from 'react-icons/md'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './section.css'

const ApexChart = () => {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const dispatch = useDispatch()

  const salesData = useSelector((state) => {
    return state.login.getMonthlySales_list
  })

  const [series, setSeries] = useState([])
  const [totalIncome, setTotalIncome] = useState(0)
  const [selectRange, setSelectRange] = useState('thisMonth')

  const handleSelectChange = (event) => {
    //debugger
    const value = event.target.value
    setSelectRange(value)
    dispatch(getMonthlySales(value))
  }

  const clearFilters = () => {
    setStartDate(null)
    setEndDate(null)
  }
  const showClearFiltersButton = startDate !== null || endDate !== null
  const handleDateRangeChange = (start, end) => {
    setStartDate(start)
    setEndDate(end || null) // Set endDate to null if no end date is selected
    const value = 'customRange'
    dispatch(getMonthlySales(value, start, end))
  }
  useEffect(() => {
    if (
      salesData &&
      salesData.salesData &&
      salesData.salesData.days &&
      salesData.salesData.days.length > 0
    ) {
      const accumulatedSales = {}
      let total = 0

      salesData.salesData.days.forEach((day) => {
        if (day.sales !== null) {
          const dateKey = day.date
          if (accumulatedSales[dateKey]) {
            accumulatedSales[dateKey] += day.sales
          } else {
            accumulatedSales[dateKey] = day.sales
          }
          total += day.sales
        }
      })

      const transformedData = Object.keys(accumulatedSales).map((dateKey) => ({
        x: new Date(dateKey),
        y: accumulatedSales[dateKey],
      }))

      setSeries([{name: 'Total Sales', data: transformedData}])
      setTotalIncome(total)
    } else {
      console.log('Data is not available or does not meet conditions')
    }
  }, [salesData])

  useEffect(() => {
    const defaultValue = 'thisMonth'
    dispatch(getMonthlySales(defaultValue))
  }, [dispatch])

  const options = {
    chart: {
      height: 350,
      type: 'area',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      type: 'datetime',
      labels: {
        formatter: function (value, timestamp) {
          // const date = new Date(timestamp)
          // return `${date.toLocaleString('default', {weekday: 'short'})} ${date.getFullYear()}`
          const date = new Date(timestamp)
          const day = date.getDate()
          const month = date.toLocaleString('default', {month: 'short'}) // Month is zero-based
          const year = date.getFullYear().toString().slice(-2) // Get the last two digits of the year
          return `${day} ${month} ${year}`
        },
      },
    },
    tooltip: {
      x: {
        format: 'dd/mm/yy', // Format for tooltip date
      },
    },
  }

  return (
    <div>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div
          className='card-toolbar'
          style={{
            border: '1px solid #ccc',
            padding: '5px',
            borderRadius: '5px',
            width: '10%',
            display: 'flex',
            alignItems: 'end',
          }}
        >
          <select
            value={selectRange}
            onChange={handleSelectChange}
            style={{
              border: 'none',
              outline: 'none',
              fontSize: '12px',
              backgroundColor: 'white',

              padding: '5px',
              borderRadius: '10px',
              width: '100%',
            }}
          >
            <option value='all'>All</option>
            <option value='lastWeek'>This Week</option>
            <option value='thisMonth'>This Month</option>
            <option value='lastMonth'>Last Month</option>
            <option value='lastSixMonth'>Last 6 Months</option>
            <option value='lastYear'>Last year</option>
            {/* {/ <option value='customRange'>Custom Range</option> /} */}
          </select>
        </div>
        <div>
          <div>
            <DatePicker
              selected={startDate}
              onChange={(date) => handleDateRangeChange(date, endDate)} // Pass current endDate
              startDate={startDate}
              endDate={endDate}
              selectsStart
              placeholderText='Start Date'
              dateFormat='dd/MM/yyyy'
              className='custom-date-picker'
              minDate={new Date()}
            />
            <DatePicker
              selected={endDate}
              onChange={(date) => handleDateRangeChange(startDate, date)} // Pass current startDate
              startDate={startDate}
              endDate={endDate}
              selectsEnd
              placeholderText='End Date'
              dateFormat='dd/MM/yyyy'
              className='custom-date-picker'
              minDate={new Date()}
            />
            {showClearFiltersButton && (
              <MdFilterAltOff onClick={clearFilters} className='cursor-pointer fs-3' />
            )}
          </div>
        </div>
      </div>

      <div style={{paddingTop: '10px'}}>Total Sales: £{totalIncome}</div>
      <div id='chart'>
        <ReactApexChart options={options} series={series} type='area' height={350} />
      </div>
    </div>
  )
}

export default ApexChart
