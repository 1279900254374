import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Dropdown from 'react-bootstrap/Dropdown'
import {useNavigate} from 'react-router-dom'
import {GetInstructorAvailabilty} from '../../../Redux/Action/instructorAction'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {Modal} from 'antd'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {useForm} from 'react-hook-form'
import {useParams} from 'react-router-dom'
import Loader from '../../../components/Loader/loader'
import './allusers.css'
import {format, parse} from 'date-fns'
import {Tooltip} from 'antd'
import {FaEdit, FaTrash} from 'react-icons/fa'
import {
  getAllAvaibilityAction,
  UpdateBookedTeacherAction,
  GetRangeByInstructorId,
} from '../../../Redux/Action/instructorAction'
import {PreviewDetailsAction} from '../../../Redux/Action/courseAction'
import {TeacherCoursesAction} from '../../../Redux/Action/instructorAction'
import moment from 'moment'
import Table from 'react-bootstrap/Table'
import {GetMeeting_byidAction} from '../../../Redux/Action/zoomAction'
import {MdOutlineContentCopy} from 'react-icons/md'

const BookedInstructor = () => {
  const dispatch = useDispatch()

  var newURL = window.location

  var splitURL = newURL.href.split('/')

  const instid = splitURL[4]
  const bookinstructor = useSelector((state) => {
    return state.login.getavailibilityInstlist
  })

  useEffect(() => {
    // debugger;
    dispatch(GetInstructorAvailabilty(instid))
  }, [dispatch, instid])

  let cdata = ''

  const [copyStatuses, setCopyStatuses] = useState(new Array(bookinstructor.length).fill(''))
  const [startUrlCopyStatuses, setStartUrlCopyStatuses] = useState(
    new Array(bookinstructor.length).fill('')
  )
  const [joinUrlCopyStatuses, setJoinUrlCopyStatuses] = useState(
    new Array(bookinstructor.length).fill('')
  )

  const handleCopy = (item, urlKey, setCopyStatuses) => {
    return () => {
      setCopyStatuses((prevStatuses) => {
        const newStatuses = [...prevStatuses]
        newStatuses[item] = 'Copied'
        setTimeout(() => {
          newStatuses[item] = ''
          setCopyStatuses(newStatuses)
        }, 2000)
        return newStatuses
      })
    }
  }

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const [isModalOpenmetting, setIsModalOpenmetting] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [selectedInstructorId, setSelectedInstructorId] = useState(null)
  const [selectedCourseId, setSelectedCourseId] = useState('')
  const [timezone, setTimezone] = useState(null)
  const [courseDatesId, setcourseDatesId] = useState(null)
  const [selectedDate, setSelectedDate] = useState(null)
  const [isOtherButtonDisabled, setIsOtherButtonDisabled] = useState(true)

  const handleCourseChange = (e) => {
    const courseId = e.target.value
    setSelectedCourseId(courseId)
  }

  const teacherCourses = useSelector((state) => {
    return state.login.gitalltechercourseList
  })

  useEffect(() => {
    dispatch(TeacherCoursesAction(instid))
  }, [dispatch, instid])

  const handleDateSelection = (startDate, endDate, timezone, datesId) => {
    setStartDate(startDate)
    setEndDate(endDate)
    setTimezone(timezone)
    setcourseDatesId(datesId)
  }

  const handleBookButtonClick = (instid) => {
    setSelectedInstructorId(instid)
    setIsModalOpen(true)
  }
  const handleCancel = () => {
    setSelectedInstructorId(null)
    setIsModalOpen(false)
  }

  const [selectedbookId, setSelectedbookId] = useState(null)

  const handlebookSelection = (id) => {
    setSelectedbookId(id)
  }

  const course = useSelector((state) => {
    return state.login.getpreviewdetailslist
  })

  useEffect(() => {
    dispatch(PreviewDetailsAction(selectedCourseId))
  }, [dispatch, selectedCourseId])

  const {
    register,
    handleSubmit,
    formState: {errors},
    setValue,
  } = useForm({})

  const getbookinstructor = useSelector((state) => {
    return state.login.getAllAvaibility_list
  })

  useEffect(() => {
    // debugger;
    dispatch(getAllAvaibilityAction(selectedbookId))
  }, [dispatch, selectedbookId])

  useEffect(() => {
    if (
      getbookinstructor &&
      Array.isArray(getbookinstructor.data) &&
      getbookinstructor.data.length > 0
    ) {
      const firstItem = getbookinstructor.data[0]

      setcourseDatesId(firstItem.datesId)
      setSelectedCourseId(firstItem.Course_id)
      setSelectedDate(firstItem.datesId)
    }
  }, [getbookinstructor])

  const onSubmit = () => {
    // debugger
    const formData = new FormData()

    formData.append('Course_id', selectedCourseId)
    formData.append('instructor_id', instid)
    formData.append('start_date', startDate)
    formData.append('end_date', endDate)
    formData.append('timezone', timezone)
    formData.append('courseDatesId', courseDatesId)

    dispatch(UpdateBookedTeacherAction(selectedbookId, formData))
  }

  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const Range = useSelector((state) => state.login.getallRangelist)

  useEffect(() => {
    dispatch(GetRangeByInstructorId(instid))
      .then(() => setIsDataLoaded(true))
      .catch((error) => {
        console.error('Error fetching range data:', error)
        setIsDataLoaded(true)
      })
  }, [dispatch, instid])

  const parseDateString = (dateString) => {
    const [day, month, year] = dateString.split('/')
    return new Date(year, month - 1, day)
  }

  const dateIntervals =
    Range?.dateArray?.flatMap((availableDateString) => {
      const available_date = new Date(availableDateString)
      const booked_dates =
        Range?.bookedDates?.map((bookedDateString) => new Date(bookedDateString)) || []
      return [available_date, ...booked_dates]
    }) ?? []

  const handleDeleteButtonClick = (id) => {
    const formData = new FormData()

    formData.append('status', 'Cancelled')

    dispatch(UpdateBookedTeacherAction(id, formData))
  }

  function formatDate(datetime) {
    const isValidDate = moment(datetime).isValid()

    if (isValidDate) {
      const formattedDate = moment(datetime).format('DD/MM/YYYY')
      return formattedDate
    } else {
      return 'Invalid Date'
    }
  }

  const meetingdetails = useSelector((state) => {
    return state.login.getMeetingbyIdList
  })

  const [fetchedUrl, setFetchedUrl] = useState(null)
  const [clickedMeetingId, setClickedMeetingId] = useState(null)
  const [copied, setCopied] = useState(false)

  const handleClick = (meetingId) => {
    setClickedMeetingId(meetingId)
    dispatch(GetMeeting_byidAction(meetingId))
  }

  const copyToClipboard = (text) => {
    if (text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          console.log('Copied to clipboard successfully!')
          setCopied(true)
          setTimeout(() => setCopied(false), 2000)
        })
        .catch((err) => {
          console.error('Failed to copy text to clipboard:', err)
        })
    }
  }

  useEffect(() => {
    if (clickedMeetingId) {
      const meeting = meetingdetails.find((meeting) => meeting.id == clickedMeetingId)
      const url = meeting?.start_url
      setFetchedUrl(url)
    }
  }, [clickedMeetingId, meetingdetails])

  return (
    <div>
      <div className='purchase-history'>
        <div className='card'>
          <div className='card-header card-header-stretch border-bottom border-gray-200'>
            <div className='card-title'>
              <h3 className='fw-bold m-0'>Booked Instructor</h3>
            </div>
          </div>

          <div className='tab-content'>
            <div className='table-responsive'>
              <table className='table table-row-bordered align-middle gy-4 gs-9'>
                <thead className='border-bottom border-gray-200 fs-6 text-gray-600 fw-bold bg-light bg-opacity-75'>
                  <tr>
                    <td className='min-w-150px'>#</td>
                    <td className='min-w-250px'>Course Name</td>
                    <td className='min-w-150px'>Date</td>
                    <td className='min-w-150px'>MeetingLink</td>
                    <td className='min-w-150px'>Action</td>
                  </tr>
                </thead>
                <tbody className='fw-semibold text-gray-600'>
                  {Object.entries(bookinstructor).map(([key, course], i) => {
                    if (key == 'data') {
                      cdata = course
                    }
                  })}

                  {Object.entries(cdata).map(([key, item], i) => {
                    {
                      console.log(item, 'Edit Booked InstructorEdit Booked Instructor')
                    }
                    return (
                      <tr>
                        <td>{i + 1}</td>

                        <td>{item.courseTitle}</td>

                        <td>
                          {moment.utc(item.start_date || '').format('DD/MM/YYYY')} {item.startTime}-
                          {moment.utc(item.end_date || '').format('DD/MM/YYYY')} {item.endTime}
                        </td>
                        <td style={{width: '300px'}}>
                          <div>
                            <span
                              onClick={() => {
                                handleClick(item.meetingID)
                              }}
                              style={{cursor: 'pointer', color: 'blue'}}
                            >
                              {item.meetingID === clickedMeetingId && fetchedUrl !== null ? (
                                <>
                                  {fetchedUrl?.slice(0, 50)}....
                                  <MdOutlineContentCopy
                                  style={{
                                    fontSize: '20px',
                                    paddingRight: '5px',
                                    color: 'rgb(126, 130, 153)',
                                  }}
                                    onClick={() => copyToClipboard(fetchedUrl)}
                                  />
                                </>
                              ) : (
                                'Show Url'
                              )}
                            </span>
                            {copied && item.meetingID === clickedMeetingId && <div>Copied</div>}
                          </div>
                        </td>

                        <td>
                          <div class='dropdown'>
                            <div style={{fontSize: '15px'}}>
                              <Tooltip title='Edit'>
                                <button
                                  onClick={() => {
                                    handleBookButtonClick(instid)
                                    handlebookSelection(item.id)
                                  }}
                                  style={{border: 'none', background: 'white'}}
                                >
                                  <span
                                    style={{
                                      fontSize: '18px',
                                      paddingRight: '5px',
                                      color: 'rgb(126, 130, 153)',
                                    }}
                                  >
                                    <FaEdit />
                                  </span>
                                </button>
                              </Tooltip>
                              <Tooltip title='Delete'>
                                <button
                                  onClick={() => handleDeleteButtonClick(item.id)}
                                  style={{border: 'none', background: 'white'}}
                                >
                                  <span
                                    style={{
                                      fontSize: '18px',
                                      paddingRight: '5px',
                                      color: 'rgb(126, 130, 153)',
                                    }}
                                  >
                                    <FaTrash />
                                  </span>
                                </button>
                              </Tooltip>
                            </div>
                          </div>
                          <div
                            class='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
                            data-kt-menu='true'
                          >
                            <div class='menu-item px-3'></div>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>

                <>
                  <Modal
                    title='Edit Booked Instructor'
                    centered
                    open={isModalOpen}
                    onCancel={handleCancel}
                    footer={null}
                  >
                    <form
                      style={{paddingTop: '2rem', paddingLeft: '1rem'}}
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div>
                        <div>
                          <label
                            className='selectcountry'
                            style={{fontWeight: 'bold', fontSize: '15px'}}
                          >
                            Select a Course
                          </label>
                          <input
                            type='text'
                            className='slect_course_lable form-control mb-4'
                            value={
                              selectedCourseId
                                ? teacherCourses.find(
                                    (course) => course.course_id === selectedCourseId
                                  )?.title
                                : 'Select a course'
                            }
                            readOnly
                            style={{height: '4%', border: '1px solid #ccc', borderRadius: '5px'}}
                          />
                        </div>
                        <div>
                          <label
                            className='selectcountry'
                            style={{fontWeight: 'bold', fontSize: '15px'}}
                          >
                            Select Date :
                          </label>

                          <Table
                            bordered
                            id='Elevenplusyear_castomer'
                            style={{
                              tableLayout: 'fixed',
                            }}
                          >
                            <tbody style={{border: '1px solid #CECFCF', fontSize: '15px'}}>
                              <tr>
                                <td width={40}></td>
                                <th>Start Date & Time</th>
                                <th>End Date & Time</th>
                                <th>Timezone</th>
                              </tr>

                              {course?.dateRange && course.dateRange.length > 0 ? (
                                course.dateRange.map((item, index) => {
                                  const start_datetime = moment.utc(item?.start_datetime || '')

                                  const end_datetime = moment.utc(item?.end_datetime || '')

                                  let isDisabled = false
                                  for (
                                    let date = moment(start_datetime);
                                    date.isSameOrBefore(end_datetime);
                                    date.add(1, 'day')
                                  ) {
                                    if (
                                      !dateIntervals.some((dateInArray) =>
                                        moment(date).isSame(dateInArray, 'day')
                                      )
                                    ) {
                                      isDisabled = true

                                      break
                                    }
                                  }

                                  const tooltipContent = isDisabled
                                    ? 'Teacher is not available within this date range'
                                    : ''

                                  return (
                                    <React.Fragment key={item.id}>
                                      <tr style={{border: '1px solid #CECFCF'}}>
                                        <td>
                                          <Tooltip title={tooltipContent} placement='top'>
                                            <input
                                              type='radio'
                                              name={`dateType_${course.data.id}`}
                                              value={formatDate(item.start_datetime)}
                                              checked={item.datesId == courseDatesId}
                                              required
                                              onChange={() => {
                                                const value = formatDate(item.start_datetime)
                                                handleDateSelection(
                                                  item.start_datetime,
                                                  item.end_datetime,
                                                  item.timezone,
                                                  item.datesId
                                                )
                                                setSelectedDate(item.datesId)
                                              }}
                                              disabled={isDisabled}
                                            />
                                          </Tooltip>
                                        </td>

                                        <td>{start_datetime.format('DD/MM/YYYY, HH:mm')}</td>
                                        <td>{end_datetime.format('DD/MM/YYYY, HH:mm')}</td>
                                        <td>{item.timezone}</td>
                                      </tr>
                                    </React.Fragment>
                                  )
                                })
                              ) : (
                                <tr>
                                  <td colSpan='2'>No Dates Available</td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </div>

                        <button
                          style={{
                            textAlign: 'center',
                            width: ' 100%',
                            color: '#ffff',
                            fontSize: '1rem',
                            borderRadius: '.375rem',
                            backgroundColor: selectedDate ? '#FF763F' : 'rgba(255, 118, 63, 0.5)',
                            textTransform: 'capitalize',
                            border: 'none',
                            padding: '7px',
                            marginTop: '20px',
                          }}
                          type='submit'
                          className='slect_course_lable btn-warning'
                          disabled={!selectedDate}
                        >
                          submit
                        </button>
                      </div>
                    </form>
                  </Modal>
                </>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BookedInstructor
