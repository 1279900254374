import React, {useEffect, useState} from 'react'
import {Col, Container, Row} from 'react-bootstrap'

import './bundle.css'

import {Link, useNavigate} from 'react-router-dom'

import {CheckusercourseAction} from '../../../../Redux/Action/courseAction'
import {BoughtBundleAction} from '../../../../Redux/Action/bundleAction'

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'

import {useDispatch, useSelector} from 'react-redux'

const MyBundles = () => {
  const dispatch = useDispatch()
  const [message, setMessage] = useState('')
  const studentid = localStorage.getItem('userid')

  const mybundle = useSelector((state) => {
    return state.login.getboughtbundlelist
  })

  useEffect(() => {
    dispatch(BoughtBundleAction(studentid))
  }, [dispatch])

  let cdata = ''

  useEffect(() => {
    dispatch(CheckusercourseAction())
  }, [dispatch])

  const learningprev = (props) => {
    dispatch(CheckusercourseAction(props, navigate, setMessage))
  }

  const Comptia = (props) => {
    dispatch(CheckusercourseAction(props, navigate, setMessage))
  }
  const navigate = useNavigate()
  return (
    <div>
      <div className='card'>
        {mybundle &&
          Object.entries(mybundle).map(([key, course], i) => {
            if (key === 'data') {
              cdata = course
            }
          })}

        {cdata && Object.entries(cdata).length === 0 ? (
          <div class='d-flex flex-column flex-center flex-column-fluid'>
            <div class='d-flex flex-column flex-center text-center p-10'>
              <div class='card-body py-15 py-lg-20' style={{height: '15rem', marginTop: '69px'}}>
                <h2 class='fw-bolder fs-2hx text-gray-900 mb-4'>No Bundle</h2>
              </div>
            </div>
          </div>
        ) : (
          Object.entries(cdata).map(([key, item], i) => {
            return (
              <div className='course-list-item  row   '>
                <div className='courseimg_list col-md-4'>
                  <img src={item.ImageLink} alt='img' style={{borderRadius: '1rem'}} />
                </div>
                <div className='course-list-info col-md-8'>
                  <h4>{item.Name}</h4>
                  <div
                    className='course-list-info__meta pt-1'
                    style={{display: 'flex', gap: '1rem'}}
                  >
                    <span style={{display: 'flex', gap: '1rem'}}>
                      <CalendarMonthIcon style={{color: '#FF763F'}} />
                      {/* <span className='text-gray-600'>
                      {' '}
                      Expire On: <span className='fw-bold'>{item.expiration_time}</span>
                    </span> */}
                    </span>
                  </div>
                  <div className='course-list-info__description text-gray-600 fw-bold  bg-opacity-75 pt-3'>
                    {/* {item.description} */}ggggggggggggggg
                  </div>
                  <div className='pt-4'>
                    <a
                      class='buttonlaunch button'
                      onClick={() => {
                        navigate(`/bundle_details/${item.bundle_slug}/${item.bundle_code}`)
                      }}
                      style={{cursor: 'pointer'}}
                    >
                      View Course
                    </a>
                  </div>
                </div>
              </div>
            )
          })
        )}
      </div>
    </div>
  )
}

export default MyBundles
