import React, { useEffect, useState } from 'react'


import { PreviewDetailsAction } from '../../../../Redux/Action/courseAction'
import { useDispatch, useSelector } from 'react-redux'

import pricingIcon from "../../../../assets/BundleImages/bundle_icon3.svg"
import deliveryIcon from "../../../../assets/BundleImages/bundle_icon4.svg"
import validityIcon from "../../../../assets/BundleImages/bundle_icon5.svg"
import resourcesIcon from "../../../../assets/BundleImages/bundle_icon6.svg"
import voucherIcon from "../../../../assets/BundleImages/bundle_icon7.svg"
import labsIcon from "../../../../assets/BundleImages/bundle_icon8.svg"
import durationIcon from "../../../../assets/BundleImages/bundle_icon9.svg"
import BuyBtn from "../MicrosoftBootCamp/BuyBtn"
const Section1 = () => {
    const dispatch = useDispatch()
    var newURL = window.location
    var splitURL = newURL.href.split('/')

    const courseslug = splitURL[5]

    const previewdetails = useSelector((state) => {
        // debugger;
        return state.login.getpreviewdetailslist
    })

    let cdata = ''
    useEffect(() => {
        // debugger
        dispatch(PreviewDetailsAction(courseslug))
    }, [dispatch])
    return (
        <div className='container'>
            <div className='row row mb-10 pb-10'>
                <div className='col-md-8'>
                    <div className='bundle-left-sec'>
                        <div className='bundle-overview p-5'>
                            <h2 className='heading-blue'>Overview</h2>

                            {previewdetails && previewdetails.data ? (
                                <div
                                    className='bundle_detailsprag'
                                    dangerouslySetInnerHTML={{ __html: previewdetails.data.description }}
                                ></div>
                            ) : (
                                <div>No data</div>
                            )}
                        </div>

                        <div className='bundle-keyTopics p-5'>
                            <h2 className='heading-blue'>Key Topics Covered:</h2>

                            {previewdetails && previewdetails.data ? (
                                <div
                                    className='bundle_detailsprag'
                                    dangerouslySetInnerHTML={{ __html: previewdetails.data.topicCovered }}
                                ></div>
                            ) : (
                                <div>No data</div>
                            )}
                            {previewdetails && previewdetails.data && previewdetails.data.length > 0 && previewdetails.data.course_pdf ? (
                                <p><a
                                    href={previewdetails.data.course_pdf}  
                                    download={previewdetails.data.course_pdf}
                                    style={{ color: "#264ACA" }}
                                >
                                    Download</a> the full course syllabus to find out more!
                                </p>
                            ) : (
                                <p>Download the full course syllabus to find out more!</p>
                            )}
                        </div>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='bundle-right-sec'>
                        <div className='bundle-benefits p-5'>
                            <div className='heading-icon d-flex'>
                                <img src={deliveryIcon} alt='bundle price' className='feature-icon' />
                                <div className='feature-content'>
                                    <h2 className='heading-orange'>Delivery Mechanism</h2>

                                    {previewdetails && previewdetails.data ? (
                                        <div
                                            className='bundle_detailsprag'
                                            dangerouslySetInnerHTML={{ __html: previewdetails.data.Delivery_Mechanism }}
                                        ></div>
                                    ) : (
                                        <div>No data</div>
                                    )}
                                </div>
                            </div>

                            <div className='heading-icon d-flex'>
                                <img src={durationIcon} alt='bundle price' className='feature-icon' />
                                <div className='feature-content'>
                                    <h2 className='heading-orange'>Duration</h2>
                                    <ul>

                                        {previewdetails && previewdetails.data ? (
                                            <div
                                                className='bundle_detailsprag'
                                                dangerouslySetInnerHTML={{ __html: previewdetails.data.duration }}
                                            ></div>
                                        ) : (
                                            <div>No data</div>
                                        )}
                                    </ul>
                                </div>
                            </div>

                            <div className='heading-icon d-flex'>
                                <img src={validityIcon} alt='bundle price' className='feature-icon' />
                                <div className='feature-content'>
                                    <h2 className='heading-orange'>Validity</h2>
                                    <ul>
                                        <li>12 months (from the date of purchase)</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='heading-icon d-flex'>
                                <img src={labsIcon} alt='bundle price' className='feature-icon' />
                                <div className='feature-content'>
                                    <h2 className='heading-orange'>Hands-on Lab Training? </h2>
                                    <ul>

                                        {previewdetails && previewdetails.data ? (
                                            <li
                                                className='bundle_detailsprag'

                                                dangerouslySetInnerHTML={{
                                                    __html: previewdetails.data.handsonlabs === 'false' || previewdetails.data.handsonlabs === null
                                                        ? 'No'
                                                        : previewdetails.data.handsonlabs
                                                }}
                                            ></li>
                                        ) : null}

                                    </ul>
                                </div>
                            </div>
                            <div className='heading-icon d-flex'>
                                <img src={resourcesIcon} alt='bundle price' className='feature-icon' />
                                <div className='feature-content'>
                                    <h2 className='heading-orange'>Resources Available: </h2>

                                    {previewdetails && previewdetails.data ? (
                                        <div
                                            className='bundle_detailsprag'
                                            dangerouslySetInnerHTML={{ __html: previewdetails.data.resources }}
                                        ></div>
                                    ) : (
                                        <div>No data</div>
                                    )}
                                </div>
                            </div>
                            <div className='heading-icon d-flex'>
                                <img src={voucherIcon} alt='bundle price' className='feature-icon' />
                                <div className='feature-content'>
                                    <h2 className='heading-orange'>Exam Voucher Included? </h2>
                                    <ul>

                                        {previewdetails && previewdetails.data ? (
                                            <li
                                                className='bundle_detailsprag'
                                                dangerouslySetInnerHTML={{ __html: (previewdetails.data.vouchers == 'false' || previewdetails.data.vouchers == null) ? 'No.' : 'Yes.' }}
                                            ></li>
                                        ) : (
                                            <li>No data</li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                            <div className='heading-icon d-flex'>
                                <img src={pricingIcon} alt='bundle price' className='feature-icon' />
                                <div className='feature-content'>
                                    <h2 className='heading-orange'>
                                        Total Course Bundle Price</h2>

                                    <BuyBtn />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        // <div>
        //   <div className='row microsoft-bootcamp' style={{padding: '0px', paddingLeft: '14rem'}}>
        //     <div className='col-md-6'>
        //       <div className='col-md-6'>
        //       <div className='heading text-start'>
        //                 <span style={{fontSize: '25px', fontWeight: '400'}}>CompTIA Bootcamp</span>
        //                 <h2 style={{fontSize: '40px', fontWeight: '500', width: '100%'}}>
        //                   { previewdetails?.data?.title}
        //                 </h2>
        //                 <div dangerouslySetInnerHTML={{__html: previewdetails?.data?.description ?? null}}></div>
        //               </div>


        //         {/* {Object.entries(previewdetails).map(([key, course], i) => {
        //           if (course.description) {
        //             return (
        //               <div className='heading text-start'>
        //                 <span style={{fontSize: '25px', fontWeight: '400'}}>CompTIA Bootcamp</span>
        //                 <h2 style={{fontSize: '40px', fontWeight: '500', width: '100%'}}>
        //                   {course.title}
        //                 </h2>
        //                 <p
        //                   style={{
        //                     fontSize: '15px',
        //                     width: '100%',
        //                     paddingTop: '1rem',
        //                     fontWeight: '500',
        //                   }}
        //                   dangerouslySetInnerHTML={{__html: course.description}}
        //                 ></p>
        //               </div>
        //             )
        //           } else {
        //             return (
        //               <div>
        //                 <p>No data available</p>
        //               </div>
        //             )
        //           }
        //         })} */}
        //       </div>
        //     </div>
        //     <div className='col-md-6' style={{position: 'relative', width: '50%'}}>
        //       <img
        //         src={comptiacircle}
        //         alt='bundleimg'
        //         style={{width: '50%', position: 'relative', zIndex: '1', left: '135px'}}
        //       />
        //       <img
        //         src={Group}
        //         alt='circle'
        //         style={{width: '45%', position: 'absolute', top: '-109px', left: '459', zIndex: '2'}}
        //       />
        //     </div>
        //   </div>
        //   <Section2 />
        //   {/* <Compitiadateabl /> */}

        //   <Section3 />

        //   <Section4 />

        //   <Section5 />
        // </div>
    )
}

export default Section1
