import React, {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {
  GetAllCountriesAction,
  GetStateByCountryAction,
  GetCityByStateAction,
} from '../../../Redux/Action/locationAction'

import {useForm} from 'react-hook-form'
import {GetallteacherCourseAction} from '../../../Redux/Action/courseAction'
import {sendMailAction, PreviewEmailAction} from '../../../Redux/Action/authAction'
import {
  UpdateInstructorByidAction,
  GetInstructorCoursesDetails,
  GetInstructorbyId,
} from '../../../Redux/Action/instructorAction'
import {Button, Modal} from 'react-bootstrap'
import {Col, Row} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import TimezoneSelect from 'react-timezone-select'
import Loader from '../../../components/Loader/loader'
const EditInstructor = () => {
  var newURL = window.location

  var splitURL = newURL.href.split('/')

  const instid = splitURL[4]
  const [previewHtml, setPreviewtml] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  )
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: {errors},
    setValue,
    getValues,
  } = useForm({})

  const instructorCoursesdetails = useSelector((state) => {
    return state.login.getaInstructorCourseslist
  })

  useEffect(() => {
    dispatch(GetInstructorCoursesDetails(instid))
  }, [dispatch, instid])

  const instructordetails = useSelector((state) => {
    return state.login.getaInstructorIdlist
  })

  useEffect(() => {
    dispatch(GetInstructorbyId(instid))
  }, [dispatch, instid])

  useEffect(() => {
    setValue('FirstName', instructordetails.message && instructordetails.message.FirstName)
    setValue('Surname', instructordetails.message && instructordetails.message.Surname)
    setValue('Email', instructordetails.message && instructordetails.message.Email)
    setValue('PhoneNumber', instructordetails.message && instructordetails.message.PhoneNumber)
    setValue('Country', instructordetails.message && instructordetails.message.Country)
    setValue('state', instructordetails.message && instructordetails.message.state)
    setValue('City', instructordetails.message && instructordetails.message.City)

    setValue('mtm_id', instructordetails.message && instructordetails.message.mtm_id)
    setValue('comptia_id', instructordetails.message && instructordetails.message.comptia_id)
    setValue('other_id', instructordetails.message && instructordetails.message.other_id)
    setValue('qualification', instructordetails.message && instructordetails.message.qualification)
    setValue('experiance', instructordetails.message && instructordetails.message.experiance)
    setValue('IBANNumber', instructordetails.message && instructordetails.message.IBANNumber)
    setValue('AccountName', instructordetails.message && instructordetails.message.AccountName)
    setValue('AccountNumber', instructordetails.message && instructordetails.message.AccountNumber)
    setValue('companyName', instructordetails.message && instructordetails.message.Company_Name)
    setValue('dayRate', instructordetails.message && instructordetails.message.dayRate)
    setSelectedTimezone(
      instructordetails.message?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
    )
    if (instructorCoursesdetails.data) {
      const formattedData = instructorCoursesdetails.data.reduce(
        (acc, course) => {
          acc.courses[course.course_id.toString()] = course.title
          return acc
        },
        {courses: {}}
      )

      setSelectedCheckboxes(formattedData)
    }
  }, [instructordetails])

  const allcourse = useSelector((state) => {
    return state.login.getteachercourseslist
  })
  const [selectedCountryISOCode, setSelectedCountryISOCode] = useState('')
  const [selectedStateISOCode, setSelectedStateISOCode] = useState('')
  useEffect(() => {
    dispatch(GetallteacherCourseAction())
  }, [dispatch])
  const [file, setFile] = useState(null)

  const validateEmailFormat = (value) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
    return emailRegex.test(value) || 'Enter a valid email address'
  }
  const formattedTimezone = selectedTimezone?.value || ''
  const onSubmit = (data) => {
    const formData = new FormData()
    if (data.profileUrl[0]) {
      formData.append('profileUrl', data.profileUrl[0])
    }
    formData.append('FirstName', data.FirstName)
    formData.append('Surname', data.Surname)

    formData.append('PhoneNumber', data.PhoneNumber)
    formData.append('Country', selectedCountryISOCode)
    formData.append('state', selectedStateISOCode)
    formData.append('City', data.City)
    formData.append('timezone', formattedTimezone || selectedTimezone)
    formData.append('mtm_id', data.mtm_id)
    formData.append('comptia_id', data.comptia_id)
    formData.append('other_id', data.other_id)
    formData.append('qualification', data.qualification)
    formData.append('experiance', data.experiance)
    formData.append('courses', data.courses)
    formData.append('IBANNumber', data.IBANNumber)
    formData.append('AccountName', data.AccountName)
    formData.append('AccountNumber', data.AccountNumber)
    formData.append('companyName', data.companyName)
    formData.append('selectedCourses', JSON.stringify(selectedCheckboxes))
    formData.append('dayRate', data.dayRate)
    dispatch(UpdateInstructorByidAction(formData, instid, navigate))
  }

  const allCountries = useSelector((state) => {
    return state.login.getAllCountriesList
  })

  useEffect(() => {
    dispatch(GetAllCountriesAction())
  }, [dispatch])

  const state = useSelector((state) => {
    return state.login.getStateByCountrList
  })

  useEffect(() => {
    dispatch(GetStateByCountryAction(selectedCountryISOCode))
  }, [dispatch, selectedCountryISOCode])

  const city = useSelector((state) => {
    return state.login.getCityByStateList
  })

  useEffect(() => {
    dispatch(GetCityByStateAction(selectedCountryISOCode, selectedStateISOCode))
  }, [dispatch, selectedCountryISOCode, selectedStateISOCode])

  const [selectedCheckboxes, setSelectedCheckboxes] = useState({
    courses: {},
  })

  const handleCheckboxChange = (id, value) => {
    setSelectedCheckboxes((prevSelected) => {
      const updatedCourseIds = {...prevSelected.courses}

      if (value.checked) {
        updatedCourseIds[value.id] = value.title
      } else {
        delete updatedCourseIds[value.id]
      }

      return {
        ...prevSelected,
        courses: updatedCourseIds,
      }
    })
  }

  const handleChange = (event, formData) => {
    const selectedValue = event.target.value
    setSelectedCountryISOCode(selectedValue)
    setValue('Country', selectedValue) // Update the state value
    setSelectedStateISOCode('')
    setValue('state', '')
    setValue('City', '') // Clear state when country changes
  }

  useEffect(() => {
    dispatch(GetInstructorbyId(instid))

    const storedCountry = localStorage.getItem('selectedCountryISOCode')
    if (storedCountry) {
      setSelectedCountryISOCode(storedCountry)
    }
  }, [dispatch, instid])

  useEffect(() => {
    if (instructordetails.message) {
      setSelectedCountryISOCode(instructordetails.message.Country || '')
      setSelectedStateISOCode(instructordetails.message.state || '')
    }
  }, [instructordetails.message])

  const handleStateChange = (e) => {
    const selectedState = e.target.value
    setSelectedStateISOCode(selectedState)
    setValue('state', selectedState) // Update the state value
  }

  const [selectedCity, setSelectedCity] = useState('')

  useEffect(() => {
    if (instructordetails.message) {
      setSelectedCity(instructordetails.message && instructordetails.message.City)
    }
  }, [instructordetails.message])

  useEffect(() => {
    setValue('City', selectedCity)
  }, [selectedCity, setValue])

  const handleCityChange = (event, formData) => {
    const selectedCityValue = event.target.value
    setSelectedCity(selectedCityValue)
    //formData.set('City', selectedCityValue)
  }

  const [loading, setLoading] = useState(false)

  const handleSubmit1 = () => {
    const formData = new FormData()
    setLoading(true)
    formData.append('email', instructordetails.message && instructordetails.message.Email)
    formData.append('firstName', instructordetails.message && instructordetails.message.FirstName)
    formData.append('lastName', instructordetails.message && instructordetails.message.Surname)
    formData.append('forceReset', instructordetails.message && instructordetails.message.forceReset)
    formData.append('amount', instructordetails.message && instructordetails.amountpaid)
    formData.append('courseId', instructordetails.message && instructordetails.courseCode)
    formData.append('roleid', instructordetails.message && instructordetails.message.roleid)
    formData.append('userId', instructordetails.message && instructordetails.message.insid)
    dispatch(sendMailAction(formData, setLoading))
  }

  const handlePreviewEmail = () => {
    if (instructordetails.message?.Password == 0 || instructordetails.message?.Password == null) {
      const formData = new FormData()
      formData.append('email', instructordetails.message && instructordetails.message.Email)
      formData.append('firstName', instructordetails.message && instructordetails.message.FirstName)
      formData.append('lastName', instructordetails.message && instructordetails.message.Surname)
      formData.append(
        'forceReset',
        instructordetails.message && instructordetails.message.forceReset
      )
      formData.append('amount', instructordetails.message && instructordetails.amountpaid)
      formData.append('courseId', instructordetails.message && instructordetails.courseCode)
      formData.append('roleid', instructordetails.message && instructordetails.message.roleid)
      formData.append('userId', instructordetails.message && instructordetails.message.insid)
      dispatch(PreviewEmailAction(formData))
    }
    //setPreviewtml(emailPreviewHtml?.message?.html)
    setShowModal(true)
  }

  const emailPreviewHtml = useSelector((state) => {
    return state.login.emailPreviewList
  })

  useEffect(() => {
    if (emailPreviewHtml) {
      setPreviewtml(emailPreviewHtml?.message?.html)
    }
  }, [emailPreviewHtml])

  const handleButtonClick = () => {
    setShowModal(true)
  }

  const closePopup = () => {
    setShowModal(false)
  }

  return (
    <div className='instructor-editii'>
      <Row>
        <Col lg={12}>
          <div
            className='user-editor'
            style={{
              marginTop: '47px',
            }}
          >
            {loading && (
              <div className='loader-container'>
                <Loader />
              </div>
            )}
            <div className='card mb-5 mb-xl-10'>
              <form id='kt_account_profile_details_form' className='form'>
                <div className='card-body border-top p-9'>
                  <div className='d-flex align-items-center fs-4 fw-bold mb-5'>
                    {' '}
                    Contact Details
                  </div>
                  <div className='row'>
                    <label className='col-lg-4 col-form-label fw-semibold fs-6'>Avatar</label>
                    <div className='col-lg-8'>
                      {instructordetails.message?.profileUrl && (
                        <img
                          src={instructordetails.message.profileUrl}
                          alt='Current Profile'
                          style={{maxWidth: '100px'}}
                        />
                      )}

                      <input
                        type='file'
                        {...register('profileUrl')}
                        className='form-control W-50 mt-5'
                        onChange={(e) => {
                          const selectedFile = e.target.files[0]
                          setFile(URL.createObjectURL(selectedFile))
                        }}
                      />
                      {file && <img src={file} alt='Selected' style={{maxWidth: '100px'}} />}
                      <div className='form-text'>Allowed file types: png, jpg, jpeg.</div>
                      {errors.profileUrl && (
                        <p className='alert_danger' role='alert'>
                          {errors.profileUrl.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label required fw-semibold fs-6'>
                      Full Name
                    </label>

                    <div className='col-lg-8'>
                      <div className='row'>
                        <div className='col-lg-6 fv-row'>
                          <input
                            type='text'
                            name='fname'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder='First name'
                            {...register('FirstName', {
                              required: 'This is required field',
                            })}
                            aria-invalid={errors.FirstName ? 'true' : 'false'}
                          />
                          {errors.FirstName && (
                            <p role='alert' style={{marginTop: '1rem', color: 'crimson'}}>
                              {errors.FirstName?.message}
                            </p>
                          )}
                        </div>

                        <div className='col-lg-6 fv-row'>
                          <input
                            type='text'
                            name='fname'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder='First name'
                            {...register('Surname', {
                              required: 'This is required field',
                            })}
                            aria-invalid={errors.Surname ? 'true' : 'false'}
                          />
                          {errors.Surname && (
                            <p role='alert' style={{marginTop: '1rem', color: 'crimson'}}>
                              {errors.Surname?.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                      <span className='required'>Email</span>
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <input
                        type='email'
                        name='email'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Email'
                        {...register('Email', {
                          required: 'This is required field',
                          validate: validateEmailFormat,
                        })}
                        aria-invalid={errors.Email ? 'true' : 'false'}
                      />
                      {errors.Email && (
                        <p className='alert_danger' role='alert'>
                          {errors.Email.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                      <span className='required'>Telephone</span>
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <input
                        type='tel'
                        name='phone'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Phone number'
                        {...register('PhoneNumber', {
                          required: 'This is required field',
                        })}
                        aria-invalid={errors.PhoneNumber ? 'true' : 'false'}
                        onKeyPress={(e) => {
                          const isNumeric = /^[0-9]$/
                          if (!isNumeric.test(e.key)) {
                            e.preventDefault()
                          }
                        }}
                      />
                      {errors.PhoneNumber && (
                        <p role='alert' style={{marginTop: '1rem', color: 'crimson'}}>
                          {errors.PhoneNumber?.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                      <span className='required'>Timezone</span>
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <TimezoneSelect
                        //options={[{label: 'Select Timezone', value: selectedTimezone}]}
                        value={selectedTimezone}
                        onChange={setSelectedTimezone}
                        placeholder='Select Timezone'
                      />
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                      <span className='required'>Country</span>
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <select
                        name='country'
                        aria-label='Select a Country'
                        data-control='select2'
                        data-placeholder='Select a country...'
                        className='form-select form-select-solid form-select-lg fw-semibold'
                        required
                        {...register('Country', {
                          required: 'Country is a required field',
                        })}
                        aria-invalid={errors.Country ? 'true' : 'false'}
                        value={selectedCountryISOCode}
                        onChange={(e) => handleChange(e, setValue)}
                      >
                        {/* Move the console.log outside the JSX */}

                        <option>Select a Country...</option>
                        {Object.values(allCountries).map((countryArr) =>
                          countryArr.map((country, i) => (
                            <option key={i} value={country.isoCode}>
                              {country.name}
                            </option>
                          ))
                        )}
                      </select>
                      {errors.Country && (
                        <p className='alert_danger' role='alert'>
                          {errors.Country?.message}
                        </p>
                      )}
                    </div>
                  </div>
                  {state.count > 0 && (
                    <>
                      <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                          <span className='required'>State/County</span>
                        </label>

                        <div className='col-lg-8 fv-row'>
                          <select
                            name='country'
                            aria-label='Select Country'
                            data-control='select2'
                            data-placeholder='Select a country...'
                            className='form-select form-select-solid form-select-lg fw-semibold'
                            aria-invalid={errors.Country ? 'true' : 'false'}
                            value={selectedStateISOCode}
                            onChange={handleStateChange}
                          >
                            <option value=''>Select State...</option>
                            {state.states.map((item) => (
                              <option key={item.isoCode} value={item.isoCode}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      {city.count > 0 && (
                        <div className='row mb-6'>
                          <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                            <span>City</span>
                          </label>

                          <div className='col-lg-8 fv-row'>
                            <select
                              name='City'
                              aria-label='Select a City'
                              data-control='select2'
                              data-placeholder='choose a City...'
                              className='form-select form-select-solid form-select-lg fw-semibold'
                              {...register('City')}
                              aria-invalid={errors.City ? 'true' : 'false'}
                              value={selectedCity}
                              onChange={(e) => handleCityChange(e)}
                            >
                              <option>Select a City...</option>
                              {Array.isArray(city.message) &&
                                city.message.map((cityOption, index) => (
                                  <option key={index} value={cityOption.name}>
                                    {cityOption.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  <div>
                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                        <span className=''>Day Rate(£)</span>
                      </label>

                      <div className='col-lg-8 fv-row'>
                        <input
                          type='dayRate'
                          name='dayRate'
                          className='form-control form-control-lg form-control-solid'
                          placeholder='Day Rate(£)'
                          defaultValue='YourDefaultValueHere'
                          {...register('dayRate')}
                          aria-invalid={errors.dayRate ? 'true' : 'false'}
                          onKeyPress={(e) => {
                            const isNumeric = /^[0-9]$/
                            if (!isNumeric.test(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                        {errors.dayRate && (
                          <p role='alert' style={{marginTop: '1rem'}}>
                            {errors.dayRate?.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label fw-semibold fs-6'>MTM ID</label>
                      <div className='col-lg-8 fv-row'>
                        <input
                          type='text'
                          name='mtmId'
                          className='form-control form-control-lg form-control-solid'
                          placeholder='MTM ID'
                          aria-invalid={errors.mtm_id ? 'true' : 'false'}
                          {...register('mtm_id')}
                        />
                        {errors.mtm_id && (
                          <p role='alert' style={{marginTop: '1rem', color: 'crimson'}}>
                            {errors.mtm_id?.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label fw-semibold fs-6'>CompTIA ID</label>
                      <div className='col-lg-8 fv-row'>
                        <input
                          type='text'
                          name='comptiaId'
                          className='form-control form-control-lg form-control-solid'
                          placeholder='CompTIA ID'
                          {...register('comptia_id')}
                          aria-invalid={errors.comptia_id ? 'true' : 'false'}
                        />
                        {errors.comptia_id && (
                          <p role='alert' style={{marginTop: '1rem', color: 'crimson'}}>
                            {errors.comptia_id?.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label fw-semibold fs-6'>Other IDs</label>
                      <div className='col-lg-8 fv-row'>
                        <input
                          type='text'
                          name='otherIds'
                          className='form-control form-control-lg form-control-solid'
                          placeholder='Other IDs'
                          {...register('other_id')}
                          aria-invalid={errors.other_id ? 'true' : 'false'}
                        />
                        {errors.other_id && (
                          <p role='alert' style={{marginTop: '1rem', color: 'crimson'}}>
                            {errors.other_id?.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <hr class='solid' />
                  <div>
                    <div className='d-flex align-items-center fs-4 fw-bold mb-5'>
                      {' '}
                      Teaching Details
                    </div>
                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                        <span className=''>Qualifications / Accreditation</span>
                      </label>

                      <div className='col-lg-8 fv-row'>
                        <input
                          type='text'
                          name='phone'
                          className='form-control form-control-lg form-control-solid'
                          placeholder='Qualifications'
                          {...register('qualification')}
                          aria-invalid={errors.qualification ? 'true' : 'false'}
                        />
                        {errors.qualification && (
                          <p role='alert' style={{marginTop: '1rem', color: 'crimson'}}>
                            {errors.qualification?.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                        <span className=''>Years of teaching experience</span>
                      </label>

                      <div className='col-lg-8 fv-row'>
                        <select
                          name='experiance'
                          aria-label='Select a experience'
                          data-control='select2'
                          data-placeholder='Select a experience...'
                          className='form-select form-select-solid form-select-lg fw-semibold'
                          {...register('experiance')}
                          aria-invalid={errors.experiance ? 'true' : 'false'}
                        >
                          {errors.experiance && (
                            <p role='alert' style={{marginTop: '1rem', color: 'crimson'}}>
                              {errors.experiance?.message}
                            </p>
                          )}
                          <option value=''>Select a experience...</option>
                          <option value='1'>1 Year</option>
                          <option value='2-5'>2-5 Years</option>
                          <option value='5+'>5+ Years</option>
                        </select>
                      </div>
                    </div>

                    <div class='row mb-6'>
                      <label class='col-lg-4 col-form-label  fw-semibold fs-6'>
                        Teaching Courses
                      </label>

                      <div class='col-lg-8 fv-row'>
                        <ul>
                          {allcourse.map((item, index) => (
                            <li
                              style={{margin: 0, padding: 0}}
                              className='form-check'
                              key={`bundle-${index}`}
                            >
                              <input
                                type='checkbox'
                                className='form-check-input'
                                id={`checkboxBundle-${index}`}
                                value={`${item.id}-${item.title}`}
                                checked={selectedCheckboxes.courses[item.id] !== undefined}
                                onChange={() =>
                                  handleCheckboxChange(`bundle${item.id}`, {
                                    ...item,
                                    checked: !selectedCheckboxes.courses[item.id],
                                  })
                                }
                              />
                              <label
                                className='form-label mt-0 p-1'
                                htmlFor={`checkboxBundle-${index}`}
                              >
                                {item.title}
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <hr class='solid' />
                  <div>
                    <div className='d-flex align-items-center fs-4 fw-bold mb-5'> Bank Details</div>

                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                        <span className=''>Bank account / IBAN numbers</span>
                      </label>

                      <div className='col-lg-8 fv-row'>
                        <input
                          type='text'
                          name='phone'
                          className='form-control form-control-lg form-control-solid'
                          placeholder='Bank account'
                          {...register('IBANNumber')}
                          aria-invalid={errors.IBANNumber ? 'true' : 'false'}
                        />
                        {errors.IBANNumber && (
                          <p
                            className='alert_danger'
                            role='alert'
                            style={{background: 'white', fontSize: '12px', color: 'crimson'}}
                          >
                            {errors.IBANNumber?.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                        <span className=''>Account Name</span>
                      </label>

                      <div className='col-lg-8 fv-row'>
                        <input
                          type='text'
                          name='AccountName'
                          className='form-control form-control-lg form-control-solid'
                          placeholder='Account Name'
                          {...register('AccountName')}
                          aria-invalid={errors.AccountName ? 'true' : 'false'}
                        />
                        {errors.AccountName && (
                          <p
                            className='alert_danger'
                            role='alert'
                            style={{background: 'white', fontSize: '12px', color: 'crimson'}}
                          >
                            {errors.AccountName?.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                        <span className=''>Account Number / IBAN</span>
                      </label>

                      <div className='col-lg-8 fv-row'>
                        <input
                          type='text'
                          name='AccountNumber'
                          className='form-control form-control-lg form-control-solid'
                          placeholder='Account Number'
                          {...register('AccountNumber')}
                          aria-invalid={errors.AccountNumber ? 'true' : 'false'}
                        />
                        {errors.AccountNumber && (
                          <p
                            className='alert_danger'
                            role='alert'
                            style={{background: 'white', fontSize: '12px', color: 'crimson'}}
                          >
                            {errors.AccountNumber?.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                        <span>Company/Account Name</span>
                      </label>

                      <div className='col-lg-8 fv-row'>
                        <input
                          type='text'
                          name='companyName'
                          className='form-control form-control-lg form-control-solid'
                          placeholder='Company Name'
                          {...register('companyName')}
                          aria-invalid={errors.companyName ? 'true' : 'false'}
                        />
                        {errors.companyName && (
                          <p className='alert_danger' role='alert'>
                            {errors.companyName?.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                  <Link to='/alluserlist'>
                    <button type='reset' className='btn cybercancelbtn me-2'>
                      Cancel
                    </button>
                  </Link>
                  <button
                    type='submit'
                    id='kt_ecommerce_add_product_submit'
                    className='btn cyberbtnallpage'
                    style={{background: '#FF763F', color: 'white'}}
                    onClick={handleSubmit(onSubmit)}
                  >
                    Save Changes
                  </button>
                </div>
              </form>
              <div className='p-6'>
                {instructordetails.message?.Password == 0 ||
                instructordetails.message?.Password == null ? (
                  <div>
                    <button
                      type='submit'
                      id='kt_ecommerce_add_product_submit'
                      className='btn cyberbtnallpage'
                      style={{background: '#FF763F', color: 'white', width: '10%'}}
                      onClick={handleSubmit1}
                    >
                      Send Mail
                    </button>
                    <button
                      type='submit'
                      id='kt_ecommerce_add_product_submit'
                      className='btn cyberbtnallpage'
                      style={{background: '#FF763F', color: 'white', width: '10%'}}
                      onClick={handlePreviewEmail}
                    >
                      Preview Mail
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Modal show={showModal} onHide={closePopup} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Email Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body dangerouslySetInnerHTML={{__html: previewHtml}} />
        <Modal.Footer>
          <Button variant='secondary' onClick={closePopup}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default EditInstructor
