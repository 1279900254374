import React, {useEffect, useState} from 'react'
import {
  UpdateuseridAction,
  sendMailAction,
  PreviewEmailAction,
} from '../../../Redux/Action/authAction'
import {BoughtcourseAction} from '../../../Redux/Action/courseAction'
import {BoughtBundleAction} from '../../../Redux/Action/bundleAction'
import {Alluseredit} from '../../../Redux/Action/profileAction'
import {useForm} from 'react-hook-form'
import {Link, useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import TimezoneSelect from 'react-timezone-select'
import {Button, Modal} from 'react-bootstrap'
import {ArrowLeftOutlined} from '@ant-design/icons'
import Loader from '../../../components/Loader/loader'
import {
  GetallCourseAction,
  GetallCoursesBundlesAction,
  PreviewDetailsAction,
} from '../../../Redux/Action/courseAction'
import {
  GetAllCountriesAction,
  GetStateByCountryAction,
  GetCityByStateAction,
} from '../../../Redux/Action/locationAction'
import {GetallbundleactionAction} from '../../../Redux/Action/bundleAction'

const Edituser = () => {
  var newURL = window.location

  var splitURL = newURL.href.split('/')
  const id = splitURL[4]
  const [file, setFile] = useState(null)

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: {errors},
    watch,
    setValue,
  } = useForm({})

  const userdetails = useSelector((state) => {
    return state.login.getedituser_list
  })

  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  )

  const [educationLevel, setEducationLevel] = useState('')
  const [PurposeOfTakingTheCourse, setPurposeOfTakingTheCourse] = useState('')
  const [EmploymentStatus, setEmploymentStatus] = useState('')
  const [HowGotToKnow, setHowGotToKnow] = useState('')

  const [educationLeveloptions, seteducationLevelOptions] = useState([
    'High-school',
    'University Degree',
    'Other',
  ])
  const [purposeOptions, setpurposeOption] = useState([
    'To enhance my Skills',
    'To learn about Microsoft Security',
    'To improve my chances of getting a job',
    'Other',
  ])
  const [employmentOption, setemploymentOption] = useState([
    'Unemployed',
    'Employed in a cyber security role',
    'Employed in a unrelated cyber security role',
    'Other',
  ])
  const [HowGotToKnowOption, setHowGotToKnowOption] = useState([
    'Word of mouth',
    'Referral',
    'Social Media',
    'Google',
    'Other',
  ])

  const [educationLevelExists, seteducationLevelExists] = useState(false)
  const [purposeExists, setPurposeExists] = useState(false)
  const [employmentExists, setemploymentExists] = useState(false)
  const [HowGotToKnowExists, setHowGotToKnowExists] = useState(false)

  useEffect(() => {
    const educationLevelexists = educationLeveloptions.some(
      (option) => option.toString() == userdetails.message?.EducationLevel.toString()
    )
    seteducationLevelExists(educationLevelexists)
  }, [userdetails.message?.EducationLevel, educationLeveloptions])


  useEffect(() => {
    const Purposeexists = purposeOptions.some(
      (option) => option.toString() == userdetails.message?.PurposeOfTakingTheCourse.toString()
    )
    setPurposeExists(Purposeexists)
  }, [userdetails.message?.PurposeOfTakingTheCourse, purposeOptions])


  useEffect(() => {
    const employmentexists = employmentOption.some(
      (option) => option.toString() == userdetails.message?.EmploymentStatus.toString()
    )
    setemploymentExists(employmentexists)
  }, [userdetails.message?.EmploymentStatus, employmentOption])


  useEffect(() => {
    const HowGotToKnowexists = HowGotToKnowOption.some(
      (option) => option.toString() == userdetails.message?.HowYouGotToKnow.toString()
    )
    setHowGotToKnowExists(HowGotToKnowexists)
  }, [userdetails.message?.HowYouGotToKnow, HowGotToKnowOption])

  const handleeducationLevelChange = (e) => {
    setEducationLevel(e.target.value)
  }

  const handlePurposeChange = (e) => {
    setPurposeOfTakingTheCourse(e.target.value)
  }

  const handleEmploymentChange = (e) => {
    setEmploymentStatus(e.target.value)
  }

  const handleHowGotToKnowChange = (e) => {
    setHowGotToKnow(e.target.value)
  }

  useEffect(() => {
    debugger
    setValue('FirstName', userdetails.message?.FirstName || '')
    setValue('Surname', userdetails.message?.Surname || '')
    setValue('Email', userdetails.message?.Email || '')
    setValue('PhoneNumber', userdetails.message?.PhoneNumber || '')
    setValue('Country', userdetails.message?.Country || '')
    setValue('state', userdetails.message?.state || '')
    setValue('City', userdetails.message?.City || '')
    setValue('timezone', userdetails.message?.timezone || '')
    setValue('profileUrl', userdetails.message?.profileUrl || '')
    setValue(
      'YearsOfCyberSecurityExperience',
      userdetails.message?.YearsOfCyberSecurityExperience || ''
    )

    if (userdetails.message?.EducationLevel) {
      const educationLevelExists = educationLeveloptions.some(
        (option) => option.toString() == userdetails.message?.EducationLevel.toString()
      )

      if (educationLevelExists) {
        setEducationLevel(userdetails.message?.EducationLevel)
        setValue('EducationLevel', userdetails.message?.EducationLevel)
      } else {
        setEducationLevel('Other')
        setValue('OtherEducationLevel', userdetails.message?.EducationLevel)
        setValue('EducationLevel', 'Other')
      }
    }

    if (userdetails.message?.PurposeOfTakingTheCourse) {
      const purposeExists = purposeOptions.some(
        (option) => option.toString() == userdetails.message?.PurposeOfTakingTheCourse.toString()
      )

      if (purposeExists) {
        setPurposeOfTakingTheCourse(userdetails.message?.PurposeOfTakingTheCourse)
        setValue('PurposeOfTakingTheCourse', userdetails.message?.PurposeOfTakingTheCourse)
      } else {
        setPurposeOfTakingTheCourse('Other')
        setValue('OtherPurposeOfTakingTheCourse', userdetails.message?.PurposeOfTakingTheCourse)
        setValue('PurposeOfTakingTheCourse', 'Other')
      }
    }

    if (userdetails.message?.EmploymentStatus) {
      const employmentExists = employmentOption.some(
        (option) => option.toString() == userdetails.message?.EmploymentStatus.toString()
      )

      if (employmentExists) {
        setEmploymentStatus(userdetails.message?.EmploymentStatus)
        setValue('EmploymentStatus', userdetails.message?.EmploymentStatus)
      } else {
        setEmploymentStatus('Other')
        setValue('OtherEmploymentStatus', userdetails.message?.EmploymentStatus)
        setValue('EmploymentStatus', 'Other')
      }
    }

    if (userdetails.message?.HowYouGotToKnow) {
      const HowGotToKnowExists = HowGotToKnowOption.some(
        (option) => option.toString() == userdetails.message?.HowYouGotToKnow.toString()
      )

      if (HowGotToKnowExists) {
        setHowGotToKnow(userdetails.message?.HowYouGotToKnow)
        setValue('HowYouGotToKnow', userdetails.message?.HowYouGotToKnow)
      } else {
        setHowGotToKnow('Other')
        setValue('OtherHowYouGotToKnow', userdetails.message?.HowYouGotToKnow)
        setValue('HowYouGotToKnow', 'Other')
      }
    }

    setValue('InterestedInCyberheadRole', userdetails.message?.InterestedInCyberheadRole || '')

    setSelectedTimezone(
      userdetails.message?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
    )
  }, [userdetails])

  useEffect(() => {
    dispatch(Alluseredit(id))
  }, [dispatch, id])

  const [selectedCountryISOCode, setSelectedCountryISOCode] = useState('')

  const [selectedStateISOCode, setSelectedStateISOCode] = useState('')

  const validateEmailFormat = (value) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
    return emailRegex.test(value) || 'Enter a valid email address'
  }

  const formattedTimezone = selectedTimezone?.value || ''

  const onSubmit = (data) => {
    const formData = new FormData()
    debugger
    // Append profilePic only if a new image is selected
    if (data.profileUrl[0]) {
      formData.append('profileUrl', data.profileUrl[0])
    }

    formData.append('FirstName', data.FirstName)
    formData.append('Surname', data.Surname)
    formData.append('PhoneNumber', data.PhoneNumber)
    formData.append('Country', selectedCountryISOCode)
    formData.append('state', selectedStateISOCode)
    formData.append('City', data.City)
    formData.append('timezone', formattedTimezone)
    formData.append('Email', data.Email)
    formData.append('YearsOfCyberSecurityExperience', data.YearsOfCyberSecurityExperience)
    formData.append('InterestedInCyberheadRole', data.InterestedInCyberheadRole)

    if (educationLevel == 'Other') {
      formData.append('EducationLevel', data.OtherEducationLevel)
    } else {
      formData.append('EducationLevel', educationLevel)
    }

    if (PurposeOfTakingTheCourse == 'Other') {
      formData.append('PurposeOfTakingTheCourse', data.OtherPurposeOfTakingTheCourse)
    } else {
      formData.append('PurposeOfTakingTheCourse', PurposeOfTakingTheCourse)
    }

    if (EmploymentStatus == 'Other') {
      formData.append('EmploymentStatus', data.OtherEmploymentStatus)
    } else {
      formData.append('EmploymentStatus', EmploymentStatus)
    }

    if (HowGotToKnow == 'Other') {
      formData.append('HowYouGotToKnow', data.OtherHowYouGotToKnow)
    } else {
      formData.append('HowYouGotToKnow', HowGotToKnow)
    }

    formData.append('CourseId', courseId)
    formData.append('MicrosoftCourseId', microsoftBootcampId)
    formData.append('CompTIACourseId', comptiaBootcampId)
    formData.append('courseDate', selectedDate)
    formData.append('selectedMicrosoftDate', selectedMicrosoftDate)
    formData.append('selectedCompTIABootcampDate', selectedComptiaDate)
    formData.append('Bundle_code', bundleCode)
    //formData.append('timezone', selectedTimezone)
    dispatch(UpdateuseridAction(formData, id, navigate))
  }

  const allCountries = useSelector((state) => {
    return state.login.getAllCountriesList
  })

  useEffect(() => {
    dispatch(GetAllCountriesAction())
  }, [dispatch])

  const state = useSelector((state) => {
    return state.login.getStateByCountrList
  })

  useEffect(() => {
    dispatch(GetStateByCountryAction(selectedCountryISOCode))
  }, [dispatch, selectedCountryISOCode])

  const city = useSelector((state) => {
    return state.login.getCityByStateList
  })

  useEffect(() => {
    dispatch(GetCityByStateAction(selectedCountryISOCode, selectedStateISOCode))
  }, [dispatch, selectedCountryISOCode, selectedStateISOCode])

  const handleChange = (event, formData) => {
    const selectedValue = event.target.value
    setSelectedCountryISOCode(selectedValue)
    // formData.set('Country', selectedValue)

    // Clear state and city when country changes
    setSelectedStateISOCode('')
    // formData.set('state', '')
  }

  useEffect(() => {
    dispatch(Alluseredit(id), [id])

    const storedCountry = localStorage.getItem('selectedCountryISOCode')
    if (storedCountry) {
      setSelectedCountryISOCode(storedCountry)
    }
  }, [dispatch, id])

  useEffect(() => {
    if (userdetails.message) {
      setSelectedCountryISOCode(userdetails.message.Country || '')
      setSelectedStateISOCode(userdetails.message.state || '')
    }
  }, [userdetails.message])

  const handleStateChange = (e) => {
    const selectedState = e.target.value
    setSelectedStateISOCode(selectedState)
  }
  const [selectedCity, setSelectedCity] = useState('')

  useEffect(() => {
    if (userdetails.message) {
      setSelectedCity(userdetails.message.City || '')
    }
  }, [userdetails.message])

  useEffect(() => {
    setValue('City', selectedCity)
  }, [selectedCity, setValue])

  const handleCityChange = (event, formData) => {
    const selectedCityValue = event.target.value
    setSelectedCity(selectedCityValue)
    // formData.set('City', selectedCityValue)
  }

  const emailPreviewHtml = useSelector((state) => {
    return state.login.emailPreviewList
  })

  const handleButtonClick = () => {
    setShowModal(true)
  }

  const closePopup = () => {
    setShowModal(false)
  }
  const [loading, setLoading] = useState(false)
  const handleSubmit1 = (e) => {
    // debugger
    e.preventDefault()
    const formData = new FormData()
    setLoading(true)
    formData.append('userId', id)
    formData.append('email', userdetails.message?.Email)
    formData.append('firstName', userdetails.message?.FirstName)
    formData.append('lastName', userdetails.message?.Surname)
    formData.append('forceReset', userdetails.message?.forceReset)
    formData.append('amount', userdetails.message?.amountpaid)
    formData.append('courseId', userdetails.message?.courseCode)
    formData.append('roleid', userdetails.message?.roleid)
    formData.append('type', userdetails.message?.type)
    dispatch(sendMailAction(formData, setLoading))
  }

  const [previewHtml, setPreviewtml] = useState('')
  const [showModal, setShowModal] = useState(false)

  const userLocale = navigator.language || 'en-UK'
  let asiaTimezone = Intl.DateTimeFormat(userLocale).resolvedOptions().timeZone

  const handlePreviewEmail = () => {
    debugger
    if (userdetails.message?.Password == 0 || userdetails.message?.Password == null) {
      const formData = new FormData()
      formData.append('userId', id)
      formData.append('email', userdetails.message?.Email)
      formData.append('firstName', userdetails.message?.FirstName)
      formData.append('lastName', userdetails.message?.Surname)
      formData.append('forceReset', userdetails.message?.forceReset)
      formData.append('amount', userdetails.message?.amountpaid)
      formData.append('courseId', userdetails.message?.courseCode)
      formData.append('roleid', userdetails.message?.roleid)
      formData.append('type', userdetails.message?.type)
      formData.append('adminTimezone', asiaTimezone)
      dispatch(PreviewEmailAction(formData))
    }
    setShowModal(true)
  }

  useEffect(() => {
    if (emailPreviewHtml) {
      setPreviewtml(emailPreviewHtml?.message?.html)
    }
  }, [emailPreviewHtml])

  const coursesData = useSelector((state) => {
    return state.login.boughtcourseslist
  })

  useEffect(
    () => {
      //debugger
      dispatch(BoughtcourseAction(id))
    },
    dispatch,
    id
  )

  const bundlesData = useSelector((state) => {
    return state.login.getboughtbundlelist
  })

  useEffect(
    () => {
      //debugger
      dispatch(BoughtBundleAction(id))
    },
    dispatch,
    id
  )

  const [selectedOption, setSelectedOption] = useState('')

  const handleSelectChangee = (event) => {
    setSelectedOption(event.target.value)
  }

  const Course = coursesData.data
    ? coursesData.data.map((item) => (
        <option key={item.id} value={item.courseCode}>
          {item.title} (Course)
        </option>
      ))
    : null

  const Bundle = bundlesData.data
    ? bundlesData.data.map((item) => (
        <option key={item.id} value={item.bundle_code}>
          {item.Name} (Bundle)
        </option>
      ))
    : null

  useEffect(() => {
    dispatch(GetallCoursesBundlesAction())
  }, [dispatch])

  const course = useSelector((state) => {
    return state.login.getpreviewdetailslist
  })

  useEffect(() => {
    //debugger
    dispatch(PreviewDetailsAction(selectedOption))
  }, [dispatch, selectedOption])

  const courseCategory =
    course.dateRange && course.dateRange[0] ? course.dateRange[0].Course_Category : null
  const courseId = course?.data?.id ?? null

  const bundle = useSelector((state) => {
    return state.login.getBundleall_list
  })
  let bundleCode = null

  if (bundle.data1 && bundle.data1.length > 0) {
    bundleCode = bundle.data1[0].bundle_code
  }
  const microsoftBootcampId = bundle?.categorizedData?.Microsoft_Bootcamp[0]?.id ?? null
  const comptiaBootcampId = bundle?.categorizedData?.Comptia_Bootcamp[0]?.id ?? null

  useEffect(() => {
    //debugger
    dispatch(GetallbundleactionAction(selectedOption))
  }, [dispatch, selectedOption])

  const [selectedDate, setSelectedDateId] = useState(null)
  const [selectedMicrosoftDate, setselectedMicrosoftDate] = useState(null)
  const [selectedComptiaDate, setselectedComptiaDate] = useState(null)

  const handleDateSelection = (itemId) => {
    setSelectedDateId(itemId)
  }

  const handleMicrosoftDate = (itemId) => {
    setselectedMicrosoftDate(itemId)
  }

  const handleComptiaDate = (itemId) => {
    setselectedComptiaDate(itemId)
  }

  return (
    <div className='user-editor'>
      <div className='card mb-xl-10'>
        <form id='kt_account_profile_details_form' className='form'>
          {loading && (
            <div className='loader-container'>
              <Loader />
            </div>
          )}
          <div className='card-body border-top p-9'>
            <div className='row'>
              <label className='col-lg-4 col-form-label fw-semibold fs-6'>Avatar</label>
              <div className='col-lg-8'>
                {/* Display current profile picture */}
                {userdetails.message?.profileUrl && (
                  <img
                    src={userdetails.message.profileUrl}
                    alt='Current Profile'
                    style={{maxWidth: '100px'}}
                  />
                )}

                {/* Input for selecting a new profile picture */}
                <input
                  type='file'
                  {...register('profileUrl')}
                  onChange={(e) => {
                    const selectedFile = e.target.files[0]
                    setFile(URL.createObjectURL(selectedFile))
                  }}
                />
                {file && <img src={file} alt='Selected' style={{maxWidth: '100px'}} />}
                <div className='form-text'>Allowed file types: png, jpg, jpeg.</div>
                {errors.profileUrl && (
                  <p className='alert_danger' role='alert'>
                    {errors.profileUrl.message}
                  </p>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                <span className='required'>Email</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='email'
                  name='email'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Email'
                  {...register('Email', {
                    required: 'This is required field',
                    validate: validateEmailFormat,
                  })}
                  aria-invalid={errors.Email ? 'true' : 'false'}
                />
                {errors.Email && (
                  <p className='alert_danger' role='alert'>
                    {errors.Email.message}
                  </p>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-semibold fs-6'>Full Name</label>

              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      name='fname'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='First name'
                      {...register('FirstName', {
                        required: 'This is required field',
                      })}
                      aria-invalid={errors.FirstName ? 'true' : 'false'}
                    />
                    {errors.FirstName && (
                      <p className='alert_danger' role='alert' style={{marginTop: '1rem'}}>
                        {errors.FirstName?.message}
                      </p>
                    )}
                  </div>

                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      name='fname'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='First name'
                      {...register('Surname', {
                        required: 'This is required field',
                      })}
                      aria-invalid={errors.Surname ? 'true' : 'false'}
                    />
                    {errors.Surname && (
                      <p className='alert_danger' role='alert' style={{marginTop: '1rem'}}>
                        {errors.Surname?.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                <span className='required'>Telephone</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='tel'
                  name='phone'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Phone number'
                  {...register('PhoneNumber', {
                    required: 'This is required field',
                  })}
                  aria-invalid={errors.PhoneNumber ? 'true' : 'false'}
                  onKeyPress={(e) => {
                    const isNumeric = /^[0-9]$/
                    if (!isNumeric.test(e.key)) {
                      e.preventDefault()
                    }
                  }}
                />
                {errors.PhoneNumber && (
                  <p className='alert_danger' role='alert' style={{marginTop: '1rem'}}>
                    {errors.PhoneNumber?.message}
                  </p>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                <span className='required'>Timezone</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <TimezoneSelect value={selectedTimezone} onChange={setSelectedTimezone} />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                <span className='required'>Country</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  name='country'
                  aria-label='Select a Country'
                  data-control='select2'
                  data-placeholder='Select a country...'
                  className='form-select form-select-solid form-select-lg fw-semibold'
                  required
                  {...register('Country', {
                    required: 'Country is a required field',
                  })}
                  aria-invalid={errors.Country ? 'true' : 'false'}
                  value={selectedCountryISOCode}
                  onChange={(e) => handleChange(e, setValue)}
                >
                  {/* Move the console.log outside the JSX */}
                  <option>Select a Country...</option>
                  {Object.values(allCountries).map((countryArr) =>
                    countryArr.map((country, i) => (
                      <option key={i} value={country.isoCode}>
                        {country.name}
                      </option>
                    ))
                  )}
                </select>

                {errors.Country && (
                  <p className='alert_danger' role='alert'>
                    {errors.Country?.message}
                  </p>
                )}
              </div>
            </div>
            {state.count > 0 && (
              <>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                    <span className='required'>State/County</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <select
                      name='country'
                      aria-label='Select Country'
                      data-control='select2'
                      data-placeholder='Select a country...'
                      className='form-select form-select-solid form-select-lg fw-semibold'
                      aria-invalid={errors.Country ? 'true' : 'false'}
                      value={selectedStateISOCode}
                      onChange={handleStateChange}
                    >
                      <option value=''>Select State...</option>
                      {state.states.map((item) => (
                        <option key={item.isoCode} value={item.isoCode}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {city.count > 0 && (
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                      <span>City</span>
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <select
                        name='City'
                        aria-label='Select a City'
                        data-control='select2'
                        data-placeholder='choose a City...'
                        className='form-select form-select-solid form-select-lg fw-semibold'
                        required
                        {...register('City', {})}
                        aria-invalid={errors.City ? 'true' : 'false'}
                        value={selectedCity} // Make sure to set the value attribute
                        onChange={(e) => handleCityChange(e, setValue)}
                      >
                        <option>Select a City...</option>
                        {city.message &&
                          city.message.map((city, index) => (
                            <option key={index} value={city.name}>
                              {city.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                )}
              </>
            )}
            
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                <span className='required'>Education Level</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <select
                  name='EducationLevel'
                  aria-label='Select an EducationLevel'
                  data-control='select2'
                  data-placeholder='Select an EducationLevel...'
                  className='form-select form-select-solid form-select-lg fw-semibold'
                  {...register('EducationLevel', {
                    required: 'This is a required field',
                  })}
                  aria-invalid={errors.EducationLevel ? 'true' : 'false'}
                  value={educationLevel}
                  onChange={handleeducationLevelChange}
                >
                  <option value=''>Select Education Level</option>
                  {educationLeveloptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                {errors.EducationLevel && (
                  <p className='alert_danger' role='alert'>
                    {errors.EducationLevel?.message}
                  </p>
                )}
                {educationLevel === 'Other' ? (
                  <input
                    type='text'
                    placeholder='Please specify your education level'
                    className='form-control'
                    {...register('OtherEducationLevel', {
                      required: 'This is a required field',
                    })}
                    aria-invalid={errors.OtherEducationLevel ? 'true' : 'false'}
                  />
                ) : (
                  <></>
                )}
                {errors.OtherEducationLevel && (
                  <p className='alert_danger' role='alert'>
                    {errors.OtherEducationLevel?.message}
                  </p>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                <span className='required'>Years of Cyber Security Experience</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  id='inputState'
                  data-control='select2'
                  data-placeholder='Select a YearsOfCyberSecurityExperience...'
                  className='form-select form-select-solid form-select-lg fw-semibold'
                  {...register('YearsOfCyberSecurityExperience')}
                >
                  <option value='' disabled selected style={{color: '#495057'}}>
                    Select{' '}
                  </option>
                  <option value='None' style={{color: '#495057'}}>
                    None{' '}
                  </option>
                  <option value='1-2'>1-2</option>
                  <option value='3-5'>3-5</option>

                  <option value='5-7'>5-7</option>
                  <option value='7+'>7+</option>
                </select>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                <span className='required'>Purpose of taking the Course</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  id='inputState'
                  data-control='select2'
                  data-placeholder='Select a City...'
                  className='form-select form-select-solid form-select-lg fw-semibold'
                  {...register('PurposeOfTakingTheCourse', {
                    required: 'This is required field',
                  })}
                  aria-invalid={errors.dayRate ? 'true' : 'false'}
                  value={PurposeOfTakingTheCourse}
                  onChange={handlePurposeChange}
                >
                  <option value='' disabled selected style={{color: '#495057'}}>
                    Select
                  </option>
                  {purposeOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                {errors.PurposeOfTakingTheCourse && (
                  <p className='alert_danger' role='alert'>
                    {errors.PurposeOfTakingTheCourse?.message}
                  </p>
                )}
                {/* Display input field when 'Other' is selected */}
                {PurposeOfTakingTheCourse === 'Other' && (
                  <input
                    type='text'
                    placeholder='Please specify your purpose'
                    className='form-control'
                    {...register('OtherPurposeOfTakingTheCourse', {
                      required: 'This is a required field',
                    })}
                    aria-invalid={errors.OtherPurposeOfTakingTheCourse ? 'true' : 'false'}
                  />
                )}
                {/* Display error message for 'Other' input field */}
                {errors.OtherPurposeOfTakingTheCourse && (
                  <p className='alert_danger' role='alert'>
                    {errors.OtherPurposeOfTakingTheCourse?.message}
                  </p>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                <span className='required'>Current employment status</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  id='inputState'
                  data-control='select2'
                  data-placeholder='Select a EmploymentStatus...'
                  className='form-select form-select-solid form-select-lg fw-semibold'
                  {...register('EmploymentStatus', {
                    required: 'This is required field',
                  })}
                  aria-invalid={errors.dayRate ? 'true' : 'false'}
                  value={EmploymentStatus}
                  onChange={handleEmploymentChange}
                >
                  <option value='' disabled selected style={{color: '#495057'}}>
                    Select{' '}
                  </option>
                  {employmentOption.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                {errors.EmploymentStatus && (
                  <p className='alert_danger' role='alert'>
                    {errors.EmploymentStatus?.message}
                  </p>
                )}
                {/* Display input field when 'Other' is selected */}
                {EmploymentStatus === 'Other' && (
                  <input
                    type='text'
                    placeholder='Please specify your employment status'
                    className='form-control'
                    {...register('OtherEmploymentStatus', {
                      required: 'This is a required field',
                    })}
                    aria-invalid={errors.OtherEmploymentStatus ? 'true' : 'false'}
                  />
                )}
                {/* Display error message for 'Other' input field */}
                {errors.OtherEmploymentStatus && (
                  <p className='alert_danger' role='alert'>
                    {errors.OtherEmploymentStatus?.message}
                  </p>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                <span className='required'>How did you find out about Cyberheads?</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  id='inputState'
                  data-control='select2'
                  data-placeholder='Select a HowYouGotToKnow...'
                  className='form-select form-select-solid form-select-lg fw-semibold'
                  {...register('HowYouGotToKnow', {
                    required: 'This is required field',
                  })}
                  aria-invalid={errors.dayRate ? 'true' : 'false'}
                  value={HowGotToKnow}
                  onChange={handleHowGotToKnowChange}
                >
                  <option value='' disabled selected style={{color: '#495057'}}>
                    Select{' '}
                  </option>
                  {HowGotToKnowOption.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                {errors.HowYouGotToKnow && (
                  <p className='alert_danger' role='alert'>
                    {errors.HowYouGotToKnow?.message}
                  </p>
                )}
                {HowGotToKnow === 'Other' && (
                  <input
                    type='text'
                    placeholder='Please specify how you found out about Cyberheads'
                    className='form-control'
                    {...register('OtherHowYouGotToKnow', {
                      required: 'This is a required field',
                    })}
                    aria-invalid={errors.OtherHowYouGotToKnow ? 'true' : 'false'}
                  />
                )}
                {errors.OtherHowYouGotToKnow && (
                  <p className='alert_danger' role='alert'>
                    {errors.OtherHowYouGotToKnow?.message}
                  </p>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                <span className='required'>I am interested in Cyberheads finding me a new job</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  id='inputState'
                  data-control='select2'
                  data-placeholder='Select a InterestedInCyberheadRole...'
                  className='form-select form-select-solid form-select-lg fw-semibold'
                  {...register('InterestedInCyberheadRole')}
                >
                  <option value='' disabled selected style={{color: '#495057'}}>
                    Select{' '}
                  </option>
                  <option value='Yes' style={{color: '#495057'}}>
                    Yes{' '}
                  </option>
                  <option value='No'>No</option>
                </select>
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <Link to='/alluserlist'>
              <button type='reset' className='btn cybercancelbtn me-2'>
                Cancel
              </button>
            </Link>
            <button
              type='submit'
              id='kt_ecommerce_add_product_submit'
              className='btn cyberbtnallpage'
              style={{background: '#FF763F', color: 'white'}}
              onClick={handleSubmit(onSubmit)}
            >
              Save Changes
            </button>
          </div>

          <div style={{padding: '20px'}}>
            {userdetails.message?.Password == 0 || userdetails.message?.Password == null ? (
              <div>
                <button
                  type='submit'
                  id='kt_ecommerce_add_product_submit'
                  className='btn cyberbtnallpage m-2'
                  style={{background: '#FF763F', width: '10%'}}
                  onClick={handleSubmit1}
                >
                  Send Mail
                </button>
                <button
                  type='button'
                  id='kt_ecommerce_add_product_submit'
                  className='btn cyberbtnallpage'
                  style={{background: '#FF763F', width: '10%'}}
                  onClick={handlePreviewEmail}
                >
                  Preview Mail
                </button>
              </div>
            ) : null}
          </div>
        </form>
      </div>
      <Modal show={showModal} onHide={closePopup} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Email Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body dangerouslySetInnerHTML={{__html: previewHtml}} />
        <Modal.Footer>
          <Button variant='secondary' onClick={closePopup}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Edituser
