import React, {useState, useEffect} from 'react'

import './CustomCalendar.css'

import {Calendar, DateObject} from 'react-multi-date-picker'

import 'react-datepicker/dist/react-datepicker.css'

import {useDispatch, useSelector} from 'react-redux'

import {
  GetRangeByInstructorId,
  UpdateInstructoRangeByidAction,
  updateSingleDateAvaibility,
  GetInstructorAvaibilityReservedDetails,
  sendReviwAction,
} from '../../../Redux/Action/instructorAction'

import {useNavigate} from 'react-router-dom'
import moment from 'moment'
import {Row, Col} from 'react-bootstrap'

const SetAvailability = () => {
  const navigate = useNavigate
  const dispatch = useDispatch()
  const instid = localStorage.getItem('userid')
  const Range = useSelector((state) => state.login.getallRangelist)
  const ReservedRange = useSelector((state) => state.login.getaInstructorAvaibilityReservedlist)

  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const [dates, setSelectedDates] = useState([])
  const [reservedDates, setReservedDates] = useState([])

  const currentDate = new Date().toISOString().split('T')[0]
  const expiary = new Date(
    new Date(currentDate).getFullYear(),
    new Date(currentDate).getMonth() + 6,
    new Date(currentDate).getDate()
  )
    .toISOString()
    .slice(0, 10)

  const disabledDates = Array.from({length: 31}, (_, index) => {
    const date = new Date()
    date.setDate(date.getDate() - index - 1)
    return new DateObject(date)
  })

  useEffect(() => {
    dispatch(GetRangeByInstructorId(instid))
      .then(() => setIsDataLoaded(true))
      .catch((error) => {
        console.error('Error fetching range data:', error)
        setIsDataLoaded(true)
      })
  }, [dispatch, instid])

  useEffect(() => {
    dispatch(GetInstructorAvaibilityReservedDetails(instid))
      .then(() => setIsDataLoaded(true))
      .catch((error) => {
        console.error('Error fetching range data:', error)
        setIsDataLoaded(true)
      })
  }, [dispatch, instid])

  useEffect(() => {
    if (isDataLoaded && Range.dateArray && Array.isArray(Range.dateArray)) {
      const uniqueDateSet = new Set(Range.dateArray)

      const uniqueDateObjects = Array.from(uniqueDateSet, (dateString) => new Date(dateString))

      setSelectedDates(uniqueDateObjects)
    }
  }, [isDataLoaded, Range.dateArray])

  useEffect(() => {
    if (isDataLoaded && Range.bookedDates && Array.isArray(Range.bookedDates)) {
      const uniqueDateSet = new Set(Range.bookedDates)

      const uniqueDateObjects = Array.from(uniqueDateSet, (dateString) => new Date(dateString))

      setReservedDates(uniqueDateObjects)
    }
  }, [isDataLoaded, Range.bookedDates])

  if (!isDataLoaded) {
    return <div>Loading...</div>
  }

  const onSubmit = () => {
    const formData = {
      dates: [],
    }

    let hasValidDates = false

    const dateValues = Object.values(dates)

    dateValues.forEach((start) => {
      if (start && start.format && typeof start.format === 'function') {
        formData.dates.push(start.format('YYYY-MM-DDTHH:mm:ss.SSSZ'))
        hasValidDates = true
      }
    })

    if (hasValidDates) {
      dispatch(updateSingleDateAvaibility(formData, instid))
    }
  }

  const submitReview = () => {
    dispatch(sendReviwAction(instid))
  }
  // 181c32
  return (
    <div className='set-availability card p-10 '>
      <div
        style={{
          borderBottom: '2px solid #181c32',
          paddingBottom: '10px',
          paddingTop: '10px',
          fontWeight: 'bold',
          fontSize: '17px',
          textAlign: 'center',
        }}
      >
        SET AVAILABILITY
      </div>

      <Row>
        <Col>
          <div style={{padding: '16px'}}>
            <ul className='loreamul'>
              <li className='loream'>
                Please select all the dates you are available to teach a course
              </li>
              <li className='loream'>
                Once you save and submit your teaching availability, our Admin team will schedule
                you in to teach a virtual bootcamp.
              </li>
              <li className='loream'>
                You will receive an email notification and reminder for any upcoming courses you are
                scheduled to teach, as well as be able to view your full teaching schedule in the
                portal dashboard
              </li>
              <li className='loream'>
                If you have any further queries please contact the Cyberheads Team.
              </li>

              <li className='loream' style={{listStyle: 'none'}}>
                <div class='circle blue'></div>
                <span class='unbooked' style={{fontWeight: '700'}}>
                  Available For Training -
                </span>
                The dates instructors are available for training
              </li>
              <li className='loream' style={{listStyle: 'none'}}>
                {' '}
                <div class='circle grey'></div>
                <span class='greyyy' style={{fontWeight: '700'}}>
                  Unavailable For Training -
                </span>
                The grey dates
              </li>

              <li className='loream' style={{listStyle: 'none'}}>
                <div class='circle green'></div>
                <span class='booked' style={{fontWeight: '700'}}>
                  Booked For Training -
                </span>{' '}
                The dates allocated/agreed for instructors to offer training
              </li>
            </ul>
          </div>
        </Col>
      </Row>

      <Row>
        <Col style={{display: 'flex', justifyContent: 'flex-end'}}>
          <div>
            <div className='calander-buttons'>
              <div className='d-flex'>
                <button onClick={submitReview} className='btn cyberbtnallpage'>
                  Submit for Review
                </button>
                <button
                  onClick={onSubmit}
                  className='btn cyberbtnallpage'
                  style={{marginLeft: '10px'}}
                >
                  Update Availability
                </button>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <div>
        <div className='rmdp-header-values' style={{display: 'flex', justifyContent: 'center'}}>
          <div class='rmdp-day-picker rmdp-full-year'>
            <div
              className='rmdp-wrapper rmdp-shadow custom-calendar'
              style={{marginLeft: '0px', fontSize: '30px'}}
            >
              <>
                <div
                  className='rmdp-month-name rmdp-header'
                  style={{fontSize: '30px', padding: '0px', margin: '0px'}}
                >
                  <Calendar
                    style={{columnGap: '80px'}}
                    className='custom-calendar'
                    fullYear
                    multiple
                    value={dates}
                    onChange={setSelectedDates}
                    excludeDates={disabledDates}
                    minDate={new DateObject(currentDate)}
                    maxDate={new DateObject(expiary)}
                    mapDays={({date}) => {
                      const Date = date.format('YYYY/MM/DD')
                      const formattedDates = reservedDates.map((date) => {
                        const year = date.getFullYear()
                        const month = (date.getMonth() + 1).toString().padStart(2, '0')
                        const day = date.getDate().toString().padStart(2, '0')
                        return `${year}/${month}/${day}`
                      })

                      const isReserved = formattedDates.includes(Date)

                      if (isReserved) {
                        return {
                          disabled: true,
                          style: {
                            color: 'white',
                            backgroundColor: 'green',
                            cursor: 'pointer',
                            paddingTop: '3px',
                          },
                        }
                      }

                      return null
                    }}
                  />
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SetAvailability
