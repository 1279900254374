import React, {useState, useEffect} from 'react'
import 'primereact/resources/themes/lara-light-indigo/theme.css' // theme
import 'primereact/resources/primereact.css' // core css
import {useDispatch, useSelector} from 'react-redux'
import {GetallPaymentAction} from '../../../Redux/Action/paymentAction'
import {FilterMatchMode, FilterOperator} from 'primereact/api'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {InputText} from 'primereact/inputtext'
import {Link, useNavigate} from 'react-router-dom'
import {Instructor_getallmemberinvoiceAction} from '../../../Redux/Action/instructorAction'
import {FaFilePdf} from 'react-icons/fa'

export default function AllPayements() {
  const dispatch = useDispatch()

  const [filters, setFilters] = useState({
    global: {value: null, matchMode: FilterMatchMode.CONTAINS},
    title: {value: null, matchMode: FilterMatchMode.STARTS_WITH},

    status: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
  })

  const [loading, setLoading] = useState(true)
  const [globalFilterValue, setGlobalFilterValue] = useState('')

  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    let _filters = {...filters}

    _filters['global'].value = value

    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const renderHeader = () => {
    return (
      <div className='flex justify-content-end'>
        <span className='p-input-icon-left'>
          <i className='pi pi-search' />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder='Keyword Search'
          />
        </span>
      </div>
    )
  }

  const header = renderHeader()
  const invoiceid = localStorage.getItem('userid')
  const myInvoice = useSelector((state) => {
    return state.login.getAllInvoiceList
  })

  useEffect(() => {
    dispatch(Instructor_getallmemberinvoiceAction(invoiceid)).then(() => {
      setLoading(false)
    })
  }, [dispatch])

  const rows = myInvoice.map((invoice, index) => {
    // const formattedDate = (dateString) => {
    //   const date = new Date(dateString)
    //   return date.toLocaleDateString('en-US', {
    //     month: 'long',
    //     day: 'numeric',
    //     year: 'numeric',
    //   })
    // }
    function formatDateString(inputDateStr) {
      const inputDate = new Date(inputDateStr)
      const day = inputDate.getDate()
      const month = inputDate.getMonth() + 1
      const year = inputDate.getFullYear()
      const formattedDay = day < 10 ? '0' + day : day
      const formattedMonth = month < 10 ? '0' + month : month
      const formattedDate = formattedDay + '/' + formattedMonth + '/' + year
      return formattedDate
    }

    const openPdfInPopup = (pdfLink) => {
      if (pdfLink) {
        window.open(pdfLink, '_self') // '_self' opens the link in the same tab/window
      }
    }
    return {
      id: index,
      SNo: index + 1,
      title: invoice.title,
      status: invoice.status,
      created_at: formatDateString(invoice.created_at),
      updated_at: formatDateString(invoice.updated_at),
      raisedate: formatDateString(invoice.raisedate),
      pdfLink: (
        <div onClick={() => openPdfInPopup(invoice.pdfLink)} style={{cursor: 'pointer'}}>
          <FaFilePdf style={{fontSize: '30px', color: 'red'}} />
        </div>
      ),
    }
  })

  return (
    <div className='card'>
      <DataTable
        value={rows}
        paginator
        rows={10}
        dataKey='id'
        filters={filters}
        tableStyle={{minWidth: '100%'}}
        filterDisplay='row'
        globalFilterFields={['SNo', 'CourseName', 'status']}
        header={header}
        emptyMessage='No Invoice found.'
      >
        <Column sortable field='SNo' header='#' />

        <Column
          field='title'
          header='CourseName'
          filter
          filterPlaceholder='Search by Course Name'
        />
        <Column field='created_at' header='Start Date' style={{minWidth: '12rem'}} />
        <Column
          field='updated_at'
          header='End Date'
          filterPlaceholder='Search by end date'
          style={{minWidth: '12rem'}}
        />
        <Column field='raisedate' header='Raise Date' style={{minWidth: '12rem'}} />

        <Column
          field='status'
          header='Status'
          filter
          filterPlaceholder='Search by status'
          style={{minWidth: '12rem'}}
        />
        <Column field='pdfLink' header='View Invoice' style={{minWidth: '12rem'}} />
      </DataTable>
    </div>
  )
}
