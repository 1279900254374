import React, {useEffect, useState} from 'react'

import AddIcon from '@mui/icons-material/Add'
import {useDispatch, useSelector} from 'react-redux'

import {FaEdit, FaTrash, FaPlus} from 'react-icons/fa'
import {Tooltip} from 'antd'

import {GetallBundlaction, DeleteBundleAction} from '../../../Redux/Action/bundleAction'

import {useParams} from 'react-router-dom'
import './bundle.css'
import {Link, useNavigate} from 'react-router-dom'
import {AiOutlineLeft, AiOutlineRight} from 'react-icons/ai'
import {FilterMatchMode} from 'primereact/api'
import {MultiSelect} from 'primereact/multiselect'
import {Toolbar} from 'primereact/toolbar'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'

export default function GetallBundlelist() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [filters, setFilters] = useState({
    global: {value: null, matchMode: FilterMatchMode.CONTAINS},
    Name: {value: null, matchMode: FilterMatchMode.CONTAINS},
    status: {value: null, matchMode: FilterMatchMode.IN},
    duration: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
    price: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
  })

  const columns = [
    {field: 'Name', header: 'Bundle Name'},
    {field: 'status', header: 'Status'},
    {field: 'duration', header: 'Duration'},
    {field: 'price', header: 'Price'},
  ]

  const onColumnToggle = (event) => {
    let selectedColumns = event.value
    let orderedSelectedColumns = columns.filter((col) =>
      selectedColumns.some((sCol) => sCol.field === col.field)
    )

    setVisibleColumns(orderedSelectedColumns)
  }

  const [loading, setLoading] = useState(true)
  const [globalFilterValue, setGlobalFilterValue] = useState('')
  const [visibleColumns, setVisibleColumns] = useState(columns)

  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    let _filters = {...filters}

    _filters['global'].value = value

    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const bundles = useSelector((state) => {
    const publishedBundles = state.login.getbundlelist?.Published || [];
    const draftBundles = state.login.getbundlelist?.Draft || [];
  
    const combinedBundles = [
      ...publishedBundles.map((item, index) => ({
        ...item,
        SNo: index + 1,
        status: 'Published',
      })),
      ...draftBundles.map((item, index) => ({
        ...item,
        SNo: publishedBundles.length + index + 1,
        status: 'Draft',
      }))
    ];
  
    return combinedBundles;
  });
  

  useEffect(() => {
    dispatch(GetallBundlaction()).then(() => {
      setLoading(false)
    })
  }, [dispatch])

  const deleteHandler = (props) => {
    dispatch(DeleteBundleAction(props))
  }

  const rightToolbarTemplate = () => {
    return (
      <div class='card-toolbar'>
        <div class='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
          <Link to='/create_bundle' type='button' class='btn cyberbtnallpage'>
            <AddIcon /> Add Bundles
          </Link>
        </div>
      </div>
    )
  }

  const leftToolbarTemplate = () => {
    return (
      <MultiSelect
        value={visibleColumns}
        options={columns}
        optionLabel='header'
        onChange={onColumnToggle}
        className='w-full sm:w-20rem'
        display='chip'
      />
    )
  }

  const actionTemplate = (rowData) => {
    return (
      <div style={{display: 'flex', gap: '0px', marginLeft: '-3rem'}}>
        <div style={{fontSize: '15px'}}>
          {' '}
          <Tooltip title='Edit Bundle'>
            <Link to={`/editBundle/${rowData.bundle_slug}`}>
              <span
                style={{
                  fontSize: '18px',
                  paddingRight: '5px',
                  color: 'rgb(126, 130, 153)',
                }}
              >
                <FaEdit />
              </span>
            </Link>
          </Tooltip>
          <Tooltip title='Add Course'>
            <Link to={`/addbundle/${rowData.bundle_code}`}>
              <span
                style={{
                  fontSize: '18px',
                  paddingRight: '5px',
                  color: 'rgb(126, 130, 153)',
                }}
              >
                <FaPlus />
              </span>
            </Link>
          </Tooltip>
          <Tooltip title='Delete'>
            <Link to='#' onClick={() => deleteHandler(rowData.bundle_slug)}>
              <span
                style={{
                  fontSize: '18px',
                  paddingRight: '5px',
                  color: 'rgb(126, 130, 153)',
                }}
              >
                <FaTrash />
              </span>
            </Link>
          </Tooltip>
        </div>
        <div
          class='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
          data-kt-menu='true'
        >
          <div class='menu-item px-3'></div>
        </div>
      </div>
    )
  }

  const StatusFilter = (props) => {
    const options = ['Published', 'Draft']

    return (
      <MultiSelect
        value={props.value}
        options={options}
        onChange={(e) => {
          props.filterApplyCallback(e.value)
        }}
        optionLabel=''
        placeholder='Any'
        className='p-column-filter'
        maxSelectedLabels={1}
        style={{minWidth: '10rem'}}
      />
    )
  }
  return (
    <div>
      <Toolbar className='mb-4' left={leftToolbarTemplate} right={rightToolbarTemplate}/>
      <DataTable
        value={bundles}
        paginator
        rows={20}
        tableStyle={{minWidth: '100%'}}
        dataKey='id'
        filters={filters}
        filterDisplay='row'
        className='card'
        globalFilterFields={['Name', 'status', 'duration', 'price']}
        emptyMessage='No bundles found.'
        rowClassName='table-row'
      >
        <Column field='SNo' header='#' sortable />
        {visibleColumns.map((col) => (
          <Column
            sortable
            key={col.field}
            field={col.field}
            header={col.header}
            filter
            style={{minWidth: '15rem'}}
            filterPlaceholder={`Search by ${col.header}`}
            filterElement={(() => {
              switch (col.field) {
                case 'status':
                  return StatusFilter
                default:
                  return ''
              }
            })()}
          />
        ))}
        <Column
          field='id'
          header='Action'
          body={actionTemplate}
          exportable={false}
          style={{marginLeft: '-12rem'}}
        />
      </DataTable>
    </div>
  )
}

// const GetallBundlelist = () => {
//   const dispatch = useDispatch()
//   const [modalShow, setModalShow] = React.useState(false)
//   const navigate = useNavigate()

//   const id = useParams()

//   const allbundle = useSelector((state) => {
//     return state.login.getbundlelist
//   })

//   const bundle = (allbundle.Published || []).concat(allbundle.Draft || [])

//   useEffect(() => {
//     dispatch(GetallBundlaction())
//   }, [dispatch])

//   function nextPage() {
//     if (currentPage !== nPage) {
//       setCurrentPage(currentPage + 1)
//     }
//   }

//   function prePage() {
//     if (currentPage !== 1) {
//       setCurrentPage(currentPage - 1)
//     }
//   }

//   function changeCPage(id) {
//     setCurrentPage(id)
//   }

//   const [currentPage, setCurrentPage] = useState(1)

//   const recordsPerPage = 10

//   const lastIndex = currentPage * recordsPerPage

//   const firstIndex = lastIndex - recordsPerPage

//   const records = bundle.slice(firstIndex, lastIndex)

//   const nPage = Math.ceil(bundle.length / recordsPerPage)

//   const number = [...Array(nPage + 1).keys()].slice(1)

//   const handleDeleteButtonClick = (bundle_slug) => {
//     dispatch(DeleteBundleAction(bundle_slug))
//   }

//   return (
//     <div className='user-list'>
//       <div id='kt_app_content_container'>
//         <div class='card'>
//           <div class='card-header border-0 pt-6'>
//             <div class='card-title'>
//               <h3 className='fw-bold m-0'>Bundles List</h3>
//             </div>

//             <div class='card-toolbar'>
//               <div class='d-flex justify-content-end' data-kt-user-table-toolbar='base'>

//                 <Link to='/create_bundle' type='button' class='btn cyberbtnallpage'>
//                   <AddIcon /> Add Bundles
//                 </Link>
//               </div>

//             </div>
//           </div>

//           <div class='card-body py-4'>
//             <div className='table-responsive'>
//               <table class='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>
//                 <thead>
//                   <tr class='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
//                     <th class='min-w-125px'>#</th>
//                     <th class='min-w-125px'>Bundle Name</th>
//                     <th class='min-w-125px'>Status</th>
//                     <th class='min-w-125px'>Duration</th>
//                     <th class='min-w-125px'>Price</th>
//                     <th class='min-w-125px'>Actions</th>
//                   </tr>
//                 </thead>

//                 <tbody class='text-gray-600 fw-semibold'>
//                   {records.map((bundle, index) => (
//                     <tr key={index}>
//                       <td>{firstIndex + index + 1}</td>

//                       <td class='d-flex align-items-center'>
//                         <div class='d-flex flex-column'>
//                           <span style={{color: '#7e8299'}}>{bundle.Name}</span>
//                         </div>
//                       </td>

//                       <td>{bundle.status}</td>

//                       <td>{bundle.duration}</td>

//                     <td>£{bundle.price}</td>

//                       <td>
//                         <div style={{fontSize: '15px'}}>
//                           <Tooltip title='Edit Bundle'>
//                             <Link to={`/editBundle/${bundle.bundle_slug}`}>
//                               <span
//                                 style={{
//                                   fontSize: '18px',
//                                   paddingRight: '5px',
//                                   color: 'rgb(126, 130, 153)',
//                                 }}
//                               >
//                                 <FaEdit />
//                               </span>
//                             </Link>
//                           </Tooltip>

//                           <Tooltip title='Add Course'>
//                             <Link to={`/addbundle/${bundle.bundle_code}`}>
//                               <span
//                                 style={{
//                                   fontSize: '18px',
//                                   paddingRight: '5px',
//                                   color: 'rgb(126, 130, 153)',
//                                 }}
//                               >
//                                 <FaPlus />
//                               </span>
//                             </Link>
//                           </Tooltip>
//                           <Tooltip title='Delete'>
//                             <Link
//                               to='#'
//                               onClick={() => handleDeleteButtonClick(bundle.bundle_slug)}
//                             >
//                               <span
//                                 style={{
//                                   fontSize: '18px',
//                                   paddingRight: '5px',
//                                   color: 'rgb(126, 130, 153)',
//                                 }}
//                               >
//                                 <FaTrash />
//                               </span>
//                             </Link>
//                           </Tooltip>
//                         </div>
//                         <div
//                           class='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
//                           data-kt-menu='true'
//                         >
//                           <div class='menu-item px-3'></div>
//                         </div>
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </div>
//             <nav>
//               <ul className='pagination'>
//                 <li className='page-item'>
//                   <Link to='#' className='page-link' onClick={prePage}>
//                     <AiOutlineLeft />
//                   </Link>
//                 </li>
//                 {number.map((n, i) => (
//                   <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
//                     <Link to='#' className='page-link' onClick={() => changeCPage(n)}>
//                       {n}
//                     </Link>
//                   </li>
//                 ))}
//                 <li className='page-item'>
//                   <Link to='#' className='page-link' onClick={nextPage}>
//                     <AiOutlineRight />
//                   </Link>
//                 </li>
//               </ul>
//             </nav>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default GetallBundlelist
