import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  GetAllMeetingAction,
  deleteMeeting,
  DeleteBulkMettingAction,
} from '../../../Redux/Action/zoomAction'

import { CopyToClipboard } from 'react-copy-to-clipboard'
import { FaEdit, FaTrash } from 'react-icons/fa'

import { TbListDetails } from 'react-icons/tb'
import { Tooltip } from 'antd'

import { Link, useNavigate } from 'react-router-dom'
//past Meeting
const Archivepastmeeting = () => {
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const meetings = useSelector((state) => {
    return state.login.getmeetingList
  })

  const pastMeetings = meetings.pastMeetings

  const meeting = pastMeetings || []

  useEffect(() => {
    dispatch(GetAllMeetingAction())
  }, [dispatch])

  const meetingid = localStorage.getItem('userid')

  const handleDeleteButtonClick = (meetingId) => {
    // debugger;
    dispatch(deleteMeeting(meetingId))
  }

  const [copyStatuses, setCopyStatuses] = useState(new Array(meetings.length).fill(''))

  // Separate copy statuses for startUrl and joinUrl
  const [startUrlCopyStatuses, setStartUrlCopyStatuses] = useState(
    new Array(meeting.length).fill('')
  )
  const [joinUrlCopyStatuses, setJoinUrlCopyStatuses] = useState(new Array(meeting.length).fill(''))

  // Function to handle copying for a specific meeting and URL
  const handleCopy = (item, urlKey, setCopyStatuses) => {
    return () => {
      setCopyStatuses((prevStatuses) => {
        const newStatuses = [...prevStatuses]
        newStatuses[item] = 'Copied'
        setTimeout(() => {
          newStatuses[item] = ''
          setCopyStatuses(newStatuses)
        }, 2000) // Reset the message after 2 seconds
        return newStatuses
      })
    }
  }

  function nextPage() {
    if (currentPage !== nPage) {
      setCurrentPage(currentPage + 1)
    }
  }

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  function changeCPage(id) {
    setCurrentPage(id)
  }

  const [currentPage, setCurrentPage] = useState(1)

  const recordsPerPage = 10

  const lastIndex = currentPage * recordsPerPage

  const firstIndex = lastIndex - recordsPerPage

  const records = meeting.slice(firstIndex, lastIndex)

  const nPage = Math.ceil(meeting.length / recordsPerPage)

  const number = [...Array(nPage + 1).keys()].slice(1)

  function getDateFromDateTimestamp(timestamp) {
    const dateObject = new Date(timestamp)
    const year = dateObject.getFullYear()
    const month = String(dateObject.getMonth() + 1).padStart(2, '0')
    const day = String(dateObject.getDate()).padStart(2, '0')
    const hours = String(dateObject.getHours()).padStart(2, '0')
    const minutes = String(dateObject.getMinutes()).padStart(2, '0')

    return `${day}/${month}/${year} ${hours}:${minutes}`
  }
  const [selectedRows, setSelectedRows] = useState([])

  // ... (your existing code)

  const handleCheckboxChange = (meetingId) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(meetingId)) {
        return prevSelectedRows.filter((id) => id !== meetingId)
      } else {
        return [...prevSelectedRows, meetingId]
      }
    })
  }

  const handleBulkDelete = () => {
    const meetingIdsToDelete = selectedRows

    dispatch(DeleteBulkMettingAction(meetingIdsToDelete))
  }


  const handleSelectAllCheckboxChange = (event) => {
    const isChecked = event.target.checked;
  
    // If checked, set all meetingIds to selectedRows, else clear selectedRows
    const updatedSelectedRows = isChecked
      ? records.map((meeting) => meeting.meetingId)
      : [];
  
      console.log(updatedSelectedRows, "updatedSelectedRows")
    // Update the state with the new selectedRows
    setSelectedRows(updatedSelectedRows);
  };

 

  return (
    <div className='user-list'>
      <div id='kt_app_content_container'>
        <div class='card'>
          <div className='card-header border-0 pt-6'>
            <div className='card-title'>
              <h3 className='fw-bold m-0'>Archived Meetings List</h3>
            </div>
          </div>
          <div class='card-body py-4'>
            <div className='table-responsive'>
              <table class='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>
                <thead>
                  <tr class='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                    <td class='min-w-50px'><input
                      type='checkbox'
                      checked={selectedRows.length === records.length}
                      onChange={handleSelectAllCheckboxChange}
                    /></td>
                    <th class='min-w-50px'>#</th>

                    <th class='min-w-125px'>Meeting ID</th>
                    <th class='min-w-125px'>Title</th>
                    <th class='min-w-125px'>Time</th>

                    <th class='min-w-125px'>start url</th>
                    <th class='min-w-125px'>join url</th>
                    <th class='min-w-125px'>Actions</th>
                  </tr>
                </thead>

                <tbody class='text-gray-600 fw-semibold'>
                  {records.map((meeting, item) => (
                    <tr>
                      <td class='min-w-50px'>
                        <input
                          type='checkbox'
                          value={meeting.meetingId}
                          checked={selectedRows.includes(meeting.meetingId)}
                          onChange={() => handleCheckboxChange(meeting.meetingId)}
                        />
                      </td>
                      <td>{firstIndex + item + 1}</td>

                      <td>
                        <span style={{ color: '#7e8299' }}>{meeting.meetingId}</span>
                      </td>
                      <td>
                        <Link to={`/meetingsdetails_byid/${meeting.meetingId}`}>
                          {meeting.title}
                        </Link>
                      </td>

                      <td>{getDateFromDateTimestamp(meeting.start_date)}</td>


                      <td className='text-center'>
                        <div style={{ display: 'flex' }}>
                          {meeting.startUrl.slice(0, 20)}
                          <CopyToClipboard
                            text={meeting.startUrl}
                            onCopy={handleCopy(item, 'startUrl', setStartUrlCopyStatuses)}
                          >
                            <div className='copy'>
                              <a>
                                <i
                                  style={{ fontSize: '20px' }}
                                  className={`fa-regular fa-copy`}
                                  disabled={
                                    !window.ethereum?.selectedAddress ||
                                    !localStorage.getItem('authToken')
                                  }
                                ></i>
                              </a>
                            </div>
                          </CopyToClipboard>
                        </div>
                        {startUrlCopyStatuses[item] && (
                          <span style={{ color: 'green', marginLeft: '5px' }}>
                            {startUrlCopyStatuses[item]}
                          </span>
                        )}
                      </td>


                      <td className='text-center'>
                        <div style={{ display: 'flex' }}>
                          {meeting.joinUrl.slice(0, 20)}
                          <CopyToClipboard
                            text={meeting.joinUrl}
                            onCopy={handleCopy(item, 'joinUrl', setJoinUrlCopyStatuses)}
                          >
                            <div className='copy'>
                              <a>
                                <i
                                  style={{ fontSize: '20px' }}
                                  className={`fa-regular fa-copy`}
                                  disabled={
                                    !window.ethereum?.selectedAddress ||
                                    !localStorage.getItem('authToken')
                                  }
                                ></i>
                              </a>
                            </div>
                          </CopyToClipboard>
                        </div>
                        {joinUrlCopyStatuses[item] && (
                          <span style={{ color: 'green', marginLeft: '5px' }}>
                            {joinUrlCopyStatuses[item]}
                          </span>
                        )}
                      </td>

                      <td>
                        <div style={{ fontSize: '15px' }}>

                          <Tooltip title='View'>
                            <button
                              onClick={() => navigate(`/meetingsdetails_byid/${meeting.meetingId}`)}
                              style={{ border: 'none', background: 'white' }}
                            >
                              <span
                                style={{
                                  fontSize: '18px',
                                  paddingRight: '5px',
                                  color: 'rgb(126, 130, 153)',
                                }}
                              >
                                <TbListDetails />
                              </span>
                            </button>
                          </Tooltip>
                          <Tooltip title='Delete'>
                            <Link to='#' onClick={() => handleDeleteButtonClick(meeting.meetingId)}>
                              <span
                                style={{
                                  fontSize: '18px',
                                  paddingRight: '5px',
                                  color: 'rgb(126, 130, 153)',
                                }}
                              >
                                <FaTrash />
                              </span>
                            </Link>
                          </Tooltip>
                        </div>

                        <div
                          class='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
                          data-kt-menu='true'
                        >
                          <div class='menu-item px-3'></div>
                        </div>
                      </td>


                    </tr>
                  ))}
                </tbody>
                <div className='pt-10'>
                  <button
                    onClick={handleBulkDelete}
                    className='btn btn-secondary btn-sm'
                    style={{ borderRadius: '15px' }}
                  >
                    Delete Rows
                  </button>
                </div>
              </table>
            </div>
            <nav className='w-0'>
              <ul className='pagination'>
                <li className='page-item'>
                  <a href='#' className='page-link' onClick={prePage}>
                    Prev
                  </a>
                </li>
                {number.map((n, i) => (
                  <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                    <a href='#' className='page-link' onClick={() => changeCPage(n)}>
                      {n}
                    </a>
                  </li>
                ))}
                <li className='page-item'>
                  <a href='#' className='page-link' onClick={nextPage}>
                    Next
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Archivepastmeeting
