import React, {useState, useEffect} from 'react'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primereact/resources/primereact.css'
import {useDispatch, useSelector} from 'react-redux'
import {UserListAction, DeleteUserAction} from '../../../Redux/Action/usersAction'
import {FilterMatchMode} from 'primereact/api'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {InputText} from 'primereact/inputtext'
import {Link, useNavigate} from 'react-router-dom'
import * as CountryStateCity from 'country-state-city'
import {MultiSelect} from 'primereact/multiselect'
import {FaEdit, FaTrash} from 'react-icons/fa'
import {CgProfile} from 'react-icons/cg'

export default function BasicFilterDemo() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [filters, setFilters] = useState({
    global: {value: null, matchMode: FilterMatchMode.CONTAINS},
    FirstName: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
    Email: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
    PhoneNumber: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
    AgeGroup: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
    Role: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
    State: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
    City: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
    countryNames: {value: null, matchMode: FilterMatchMode.IN},
  })

  const [loading, setLoading] = useState(true)
  const [globalFilterValue, setGlobalFilterValue] = useState('')

  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    let _filters = {...filters}

    _filters['global'].value = value

    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const renderHeader = () => {
    return (
      <div className='flex justify-content-end'>
        <span className='p-input-icon-left'>
          <i className='pi pi-search' />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder='Keyword Search'
          />
        </span>
      </div>
    )
  }

  const header = renderHeader()

  const customers = useSelector((state) => {
    return state.login.userlist
    setLoading(false)
  })

  useEffect(() => {
    dispatch(UserListAction()).then(() => {
      setLoading(false)
    })
  }, [dispatch])

  const [countryNames, setCountryNames] = useState({})
  const [stateNames, setStateNames] = useState({})

  useEffect(() => {
    const fetchCountryAndStateNames = () => {
      const uniqueCountryCodes = [...new Set(customers.map((item) => item.Country))]
      const uniqueStateCodes = [...new Set(customers.map((item) => item.state))]

      const fetchedCountryNames = {}
      const fetchedStateNames = {}

      uniqueCountryCodes.forEach((code) => {
        const country = CountryStateCity.Country.getCountryByCode(code)
        fetchedCountryNames[code] = country ? country.name : code
      })

      uniqueStateCodes.forEach((code) => {
        const state = CountryStateCity.State.getStateByCode(code)
        fetchedStateNames[code] = state ? state.name : code
      })

      setCountryNames(fetchedCountryNames)
      setStateNames(fetchedStateNames)
    }

    fetchCountryAndStateNames()
  }, [customers])

  const deleteHandler = (props) => {
    dispatch(DeleteUserAction(props))
  }

  const representativeRowFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={Object.keys(countryNames).map((code) => ({name: countryNames[code]}))}
        onChange={(e) => options.filterApplyCallback(e.value)}
        optionLabel='name'
        placeholder='Any'
        className='p-column-filter'
        maxSelectedLabels={1}
        style={{minWidth: '14rem'}}
      />
    )
  }

  const actionTemplate = (rowData) => {
    return (
      <div>
        <button
          onClick={() => navigate(`/edit_users/${rowData.id}`)}
          style={{border: '0', padding: '5px', marginRight: '10px'}}
        >
          <FaEdit />
        </button>
        <button
          onClick={() => deleteHandler(rowData.id)}
          style={{border: '0', padding: '5px', marginRight: '10px'}}
        >
          <FaTrash />
        </button>
        {/* Rest of the code */}

        {rowData.roleid == '4' ? (
          <button
            onClick={() => navigate(`/viewDetails/${rowData.id}`)}
            style={{border: '0', padding: '5px'}}
          >
            <CgProfile />
          </button>
        ) : rowData.roleid == '2' ||
          rowData.roleid == '1' ||
          rowData.roleid == '3' ||
          rowData.roleid == '5' ||
          rowData.roleid == '6' ||
          rowData.roleid == '7' ? (
          <button
            onClick={() => navigate(`/UserDetails/${rowData.id}`)}
            style={{border: '0', padding: '5px'}}
          >
            <CgProfile />
          </button>
        ) : null}
      </div>
    )
  }
  return (
    <div className='card'>
      <DataTable
        value={customers}
        paginator
        rows={10}
        dataKey='id'
        filters={filters}
        filterDisplay='row'
        // loading={loading}
        globalFilterFields={[
          'FirstName',
          'Email',
          'PhoneNumber',
          'AgeGroup',
          'Role',
          'State',
          'City',
          'Country',
        ]}
        header={header}
        emptyMessage='No customers found.'
      >
        <Column
          header='#'
          body={(rowData, rowIndex) => (
            <div className='symbol symbol-circle symbol-50px overflow-hidden'>
              <Link to='#'>
                <div className='symbol-label'>
                  <img src={rowData.profileUrl} alt='Emma Smith' className='w-100' />
                </div>
              </Link>
            </div>
          )}
          style={{minWidth: '12rem'}}
        />

        <Column
          field='Email'
          header='Email'
          filter
          filterPlaceholder='Search by name'
          style={{minWidth: '12rem'}}
        />
        <Column
          field='PhoneNumber'
          header='Phone Number'
          filter
          filterPlaceholder='Search by name'
          style={{minWidth: '12rem'}}
        />
        <Column
          field='AgeGroup'
          header='Age Group'
          filter
          filterPlaceholder='Search by name'
          style={{minWidth: '12rem'}}
        />
        <Column
          field='Role'
          header='Role'
          filter
          filterPlaceholder='Search by name'
          style={{minWidth: '12rem'}}
        />
        <Column
          header='Country'
          filter
          filterField='countryNames'
          showFilterMenu={false}
          filterMenuStyle={{width: '14rem'}}
          style={{minWidth: '14rem'}}
          body={(item) => countryNames[item.Country] || item.Country}
          filterPlaceholder='Search by name'
          filterElement={representativeRowFilterTemplate}
        />

        <Column
          field='state'
          header='State'
          filter
          showFilterMenu={false}
          filterMenuStyle={{width: '14rem'}}
          filterPlaceholder='Search by name'
          body={(item) => stateNames[item.state] || item.state}
          style={{minWidth: '12rem'}}
          // filterElement={representativeRowFilterTemplatee}
        />
        <Column
          field='City'
          header='City'
          filter
          filterPlaceholder='Search by name'
          style={{minWidth: '12rem'}}
        />
        <Column field='id' header='Action' body={actionTemplate} style={{minWidth: '12rem'}} />
      </DataTable>
    </div>
  )
}
