import axios from 'axios'

import swal from 'sweetalert'

import {getuserbyIdslice, getvoucheruserbyIdslice, getedit_usersidIdslice} from '../Slice/authSlice'

export const GetUserById = (studentid) => async (dispatch) => {
  try {
    //  debugger;
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getuserid = `${process.env.REACT_APP_BACKEND_API}/getUser/${studentid}`

    const res = await axios.get(getuserid, config, '')

    await dispatch(getuserbyIdslice(res))
  } catch (e) {
    console.log(e)
  }
}

export const Alluseredit = (id) => async (dispatch) => {
  try {
    //  debugger;
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const edit_usersid = `${process.env.REACT_APP_BACKEND_API}/getUser/${id}`

    const res = await axios.get(edit_usersid, config, '')

    await dispatch(getedit_usersidIdslice(res))
  } catch (e) {
    console.log(e)
  }
}

export const UpdateuserbyidAction = (formData, studentid, navigate) => async (dispatch) => {
  debugger
  try {
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const updateuserid = `${process.env.REACT_APP_BACKEND_API}/updateUser/${studentid}`
    const res = await axios.put(updateuserid, formData, config)

    if (res.status === 200) {
      // Display success message using swal
      swal({
        title: 'Success',
        text: res.data.message,
        icon: 'success',
      }).then(() => {
        // Reload the page after user acknowledges the message
        window.location.reload()
      })
    }
  } catch (e) {
    console.log(e)
  }
}

export const UpdateuserPasswordAction = (formData, studentid) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const updateuserpassword = `${process.env.REACT_APP_BACKEND_API}/users/${studentid}/password`
    try {
      const res = await axios.put(updateuserpassword, formData, config)
      if (res.status === 200) {
        swal('Success', res.data.message, 'success')
      } else if (res.status === 400) {
      } else if (res.status === 401) {
      } else if (res.status === 500) {
      } else {
      }
      window.location.reload()
    } catch (e) {
      swal('Error', e.response.data.error, 'error')
    }
  } catch (error) {}
}

export const DeleteProfilebyidAction = (userid) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const deleteuser = `${process.env.REACT_APP_BACKEND_API}/deleteProfileby/${userid}`
    const res = await axios.delete(deleteuser, config)

    dispatch(res())
    if (res.status === 200) {
      window.location.reload()
      swal('Success', res.data.message, 'success')
    }
  } catch (e) {}
}
