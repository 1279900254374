import React, {useEffect, useState} from 'react'

import {useDispatch, useSelector} from 'react-redux'
import {getTopPurchasedCourses} from '../../../../Redux/Action/admindashboardAction'
import {Link} from 'react-router-dom'

const Topsellingcourses = () => {
  const dispatch = useDispatch()

  const [selectRange, setSelectRange] = useState('ThisMonth')

  const topPurchasedCourses = useSelector((state) => {
    return state.login.getTopPurchasedCourseslist
  })

  const handleSelectChange = (event) => {
    //debugger
    const value = event.target.value
    setSelectRange(value)
    dispatch(getTopPurchasedCourses(value))
  }

  useEffect(() => {
    const defaultValue = 'ThisMonth'
    dispatch(getTopPurchasedCourses(defaultValue))
  }, [dispatch])

  ///////////////////////////////////////////////////////////////////////////////

  // const topCourses = useSelector((state) => {
  //   return state.login.getTopCoursesList
  // })

  // useEffect(() => {
  //   dispatch(TopCoursesAction())
  // }, [dispatch])

  // const topThisMonthCourses = useSelector((state) => {
  //   return state.login.getThisMonthTopCoursesList
  // })

  // useEffect(() => {
  //   dispatch(thisMonthTopCourses())
  // }, [dispatch])

  // const topLastMonthCourses = useSelector((state) => {
  //   return state.login.getLastMonthTopCoursesList
  // })

  // useEffect(() => {
  //   dispatch(LastMonthTopCourses())
  // }, [dispatch])

  // const topLastSixMonthCourses = useSelector((state) => {
  //   return state.login.getLastSixMonthTopCoursesList
  // })

  // useEffect(() => {
  //   dispatch(LastSixMonthTopCourses())
  // }, [dispatch])
  return (
    <div>
      <div className='card card-flush h-xl-100'>
        <div className='card-header pt-7'>
          <h3 className='card-title align-items-start flex-column' style={{paddingBottom: '1rem'}}>
            <span className='card-label fw-bold text-gray-800'>Top Courses Purchased</span>
          </h3>

          <div
            className='card-toolbar'
            style={{border: '1px solid #ccc', borderRadius: '4px', padding: '10px'}}
          >
            <select
              value={selectRange}
              onChange={handleSelectChange}
              style={{
                border: 'none',
                outline: 'none',
                fontSize: '12px',
                backgroundColor: 'white',
                borderRadius: '4px',

                width: '100%',
              }}
            >
              <option>Select Date Range</option>
              <option value='Last24hrs'>Last 24 Hrs</option>
              <option value='Last7days'>Last 7 Days</option>
              <option value='ThisMonth'>This Month</option>
              <option value='LastMonth'>Last Month</option>
              <option value='LastSixMonth'>Last 6 Month</option>
              <option value='LastYear'>Last Year</option>
            </select>
          </div>
        </div>

        <div className='card-body pt-3 pb-4'>
          <div className='table-responsive'>
            <table className='table table-row-dashed align-middle gs-0 gy-4 my-0'>
              <thead>
                <tr className='fs-7 fw-bold text-gray-500 border-bottom-0'>
                  <th className='p-0 '>#</th>
                  <th className='p-0 w-200px w-xxl-450px'>Course Name</th>
                  <th className='p-0 min-w-150px'>No of Purchases</th>
                </tr>
              </thead>
              <>
                {topPurchasedCourses.salesData && topPurchasedCourses.salesData.length > 0 ? (
                  <tbody>
                    {topPurchasedCourses.salesData.slice(0, 5).map((topPurchasedCourses, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              {topPurchasedCourses.title}
                            </div>
                          </div>
                        </td>
                        <td className='text-start'>
                          <span className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                            {topPurchasedCourses.purchase_count}
                          </span>
                        </td>
                      </tr>
                    ))}
                    {topPurchasedCourses.salesData.length < 5 && (
                      <>
                        {Array(5 - topPurchasedCourses.salesData.length)
                          .fill('')
                          .map((_, index) => (
                            <tr key={index + topPurchasedCourses.salesData.length}>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                            </tr>
                          ))}
                      </>
                    )}

                    <tr>
                      <td colSpan='4' className='text-end'>
                        <Link
                          to='/AllPurchases'
                          className='btn btn-sm fw-bold cyberbtnallpage'
                          style={{background: 'rgb(255, 118, 63)', color: 'white'}}
                        >
                          View All
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={3} className='text-gray-600 text-center'>
                        No Data Available
                      </td>
                    </tr>
                  </tbody>
                )}
              </>

              {/* {selectRange === 'ThisMonth' && (
                <>
                  <tbody>
                    {topThisMonthCourses.slice(0, 5).map((todayCourse, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              {todayCourse.title}
                            </div>
                          </div>
                        </td>
                        <td className='text-start'>
                          <span className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                            {todayCourse.purchase_count}
                          </span>
                        </td>
                      </tr>
                    ))}
                    {topThisMonthCourses.length < 5 && (
                      <>
                        {Array(5 - topThisMonthCourses.length)
                          .fill('')
                          .map((_, index) => (
                            <tr key={index + topThisMonthCourses.length}>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                            </tr>
                          ))}
                      </>
                    )}

                    <tr>
                      <td colSpan='4' className='text-end'>
                        <Link
                          to='/thisMonthTopCourses'
                          className='btn btn-sm fw-bold '
                          style={{background: 'rgb(255, 118, 63)', color: 'white'}}
                        >
                          View All
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </>
              )}
              {selectRange === 'LastMonth' && (
                <>
                  <tbody>
                    {topLastMonthCourses.slice(0, 5).map((topLastMonthCourses, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              {topLastMonthCourses.title}
                            </div>
                          </div>
                        </td>
                        <td className='text-start'>
                          <span className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                            {topLastMonthCourses.purchase_count}
                          </span>
                        </td>
                      </tr>
                    ))}
                    {topLastMonthCourses.length < 5 && (
                      <>
                        {Array(5 - topLastMonthCourses.length)
                          .fill('')
                          .map((_, index) => (
                            <tr key={index + topLastMonthCourses.length}>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                            </tr>
                          ))}
                      </>
                    )}

                    <tr>
                      <td colSpan='4' className='text-end'>
                        <Link
                          to='/LastMonthTopCourses'
                          className='btn btn-sm fw-bold '
                          style={{background: 'rgb(255, 118, 63)', color: 'white'}}
                        >
                          View All
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </>
              )}
              {selectRange === 'LastSixMonth' && (
                <>
                  <tbody>
                    {topLastSixMonthCourses.slice(0, 5).map((topLastSixMonthCourses, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              {topLastSixMonthCourses.title}
                            </div>
                          </div>
                        </td>
                        <td className='text-start'>
                          <span className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                            {topLastSixMonthCourses.purchase_count}
                          </span>
                        </td>
                      </tr>
                    ))}
                    {topLastSixMonthCourses.length < 5 && (
                      <>
                        {Array(5 - topLastSixMonthCourses.length)
                          .fill('')
                          .map((_, index) => (
                            <tr key={index + topLastSixMonthCourses.length}>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>

                              <td>&nbsp;</td>
                            </tr>
                          ))}
                      </>
                    )}

                    <tr>
                      <td colSpan='4' className='text-end'>
                        <Link
                          to='/LastSixMonthRecent'
                          className='btn btn-sm fw-bold '
                          style={{background: 'rgb(255, 118, 63)', color: 'white'}}
                        >
                          View All
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </>
              )} */}
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Topsellingcourses
