import React, {useEffect, useState} from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import {useSelector, useDispatch} from 'react-redux'

import {Button, Modal, Tooltip, List} from 'antd'
// import {
//   AllExamVoucher,
//   DeleteVoucherAction,
//   AddExamVoucher,
// } from "../../../Redux/Action/authAction";

import {
  AllExamVoucher,
  DeleteVoucherAction,
  AddExamVoucher,
} from '../../../Redux/Action/voucherAction'

import {Link} from 'react-router-dom'
import {useForm} from 'react-hook-form'

const Voucher = () => {
  const dispatch = useDispatch()

  const allVoucher = useSelector((state) => {
    //debugger
    return state.login.getAllVoucherList
  })

  useEffect(() => {
    dispatch(AllExamVoucher())
  }, [dispatch])

  const deleteHandler = (props) => {
    //debugger
    dispatch(DeleteVoucherAction(props))
  }
  const [isModalOpen, setIsModalOpen] = useState(false)
  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const onSubmit = (data) => {
    //debugger
    const formData = new FormData()
    formData.append('code', data.code)

    dispatch(AddExamVoucher(formData))
  }

  function nextPage() {
    if (currentPage !== nPage) {
      setCurrentPage(currentPage + 1)
    }
  }

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  function changeCPage(id) {
    setCurrentPage(id)
  }

  const [currentPage, setCurrentPage] = useState(1)

  const recordsPerPage = 20

  const lastIndex = currentPage * recordsPerPage

  const firstIndex = lastIndex - recordsPerPage

  const records = allVoucher.slice(firstIndex, lastIndex)

  const nPage = Math.ceil(allVoucher.length / recordsPerPage)

  const number = [...Array(nPage + 1).keys()].slice(1)

  const {register, handleSubmit} = useForm({})
  return (
    <div>
      <div className='card mb-6 mb-xl-9'>
        <div className='card-header'>
          <div className='card-title'>
            <h2>Vouchers</h2>
          </div>
          <div className='exam_button' style={{paddingTop: '16px', width: '159px', height: '86px'}}>
            <button className='btn cyberbtnallpage' onClick={showModal}>
              + Add Voucher
            </button>
          </div>
        </div>
        <div className='card-body pt-9 '>
          <Modal centered open={isModalOpen} onCancel={handleCancel} footer={null}>
            <div>
              <form style={{marginTop: '40px'}}>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-semibold fs-6'>
                    Voucher Code
                  </label>

                  <div className='col-lg-8'>
                    <div>
                      <input
                        type='text'
                        name='fname'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Exam Code'
                        {...register('code', {
                          required: 'This is required field',
                        })}
                      />
                    </div>
                  </div>
                </div>{' '}
                <button className='btn cyberbtnallpage' onClick={handleSubmit(onSubmit)}>
                  Add
                </button>
              </form>
            </div>
          </Modal>
          <table
            className='table align-middle table-row-dashed fs-6 gy-5'
            id='kt_ecommerce_sales_table'
          >
            <thead>
              <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                <th>Exam Code</th>
                <th>Status</th>
                {/* <th>Expiary Date</th> */}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody className='fw-semibold text-gray-600'>
            {records.map((item, index) => (
              
                <tr key={index}>
                  <td>
                    <span className='fw-bold'>{item.code}</span>
                  </td>
                  {item.userid === null ? (
                    <td>
                      <div className='badge badge-light-success'>Not Used yet</div>
                    </td>
                  ) : (
                    <td>
                      <div className='badge badge-light-danger'>Used</div>
                    </td>
                  )}

                  {/* <td>
                          <span className="fw-bold">{item.expiary_date}</span>
                        </td> */}

                  <td>
                    {item.userid === null ? (
                      <button className='btn btn-primary' onClick={() => deleteHandler(item.id)}>
                        Delete
                      </button>
                    ) : (
                      <button
                        disabled
                        onClick={() => deleteHandler(item.id)}
                        className='btn  btn-secondary'
                      >
                        Delete
                      </button>
                    )}
                  </td>
                </tr>
              
            ))}
            </tbody>
          </table>
          <nav>
            <ul className='pagination'>
              <li className='page-item'>
                <a href='#' className='page-link' onClick={prePage}>
                  Prev
                </a>
              </li>
              {number.map((n, i) => (
                <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                  <a href='#' className='page-link' onClick={() => changeCPage(n)}>
                    {n}
                  </a>
                </li>
              ))}
              <li className='page-item'>
                <a href='#' className='page-link' onClick={nextPage}>
                  Next
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  )
}

export default Voucher
