import React, {useState, useEffect} from 'react'
import TimezoneSelect from 'react-timezone-select'
import {useDispatch, useSelector} from 'react-redux'
import {GetMeeting_byidAction} from '../../../Redux/Action/zoomAction'
import {useForm} from 'react-hook-form'
import axios from 'axios'
import swal from 'sweetalert'
import {Form, Row, Col} from 'react-bootstrap'
import DatePicker from 'react-datepicker'
const EditMeeting = () => {
  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  )
  const dispatch = useDispatch()
  const newUrl = window.location.href
  const splitUrl = newUrl.split('/')

  const meetingid = splitUrl[4]

  const {
    formState: {errors},
  } = useForm()
  const [showForm, setShowForm] = useState(false)
  const [checkbox, setcheckbox] = useState(false)
  const [hourOptions] = useState(Array.from({length: 10}, (_, i) => i + 1))
  const minuteOptions = [0, 15, 30, 45]
  const handleCheckboxChange = (event) => {
    setShowForm((prevShowForm) => !prevShowForm)
    setcheckbox(event.target.checked)
  }

  const [userid, setuserid] = useState('')
  const [topic, setTopic] = useState('')

  const [type, setType] = useState(2)

  const [hostVideo, setHostVideo] = useState(false)
  const [participantVideo, setParticipantVideo] = useState(false)
  const [joinBeforeHost, setJoinBeforeHost] = useState(false)
  const [muteUponEntry, setMuteUponEntry] = useState(false)
  const [watermark, setWatermark] = useState(false)

  const [timezone, settimezone] = useState(true)
  const [audio, setAudio] = useState('voip')
  const [autoRecording, setAutoRecording] = useState('cloud')

  // const [repeat_interval, setrepeat_interval] = useState('')
  const [end_times, setend_times] = useState('')
  const [Recurrence, setRecurrence] = useState('1')
  const [waiting_room, setwaiting_room] = useState('true')
  const [end_date_time, setend_date_time] = useState('')

  const handleInputChange = (event) => {
    debugger
    setwaiting_room(event.target.value)
  }

  useEffect(() => {
    const storedUserId = localStorage.getItem('userId')
    if (storedUserId) {
      setuserid(storedUserId)
    }
  }, [])

  function combineDateTime(start_Date, Start_time) {
    // Convert start_Date object to string
    const startDateString = start_Date.toISOString();
    
    // Extract date part from start_Date string
    const dateParts = startDateString.split('T')[0].split('-');
    
    // Convert Start_time object to string
    const startTimeString = Start_time.toLocaleTimeString('en-US', {hour12: false});
    
    // Extract hour and minute from Start_time
    const timeParts = startTimeString.split(':');
    const hour = parseInt(timeParts[0]);
    const minute = parseInt(timeParts[1]);

    // Combine date and time parts
    const combinedDateTime = `${dateParts[0]}-${dateParts[1]}-${dateParts[2]}T${hour < 10 ? '0' + hour : hour}:${minute < 10 ? '0' + minute : minute}:00Z`;

    return combinedDateTime;
}

  const handleSubmit = async (e) => {
    debugger
    e.preventDefault()
    const userid = localStorage.getItem('userid')

    const formData = {
      // meetingtype,
      userid,
      topic,
      type,
      start_Date: combineDateTime(startDate, startTime),
      Start_time: startTime.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}),
      End_Time: endTime.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}),
      duration: durationInMins,
      timezone: selectedTimezone?.value || timezone,
      settings: {
        host_video: hostVideo,
        participant_video: participantVideo,
        join_before_host: joinBeforeHost,
        mute_upon_entry: muteUponEntry,
        watermark,
        audio,
        auto_recording: autoRecording,
        waiting_room: waiting_room,
      },

      recurrence: {
        endDateOptions: selectedDateOption,
        monthDateOption: monthDateOption,
        checkbox: checkbox,
        Recurrence: Recurrence,
        repeat_interval: repeat_interval,

        ...(selectedDateOption == '1' ? {end_times: occurrences} : {}),
        ...(selectedDateOption == '2' ? {end_times: setoccurrences} : {}),
        ...(Recurrence == '3'
          ? {
              monthly_week: weekNumber,
              monthly_week_day: monthDay,
              monthly_day: monthDate,
            }
          : {}),
        ...(Recurrence == '2' ? {weekly_days: setWeekDay} : {}),
      },
    }

    try {
      const baseURL = process.env.REACT_APP_BACKEND_API
      const url = `${baseURL}/update-meeting/${meetingid}`
      const token = localStorage.getItem('authToken')
      let response
      if (occurrences > 20) {
        swal({
          title: 'Change Dates',
          text: 'Occurrences cannot be more than 20. Please change end dates!',
          icon: 'warning',
          closeOnClickOutside: false,
          buttons: {
            cancel: {
              text: 'OK',
              value: null,
              visible: true,
              className: 'swal-button--confirm cyberbtnallpage',
            },
          },
        })
      } else {
        response = await axios.put(url, formData, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
      }

      if (response.status === 200) {
        const start_url = response.data.start_url
        swal('Success', response.data.message, 'success')
        window.location.href = '/current_upcommimg_classes'
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const MeetingDetails = useSelector((state) => state.login.getMeetingbyIdList)
  console.log(MeetingDetails, 'hhhhhhhhhhhhhh')

  let start_time
  let end_time
  let Duration
  if (MeetingDetails && MeetingDetails.length > 0) {
    const meeting = MeetingDetails[0]
    start_time = meeting.startTime
    end_time = meeting.endTime
    const durationInMinutes = meeting.duration
    const hours = Math.floor(durationInMinutes / 60)
    const minutes = durationInMinutes % 60
    Duration = `${hours} Hrs ${minutes} Mins`
  }

  function timeStringToDate(timeString) {
    const [hours, minutes, period] = timeString.split(/:|\s/)
    let today = new Date()
    today.setHours(parseInt(hours, 10) || 0)
    today.setMinutes(parseInt(minutes, 10) || 0)

    if (period && period.toUpperCase() === 'PM' && today.getHours() < 12) {
      today.setHours(today.getHours() + 12)
    }

    return today
  }

  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [startTime, setStartTime] = useState('')
  const [endTime, setEndTime] = useState('')
  const [duration, setDuration] = useState(Duration)
  const [durationInMins, setDurationInMins] = useState('')

  useEffect(() => {
    debugger
    const Start_Time = start_time ? timeStringToDate(start_time) : null
    const End_Time = end_time ? timeStringToDate(end_time) : null

    if (MeetingDetails && MeetingDetails.length > 0) {
      const meeting = MeetingDetails[0]

      setTopic(meeting.topic || '')
      setStartTime(new Date(Start_Time || ''))
      setEndTime(new Date(End_Time || ''))

      if (meeting.occurrences) {
        setStartDate(new Date(meeting?.occurrences[0]?.start_time || ''))
      }
      if (!meeting.occurrences) {
        setStartDate(new Date(meeting?.start_time || ''))
      }

      settimezone(meeting.timezone || '')
      setSelectedTimezone(meeting.timezone || '')
      if (meeting.settings) {
        setAutoRecording(meeting.settings.auto_recording || '')
        setHostVideo(meeting.settings.host_video || '')
        setAudio(meeting.settings.audio || '')
        setParticipantVideo(meeting.settings.participant_video || '')
        setJoinBeforeHost(meeting.settings.join_before_host || '')
        setMuteUponEntry(meeting.settings.mute_upon_entry || '')
        setWatermark(meeting.settings.watermark || '')
      }

      // if (meeting?.recurrence?.end_date_time) {
      //   setSelectedDateOption('1')
      // } else if (meeting?.recurrence?.end_times) {
      //   setSelectedDateOption('2')
      // }

      if (meeting?.recurrence && meeting?.endType === '1') {
        setSelectedDateOption('1')
        setOccurrences(meeting?.occurrences.length || '')
      } else if (meeting?.recurrence && meeting?.endType === '2') {
        setSelectedDateOption('2')
      }

      if (meeting?.recurrence?.end_times && meeting?.endType === '2') {
        setOccurrences(meeting.recurrence.end_times || '')
      }

      if (meeting?.recurrence) {
        setcheckbox(true)
      }

      if (meeting?.recurrence?.monthly_week) {
        setmonthDateOption('2')

        let week_num
        const weekNum = meeting?.recurrence?.monthly_week

        switch (weekNum) {
          case 1:
            week_num = 'First'
            break
          case 2:
            week_num = 'Second'
            break
          case 3:
            week_num = 'Third'
            break
          case 4:
            week_num = 'Fourth'
            break
          case -1:
            week_num = 'Last'
            break
          default:
            week_num = ''
        }

        setWeekNumber(week_num || '')

        let week_day
        const weekDay = meeting?.recurrence?.monthly_week_day
        switch (weekDay) {
          case 1:
            week_day = 'Sunday'
            break
          case 2:
            week_day = 'Monday'
            break
          case 3:
            week_day = 'Tuesday'
            break
          case 4:
            week_day = 'Wednesday'
            break
          case 5:
            week_day = 'Thursday'
          case 6:
            week_day = 'Friday'
          case 7:
            week_day = 'Saturday'
            break
          default:
            week_day = ''
        }

        setmonthDayOption(week_day || '')
      }

      const endDateTime = meeting?.recurrence?.end_date_time
      let endDate2
      if (meeting?.occurrences) {
        endDate2 = endDateTime
          ? new Date(meeting?.occurrences[meeting.occurrences.length - 1]?.start_time)
          : new Date(meeting?.occurrences[meeting.occurrences.length - 1]?.start_time)
      }

      if (meeting?.recurrence) {
        setrepeat_interval(meeting?.recurrence?.repeat_interval || '')
        setRecurrence(meeting?.recurrence?.type || '')
        setEndDate(endDate2)
      }

      if (!meeting?.recurrence) {
        const newDate = new Date(meeting?.start_time || '')
        newDate.setDate(newDate.getDate() + 6)
        setEndDate(newDate)
        setOccurrences('7')
      }

      if (meeting?.recurrence?.type === 2) {
        let weekValues = []

        if (typeof meeting.recurrence.weekly_days === 'string') {
          const daysArray = meeting.recurrence.weekly_days.split(',')

          weekValues = daysArray.map((day) => {
            switch (day.trim()) {
              case '1':
                return 'Sunday'
              case '2':
                return 'Monday'
              case '3':
                return 'Tuesday'
              case '4':
                return 'Wednesday'
              case '5':
                return 'Thursday'
              case '6':
                return 'Friday'
              case '7':
                return 'Saturday'
              default:
                return 'Invalid day'
            }
          })
        }

        setSelectWeekDay(weekValues)
      }

      if (meeting?.recurrence?.monthly_day) {
        setMonthDate(meeting.recurrence.monthly_day || '')
      }

      if (meeting?.occurrences && meeting.occurrences.length > 0) {
        const lastOccurrenceStartTime =
          meeting.occurrences[meeting.occurrences.length - 1].start_time
        setend_date_time(lastOccurrenceStartTime ? lastOccurrenceStartTime.slice(0, 16) : '')
      }

      // Set showForm based on the meeting type
      setShowForm(meeting.type === 8)
    }
  }, [MeetingDetails])

  useEffect(() => {
    // debugger;
    dispatch(GetMeeting_byidAction(meetingid))
  }, [dispatch])

  /////////////////////////////////////

  //const [startTime, setStartTime] = useState('')

  const [repeat_interval, setrepeat_interval] = useState('1')
  const [setoccurrences, setOccurrences] = useState('')
  const [selectedDateOption, setSelectedDateOption] = useState('1')
  const [setWeekDay, setSelectWeekDay] = useState(['Monday'])
  const [monthDateOption, setmonthDateOption] = useState('1')
  const [weekNumber, setWeekNumber] = useState('First')
  const [monthDay, setmonthDayOption] = useState('Monday')
  // const [monthDate, setMonthDate] = useState(dayOfMonth)
  const [monthDate, setMonthDate] = useState('')

  const StartTime = new Date()
  const EndTime = new Date()

  const formatTime = (date) => {
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const seconds = String(date.getSeconds()).padStart(2, '0')
    return `${hours}:${minutes}:${seconds}`
  }

  const startTimeString = formatTime(StartTime)
  const endTimeString = formatTime(EndTime)

  const currentDate = new Date()
  const starttTime = new Date(`${currentDate.toDateString()} ${startTimeString}`)
  const endTTime = new Date(`${currentDate.toDateString()} ${endTimeString}`)

  // Calculate the duration in milliseconds
  const durationInMilliseconds = endTTime - starttTime

  // Check if the times are valid
  let formattedDuration
  if (!isNaN(durationInMilliseconds) && durationInMilliseconds >= 0) {
    // Calculate hours and minutes
    const hours = Math.floor(durationInMilliseconds / (1000 * 60 * 60))
    const minutes = Math.floor((durationInMilliseconds % (1000 * 60 * 60)) / (1000 * 60))
    formattedDuration = `${hours} Hrs ${minutes} Mins`
  } else {
    console.log('Invalid time format or duration')
  }

  const newDailyDate = new Date(startDate)
  newDailyDate.setDate(newDailyDate.getDate() + 6)

  const newweekDate = new Date(startDate)
  newweekDate.setDate(newweekDate.getDate() + 42)

  const newmonthDate = new Date(startDate)
  newmonthDate.setMonth(newmonthDate.getMonth() + 6)

  const handleRecurrenceChange = (e) => {
    setRecurrence(e.target.value)
    let initialEndDate

    if (e.target.value === '1') {
      initialEndDate = newDailyDate
    } else if (e.target.value === '2') {
      initialEndDate = newweekDate
    } else if (e.target.value === '3') {
      initialEndDate = newmonthDate
    }
    setEndDate(initialEndDate)
  }

  const handleOptionChange = (event) => {
    setSelectedDateOption(event.target.value)
  }

  const handleWeekCheckboxChange = (day) => {
    if (setWeekDay.includes(day)) {
      setSelectWeekDay(setWeekDay.filter((selectedDay) => selectedDay !== day))
    } else {
      setSelectWeekDay([...setWeekDay, day])
    }
  }

  const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

  const handleMonthDate = (event) => {
    setmonthDateOption(event.target.value)
  }

  useEffect(() => {
    if (startTime && endTime) {
      const durationInMilliseconds = endTime - startTime
      const hours = Math.floor(durationInMilliseconds / (1000 * 60 * 60))
      const minutes = Math.floor((durationInMilliseconds % (1000 * 60 * 60)) / (1000 * 60))
      const durationinMinutes = Math.floor(durationInMilliseconds / (1000 * 60))
      const formattedDuration = `${hours} Hrs ${minutes} Mins`
      setDuration(formattedDuration)
      setDurationInMins(durationinMinutes)
    }
  }, [startTime, endTime])

  useEffect(() => {
    if (startDate) {
      const dayOfMonth = startDate.getDate()
      setMonthDate(dayOfMonth || '')
    }
  }, [startDate])

  function countRepeatedWeekdays(startDate, endDate, weeklyDays) {
    const oneDay = 24 * 60 * 60 * 1000
    let count = 0
    const start = new Date(startDate).getTime()
    const end = new Date(endDate).getTime()
    for (let timestamp = start; timestamp <= end; timestamp += oneDay) {
      const currentDate = new Date(timestamp)
      const dayOfWeek = currentDate.getDay()
      const dayName = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ][dayOfWeek]
      if (weeklyDays.includes(dayName)) {
        count++
      }
    }
    return count
  }

  function countWeekdayOccurrences(startDate, endDate, weekNumber, weekday) {
    let count = 0
    let currentDate = new Date(startDate)
    while (currentDate <= new Date(endDate)) {
      if (
        currentDate.getDay() ===
        ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].indexOf(
          weekday
        )
      ) {
        switch (weekNumber.toLowerCase()) {
          case 'first':
            if (currentDate.getDate() <= 7) {
              count++
            }
            break
          case 'second':
            if (currentDate.getDate() > 7 && currentDate.getDate() <= 14) {
              count++
            }
            break
          case 'third':
            if (currentDate.getDate() > 14 && currentDate.getDate() <= 21) {
              count++
            }
            break
          case 'fourth':
            if (currentDate.getDate() > 21 && currentDate.getDate() <= 28) {
              count++
            }
            break
          case 'last':
            const nextMonth = new Date(currentDate)
            nextMonth.setMonth(nextMonth.getMonth() + 1)
            nextMonth.setDate(0)
            const lastWeekday = nextMonth.getDay()
            if (
              lastWeekday ===
              [
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
              ].indexOf(weekday)
            ) {
              count++
            }
            break
        }
      }
      currentDate.setDate(currentDate.getDate() + 1)
    }
    return count
  }

  function countRepeatedDates(startDate, endDate, dayOfMonth) {
    let count = 0
    let currentDate = new Date(startDate)
    while (currentDate <= new Date(endDate)) {
      if (currentDate.getDate() == dayOfMonth) {
        count++
      }
      currentDate.setDate(currentDate.getDate() + 1)
    }
    return count
  }

  //   const calculateOccurrences = (startDate, endDate, recurrence, repeatInterval) => {
  //     //console.log(startDate, endDate, recurrence, repeatInterval,"kkkkkkkkkkkkkkkkkkk")
  //     const startDateTime = new Date(startDate).getTime()
  //     const endDateTime = new Date(endDate).getTime()

  //     const timeDifference = endDateTime - startDateTime

  //     let occurrences = 0

  //     switch (recurrence) {
  //       case 1:
  //         occurrences = Math.floor(timeDifference / (24 * 60 * 60 * 1000 * repeatInterval)) + 1
  //         break
  //       case 2:
  //         occurrences = Math.floor(
  //           countRepeatedWeekdays(startDate, endDate, setWeekDay) / parseInt(repeatInterval)
  //         )
  //         break
  //       case 3:
  //         if (monthDateOption == '1') {
  //           occurrences = Math.floor(
  //             countRepeatedDates(startDate, endDate, monthDate) / parseInt(repeatInterval)
  //           )
  //         } else if (monthDateOption == '2') {
  //           occurrences = Math.floor(
  //             countWeekdayOccurrences(startDate, endDate, weekNumber, monthDay) /
  //               parseInt(repeatInterval)
  //           )
  //         }
  //         break
  //       default:
  //         break
  //     }

  //     return occurrences
  //   }

  //  const occurrences = calculateOccurrences(startDate, endDate, Recurrence, repeat_interval)

  const [occurrences, setOccurrencess] = useState(0)
  useEffect(() => {
    const recurrenceValue = parseInt(Recurrence)
    const repeatIntervalValue = parseInt(repeat_interval)
    const calculateOccurrences = () => {
      const startDateTime = new Date(startDate).getTime()
      const endDateTime = new Date(endDate).getTime()
      const timeDifference = endDateTime - startDateTime

      let occurrences = 0

      switch (recurrenceValue) {
        case 1:
          occurrences = Math.floor(timeDifference / (24 * 60 * 60 * 1000 * repeatIntervalValue)) + 1
          break
        case 2:
          occurrences = Math.floor(
            countRepeatedWeekdays(startDate, endDate, setWeekDay) / parseInt(repeatIntervalValue)
          )
          break
        case 3:
          if (monthDateOption == '1') {
            occurrences = Math.floor(
              countRepeatedDates(startDate, endDate, monthDate) / parseInt(repeatIntervalValue)
            )
          } else if (monthDateOption == '2') {
            occurrences = Math.floor(
              countWeekdayOccurrences(startDate, endDate, weekNumber, monthDay) /
                parseInt(repeatIntervalValue)
            )
          }
          break
        default:
          break
      }

      return occurrences
    }

    const calculatedOccurrences = calculateOccurrences()
    setOccurrencess(calculatedOccurrences)
  }, [
    startDate,
    endDate,
    Recurrence,
    repeat_interval,
    monthDateOption,
    setWeekDay,
    monthDate,
    monthDay,
  ])

  //  useEffect(() => {
  //   const occurrences = calculateOccurrences(startDate, endDate, Recurrence, repeat_interval)
  //   setOccurrencess(occurrences);
  //  }, [startDate, endDate, Recurrence, repeat_interval])

  return (
    <div>
      <form
        id='kt_ecommerce_add_product_form'
        class='form d-flex flex-column flex-lg-row'
        onSubmit={handleSubmit}
      >
        <div class='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
          <div class='tab-content'>
            <div
              class='tab-pane fade show active'
              id='kt_ecommerce_add_product_general'
              role='tab-panel'
            >
              <div class='d-flex flex-column gap-7 gap-lg-10'>
                <div class='card card-flush py-4'>
                  <div class='card-body pt-0'>
                    <div className='d-flex align-items-center fs-2 fw-bold mb-5 cyb-admin-title'>
                      {' '}
                      Edit meeting
                    </div>

                    <Row>
                      <Col>
                        <Form.Group controlId='meetingTitle'></Form.Group>
                        <Form.Label>Topic</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter meeting topic'
                          defaultValue={MeetingDetails?.[0]?.topic || ''}
                          onChange={(e) => setTopic(e.target.value)}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group controlId='meetingStartTime'></Form.Group>
                        <Form.Label>Start Date</Form.Label>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                          <DatePicker
                            className='form-control mb-2'
                            showIcon
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            icon='fa fa-calendar'
                            dateFormat='dd/MM/yyyy'
                            minDate={new Date()}
                          />
                        </div>
                      </Col>

                      <Col>
                        <Form.Group controlId='meetingStartTime'></Form.Group>
                        <Form.Label>Start Time</Form.Label>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                          <DatePicker
                            className='form-control mb-2'
                            selected={startTime}
                            onChange={(date) => setStartTime(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeCaption='Time'
                            dateFormat='HH:mm'
                            timeFormat='HH:mm'
                          />
                        </div>
                      </Col>
                      <Col>
                        <Form.Group controlId='meetingStartTime'></Form.Group>
                        <Form.Label>End Time</Form.Label>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                          <DatePicker
                            className='form-control mb-2'
                            selected={endTime}
                            onChange={(date) => setEndTime(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={30}
                            timeCaption='Time'
                            dateFormat='HH:mm'
                            timeFormat='HH:mm'
                          />
                        </div>
                      </Col>
                      <Col>
                        <Form.Group controlId='meetingHours'>
                          <Form.Label>Meeting Duration</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Duration'
                            value={duration}
                            //defaultValue={duration}
                            readOnly
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group controlId='meetingMinutes'>
                          <Form.Label>Timezone</Form.Label>
                          <TimezoneSelect
                            className=''
                            value={selectedTimezone}
                            onChange={setSelectedTimezone}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <br />

                    <div class='mb-10 fv-row'>
                      <div className='form-check'>
                        <input
                          type='checkbox'
                          id='showFormCheckbox'
                          className='form-check-input'
                          checked={showForm}
                          // value='8'

                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor='showFormCheckbox' className='form-check-label'>
                          Recurring meeting
                        </label>
                      </div>
                      {showForm && (
                        <div>
                          <div className='fv-row mb-10'>
                            {console.log(endDate, 'RecurrenceRecurrence')}
                            {parseInt(Recurrence) === 1 && (
                              <div className='fv-row mb-5 p-5'>
                                {selectedDateOption === '1' && (
                                  <>
                                    &nbsp;&nbsp;&nbsp;&nbsp;Every day, until{' '}
                                    {endDate ? endDate.toDateString() : 'some future date'},{' '}
                                    {occurrences || setoccurrences} occurrence(s)
                                  </>
                                )}
                                {selectedDateOption === '2' && (
                                  <>
                                    &nbsp;&nbsp;&nbsp;&nbsp;Every day, {setoccurrences}{' '}
                                    occurrence(s)
                                  </>
                                )}
                              </div>
                            )}

                            {parseInt(Recurrence) === 2 && (
                              <div>
                                {selectedDateOption === '1' && (
                                  <>
                                    &nbsp;&nbsp;&nbsp;&nbsp;Every week on {setWeekDay.join(', ')},
                                    until {endDate.toDateString()}, {occurrences || setoccurrences}{' '}
                                    occurrence(s)
                                  </>
                                )}
                                {selectedDateOption === '2' && (
                                  <>
                                    &nbsp;&nbsp;&nbsp;&nbsp;Every week on {setWeekDay.join(', ')},{' '}
                                    {setoccurrences} occurrence(s)
                                  </>
                                )}
                              </div>
                            )}

                            {parseInt(Recurrence) === 3 && (
                              <div>
                                {selectedDateOption === '1' && (
                                  <div>
                                    {monthDateOption == '1' && (
                                      <>
                                        &nbsp;&nbsp;&nbsp;&nbsp;Every month on the {monthDate} of
                                        the month, until {endDate.toDateString()},{' '}
                                        {occurrences || setoccurrences} occurrence(s)
                                      </>
                                    )}

                                    {monthDateOption == '2' && (
                                      <>
                                        &nbsp;&nbsp;&nbsp;&nbsp;Every month on the {weekNumber}{' '}
                                        {monthDay}, until {endDate.toDateString()},{' '}
                                        {occurrences || setoccurrences} occurrence(s)
                                      </>
                                    )}
                                  </div>
                                )}
                                {selectedDateOption === '2' && (
                                  <div>
                                    {monthDateOption == '1' && (
                                      <>
                                        &nbsp;&nbsp;&nbsp;&nbsp;Every month on the {monthDate} of
                                        the month, {setoccurrences} occurrence(s)
                                      </>
                                    )}

                                    {monthDateOption == '2' && (
                                      <>
                                        &nbsp;&nbsp;&nbsp;&nbsp;Every month on the {weekNumber}{' '}
                                        {monthDay}, {setoccurrences} occurrence(s)
                                      </>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                          <div style={{gap: '20px'}}>
                            <div className='row mb-10'>
                              <label className='col-md-1 fw-semibold fs-6' htmlFor='dropdown'>
                                Recurrence:
                              </label>
                              <div className='col-md-4'>
                                <select
                                  class='form-control mb-2'
                                  name='Recurrence'
                                  data-placeholder='Select a Auto Recurrence:...'
                                  value={Recurrence}
                                  onChange={(e) => {
                                    handleRecurrenceChange(e)
                                    // calculateOccurrences(
                                    //   startDate,
                                    //   endDate,
                                    //   Recurrence,
                                    //   repeat_interval
                                    // )
                                  }}
                                >
                                  <option value='1'>Daily</option>
                                  <option value='2'>Weekly</option>
                                  <option value='3'>Monthly</option>
                                </select>
                              </div>
                            </div>
                            {Recurrence == '1' ? (
                              <div className='row'>
                                <label className='col-md-1 fw-semibold fs-6' htmlFor='dropdown'>
                                  Repeat Every:
                                </label>
                                <div className='col-md-5 d-flex align-items-center'>
                                  <select
                                    className='form-control mb-2'
                                    name='Recurrence'
                                    data-placeholder='Select an Auto Recurrence...'
                                    style={{width: '50%'}}
                                    value={repeat_interval}
                                    onChange={(e) => setrepeat_interval(e.target.value)}
                                  >
                                    <option value=''></option>
                                    <option value='1'>1</option>
                                    <option value='2'>2</option>
                                    <option value='3'>3</option>
                                    <option value='4'>4</option>
                                    <option value='5'>5</option>
                                    <option value='6'>6</option>
                                    <option value='7'>7</option>
                                    <option value='8'>8</option>
                                    <option value='9'>9</option>
                                    <option value='10'>10</option>
                                  </select>
                                  <span style={{lineHeight: '38px'}}>&nbsp;&nbsp;&nbsp;day(s)</span>
                                </div>
                              </div>
                            ) : Recurrence == '2' ? (
                              <div className='row'>
                                <label className='col-md-1 fw-semibold fs-6' htmlFor='dropdown'>
                                  Repeat Every:
                                </label>
                                <div className='col-md-5 d-flex align-items-center'>
                                  <select
                                    className='form-control mb-2'
                                    name='Recurrence'
                                    data-placeholder='Select an Auto Recurrence...'
                                    value={repeat_interval}
                                    onChange={(e) => setrepeat_interval(e.target.value)}
                                  >
                                    <option value=''></option>
                                    <option value='1'>1</option>
                                    <option value='2'>2</option>
                                    <option value='3'>3</option>
                                    <option value='4'>4</option>
                                    <option value='5'>5</option>
                                    <option value='6'>6</option>
                                    <option value='7'>7</option>
                                    <option value='8'>8</option>
                                    <option value='9'>9</option>
                                    <option value='10'>10</option>
                                    <option value='10'>11</option>
                                    <option value='10'>12</option>
                                  </select>
                                  <span style={{lineHeight: '38px'}}>week(s)</span>
                                </div>
                                <div
                                  className='col-md-12'
                                  style={{display: 'flex', gap: '20px', paddingTop: '2rem'}}
                                >
                                  <label className='fw-semibold fs-6' htmlFor='dropdown'>
                                    Occurs On:
                                  </label>
                                  <div>
                                    <Form>
                                      {weekdays.map((day) => (
                                        <Form.Check
                                          key={day}
                                          inline
                                          label={day}
                                          type='checkbox'
                                          id={`inline-checkbox-${day}`}
                                          onChange={() => handleWeekCheckboxChange(day)}
                                          checked={setWeekDay.includes(day)}
                                        />
                                      ))}
                                    </Form>
                                  </div>
                                </div>
                              </div>
                            ) : Recurrence == '3' ? (
                              <div className='row'>
                                <label className='col-md-1 fw-semibold fs-6' htmlFor='dropdown'>
                                  Repeat Every:
                                </label>
                                <div className='col-md-5'>
                                  <div className='d-flex '>
                                    <select
                                      className='form-control mb-2'
                                      name='Recurrence'
                                      data-placeholder='Select an Auto Recurrence...'
                                      style={{width: '100%'}}
                                      value={repeat_interval}
                                      onChange={(e) => setrepeat_interval(e.target.value)}
                                    >
                                      <option value=''></option>
                                      <option value='1'>1</option>
                                      <option value='2'>2</option>
                                      <option value='3'>3</option>
                                    </select>
                                    <span>month(s)</span>
                                  </div>
                                </div>

                                <div className='col-md-12 d-flex justify-content-start align-items-center'>
                                  <label className='fw-semibold fs-6' htmlFor='dropdown'>
                                    Occurs On:
                                  </label>
                                  <div style={{display: 'flex', gap: '1rem'}}>
                                    <input
                                      type='radio'
                                      name='monthDateOption'
                                      value='1'
                                      id='1'
                                      checked={monthDateOption === '1'}
                                      onChange={handleMonthDate}
                                      style={{fontSize: '20px', marginTop: '10px'}}
                                    />

                                    <div className='col-md-12'>
                                      <div
                                        //className='form-icon'
                                        style={{display: 'flex', width: '100%'}}
                                      >
                                        <div>By</div>
                                        <select
                                          className='form-control mb-2'
                                          name='Recurrence'
                                          data-placeholder='Select an Auto Recurrence...'
                                          style={{width: '200px'}}
                                          value={monthDate}
                                          onChange={(e) => setMonthDate(e.target.value)}
                                        >
                                          <option value=''></option>
                                          <option value='1'>1</option>
                                          <option value='2'>2</option>
                                          <option value='3'>3</option>
                                          <option value='4'>4</option>
                                          <option value='5'>5</option>
                                          <option value='6'>6</option>
                                          <option value='7'>7</option>
                                          <option value='8'>8</option>
                                          <option value='9'>9</option>
                                          <option value='10'>10</option>
                                          <option value='11'>11</option>
                                          <option value='12'>12</option>
                                          <option value='13'>13</option>
                                          <option value='14'>14</option>
                                          <option value='15'>15</option>
                                          <option value='16'>16</option>
                                          <option value='17'>17</option>
                                          <option value='18'>18</option>
                                          <option value='19'>19</option>
                                          <option value='20'>20</option>
                                          <option value='21'>21</option>
                                          <option value='22'>22</option>
                                          <option value='23'>23</option>
                                          <option value='24'>24</option>
                                          <option value='25'>25</option>
                                          <option value='26'>26</option>
                                          <option value='27'>27</option>
                                          <option value='28'>28</option>
                                          <option value='29'>29</option>
                                          <option value='30'>30</option>
                                          <option value='31'>31</option>
                                        </select>
                                        <div>of the month</div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='col-md-12 d-flex justify-content-start align-items-center'>
                                    <div style={{display: 'flex', gap: '1rem'}}>
                                      <input
                                        type='radio'
                                        name='monthDateOption'
                                        value='2'
                                        id='2'
                                        checked={monthDateOption == '2'}
                                        onChange={handleMonthDate}
                                        style={{fontSize: '20px', marginTop: '10px'}}
                                      />
                                      <div
                                        //className='form-control mb-2'
                                        style={{display: 'flex', gap: '20px'}}
                                      >
                                        <select
                                          className='form-control mb-2'
                                          name='weekNumber'
                                          data-placeholder='Select an Auto Recurrence...'
                                          style={{width: '200px'}}
                                          value={weekNumber}
                                          onChange={(e) => setWeekNumber(e.target.value)}
                                        >
                                          <option value=''></option>
                                          <option value='First'>First</option>
                                          <option value='Second'>Second</option>
                                          <option value='Third'>Third</option>
                                          <option value='Fourth'>Fourth</option>
                                          <option value='Last'>Last</option>
                                        </select>

                                        <div
                                          //className='form-control mb-2'
                                          style={{display: 'flex', gap: '20px'}}
                                        >
                                          <select
                                            className='form-control mb-2'
                                            name='monthDay'
                                            data-placeholder='Select an Auto Recurrence...'
                                            style={{width: '100%'}}
                                            value={monthDay}
                                            onChange={(e) => setmonthDayOption(e.target.value)}
                                          >
                                            <option value=''></option>
                                            <option value='Sunday'>Sunday</option>
                                            <option value='Monday'>Monday</option>
                                            <option value='Tuesday'>Tuesday</option>
                                            <option value='Wednesday'>Wednesday</option>
                                            <option value='Thursday'>Thursday</option>
                                            <option value='Friday'>Friday</option>
                                            <option value='Saturday'>Saturday</option>
                                          </select>
                                        </div>
                                        <div>of the month</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>

                          <div className='row d-flex align-items-center mt-5'>
                            <label className='col-md-1 fw-semibold fs-6'>End Date:</label>
                            <div className='col-md-10'>
                              <di
                                v
                                className='d-flex align-items-center justify-content-satrt gap-5'
                              >
                                <input
                                  type='radio'
                                  name='paymentOption'
                                  value='1'
                                  id='1'
                                  checked={selectedDateOption === '1'}
                                  onChange={handleOptionChange}
                                />
                                <span>By</span>
                                <div className='form-icon'>
                                  <DatePicker
                                    className='form-control mb-2'
                                    showIcon
                                    minDate={new Date()}
                                    selected={endDate}
                                    onChange={(date) => {
                                      setEndDate(date)
                                      // calculateOccurrences(
                                      //   startDate,
                                      //   endDate,
                                      //   Recurrence,
                                      //   repeat_interval
                                      // )
                                    }}
                                    icon='fa fa-calendar'
                                    dateFormat='dd/MM/yyyy'
                                  />
                                </div>
                                <label>
                                  <input
                                    type='radio'
                                    name='paymentOption'
                                    value='2'
                                    id='2'
                                    checked={selectedDateOption === '2'}
                                    onChange={handleOptionChange}
                                  />{' '}
                                  After
                                </label>
                                <div>
                                  <select
                                    className='form-control mb-2'
                                    name='Occurrences'
                                    data-placeholder='Select an Auto Recurrence...'
                                    style={{width: '100%'}}
                                    value={setoccurrences}
                                    onChange={(e) => setOccurrences(e.target.value)}
                                  >
                                    <option value=''></option>
                                    <option value='1'>1</option>
                                    <option value='2'>2</option>
                                    <option value='3'>3</option>
                                    <option value='4'>4</option>
                                    <option value='5'>5</option>
                                    <option value='6'>6</option>
                                    <option value='7'>7</option>
                                    <option value='8'>8</option>
                                    <option value='9'>9</option>
                                    <option value='10'>10</option>
                                    <option value='11'>11</option>
                                    <option value='12'>12</option>
                                    <option value='13'>13</option>
                                    <option value='14'>14</option>
                                    <option value='15'>15</option>
                                    <option value='16'>16</option>
                                    <option value='17'>17</option>
                                    <option value='18'>18</option>
                                    <option value='19'>19</option>
                                    <option value='20'>20</option>
                                  </select>
                                </div>
                                <span>Occurrences</span>
                              </di>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div class='row mb-6' style={{flexDirection: 'column'}}>
                      <label class='col-lg-4 col-form-label  fw-semibold fs-6'>Settings:-</label>
                      <p>Select all the video hosting options</p>
                      <div class='d-flex align-items-center mt-3'>
                        <div class='form-check form-check-custom form-check-inline form-check-solid me-3'>
                          <input
                            class='form-check-input'
                            name='Host video[]'
                            type='checkbox'
                            checked={hostVideo}
                            onClick={() => setHostVideo(true)}
                            onChange={(e) => setHostVideo(e.target.checked)}
                          />
                          <label class='form-check-label fw-semibold ps-2 fs-6 text-gray-800'>
                            Host video
                          </label>
                        </div>

                        <div class='form-check form-check-custom form-check-inline form-check-solid me-3'>
                          <input
                            class='form-check-input'
                            name='  Participant video[]'
                            type='checkbox'
                            checked={participantVideo}
                            onClick={() => setParticipantVideo(true)}
                            onChange={(e) => setParticipantVideo(e.target.checked)}
                          />
                          <label class='form-check-label fw-semibold ps-2 fs-6 text-gray-800'>
                            Participant video
                          </label>
                        </div>
                        <div class='form-check form-check-custom form-check-inline form-check-solid me-3'>
                          <input
                            class='form-check-input'
                            name=' Join-before-host[]'
                            type='checkbox'
                            checked={joinBeforeHost}
                            onClick={() => setJoinBeforeHost(true)}
                            onChange={(e) => setJoinBeforeHost(e.target.checked)}
                          />
                          <label class='form-check-label fw-semibold ps-2 fs-6 text-gray-800'>
                            Join before host
                          </label>
                        </div>
                        <div className='form-check form-check-custom form-check-inline form-check-solid'>
                          <input
                            className='form-check-input custom-checkbox-bg'
                            name='Mute-upon-entry[]'
                            type='checkbox'
                            onClick={() => setMuteUponEntry(true)}
                            checked={muteUponEntry}
                            onChange={(e) => setMuteUponEntry(e.target.checked)}
                          />
                          <label className='form-check-label fw-semibold ps-2 fs-6 text-gray-800'>
                            Mute upon entry
                          </label>
                        </div>
                        {/* <div class='form-check form-check-custom form-check-inline form-check-solid'>
                          <input
                            class='form-check-input'
                            name=' Mute-upon-entry[]'
                            type='checkbox'
                            checked={watermark}
                            onClick={() => setWatermark(true)}
                            onChange={(e) => setWatermark(e.target.checked)}
                          />
                          <label class='form-check-label fw-semibold ps-2 fs-6'>Watermark</label>
                        </div> */}
                      </div>
                    </div>

                    <div className='d-flex'>
                      <label className='col-lg-2 col-form-label fw-semibold fs-6'>
                        <span>Enable meeting room</span>
                      </label>
                      <div style={{paddingTop: '10px'}}>
                        <div className='form-check form-check-inline'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='yesNoRadio'
                            id='yesRadio'
                            value='true'
                            checked={waiting_room === 'true'}
                            onChange={handleInputChange}
                          />
                          <label className='form-check-label' htmlFor='yesRadio'>
                            True
                          </label>
                        </div>
                        <div className='form-check form-check-inline'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='yesNoRadio'
                            id='noRadio'
                            value='false'
                            checked={waiting_room === 'false'}
                            onChange={handleInputChange}
                          />
                          <label className='form-check-label' htmlFor='noRadio'>
                            False
                          </label>
                        </div>
                      </div>
                    </div>

                    <div class='mb-10 fv-row'>
                      <label class=' form-label'>Audio</label>
                      <select
                        class='form-control mb-2'
                        name='audio'
                        data-placeholder='Select a Audio...'
                        value={audio}
                        onChange={(e) => setAudio(e.target.value)}
                      >
                        <option value=''>Select audio..</option>

                        <option value='voip'>VoIP</option>
                        <option value='telephony'>Telephony</option>
                      </select>
                    </div>

                    <div class='mb-10 fv-row'>
                      <label class=' form-label'>Auto Recording:</label>
                      <select
                        class='form-control mb-2'
                        name='audio'
                        data-placeholder='Select a Auto Recording:...'
                        value={autoRecording}
                        onChange={(e) => setAutoRecording(e.target.value)}
                      >
                        <option value=''>Select a Auto Recording..</option>

                        <option value='cloud'>Cloud</option>
                        <option value='local'>Local</option>
                        <option value='none'>None</option>
                      </select>
                    </div>
                  </div>
                  <div style={{marginLeft: '2rem'}}>
                    <button type='submit' className='btn cyberbtnallpage'>
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default EditMeeting
