import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Navbar from '../../../Navbar/Navbar'
import Footer from '../../../Footer/Footer'
import {Link, useNavigate} from 'react-router-dom'
import {PreviewDetailsAction} from '../../../../Redux/Action/courseAction'
import Table from 'react-bootstrap/Table'
import {Button} from 'react-bootstrap'
import Microsoft from './Microsoft'
import {Modal} from 'antd'
import {useForm} from 'react-hook-form'
import {StudentAction} from '../../../../Redux/Action/bundleAction'
import BuyBtn from '../MicrosoftBootCamp/BuyBtn'
const Microsoftbootcamp = () => {
  const {
    register,
    handleSubmit,

    formState: {errors},
  } = useForm()

  const dispatch = useDispatch()
  const navigate = useNavigate()
  var newURL = window.location
  var splitURL = newURL.href.split('/')
  const [selectedPreviewStartDate, setSelectedPreviewStartDate] = useState(null)
  const [selectedPreviewEndDate, setSelectedPreviewEndDate] = useState(null)
  const [selectedCourseId, setSelectedCourseId] = useState(null)
  const [selectedCourseCode, setSelectedCourseCode] = useState(null)
  const [selectedPrice, setSelectedCoursePrice] = useState(null)
  const [selectedTimezone, setSelectedtimezone] = useState(null)
  const [selectedCourseName, setSelectedCourseName] = useState(null)
  const course_code = splitURL[4]
  const courseslug = splitURL[5]
  //const paidPrice = localStorage.getItem('paid_price')
  const studentId = localStorage.getItem('userid')
  const authToken = localStorage.getItem('authToken')

  localStorage.setItem('title', selectedCourseName)
  localStorage.setItem('price', selectedPrice)
  localStorage.setItem('startDate', selectedPreviewStartDate)
  localStorage.setItem('endDate', selectedPreviewEndDate)
  localStorage.setItem('CourseId', selectedCourseId)
  localStorage.setItem('course_code', splitURL[4])
  localStorage.setItem('timezone', selectedTimezone)
  localStorage.setItem('courseCode', splitURL[4])

  const previewdetails = useSelector((state) => {
    return state.login.getpreviewdetailslist
  })

  let cdata = ''
  useEffect(() => {
    dispatch(PreviewDetailsAction(courseslug))
  }, [dispatch])

  const scrollTomicrosoft = () => {
    const element = document.getElementById('microsoft')
    if (element) {
      element.scrollIntoView({behavior: 'smooth'})
    }
  }

  const handlePreviewDateChange = (formattedStartDate, formattedEndDate, asiaTimezone) => {
    setSelectedPreviewStartDate(formattedStartDate)
    setSelectedPreviewEndDate(formattedEndDate)
    setSelectedtimezone(asiaTimezone)
  }

  // Scroll to 'bundletitle' on page load
  useEffect(() => {
    scrollTomicrosoft()
  }, [])

  const [open, setOpen] = useState(false)

  const showModal = () => {
    setOpen(true)
  }

  const hideModal = () => {
    setOpen(false)
  }

  const onSubmit = () => {
    // debugger
    const formData = new FormData()
    formData.append('Bundle_code', 0)
    formData.append('studentId', studentId)
    formData.append('CourseId', selectedCourseId)
    formData.append('CourseStartDate', selectedPreviewStartDate)
    formData.append('CourseEndDate', selectedPreviewEndDate)
    formData.append('timezone', selectedTimezone)
    formData.append('coursetype', 'course')
    dispatch(StudentAction(formData, course_code, navigate, onSubmit))
  }

  const isFormFilled = () => {
    return (
      selectedCourseId &&
      selectedPreviewStartDate &&
      selectedPreviewStartDate &&
      selectedCourseName &&
      selectedPrice &&
      selectedCourseCode
    )
  }

  const handleClick = () => {
    localStorage.setItem('type', 'course')
    //localStorage.setItem("course_code", splitURL[4])
  }

  return (
    <div id='microsoft'>
      <Navbar />
      <div
        className='BundleDeytails'
        style={{
          position: 'absolute',
          top: '40%',
          left: '35%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'start',
          color: 'white',
          fontSize: '30px',
        }}
      >
        {previewdetails &&
          Object.entries(previewdetails).map(([key, course], i) => {
            if (key === 'data') {
              return (
                <div key={i}>
                  {/* <h1
                    className='banner-title'
                    style={{color: 'white', fontSize: '60px', width: '69%'}}
                  >
                    {course?.title ?? null}
                  </h1>
                  <Button
                    className='start_btn'
                    style={{
                      background: '#ff763f',
                      fontSize: '15px',
                      textTransform: 'capitalize',
                    }}
                    type='submit'
                    onClick={() => {
                      setSelectedCourseId(course.id)
                      setSelectedCourseCode(course.course_code)
                      setSelectedCoursePrice(course.paid_price)
                      setSelectedCourseName(course.title)
                      handleClick()
                      showModal()
                    }}
                  >
                    Buy this course
                  </Button>
                  <BuyBtn /> */}
                </div>
              )
            }
            return null
          })}
      </div>
      <Microsoft />
      <Footer />
    </div>
  )
}

export default Microsoftbootcamp
