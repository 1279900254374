import React, {useState, useRef, useMemo, useEffect} from 'react'
import JoditEditor from 'jodit-react'
import {Link, useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {CreateTestimonialAction} from '../../../Redux/Action/testimonialAction'
import {useForm, Controller} from 'react-hook-form'
import Loader from '../../../components/Loader/loader'

const AddTestimonial = ({placeholder}) => {
  const [loading, setLoading] = useState(false)
  const [long_description, setlong_description] = useState('')
  const [designation, setdesignation] = useState('')

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
  } = useForm()

  const onSubmit = (data) => {
    //debugger
    const formData = new FormData()
    setLoading(true)

    formData.append('name', data.name)
    formData.append('long_description', data.long_description)
    formData.append('designation', data.designation)
    formData.append('status', data.status)

    dispatch(CreateTestimonialAction(formData, navigate, setLoading))
  }

  return (
    <div>
      <div id='kt_app_content' class='app-content flex-column-fluid'>
        {loading && (
          <div className='loader-container'>
            <Loader />
          </div>
        )}
        <form
          id='kt_ecommerce_add_product_form'
          class='form d-flex flex-column flex-lg-row'
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className='course_category col-md-4'>
            <div class='d-flex flex-column gap-7 gap-lg-10 w-100 mb-7 me-lg-10'>
              <div class='card card-flush py-4'>
                <div class='card-header'>
                  <div class='card-title'>
                    <h2>Status</h2>
                  </div>
                </div>

                <div class='card-body pt-0'>
                  <select
                    class='form-select mb-2'
                    data-control='select2'
                    data-hide-search='true'
                    data-placeholder='Select an option'
                    {...register('status', {
                      required: true,
                    })}
                  >
                    aria-invalid={errors.status ? 'true' : 'false'}
                    <option value='Published'>Published</option>
                    <option value='Draft' selected='selected' default>
                      Draft
                    </option>
                  </select>
                  {errors.status?.status === 'required' && (
                    <p className='alert_danger' role='alert'>
                      This field is required
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='course_category col-md-8'>
            <div class='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
              <div class='tab-content'>
                <div
                  class='tab-pane fade show active'
                  id='kt_ecommerce_add_product_general'
                  role='tab-panel'
                >
                  <div class='d-flex flex-column gap-7 gap-lg-10'>
                    <div class='card card-flush py-4'>
                      <div class='card-body pt-0'>
                        <div class='mb-10 fv-row'>
                          <label class='required form-label'>Name</label>

                          <input
                            type='text'
                            name='name'
                            class='form-control mb-2'
                            placeholder='Name'
                            aria-invalid={errors.name ? 'true' : 'false'}
                            {...register('name', {required: true})}
                          />
                          {errors.name?.type === 'required' && (
                            <p className='alert_danger' role='alert'>
                              This Field is required
                            </p>
                          )}
                        </div>

                        <div class='mb-10 fv-row'>
                          <label class='required form-label'>Designation</label>

                          <input
                            type='text'
                            name='designation'
                            class='form-control mb-2'
                            placeholder='designation'
                            aria-invalid={errors.designation ? 'true' : 'false'}
                            {...register('designation', {required: true})}
                          />
                          {errors.designation?.type === 'required' && (
                            <p className='alert_danger' role='alert'>
                              This Field is required
                            </p>
                          )}
                        </div>
                        <div>
                          <label class='required form-label'>Content:</label>

                          <Controller
                            control={control}
                            name='long_description'
                            defaultValue=''
                            rules={{required: true}}
                            render={({field}) => {
                              return (
                                <JoditEditor
                                  ref={field.ref}
                                  value={field.value}
                                  placeholder='start typing'
                                  aria-invalid={errors.long_description ? 'true' : 'false'}
                                  tabIndex={1} // tabIndex of textarea
                                  //onBlur={(newContent) => setlong_description(newContent)}
                                  onBlur={(newContent) => {
                                    if(newContent === '<p><br></p>')
                                    newContent =''
                                    field.onChange(newContent);
                                    setlong_description(newContent)
                                  }}
                                  onChange={newContent => {}}
                                />
                              )
                            }}
                          />
                          {errors.long_description?.type === 'required' && (
                            <p className='alert_danger' role='alert'>
                              This Field is required
                            </p>
                          )}
                         
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class='d-flex justify-content-end'>
                <Link
                  to='/all_testimonial'
                  id='kt_ecommerce_add_product_cancel'
                  class='btn cybercancelbtn me-5'
                >
                  Cancel
                </Link>

                <button
                  type='submit'
                  id='kt_ecommerce_add_product_submit'
                  className='btn cyberbtnallpage'
                >
                  <span class='indicator-label'>Save Changes</span>
                  <span class='indicator-progress'>
                    Please wait...
                    <span class='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddTestimonial
