import {Container} from 'react-bootstrap'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Table from 'react-bootstrap/Table'

import React, {useEffect} from 'react'

import {PreviewDetailsAction} from '../../../../Redux/Action/courseAction'

import {Link, useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'

function Section2() {
  const dispatch = useDispatch()
  var newURL = window.location
  var splitURL = newURL.href.split('/')

  const courseslug = splitURL[5]

  const previewdetails = useSelector((state) => {
    // debugger;
    return state.login.getpreviewdetailslist
  })

  //console.log(previewdetails, 'coursedetailcoursedetailscoursdddddddddddddddedetailscoursedetailss')
  let cdata = ''
  useEffect(() => {
    // debugger
    dispatch(PreviewDetailsAction(courseslug))
  }, [dispatch])
  return (
    <div>
      <Container style={{paddingLeft: '50px'}}>
        {previewdetails &&
          Object.entries(previewdetails).map(([key, course], i) => {
            if (key === 'data') {
              const truncatedDescription =
              course.Delivery_Mechanism && course.Delivery_Mechanism.length > 10000
                ? `${course.Delivery_Mechanism.slice(0, 10000)}...`
                : course.Delivery_Mechanism ?? '';
            

              return (
                <div key={course.id}>
                  <p
                    dangerouslySetInnerHTML={{__html: truncatedDescription ?? ''}}
                    style={{
                      fontFamily: 'Sora, sans-serif',
                      fontSize: '16px',
                      paddingTop: '20px',
                    }}
                  />
                </div>
              )
            }
            return null
          })}
        {!Object.entries(previewdetails).some(([key]) => key === 'data') && (
          <div className='no-data-message'>
            <p>No Data</p>
          </div>
        )}
      </Container>
    </div>
  )
}

export default Section2
