import axios from 'axios'
import swal from 'sweetalert'


import {
    getAllRoleslice,
    getuserbyroleslice
    
  } from '../Slice/authSlice'







export const GetallRoleAction = () => async (dispatch) => {
    try {
      const token = localStorage.getItem('authToken')
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
  
      const getAllRoles = process.env.REACT_APP_BACKEND_API + '/getAllRoles'
      const res = await axios.get(getAllRoles, config, '')
  
      await dispatch(getAllRoleslice(res))
    } catch (e) {
      // if (e?.response?.data.message) {
      //   swal("error", e?.response?.data?.message, "error");
      // }
      // console.log(e)
    }
  }




  export const UserByRolesActions = (id) => async (dispatch) => {
    // debugger
    try {
      const token = localStorage.getItem('authToken')
  
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
  
      const getuserid = `${process.env.REACT_APP_BACKEND_API}/getuserbyrole/${id}`
  
      const res = await axios.get(getuserid, config)
  
      await dispatch(getuserbyroleslice(res))
    } catch (e) {
      // console.log(e)
    }
  }


  // getuserbyroleslice,getuserbyrole_list