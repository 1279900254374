import React from 'react'

import {Col, Row} from 'react-bootstrap'

import './Student.css'

import MyActivity from './Dashboard/Sections/Myactivity/MyActivity'
import {useEffect} from 'react'
import swal from 'sweetalert'
import Swal from 'sweetalert2'
import {useLocation} from 'react-router-dom'
import Section2 from '../Student_Dashboard/Dashboard/Sections/Section2/Section2'
import Coursecategory from '../Student_Dashboard/Dashboard/Coursecategory/Coursecategory'

const Dashboard = () => {
  const location = useLocation()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const message = searchParams.get('message')
    if (message) {
      swal({
        title: 'Success!',
        text: 'Payment successful. Thank you for your purchase!',
        icon: 'success',
        buttons: {
          cancel: {
            text: 'OK',
            value: null,
            visible: true,
            className: 'swal-button--confirm cyberbtnallpage', 
          },
        },
      }).then(() => {
        const newUrl = window.location.pathname
        window.history.pushState({}, '', newUrl)
      })
    }
  }, [location.search])

  return (
    <div>
      <div className='main-page'>
        <div className='main'>
          <>
            <Row>
              <Col className='col-md-12'>
                <div className='activity'>
                  <MyActivity />
                </div>
              </Col>
            </Row>
            {/* <span class='fw-bold fs-3x mb-3'>Bundles</span> */}
            <div className='pt-10'>
              <div className='card shadow-none'>
                <h2 className='m-5'>My Courses</h2>
              </div>
              <Row className='card' style={{marginTop: '2rem'}}>
                <Col>
                  <div>
                    <Section2 />
                  </div>
                </Col>
              </Row>
            </div>

            <div className='pt-10'>
              <Row>
                <Col>
                  <div className='student-events pt-5 pb-7'>
                    <Coursecategory />
                  </div>
                </Col>
              </Row>
            </div>
          </>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
