import swal from 'sweetalert'
import axios from 'axios'
import {createAsyncThunk} from '@reduxjs/toolkit'

import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
    getLabsSlice,
    getPublishedLabsslice,
    getlabbyidslice
  } from '../Slice/authSlice'



export const CreateLabAction = (formData, navigate, setLoading) => async (dispatch) => {
    try {
      //debugger
      const token = localStorage.getItem('authToken')
  
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
  
          Authorization: `Bearer ${token}`,
        },
      }
  
      const createcourse = process.env.REACT_APP_BACKEND_API + '/createLab'
  
      const res = await axios.post(createcourse, formData, config)
  
      if (res.status === 200) {
        swal({
          title: 'Success',
          text: 'Lab Created',
          icon: 'success',
          buttons: {
            cancel: {
              text: 'OK',
              value: null,
              visible: true,
              className: 'swal-button--confirm cyberbtnallpage',
            },
          },
        })
        navigate('/AllChallengeLabs')
      }
      dispatch(res())
    } catch (e) {
      if (e?.response?.data?.message) {
        swal('Error', e?.response?.data?.message, 'Error')
        //toast.error(e?.response?.data?.message)
        setLoading(false)
      }
    }
  }

  export const GetallLabsAction = () => async (dispatch) => {
    try {
      // debugger;
      const token = localStorage.getItem('authToken')
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
  
      const getcourses = process.env.REACT_APP_BACKEND_API + '/getallLabs'
      const res = await axios.get(getcourses, config)
  
      await dispatch(getLabsSlice(res))
    } catch (e) {
      if (e?.response?.data.message) {
        swal('Error', e?.response?.data?.message, 'error')
      }
    }
  }

  export const GetallPublishedLabsAction = () => async (dispatch) => {
    try {
      // debugger;
      const token = localStorage.getItem('authToken')
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
  
      const getcourses = process.env.REACT_APP_BACKEND_API + '/getallPublishedLabs'
      const res = await axios.get(getcourses, config)
  
      await dispatch(getPublishedLabsslice(res))
    } catch (e) {
      if (e?.response?.data.message) {
        swal('Error', e?.response?.data?.message, 'error')
      }
    }
  }

  export const LabDeleteAction = (labID) => async (dispatch) => {
    try {
      const token = localStorage.getItem('authToken')
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
  
      const confirmResult = await swal({
        title: 'Delete Challenge Lab',
        text: 'Are you sure you want to delete this?',
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Cancel',
            value: null,
            visible: true,
            className: 'swal-button--cancel cybercancelbtn', 
          },
          confirm: {
            text: 'Delete',
            value: true,
            visible: true,
            className: 'swal-button--confirm swal-button--danger cyberbtnallpage', 
          },
        },
        dangerMode: true,
      })
      if (confirmResult) {
        const deletelab = `${process.env.REACT_APP_BACKEND_API}/deleteLab/${labID}`
        const res = await axios.delete(deletelab, config)
  
        if (res.status === 200) {
          swal({
            title: 'Success',
            text: 'Challenge Lab Deleted',
            icon: 'success',
            buttons: {
              cancel: {
                text: 'OK',
                value: null,
                visible: true,
                className: 'swal-button--confirm cyberbtnallpage', 
              },
            },
          }).then(() => {
            window.location.reload()
          })
        }
      }
    } catch (e) {
      // console.log(e)
    }
  }


  export const GetLabAction = (labID) => async (dispatch) => {

    try {
      // debugger
      const token = localStorage.getItem('authToken')
  
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
  
      const getLabById = `${process.env.REACT_APP_BACKEND_API}/getLabById/${labID}`
  
      const res = await axios.get(getLabById, config)
  
      await dispatch(getlabbyidslice(res))
    } catch (e) {
      // console.log(e)
    }
  }
  
  export const EditLabAction = (formData, labID, navigate) => async (dispatch) => {
    try {
      // debugger
  
      const token = localStorage.getItem('authToken')
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
  
      const updateLab = `${process.env.REACT_APP_BACKEND_API}/editLab/${labID}`
      const res = await axios.put(updateLab, formData, config)
      // console.log(res)
  
      if (res.status === 200) {
        swal({
          title: 'Success',
          text: 'Lab Updated',
          icon: 'success',
          buttons: {
            cancel: {
              text: 'OK',
              value: null,
              visible: true,
              className: 'swal-button--confirm cyberbtnallpage', 
            },
          },
        })
        navigate('/AllChallengeLabs')
      }
    } catch (e) {
      if (e?.response?.data.message) {
        swal('Error', e.response.data.message, 'error')
      }
    }
  }