import React, {useState, useEffect, useRef} from 'react'
import 'primereact/resources/themes/lara-light-indigo/theme.css' // theme
import 'primereact/resources/primereact.css' // core css
import {useDispatch, useSelector} from 'react-redux'
import {GetallPaymentAction} from '../../../Redux/Action/paymentAction'
import {FilterMatchMode} from 'primereact/api'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {InputText} from 'primereact/inputtext'
import {Button} from 'primereact/button'
import {Toolbar} from 'primereact/toolbar'
import ExcelJS from 'exceljs'

export default function AllPayements() {
  const dispatch = useDispatch()
  const dt = useRef(null)
  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({selectionOnly})
  }

  const exportExcel = (selectionOnly) => {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('User Data')

    // Define columns for the Excel sheet
    const columns = [
      {header: 'UserName', key: 'FirstName'},
      {header: 'PayuserEmail', key: 'payeruseremail'},
      {header: 'PaymentID', key: 'paymentid'},
      {header: 'Amount', key: 'amountpaid'},
      {header: 'Purchase Date', key: 'paymenttime'},
    ]

    // Add the columns to the worksheet
    worksheet.columns = columns

    // Export selected data or all data based on 'selectionOnly' flag
    const dataToExport = selectionOnly ? getallpayment : getallpayment

    // Add data to the worksheet
    dataToExport.forEach((item) => {
      worksheet.addRow(item)
    })

    // Generate a unique filename
    const fileName = `user_data_${new Date().getTime()}.xlsx`

    // Create a blob from the workbook and save it as a file
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = fileName
      a.click()
      window.URL.revokeObjectURL(url)
    })
  }

  const [filters, setFilters] = useState({
    global: {value: null, matchMode: FilterMatchMode.CONTAINS},
    UserName: {value: null, matchMode: FilterMatchMode.CONTAINS},
    PayuserEmail: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
    PaymentID: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
    Amount: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
    Type: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
    PaymentTime: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
  })

  const [loading, setLoading] = useState(true)
  const [globalFilterValue, setGlobalFilterValue] = useState('')

  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    let _filters = {...filters}

    _filters['global'].value = value

    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const renderHeader = () => {
    return (
      <div className='flex justify-content-end'>
        <span className='p-input-icon-left'>
          <i className='pi pi-search' style={{marginTop: '-5px'}} />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder='Keyword Search'
          />
        </span>
      </div>
    )
  }

  const header = renderHeader()

  const getallpayment = useSelector((state) => {
    return state.login.getAllPaymentList
  })

  useEffect(() => {
    dispatch(GetallPaymentAction()).then(() => {
      setLoading(false)
    })
  }, [dispatch])

  const rows = getallpayment.map((item, index) => {
    const fullName =
      item.FirstName && item.Surname ? item.FirstName + ' ' + item.Surname : 'User Deleted'

    return {
      id: index,
      SNo: index + 1,
      UserName: fullName,
      PayuserEmail: item.payeruseremail,
      PaymentID: item.paymentid,
      Amount: `$${item.amountpaid}`,
      Type: item.type,
      PaymentTime: item.paymenttime,
    }
  })
  const rightToolbarTemplate = () => {
    return (
      <>
        <Button
          label='CSV'
          icon='pi pi-upload'
          className='p-button-secondary cyberbtnallpage csv_btn'
          onClick={() => exportCSV(false)}
        />
        <div style={{margin: '0 10px'}}></div>
        <Button
          label='Excel'
          icon='pi pi-upload'
          className='p-button-secondary cyberbtnallpage csv_btn'
          onClick={() => exportExcel(false)}
        />
      </>
    )
  }
  return (
    <div className='card'>
      <Toolbar className='mb-4' right={rightToolbarTemplate} />
      <DataTable
        value={rows}
        paginator
        ref={dt}
        rows={10}
        dataKey='id'
        tableStyle={{minWidth: '100%'}}
        filters={filters}
        filterDisplay='row'
        // loading={loading}
        globalFilterFields={[
          'UserName',
          'PayuserEmail',
          'PaymentID',
          'Amount',
          'Type',
          'PaymentTime',
        ]}
        header={header}
        emptyMessage='No customers found.'
        rowClassName='table-row'
      >
        <Column sortable field='SNo' header='#' style={{minWidth: '12rem'}} />

        <Column
          field='UserName'
          header='User Name'
          filter
          filterPlaceholder='Search by User name'
          style={{minWidth: '12rem'}}
        />
        <Column
          field='PayuserEmail'
          header='Payuser Email'
          filter
          filterPlaceholder='Search by email'
          style={{minWidth: '12rem'}}
        />
        <Column
          field='PaymentID'
          header='Payment ID'
          filter
          filterPlaceholder='Search by Payment Id'
          style={{minWidth: '12rem'}}
        />
        <Column
          field='Amount'
          header='Amount'
          filter
          filterPlaceholder='Search by Amount'
          style={{minWidth: '12rem'}}
        />

        <Column
          field='PaymentTime'
          header='Purchase Date'
          filter
          filterPlaceholder='Search by Payment Time'
          style={{minWidth: '12rem'}}
          body={(rowData) => rowData.PaymentTime}
        />
      </DataTable>
    </div>
  )
}
