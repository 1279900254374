import React from 'react'
const Permissions = () => {
  return (
    <div>
      <div id='kt_app_toolbar' class='app-toolbar  '>
        <div id='kt_app_toolbar_container' className='px-3'>
          <div class='page-title d-flex flex-column justify-content-center flex-wrap me-3 '>
            <h1 class='page-heading d-flex fw-bold fs-3 flex-column justify-content-center my-0'>
              Permissions List
            </h1>

            <ul class='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
              <li class='breadcrumb-item text-muted'>
                <a
                  href='/metronic8/demo1/../demo1/index.html'
                  class='text-muted text-hover-primary'
                >
                  Home{' '}
                </a>
              </li>
              <li class='breadcrumb-item'>
                <span class='bullet bg-gray-400 w-5px h-2px'></span>
              </li>

              <li class='breadcrumb-item text-muted'>User Management </li>
            </ul>
          </div>
        </div>
      </div>
      <div id='kt_app_content' class='app-content flex-column-fluid'>
        <div>
          <div class='card card-flush'>
            <div class='card-body pt-0'>
              <div class='table-responsive-sm'>
                <table
                  class='table align-middle table-row-dashed fs-6 gy-5 mb-0'
                  id='kt_permissions_table'
                >
                  <thead class='thead-dark'>
                    <tr class='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                      <th class='min-w-125px'>Name</th>
                      <th class='min-w-250px'>Assigned to</th>
                    </tr>
                  </thead>

                  <tbody class='fw-semibold text-gray-600'>
                    <tr>
                      <td>User Management</td>
                      <td>
                        <a href='/view_role/1' class='badge badge-light-success fs-7 m-1'>
                          Superadmin
                        </a>
                        <a href='/view_role/2' class='badge badge-light-primary fs-7 m-1'>
                          Cyberheads Team Admin
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Manage Virtual Classes</td>
                      <td>
                        <a href='/view_role/1' class='badge badge-light-success fs-7 m-1'>
                          Superadmin
                        </a>
                        <a href='/view_role/2' class='badge badge-light-primary fs-7 m-1'>
                          Cyberheads Team Admin
                        </a>
                        <a href='/view_role/8' class='badge badge-light-info fs-7 m-1'>
                          Training Coordinator
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Manage Courses</td>
                      <td>
                        <a href='/view_role/1' class='badge badge-light-success fs-7 m-1'>
                          Superadmin
                        </a>
                        <a href='/view_role/2' class='badge badge-light-primary fs-7 m-1'>
                          Cyberheads Team Admin
                        </a>
                        <a href='/view_role/9' class='badge badge-light-danger fs-7 m-1'>
                          Content Administrator
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Manage Course Bundles</td>
                      <td>
                        <a href='/view_role/1' class='badge badge-light-success fs-7 m-1'>
                          Superadmin
                        </a>
                        <a href='/view_role/2' class='badge badge-light-primary fs-7 m-1'>
                          Cyberheads Team Admin
                        </a>
                        <a href='/view_role/9' class='badge badge-light-danger fs-7 m-1'>
                          Content Administrator
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>User Role Management</td>
                      <td>
                        <a href='/view_role/1' class='badge badge-light-success fs-7 m-1'>
                          Superadmin
                        </a>
                        <a href='/view_role/2' class='badge badge-light-primary fs-7 m-1'>
                          Cyberheads Team Admin
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Permission Management</td>
                      <td>
                        <a href='/view_role/1' class='badge badge-light-success fs-7 m-1'>
                          Superadmin
                        </a>
                        <a href='/view_role/2' class='badge badge-light-primary fs-7 m-1'>
                          Cyberheads Team Admin
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Payments</td>
                      <td>
                        <a href='/view_role/1' class='badge badge-light-success fs-7 m-1'>
                          Superadmin
                        </a>
                        <a href='/view_role/2' class='badge badge-light-primary fs-7 m-1'>
                          Cyberheads Team Admin
                        </a>
                        <a href='/view_role/10' class='badge badge-light-warning fs-7 m-1'>
                          User Manager
                        </a>
                        <a href='/view_role/10' class='badge badge-light-dark fs-7 m-1'>
                          Corporate Admin
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Reports</td>
                      <td>
                        <a href='/view_role/1' class='badge badge-light-success fs-7 m-1'>
                          Superadmin
                        </a>
                        <a href='/view_role/2' class='badge badge-light-primary fs-7 m-1'>
                          Cyberheads Team Admin
                        </a>
                        <a href='/view_role/10' class='badge badge-light-warning fs-7 m-1'>
                          User Manager
                        </a>
                        <a href='/view_role/10' class='badge badge-light-dark fs-7 m-1'>
                          Corporate Admin
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>LTI Tools</td>
                      <td>
                        <a href='/view_role/1' class='badge badge-light-success fs-7 m-1'>
                          Superadmin
                        </a>
                        <a href='/view_role/2' class='badge badge-light-primary fs-7 m-1'>
                          Cyberheads Team Admin
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Permissions
