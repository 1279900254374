import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { GetBlogBySlugAction, GetallBlogsAction } from '../../Redux/Action/blogAction'
import blog_default from "../../assets/images/blog_default.png"

const Blogdetail = () => {
  const dispatch = useDispatch()

  const params = useParams()
  const blogSlug = params.blog_slug

  const blogs = useSelector((state) => {
    return state.login.getblogbysluglist
  })

  const blogslist = useSelector((state) => {
    return state.login.getblogslist
  })


  useEffect(() => {
    dispatch(GetBlogBySlugAction(blogSlug))
    dispatch(GetallBlogsAction())
  }, [dispatch])

  const blogDetail = blogs.blog || []
  const allblogs = blogslist.blog || []



  return (
    <div>
      <div className='banner-content'>
        <h1
          className='banner-title'
          style={{
            position: 'absolute',
            top: '30%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            textAlign: 'center',
            color: 'white',
            fontSize: '30px',
            zIndex: 99,
          }}
        >
          {blogDetail.title ? blogDetail.title : "Blogs"}
        </h1>
      </div>
      <div>
        <section className='blog' style={{ zIndex: '99999999', fontSize: '24px' , paddingTop: '10px'}}>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-lg-12'>
                     <div className='blog-detail-left'>
                        <div class="blog-thumb">
                          <img src={blogDetail.image_link ? blogDetail.image_link : blog_default} alt='' />
                        </div>
                        <div className='blog-description'>
                          <div dangerouslySetInnerHTML={{ __html: blogDetail.long_description }}>
                          </div>
                        </div>
                    </div>
                  </div>
                 
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='blog-sidebar'>
                  <div className='row'>
                    <div className='col-lg-12'>
                    <div class="sidebar-item recent-posts">
                  <div class="sidebar-heading">
                    <h2>Recent Posts</h2>
                  </div>
                  <div className="content">
                    <ul className='sidebar-blog'>
                      {allblogs.map((item, index) => {
                       
                        if (index < 3 && item.id != blogDetail.id) {
                          return (

                            <li className='list-item' key={index}>
                              <a href={`/blogdetail/${item.slug}`}>
                                <div className='thumb-wrapper'>
                                  <img src={item.image_link ? item.image_link : blog_default} alt='' />
                                </div>

                                <h6>{item.title}</h6>
                              </a>
                            </li>
                          )
                        }
                      })}
                    </ul>
                  </div>
                </div>
                    </div>
                  </div>
                </div>
              
              </div>
            </div>

          </div>
        </section>
      </div>
    </div>
  )
}

export default Blogdetail
