import React, {useState, useRef, useMemo} from 'react'
import JoditEditor from 'jodit-react'
import {Link, useNavigate} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {CreateCourseAction} from '../../../Redux/Action/courseAction'
import {useForm, Controller} from 'react-hook-form'
import Loader from '../../../components/Loader/loader'
import TimezoneSelect from 'react-timezone-select'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import axios from 'axios'
import moment from 'moment-timezone'

const AddCourse = ({placeholder}) => {
  // const editor = useRef(null)

  const getCurrentDateTime = () => {
    const now = new Date()
    const roundedMinutes = Math.ceil(now.getMinutes() / 15) * 15
    now.setMinutes(roundedMinutes)

    const year = now.getFullYear()
    const month = `${(now.getMonth() + 1).toString().padStart(2, '0')}`
    const day = `${now.getDate().toString().padStart(2, '0')}`
    const hours = `${now.getHours().toString().padStart(2, '0')}`
    const minutes = `${roundedMinutes.toString().padStart(2, '0')}`

    return `${year}-${month}-${day}T${hours}:${minutes}`
  }

  const [loading, setLoading] = useState(false)
  const [description, Setdescription] = useState('')
  const [Learnings, SetLearnings] = useState('')
  const [TopicCovered, SetTopicCovered] = useState('')
  const [PreRequisites, SetPreRequisites] = useState('')
  const [Delivery_Mechanism, SetDelivery_Mechanism] = useState('')
  const [resources, SetResources] = useState('')
  const [duration, SetDuration] = useState('')
  const [courseCategory, setCourseCategory] = useState('')

  // Delivery_Mechanism

  const navigate = useNavigate()

  const dispatch = useDispatch()
  const [dateFields, setDateFields] = useState([
    {start_datetime: '', end_datetime: '', timezone: '', numberOfSeats: ''},
  ])

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: {errors},
  } = useForm()
  const userid = localStorage.getItem('userid')

  function formatDate(dateString) {
    const months = {
      Jan: '01',
      Feb: '02',
      Mar: '03',
      Apr: '04',
      May: '05',
      Jun: '06',
      Jul: '07',
      Aug: '08',
      Sep: '09',
      Oct: '10',
      Nov: '11',
      Dec: '12',
    }

    const parts = dateString?.toString().split(' ')
    const month = months[parts[1]]
    const day = parts[2]
    const year = parts[3]
    const time = parts[4]?.split(':')

    const hours = time[0]
    const minutes = time[1]
    const seconds = time[2]

    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`
    return formattedDate
  }

  const formSubmit = async (data) => {
    debugger
    const fields = {
      courseCreate: true,
      userid,
      topic: data.title,
      Start_Time: [],
      End_Time: [],
      duration: [],
      timezone: [],
      uniqueCode: [],
    }

    const formData = new FormData()
    setLoading(true)
    formData.append('title', data.title)
    formData.append('description', description)
    formData.append('paid_price', data.paid_price)
    formData.append('status', data.status)
    formData.append('poster_file', data.poster_file[0])
    if (data.course_pdf) {
      formData.append('course_pdf', data.course_pdf[0])
    }
    formData.append('type', data.type)
    formData.append('certifications', data.certifications)
    formData.append('vouchers', data.vouchers)
    formData.append('learnings', Learnings)
    formData.append('topicCovered', TopicCovered)
    formData.append('preRequisites', data.preRequisites)
    formData.append('Delivery_Mechanism', Delivery_Mechanism)
    formData.append('resources', resources)
    formData.append('duration', duration)
    dateFields.forEach((dateField) => {
      const uniqueCode = Math.random().toString(36).substring(2, 10)
      formData.append('start_datetime', dateField.start_datetime)
      formData.append('end_datetime', dateField.end_datetime)
      formData.append('timezone', dateField.timezone)
      formData.append('uniqueCode', uniqueCode)
      formData.append('SeatsCount', dateField.numberOfSeats)
      if (selectedCategory != '3') {
        fields.Start_Time.push(formatDate(dateField.start_datetime))
        fields.End_Time.push(formatDate(dateField.end_datetime))
        fields.timezone.push(dateField.timezone)
        fields.uniqueCode.push(uniqueCode)
      }

      const startDateTime = new Date(dateField.start_datetime)
      const endDateTime = new Date(dateField.end_datetime)
      const startHours = startDateTime.getUTCHours()
      const startMinutes = startDateTime.getUTCMinutes()
      const endHours = endDateTime.getUTCHours()
      const endMinutes = endDateTime.getUTCMinutes()

      const durationInMinutes = endHours * 60 + endMinutes - (startHours * 60 + startMinutes)
      fields.duration.push(durationInMinutes)
    })

    dispatch(CreateCourseAction(formData, fields, navigate, setLoading))
  }

  const handleAddDateField = () => {
    setDateFields([
      ...dateFields,
      {start_datetime: '', end_datetime: '', timezone: '', numberOfSeats: ''},
    ])
  }

  const handleRemoveDateField = (index) => {
    if (dateFields.length > 1) {
      const updatedDateFields = [...dateFields]
      updatedDateFields.splice(index, 1)
      setDateFields(updatedDateFields)
    }
  }

  const handleDateFieldChange = (index, field, value) => {
    const updatedDateFields = [...dateFields]
    if (field === 'timezone') {
      updatedDateFields[index].timezone = value.value
    } else if (field === 'numberOfSeats') {
      updatedDateFields[index].numberOfSeats = value.target.value
    } else {
      updatedDateFields[index][field] = value
    }
    setDateFields(updatedDateFields)
  }

  const selectedCategory = watch('type')
  const isCategory1or2 = selectedCategory === '1' || selectedCategory === '2'
  const isCategory3 = selectedCategory === '3'

  const [selectedFile, setSelectedFile] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const handleFileChange = (e) => {
    const file = e.target.files[0]

    if (file) {
      const fileNameParts = file.name.split('.')
      const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase()

      if (fileExtension === 'pdf') {
        setSelectedFile(file)
        setErrorMessage('')
      } else {
        e.target.value = null
        setSelectedFile(null)
        setErrorMessage('Invalid file type. Please select a PDF file.')
      }
    }
  }
  return (
    <div>
      <div id='kt_app_content' class='app-content flex-column-fluid'>
        {loading && (
          <div className='loader-container'>
            <Loader />
          </div>
        )}
        <form
          id='kt_ecommerce_add_product_form'
          class='form d-flex flex-column flex-lg-row'
          onSubmit={handleSubmit(formSubmit)}
        >
          <div className='course_category'>
            <div class='d-flex flex-column gap-7 gap-lg-10 w-100 mb-7 me-lg-10'>
              <div class='card card-flush py-4'>
                <div class='card-header'>
                  <div class='required card-title'>
                    <h2>Course Category</h2>
                  </div>

                  <div class='card-toolbar'>
                    <div class='' id='kt_ecommerce_add_category_status'></div>
                  </div>
                </div>

                <div class='card-body pt-0'>
                  <select
                    class='form-select mb-2'
                    data-control='select2'
                    data-hide-search='true'
                    data-placeholder='Select an option'
                    id='kt_ecommerce_add_category_status_select'
                    {...register('type', {
                      required: true,
                    })}
                    onChange={(e) => setCourseCategory(e.target.value)}
                  >
                    aria-invalid={errors.type ? 'true' : 'false'}
                    <option value='' disabled selected>
                      Select{' '}
                    </option>
                    <option value='1'>CompTIA Bootcamp</option>
                    <option value='2'>Microsoft Bootcamp</option>
                    <option value='3'>CompTIA elearning</option>
                  </select>
                  {errors.type?.type === 'required' && (
                    <p className='alert_danger' role='alert'>
                      This is required field
                    </p>
                  )}
                </div>
              </div>

              <div class='card card-flush pt-0'>
                <div class='card-header'>
                  <div class='required card-title'>
                    <h2>Upload Image</h2>
                  </div>
                  <input
                    class='form-control '
                    type='file'
                    name='image'
                    {...register('poster_file', {
                      required: 'This is required field.',
                    })}
                    aria-invalid={errors.poster_file ? 'true' : 'false'}
                  />
                  {errors.poster_file?.type === 'required' && (
                    <p className='alert_danger' role='alert'>
                      This is required field
                    </p>
                  )}
                </div>

                <div class='card-body pt-0'></div>
              </div>

              {/* <div class='card card-flush py-4'>
                <div class='card-header'>
                  <div class='card-title'>
                    <h2>Upload Video</h2>
                  </div>
                  <input
                    class='form-control'
                    type='file'
                    name='pre_video'
                    style={{padding: '1rem'}}
                    {...register('pre_video', {})}
                    aria-invalid={errors.pre_video ? 'true' : 'false'}
                  />
                </div>

                <div class='card-body pt-0'></div>
              </div> */}

              <div class='card card-flush py-4'>
                <div class='card-header'>
                  <div class='card-title'>
                    <h2>Upload Pdf</h2>
                  </div>
                  <input
                    className='form-control'
                    type='file'
                    name='course_pdf'
                    style={{padding: '1rem'}}
                    onChange={handleFileChange}
                    aria-invalid={selectedFile === null ? 'true' : 'false'}
                  />
                </div>

                <div className='card-body pt-0'>
                  {errorMessage && (
                    <p className='alert_danger' role='alert'>
                      {errorMessage}
                    </p>
                  )}
                </div>
              </div>

              <div class='card card-flush py-4'>
                <div class='card-header'>
                  <div class='card-title'>
                    <h2>Status</h2>
                  </div>
                </div>

                <div class='card-body pt-0'>
                  <select
                    class='form-select mb-2'
                    data-control='select2'
                    data-hide-search='true'
                    data-placeholder='Select an option'
                    {...register('status', {
                      required: true,
                    })}
                  >
                    aria-invalid={errors.status ? 'true' : 'false'}
                    <option value='Published'>Published</option>
                    <option value='Draft' selected='selected' default>
                      Draft
                    </option>
                  </select>
                  {errors.status?.status === 'required' && (
                    <p className='alert_danger' role='alert'>
                      This is required field
                    </p>
                  )}
                </div>
              </div>
              <div class='card card-flush py-4'>
                <div class='card-header'>
                  <div class='card-title'>
                    <h2>Features</h2>
                  </div>
                </div>

                <div class='card-body pt-0'>
                  <input
                    type='checkbox'
                    id='vouchersCheckbox'
                    name='vouchers'
                    defaultChecked={true}
                    value='yes'
                    {...register('vouchers', {
                      required: false,
                    })}
                  />
                  <label htmlFor='vouchersCheckbox' style={{marginLeft: '5px'}}>
                    Exam vouchers
                  </label>
                  {errors.vouchers?.vouchers === 'required' && (
                    <p className='alert_danger' role='alert'>
                      This is required field
                    </p>
                  )}
                  <br />
                  <input
                    type='checkbox'
                    id='vouchersCheckbox'
                    name='vouchers'
                    defaultChecked={true}
                    {...register('vouchers', {
                      required: false,
                    })}
                  />

                  <label htmlFor='vouchersCheckbox' style={{marginLeft: '5px'}}>
                    Hands-On Lab Training
                  </label>
                  {errors.vouchers?.vouchers === 'required' && (
                    <p className='alert_danger' role='alert'>
                      This is required field
                    </p>
                  )}
                </div>
              </div>

              {courseCategory == '1' || courseCategory == '2' ? (
                <div class='card card-flush py-4'>
                  <div class='card-header'>
                    <div class='card-title'>
                      <h2>Add Schedule</h2>
                    </div>
                  </div>
                  <div className='card-body pt-0'>
                    {dateFields.map((dateField, index) => (
                      <div key={index}>
                        <label htmlFor='timezone' className='form-label'>
                          <span className=''>Timezone</span>
                        </label>
                        <div style={{marginBottom: '10px'}}>
                          <TimezoneSelect
                            value={dateField.timezone}
                            onChange={(value) => handleDateFieldChange(index, 'timezone', value)}
                          />
                        </div>
                        <div>
                          <label className=''>Start Date</label>
                          <br />
                          <div style={{display: 'flex', flexDirection: 'column'}}>
                            <DatePicker
                              className='form-control mb-2'
                              selected={dateField.start_datetime}
                              onChange={(date) =>
                                handleDateFieldChange(index, 'start_datetime', date)
                              }
                              minDate={new Date()}
                              showTimeSelect
                              timeFormat='HH:mm'
                              timeIntervals={15}
                              timeCaption='time'
                              dateFormat='dd/MM/yyyy HH:mm'
                            />
                          </div>
                        </div>
                        <div>
                          <label>End Date</label>

                          <br />
                          <div style={{display: 'flex', flexDirection: 'column'}}>
                            <DatePicker
                              minDate={new Date()}
                              className='form-control mb-2'
                              selected={dateField.end_datetime}
                              onChange={(date) =>
                                handleDateFieldChange(index, 'end_datetime', date)
                              }
                              showTimeSelect
                              timeFormat='HH:mm'
                              timeIntervals={15}
                              timeCaption='time'
                              dateFormat='dd/MM/yyyy HH:mm'
                            />
                          </div>
                        </div>
                        <div>
                          <label>No of Seats</label>
                          <br />
                          <div style={{display: 'flex', flexDirection: 'column'}}>
                            <input
                              type='number'
                              className='form-control mb-2'
                              placeholder='Enter number of seats'
                              value={dateField.numberOfSeats}
                              onChange={(value) =>
                                handleDateFieldChange(index, 'numberOfSeats', value)
                              }
                            />
                          </div>
                        </div>

                        {dateFields.length > 1 && (
                          <button
                            type='button'
                            className='btn  btn-sm cyberbtnallpage'
                            onClick={() => handleRemoveDateField(index)}
                          >
                            Remove
                          </button>
                        )}
                      </div>
                    ))}
                    <div style={{display: 'flex', justifyContent: 'end', gap: '10px'}}>
                      <button
                        type='button'
                        className='btn btn-sm cyberbtnallpage'
                        onClick={handleAddDateField}
                        style={{marginTop: '10px'}}
                      >
                        Add More
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
          <div className='add_course_section_snd col-md-8'>
            <div class='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
              <div class='tab-content'>
                <div
                  class='tab-pane fade show active'
                  id='kt_ecommerce_add_product_general'
                  role='tab-panel'
                >
                  <div class='d-flex flex-column gap-7 gap-lg-10'>
                    <div class='card card-flush py-4'>
                      <div class='card-body pt-0'>
                        <div class='mb-10 fv-row'>
                          <label class='required form-label'>Course Name</label>

                          <input
                            type='text'
                            name='title'
                            class='form-control mb-2'
                            placeholder='Course name'
                            aria-invalid={errors.title ? 'true' : 'false'}
                            {...register('title', {required: true})}
                          />
                          {errors.title?.type === 'required' && (
                            <p className='alert_danger' role='alert'>
                              This is required field
                            </p>
                          )}
                        </div>
                        <div class='mb-10 fv-row'>
                          <label class='required form-label'>Price</label>

                          <input
                            type='text'
                            name='price'
                            class='form-control mb-2'
                            placeholder='Enter Price'
                            aria-invalid={errors.paid_price ? 'true' : 'false'}
                            {...register('paid_price', {required: true})}
                            onKeyPress={(e) => {
                              const isNumeric = /^[0-9]$/
                              if (!isNumeric.test(e.key)) {
                                e.preventDefault()
                              }
                            }}
                          />
                          {errors.paid_price?.type === 'required' && (
                            <p className='alert_danger' role='alert'>
                              This is required field
                            </p>
                          )}
                        </div>
                        <div class='mb-10 fv-row'>
                          <label class='required form-label'>Certification Name</label>

                          <input
                            type='text'
                            name='certifications'
                            class='form-control mb-2'
                            placeholder='Enter Certification Names'
                            aria-invalid={errors.certifications ? 'true' : 'false'}
                            {...register('certifications', {required: true})}
                          />
                          {errors.certifications?.type === 'required' && (
                            <p className='alert_danger' role='alert'>
                              This is required field
                            </p>
                          )}
                        </div>

                        <div>
                          <label class='required form-label'>Delivery Mechanism:</label>

                          <Controller
                            control={control}
                            name='Delivery_Mechanism'
                            defaultValue=''
                            rules={{required: true}}
                            render={({field}) => (
                              <JoditEditor
                                ref={field.ref}
                                value={Delivery_Mechanism}
                                placeholder='start typing'
                                aria-invalid={errors.Delivery_Mechanism ? 'true' : 'false'}
                                tabIndex={1}
                                onBlur={(newContent) => {
                                  if (newContent === '<p><br></p>') newContent = ''
                                  SetDelivery_Mechanism(newContent)

                                  field.onChange(newContent)
                                }}
                                onChange={(newContent) => {}}
                              />
                            )}
                          />

                          {errors.Delivery_Mechanism?.type === 'required' && (
                            <p className='alert_danger' role='alert'>
                              This is required field
                            </p>
                          )}
                        </div>
                        <div>
                          <label class='required form-label'>Resources:</label>

                          <Controller
                            control={control}
                            name='resources'
                            defaultValue=''
                            rules={{required: true}}
                            render={({field}) => {
                              return (
                                <JoditEditor
                                  ref={field.ref}
                                  value={field.value}
                                  placeholder='Start typing'
                                  aria-invalid={errors.resources ? 'true' : 'false'}
                                  tabIndex={1} // tabIndex of textarea
                                  onBlur={(newContent) => {
                                    if (newContent === '<p><br></p>') newContent = ''
                                    SetResources(newContent)

                                    field.onChange(newContent)
                                  }}
                                  onChange={(newContent) => {}}
                                />
                              )
                            }}
                          />
                          {errors.resources?.type === 'required' && (
                            <p className='alert_danger' role='alert'>
                              This is required field
                            </p>
                          )}
                        </div>
                        <div>
                          <label class='required form-label'>Duration:</label>

                          <Controller
                            control={control}
                            name='duration'
                            defaultValue=''
                            rules={{required: true}}
                            render={({field}) => {
                              return (
                                <JoditEditor
                                  ref={field.ref}
                                  value={field.value}
                                  placeholder='Start typing'
                                  aria-invalid={errors.duration ? 'true' : 'false'}
                                  tabIndex={1} // tabIndex of textarea
                                  onBlur={(newContent) => {
                                    if (newContent === '<p><br></p>') newContent = ''
                                    SetDuration(newContent)

                                    field.onChange(newContent)
                                  }}
                                  onChange={(newContent) => {}}
                                />
                              )
                            }}
                          />
                          {errors.duration?.type === 'required' && (
                            <p className='alert_danger' role='alert'>
                              This is required field
                            </p>
                          )}
                        </div>
                        <div>
                          <label class='required form-label'>Description</label>

                          <Controller
                            control={control}
                            name='description'
                            defaultValue=''
                            rules={{required: true}}
                            render={({field}) => {
                              return (
                                <JoditEditor
                                  ref={field.ref}
                                  value={field.value}
                                  placeholder='start typing'
                                  aria-invalid={errors.description ? 'true' : 'false'}
                                  tabIndex={1} // tabIndex of textarea
                                  onBlur={(newContent) => {
                                    if (newContent === '<p><br></p>') newContent = ''
                                    Setdescription(newContent)

                                    field.onChange(newContent)
                                  }}
                                  onChange={(newContent) => {}}
                                />
                              )
                            }}
                          />
                          {errors.description?.type === 'required' && (
                            <p className='alert_danger' role='alert'>
                              This is required field
                            </p>
                          )}
                        </div>

                        {/* {isCategory3 && ( */}
                          <div>
                            <label class='required form-label'> What’s included: </label>

                            <Controller
                              control={control}
                              name='learnings'
                              defaultValue=''
                              rules={{required: true}}
                              render={({field}) => {
                                return (
                                  <JoditEditor
                                    ref={field.ref}
                                    value={field.value}
                                    placeholder='start typing'
                                    aria-invalid={errors.learnings ? 'true' : 'false'}
                                    tabIndex={1} // tabIndex of textarea
                                    onBlur={(newContent) => {
                                      if (newContent === '<p><br></p>') newContent = ''
                                      SetLearnings(newContent)

                                      field.onChange(newContent)
                                    }}
                                    onChange={(newContent) => {}}
                                  />
                                )
                              }}
                            />
                            {errors.learnings?.type === 'required' && (
                              <p className='alert_danger' role='alert'>
                                This is required field
                              </p>
                            )}
                          </div>
                        {/* )} */}
                        {/* {isCategory1or2 && ( */}
                          <div>
                            <label class='required form-label'>Key Topics Covered:</label>

                            <Controller
                              control={control}
                              name='topicCovered'
                              defaultValue=''
                              rules={{required: true}}
                              render={({field}) => {
                                return (
                                  <JoditEditor
                                    ref={field.ref}
                                    value={field.value}
                                    placeholder='start typing'
                                    aria-invalid={errors.topicCovered ? 'true' : 'false'}
                                    tabIndex={1} // tabIndex of textarea
                                    onBlur={(newContent) => {
                                      if (newContent === '<p><br></p>') newContent = ''
                                      SetTopicCovered(newContent)

                                      field.onChange(newContent)
                                    }}
                                    onChange={(newContent) => {}}
                                  />
                                )
                              }}
                            />
                            {errors.topicCovered?.type === 'required' && (
                              <p className='alert_danger' role='alert'>
                                This is required field
                              </p>
                            )}
                          </div>
                        {/* )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class='d-flex justify-content-end'>
                <Link
                  to='/Allcourse'
                  id='kt_ecommerce_add_product_cancel'
                  class='btn cybercancelbtn me-5'
                >
                  Cancel
                </Link>

                <button
                  type='submit'
                  id='kt_ecommerce_add_product_submit'
                  className='btn cyberbtnallpage'
                  // onClick={() => {
                  //   handleSubmit(formSubmit(data))
                  // }}
                >
                  <span class='indicator-label'>Save Changes</span>
                  <span class='indicator-progress'>
                    Please wait...
                    <span class='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddCourse
