import React, {useState, useEffect} from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import comptia from '../../assets/images/comptia.png'
import {PreviewDetailsAction} from '../../Redux/Action/courseAction'
import {useDispatch, useSelector} from 'react-redux'
import axios from 'axios'
const Comptiapage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const dispatch = useDispatch()

  const previewdetails = useSelector((state) => {
    // debugger;
    return state.login.getpreviewdetailslist
  })

  // console.log(previewdetails, 'previewdetailspreviewdetailspreviewdetailspreviewdetails')

  var newURL = window.location

  var splitURL = newURL.href.split('/')
  // console.log(splitURL)

  const courseslug = splitURL[4]

  useEffect(() => {
    // debugger
    dispatch(PreviewDetailsAction(courseslug))
  }, [dispatch])

  let cdata = ''

  const [formDataState, setFormData] = useState({
    id_token: '',
    state: '',
    redirect_uri: '',
    // Add other form fields here
  })

  useEffect(() => {
    LaunchCourse()
  }, [])

  const LaunchCourse = () => {
    // debugger;
    axios
      .post('https://backend.cyberheads.in/launch-course')
      .then((response) => {
        handleApiResponse(response.data)
      })
      .catch((error) => {
        console.log('LTI launch error:', error)
      })
  }

  const handleApiResponse = (response) => {
    setFormData({
      id_token: response.id_token,
      state: response.state,
      redirect_uri: response.redirect_uri,
      // Set values for other form fields based on the response
    })
  }

  return (
    <div>
      <div
        className='navbar navbar-expand-lg d-flex justify-content-between'
        style={{backgroundColor: 'black'}}
      >
        <Navbar />
      </div>

      <div>
        <div className='row' style={{paddingBottom: '6rem'}}>
          <div className='col-md-2'></div>
          <div className='col-md-4'>
            <img src={comptia} alt='img' style={{borderRadius: '10px', width: '70%'}} />
          </div>
          {Object.entries(previewdetails).map(([key, course], i) => {
            if (key === 'data') {
              return Object.entries(course).map(([innerKey, item], i) => {
                if (innerKey === 'title' || innerKey === 'description') {
                  return (
                    <div className='col-md-4' key={i}>
                      {innerKey === 'title' && <h2 style={{color: 'red'}}>{item}</h2>}
                      {innerKey === 'description' && (
                        <div className='course-list-info__description text-gray-600 fw-bold bg-light bg-opacity-75 pt-3'>
                          {item}
                        </div>
                      )}
                    </div>
                  )
                }
                return null // Handle other keys as needed or skip them
              })
            }
            return null // Handle other keys in previewdetails as needed or skip them
          })}

          <div className='col-md-2'></div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Comptiapage
