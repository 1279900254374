import React, {useState, useEffect, useRef} from 'react'
import 'primereact/resources/themes/lara-light-indigo/theme.css' // theme
import 'primereact/resources/primereact.css' // core css
import {useDispatch, useSelector} from 'react-redux'

import {FilterMatchMode} from 'primereact/api'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {InputText} from 'primereact/inputtext'

import {getAllinvoices} from '../../../Redux/Action/instructorAction'

import {Tooltip} from 'antd'

import {updateInstructor} from '../../../Redux/Action/instructorAction'
import {Button} from 'primereact/button'
import {Toolbar} from 'primereact/toolbar'
import {FaFilePdf} from 'react-icons/fa'
import ExcelJS from 'exceljs'
export default function Invoices() {
  const dispatch = useDispatch()
  const dt = useRef(null)

  function formatDateString(inputDateStr) {
    const inputDate = new Date(inputDateStr)
    const day = inputDate.getDate()
    const month = inputDate.getMonth() + 1
    const year = inputDate.getFullYear()
    const formattedDay = day < 10 ? '0' + day : day
    const formattedMonth = month < 10 ? '0' + month : month
    const formattedDate = formattedDay + '/' + formattedMonth + '/' + year
    return formattedDate
  }

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({selectionOnly})
  }

  const exportExcel = (selectionOnly) => {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('User Data')

    // Define columns for the Excel sheet
    const columns = [
      {header: 'First Name', key: 'FirstName'},
      {header: 'Last Name', key: 'Surname'},
      {header: 'Raise Date', key: 'raisedate'},
      {header: 'Amount', key: 'Amount'},
      {header: 'Status', key: 'Payment_status'},
    ]
 
    // Add the columns to the worksheet
    worksheet.columns = columns

    // Export selected data or all data based on 'selectionOnly' flag
    const dataToExport = selectionOnly ? allInvoice : allInvoice

    // Add data to the worksheet
    dataToExport.forEach((item) => {
     
      worksheet.addRow(item)
    })

    // Generate a unique filename
    const fileName = `user_data_${new Date().getTime()}.xlsx`

    // Create a blob from the workbook and save it as a file
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = fileName
      a.click()
      window.URL.revokeObjectURL(url)
    })
  }
  const [selectedOptions, setSelectedOptions] = useState('')

  const [filters, setFilters] = useState({
    global: {value: null, matchMode: FilterMatchMode.CONTAINS},
    UserName: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
    title: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
    Amount: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
    status: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
  })

  const [loading, setLoading] = useState(true)
  const [globalFilterValue, setGlobalFilterValue] = useState('')

  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    let _filters = {...filters}

    _filters['global'].value = value

    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const renderHeader = () => {
    return (
      <div className='flex justify-content-end'>
        <span className='p-input-icon-left'>
          <i className='pi pi-search' />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder='Keyword Search'
          />
        </span>
      </div>
    )
  }

  const header = renderHeader()

  const allInvoice = useSelector((state) => {
    return state.login.getAllinvoices_list
  })

  useEffect(() => {
    dispatch(getAllinvoices()).then(() => {
      setLoading(false)
    })
  }, [dispatch])

  const rows = allInvoice.map((invoice, index) => {
   
    const openPdfInPopup = (pdfLink) => {
      const width = 800
      const height = 600
      const left = window.innerWidth / 2 - width / 2
      const top = window.innerHeight / 2 - height / 2

      window.open(pdfLink, 'PDF_Popup', `width=${width},height=${height},left=${left},top=${top}`)
    }
    const fullName =
      invoice.FirstName && invoice.Surname
        ? invoice.FirstName + ' ' + invoice.Surname
        : 'User Deleted'

    return {
      id: invoice.id,
      SNo: invoice.id,
      UserName: fullName,
      title: invoice.title,
      status: invoice.Payment_status,
      Amount: invoice.Amount,
      created_at: formatDateString(invoice.created_at),
      updated_at: formatDateString(invoice.updated_at),
      raisedate: formatDateString(invoice.raisedate),
      pdfLink: invoice.pdfLink,
    }
  })

  const handleOptionChange = (e, rowData) => {
    const selectedValue = e.target.value

    localStorage.setItem(`paymentStatus_${rowData.id}`, selectedValue)

    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [rowData.id]: selectedValue,
    }))

    const formData = new FormData()
    formData.append('paymentStatus', selectedValue)

    dispatch(updateInstructor(formData, rowData.id))
  }
  useEffect(() => {
    const savedPaymentStatuses = {}
    allInvoice.forEach((invoice) => {
      const savedStatus = localStorage.getItem(`paymentStatus_${invoice.id}`)
      savedPaymentStatuses[invoice.id] = savedStatus || 'unpaid'
    })

    setSelectedOptions(savedPaymentStatuses)
  }, [allInvoice])
  const rightToolbarTemplate = () => {
    return (
      <>
        <Button
          label='CSV'
          icon='pi pi-upload'
          className='p-button-secondary cyberbtnallpage'
          onClick={() => exportCSV(false)}
        />
        <div style={{margin: '0 10px'}}></div>
        <Button
          label='Excel'
          icon='pi pi-upload'
          className='p-button-secondary cyberbtnallpage'
          onClick={() => exportExcel(false)}
        />
      </>
    )
  }
  const actionTemplate = (rowData) => {
    return (
      <div style={{display: 'flex', gap: '0px', marginLeft: '-2rem'}}>
        <div key={rowData.id}>
          <select
            style={{
              border: '1px solid rgb(204, 204, 204)',
              borderRadius: '4px',
              padding: '5px',
            }}
            value={selectedOptions[rowData.id] || 'unpaid'}
            onChange={(e) => handleOptionChange(e, rowData)}
          >
            <option value='unpaid'>Unpaid</option>
            <option value='paid'>Paid</option>
          </select>
        </div>

        <Tooltip title='Download PDF'>
          <a
            href={rowData.pdfLink}
            rel='noopener noreferrer'
            style={{border: '0', padding: '5px', background: 'white'}}
          >
            <span style={{fontSize: '18px', paddingRight: '5px', color: 'rgb(126, 130, 153)'}}>
              <FaFilePdf style={{fontSize: '30px', color: 'red'}} />
            </span>
          </a>
        </Tooltip>
      </div>
    )
  }

  return (
    <div className='card'>
      <Toolbar className='mb-4' right={rightToolbarTemplate}></Toolbar>
      <DataTable
        value={rows}
        paginator
        ref={dt}
        rows={10}
        dataKey='id'
        filters={filters}
        tableStyle={{minWidth: '100%'}}
        filterDisplay='row'
        globalFilterFields={['UserName', 'raisedate', 'Amount', 'status']}
        header={header}
        emptyMessage='No Invoice found.'
        rowClassName='table-row'
      >
        <Column field='SNo' header='#' style={{minWidth: '12rem'}} />

        <Column
          field='UserName'
          header='Username'
          filter
          filterPlaceholder='Search by User Name'
          style={{minWidth: '12rem'}}
        />
        <Column field='created_at' header='Start Date' style={{minWidth: '12rem'}} />
        <Column
          field='updated_at'
          header='End Date'
          filterPlaceholder='Search by end date'
          style={{minWidth: '12rem'}}
        />
        <Column field='raisedate' header='Raise Date' style={{minWidth: '12rem'}} />

        <Column
          field='status'
          header='Status'
          filter
          filterPlaceholder='Search by status'
          style={{minWidth: '12rem'}}
        />
        <Column
          field='Amount'
          header='Amount'
          filter
          filterPlaceholder='Search by Amount'
          style={{minWidth: '12rem'}}
        />

        <Column field='id' header='Action' body={actionTemplate} style={{marginLeft: '-12rem'}} />
      </DataTable>
    </div>
  )
}
