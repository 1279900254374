import React, {useEffect, useState} from 'react'

import AddIcon from '@mui/icons-material/Add'
import {useDispatch, useSelector} from 'react-redux'

import {GetallCareersAction, CareerDeleteAction} from '../../../Redux/Action/careerAction'

import {FaEdit, FaTrash} from 'react-icons/fa'

import {AiOutlineLeft, AiOutlineRight} from 'react-icons/ai'
import {Link, useNavigate} from 'react-router-dom'
import {Tooltip} from 'antd'
import {FilterMatchMode} from 'primereact/api'
import {MultiSelect} from 'primereact/multiselect'
import {Toolbar} from 'primereact/toolbar'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'

export default function AllCareers() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [filters, setFilters] = useState({
    global: {value: null, matchMode: FilterMatchMode.CONTAINS},
    title: {value: null, matchMode: FilterMatchMode.CONTAINS},
    Career_Category: {value: null, matchMode: FilterMatchMode.IN},
    status: {value: null, matchMode: FilterMatchMode.IN},
  })

  const columns = [
    {field: 'title', header: 'Title'},
    {field: 'Career_Category', header: 'Category'},
    {field: 'status', header: 'Status'},
  ]

  const onColumnToggle = (event) => {
    let selectedColumns = event.value
    let orderedSelectedColumns = columns.filter((col) =>
      selectedColumns.some((sCol) => sCol.field === col.field)
    )

    setVisibleColumns(orderedSelectedColumns)
  }

  const [loading, setLoading] = useState(true)
  const [globalFilterValue, setGlobalFilterValue] = useState('')
  const [visibleColumns, setVisibleColumns] = useState(columns)

  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    let _filters = {...filters}

    _filters['global'].value = value

    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const careers = useSelector((state) => {
    const publishedCareers = state.login.getcareersslicelist?.careers || []

    const publishedMapped = publishedCareers.map((item, index) => ({
      ...item,
      SNo: index + 1,
    }))

    return publishedMapped
  })
  //   const Allcourse = useSelector((state) => {
  //   return state.login.getcareersslicelist
  // })

  // console.log(Allcourse,"AllcourseAllcourseAllcourseAllcourse")

  useEffect(() => {
    dispatch(GetallCareersAction()).then(() => {
      setLoading(false)
    })
  }, [dispatch])

  const deleteHandler = (props) => {
    dispatch(CareerDeleteAction(props))
  }

  const rightToolbarTemplate = () => {
    return (
      <div class='card-toolbar'>
        <div class='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
          <Link to='/addWebCareer' type='button' class='btn cyberbtnallpage'>
            <AddIcon /> Add Career
          </Link>
        </div>
      </div>
    )
  }

  const leftToolbarTemplate = () => {
    return (
      <MultiSelect
        value={visibleColumns}
        options={columns}
        optionLabel='header'
        onChange={onColumnToggle}
        className='w-full sm:w-20rem'
        display='chip'
      />
    )
  }

  const actionTemplate = (rowData) => {
    return (
      <div style={{display: 'flex', gap: '0px', marginLeft: '-2rem'}}>
        <div style={{fontSize: '15px'}}>
          <Tooltip title='Edit'>
            <Link to={`/webCareer/edit/${rowData.id}`}>
              <span
                style={{
                  fontSize: '18px',
                  paddingRight: '5px',
                  color: 'rgb(126, 130, 153)',
                }}
              >
                <FaEdit />
              </span>
            </Link>
          </Tooltip>
          <Tooltip title='Delete'>
            <Link to='#' onClick={() => deleteHandler(rowData.id)}>
              <span
                style={{
                  fontSize: '18px',
                  paddingRight: '5px',
                  color: 'rgb(126, 130, 153)',
                }}
              >
                <FaTrash />
              </span>
            </Link>
          </Tooltip>
        </div>

        
      </div>
    )
  }

  const CategoryFilter = (props) => {
    const options = ['Junior Roles', 'Mid Roles', 'Senior/Exec Roles']

    return (
      <MultiSelect
        value={props.value}
        options={options}
        onChange={(e) => {
          props.filterApplyCallback(e.value)
        }}
        optionLabel=''
        placeholder='Any'
        className='p-column-filter'
        maxSelectedLabels={1}
        style={{minWidth: '10rem'}}
      />
    )
  }

  const StatusFilter = (props) => {
    const options = ['Published', 'Draft']

    return (
      <MultiSelect
        value={props.value}
        options={options}
        onChange={(e) => {
          props.filterApplyCallback(e.value)
        }}
        optionLabel=''
        placeholder='Any'
        className='p-column-filter'
        maxSelectedLabels={1}
        style={{minWidth: '10rem'}}
      />
    )
  }

  return (
    <div>
      <Toolbar className='mb-4' left={leftToolbarTemplate} right={rightToolbarTemplate}/>
      <DataTable
        value={careers}
        paginator
        rows={20}
        tableStyle={{minWidth: '100%'}}
        dataKey='id'
        filters={filters}
        filterDisplay='row'
        className='card'
        globalFilterFields={['title', 'Career_Category', 'status']}
        emptyMessage='No career found.'
        rowClassName='table-row'
      >
        <Column field='SNo' header='#' sortable />
        {visibleColumns.map((col) => (
          <Column
            sortable
            key={col.field}
            field={col.field}
            header={col.header}
            filter
            style={{minWidth: '15rem'}}
            filterPlaceholder={`Search by ${col.header}`}
            filterElement={(() => {
              switch (col.field) {
                case 'Career_Category':
                  return CategoryFilter
                case 'status':
                  return StatusFilter
                default:
                  return ''
              }
            })()}
          />
        ))}
        <Column
          field='careerpaths'
          header='Career Paths'
          body={(rowData) => rowData.careerpaths.slice(0, 50)}
          filterPlaceholder='Search by Career Paths'
          style={{minWidth: '12rem'}}
        />
        <Column
          field='id'
          header='Action'
          body={actionTemplate}
          exportable={false}
          style={{marginLeft: '-12rem'}}
        />
      </DataTable>
    </div>
  )
}

// const AllCareers = () => {
//   const dispatch = useDispatch()
//   // const [modalShow, setModalShow] = React.useState(false)
//   // const navigate = useNavigate()

//   // const id = useParams()

//   const Allcourse = useSelector((state) => {
//     return state.login.getcareersslicelist
//   })

//   const allcourse = Allcourse.careers || []

//   // const allcourse = [...(Allcourse.Published || []), ...(Allcourse.Draft || [])]

//   useEffect(() => {
//     dispatch(GetallCareersAction())
//   }, [dispatch])

//   const deleteHandler = (props) => {
//     dispatch(CareerDeleteAction(props))
//   }

//   function nextPage() {
//     if (currentPage !== nPage) {
//       setCurrentPage(currentPage + 1)
//     }
//   }

//   function prePage() {
//     if (currentPage !== 1) {
//       setCurrentPage(currentPage - 1)
//     }
//   }

//   function changeCPage(id) {
//     setCurrentPage(id)
//   }

//   const [currentPage, setCurrentPage] = useState(1)

//   const recordsPerPage = 10

//   const lastIndex = currentPage * recordsPerPage

//   const firstIndex = lastIndex - recordsPerPage

//   const records = allcourse.slice(firstIndex, lastIndex)

//   const nPage = Math.ceil(allcourse.length / recordsPerPage)

//   const number = [...Array(nPage + 1).keys()].slice(1)

//   return (
//     <div className='user-list'>
//       <div id='kt_app_content_container'>
//         <div class='card'>
//           <div className='card-header border-0 pt-6'>
//             <div className='card-title'>
//               <h3 className='fw-bold m-0'>Web Careers List</h3>
//             </div>
//             <div class='card-toolbar'>
//               <div class='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
//                 <Link to='/addWebCareer' type='button' class='btn cyberbtnallpage'>
//                   <AddIcon /> Add Web Career
//                 </Link>
//               </div>
//             </div>
//           </div>
//           <div class='card-body py-4'>
//             <div className='table-responsive'>
//               <table class='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>
//                 <thead>
//                   <tr class='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
//                     <th>#</th>
//                     <th class='min-w-125px'>Title</th>
//                     <th class='min-w-125px'>Category</th>
//                     <th class='min-w-125px'>Career Path</th>
//                     <th class='min-w-125px'>Status</th>
//                     <th class='min-w-125px'>Action</th>
//                   </tr>
//                 </thead>

//                 <tbody class='text-gray-600 fw-semibold'>
//                   {records.map((career, index) => {
//                     return (
//                       <tr key={index}>
//                         <td>{firstIndex + index + 1}</td>
//                         <td style={{color: '#7e8299', fontSize: '14px'}}>{career.title}</td>
//                         <td>
//                           {career.category === 1
//                             ? 'Junior Roles'
//                             : career.category === 2
//                             ? 'Mid Roles'
//                             : 'Senior/Exec Roles'}
//                         </td>
//                         <td>
//                           {career.careerpaths && career.careerpaths.length > 0
//                             ? career.careerpaths.join(', ')
//                             : 'No career paths'}
//                         </td>

//                         <td>{career.status}</td>

//                         <td>
//                           <div style={{fontSize: '15px'}}>
//                             <Tooltip title='Edit'>
//                               <Link to={`/webCareer/edit/${career.id}`}>
//                                 <span
//                                   style={{
//                                     fontSize: '18px',
//                                     paddingRight: '5px',
//                                     color: 'rgb(126, 130, 153)',
//                                   }}
//                                 >
//                                   <FaEdit />
//                                 </span>
//                               </Link>
//                             </Tooltip>
//                             <Tooltip title='Delete'>
//                               <Link to='#' onClick={() => deleteHandler(career.id)}>
//                                 <span
//                                   style={{
//                                     fontSize: '18px',
//                                     paddingRight: '5px',
//                                     color: 'rgb(126, 130, 153)',
//                                   }}
//                                 >
//                                   <FaTrash />
//                                 </span>
//                               </Link>
//                             </Tooltip>
//                           </div>

//                           <div
//                             class='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
//                             data-kt-menu='true'
//                           >
//                             <div class='menu-item px-3'></div>
//                           </div>
//                         </td>
//                       </tr>
//                     )
//                   })}
//                 </tbody>
//               </table>
//             </div>
//             <nav>
//               <ul className='pagination'>
//                 <li className='page-item'>
//                   <Link to='#' className='page-link' onClick={prePage}>
//                     <AiOutlineLeft />
//                   </Link>
//                 </li>
//                 {number.map((n, i) => (
//                   <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
//                     <Link to='#' className='page-link' onClick={() => changeCPage(n)}>
//                       {n}
//                     </Link>
//                   </li>
//                 ))}
//                 <li className='page-item'>
//                   <Link to='#' className='page-link' onClick={nextPage}>
//                     <AiOutlineRight />
//                   </Link>
//                 </li>
//               </ul>
//             </nav>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default AllCareers
