import swal from 'sweetalert'
import axios from 'axios'

import 'react-toastify/dist/ReactToastify.css'

import {
  getblogsslice,
  getblogsbyidslice,
  getblogsbyslugslice,
  getpublishedblogsslice,
} from '../Slice/authSlice'

export const CreateBlogAction = (formData, navigate) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',

        Authorization: `Bearer ${token}`,
      },
    }

    const createcourse = process.env.REACT_APP_BACKEND_API + '/createBlog'

    const res = await axios.post(createcourse, formData, config)

    if (res.status === 200) {
      swal({
        title: 'Success',
        text: 'Blog Created',
        icon: 'success',
        buttons: {
          cancel: {
            text: 'OK',
            value: null,
            visible: true,
            className: 'swal-button--confirm cyberbtnallpage', 
          },
        },
      })
      navigate('/all_blog')
    }
    dispatch(res())
  } catch (e) {
    // console.log(e)
  }
}
export const GetBlogAction = (blogId) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getcoursebyid = `${process.env.REACT_APP_BACKEND_API}/getBlog/${blogId}`

    const res = await axios.get(getcoursebyid, config)

    await dispatch(getblogsbyidslice(res))
  } catch (e) {
    // console.log(e)
  }
}

export const GetBlogBySlugAction = (blogSlug) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        //Authorization: `Bearer ${token}`,
      },
    }

    const getblogbyid = `${process.env.REACT_APP_BACKEND_API}/getBlogBySlug/${blogSlug}`

    const res = await axios.get(getblogbyid)

    await dispatch(getblogsbyslugslice(res))
  } catch (e) {
    // console.log(e)
  }
}

export const EditBlogAction = (formData, careerId, navigate) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const updateCourseid = `${process.env.REACT_APP_BACKEND_API}/editBlog/${careerId}`
    const res = await axios.put(updateCourseid, formData, config)

    if (res.status === 200) {
      swal({
        title: 'Success',
        text: 'Blog Updated',
        icon: 'success',
        buttons: {
          cancel: {
            text: 'OK',
            value: null,
            visible: true,
            className: 'swal-button--confirm cyberbtnallpage', 
          },
        },
      })
      navigate('/all_blog')
    }
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e.response.data.message, 'error')
    }
  }
}

export const GetallBlogsAction = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getBlogs = process.env.REACT_APP_BACKEND_API + '/getAllBlog'
    const res = await axios.get(getBlogs, config)

    await dispatch(getblogsslice(res))
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e?.response?.data?.message, 'error')
    }
  }
}

export const GetPublishedBlogsAction = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getBlogs = process.env.REACT_APP_BACKEND_API + '/getAllPublishedBlog'
    const res = await axios.get(getBlogs, config)

    await dispatch(getpublishedblogsslice(res))
  } catch (e) {
    if (e?.response?.data.message) {
      swal('error', e?.response?.data?.message, 'error')
    }
  }
}

export const BlogDeleteAction = (careerId) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const confirmResult = await swal({
      title: 'Delete Blog',
      text: 'Are you sure you want to delete this?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          value: null,
          visible: true,
          className: 'swal-button--cancel cybercancelbtn', 
        },
        confirm: {
          text: 'Delete',
          value: true,
          visible: true,
          className: 'swal-button--confirm swal-button--danger cyberbtnallpage', 
        },
      },
      dangerMode: true,
    })
    if (confirmResult) {
      const deletecourse = `${process.env.REACT_APP_BACKEND_API}/deleteBlog/${careerId}`
      const res = await axios.delete(deletecourse, config)

      if (res.status === 200) {
        swal({
          title: 'Success',
          text: 'Blog Deleted',
          icon: 'success',
          buttons: {
            cancel: {
              text: 'OK',
              value: null,
              visible: true,
              className: 'swal-button--confirm cyberbtnallpage', 
            },
          },
        }).then(() => {
          window.location.reload()
        })
      }
    }
  } catch (e) {
    // console.log(e)
  }
}
