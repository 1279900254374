import swal from 'sweetalert'
import axios from 'axios'

import 'react-toastify/dist/ReactToastify.css'
import {userlistslice, getAllRolesslice, deletedUserListSlice, paymentDetailSlice, payDetailSlice} from '../Slice/authSlice'

export const AddInstructorAdminAction = (formData, navigate) => async (dispatch) => {
  try {
    debugger
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const addInstructor = `${process.env.REACT_APP_BACKEND_API}/instructoradd`

    const res = await axios.post(addInstructor, formData, config)

    if (res.status === 200) {
      swal('Success', res.data.message, 'success')
      navigate('/alluserlist')
    }
    dispatch(res())
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e?.response?.data?.message, 'error')
    }
  }
}

export const UserListAction = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const ulisturl = process.env.REACT_APP_BACKEND_API + '/getuserdetails'
    const res = await axios.get(ulisturl, '', config)

    await dispatch(userlistslice(res))
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e?.response?.data?.message, 'error')
    }
  }
}

export const DeleteUserAction = (props) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const confirmResult = await swal({
      title: 'Delete User',
      text: 'Are you sure you want to delete this user?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          value: null,
          visible: true,
          className: 'swal-button--cancel cybercancelbtn',
        },
        confirm: {
          text: 'Delete',
          value: true,
          visible: true,
          className: 'swal-button--confirm swal-button--danger cyberbtnallpage',
        },
      },
      dangerMode: true,
    })

    if (confirmResult) {
      const deleteuser = `${process.env.REACT_APP_BACKEND_API}/deleteUser/${props}`
      const res = await axios.delete(deleteuser, config)

      if (res.status === 200) {
        swal({
          title: 'Success',
          text: 'User deleted successfully!',
          icon: 'success',
          buttons: {
            cancel: {
              text: 'OK',
              value: null,
              visible: true,
              className: 'swal-button--confirm cyberbtnallpage',
            },
          },
        })
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export const DeleteBulkUserAction = (meetingIds) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const confirmResult = await swal({
      title: 'Delete User',
      text: 'Are you sure you want to delete this user?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          value: null,
          visible: true,
          className: 'swal-button--cancel cybercancelbtn',
        },
        confirm: {
          text: 'Delete',
          value: true,
          visible: true,
          className: 'swal-button--confirm swal-button--danger cyberbtnallpage',
        },
      },
      dangerMode: true,
    })

    if (confirmResult) {
      const deleteuser = `${process.env.REACT_APP_BACKEND_API}/deleteUser/${meetingIds}`
      const res = await axios.delete(deleteuser, config)

      if (res.status === 200) {
        swal('Success', res.data.message, 'success').then(() => {
          window.location.reload()
        })
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export const AllRolesAction = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getuserid = `${process.env.REACT_APP_BACKEND_API}/getAllRoles`

    const res = await axios.get(getuserid, config, '')

    await dispatch(getAllRolesslice(res))
  } catch (e) {}
}

export const DeletedUserListAction = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const ulisturl = process.env.REACT_APP_BACKEND_API + '/deletedUser'
    const res = await axios.get(ulisturl, '', config)

    await dispatch(deletedUserListSlice(res))
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e?.response?.data?.message, 'error')
    }
  }
}

export const permanentlydeleteUser = (id) => async (dispatch) => {
  try {
    debugger
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const confirmResult = await swal({
      title: 'Permanently Delete User',
      text: 'Are you sure you want to delete this user permanently?',
      icon: 'warning',
      buttons: ['Cancel', 'Delete'],
      dangerMode: true,
    })

    if (confirmResult) {
      const deleteuser = `${process.env.REACT_APP_BACKEND_API}/permanentlydeleteUser/${id}`
      const res = await axios.delete(deleteuser, config)

      if (res.status === 200) {
        swal({
          title: 'Success',
          text: 'User Deleted Permanently',
          icon: 'success',
          buttons: {
            cancel: {
              text: 'OK',
              value: null,
              visible: true,
              className: 'swal-button--confirm cyberbtnallpage',
            },
          },
        })
      }
    }
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e?.response?.data?.message, 'error')
      window.reload('/alluserlist')
    }
  }
}

export const AddTemporaryUserAction = (formData, navigate) => async (dispatch) => {
  try {
    debugger
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const addtempuser = `${process.env.REACT_APP_BACKEND_API}/tempusers`

    const res = await axios.post(addtempuser, formData, config)

    if (res.status === 200) {
      // swal('Success', res.data.message, 'success')
      navigate('/payment')
    }
    //dispatch(res())
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e?.response?.data?.message, 'error')
    }
    console.log('Error: ', e)
  }
}

export const DeletedCVAction = (id) => async () => {
  try {
    debugger
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const deleteCV = process.env.REACT_APP_BACKEND_API + `/cvdelete/${id}`
    const res = await axios.delete(deleteCV, '', config)
    if (res.status === 200) {
      swal('Success', res.data.message, 'success')
    }
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e?.response?.data?.message, 'error')
    }
  }
}

export const paymentDetailsbyIdAction = (id) => async (dispatch) => {
  try {
    debugger
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const paymentDetail = process.env.REACT_APP_BACKEND_API + `/paymentDetail/${id}`
    const res = await axios.get(paymentDetail, '', config)
    await dispatch(paymentDetailSlice(res))
  } catch (e) {
    console.log('Error:', e)
  }
}


export const payDetailsbyIdAction = (id) => async (dispatch) => {
  try {
    debugger
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const payDetail = process.env.REACT_APP_BACKEND_API + `/paydetailbyId/${id}`
    const res = await axios.get(payDetail, '', config)
    await dispatch(payDetailSlice(res))
  } catch (e) {
    console.log('Error:', e)
  }
}


export const Updatepaydetail = (id, formData) => async (dispatch) => {
  try {
    debugger
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const Updatepaydetail = process.env.REACT_APP_BACKEND_API + `/updatepaydetail/${id}`
    const res = await axios.put(Updatepaydetail, formData, config)
    if (res.status === 200) {
      swal('Success', res.data.message, 'success')
      window.location.reload()
    }
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e?.response?.data?.message, 'error')
    }
  }
}