import React from 'react'

import './previewlearning.css'

import {Button} from 'react-bootstrap'

import Previewlearningbody from './Previewlearningbody/Previewlearningbody'

import Footer from '../Footer/Footer'

import {Link} from 'react-router-dom'

import {useDispatch, useSelector} from 'react-redux'

import Navbar from '../Navbar/Navbar'

const Previewlearning = () => {
  var newURL = window.location

  var splitURL = newURL.href.split('/')

  const course_code = splitURL[4]

  const previewdetails = useSelector((state) => state.login.getpreviewdetailslist)
  // Get course_code from local storage

  // Ensure course_code is not null
  if (!course_code) {
    // Handle the case where course_code is not available in local storage
  }

  let cdata = {} // Initialize cdata

  // Iterate through 'previewdetails' and extract 'data' property
  for (const [key, course] of Object.entries(previewdetails)) {
    if (key === 'data') {
      cdata = course
    }
  }

  // Set values in local storage
  localStorage.setItem('paidPrice', cdata.paid_price)
  localStorage.setItem('coursecode', course_code)
  localStorage.setItem('title', cdata.title)
  const paidPrice = localStorage.getItem('paid_price')

  return (
    <div>
      <div

      // style={{backgroundColor: 'black', paddingTop: '3rem'}}
      >
        <Navbar />
      </div>
      <div>
        <div className='Previewlearning_backgrounddd'>
          <div className='preview_sadowee'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-9'>
                  <div
                    className='BundleDeytails'
                    style={{
                      position: 'absolute',
                      top: '40%',
                      left: '35%',
                      transform: 'translate(-50%, -50%)',
                      textAlign: 'start',
                      color: 'white',
                      fontSize: '30px',
                    }}
                  >
                    <h1
                      className='banner-title'
                      style={{color: 'white', fontSize: '50px', width: '62%'}}
                    >
                      {cdata.title}
                    </h1>
                    <div></div>

                    <div>
                      {localStorage.getItem('authToken') ? (
                        <div className='preview_learning_btn'>
                          <Link to={`/insidebuycourse?/${course_code}`}>
                            <Button
                              className='start_btn'
                              style={{
                                background: '#ff763f',
                                fontSize: '15px',
                                textTransform: 'capitalize',
                              }}
                              type='submit'
                              onClick={paidPrice}
                            >
                              Buy this course
                            </Button>
                          </Link>
                        </div>
                      ) : (
                        <div className='preview_learning_btn'>
                          <Link to={`/signup?/${course_code}`}>
                            <Button
                              className='start_btn'
                              style={{
                                background: '#ff763f',
                                fontSize: '15px',
                                textTransform: 'capitalize',
                              }}
                              type='submit'
                              onClick={paidPrice}
                            >
                              Buy this course
                            </Button>
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="col-md-3" style={{ paddingTop: "10rem" }}>
               
                  <Button
                    className="buy_btn"
                    type="submit"
                    onClick={openModal}
                    style={{
                      border: "1px solid white",
                      fontSize: "15px",
                      textTransform: "capitalize",
                      background: "rgba(0,0,0,6)",
                      color: "white",
                      borderRadius: "2rem",
                    }}
                  >
                    <span>
                      <PlayArrowIcon style={{ fontSize: "30px" }} />
                    </span>
                    <span style={{ fontSize: "15px" }}>Preview</span>
                    {modal ? (
                      <section className="modal__bg">
                        <div className="modal__align">
                          {Object.entries(previewdetails).map(
                            ([key, course], i) => {
                              if (key == "data") {
                                cdata = course;
                              }
                              
                            
                            }
                          )}

                          {Object.entries(cdata).map(([key, item], i) => {
                            localStorage.getItem("paid_price", item.paid_price);
                            
                        
                            
                            const videoUrl = item.vdo_link;

                            return (
                              <div className="modal__content" modal={modal}>
                                <IoCloseOutline
                                  className="modal__close"
                                  arial-label="Close modal"
                                  onClick={setModal}
                                />
                                <div className="modal__video-align">
                                  {videoLoading ? (
                                    <div className="modal__spinner">
                                      <BiLoaderAlt
                                        className="modal__spinner-style"
                                        fadeIn="none"
                                      />
                                    </div>
                                  ) : null}

                                  <div className="col-md-12">

                                 
                                  <Media>
                                    <Video

                                    autoplay
                                      loading="visible"
                                      
                                      controls
                                      preload="true"

                                      style={{  width: "100%", height: "92%" , borderRadius: "20px"}}
                                    >
                                      <video
                                      autoplay
                                        loading="visible"
                                        poster="https://media-files.vidstack.io/poster-seo.png"
                                        src= {`${videoUrl}`}
                                        preload="none"
                                        data-video="0"
                                        style={{  width: "100%", height: "92%" ,borderRadius: "20px"}}
                                        controls
                                      />
                                    </Video>
                                  </Media>

                                  </div>
                                 
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </section>
                    ) : null}
                  </Button>
                </div> */}
            </div>
          </div>
        </div>

        <Previewlearningbody />
      </div>

      <Footer />
    </div>
  )
}

export default Previewlearning

//  <div className='col-md-9'>
//           <div className='row'>
//             <div className='col-md-4'>
//               <div className='jobTile jobtext-color'>
//                 <span className='jobtext-color'>Common Job Titles:</span>
//               </div>

//               {cdata && cdata.jobs && (
//                 <p
//                   dangerouslySetInnerHTML={{__html: cdata.jobs}}
//                   style={{fontFamily: 'Sora, sans-serif', fontSize: '16px', paddingTop: '20px'}}
//                 />
//               )}
//             </div>
//             <div className='col-md-4'>
//               <div className='jobTile jobtext-color'>
//                 <span className='jobtext-color'>Top Certifications Requested:</span>
//               </div>

//               {cdata && cdata.certifications && (
//                 <p
//                   dangerouslySetInnerHTML={{__html: cdata.certifications}}
//                   style={{fontFamily: 'Sora, sans-serif', fontSize: '16px', paddingTop: '20px'}}
//                 />
//               )}
//             </div>
//             <div className='col-md-4'>
//               <div className='jobTile jobtext-color'>
//                 <span className='jobtext-color'>Top Skills Requested:</span>
//               </div>

//               {cdata && cdata.skills && (
//                 <p
//                   dangerouslySetInnerHTML={{__html: cdata.skills}}
//                   style={{fontFamily: 'Sora, sans-serif', fontSize: '16px', paddingTop: '20px'}}
//                 />
//               )}
//             </div>
//           </div>
//         </div>
