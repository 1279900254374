import React, {useEffect, useState} from 'react'

import {useNavigate, Link} from 'react-router-dom'

import {useDispatch, useSelector} from 'react-redux'

import {useForm, Controller} from 'react-hook-form'
import JoditEditor from 'jodit-react'
import {GetBundleaction, UpdateBundleAction} from '../../../Redux/Action/bundleAction'
import {GetallPublishedLabsAction} from '../../../Redux/Action/challengeLabAction'
import {useParams} from 'react-router-dom'

const UpdateBundle = () => {
  const navigate = useNavigate()
  // const [description, Setdescription] = useState('')
  // const [jobOpportunity, SetjobOpportunity] = useState('')
  const [bundle_description, Setbundle_description] = useState('')
  const [includes, Setincludes] = useState('')
  const [benefits, Setbenefits] = useState('')
  const [selectedCheckboxes, setSelectedCheckboxes] = useState()
  const dispatch = useDispatch()

  const bundle_slug = useParams()
  const [file, setFile] = useState(null)
  const [pdf, setPdf] = useState(null)

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: {errors},
  } = useForm()

  useEffect(() => {
    dispatch(GetBundleaction(bundle_slug.bundle_slug))
    dispatch(GetallPublishedLabsAction())
  }, [dispatch])

  const getbundle = useSelector((state) => {
    return state.login.getbundlebyid
  })

  const labList = useSelector((state) => {
    return state.login.getPublishedLabsList
  })

  useEffect(() => {
    setValue('ImageLink', getbundle?.data && getbundle?.data[0].ImageLink)
    setValue('bundle_pdf', getbundle?.data && getbundle?.data[0].bundle_pdf)
    setValue('Name', getbundle?.data && getbundle?.data[0].Name)
    setValue('bundle_code', getbundle?.data && getbundle?.data[0].bundle_code)
    setValue('duration', getbundle?.data && getbundle?.data[0].duration)
    setValue('price', getbundle?.data && getbundle?.data[0].price)
    setValue('description', getbundle?.data && getbundle?.data[0].description)
    setValue('jobOpportunity', getbundle?.data && getbundle?.data[0].jobOpportunity)
    setValue('status', getbundle?.data && getbundle?.data[0].status)
    setValue('bundle_description', getbundle?.data && getbundle?.data[0].bundle_description)
    setValue('includes', getbundle?.data && getbundle?.data[0].includes)
    setValue('benefits', getbundle?.data && getbundle?.data[0].benefits)

    setSelectedCheckboxes(getbundle?.data && getbundle?.data[0].challengeLabs)
  }, [getbundle])

  const handleCheckboxChange = (id, value) => {
    setSelectedCheckboxes(id)
    // setSelectedCheckboxes((prevSelected) => {
    //   const isChecked = prevSelected.includes(id); // Check if the checkbox is already checked

    //   let updatedSelectedCheckboxes;
    //   if (isChecked) {
    //     // If the checkbox is already checked, remove its ID from the state
    //     updatedSelectedCheckboxes = prevSelected.filter((checkboxId) => checkboxId !== id);
    //   } else {
    //     // If the checkbox is not checked, add its ID to the state
    //     updatedSelectedCheckboxes = [...prevSelected, id];
    //   }

    //   console.log(updatedSelectedCheckboxes, "updatedSelectedCheckboxesupdatedSelectedCheckboxesupdatedSelectedCheckboxesupdatedSelectedCheckboxesupdatedSelectedCheckboxes")
    //   return updatedSelectedCheckboxes;
    // });
  }

  const onSubmit = (data) => {
    const formData = new FormData()
    if (data.poster_file[0]) {
      formData.append('poster_file', data.poster_file[0])
    }
    if (data.bundle_pdf) {
      formData.append('bundle_pdf', data.bundle_pdf[0])
    }
    formData.append('Name', data.Name)
    //formData.append('bundle_code', data.bundle_code)
    formData.append('duration', data.duration)
    formData.append('price', data.price)
    formData.append('description', data.description)
    formData.append('jobOpportunity', data.jobOpportunity)
    formData.append('status', data.status)
    formData.append('bundle_description', data.bundle_description)
    formData.append('includes', data.includes)
    formData.append('benefits', data.benefits)
    formData.append('challengeLabs', selectedCheckboxes)

    dispatch(UpdateBundleAction(formData, bundle_slug.bundle_slug, navigate))
  }

  const [defaultImageName, setDefaultImageName] = useState(
    getbundle?.data && getbundle?.data[0]?.imageName
  )

  useEffect(() => {
    if (getbundle?.data && getbundle?.data[0]?.imageName) {
      setDefaultImageName(getbundle.data[0].imageName)
    }
  }, [getbundle])

  const getFileNameFromLink = (link) => {
    const segments = link.split('/')
    return segments[segments.length - 1]
  }

  const [selectedFile, setSelectedFile] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')

  const handleFileChange = (e) => {
    const file = e.target.files[0]

    if (file) {
      const fileNameParts = file.name.split('.')
      const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase()

      if (fileExtension === 'pdf') {
        setSelectedFile(file)
        setErrorMessage('')
      } else {
        e.target.value = null
        setSelectedFile(null)
        setErrorMessage('Invalid file type. Please select a PDF file.')
      }
    }
  }

  return (
    <div>
      <div id='kt_app_content' className='app-content flex-column-fluid'>
        <form
          id='kt_ecommerce_add_product_form'
          className='form d-flex flex-column flex-lg-row'
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className='course_category col-md-4'>
            <div className='d-flex flex-column gap-7 gap-lg-10 w-100 mb-7 me-lg-10'>
              <div className='card card-flush py-4'>
                <div className='card-header'>
                  <div className='card-title'>
                    <h2>Upload Image</h2>
                  </div>

                  <div className='card-body'>
                    <div className='d-flex flex-column align-items-center'>
                      {getbundle?.data && getbundle?.data[0].ImageLink ? (
                        <div>
                          <img
                            src={getbundle?.data && getbundle?.data[0].ImageLink}
                            alt='Current Profile'
                            style={{maxWidth: '400px'}}
                            onError={(e) => {
                              console.error('Error loading image:', e)
                            }}
                          />
                        </div>
                      ) : (
                        <p>No image available</p>
                      )}

                      <input
                        type='file'
                        className='form-control mt-3'
                        {...register('poster_file')}
                        onChange={(e) => {
                          const selectedFile = e.target.files[0]
                          setFile(URL.createObjectURL(selectedFile))
                        }}
                      />

                      {file && (
                        <img
                          src={file}
                          alt='Selected'
                          style={{maxWidth: '400px', marginTop: '10px'}}
                        />
                      )}
                      <div className='form-text mt-2'>Allowed file types: png, jpg, jpeg.</div>
                      {errors.poster_file && (
                        <p className='alert_danger' role='alert'>
                          {errors.poster_file.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className='card-body pt-0'></div>
              </div>

              <div className='card card-flush py-4'>
                <div className='card-header'>
                  <div className='card-title'>
                    <h2>Upload Pdf</h2>
                  </div>
                  <div className='card-body'>
                    <div className='d-flex flex-column align-items-center'>
                      {getbundle?.data && getbundle?.data[0].bundle_pdf ? (
                        <p>{getFileNameFromLink(getbundle?.data[0].bundle_pdf)}</p>
                      ) : (
                        <p>No PDF available</p>
                      )}
                      <input
                        type='file'
                        className='form-control mt-3'
                        {...register('bundle_pdf')}
                        onChange={handleFileChange}
                      />
                      {errorMessage && (
                        <p style={{color: 'red', marginTop: '10px'}}>{errorMessage}</p>
                      )}
                      {errors.bundle_pdf && (
                        <p className='alert_danger' role='alert'>
                          {errors.bundle_pdf.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='card card-flush py-4'>
                <div className='card-header'>
                  <div className='card-title'>
                    <h2>Challenge Labs</h2>
                  </div>
                </div>
                <div class='card-body pt-0'>
                  <div className='mt-2 bg-white'>
                    {labList?.labs?.length > 0 ? (
                      <>
                        <p>Select Challenge Labs</p>
                        <ul>
                          {labList?.labs?.map((lab, index) => (
                            <li className='form-check' key={`bundle-${index}`}>
                              <input
                                type='radio'
                                className='form-check-input'
                                id={`checkboxBundle-${index}`}
                                value={lab.id}
                                checked={selectedCheckboxes == lab.id}
                                onChange={() => handleCheckboxChange(lab.id)}
                              />
                              <label
                                className='form-label mt-0'
                                htmlFor={`checkboxBundle-${index}`}
                              >
                                {lab.title}
                              </label>
                            </li>
                          ))}
                        </ul>
                      </>
                    ) : (
                      <p>No published labs found</p>
                    )}
                  </div>
                </div>
              </div>
              <div className='card card-flush py-4'>
                <div className='card-header'>
                  <div className='card-title'>
                    <h2>Status</h2>
                  </div>
                </div>
                <div className='card-body'>
                  <select
                    className='form-select mb-2'
                    data-control='select2'
                    data-hide-search='true'
                    data-placeholder='Select an option'
                    {...register('status', {
                      required: true,
                    })}
                    aria-invalid={errors.status ? 'true' : 'false'}
                  >
                    <option value='' disabled selected>
                      Select
                    </option>
                    <option value='Draft'>Draft</option>
                    <option value='Published'>Published</option>
                  </select>
                  {errors.status?.status === 'required' && (
                    <p className='alert_danger' role='alert'>
                      This is required field
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-8'>
            <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
              <div className='tab-content'>
                <div
                  className='tab-pane fade show active'
                  id='kt_ecommerce_add_product_general'
                  role='tab-panel'
                >
                  <div className='d-flex flex-column gap-7 gap-lg-10'>
                    <div className='card card-flush py-4'>
                      <div className='card-body pt-0'>
                        <div className='mb-10 fv-row'>
                          <label className='required form-label'> Bundle Name</label>

                          <input
                            type='text'
                            name='Name'
                            className='form-control mb-2'
                            placeholder='Enter Bundle name'
                            style={{padding: '1rem'}}
                            {...register('Name', {
                              required: 'Name is required field',
                            })}
                            aria-invalid={errors.Name ? 'true' : 'false'}
                          />
                          {errors.Name && (
                            <p className='alert_danger' role='alert'>
                              {errors.Name?.message}
                            </p>
                          )}
                        </div>

                        <div className='mb-10 fv-row'>
                          <label className='required form-label'>Price</label>

                          <input
                            type='text'
                            name='price'
                            className='form-control mb-2'
                            placeholder='Enter Price'
                            style={{padding: '1rem'}}
                            {...register('price', {
                              required: 'Price is required field',
                            })}
                            aria-invalid={errors.price ? 'true' : 'false'}
                            onKeyPress={(e) => {
                              const isNumeric = /^[0-9]$/
                              if (!isNumeric.test(e.key)) {
                                e.preventDefault()
                              }
                            }}
                          />
                          {errors.price && (
                            <p className='alert_danger' role='alert'>
                              {errors.price?.message}
                            </p>
                          )}
                        </div>

                        <div className='mb-10 fv-row'>
                          <label className='required form-label'>Duration</label>

                          <input
                            type='text'
                            name='duration'
                            className='form-control mb-2'
                            placeholder='Enter Duration'
                            style={{padding: '1rem'}}
                            {...register('duration', {
                              required: 'This is required field',
                            })}
                            aria-invalid={errors.duration ? 'true' : 'false'}
                          />
                          {errors.duration && (
                            <p className='alert_danger' role='alert'>
                              {errors.duration?.message}
                            </p>
                          )}
                        </div>

                        <div>
                          <label className='required form-label'>Bundle Description</label>

                          <Controller
                            control={control}
                            name='bundle_description'
                            defaultValue=''
                            rules={{required: true}}
                            render={({field}) => {
                              return (
                                <JoditEditor
                                  ref={field.ref}
                                  value={field.value}
                                  placeholder='start typing'
                                  aria-invalid={errors.bundle_description ? 'true' : 'false'}
                                  tabIndex={1} // tabIndex of textarea
                                  onBlur={(newContent) => {
                                    if (newContent === '<p><br></p>') newContent = ''
                                    Setbundle_description(newContent)

                                    field.onChange(newContent)
                                  }}
                                  onChange={(newContent) => {}}
                                />
                              )
                            }}
                          />
                          {errors.bundle_description?.type === 'required' && (
                            <p className='alert_danger' role='alert'>
                              This is required field
                            </p>
                          )}
                        </div>

                        <div>
                          <label className='required form-label'> What’s Included</label>

                          <Controller
                            control={control}
                            name='includes'
                            defaultValue=''
                            rules={{required: true}}
                            render={({field}) => {
                              return (
                                <JoditEditor
                                  ref={field.ref}
                                  value={field.value}
                                  placeholder='start typing'
                                  aria-invalid={errors.includes ? 'true' : 'false'}
                                  tabIndex={1} // tabIndex of textarea
                                  onBlur={(newContent) => {
                                    if (newContent === '<p><br></p>') newContent = ''
                                    Setincludes(newContent)

                                    field.onChange(newContent)
                                  }}
                                  onChange={(newContent) => {}}
                                />
                              )
                            }}
                          />
                          {errors.includes?.type === 'required' && (
                            <p className='alert_danger' role='alert'>
                              This is required field
                            </p>
                          )}
                        </div>

                        <div>
                          <label className='required  form-label'>Course Bundle Benefits</label>

                          <Controller
                            control={control}
                            name='benefits'
                            defaultValue=''
                            rules={{required: true}}
                            render={({field}) => {
                              return (
                                <JoditEditor
                                  ref={field.ref}
                                  value={field.value}
                                  placeholder='start typing'
                                  aria-invalid={errors.benefits ? 'true' : 'false'}
                                  tabIndex={1} // tabIndex of textarea
                                  onBlur={(newContent) => {
                                    if (newContent === '<p><br></p>') newContent = ''
                                    Setbenefits(newContent)

                                    field.onChange(newContent)
                                  }}
                                  onChange={(newContent) => {}}
                                />
                              )
                            }}
                          />
                          {errors.benefits?.type === 'required' && (
                            <p className='alert_danger' role='alert'>
                              This is required field
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='d-flex justify-content-end'>
                <Link to='/bundlelist'>
                  <button type='reset' className='btn cybercancelbtn me-2'>
                    Cancel
                  </button>
                </Link>

                <button
                  type='submit'
                  id='kt_ecommerce_add_product_submit'
                  className='btn cyberbtnallpage'
                  style={{background: '#FF763F', color: 'white'}}
                >
                  <span className='indicator-label'>Save Changes</span>
                  <span className='indicator-progress'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default UpdateBundle
