import React, {useState, useEffect, useMemo} from 'react'
import JoditEditor from 'jodit-react'
import {useNavigate, Link} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
// import {CreateCourseAction} from '../../../Redux/Action/courseAction'
import {useForm, Controller} from 'react-hook-form'

import {GetCourseAction, UpdateCourseAction} from '../../../Redux/Action/courseAction'
import {useParams} from 'react-router-dom'
import Loader from '../../../components/Loader/loader'
import TimezoneSelect from 'react-timezone-select'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'

const EditCourse = ({placeholder}) => {
  // const editor = useRef(null)

  const getCurrentDateTime = () => {
    const now = new Date()
    const year = now.getFullYear()
    const month = `${(now.getMonth() + 1).toString().padStart(2, '0')}`
    const day = `${now.getDate().toString().padStart(2, '0')}`
    const hours = `${now.getHours().toString().padStart(2, '0')}`
    const minutes = `${now.getMinutes().toString().padStart(2, '0')}`

    return `${year}-${month}-${day}T${hours}:${minutes}`
  }

  const [description, Setdescription] = useState('')
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState(null)
  const [video, setVideo] = useState(null)

  const [certifications, Setcertifications] = useState('')

  const [Learnings, SetLearnings] = useState('')
  const [TopicCovered, SetTopicCovered] = useState('')
  const [PreRequisites, SetPreRequisites] = useState('')

  const [Delivery_Mechanism, SetDelivery_Mechanism] = useState('')
  const [resources, SetResources] = useState('')
  const [duration, SetDuration] = useState('')
  const [timezone, settimezone] = useState(true)
  const [pdf, setPdf] = useState(null)
  const courseId = useParams()

  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  )
  const [startTime, setStartTime] = useState()
  const [poster_file, setposter_file] = useState('')
  const [dateFields, setDateFields] = useState([
    {
      start_datetime: '',
      end_datetime: '',
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      salesCount: '',
      numberOfSeats: '',
    },
  ])
  const [prevdateFields, setprevDateFields] = useState([])

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: {errors},
  } = useForm()
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const coursedetailsid = useSelector((state) => state.login.getcoursebyidlist)

  useEffect(() => {
    if (coursedetailsid?.data) {
      const data = coursedetailsid.data

      setValue('title', data.title)
      setValue('description', data.description)
      setValue('course_code', data.course_code)
      setValue('paid_price', data.paid_price)
      // setValue('category', data.category)
      setValue('status', data.status)
      setValue('pre_video', data.vdo_link)
      setValue('course_pdf', data.course_pdf)
      setValue('type', data.type)
      setValue('about_role', data.about_role)
      setValue('who_suited', data.who_suited)
      setValue('why_consider', data.why_consider)
      setValue('jobs', data.jobs)
      setValue('certifications', data.certifications)
      setValue('skills', data.skills)
      setValue('salary', data.salary)
      setValue('vouchers', data.vouchers)
      setValue('handsonlabs', data.handsonlabs)
      setValue('duration', data.duration)
      setValue('learnings', data.learnings)
      setValue('topicCovered', data.topicCovered)
      setValue('preRequisites', data.preRequisites)
      setValue('Delivery_Mechanism', data.Delivery_Mechanism)
      setValue('resources', data.resources)
      setCourseCategory(data.type)

      if (Array.isArray(coursedetailsid.dateRange)) {
        const formattedDateFields = coursedetailsid.dateRange.map((dateField) => {
          const start_datetime = moment.utc(dateField?.start_datetime || '')
          const end_datetime = moment.utc(dateField?.end_datetime || '')

          const start_datetimeHour = start_datetime.hour()
          const start_datetimeMin = start_datetime.minute()
          const start_datetimeDate = start_datetime.date()
          const start_datetimeMonth = start_datetime.month()
          const start_datetimeYear = start_datetime.year()

          const end_datetimeHour = end_datetime.hour()
          const end_datetimeMin = end_datetime.minute()
          const end_datetimeDate = end_datetime.date()
          const end_datetimeMonth = end_datetime.month()
          const end_datetimeYear = end_datetime.year()

          const formattedStartDatetime = new Date(
            start_datetimeYear,
            start_datetimeMonth,
            start_datetimeDate,
            start_datetimeHour,
            start_datetimeMin
          )

          const formattedEndDatetime = new Date(
            end_datetimeYear,
            end_datetimeMonth,
            end_datetimeDate,
            end_datetimeHour,
            end_datetimeMin
          )
          return {
            start_datetime: formattedStartDatetime,
            end_datetime: formattedEndDatetime,
            timezone: dateField.timezone,
            salesCount: dateField.salesCount,
            numberOfSeats: dateField.SeatsCount,
            meetingId: dateField.meetingId,
            uniqueCode: dateField.uniqueCode,
          }
        })
        setDateFields(formattedDateFields)
        setprevDateFields(formattedDateFields)
      }
    }
  }, [coursedetailsid])

  useEffect(() => {
    // debugger;
    dispatch(GetCourseAction(courseId))
  }, [dispatch])

  const onSubmit = (data) => {
    debugger
    const formData = new FormData()
    setLoading(true)

    if (data.poster_file[0]) {
      formData.append('poster_file', data.poster_file[0])
    }
    if (data.course_pdf) {
      formData.append('course_pdf', data.course_pdf[0])
    }
    formData.append('title', data.title)
    formData.append('description', data.description)
    formData.append('paid_price', data.paid_price)
    // formData.append('category', data.category)
    formData.append('status', data.status)

    if (data.pre_video && data.pre_video.length > 0) {
      formData.append('pre_video', data.pre_video[0])
    } else {
      formData.append('pre_video', null)
    }
    formData.append('type', data.type)
    formData.append('certifications', data.certifications)
    formData.append('salary', data.salary)
    formData.append('learnings', data.learnings)
    formData.append('topicCovered', data.topicCovered)
    formData.append('preRequisites', data.preRequisites)
    formData.append('certMasterPractice', data.certMasterPractice)
    formData.append('certMasterLabs', data.certMasterLabs)
    formData.append('Delivery_Mechanism', data.Delivery_Mechanism)
    formData.append('resources', data.resources)
    formData.append('vouchers', data.vouchers)
    formData.append('handsonlabs', data.handsonlabs)
    formData.append('duration', data.duration)
    dateFields.forEach((dateField) => {
      formData.append('start_datetime', dateField.start_datetime)
      formData.append('end_datetime', dateField.end_datetime)
      formData.append('timezone', dateField.timezone)
      formData.append('salesCount', dateField.salesCount)
      formData.append('SeatsCount', dateField.numberOfSeats)
      formData.append('meetingId', dateField.meetingId)
      formData.append('uniqueCode', dateField.uniqueCode)
    })

    dispatch(UpdateCourseAction(formData, courseId, navigate, setLoading))
  }

  const [numForms, setNumForms] = useState(1)

  const handleAddDateField = () => {
    setDateFields([
      ...dateFields,
      {start_datetime: '', end_datetime: '', timezone: '', numberOfSeats: ''},
    ])
  }

  const handleRemoveDateField = (index) => {
    if (dateFields.length > 1) {
      const updatedDateFields = [...dateFields]
      updatedDateFields.splice(index, 1)
      setDateFields(updatedDateFields)
    }
  }
  const extractTimezoneString = (selectedTimezone) => {
    // Implement the logic to extract the string from the object
    return selectedTimezone.value // You may need to adjust this based on the actual structure of the object
  }

  const handleDateFieldChange = (index, field, value) => {
    const updatedDateFields = [...dateFields]
    updatedDateFields[index][field] = field === 'timezone' ? extractTimezoneString(value) : value

    if (field === 'timezone') {
      setSelectedTimezone(extractTimezoneString(value))
    } else if (field === 'numberOfSeats') {
      updatedDateFields[index].numberOfSeats = value.target.value
    }

    setDateFields(updatedDateFields)
  }

  const selectedCategory = watch('type')
  const isCategory1or2 = selectedCategory === '1' || selectedCategory === '2'
  const isCategory3 = selectedCategory === '3'

  const getFileNameFromLink = (link) => {
    const segments = link.split('/')
    return segments[segments.length - 1]
  }

  const [selectedFile, setSelectedFile] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [courseCategory, setCourseCategory] = useState('')

  const handleFileChange = (e) => {
    const file = e.target.files[0]

    if (file) {
      const fileNameParts = file.name.split('.')
      const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase()

      if (fileExtension === 'pdf') {
        setSelectedFile(file)
        setErrorMessage('')
      } else {
        e.target.value = null
        setSelectedFile(null)
        setErrorMessage('Invalid file type. Please select a PDF file.')
      }
    }
  }
  const config = useMemo(() => ({readonly: false}), [])
  return (
    <div>
      <div id='kt_app_content' className='app-content flex-column-fluid'>
        {loading && (
          <div className='loader-container'>
            <Loader />
          </div>
        )}
        <form
          id='kt_ecommerce_add_product_form'
          className='form d-flex flex-column flex-lg-row'
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className='course_category col-md-4'>
            <div className='d-flex flex-column gap-7 gap-lg-10 w-100 mb-7 me-lg-10'>
              <div className='card card-flush py-4'>
                <div className='card-header'>
                  <div className='card-title'>
                    <h2>Course Image</h2>
                  </div>
                </div>

                <div class='card-body'>
                  <div className='d-flex flex-column align-items-center'>
                    {coursedetailsid?.data ? (
                      <div>
                        <img
                          src={coursedetailsid.data.image_link}
                          alt='Current Profile'
                          style={{maxWidth: '200px'}}
                          onError={(e) => {
                            console.error('Error loading image:', e)
                          }}
                        />
                      </div>
                    ) : (
                      <p>No image available</p>
                    )}

                    <input
                      type='file'
                      className='form-control'
                      {...register('poster_file')}
                      onChange={(e) => {
                        const selectedFile = e.target.files[0]
                        setFile(URL.createObjectURL(selectedFile))
                      }}
                    />

                    {file && <img src={file} alt='Selected' style={{maxWidth: '300px'}} />}
                    <div className='form-text'>Allowed file types: png, jpg, jpeg.</div>
                    {errors.poster_file && (
                      <p className='alert_danger' role='alert'>
                        {errors.poster_file.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className='card-body pt-0'></div>
              </div>

              <div className='card card-flush py-4'>
                <div className='card-header'>
                  <div className='card-title'>
                    <h2>Upload Pdf</h2>
                  </div>
                  <div className='card-body'>
                    <div className='d-flex flex-column align-items-center'>
                      {coursedetailsid?.data && coursedetailsid.data.course_pdf !== null ? (
                        <p>{getFileNameFromLink(coursedetailsid?.data?.course_pdf)}</p>
                      ) : (
                        <p>No PDF available</p>
                      )}
                      <input
                        type='file'
                        className='form-control mt-3'
                        {...register('course_pdf')}
                        onChange={handleFileChange}
                      />
                      {errorMessage && (
                        <p style={{color: 'red', marginTop: '10px'}}>{errorMessage}</p>
                      )}
                      {errors.course_pdf && (
                        <p className='alert_danger' role='alert'>
                          {errors.course_pdf.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className='card card-flush py-4'>
                <div className='card-header'>
                  <div className='card-title'>
                    <h2>Course Category</h2>
                  </div>

                  <div className='card-toolbar'></div>
                </div>

                <div className='card-body pt-0'>
                  <select
                    className='form-select mb-2'
                    data-control='select2'
                    data-hide-search='true'
                    data-placeholder='Select an option'
                    id='kt_ecommerce_add_category_status_select'
                    {...register('type', {
                      required: true,
                    })}
                    onChange={(e) => setCourseCategory(e.target.value)}
                  >
                    aria-invalid={errors.type ? 'true' : 'false'}
                    <option value='' disabled selected>
                      Select{' '}
                    </option>
                    <option value='1'>CompTIA Bootcamp</option>
                    <option value='2'>Microsoft Bootcamp</option>
                    <option value='3'>CompTIA elearning</option>
                  </select>
                  {errors.type?.type === 'required' && (
                    <p className='alert_danger' role='alert'>
                      This is required field
                    </p>
                  )}
                </div>
              </div>

              <div class='card card-flush py-4'>
                <div class='card-header'>
                  <div class='card-title'>
                    <h2>Features</h2>
                  </div>
                </div>

                <div class='card-body pt-0'>
                  <input
                    type='checkbox'
                    id='vouchersCheckbox'
                    name='vouchers'
                    value='Yes'
                    checked={watch('vouchers') === 'Yes'}
                    {...register('vouchers', {
                      required: false,
                    })}
                  />
                  <label htmlFor='vouchersCheckbox' style={{marginLeft: '5px'}}>
                    Exam vouchers
                  </label>
                  {errors.vouchers?.vouchers === 'required' && (
                    <p className='alert_danger' role='alert'>
                      This is required field
                    </p>
                  )}
                  <br />
                  <input
                    type='checkbox'
                    id='handsonlabsCheckbox'
                    name='handsonlabs'
                    value='Yes'
                    checked={watch('handsonlabs') === 'Yes'}
                    {...register('handsonlabs', {
                      required: false,
                    })}
                  />

                  <label htmlFor='handsonlabsCheckbox' style={{marginLeft: '5px'}}>
                    Hands-On Lab Training
                  </label>
                  {errors.handsonlabs?.handsonlabs === 'required' && (
                    <p className='alert_danger' role='alert'>
                      This is required field
                    </p>
                  )}
                </div>
              </div>

              <div className='card card-flush py-4'>
                <div className='card-header'>
                  <div className='card-title'>
                    <h2>Status</h2>
                  </div>

                  <div className='card-toolbar'></div>
                </div>

                <div className='card-body pt-0'>
                  <select
                    className='form-select mb-2'
                    data-control='select2'
                    data-hide-search='true'
                    data-placeholder='Select an option'
                    {...register('status', {
                      required: true,
                    })}
                  >
                    aria-invalid={errors.status ? 'true' : 'false'}
                    <option value='Published' selected='selected'>
                      Published
                    </option>
                    <option value='Draft'>Draft</option>
                  </select>
                  {errors.status?.status === 'required' && (
                    <p className='alert_danger' role='alert'>
                      This is required field
                    </p>
                  )}
                </div>
              </div>
              {courseCategory == '1' || courseCategory == '2' ? (
                <div class='card card-flush py-4'>
                  <div class='card-header'>
                    <div class='card-title'>
                      <h2>Add Schedule</h2>
                    </div>
                  </div>

                  <div className='card-body pt-0'>
                    {dateFields.map((dateField, index) => (
                      <div key={index}>
                        <label className='form-label'>
                          <span>Timezone</span>
                        </label>
                        <div style={{marginBottom: '10px'}}>
                          <TimezoneSelect
                            value={dateField.timezone ? dateField.timezone : ''}
                            onChange={(selectedTimezone) =>
                              handleDateFieldChange(index, 'timezone', selectedTimezone)
                            }
                            isDisabled={dateField.salesCount > '0'}
                          />
                        </div>
                        <div>
                          <label>Start Date</label>
                          <br />
                          <DatePicker
                            minDate={new Date()}
                            className='form-control mb-2'
                            selected={dateField.start_datetime}
                            onChange={(date) =>
                              handleDateFieldChange(index, 'start_datetime', date)
                            }
                            showTimeSelect
                            timeFormat='HH:mm'
                            timeIntervals={15}
                            timeCaption='time'
                            dateFormat='dd/MM/yyyy HH:mm'
                            timeZone='UTC'
                            disabled={dateField.salesCount > '0'}
                          />
                        </div>
                        <div>
                          <label>End Date</label>
                          <br />
                          <DatePicker
                            minDate={new Date()}
                            className='form-control mb-2'
                            selected={dateField.end_datetime}
                            onChange={(date) => handleDateFieldChange(index, 'end_datetime', date)}
                            showTimeSelect
                            timeFormat='HH:mm'
                            timeIntervals={15}
                            timeCaption='time'
                            dateFormat='dd/MM/yyyy HH:mm'
                            timeZone='UTC'
                            disabled={dateField.salesCount > '0'}
                          />
                        </div>
                        <div>
                          <label>No of Seats</label>
                          <br />
                          <div style={{display: 'flex', flexDirection: 'column'}}>
                            <input
                              type='number'
                              className='form-control mb-2'
                              placeholder='Enter number of seats'
                              value={dateField.numberOfSeats}
                              onChange={(value) =>
                                handleDateFieldChange(index, 'numberOfSeats', value)
                              }
                            />
                          </div>
                        </div>

                        {dateField.salesCount == '0' ? (
                          <button
                            type='button'
                            className='btn btn-sm cyberbtnallpage'
                            onClick={() => handleRemoveDateField(index)}
                          >
                            Remove
                          </button>
                        ) : null}
                      </div>
                    ))}
                    <div style={{display: 'flex', justifyContent: 'end', gap: '10px'}}>
                      <button
                        type='button'
                        className='btn btn-sm cyberbtnallpage'
                        onClick={handleAddDateField}
                        style={{marginTop: '10px'}}
                      >
                        Add More
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>

          <div className=' add_course_section_snd col-md-8'>
            <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
              <div className='tab-content'>
                <div
                  className='tab-pane fade show active'
                  id='kt_ecommerce_add_product_general'
                  role='tab-panel'
                >
                  <div className='d-flex flex-column gap-7 gap-lg-10'>
                    <div className='card card-flush py-4'>
                      <div className='card-body pt-0'>
                        <div className='mb-10 fv-row'>
                          <label className='required form-label'>Course Name</label>

                          <input
                            type='text'
                            name='title'
                            className='form-control mb-2'
                            placeholder='Course name'
                            aria-invalid={errors.title ? 'true' : 'false'}
                            {...register('title', {required: true})}
                          />
                          {errors.title?.type === 'required' && (
                            <p className='alert_danger' role='alert'>
                              This is required field
                            </p>
                          )}
                        </div>

                        <div className='mb-10 fv-row'>
                          <label className='required form-label'>Price</label>

                          <input
                            type='text'
                            name='price'
                            className='form-control mb-2'
                            placeholder='Enter Price'
                            aria-invalid={errors.paid_price ? 'true' : 'false'}
                            {...register('paid_price', {required: true})}
                            onKeyPress={(e) => {
                              const isNumeric = /^[0-9]$/
                              if (!isNumeric.test(e.key)) {
                                e.preventDefault()
                              }
                            }}
                          />
                          {errors.paid_price?.type === 'required' && (
                            <p className='alert_danger' role='alert'>
                              This is required field
                            </p>
                          )}
                        </div>
                        {/* {isCategory1or2 && ( */}
                        <div class='mb-10 fv-row'>
                          <label class='required form-label'>Certification Name</label>

                          <input
                            type='text'
                            name='certifications'
                            class='form-control mb-2'
                            placeholder='Enter Certification Names'
                            aria-invalid={errors.certifications ? 'true' : 'false'}
                            {...register('certifications', {required: true})}
                          />
                          {errors.certifications?.type === 'required' && (
                            <p className='alert_danger' role='alert'>
                              This is required field
                            </p>
                          )}
                        </div>
                        {/* )} */}
                        <div>
                          <label class='form-label'>Delivery Mechanism:</label>

                          <Controller
                            control={control}
                            name='Delivery_Mechanism'
                            defaultValue=''
                            rules={{required: true}}
                            render={({field}) => {
                              return (
                                <JoditEditor
                                  ref={field.ref}
                                  value={field.value}
                                  placeholder='Start typing'
                                  aria-invalid={errors.Delivery_Mechanism ? 'true' : 'false'}
                                  tabIndex={1} // tabIndex of textarea
                                  onBlur={(newContent) => {
                                    if (newContent === '<p><br></p>') newContent = ''
                                    SetDelivery_Mechanism(newContent)

                                    field.onChange(newContent)
                                  }}
                                  onChange={(newContent) => {}}
                                />
                              )
                            }}
                          />

                          {errors.Delivery_Mechanism?.type === 'required' && (
                            <p className='alert_danger' role='alert'>
                              This is required field
                            </p>
                          )}
                        </div>
                        <div>
                          <label class='form-label'>Resources:</label>

                          <Controller
                            control={control}
                            name='resources'
                            defaultValue=''
                            rules={{required: true}}
                            render={({field}) => {
                              return (
                                <JoditEditor
                                  ref={field.ref}
                                  value={field.value}
                                  placeholder='Start typing'
                                  aria-invalid={errors.resources ? 'true' : 'false'}
                                  tabIndex={1} // tabIndex of textarea
                                  onBlur={(newContent) => {
                                    if (newContent === '<p><br></p>') newContent = ''
                                    SetResources(newContent)

                                    field.onChange(newContent)
                                  }}
                                  onChange={(newContent) => {}}
                                />
                              )
                            }}
                          />
                          {errors.resources?.type === 'required' && (
                            <p className='alert_danger' role='alert'>
                              This is required field
                            </p>
                          )}
                        </div>
                        <div>
                          <label class='form-label'>Duration:</label>

                          <Controller
                            control={control}
                            name='duration'
                            defaultValue=''
                            rules={{required: true}}
                            render={({field}) => {
                              return (
                                <JoditEditor
                                  ref={field.ref}
                                  value={field.value}
                                  placeholder='Start typing'
                                  aria-invalid={errors.duration ? 'true' : 'false'}
                                  tabIndex={1} // tabIndex of textarea
                                  onBlur={(newContent) => {
                                    if (newContent === '<p><br></p>') newContent = ''
                                    SetDuration(newContent)

                                    field.onChange(newContent)
                                  }}
                                  onChange={(newContent) => {}}
                                />
                              )
                            }}
                          />
                          {errors.duration?.type === 'required' && (
                            <p className='alert_danger' role='alert'>
                              This is required field
                            </p>
                          )}
                        </div>
                        <div>
                          <label className='form-label'>Description</label>

                          <Controller
                            control={control}
                            name='description'
                            defaultValue=''
                            rules={{required: true}}
                            render={({field}) => {
                              return (
                                <JoditEditor
                                  ref={field.ref}
                                  value={field.value}
                                  placeholder='start typing'
                                  aria-invalid={errors.description ? 'true' : 'false'}
                                  tabIndex={1} // tabIndex of textarea
                                  onBlur={(newContent) => {
                                    if (newContent === '<p><br></p>') newContent = ''
                                    Setdescription(newContent)

                                    field.onChange(newContent)
                                  }}
                                  onChange={(newContent) => {}}
                                />
                              )
                            }}
                          />
                          {errors.description?.type === 'required' && (
                            <p className='alert_danger' role='alert'>
                              This is required field
                            </p>
                          )}
                        </div>

                        {/* {isCategory3 && ( */}
                          <div>
                            <label class='form-label'>What’s included:</label>

                            <Controller
                              control={control}
                              name='learnings'
                              defaultValue=''
                              rules={{required: true}}
                              render={({field}) => {
                                return (
                                  <JoditEditor
                                    ref={field.ref}
                                    value={field.value}
                                    placeholder='start typing'
                                    aria-invalid={errors.learnings ? 'true' : 'false'}
                                    tabIndex={1} // tabIndex of textarea
                                    onBlur={(newContent) => {
                                      if (newContent === '<p><br></p>') newContent = ''
                                      SetLearnings(newContent)

                                      field.onChange(newContent)
                                    }}
                                    onChange={(newContent) => {}}
                                  />
                                )
                              }}
                            />
                            {errors.learnings?.type === 'required' && (
                              <p className='alert_danger' role='alert'>
                                This is required field
                              </p>
                            )}
                          </div>
                        {/* )} */}
                        {/* {isCategory1or2 && ( */}
                          <div>
                            <label class='form-label'>Key Topics Covered:</label>

                            <Controller
                              control={control}
                              name='topicCovered'
                              defaultValue=''
                              rules={{required: true}}
                              render={({field}) => {
                                return (
                                  <JoditEditor
                                    ref={field.ref}
                                    value={field.value}
                                    placeholder='start typing'
                                    aria-invalid={errors.topicCovered ? 'true' : 'false'}
                                    tabIndex={1} // tabIndex of textarea
                                    onBlur={(newContent) => {
                                      if (newContent === '<p><br></p>') newContent = ''
                                      SetTopicCovered(newContent)

                                      field.onChange(newContent)
                                    }}
                                    onChange={(newContent) => {}}
                                  />
                                )
                              }}
                            />
                            {errors.topicCovered?.type === 'required' && (
                              <p className='alert_danger' role='alert'>
                                This is required field
                              </p>
                            )}
                          </div>
                        {/* )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='d-flex justify-content-end'>
                <Link to='/Allcourse'>
                  <button type='reset' className='btn cybercancelbtn me-2'>
                    Cancel
                  </button>
                </Link>

                <button
                  type='submit'
                  id='kt_ecommerce_add_product_submit'
                  className='btn cyberbtnallpage'
                  style={{background: '#FF763F', color: 'white'}}
                >
                  <span className='indicator-label'>Save Changes</span>
                  <span className='indicator-progress'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default EditCourse
