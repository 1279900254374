import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {BsFillGeoAltFill, BsFillEnvelopeAtFill} from 'react-icons/bs'
import AdminProfile from '../AdminTabs/Overview/AdminProfile'
import {Row, Col} from 'react-bootstrap'

import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import admin from '../../../../assets/images/default.png'

import {useDispatch, useSelector} from 'react-redux'

import {BoughtcourseAction} from '../../../../Redux/Action/courseAction'
import {GetUserById} from '../../../../Redux/Action/profileAction'
import {ExamVouchersbyUserId} from '../../../../Redux/Action/voucherAction'

import SignInMethod from '../AdminTabs/Setting/SignInMethod'
import Edit from '../AdminTabs/Setting/Edit'

import './admin.css'
import InstructorEdit from '../AdminTabs/Setting/InstructorEdit'
import * as CountryStateCity from 'country-state-city'
const AdminPro = () => {
  const dispatch = useDispatch()
  const studentid = localStorage.getItem('userid')

  const userdetails = useSelector((state) => {
    return state.login.getuserIdlist
  })

  useEffect(() => {
    dispatch(GetUserById(studentid))
  }, [dispatch, studentid])

  const mycoures = useSelector((state) => {
    return state.login.boughtcourseslist
  })

  useEffect(() => {
    dispatch(BoughtcourseAction(studentid))
  }, [dispatch, studentid])

  const myvouchers = useSelector((state) => {
    return state.login.getvoucheruserIdlist
  })

  useEffect(() => {
    dispatch(ExamVouchersbyUserId(studentid))
  }, [dispatch, studentid])

  const [countryName, setCountryName] = useState('')
  const [stateName, setStateName] = useState('')
  useEffect(() => {
    if (userdetails && userdetails.message && userdetails.message.Country) {
      const country = CountryStateCity.Country.getCountryByCode(userdetails.message.Country)
      const state = CountryStateCity.State.getStateByCode(userdetails.message.state)

      const fetchedCountryName = country ? country.name : userdetails.message.Country || ''
      const fetchedStateName = state ? state.name : userdetails.message.state || ''

      setCountryName(fetchedCountryName)
      setStateName(fetchedStateName)
    } else {
      // Handle the case when data is not available by setting "NA"
      setCountryName('NA')
      setStateName('NA')
    }
  }, [userdetails])

  // debugger;
  let roleid = localStorage.getItem('roleid')

  return (
    <Row>
      <Col lg={12}>
        <div className='pro-pro'>
          <div
            id='kt_app_body'
            data-kt-app-layout='dark-sidebar'
            data-kt-app-header-fixed='true'
            data-kt-app-sidebar-enabled='true'
            data-kt-app-sidebar-fixed='true'
            data-kt-app-sidebar-hoverable='true'
            data-kt-app-sidebar-push-header='true'
            data-kt-app-sidebar-push-toolbar='true'
            data-kt-app-sidebar-push-footer='true'
            data-kt-app-toolbar-enabled='true'
            className='app-default'
          >
            <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
              <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                <div className='d-flex flex-column flex-column-fluid'>
                  <div id='kt_app_toolbar' class='app-toolbar  py-3 py-lg-6 '>
                    <div className='px-2'>
                      <div class='page-title d-flex flex-column justify-content-center flex-wrap '>
                        <h1 class='page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0'>
                          Profile
                        </h1>
                        <ul class='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
                          <li class='breadcrumb-item text-muted'>
                            <a href='/dashboard' class='text-muted text-hover-primary'>
                              Home{' '}
                            </a>
                          </li>
                          <li class='breadcrumb-item'>
                            <span class='bullet bg-gray-500 w-5px h-2px'></span>
                          </li>
                          <li class='breadcrumb-item text-muted'>Profile </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div id='kt_app_content' className='app-content flex-column-fluid'>
                    <div>
                      <div className=''>
                        <div className='card   tabs-tabs'>
                          <div className='card-body '>
                            {/* <!--begin::Details--> */}
                            <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                              {/* <!--begin: Pic--> */}
                              <div className='me-7 mb-4'>
                                <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                                  {userdetails.message?.profileUrl ? (
                                    <img
                                      src={
                                        userdetails.message &&
                                        (userdetails.message.profileUrl
                                          ? userdetails.message.profileUrl
                                          : userdetails.message.profilePic)
                                      }
                                      alt='profile'
                                    />
                                  ) : (
                                    // Display a default image if profileUrl is null
                                    <img src={admin} alt='Default Profile' />
                                  )}
                                </div>
                              </div>
                              {/* <!--end::Pic--> */}
                              {/* <!--begin::Info--> */}
                              <div className='flex-grow-1'>
                                {/* <!--begin::Title--> */}
                                <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                                  {/* <!--begin::User--> */}
                                  <div className='d-flex flex-column'>
                                    <div className='d-flex align-items-center mb-2'>
                                      <span class='text-gray-900 text-hover-primary fs-2 fw-bold me-1'>
                                        {userdetails.message &&
                                          userdetails.message.FirstName +
                                            ' ' +
                                            userdetails.message.Surname}
                                      </span>
                                    </div>
                                    <div className='d-flex flex-wrap fw-semibold fs-5 mb-4 pe-2'>
                                      <div className='d-flex align-items-center text-gray-600 text-hover-primary me-5 mb-2'>
                                        <BsFillGeoAltFill />{' '}
                                        <span className='mx-2'>
                                          {countryName}
                                          {stateName && stateName != 'undefined' && stateName != null && `, ${stateName}`}
                                        </span>
                                      </div>
                                    </div>
                                    <div className='d-flex flex-wrap fw-semibold fs-5 mb-4 pe-2'>
                                      <div className='d-flex align-items-center text-gray-600 text-hover-primary me-5 mb-2'>
                                        <BsFillEnvelopeAtFill />{' '}
                                        <span className='mx-2'>
                                          {userdetails.message && userdetails.message.Email}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {roleid === '2' || roleid === '1' ? (
                            <div className='centerclass'>
                              <Tabs
                                defaultActiveKey='Overview'
                                id='uncontrolled-tab-example'
                                className='nav nav-stretch nav-line-tabs nav-line-tabs-2x profile-tabs border-transparent fs-5 fw-bold '
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <Tab eventKey='Overview' title='Overview'>
                                  <AdminProfile />
                                </Tab>

                                <Tab eventKey='Setting' title='Edit'>
                                  <Edit />
                                </Tab>
                                <Tab eventKey='Security' title='Reset Password'>
                                  <SignInMethod />
                                </Tab>
                              </Tabs>
                            </div>
                          ) : roleid === '3' ? (
                            <Tabs
                              defaultActiveKey='Overview'
                              id='uncontrolled-tab-example'
                              className='nav nav-stretch nav-line-tabs nav-line-tabs-2x profile-tabs border-transparent fs-5 fw-bold '
                            >
                              <Tab eventKey='Overview' title='Overview' className='active_title'>
                                <AdminProfile />
                              </Tab>
                              <Tab eventKey='Setting' title='Edit' className='active_title'>
                                <Edit />
                              </Tab>
                              <Tab eventKey='Security' title='Reset Password' className='active_title'>
                                <SignInMethod />
                              </Tab>

                              {/* <Tab eventKey='Purched History' title='Purched History'>
                                <Billing />
                              </Tab> */}
                            </Tabs>
                          ) : roleid === '4' ? (
                            <Tabs
                              defaultActiveKey='Overview'
                              id='uncontrolled-tab-example'
                              className='nav nav-stretch nav-line-tabs nav-line-tabs-2x profile-tabs border-transparent fs-5 fw-bold'
                            >                           
                              <Tab eventKey='Overview' title='Overview'>
                                <AdminProfile />
                              </Tab>
                              <Tab eventKey='Setting' title='Edit'>
                                <InstructorEdit />
                              </Tab>
                              <Tab eventKey='Security' title='Reset Password'>
                                <SignInMethod />
                              </Tab>
                            </Tabs>
                          ) : roleid === '5' ? (
                            <Tabs
                              defaultActiveKey='Overview'
                              id='uncontrolled-tab-example'
                              className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold'
                            >
                              <Tab eventKey='Overview' title='Overview'>
                                <AdminProfile />
                              </Tab>
                              <Tab eventKey='Setting' title='Edit'>
                                <Edit />
                              </Tab>
                              <Tab eventKey='Security' title='Reset Password'>
                                <SignInMethod />
                              </Tab>
                            </Tabs>
                          ) : roleid === '6' ? (
                            <Tabs
                              defaultActiveKey='Overview'
                              id='uncontrolled-tab-example'
                              className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold'
                            >
                              <Tab eventKey='Overview' title='Overview'>
                                <AdminProfile />
                              </Tab>
                              <Tab eventKey='Setting' title='Edit'>
                                <Edit />
                              </Tab>
                              <Tab eventKey='Security' title='Reset Password'>
                                <SignInMethod />
                              </Tab>
                            </Tabs>
                          ) : roleid === '7' ? (
                            <Tabs
                              defaultActiveKey='Overview'
                              id='uncontrolled-tab-example'
                              className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold'
                            >
                              <Tab eventKey='Overview' title='Overview'>
                                <AdminProfile />
                              </Tab>
                              <Tab eventKey='Setting' title='Edit'>
                                <Edit />
                              </Tab>
                              <Tab eventKey='Security' title='Reset Password'>
                                <SignInMethod />
                              </Tab>
                            </Tabs>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default AdminPro
