import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Section1 from './Section1'
import Navbar from '../../../Navbar/Navbar'
import {Link, useNavigate} from 'react-router-dom'
import Footer from '../../../Footer/Footer'
import {Modal} from 'antd'
import {PreviewDetailsAction} from '../../../../Redux/Action/courseAction'
import {StudentAction} from '../../../../Redux/Action/bundleAction'
import {Button} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import Table from 'react-bootstrap/Table'
import BuyBtn from '../MicrosoftBootCamp/BuyBtn'
const Compitiabootcamp = () => {
  const {
    register,
    handleSubmit,

    formState: {errors},
  } = useForm()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  var newURL = window.location
  var splitURL = newURL.href.split('/')
  const [selectedPreviewStartDate, setSelectedPreviewStartDate] = useState(null)
  const [selectedPreviewEndDate, setSelectedPreviewEndDate] = useState(null)
  const [selectedCourseId, setSelectedCourseId] = useState(null)
  const [selectedCourseCode, setSelectedCourseCode] = useState(null)
  const [selectedPrice, setSelectedCoursePrice] = useState(null)
  const [selectedCourseName, setSelectedCourseName] = useState(null)
  const [selectedTimezone, setSelectedtimezone] = useState(null)
  const course_code = splitURL[4]
  const courseslug = splitURL[5]

  const studentId = localStorage.getItem('userid')
  const authToken = localStorage.getItem('authToken')
  //const paidPrice = localStorage.getItem('paid_price')
  localStorage.setItem('title', selectedCourseName)
  localStorage.setItem('price', selectedPrice)
  localStorage.setItem('startDate', selectedPreviewStartDate)
  localStorage.setItem('endDate', selectedPreviewEndDate)
  localStorage.setItem('CourseId', selectedCourseId)
  localStorage.setItem('course_code', splitURL[4])
  localStorage.setItem('timezone', selectedTimezone)
  localStorage.setItem('courseCode', splitURL[4])

  const previewdetails = useSelector((state) => {
    // debugger;
    return state.login.getpreviewdetailslist
  })

  let cdata = ''
  useEffect(() => {
    // debugger
    dispatch(PreviewDetailsAction(courseslug))
  }, [dispatch])

  const scrollTobootcamp = () => {
    const element = document.getElementById('bootcamp')
    if (element) {
      element.scrollIntoView({behavior: 'smooth'})
    }
  }

  // Scroll to 'bundletitle' on page load
  useEffect(() => {
    scrollTobootcamp()
  }, [])

  const [open, setOpen] = useState(false)

  const showModal = () => {
    setOpen(true)
  }

  const hideModal = () => {
    setOpen(false)
  }

  const onSubmit = () => {
    // debugger
    const formData = new FormData()
    formData.append('Bundle_code', 0)
    formData.append('studentId', studentId)
    formData.append('CourseId', selectedCourseId)
    formData.append('CourseStartDate', selectedPreviewStartDate)
    formData.append('CourseEndDate', selectedPreviewEndDate)
    formData.append('timezone', selectedTimezone)
    formData.append('coursetype', 'course')
    dispatch(StudentAction(formData, course_code, navigate, onSubmit))
  }
  const handlePreviewDateChange = (formattedStartDate, formattedEndDate, asiaTimezone) => {
    //debugger
    setSelectedPreviewStartDate(formattedStartDate)
    setSelectedPreviewEndDate(formattedEndDate)
    setSelectedtimezone(asiaTimezone)
  }

  const isFormFilled = () => {
    return (
      selectedCourseId &&
      selectedPreviewStartDate &&
      selectedPreviewStartDate &&
      selectedCourseName &&
      selectedPrice &&
      selectedCourseCode
    )
  }

  const handleClick = () => {
    localStorage.setItem('type', 'course')
    //localStorage.setItem("course_code", splitURL[4])
  }

  return (
    <div id='bootcamp'>
      <Navbar />
      <div
        className='BundleDeytails'
        style={{
          position: 'absolute',
          top: '40%',
          left: '35%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'start',
          color: 'white',
          fontSize: '30px',
        }}
      >
        {Object.entries(previewdetails).map(([key, course], i) => {
          if (key === 'data') {
            return (
              <div key={i}>
                {/* <h1
                  className='banner-title'
                  style={{color: 'white', fontSize: '60px', width: '69%'}}
                >
                  {course.title}
                </h1>
                <div>
                  {localStorage.getItem('authToken') ? (
                    <div className='preview_learning_btn'>
                      <Link to={`/insidebuycourse?/${course_code}`}>
                        <Button
                          className='start_btn'
                          style={{
                            background: '#ff763f',
                            fontSize: '15px',
                            textTransform: 'capitalize',
                          }}
                          type='submit'
                          onClick={paidPrice}
                        >
                          Buy this course
                        </Button>
                      </Link>
                    </div>
                  ) : (
                    <div className='preview_learning_btn'>
                      <Link to={`/signup?/${course_code}`}>
                        <Button
                          className='start_btn'
                          style={{
                            background: '#ff763f',
                            fontSize: '15px',
                            textTransform: 'capitalize',
                          }}
                          type='submit'
                          onClick={paidPrice}
                        >
                          Buy this course
                        </Button>
                      </Link>
                    </div>
                  )}
                </div>
                <Button
                  className='start_btn'
                  style={{
                    background: '#ff763f',
                    fontSize: '15px',
                    textTransform: 'capitalize',
                  }}
                  type='submit'
                  onClick={() => {
                    setSelectedCourseId(course.id)
                    setSelectedCourseCode(course.course_code)
                    setSelectedCoursePrice(course.paid_price)
                    setSelectedCourseName(course.title)
                    handleClick()
                    showModal()
                  }}
                >
                  Buy this course
                </Button>
                <BuyBtn /> */}
              </div>
            )
          }

          ///Compitia
          return null
        })}
        <Modal
          title='Dates Available'
          open={open}
          onCancel={hideModal}
          footer={null} // Remove the default footer (OK and Cancel buttons)
          centered
          wrapClassName='custom-modal' // Apply a custom CSS class for styling
          // style={{width: '100vw', overflowX: 'auto'}}
        >
          <div>
            <div className='container'>
              <div className='Mylearning' style={{width: '100%'}}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div style={{border: '1px solid #f4f4f4'}}>
                    <div
                      className='book-view'
                      style={{
                        display: 'flex',
                        gap: '10px',
                        padding: '5px',
                        borderRadius: '4px',
                        background: '#264ACA',
                      }}
                    >
                      <h4 class='card-title align-items-start flex-column'>
                        <span class='card-label fw-bold text-800' style={{color: '#fff'}}>
                          {selectedCourseName}
                        </span>
                      </h4>
                    </div>

                    <Table
                      bordered
                      id='Elevenplusyear_castomer'
                      style={{
                        tableLayout: 'fixed',
                      }}
                    >
                      <tbody style={{border: '1px solid #CECFCF', fontSize: '15px'}}>
                        <tr>
                          <td width={40}></td>
                          <th>Start Date & Time</th>
                          <th>End Date & Time</th>
                          <th>Timezone</th>
                        </tr>

                        {previewdetails.dateRange ? (
                          previewdetails.dateRange.map((dateRange, index) => {
                            const userLocale = navigator.language || 'en-UK'

                            const asiaTimezone =
                              Intl.DateTimeFormat(userLocale).resolvedOptions().timeZone

                            // const asiaTimezone = 'America/Los_Angeles';
                            const options = {
                              day: '2-digit',
                              month: '2-digit',
                              year: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                              //second: 'numeric',
                              timeZone: asiaTimezone,
                              //timeZoneName: 'short',
                            }

                            const formattedStartDate = new Intl.DateTimeFormat(
                              'en-UK',
                              options
                            ).format(new Date(dateRange.start_datetime))
                            const formattedEndDate = new Intl.DateTimeFormat(
                              'en-UK',
                              options
                            ).format(new Date(dateRange.end_datetime))

                            return (
                              <React.Fragment key={index}>
                                <tr style={{border: '1px solid #CECFCF'}}>
                                  <td>
                                    <input
                                      type='radio'
                                      name={`dateType_${previewdetails.id}`}
                                      value={formattedStartDate}
                                      checked={selectedPreviewStartDate === formattedStartDate}
                                      onChange={() =>
                                        handlePreviewDateChange(
                                          dateRange.start_datetime,
                                          dateRange.end_datetime,
                                          asiaTimezone
                                        )
                                      }
                                    />
                                  </td>
                                  <td>{formattedStartDate}</td>
                                  <td>{formattedEndDate}</td>
                                  <td>{asiaTimezone}</td>
                                </tr>
                              </React.Fragment>
                            )
                          })
                        ) : (
                          <tr>
                            <td colSpan='2'>No Dates Available</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                  <div>
                    {authToken && isFormFilled() ? (
                      <button className='buttonlaunch button b-0px'>
                        Submit
                      </button>
                    ) : isFormFilled() ? (
                      <Link
                        to={`/signup?&${selectedCourseCode}&${selectedCourseId}&${selectedPreviewStartDate}&${selectedPreviewEndDate}`}
                        className='buttonlaunch button b-0px'
                      >
                        Submit
                      </Link>
                    ) : (
                      <div>
                        <span style={{width: '200px', fontSize: '15px'}}>
                          Please Select The Dates.
                        </span>
                        <button
                          className='buttonlaunch button b-0px'
                          disabled
                          style={{
                            width: '20%',
                            justifyContent: 'end',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      <Section1 />
      <Footer />
    </div>
  )
}

export default Compitiabootcamp
