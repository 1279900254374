import React from 'react'

import './About.css'

import Groupeight from '../../PrivateImage/img/Groupeight.png'
import aboutus1 from '../../PrivateImage/img/aboutus1.png'

import Cta from '../../components/Cta/Cta'
import Groupthree from '../../PrivateImage/img/Groupthree.png'

const Aboutus = () => {
  // In the 'aboutus' page

  return (
    <div>
      <div className='responsvive_text'>
        <h2 className='about_text-white '>
          About Us
        </h2>
      </div>
      <div className='aboutus' style={{zIndex: 99999999, fontSize: '24px'}}>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='headingg text-center'>
                <p className='aboutus_content'>
                  Cybersecurity challenges are on the rise. <br /> It may well be time for a digital
                  transformation.
                </p>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='about_sectionpargraphh col-lg-9 offset-lg-2 secondrow'>
              <p className=' about_sectionpargraph gray-text text-center mb-10 titleabout_text'>
                As we move towards a world, more connected by the web, data, analytics, and people,
                the security of every digital experience becomes all the more critical. Today,
                cybercrime costs small and medium businesses more than 2.2 million a year. A telling
                statistic, for talent looking to make a mark as part of a contemporary workforce in
                this ever-evolving sphere. And just as equally telling, for entrepreneurs working to
                empower their businesses successfully in the long term.
              </p>
            </div>
          </div>

          <div className='row'>
            <div className='col-lg-12'>
              <div className='heading text-center'>
                <p className='aboutus_content mb-10 text-black'>
                  At Cyberheads, we’re paving the way for those <br /> who dare to be at the
                  forefront of a cyber ready future.
                </p>
              </div>
            </div>
          </div>
          <div className=' about_resposiveready col-lg-12'>
            <div className='headingg text-center'>
              <p className='aboutus_content' style={{color: 'black', paddingBottom: '2rem'}}>
                At Cyberheads, we’re paving the way for those who dare to be at the forefront of a
                cyber ready future
              </p>
            </div>
          </div>
          <div className='aboutus_ourcourse'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='image-aboutus'>
                  <img src={aboutus1} alt='aboutus1' className='img-fluid' />
                </div>
              </div>
              <div className='col-md-6'>
                <h3 className='subtitle_aboutus'>OUR COURSES</h3>
                <p className='divid_coursefirst'>
                  To start with, we have courses that have been purposefully built to be role-based,
                  qualitative and impactful for freshers and working professionals looking to become
                  experts in this realm. These courses are complemented by a unique mentor-led
                  learning module that not only helps talent master in-demand skills but also
                  enhances key competencies.
                </p>
              </div>
            </div>
          </div>

          <div className='aboutus_ourcourse newclass'>
            <div className='row'>
              <div className='col-lg-5'>
                <h3 className='subtitle_aboutus'>CONSULTANCY</h3>
                <p className='divid_coursefirst'>
                  For corporates looking at out-of-the-box cybersecurity programs, we provide
                  integrated solutions to build and protect technology, integrate talent, and ensure
                  a seamless workflow that secures critical assets. All thanks to a team led by the
                  best security experts in the UK, including the ex-Head Chair of the UK’s National
                  Cyber Security Centre and CBIE.
                </p>
              </div>
              <div className='col-lg-2'></div>
              <div className='col-lg-5 new'>
                <div className='image-aboutus new'>
                  <img src={Groupeight} alt='Groupeight' className='img-fluid' />
                </div>
              </div>
            </div>
          </div>

          <div className='consultancyresposive aboutus_ourcourse'>
            <div className='row'>
              <div className='col-md-6'>
                <h3 className='subtitle_aboutus'>CONSULTANCY</h3>
                <p className='divid_coursefirst'>
                  For corporates looking at out-of-the-box cybersecurity programs, we provide
                  integrated solutions to build and protect technology, integrate talent, and ensure
                  a seamless workflow that secures critical assets. All thanks to a team led by the
                  best security experts in the UK, including the ex-Head Chair of the UK’s National
                  Cyber Security Centre and CBIE.
                </p>
              </div>
              <div className='col-md-6'>
                <div className='image-aboutus'>
                  <img src={Groupeight} alt='Groupthree' />
                </div>
              </div>
            </div>
          </div>

          <div className='aboutus_ourcourse'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='image-aboutus'>
                  <img src={Groupthree} alt='Groupthree' />
                </div>
              </div>
              <div className='col-md-6'>
                <h3 className='subtitle_aboutus'>EXPERTISE</h3>
                <p className='divid_coursefirst'>
                  With over 40 years of combined experience, our industry-leading expertise is
                  rounded off by security professionals who have built, staffed and managed Global
                  Security Operation centres - this enables us in taking a more proactive and
                  adaptive approach to guide students and organisations alike.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Cta />
      {/* <div style={{ position: 'relative' }}>
        <img src={bootombanner} alt='img' style={{ width: '100%', height: 'auto' }} />

        <span
          className='careerbotombanner'
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            color: 'white',
            fontSize: '48px',
          }}
        >
          Take a journey into cybersecurity,
          <br />
          armed with our experts
        </span>
        <div className='d-flex gap-3'>
          <div>
            <Link to='/Consultancy'>
              <button
                className='button button-rounded border-0'
                style={{
                  position: 'absolute',
                  top: 'calc(60% + 60px)', // Adjust the distance from the text
                  transform: 'translateX(-50%)',
                  left: '50%',
                }}
              >
                CONSULTANCY
              </button>
            </Link>

            <div>
              <Link to='/course'>
                <button
                  className='button button-rounded border-0'
                  style={{
                    position: 'absolute',
                    top: 'calc(60% + 60px)', // Adjust the distance from the text
                    left: '38%',
                    transform: 'translateX(-50%)',
                  }}
                >
                  COURSES
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default Aboutus
