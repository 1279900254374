import React, {useEffect, useState} from 'react'
// import courseimg from '../../../../../../../src/assets/images/courseimg.png'
import courseimg from '../../../../../src/assets/images/courseimg.png'
import challageimg from '../../../../../src/assets/images/challenge_labs.jpg'
import voucher from '../../../../../src/assets/images/voucher.jpg'
import comptia_learn from '../../../../../src/assets/images/comptia_learn.png.jpg'
import ms_learn from '../../../../../src/assets/images/microsoft_learns.jpg'
import AssignmentIcon from '@mui/icons-material/Assignment'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import {Document, Page, pdfjs} from 'react-pdf'
import {BoughtcourseAction, CheckusercourseAction} from '../../../../Redux/Action/courseAction'
// import comptia from '../../assets/images/comptia.png'
import comptia from '../../../../assets/images/comptia.png'
import dummy from '../../../../assets/images/dummy.pdf'
import {MdEventRepeat, MdLaunch, MdOutlineArrowForward} from 'react-icons/md'
import moment from 'moment-timezone';

import {Modal} from 'antd'
// import moment from 'moment'
import {Link, useNavigate} from 'react-router-dom'
import {TbNotes} from 'react-icons/tb'
import {BsQuestionSquare} from 'react-icons/bs'
import {useDispatch, useSelector} from 'react-redux'
import {ExamVouchers, ExamVouchersPut} from '../../../../Redux/Action/voucherAction'
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`
// require('moment-timezone/builds/moment-timezone-with-data');

const Coursecategory = () => {
  const dispatch = useDispatch()
  const [isClicked, setIsClicked] = useState([])
  const studentid = localStorage.getItem('userid')
  const [pdfModalVisible, setPdfModalVisible] = useState(false)

  const showPdfModal = () => {
    setPdfModalVisible(true)
  }

  const hidePdfModal = () => {
    setPdfModalVisible(false)
  }

  const mycoures = useSelector((state) => {
    return state.login.boughtcourseslist
  })

  useEffect(() => {
    dispatch(BoughtcourseAction(studentid))
  }, [dispatch])

  useEffect(() => {
    dispatch(CheckusercourseAction())
  }, [dispatch])

  const [formDataState, setFormData] = useState({
    id_token: '',
    state: '',
    redirect_uri: '',
    // Add other form fields here
  })

  let cdata = ''
  const navigate = useNavigate()

  const [open, setOpen] = useState(false)

  const showModal = () => {
    setOpen(true)
  }

  const hideModal = () => {
    setOpen(false)
  }

  const showModalReschedule = () => {
    setOpen(true)
  }

  const hideModalReschedule = () => {
    setOpen(false)
  }
  const onSubmit = (item) => {
    //debugger
    const course = item.courseCode

    dispatch(ExamVouchersPut(studentid, course))
  }
  const handleClick = (item) => {
    let clicked = isClicked.slice()

    clicked[item.id] = true
    setIsClicked(clicked)
    const course = item.courseCode
  }
  const changeDateFormat = (dateRange) => {
    const [startDateStr, endDateStr] = dateRange.split(' - ')
    const startDate = new Date(startDateStr)
    const endDate = new Date(endDateStr)

    const formattedStartDate = `${startDate.getDate()}/${
      startDate.getMonth() + 1
    }/${startDate.getFullYear()}`

    return `${formattedStartDate}`
  }


  const userLocale = navigator.language || 'en-UK'
  let asiaTimezone = Intl.DateTimeFormat(userLocale).resolvedOptions().timeZone

  function formatDate(dateString, timezone) {
    if (dateString) {
      const inputDate = moment.tz(dateString, 'YYYY-MM-DD HH:mm:ss', timezone);
      const localDate = inputDate.clone().local();
    
      return localDate.format('YYYY-MM-DD HH:mm:ss');
    } else {
      return null
    }
  }

  const currentDateISOString = new Date().toISOString()



  const formatDateString = (date) => {
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true // To use 12-hour format
    };
    return new Date(date).toLocaleDateString('en-GB', options);
  };


// function convertToLocaleTimezone(dateString, timezone) {

//   const inputDate = moment.tz(dateString, 'YYYY-MM-DD HH:mm:ss', timezone);
//   const localDate = inputDate.clone().local();

//   return localDate.format('YYYY-MM-DD HH:mm:ss');
// }


  return (
    <div>
      {Object.entries(mycoures).map(([key, course], i) => {
        if (key == 'data') {
          cdata = course
        }
      })}

      {Object.entries(cdata).map(([key, item], i) => {
        {console.log(item,"kkkkkkkkkk")}
        // const truncatedDescription =
        //   item.description.length > 100 ? `${item.description.slice(0, 250)}...` : item.description
        return (
          <>
            <div className='card mb-5'>
              <div className='row'>
                <div className='col'>
                  <div className='p-5'>
                    {item.type === '1' || item.type === '2' ? (
                      <span className='badge badge-warning' style={{background: '#ff763f'}}>
                        Bootcamp
                      </span>
                    ) : (
                      <span className='badge badge-warning' style={{background: '#ff763f'}}>
                        Self Paced
                      </span>
                    )}

                    <h3 className='card-title align-items-start flex-column'>
                      <span className='fw-bold fs-1 mb-3'>{item.title ?? null}</span>
                    </h3>
                  </div>
                </div>
              </div>

              {currentDateISOString < item.expiration_time ? (
                <div class='row equal-height-row p-3'>
                  {item.Course_Category !== 'CompTIA eLearning' ? (
                    <div class='col col-sm-12 col-md-6 col-lg-4  equal-height-col mb-5'>
                      <div class='card shadow-none h-100'>
                        <h4
                          class='card-header text-white justify-content-start align-items-center p-3'
                          style={{
                            background: '#264aca',
                          }}
                        >
                          <CalendarMonthIcon
                            style={{fontSize: '24px', color: '#fff', marginRight: '10px'}}
                          />
                          My Schedule
                        </h4>
                        <div className='card-body bg-light p-2'>
                          <div className='d-flex justify-content-between flex-lg-row flex-md-column align-items-md-center flex-wrap'>
                            <div>
                              <button
                                class='btn btn-sm bg-secondary m-0 mb-5 mt-5'
                                onClick={showModal}
                              >
                                <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                                  <BsQuestionSquare style={{fontSize: '12px'}} /> How to Join a
                                  virtual class?
                                </div>
                              </button>
                            </div>

                            <div className='d-flex justify-content-end  '>
                              <button
                                class='btn btn-sm bg-secondary m-0 '
                                onClick={showModalReschedule}
                              >
                                <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                                  <MdEventRepeat style={{fontSize: '12px'}} /> How to reschedule
                                  class?
                                </div>
                              </button>
                            </div>
                          </div>
                          <div className='mt-3 pb-3'>
                            <div
                              className='pb-5'
                              style={{
                                padding: '5px',

                                fontWeight: 600,

                                // display: 'flex',
                                justifyContent: 'space-between',
                                borderBottom: '1px solid #264aca',
                                color: '#264aca',
                              }}
                            >
                              <span className='mb-5'>
                                {item.title
                                  ? `You are Booked! ${item.title ?? null} Bootcamp`
                                  : null}
                              </span>
                                  {console.log(item,"gggggggggggggggggggggggggggggggggggggg")}
                              <div className='d-flex justify-content-between align-items-center flex-wrap mt-5'>
                                <p
                                  style={{
                                    color: 'rgb(255, 118, 63)',
                                    fontWeight: 600,
                                  }}
                                >
                                  Start Date : {formatDateString(formatDate(item.startDate, item.timezone))}
                                </p>
                                {item.joinUrl ? (
                                  <a href={item.joinUrl} target='_blank' rel='noreferrer'>
                                    <button
                                      className='purchedcourse button-rounded border-0 d-flex align-items-center '
                                      style={{height: '30px'}}
                                    >
                                      <MdLaunch />
                                      Join
                                    </button>
                                  </a>
                                ) : (
                                  <a href='#'>
                                    <button
                                      className='purchedcourse disabled button-rounded border-0 d-flex align-items-center '
                                      style={{
                                        backgroundColor: 'rgb(100 116 149)',
                                        height: '30px',
                                      }}
                                    >
                                      <MdLaunch />
                                      Join
                                    </button>
                                  </a>
                                )}
                              </div>
                              <p
                                style={{
                                  color: 'rgb(255, 118, 63)',
                                  fontWeight: 600,
                                  whiteSpace: 'pre-line',
                                }}
                              >
                                
                                End Date: {formatDateString(formatDate(item.endDate, item.timezone))}
                              </p>

                              <p
                                style={{
                                  color: 'rgb(255, 118, 63)',
                                  fontWeight: 600,
                                  whiteSpace: 'pre-line',
                                }}
                              >
                                Timezone: {asiaTimezone}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div class='col col-sm-12 col-md-6 col-lg-4  equal-height-col mb-5'>
                    <div class='card shadow-none h-100'>
                      <h4
                        class='card-header text-white justify-content-start align-items-center p-3'
                        style={{
                          background: '#264aca',
                        }}
                      >
                        <AssignmentIcon
                          style={{fontSize: '24px', color: '#fff', marginRight: '10px'}}
                        />
                        My E-Learning Resources
                      </h4>

                      {item.Course_Category === 'Microsoft Bootcamp' ? (
                        <div class='card-body bg-light p-2'>
                          <div className='row no-gutters mb-5'>
                            <div className='col-md-5'>
                              <img src={ms_learn} alt='' />
                            </div>
                            <div
                              className='col-md-7 mt-1 mb-7 text-gray-800'
                              style={{fontWeight: 700}}
                            >
                              <p style={{color: '#264aca'}}>Microsoft Learning Resources</p>

                              <button
                                class='btn btn-sm bg-secondary m-0 d-flex'
                                onClick={showModalReschedule}
                              >
                                <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                                  <TbNotes style={{fontSize: '12px'}} /> Instructions
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : item.Course_Category === 'CompTIA Bootcamp' ? (
                        <div class='card-body bg-light p-2'>
                          <div class='row no-gutters mb-5'>
                            <div className='col-md-5 col-sm-12'>
                              <img className='card-img' src={comptia_learn} alt='' />
                            </div>
                            <div
                              className='col-md-7 col-sm-12  mt-1 mb-7 text-gray-800'
                              style={{fontWeight: 700}}
                            >
                              <p style={{color: '#264aca'}}>CompTIA - Learn, Labs & Practice</p>
                              <div class='flex-wrap' style={{display: 'flex', gap: '20px'}}>
                                <a
                                  href='https://login.comptia.org/'
                                  target='_blank'
                                  rel='noreferrer'
                                  class='btn btn-sm bg-secondary m-0'
                                  style={{display: 'flex'}}
                                >
                                  <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                                    <MdLaunch style={{fontSize: '12px'}} /> Launch
                                  </div>
                                </a>

                                <button
                                  class='btn btn-sm bg-secondary m-0 d-flex'
                                  onClick={showModalReschedule}
                                >
                                  <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                                    <TbNotes style={{fontSize: '12px'}} /> Instructions
                                  </div>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class='row no-gutters '>
                            <div className='col-md-5'>
                              <img src={voucher} alt='' />
                            </div>
                            <div
                              className='col-md-7 mt-1 mb-7 text-gray-800'
                              style={{fontWeight: 700}}
                            >
                              <p style={{color: '#264aca'}}>Exam Voucher</p>

                              {item.code ? (
                                <div>
                                  {item.exam_code === 'Reveal_Code' ? (
                                    <div style={{display: 'flex', gap: '60px'}}>
                                      <div
                                        style={{display: 'flex', alignItems: 'center', gap: '5px'}}
                                      >
                                        <span
                                        className='badge badge-secondary mb-4'
                                          onClick={() => {
                                            handleClick(item)
                                            onSubmit(item)
                                          }}
                                          style={{cursor: 'pointer'}}
                                        >
                                          Reveal Code
                                        </span>
                                      </div>
                                    </div>
                                  ) : (
                                    <div>
                                      <label>Code: </label> <span>{item.code}</span>
                                    </div>
                                  )}

                                  {item.exam_code != 'Reveal_Code' ? (
                                    <div
                                      className='mb-5'
                                      style={{display: 'flex', alignItems: 'center', gap: '5px'}}
                                    >
                                      <label>Expiry: </label>{' '}
                                      <span>
                                        {
                                          new Date(item.voucherExpiary)
                                            .toLocaleString('en-UK', {
                                              year: 'numeric',
                                              month: '2-digit',
                                              day: '2-digit',
                                              hour: '2-digit',
                                              minute: '2-digit',
                                              second: '2-digit',
                                              hour12: false,
                                            })
                                            .split(',')[0]
                                        }
                                      </span>
                                    </div>
                                  ) : (
                                    <div></div>
                                  )}
                                </div>
                              ) : (
                                <div>
                                  <p>Voucher not added yet</p>
                                </div>
                              )}
                              <button
                                class='btn btn-sm bg-secondary m-0 d-flex'
                                onClick={showModalReschedule}
                              >
                                <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                                  <TbNotes style={{fontSize: '12px'}} /> Instructions
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : item.Course_Category === 'CompTIA eLearning' ? (
                        <div class='card-body bg-light p-2'>
                          <div class='row no-gutters mb-5'>
                            <div className='col-md-5 col-sm-12'>
                              <img className='card-img' src={comptia_learn} alt='' />
                            </div>
                            <div
                              className='col-md-7 col-sm-12  mt-1 mb-7 text-gray-800'
                              style={{fontWeight: 700}}
                            >
                              <p style={{color: '#264aca'}}>CompTIA - Learn, Labs & Practice</p>
                              <div class='flex-wrap' style={{display: 'flex', gap: '20px'}}>
                                <a
                                  href='https://login.comptia.org/'
                                  target='_blank'
                                  rel='noreferrer'
                                  class='btn btn-sm bg-secondary m-0'
                                  style={{display: 'flex'}}
                                >
                                  <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                                    <MdLaunch style={{fontSize: '12px'}} /> Launch
                                  </div>
                                </a>

                                <button
                                  class='btn btn-sm bg-secondary m-0 d-flex'
                                  onClick={showModalReschedule}
                                >
                                  <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                                    <TbNotes style={{fontSize: '12px'}} /> Instructions
                                  </div>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class='row no-gutters '>
                            <div className='col-md-5'>
                              <img src={voucher} alt='' />
                            </div>
                            <div
                              className='col-md-7 mt-1 mb-7 text-gray-800'
                              style={{fontWeight: 700}}
                            >
                              <p style={{color: '#264aca'}}>Exam Voucher</p>

                              {item.code ? (
                                <div>
                                  {item.exam_code === 'Reveal_Code' ? (
                                    <div style={{display: 'flex', gap: '60px'}}>
                                      <div
                                        style={{display: 'flex', alignItems: 'center', gap: '5px'}}
                                      >
                                        <span
                                        className='badge badge-secondary mb-4'
                                          onClick={() => {
                                            handleClick(item)
                                            onSubmit(item)
                                          }}
                                          style={{cursor: 'pointer'}}
                                        >
                                          Reveal Code
                                        </span>
                                      </div>
                                    </div>
                                  ) : (
                                    <div>
                                      <label>Code:</label> {item.code}
                                    </div>
                                  )}

                                  {item.exam_code != 'Reveal_Code' ? (
                                    <div
                                      className='mb-5'
                                      style={{display: 'flex', alignItems: 'center', gap: '5px'}}
                                    >
                                      <label>Expiry: </label>{' '}
                                      <span>
                                        {
                                          new Date(item.voucherExpiary)
                                            .toLocaleString('en-US', {
                                              year: 'numeric',
                                              month: '2-digit',
                                              day: '2-digit',
                                              hour: '2-digit',
                                              minute: '2-digit',
                                              second: '2-digit',
                                              hour12: false,
                                            })
                                            .split(',')[0]
                                        }
                                      </span>
                                    </div>
                                  ) : (
                                    <div></div>
                                  )}
                                </div>
                              ) : (
                                <div>
                                  <p>Voucher not added yet</p>
                                </div>
                              )}
                              <button
                                class='btn btn-sm bg-secondary m-0 d-flex'
                                onClick={showModalReschedule}
                              >
                                <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                                  <TbNotes style={{fontSize: '12px'}} /> Instructions
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : (
                <h4 className='card-title align-items-center flex-column'>
                  <span className='mb-3'>Your Course is Expired!</span>
                </h4>
              )}
            </div>
          </>
        )
      })}
      <Modal
        title='How to Join a virtual class'
        open={pdfModalVisible}
        onCancel={hidePdfModal}
        footer={null}
        centered
        wrapClassName='custom-modal'
      >
        <Document file={dummy} onLoadError={(error) => console.error('PDF load error:', error)}>
          <Page pageNumber={1} />
        </Document>
      </Modal>

      <Modal
        title='Reschedule'
        visible={open}
        onCancel={hideModalReschedule}
        footer={null} // Remove the default footer (OK and Cancel buttons)
        centered
        wrapClassName='custom-modal' // Apply a custom CSS class for styling
        height='20vh'
      >
        <Document file={dummy} onLoadError={(error) => console.error('PDF load error:', error)}>
          <Page pageNumber={1} />
        </Document>
      </Modal>
    </div>
  )
}

export default Coursecategory
