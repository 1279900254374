import React from 'react'

import {Section1} from '../../Bundle/BundleDetails/BundlesPages/Microsoftbootcamp/index'
import Comptia from '../../Bundle/BundleDetails/BundlesPages/Compitiabootcamp/Comptia'
import Certmaster from '../../Bundle/BundleDetails/BundlesPages/Certmaster/Certmaster'
import Boltchalenge from '../../Bundle/BundleDetails/BundlesPages/BoltChallenge/Boltchalenge'
import Mentoring from './BundlesPages/Mentoring/Mentoring'

import Overview from './BundlesPages/Overview/OverviewNew'
import '../BundleDetails/Bundle.css'

const BundleCoursedetails = () => {
  return (
    <div className='cpurseeeeeeeee'>
      <section class='bundle-tab' style={{}}>
        <div className='container-fluid' style={{borderBottom: '1px solid #eee'}}>
          <div className='row'>
            <div className='bundlesoverviewpage col-md-12'>
              <div className='container'>
                <nav>
                  <div
                    className='nav nav-tabs'
                    id='nav-tab'
                    role='tablist'
                    style={{paddingBottom: '1rem'}}
                  >
                    <button
                      className='nav-link active'
                      id='nav-all-tab'
                      data-bs-toggle='tab'
                      data-bs-target='#nav-all'
                      type='button'
                      role='tab'
                      aria-controls='nav-all'
                      aria-selected='true'
                    >
                      Course Bundle Overview
                    </button>
                    <button
                      className='nav-link'
                      id='nav-beginner-tab'
                      data-bs-toggle='tab'
                      data-bs-target='#nav-beginner'
                      type='button'
                      role='tab'
                      aria-controls='nav-beginner'
                      aria-selected='false'
                    >
                      Microsoft Boot Camp
                    </button>
                    <button
                      className='nav-link'
                      id='nav-analyst-tab'
                      data-bs-toggle='tab'
                      data-bs-target='#nav-analyst'
                      type='button'
                      role='tab'
                      aria-controls='nav-analyst'
                      aria-selected='false'
                    >
                      CompTIA Boot Camp
                    </button>
                    <button
                      className='nav-link'
                      id='nav-pen-tab'
                      data-bs-toggle='tab'
                      data-bs-target='#nav-pen'
                      type='button'
                      role='tab'
                      aria-controls='nav-pen'
                      aria-selected='false'
                    >
                      CompTIA CertMaster e-Learning
                    </button>
                    <button
                      className='nav-link'
                      id='nav-engineer-tab'
                      data-bs-toggle='tab'
                      data-bs-target='#nav-engineer'
                      type='button'
                      role='tab'
                      aria-controls='nav-engineer'
                      aria-selected='false'
                    >
                      Challenge Labs
                    </button>
                    <button
                      className='nav-link'
                      id='nav-mentoring-tab'
                      data-bs-toggle='tab'
                      data-bs-target='#nav-mentoring'
                      type='button'
                      role='tab'
                      aria-controls='nav-mentoring'
                      aria-selected='false'
                    >
                      Mentoring
                    </button>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <div class='tab-content' id='nav-tabContent'>
          <div
            class='tab-pane fade active show'
            id='nav-all'
            role='tabpanel'
            aria-labelledby='nav-all-tab'
          >
            <Overview />
          </div>
          <div
            class='tab-pane fade'
            id='nav-beginner'
            role='tabpanel'
            aria-labelledby='nav-beginner-tab'
          >
            <Section1 />
          </div>
          <div
            class='tab-pane fade'
            id='nav-analyst'
            role='tabpanel'
            aria-labelledby='nav-analyst-tab'
          >
            <Comptia />
          </div>
          <div class='tab-pane fade' id='nav-pen' role='tabpanel' aria-labelledby='nav-pen-tab'>
            <Certmaster />
          </div>
          <div
            class='tab-pane fade'
            id='nav-engineer'
            role='tabpanel'
            aria-labelledby='nav-engineer-tab'
          >
            <Boltchalenge />
          </div>
          <div
            class='tab-pane fade'
            id='nav-mentoring'
            role='tabpanel'
            aria-labelledby='nav-mentoring-tab'
          >
            <Mentoring />
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'end',
          }}
        ></div>
      </section>
    </div>
  )
}

export default BundleCoursedetails
