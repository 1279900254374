import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {GetAllUserCount} from '../../../../Redux/Action/admindashboardAction'

import {RiTeamFill} from 'react-icons/ri'
import {FcManager} from 'react-icons/fc'
import {TbWriting} from 'react-icons/tb'

import {PiStudentFill} from 'react-icons/pi'
import {GiTeacher} from 'react-icons/gi'
import {PiUsersFourBold} from 'react-icons/pi'

const Totaluser = () => {
  const instid = localStorage.getItem('userid')

  const dispatch = useDispatch()
  // debugger

  const alluser = useSelector((state) => {
    return state.login.getallusercountlist
  })

  useEffect(() => {
    dispatch(GetAllUserCount())
  }, [dispatch])

  //
  return (
    <div className='user_count_dash row'>
      <div className='col-sm-12'>
        <span className='text-black-400  fw-semibold fs-2 mb-5' style={{fontWeight: '600'}}>
          User Count
        </span>
      </div>

      <div className='col-6 dashboards_userlistUserCount'>
        <div className='card p-3 shadow-none bg-light stat-sec mb-3'>
          <h2 className='fs-1 mb-5'>
            {alluser.contentWriter} <TbWriting className='stats-icon' />
          </h2>
          <h5>Content Creator</h5>
        </div>
      </div>
      <div className='col-6 dashboards_userlistUserCount'>
        <div className='card p-3 shadow-none bg-light stat-sec mb-3'>
          <h2 className='fs-1 mb-5'>
            {alluser.corporateManger} <FcManager className='stats-icon' />
          </h2>
          <h5>Corporates</h5>
        </div>
      </div>
      <div className='col-6 dashboards_userlistUserCount'>
        <div className='card p-3 shadow-none bg-light stat-sec mb-3'>
          <h2 className='fs-1 mb-5'>
            {alluser.admin} <RiTeamFill className='stats-icon' />
          </h2>
          <h5>Cyberheads Team Admin</h5>
        </div>
      </div>
      <div className='col-6 dashboards_userlistUserCount'>
        <div className='card p-3 shadow-none bg-light stat-sec mb-3'>
          <h2 className='fs-1 mb-5'>
            {alluser.corporateemployess} <PiUsersFourBold className='stats-icon' />
          </h2>
          <h5>Employees</h5>
        </div>
      </div>
      <div className='col-6 dashboards_userlistUserCount'>
        <div className='card p-3 shadow-none bg-light stat-sec mb-3'>
          <h2 className='fs-1 mb-5'>
            {' '}
            {alluser.instructor} <GiTeacher className='stats-icon' />
          </h2>
          <h5>Instructor</h5>
        </div>
      </div>
      <div className='col-6 dashboards_userlistUserCount'>
        <div className='card p-3 shadow-none bg-light stat-sec mb-3'>
          <h2 className='fs-1 mb-5'>
            {alluser.student} <PiStudentFill className='stats-icon' />
          </h2>
          <h5>Student</h5>
        </div>
      </div>

    </div>
  )
}

export default Totaluser
