import React from 'react'
// import homebanner from '../../assets/images/homebanner.png'

const Banner = () => {
  //   const bannerStyle = {
  //     backgroundImage: `url(${homebanner})`,
  //     backgroundSize: 'cover',
  //     backgroundPosition: 'center',
  //     top: '0px',
  //     left: '0px',
  //     width: '1920px',
  //     height: '1080px',
  //     opacity: '1',
  //   }

  return (
    <div className='Banner_backgound'>
      <div
        className=''
        style={{
          fontSize: '30px',
          color: 'white',
          marginTop: '35rem', // Adjust this value as needed
          fontWeight: 'bold', // Add this line for a bolder text
          padding: '200px', // Adjust this value as needed for padding
        }}
      >
        Have goals in <br /> Cybersecurity?
        <br />
        <span>We'll help accelerate them</span>
      </div>
      <div>
        <button className='button button-rounded border-0'>START NOW</button>
      </div>
    </div>
  )
}

export default Banner
