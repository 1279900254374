import React, {useRef} from 'react'
import './coursetab.css'
import {GetallBundlaction} from '../../../Redux/Action/bundleAction'
import {GetallCoursesBundlesAction, GetallCourseAction} from '../../../Redux/Action/courseAction'
import {useDispatch, useSelector} from 'react-redux'
import {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'

import time from '../../../PrivateImage/img/time.svg'
import badge from '../../../PrivateImage/img/badge.svg'

import ChallengeLabs from '../../../PrivateImage/img/ChallengeLabs.svg'
import Comptia from '../../../PrivateImage/img/Comptia.svg'
import Mentoring from '../../../PrivateImage/img/Mentoring.svg'
import ExamVoucher from '../../../PrivateImage/img/ExamVoucher.svg'

import bundlesq from '../../../PrivateImage/bundleimg/coursebund.png'
import selfpac from '../../../PrivateImage/bundleimg/selfpac.png'
import bootcamp from '../../../PrivateImage/bundleimg/bootcamp.png'

import Flickity from 'react-flickity-component'
import 'flickity/css/flickity.css'

import {AiOutlineLeft, AiOutlineRight} from 'react-icons/ai'

const BundletabTestimonial = () => {
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const allbundle = useSelector((state) => {
    return state.login.getbundlelist
  })

  const bundle = allbundle.Published || []

  useEffect(() => {
    dispatch(GetallBundlaction())
  }, [dispatch])

  const CourseBundle = useSelector((state) => state.login.getallCourseBundleslist)

  const combinedData = []

  if (CourseBundle.Courses) {
    combinedData.push(...CourseBundle.Courses.map((course) => ({...course, type: 'Course'})))
  }

  if (CourseBundle.Bundles) {
    combinedData.push(...CourseBundle.Bundles.map((bundle) => ({...bundle, type: 'Bundle'})))
  }

  useEffect(() => {
    dispatch(GetallCoursesBundlesAction())
  }, [dispatch])

  const Allcourse = useSelector((state) => {
    return state.login.getcourseslist
  })

  const allcourse = Allcourse.Published || []

  useEffect(() => {
    dispatch(GetallCourseAction())
  }, [dispatch])

  const [currentPage, setCurrentPage] = useState(1)
  const recordsPerPage = 9

  useEffect(() => {
    dispatch(GetallBundlaction())
    dispatch(GetallCoursesBundlesAction())
    dispatch(GetallCourseAction())
  }, [dispatch])

  const lastIndex = currentPage * recordsPerPage
  const firstIndex = lastIndex - recordsPerPage
  const currentRecords = combinedData.slice(firstIndex, lastIndex)

  const nPage = Math.ceil(combinedData.length / recordsPerPage)

  const nextPage = () => {
    if (currentPage < nPage) {
      setCurrentPage(currentPage + 1)
    }
  }

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const flickityRef = useRef(null)

  useEffect(() => {
    flickityRef.current = new Flickity('.your-slider-container', {
      contain: true,
      wrapAround: true,
      // Add other Flickity options as needed
    })
  }, [])

  return (
    <div>
      <section
        class='courses-tab'
        style={{zIndex: '99999999', fontSize: '24px', paddingTop: '0px'}}
      >
        <div class='container'>
          <nav>
            <div class='nav nav-tabs' id='nav-tab' role='tablist'>
              <button
                class='nav-link active'
                id='nav-all-tab'
                data-bs-toggle='tab'
                data-bs-target='#nav-all'
                type='button'
                role='tab'
                aria-controls='nav-all'
                aria-selected='true'
              >
                ALL
              </button>
              <button
                class='nav-link'
                id='nav-beginner-tab'
                data-bs-toggle='tab'
                data-bs-target='#nav-beginner'
                type='button'
                role='tab'
                aria-controls='nav-beginner'
                aria-selected='false'
              >
                ROLE BASED BUNDLES
              </button>
              <button
                class='nav-link'
                id='nav-analyst-tab'
                data-bs-toggle='tab'
                data-bs-target='#nav-analyst'
                type='button'
                role='tab'
                aria-controls='nav-analyst'
                aria-selected='false'
              >
                MICROSOFT BOOT CAMP
              </button>
              <button
                class='nav-link'
                id='nav-pen-tab'
                data-bs-toggle='tab'
                data-bs-target='#nav-pen'
                type='button'
                role='tab'
                aria-controls='nav-pen'
                aria-selected='false'
              >
                COMPTIA BOOT CAMP
              </button>
              <button
                class='nav-link'
                id='nav-engineer-tab'
                data-bs-toggle='tab'
                data-bs-target='#nav-engineer'
                type='button'
                role='tab'
                aria-controls='nav-engineer'
                aria-selected='false'
              >
                COMPTIA SELF PACED
              </button>
            </div>
          </nav>

          <div class='tab-content' id='nav-tabContent'>
            <div
              class='tab-pane fade active show'
              id='nav-all'
              role='tabpanel'
              aria-labelledby='nav-all-tab'
            >
              <ul className='course-grid card__Container'>
                {(currentRecords || []).map((item) => (
                  <li className='item' key={item.id}>
                    <div className='wrapper position-relative'>
                      {item.Coursetype === 'Bundles' && (
                        <img
                          src={bundlesq}
                          alt='course'
                          className='position-absolute start-0'
                          style={{width: '170px', top: '20px'}}
                        />
                      )}
                      {item.Coursetype === 'Courses' && (
                        <img
                          src={item.Course_Category === 'CompTIA eLearning' ? selfpac : bootcamp}
                          alt='course'
                          className='position-absolute start-0'
                          style={{width: '170px', top: '20px'}}
                        />
                      )}

                      <div className='image-wrapper'>
                        <img
                          src={
                            item.Coursetype === 'Courses' ? item.image_link : item.ImageLink || ''
                          }
                          alt='course'
                        />
                      </div>

                      <div className='details'>
                        <h5>{item.Coursetype === 'Courses' ? item.title : item.Name || ''}</h5>

                        <div className='list-item'>
                          {' '}
                          <img src={time} alt='time' />
                          {item.duration ? (
                            <div dangerouslySetInnerHTML={{__html: item?.duration}} />
                          ) : (
                            'NA'
                          )}
                        </div>
                        {item.Coursetype === 'Bundles' && (
                          <>
                            {item.certifications && item.certifications.length > 0 && (
                              <div>
                                {item.certifications.split('|').map((certification, index) => (
                                  <div key={index} className='list-item'>
                                    <img src={badge} alt='certification' />{' '}
                                    <span
                                      style={{width: '100%'}}
                                      dangerouslySetInnerHTML={{__html: certification?.trim()}}
                                    />
                                  </div>
                                ))}
                              </div>
                            )}

                            <div className='list-item'>
                              <img src={Comptia} alt='certification' /> CompTIA Labs, Learn &
                              Practice
                            </div>
                            <div className='list-item'>
                              <img src={ExamVoucher} alt='certification' /> CompTIA Exam Voucher
                            </div>
                            <div className='list-item'>
                              <img src={ChallengeLabs} alt='certification' /> Skillable Challenge
                              Labs
                            </div>
                            <div className='list-item'>
                              <img src={Mentoring} alt='certification' /> Cyberheads Mentoring
                            </div>
                          </>
                        )}

                        {item.Course_Category === 'Microsoft Bootcamp' && (
                          <div className='list-item'>
                            <img src={badge} alt='badge' />

                            {(() => {
                              const trimmedCertifications = item.certifications
                              return (
                                <span
                                  style={{width: '100%'}}
                                  dangerouslySetInnerHTML={{__html: trimmedCertifications?.trim()}}
                                />
                              )
                            })()}
                          </div>
                        )}

                        {item.Course_Category === 'CompTIA Bootcamp' && (
                          <>
                            {(() => {
                              const trimmedCertifications = item.certifications
                                ? item.certifications.trim()
                                : ''

                              return (
                                <>
                                  <div className='list-item'>
                                    <img src={badge} alt='badge' />{' '}
                                    <span
                                      style={{width: '100%'}}
                                      dangerouslySetInnerHTML={{
                                        __html: trimmedCertifications?.trim(),
                                      }}
                                    />
                                  </div>
                                  <div className='list-item'>
                                    <img src={Comptia} alt='bolt' /> CompTIA Labs, Learn & Practice
                                  </div>
                                  <div className='list-item'>
                                    <img src={ExamVoucher} alt='bolt' /> CompTIA Exam Voucher
                                  </div>
                                </>
                              )
                            })()}
                          </>
                        )}

                        {item.Course_Category === 'CompTIA eLearning' && (
                          <>
                            {(() => {
                              const trimmedCertifications = item.certifications
                                ? item.certifications.trim()
                                : ''

                              return (
                                <>
                                  <div className='list-item'>
                                    <img src={badge} alt='badge' />
                                    <span
                                      style={{width: '100%'}}
                                      dangerouslySetInnerHTML={{
                                        __html: trimmedCertifications?.trim(),
                                      }}
                                    />
                                  </div>
                                  <div className='list-item'>
                                    <img src={Comptia} alt='bolt' /> CompTIA Labs, Learn & Practice
                                  </div>
                                  <div className='list-item'>
                                    <img src={ExamVoucher} alt='bolt' /> CompTIA Exam Voucher
                                  </div>
                                </>
                              )
                            })()}
                          </>
                        )}
                      </div>
                      <div className='button-group' style={{justifyContent: 'end'}}>
                        {item.Coursetype === 'Courses' && (
                          <button
                            className='button white'
                            style={{border: '0px'}}
                            onClick={() => {
                              if (item.Course_Category === 'CompTIA eLearning') {
                                navigate(
                                  `/comptia-learning?/${item.course_code}/${item.courseslug}#learning`
                                )
                              } else if (item.Course_Category === 'CompTIA Bootcamp') {
                                navigate(
                                  `/compitia_bootcamp?/${item.course_code}/${item.courseslug}#bootcamp`
                                )
                              } else if (item.Course_Category === 'Microsoft Bootcamp') {
                                navigate(
                                  `/microsoft-bootcamp?/${item.course_code}/${item.courseslug}#microsoft`
                                )
                              }
                            }}
                          >
                            VIEW COURSE
                          </button>
                        )}

                        {item.Coursetype === 'Bundles' && (
                          <Link
                            to={`/bundle_details/${item.bundle_slug}/${item.bundle_code}#bundletitle`}
                            className='button white'
                            onClick={() => {
                              localStorage.setItem('paid_price', item.price)
                              localStorage.setItem('Name', item.Name)
                            }}
                          >
                            VIEW COURSE
                          </Link>
                        )}

                        {item.Coursetype === 'Courses' && (
                          <button
                            className='button primary'
                            style={{border: '0px'}}
                            onClick={() => {
                              if (item.Course_Category === 'CompTIA eLearning') {
                                navigate(
                                  `/comptia-learning?/${item.course_code}/${item.courseslug}#learning`
                                )
                              } else if (item.Course_Category === 'CompTIA Bootcamp') {
                                navigate(
                                  `/compitia_bootcamp?/${item.course_code}/${item.courseslug}#bootcamp`
                                )
                              } else if (item.Course_Category === 'Microsoft Bootcamp') {
                                navigate(
                                  `/microsoft-bootcamp?/${item.course_code}/${item.courseslug}#microsoft`
                                )
                              }
                            }}
                          >
                            BUY NOW
                          </button>
                        )}

                        {item.Coursetype === 'Bundles' && (
                          <Link
                            to={`/bundle_details/${item.bundle_slug}/${item.bundle_code}#bundletitle`}
                            className='button primary'
                            onClick={() => {
                              localStorage.setItem('paid_price', item.price)
                              localStorage.setItem('Name', item.Name)
                            }}
                          >
                            BUY NOW
                          </Link>
                        )}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>


              <nav>
                <ul className='pagination'>
                  <li className='page-item'>
                    <button className='page-link' onClick={prevPage} disabled={currentPage === 1}>
                      <AiOutlineLeft />
                    </button>
                  </li>
                  {[...Array(nPage).keys()].map((n, i) => (
                    <li className={`page-item ${currentPage === n + 1 ? 'active' : ''}`} key={i}>
                      <button className='page-link' onClick={() => setCurrentPage(n + 1)}>
                        {n + 1}
                      </button>
                    </li>
                  ))}
                  <li className='page-item'>
                    <button
                      className='page-link'
                      onClick={nextPage}
                      disabled={currentPage === nPage}
                    >
                      <AiOutlineRight />
                    </button>
                  </li>
                </ul>
              </nav>
            </div>

            <div
              class='tab-pane fade'
              id='nav-beginner'
              role='tabpanel'
              aria-labelledby='nav-beginner-tab'
            >
              <ul class='course-grid card__Container'>
                {bundle.slice(0, 9).map((item, index) => {
                  return (
                    <li className='item' key={index}>
                      <div className='wrapper position-relative '>
                        <img
                          src={bundlesq}
                          alt='course'
                          className='position-absolute  start-0'
                          style={{width: '170px', top: '20px'}}
                        />
                        <div className='image-wrapper'>
                          <img src={item.ImageLink} alt='course' />
                        </div>
                        <div className='details'>
                          <h5>{item.Name}</h5>

                          <div className='list-item'>
                            <img src={time} alt='time' />{' '}
                            {item.duration ? (
                              <div dangerouslySetInnerHTML={{__html: item?.duration}} />
                            ) : (
                              'NA'
                            )}
                          </div>

                          {item.certifications && item.certifications.length > 0 && (
                            <div>
                              {item.certifications.split('|').map((certification, index) => (
                                <div key={index} className='list-item'>
                                  <img src={badge} alt='certification' />{' '}
                                  <span
                                    style={{width: '100%'}}
                                    dangerouslySetInnerHTML={{__html: certification?.trim()}}
                                  />
                                </div>
                              ))}
                            </div>
                          )}

                          <div className='list-item'>
                            {' '}
                            <img src={Comptia} alt='certification' /> CompTIA Labs, Learn & Practice
                          </div>
                          <div className='list-item'>
                            {' '}
                            <img src={ExamVoucher} alt='certification' /> CompTIA Exam Voucher
                          </div>
                          <div className='list-item'>
                            {' '}
                            <img src={ChallengeLabs} alt='certification' /> Skillable Challenge Labs
                          </div>
                          <div className='list-item'>
                            {' '}
                            <img src={Mentoring} alt='certification' /> Cyberheads Mentoring
                          </div>
                        </div>
                        <div className='button-group' style={{justifyContent: 'end'}}>
                          <Link
                            to={`/bundle_details/${item.bundle_slug}/${item.bundle_code}#bundletitle`}
                            class='button white'
                            onClick={() => {
                              localStorage.setItem('paid_price', item.price)
                              localStorage.setItem('Name', item.Name)
                            }}
                          >
                            {' '}
                            VIEW BUNDLE
                          </Link>

                          <Link
                            to={`/bundle_details/${item.bundle_slug}/${item.bundle_code}#bundletitle`}
                            className='button primary'
                            onClick={() => {
                              localStorage.setItem('paid_price', item.price)
                              localStorage.setItem('Name', item.Name)
                            }}
                          >
                            BUY NOW
                          </Link>
                        </div>
                      </div>
                    </li>
                  )
                })}
              </ul>

            </div>

            <div
              className='tab-pane fade'
              id='nav-analyst'
              role='tabpanel'
              aria-labelledby='nav-analyst-tab'
            >
              <ul className='course-grid card__Container'>
                {allcourse
                  .filter((course) => course.Course_Category === 'Microsoft Bootcamp')
                  .map((item) => (
                    <li className='item' key={`analyst-${item.id}`}>
                      <div className='wrapper position-relative '>
                        <img
                          src={bootcamp}
                          alt='course'
                          className='position-absolute  start-0'
                          style={{width: '170px', top: '20px'}}
                        />
                        <div className='image-wrapper'>
                          <img src={item.image_link} alt='course' />
                        </div>
                        <div className='details'>
                          <h5>{item.title}</h5>
                          <div className='list-item'>
                            <img src={time} alt='time' />{' '}
                            {item.duration ? (
                              <div dangerouslySetInnerHTML={{__html: item?.duration}} />
                            ) : (
                              'NA'
                            )}
                          </div>
                          <div className='list-item'>
                            <img src={badge} alt='badge' />{' '}
                            <span
                              style={{width: '100%'}}
                              dangerouslySetInnerHTML={{__html: item?.certifications}}
                            />
                          </div>
                        </div>
                        <div class='button-group' style={{justifyContent: 'end'}}>
                          <button
                            style={{border: '0px'}}
                            class='button white'
                            onClick={() => {
                              if (item.Course_Category === 'CompTIA eLearning') {
                                navigate(
                                  `/comptia-learning?/${item.course_code}/${item.courseslug}#learning`
                                )
                              } else if (item.Course_Category === 'CompTIA Bootcamp') {
                                navigate(
                                  `/compitia_bootcamp?/${item.course_code}/${item.courseslug}#bootcamp`
                                )
                              } else if (item.Course_Category === 'Microsoft Bootcamp') {
                                navigate(
                                  `/microsoft-bootcamp?/${item.course_code}/${item.courseslug}#microsoft`
                                )
                              }
                            }}
                          >
                            VIEW COURSE
                          </button>
                          <button
                            style={{border: '0px'}}
                            className='button primary'
                            onClick={() => {
                              if (item.Course_Category === 'CompTIA eLearning') {
                                navigate(
                                  `/comptia-learning?/${item.course_code}/${item.courseslug}#learning`
                                )
                              } else if (item.Course_Category === 'CompTIA Bootcamp') {
                                navigate(
                                  `/compitia_bootcamp?/${item.course_code}/${item.courseslug}#bootcamp`
                                )
                              } else if (item.Course_Category === 'Microsoft Bootcamp') {
                                navigate(
                                  `/microsoft-bootcamp?/${item.course_code}/${item.courseslug}#microsoft`
                                )
                              }
                            }}
                          >
                            BUY NOW
                          </button>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
            <div class='tab-pane fade' id='nav-pen' role='tabpanel' aria-labelledby='nav-pen-tab'>
              <ul className='course-grid card__Container'>
                {allcourse
                  .filter((course) => course.Course_Category === 'CompTIA Bootcamp')
                  .map((item) => (
                    <li className='item' key={`pen-${item.id}`}>
                      <div className='wrapper position-relative '>
                        <img
                          src={bootcamp}
                          alt='course'
                          className='position-absolute  start-0'
                          style={{width: '170px', top: '20px'}}
                        />
                        <div className='image-wrapper'>
                          <img src={item.image_link} alt='course' />
                        </div>
                        <div className='details'>
                          <h5>{item.title}</h5>
                          <div className='list-item'>
                            <img src={time} alt='time' />{' '}
                            {item.duration ? (
                              <div dangerouslySetInnerHTML={{__html: item?.duration}} />
                            ) : (
                              'NA'
                            )}
                          </div>
                          <div className='list-item'>
                            <img src={badge} alt='badge' />{' '}
                            <span
                              style={{width: '100%'}}
                              dangerouslySetInnerHTML={{
                                __html: item.certifications ? item.certifications?.trim() : '',
                              }}
                            />
                          </div>
                          <div className='list-item'>
                            <img src={Comptia} alt='bolt' /> CompTIA Labs, Learn & Practice
                          </div>
                          <div className='list-item'>
                            <img src={ExamVoucher} alt='bolt' /> CompTIA Exam Voucher
                          </div>
                        </div>
                        <div class='button-group' style={{justifyContent: 'end'}}>
                          <button
                            style={{border: '0px'}}
                            class='button white'
                            onClick={() => {
                              if (item.Course_Category === 'CompTIA eLearning') {
                                navigate(
                                  `/comptia-learning?/${item.course_code}/${item.courseslug}#learning`
                                )
                              } else if (item.Course_Category === 'CompTIA Bootcamp') {
                                navigate(
                                  `/compitia_bootcamp?/${item.course_code}/${item.courseslug}#bootcamp`
                                )
                              } else if (item.Course_Category === 'Microsoft Bootcamp') {
                                navigate(
                                  `/microsoft-bootcamp?/${item.course_code}/${item.courseslug}#microsoft`
                                )
                              }
                            }}
                          >
                            VIEW COURSE
                          </button>
                          <button
                            style={{border: '0px'}}
                            className='button primary'
                            onClick={() => {
                              if (item.Course_Category === 'CompTIA eLearning') {
                                navigate(
                                  `/comptia-learning?/${item.course_code}/${item.courseslug}#learning`
                                )
                              } else if (item.Course_Category === 'CompTIA Bootcamp') {
                                navigate(
                                  `/compitia_bootcamp?/${item.course_code}/${item.courseslug}#bootcamp`
                                )
                              } else if (item.Course_Category === 'Microsoft Bootcamp') {
                                navigate(
                                  `/microsoft-bootcamp?/${item.course_code}/${item.courseslug}#microsoft`
                                )
                              }
                            }}
                          >
                            BUY NOW
                          </button>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
            <div
              class='tab-pane fade'
              id='nav-engineer'
              role='tabpanel'
              aria-labelledby='nav-engineer-tab'
            >
              <ul className='course-grid card__Container'>
                {allcourse
                  .filter((course) => course.Course_Category === 'CompTIA eLearning')
                  .map((item) => (
                    <li className='item' key={`eng-${item.id}`}>
                      <div className='wrapper position-relative '>
                        <img
                          src={selfpac}
                          alt='course'
                          className='position-absolute  start-0'
                          style={{width: '170px', top: '20px'}}
                        />
                        <div className='image-wrapper'>
                          <img src={item.image_link} alt='course' />
                        </div>
                        <div className='details'>
                          <h5>{item.title}</h5>
                          <div className='list-item'>
                            <img src={time} alt='time' />
                            {item.duration ? (
                              <div dangerouslySetInnerHTML={{__html: item?.duration}} />
                            ) : (
                              'NA'
                            )}
                          </div>

                          <div className='list-item'>
                            <img src={badge} alt='badge' />{' '}
                            <span
                              style={{width: '100%'}}
                              dangerouslySetInnerHTML={{
                                __html: item.certifications ? item.certifications?.trim() : '',
                              }}
                            />
                          </div>
                          <div className='list-item'>
                            <img src={Comptia} alt='bolt' /> CompTIA Labs, Learn & Practice
                          </div>
                          <div className='list-item'>
                            <img src={ExamVoucher} alt='bolt' /> CompTIA Exam Voucher
                          </div>
                        </div>
                        <div class='button-group' style={{justifyContent: 'end'}}>
                          <button
                            style={{border: '0px'}}
                            class='button white'
                            onClick={() => {
                              if (item.Course_Category === 'CompTIA eLearning') {
                                navigate(
                                  `/comptia-learning?/${item.course_code}/${item.courseslug}#learning`
                                )
                              } else if (item.Course_Category === 'CompTIA Bootcamp') {
                                navigate(
                                  `/compitia_bootcamp?/${item.course_code}/${item.courseslug}#bootcamp`
                                )
                              } else if (item.Course_Category === 'Microsoft Bootcamp') {
                                navigate(
                                  `/microsoft-bootcamp?/${item.course_code}/${item.courseslug}#microsoft`
                                )
                              }
                            }}
                          >
                            VIEW COURSE
                          </button>
                          <button
                            style={{border: '0px'}}
                            className='button primary'
                            onClick={() => {
                              if (item.Course_Category === 'CompTIA eLearning') {
                                navigate(
                                  `/comptia-learning?/${item.course_code}/${item.courseslug}#learning`
                                )
                              } else if (item.Course_Category === 'CompTIA Bootcamp') {
                                navigate(
                                  `/compitia_bootcamp?/${item.course_code}/${item.courseslug}#bootcamp`
                                )
                              } else if (item.Course_Category === 'Microsoft Bootcamp') {
                                navigate(
                                  `/microsoft-bootcamp?/${item.course_code}/${item.courseslug}#microsoft`
                                )
                              }
                              localStorage.setItem('price', item.paid_price)
                            }}
                          >
                            BUY NOW
                          </button>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
            <div
              class='tab-pane fade'
              id='nav-architech'
              role='tabpanel'
              aria-labelledby='nav-architech-tab'
            >
              ARCHITECT
            </div>
          </div>

          {/* <div
            style={{
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              justifyContent: 'end',
            }}
          >
            <NavLink to='/All_bundles' style={{color: 'white'}}>
              <a class='button button-rounded'>View All Bundles</a>
            </NavLink>
          </div> */}
        </div>
      </section>

      <div className='responsive-bundletestimonal p-10'>
        <h2 style={{color: 'grey', fontSize: '18px'}}>ALL COURSES</h2>
        <Flickity
          className={'your-slider-container'} // Add your class name
          options={{
            contain: true,
            wrapAround: true,
            // Add other Flickity options as needed
          }}
        >
          {(currentRecords || []).map((item, index) => (
            <div key={`sl-${index}`}>
              <ul className='course-grid card__Container'>
                <li className='item'>
                  <div className='wrapper position-relative'>
                    {item.Coursetype === 'Bundles' && (
                      <img
                        src={bundlesq}
                        alt='course'
                        className='position-absolute start-0'
                        style={{width: '170px', top: '20px'}}
                      />
                    )}
                    {item.Coursetype === 'Courses' && (
                      <img
                        src={item.Course_Category === 'CompTIA eLearning' ? selfpac : bootcamp}
                        alt='course'
                        className='position-absolute start-0'
                        style={{width: '170px', top: '20px'}}
                      />
                    )}

                    <div className='image-wrapper'>
                      <img
                        src={item.Coursetype === 'Courses' ? item.image_link : item.ImageLink || ''}
                        alt='course'
                      />
                    </div>

                    <div className='details'>
                      <h5>{item.Coursetype === 'Courses' ? item.title : item.Name || ''}</h5>

                      <div className='list-item'>
                        {' '}
                        <img src={time} alt='time' />
                        {item.duration ? (
                          <div dangerouslySetInnerHTML={{__html: item?.duration}} />
                        ) : (
                          'NA'
                        )}
                      </div>
                      {item.Coursetype === 'Bundles' && (
                        <>
                          {item.certifications && item.certifications.length > 0 && (
                            <div>
                              {item.certifications.split('|').map((certification, index) => (
                                <div key={`${item.id}-${index}`} className='list-item'>
                                  <img src={badge} alt='certification' />{' '}
                                  <span
                                    style={{width: '100%'}}
                                    dangerouslySetInnerHTML={{__html: certification?.trim()}}
                                  />
                                </div>
                              ))}
                            </div>
                          )}

                          <div className='list-item'>
                            <img src={Comptia} alt='certification' /> CompTIA Labs, Learn & Practice
                          </div>
                          <div className='list-item'>
                            <img src={ExamVoucher} alt='certification' /> CompTIA Exam Voucher
                          </div>
                          <div className='list-item'>
                            <img src={ChallengeLabs} alt='certification' /> Skillable Challenge Labs
                          </div>
                          <div className='list-item'>
                            <img src={Mentoring} alt='certification' /> Cyberheads Mentoring
                          </div>
                        </>
                      )}

                      {item.Course_Category === 'Microsoft Bootcamp' && (
                        <div className='list-item'>
                          <img src={badge} alt='badge' />

                          {(() => {
                            const trimmedCertifications = item.certifications
                            return (
                              <span
                                style={{width: '100%'}}
                                dangerouslySetInnerHTML={{__html: trimmedCertifications?.trim()}}
                              />
                            )
                          })()}
                        </div>
                      )}

                      {item.Course_Category === 'CompTIA Bootcamp' && (
                        <>
                          {(() => {
                            const trimmedCertifications = item.certifications
                              ? item.certifications.trim()
                              : ''

                            return (
                              <>
                                <div className='list-item'>
                                  <img src={badge} alt='badge' />{' '}
                                  <span
                                    style={{width: '100%'}}
                                    dangerouslySetInnerHTML={{
                                      __html: trimmedCertifications?.trim(),
                                    }}
                                  />
                                </div>
                                <div className='list-item'>
                                  <img src={Comptia} alt='bolt' /> CompTIA Labs, Learn & Practice
                                </div>
                                <div className='list-item'>
                                  <img src={ExamVoucher} alt='bolt' /> CompTIA Exam Voucher
                                </div>
                              </>
                            )
                          })()}
                        </>
                      )}

                      {item.Course_Category === 'CompTIA eLearning' && (
                        <>
                          {(() => {
                            const trimmedCertifications = item.certifications
                              ? item.certifications.trim()
                              : ''

                            return (
                              <>
                                <div className='list-item'>
                                  <img src={badge} alt='badge' />
                                  <span
                                    style={{width: '100%'}}
                                    dangerouslySetInnerHTML={{
                                      __html: trimmedCertifications?.trim(),
                                    }}
                                  />
                                </div>
                                <div className='list-item'>
                                  <img src={Comptia} alt='bolt' /> CompTIA Labs, Learn & Practice
                                </div>
                                <div className='list-item'>
                                  <img src={ExamVoucher} alt='bolt' /> CompTIA Exam Voucher
                                </div>
                              </>
                            )
                          })()}
                        </>
                      )}
                    </div>
                    <div className='button-group' style={{justifyContent: 'end'}}>
                      {item.Coursetype === 'Courses' && (
                        <button
                          style={{border: '0px'}}
                          className='button white'
                          onClick={() => {
                            if (item.Course_Category === 'CompTIA eLearning') {
                              navigate(
                                `/comptia-learning?/${item.course_code}/${item.courseslug}#learning`
                              )
                            } else if (item.Course_Category === 'CompTIA Bootcamp') {
                              navigate(
                                `/compitia_bootcamp?/${item.course_code}/${item.courseslug}#bootcamp`
                              )
                            } else if (item.Course_Category === 'Microsoft Bootcamp') {
                              navigate(
                                `/microsoft-bootcamp?/${item.course_code}/${item.courseslug}#microsoft`
                              )
                            }
                          }}
                        >
                          VIEW COURSE
                        </button>
                      )}

                      {item.Coursetype === 'Bundles' && (
                        <Link
                          to={`/bundle_details/${item.bundle_slug}/${item.bundle_code}#bundletitle`}
                          className='button white'
                          onClick={() => {
                            localStorage.setItem('paid_price', item.price)
                            localStorage.setItem('Name', item.Name)
                          }}
                        >
                          VIEW COURSE
                        </Link>
                      )}

                      {item.Coursetype === 'Courses' && (
                        <button
                          style={{border: '0px'}}
                          className='button primary'
                          onClick={() => {
                            if (item.Course_Category === 'CompTIA eLearning') {
                              navigate(
                                `/comptia-learning?/${item.course_code}/${item.courseslug}#learning`
                              )
                            } else if (item.Course_Category === 'CompTIA Bootcamp') {
                              navigate(
                                `/compitia_bootcamp?/${item.course_code}/${item.courseslug}#bootcamp`
                              )
                            } else if (item.Course_Category === 'Microsoft Bootcamp') {
                              navigate(
                                `/microsoft-bootcamp?/${item.course_code}/${item.courseslug}#microsoft`
                              )
                            }
                          }}
                        >
                          BUY NOW
                        </button>
                      )}

                      {item.Coursetype === 'Bundles' && (
                        <Link
                          to={`/bundle_details/${item.bundle_slug}/${item.bundle_code}#bundletitle`}
                          className='button primary'
                          onClick={() => {
                            localStorage.setItem('paid_price', item.price)
                            localStorage.setItem('Name', item.Name)
                          }}
                        >
                          BUY NOW
                        </Link>
                      )}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          ))}
        </Flickity>
      </div>

      <div className='responsive-bundletestimonal p-10'>
        <h2 style={{paddingTop: '4rem', color: 'grey', fontSize: '18px'}}>ROLE BASED BUNDLES</h2>
        <Flickity
          className={'your-slider-container'}
          options={{
            contain: true,
            wrapAround: true,
          }}
        >
          {bundle.slice(0, 3).map((item, index) => (
            <div key={`rb-${item.id}`}>
              <ul className='course-grid card__Container'>
                <li className='item'>
                  <div className='wrapper position-relative '>
                    <img
                      src={bundlesq}
                      alt='course'
                      className='position-absolute  start-0'
                      style={{width: '170px', top: '20px'}}
                    />
                    <div className='image-wrapper'>
                      <img src={item.ImageLink} alt='course' />
                    </div>
                    <div className='details'>
                      <h5>{item.Name}</h5>

                      <div className='list-item'>
                        <img src={time} alt='time' />{' '}
                        {item.duration ? (
                          <div dangerouslySetInnerHTML={{__html: item?.duration}} />
                        ) : (
                          'NA'
                        )}
                      </div>

                      {item.certifications && item.certifications.length > 0 && (
                        <div>
                          {item.certifications.split('|').map((certification, index) => (
                            <div key={`${item.id}-${index}`} className='list-item'>
                              <img src={badge} alt='certification' />{' '}
                              <span
                                style={{width: '100%'}}
                                dangerouslySetInnerHTML={{__html: certification?.trim()}}
                              />
                            </div>
                          ))}
                        </div>
                      )}

                      <div className='list-item'>
                        {' '}
                        <img src={Comptia} alt='certification' /> CompTIA Labs, Learn & Practice
                      </div>
                      <div className='list-item'>
                        {' '}
                        <img src={ExamVoucher} alt='certification' /> CompTIA Exam Voucher
                      </div>
                      <div className='list-item'>
                        {' '}
                        <img src={ChallengeLabs} alt='certification' /> Skillable Challenge Labs
                      </div>
                      <div className='list-item'>
                        {' '}
                        <img src={Mentoring} alt='certification' /> Cyberheads Mentoring
                      </div>
                    </div>

                    <div className='button-group' style={{justifyContent: 'end'}}>
                      <Link
                        to={`/bundle_details/${item.bundle_slug}/${item.bundle_code}#bundletitle`}
                        class='button white'
                        onClick={() => {
                          localStorage.setItem('paid_price', item.price)
                          localStorage.setItem('Name', item.Name)
                        }}
                      >
                        {' '}
                        VIEW BUNDLE
                      </Link>

                      <Link
                        to={`/bundle_details/${item.bundle_slug}/${item.bundle_code}#bundletitle`}
                        className='button primary'
                        onClick={() => {
                          localStorage.setItem('paid_price', item.price)
                          localStorage.setItem('Name', item.Name)
                        }}
                      >
                        BUY NOW
                      </Link>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          ))}
        </Flickity>
      </div>

      <div className='responsive-bundletestimonal p-10'>
        <h2 style={{paddingTop: '4rem', color: 'grey', fontSize: '18px'}}>MICROSOFT BOOTCAMP</h2>
        <Flickity
          className={'your-slider-container'} // Add your class name
          options={{
            contain: true,
            wrapAround: true,
            // Add other Flickity options as needed
          }}
        >
          {allcourse
            .filter((course) => course.Course_Category === 'Microsoft Bootcamp')
            .map((item) => (
              <div key={`mb-${item.id}`}>
                <ul className='course-grid card__Container'>
                  <li className='item' key={item.id}>
                    <div className='wrapper position-relative '>
                      <img
                        src={bootcamp}
                        alt='course'
                        className='position-absolute  start-0'
                        style={{width: '170px', top: '20px'}}
                      />
                      <div className='image-wrapper'>
                        <img src={item.image_link} alt='course' />
                      </div>
                      <div className='details'>
                        <h5>{item.title}</h5>
                        <div className='list-item'>
                          <img src={time} alt='time' />{' '}
                          {item.duration ? (
                            <div dangerouslySetInnerHTML={{__html: item?.duration}} />
                          ) : (
                            'NA'
                          )}
                        </div>
                        <div className='list-item'>
                          <img src={badge} alt='badge' />{' '}
                          <span
                            style={{width: '100%'}}
                            dangerouslySetInnerHTML={{__html: item?.certifications}}
                          />
                        </div>
                      </div>
                      <div class='button-group' style={{justifyContent: 'end'}}>
                        <button
                          style={{border: '0px'}}
                          class='button white'
                          onClick={() => {
                            if (item.Course_Category === 'CompTIA eLearning') {
                              navigate(
                                `/comptia-learning?/${item.course_code}/${item.courseslug}#learning`
                              )
                            } else if (item.Course_Category === 'CompTIA Bootcamp') {
                              navigate(
                                `/compitia_bootcamp?/${item.course_code}/${item.courseslug}#bootcamp`
                              )
                            } else if (item.Course_Category === 'Microsoft Bootcamp') {
                              navigate(
                                `/microsoft-bootcamp?/${item.course_code}/${item.courseslug}#microsoft`
                              )
                            }
                          }}
                        >
                          VIEW COURSE
                        </button>
                        <button
                          style={{border: '0px'}}
                          className='button primary'
                          onClick={() => {
                            if (item.Course_Category === 'CompTIA eLearning') {
                              navigate(
                                `/comptia-learning?/${item.course_code}/${item.courseslug}#learning`
                              )
                            } else if (item.Course_Category === 'CompTIA Bootcamp') {
                              navigate(
                                `/compitia_bootcamp?/${item.course_code}/${item.courseslug}#bootcamp`
                              )
                            } else if (item.Course_Category === 'Microsoft Bootcamp') {
                              navigate(
                                `/microsoft-bootcamp?/${item.course_code}/${item.courseslug}#microsoft`
                              )
                            }
                          }}
                        >
                          BUY NOW
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            ))}
        </Flickity>
      </div>

      <div className='responsive-bundletestimonal p-10'>
        <h2 style={{paddingTop: '4rem', color: 'grey', fontSize: '18px'}}>COMPTIA BOOTCAMP</h2>
        <Flickity
          className={'your-slider-container'} // Add your class name
          options={{
            contain: true,
            wrapAround: true,
            // Add other Flickity options as needed
          }}
        >
          {allcourse
            .filter((course) => course.Course_Category === 'CompTIA Bootcamp')
            .map((item) => (
              <div key={item.id}>
                <ul className='course-grid card__Container'>
                  <li className='item' key={item.id}>
                    <div className='wrapper position-relative '>
                      <img
                        src={bootcamp}
                        alt='course'
                        className='position-absolute  start-0'
                        style={{width: '170px', top: '20px'}}
                      />
                      <div className='image-wrapper'>
                        <img src={item.image_link} alt='course' />
                      </div>
                      <div className='details'>
                        <h5>{item.title}</h5>
                        <div className='list-item'>
                          <img src={time} alt='time' />{' '}
                          {item.duration ? (
                            <div dangerouslySetInnerHTML={{__html: item?.duration}} />
                          ) : (
                            'NA'
                          )}
                        </div>
                        <div className='list-item'>
                          <img src={badge} alt='badge' />{' '}
                          <span
                            style={{width: '100%'}}
                            dangerouslySetInnerHTML={{
                              __html: item.certifications ? item.certifications?.trim() : '',
                            }}
                          />
                        </div>
                        <div className='list-item'>
                          <img src={Comptia} alt='bolt' /> CompTIA Labs, Learn & Practice
                        </div>
                        <div className='list-item'>
                          <img src={ExamVoucher} alt='bolt' /> CompTIA Exam Voucher
                        </div>
                      </div>
                      <div class='button-group' style={{justifyContent: 'end'}}>
                        <button
                          style={{border: '0px'}}
                          class='button white'
                          onClick={() => {
                            if (item.Course_Category === 'CompTIA eLearning') {
                              navigate(
                                `/comptia-learning?/${item.course_code}/${item.courseslug}#learning`
                              )
                            } else if (item.Course_Category === 'CompTIA Bootcamp') {
                              navigate(
                                `/compitia_bootcamp?/${item.course_code}/${item.courseslug}#bootcamp`
                              )
                            } else if (item.Course_Category === 'Microsoft Bootcamp') {
                              navigate(
                                `/microsoft-bootcamp?/${item.course_code}/${item.courseslug}#microsoft`
                              )
                            }
                          }}
                        >
                          VIEW COURSE
                        </button>
                        <button
                          style={{border: '0px'}}
                          className='button primary'
                          onClick={() => {
                            if (item.Course_Category === 'CompTIA eLearning') {
                              navigate(
                                `/comptia-learning?/${item.course_code}/${item.courseslug}#learning`
                              )
                            } else if (item.Course_Category === 'CompTIA Bootcamp') {
                              navigate(
                                `/compitia_bootcamp?/${item.course_code}/${item.courseslug}#bootcamp`
                              )
                            } else if (item.Course_Category === 'Microsoft Bootcamp') {
                              navigate(
                                `/microsoft-bootcamp?/${item.course_code}/${item.courseslug}#microsoft`
                              )
                            }
                          }}
                        >
                          BUY NOW
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            ))}
        </Flickity>
      </div>

      <div className='responsive-bundletestimonal p-10'>
        <h2 style={{paddingTop: '4rem', color: 'grey', fontSize: '18px'}}>SELF PACED</h2>
        <Flickity
          className={'your-slider-container'} // Add your class name
          options={{
            contain: true,
            wrapAround: true,
            // Add other Flickity options as needed
          }}
        >
          {allcourse
            .filter((course) => course.Course_Category === 'CompTIA eLearning')
            .map((item) => (
              <div key={item.id}>
                <ul className='course-grid card__Container'>
                  <li className='item' key={item.id}>
                    <div className='wrapper position-relative '>
                      <img
                        src={selfpac}
                        alt='course'
                        className='position-absolute  start-0'
                        style={{width: '170px', top: '20px'}}
                      />
                      <div className='image-wrapper'>
                        <img src={item.image_link} alt='course' />
                      </div>
                      <div className='details'>
                        <h5>{item.title}</h5>
                        <div className='list-item'>
                          <img src={time} alt='time' />
                          {item.duration ? (
                            <div dangerouslySetInnerHTML={{__html: item?.duration}} />
                          ) : (
                            'NA'
                          )}
                        </div>

                        <div className='list-item'>
                          <img src={badge} alt='badge' />{' '}
                          <span
                            style={{width: '100%'}}
                            dangerouslySetInnerHTML={{
                              __html: item.certifications ? item.certifications?.trim() : '',
                            }}
                          />
                        </div>
                        <div className='list-item'>
                          <img src={Comptia} alt='bolt' /> CompTIA Labs, Learn & Practice
                        </div>
                        <div className='list-item'>
                          <img src={ExamVoucher} alt='bolt' /> CompTIA Exam Voucher
                        </div>
                      </div>
                      <div class='button-group' style={{justifyContent: 'end'}}>
                        <button
                          style={{border: '0px'}}
                          class='button white'
                          onClick={() => {
                            if (item.Course_Category === 'CompTIA eLearning') {
                              navigate(
                                `/comptia-learning?/${item.course_code}/${item.courseslug}#learning`
                              )
                            } else if (item.Course_Category === 'CompTIA Bootcamp') {
                              navigate(
                                `/compitia_bootcamp?/${item.course_code}/${item.courseslug}#bootcamp`
                              )
                            } else if (item.Course_Category === 'Microsoft Bootcamp') {
                              navigate(
                                `/microsoft-bootcamp?/${item.course_code}/${item.courseslug}#microsoft`
                              )
                            }
                          }}
                        >
                          VIEW COURSE
                        </button>
                        <button
                          style={{border: '0px'}}
                          className='button primary'
                          onClick={() => {
                            if (item.Course_Category === 'CompTIA eLearning') {
                              navigate(
                                `/comptia-learning?/${item.course_code}/${item.courseslug}#learning`
                              )
                            } else if (item.Course_Category === 'CompTIA Bootcamp') {
                              navigate(
                                `/compitia_bootcamp?/${item.course_code}/${item.courseslug}#bootcamp`
                              )
                            } else if (item.Course_Category === 'Microsoft Bootcamp') {
                              navigate(
                                `/microsoft-bootcamp?/${item.course_code}/${item.courseslug}#microsoft`
                              )
                            }
                            localStorage.setItem('price', item.paid_price)
                          }}
                        >
                          BUY NOW
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            ))}
        </Flickity>
      </div>
    </div>
  )
}

export default BundletabTestimonial
