import './loader.css'

function Loader() {
  return (
    <div id='preloader'>
      <div id='loader'></div>
    </div>

  
    
  )
}

export default Loader;
