import React from 'react'
import need from '../../PrivateImage/img/need-real-expert.png'

import knowmore from '../../PrivateImage/img/knowmore.png'
import microcomptia from '../../PrivateImage/img/microcomptia.png'
import {Link} from 'react-router-dom'
import Assetone from '../../PrivateImage/img/Assetone.png'
import Assettwo from '../../PrivateImage/img/Assettwo.png'
import Assetthree from '../../PrivateImage/img/Assetthree.png'

import './Hero.css'
const testimonials = [
  {
    image: Assetone,
    text: 'Thoughtfully Customised Courses',
  },
  {
    image: Assettwo,
    text: 'Elite Partnerships',
  },
  {
    image: Assetthree,
    text: 'Experienced Mentors',
  },
]

const Hero = () => {
  return (
    <div>
      <section className='real-expert'>
        <div className='home_page-title'>
          <span className='home-title'>
            Created for your career, <br />
            curated for the industry
          </span>
        </div>
        <p className='home-paragarph'>
          We work with industry recruiters to design courses that are relevant and current. Our
          ‘bundles’ of multiple courses, as well as individual courses are tailored for specific
          security roles in the field. Accredited by giants like Microsoft & CompTIA, our certified
          trainers provide comprehensive understanding of subject matter & enable your upskilling.
        </p>
        <div className='homeimg'>
          <Link to='/aboutus'>
            <img className='knowmore' src={knowmore} alt='knowmore' style={{width: '150px'}} />
          </Link>
        </div>

        <div className='need_img'>
          <img src={need} alt='need' />
        </div>

        <div className='need_imgresponsive'>
          {/* <img src={need} alt='need' /> */}

          <div id='testimonials' className='carousel slide' data-bs-ride='carousel'>
            <ol className='carousel-indicators'>
              {testimonials.map((_, index) => (
                <button
                  key={index}
                  type='button'
                  data-bs-target='#testimonials'
                  data-bs-slide-to={index}
                  className={`carousel-indicator ${index === 0 ? 'active' : ''}`}
                  aria-current={index === 0}
                  aria-label={`Slide ${index}`}
                ></button>
              ))}
            </ol>
            <div className='carousel-inner'>
              {testimonials.map((testimonial, index) => (
                <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
                  <div>
                    <img src={testimonial.image} className='d-block w-100' alt={`img${index}`} />
                    <div className='d-flex justify-content-center'>
                      <h2
                        className='testi-name'
                        style={{fontSize: '24px', width: '67%', fontWeight: '400'}}
                      >
                        {testimonial.text}
                      </h2>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className='text_consumed'>
          <div class='d-flex justify-content-center'>
            <div class='col Knowmore'>
              <span>Thoughtfully Customised Courses</span>
            </div>

            <div class='col Knowmore'>
              <span>Elite Partnerships</span>
              <img src={microcomptia} alt='microcomptia' style={{width: '80%'}} />
            </div>

            <div class='col Knowmore'>
              <span>Experienced Mentors</span>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Hero
