import React, {useEffect} from 'react'
import {PreviewDetailsAction} from '../../../../Redux/Action/courseAction'

import {useDispatch, useSelector} from 'react-redux'
const Whyconsider = () => {
  var newURL = window.location

  var splitURL = newURL.href.split('/')

  const courseslug = splitURL[5]
  const dispatch = useDispatch()

  const previewdetails = useSelector((state) => {
    // debugger
    return state.login.getpreviewdetailslist
  })

  useEffect(() => {
    // debugger
    dispatch(PreviewDetailsAction(courseslug))
  }, [courseslug])

  let cdata = ''
  return (
    <div>
      {Object.entries(previewdetails).map(([key, course], i) => {
        if (key === 'data') {
          cdata = course
        }
      })}

      {cdata && cdata.why_consider && (
        <div>
          <h4>Why Consider:</h4>
          <p
            dangerouslySetInnerHTML={{
              __html:
                cdata.why_consider.length > 100
                  ? `${cdata.why_consider.slice(0, 10000)}`
                  : cdata.why_consider,
            }}
            style={{fontFamily: 'Sora, sans-serif', fontSize: '16px', paddingTop: '20px'}}
          />
        </div>
      )}
    </div>
  )
}

export default Whyconsider
