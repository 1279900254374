import React, {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {UserByRolesActions, GetallRoleAction} from '../../../Redux/Action/roleAction'
import {useDispatch, useSelector} from 'react-redux'
import {CgProfile} from 'react-icons/cg'
import {Tooltip} from 'antd'
import {ArrowLeftOutlined} from '@ant-design/icons'
const ViewRole = () => {
  // UserByRolesActions
  const navigate = useNavigate()
  const dispatch = useDispatch()

  var newURL = window.location

  var splitURL = newURL.href.split('/')

  const id = splitURL[4]

  const roledetails = useSelector((state) => {
    return state.login.getuserbyrole_list
  })
  const allRoles = useSelector((state) => {
    return state.login.getAllRoles_list
  })

  useEffect(() => {
    dispatch(GetallRoleAction())
  }, [dispatch])

  useEffect(() => {
    dispatch(UserByRolesActions(id))
  }, [dispatch, id])

  let cdata = ''
  let rolename = ''
  let permissionDesc = ''
  return (
    <div>
      <Link
        to='/user_role'
        style={{
          fontSize: '20px',
          color: '#FF763F',
          fontWeight: 'bold',
          paddingTop: '5px',
          paddingRight: '5px',
        }}
      >
        <ArrowLeftOutlined />
      </Link>
      <div className='d-flex flex-column flex-lg-row'>
        <div className='col-lg-4 col-md-4 col-sm-12'>
          <div className='card card-flush'>
            {Object.entries(roledetails).map(([key, course], i) => {
              if (key == 'data') {
                cdata = course
              }
            })}
            {Object.entries(allRoles).map(([key, role], i) => {
              if (role.id === parseInt(id)) {
                rolename = role.rolename
                permissionDesc = role.permission_desc
              }
            })}

            <div className='card-header'>
              <div className='card-title'>
                <h2 className='mb-0'>{rolename}</h2>
              </div>
            </div>

            <div className='card-body pt-0'>
              <div className='d-flex flex-column text-gray-600'>
                {permissionDesc !== null
                  ? permissionDesc.split('|').map((permission, innerIndex) => (
                      <div key={innerIndex} className='d-flex align-items-center py-2'>
                        <span className='bullet bg-primary me-3'></span>
                        {permission.replace(/'/g, '')}
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </div>
        </div>
        <div className='user-list col-md-8'>
          <div id='kt_app_content_container'>
            <div class='card'>
              <div class='card-body py-4'>
                {Object.entries(roledetails).map(([key, course], i) => {
                  if (key == 'data') {
                    cdata = course
                  }
                })}
                <div className='table-responsive'>
                  <table class='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>
                    <thead>
                      <tr class='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                        <th class='w-10px pe-2'></th>

                        <th class='min-w-125px'>ID</th>
                        <th class='min-w-125px'>USER</th>
                        <th class='min-w-125px'>Email</th>
                        <th class='min-w-125px'>Action</th>
                      </tr>
                    </thead>
                    {Object.entries(cdata).length === 0 ? (
                      <tbody class='text-gray-600 fw-semibold'>
                        <tr>
                          <td colSpan='5 ' class='text-center'>
                            No data available
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      Object.entries(cdata).map(([key, item], i) => {
                        // Increment the counter for each item
                        const serialNumber = i + 1
                        return (
                          <tbody class='text-gray-600 fw-semibold'>
                            <tr>
                              <td></td>

                              <td>{serialNumber}</td>

                              <td class='d-flex align-items-center'>
                                <div class='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                                  <Link to='#'>
                                    <div class='symbol-label'>
                                      <img src={item.profileUrl} class='w-100' alt='profile pic' />
                                    </div>
                                  </Link>
                                </div>

                                <div class='d-flex flex-column'>
                                  <span>{item && item.FirstName + ' ' + item.Surname}</span>
                                </div>
                              </td>

                              <td>
                                <div class='badge badge-light fw-bold'>{item.Email}</div>
                              </td>

                              <td>
                                <div className='badge badge-light fw-bold'>
                                  {item.roleid === '2' ||
                                  item.roleid === '1' ||
                                  item.roleid === '3' ||
                                  item.roleid === '5' ||
                                  item.roleid === '6' ||
                                  item.roleid === '7' ? (
                                    <Tooltip title='UserDetails'>
                                      <button
                                        onClick={() => navigate(`/viewDetails/${item.id}`)}
                                        style={{border: '0', padding: '5px', background: 'white'}}
                                      >
                                        <span
                                          style={{
                                            fontSize: '18px',
                                            paddingRight: '5px',
                                            color: 'rgb(126, 130, 153)',
                                          }}
                                        >
                                          <CgProfile />
                                        </span>
                                      </button>
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title='View Details'>
                                      <button
                                        onClick={() => navigate(`/UserDetails/${item.id}`)}
                                        style={{border: '0', padding: '5px', background: 'white'}}
                                      >
                                        <span
                                          style={{
                                            fontSize: '18px',
                                            paddingRight: '5px',
                                            color: 'rgb(126, 130, 153)',
                                          }}
                                        >
                                          <CgProfile />
                                        </span>
                                      </button>
                                    </Tooltip>
                                  )}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        )
                      })
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewRole
