import React, {useState, useEffect, useRef} from 'react'
import 'primereact/resources/themes/lara-light-indigo/theme.css' // theme
import 'primereact/resources/primereact.css' // core css
import {useDispatch, useSelector} from 'react-redux'
import {DeletedUserListAction, DeleteUserAction} from '../../../Redux/Action/usersAction'
import {FilterMatchMode, FilterOperator} from 'primereact/api'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {InputText} from 'primereact/inputtext'
import {Link, useNavigate} from 'react-router-dom'
import * as CountryStateCity from 'country-state-city'
import {MultiSelect} from 'primereact/multiselect'
import {FaTrash, FaUserPlus} from 'react-icons/fa'
import admin from '../../../assets/images/default.png'
import {Tooltip} from 'antd'

import {Button} from 'primereact/button'
import {Toolbar} from 'primereact/toolbar'
import './allusers.css'

import {AllRolesAction} from '../../../Redux/Action/usersAction'
import ExcelJS from 'exceljs'
import {UpdateuseridAction} from '../../../Redux/Action/authAction'
import {permanentlydeleteUser} from '../../../Redux/Action/usersAction'

export default function Deleteduserlist() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleDelete = async (id) => {
    await dispatch(permanentlydeleteUser(id))
    dispatch(DeletedUserListAction())
  }

  const handleSubmit1 = (id) => {
    const formData = new FormData()

    formData.append('userStatus', 1)

    dispatch(UpdateuseridAction(formData, id, navigate))
  }

  const dt = useRef(null)
  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({selectionOnly})
  }

  const exportExcel = (selectionOnly) => {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('User Data')

    const columns = [
      {header: 'User Name', key: 'username'},
      {header: 'Email', key: 'Email'},
      {header: 'Phone Number', key: 'PhoneNumber'},
      {header: 'Role', key: 'Role'},
      {header: 'Country', key: 'Country'},
    ]

    worksheet.columns = columns

    const dataToExport = selectionOnly ? customers : customers

    dataToExport.forEach((item) => {
      worksheet.addRow(item)
    })

    const fileName = `user_data_${new Date().getTime()}.xlsx`

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = fileName
      a.click()
      window.URL.revokeObjectURL(url)
    })
  }
  const [filters, setFilters] = useState({
    global: {value: null, matchMode: FilterMatchMode.CONTAINS},
    username: {value: null, matchMode: FilterMatchMode.CONTAINS},
    Email: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
    PhoneNumber: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
    AgeBracket: {value: null, matchMode: FilterMatchMode.IN},
    Role: {value: null, matchMode: FilterMatchMode.IN},
    stateName: {value: null, matchMode: FilterMatchMode.IN},
    CityName: {value: null, matchMode: FilterMatchMode.IN},
    CountryName: {value: null, matchMode: FilterMatchMode.IN},
  })

  const columns = [
    {field: 'username', header: 'User Name'},
    {field: 'Email', header: 'Email'},
    {field: 'PhoneNumber', header: 'Phone Number'},
    {field: 'Role', header: 'Role'},
    {field: 'Country', header: 'Country'},
  ]
  const allColumns = [
    {field: 'username', header: 'User Name'},
    {field: 'Email', header: 'Email'},
    {field: 'PhoneNumber', header: 'Phone Number'},
    {field: 'AgeBracket', header: 'Age Group'},
    {field: 'Role', header: 'Role'},
    {field: 'Country', header: 'Country'},
    {field: 'state', header: 'State/County'},
    {field: 'City', header: 'City'},
  ]
  const onColumnToggle = (event) => {
    let selectedColumns = event.value
    let orderedSelectedColumns = allColumns.filter((col) =>
      selectedColumns.some((sCol) => sCol.field === col.field)
    )

    setVisibleColumns(orderedSelectedColumns)
  }

  const [loading, setLoading] = useState(true)
  const [globalFilterValue, setGlobalFilterValue] = useState('')
  const [visibleColumns, setVisibleColumns] = useState(columns)
  const [selectedCutomers, setSelectedCutomers] = useState(null)

  const arrayOfIds = selectedCutomers?.map((customer) => customer.id)

  const handleBulkDelete = async () => {
    await dispatch(permanentlydeleteUser(arrayOfIds))
    dispatch(DeletedUserListAction())
  }

  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    let _filters = {...filters}

    _filters['global'].value = value

    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const multiSelectRef = useRef(null)

  const resetFilters = () => {
    if (dt.current) {
      dt.current.reset()
      if (multiSelectRef.current) {
        multiSelectRef.current.value = null
        setSelectedCountries([])
        setSelectedStates([])
      }
    }
  }

  const renderHeader = () => {
    return (
      <div className='flex justify-content-end'>
        <span className='p-input-icon-left'>
          <i className='pi pi-search' />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder='Keyword Search'
          />
        </span>
        <div className='resfilter_btn'>
          <button
            class='btn btn-md btn-secondary mx-2 filter_btn'
            label='Clear Filters'
            onClick={resetFilters}
          >
            Reset Filters
          </button>

          <button onClick={handleBulkDelete} className='btn btn-md btn-secondary mx-2 filter_btn'>
            Delete Rows
          </button>
        </div>
      </div>
    )
  }

  const header = renderHeader()

  const customers = useSelector((state) => {
    return state.login.deletedUserList.map((item, index) => {
      return {
        ...item,
        SNo: index + 1,
      }
    })

    setLoading(false)
  })

  useEffect(() => {
    dispatch(DeletedUserListAction()).then(() => {
      setLoading(false)
    })
  }, [dispatch])

  const [countryNames, setCountryNames] = useState({})
  const [stateNames, setStateNames] = useState({})
  const [cityNames, setCityNames] = useState({})
  const [selectedCountries, setSelectedCountries] = useState([])
  const [selectedStates, setSelectedStates] = useState([])

  const countriesArray = Object.keys(countryNames).map((countrydata) => ({
    name: countrydata,
  }))

  const stateArray = Object.keys(stateNames).map((state) => ({
    name: state,
  }))

  const cityArray = Object.keys(cityNames).map((city) => ({
    name: city,
  }))

  const handleCountryChange = (selectedCountry) => {
    setSelectedCountries(selectedCountry)
    const fetchedStateNames = {}
    Object.values(selectedCountry).forEach((country) => {
      const countryCode = countryNames[country.name]
      const states = CountryStateCity.State.getStatesOfCountry(countryCode)

      Object.values(states).forEach((statesData) => {
        if (!fetchedStateNames[statesData.name]) {
          fetchedStateNames[statesData.name] = {}
        }

        fetchedStateNames[statesData.name]['isoCode'] = statesData.isoCode
        fetchedStateNames[statesData.name]['countryCode'] = statesData.countryCode
      })
      setStateNames(fetchedStateNames)
    })
  }

  const handleStateChange = (selectedState) => {
    setSelectedStates(selectedState)
    const fetchedCityNames = {}
    Object.values(selectedState).forEach((state) => {
      const stateCode = stateNames[state.name]['isoCode']
      const countryCode = stateNames[state.name]['countryCode']
      const cities = CountryStateCity.City.getCitiesOfState(countryCode, stateCode)

      Object.values(cities).forEach((cityData) => {
        fetchedCityNames[cityData.name] = cityData.isoCode
      })
      setCityNames(fetchedCityNames)
    })
  }

  useEffect(() => {
    const fetchCountryAndStateNames = () => {
      const uniqueCountryCodes = CountryStateCity.Country.getAllCountries()

      const fetchedCountryNames = {}

      Object.values(uniqueCountryCodes).forEach((countrydata) => {
        fetchedCountryNames[countrydata.name] = countrydata.isoCode
      })

      setCountryNames(fetchedCountryNames)
    }
    fetchCountryAndStateNames()
  }, [])

  const deleteHandler = (props) => {
    dispatch(DeleteUserAction(props))
  }

  const AgeFilter = (props) => {
    const options = ['18-25', '26-30', '31-35', '36-40', '40+']

    return (
      <MultiSelect
        value={props.value}
        options={options}
        onChange={(e) => {
          props.filterApplyCallback(e.value)
        }}
        optionLabel=''
        placeholder='Any'
        className='p-column-filter'
        maxSelectedLabels={1}
        style={{minWidth: '10rem'}}
      />
    )
  }

  const countryFilter = (options) => {
    return (
      <MultiSelect
        value={selectedCountries}
        options={countriesArray}
        onChange={(e) => {
          options.filterApplyCallback(e.value)
          handleCountryChange(e.value)
        }}
        optionLabel='name'
        placeholder='Any'
        className='p-column-filter'
        maxSelectedLabels={1}
        style={{minWidth: '10rem'}}
      />
    )
  }

  const stateFilter = (options) => {
    return (
      <MultiSelect
        value={selectedStates}
        options={selectedCountries.length === 1 ? stateArray : []}
        onChange={(e) => {
          options.filterApplyCallback(e.value)
          handleStateChange(e.value)
        }}
        optionLabel='name'
        placeholder='Any'
        className='p-column-filter'
        maxSelectedLabels={1}
        style={{minWidth: '10rem'}}
      />
    )
  }

  const cityFilter = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={selectedCountries.length === 1 ? cityArray : []}
        onChange={(e) => {
          options.filterApplyCallback(e.value)
        }}
        optionLabel='name'
        placeholder='Any'
        className='p-column-filter'
        maxSelectedLabels={1}
        style={{minWidth: '10rem'}}
      />
    )
  }

  const [Rolename, setRolename] = useState('')

  const allRoles = useSelector((state) => {
    return state.login.getAllRolesList
  })

  useEffect(() => {
    dispatch(AllRolesAction())
  }, [dispatch])

  const RoleFilter = (options) => {
    if (!allRoles || allRoles.length === 0) {
      return null
    }

    const roleOptions = allRoles.map((role) => role.rolename)

    return (
      <MultiSelect
        value={options.value}
        options={roleOptions}
        onChange={(e) => {
          options.filterApplyCallback(e.value)
        }}
        optionLabel=''
        placeholder='Any'
        className='p-column-filter'
        maxSelectedLabels={1}
        style={{minWidth: '10rem'}}
      />
    )
  }
  const rightToolbarTemplate = () => {
    return (
      <>
        <Button
          label='CSV'
          icon='pi pi-upload'
          className='p-button-secondary cyberbtnallpage'
          onClick={() => exportCSV(false)}
        />
        <div style={{margin: '0 10px'}}></div>
        <Button
          label='Excel'
          icon='pi pi-upload'
          className='p-button-secondary cyberbtnallpage'
          onClick={() => exportExcel(false)}
        />
      </>
    )
  }
  const leftToolbarTemplate = () => {
    return (
      <MultiSelect
        value={visibleColumns}
        options={allColumns}
        optionLabel='header'
        onChange={onColumnToggle}
        className='w-full sm:w-20rem'
        display='chip'
      />
    )
  }

  const actionTemplate = (rowData) => {
    return (
      <div style={{display: 'flex', gap: '0px', marginLeft: '-2rem'}}>
        <Tooltip title='Restore User'>
          <button
            onClick={() => handleSubmit1(rowData.id)}
            style={{border: '0', padding: '5px', background: 'transparent'}}
          >
            <span style={{fontSize: '18px', color: 'rgb(126, 130, 153)'}}>
              <FaUserPlus />
            </span>
          </button>
        </Tooltip>

        <Tooltip title='Delete User'>
          <button
            onClick={() => handleDelete(rowData.id)}
            style={{border: '0', padding: '5px', background: 'transparent'}}
          >
            <span style={{fontSize: '18px', color: 'rgb(126, 130, 153)'}}>
              <FaTrash />
            </span>
          </button>
        </Tooltip>
      </div>
    )
  }
  let serialNumber = 0
  return (
    <div>
      <Toolbar className='mb-4' left={leftToolbarTemplate} right={rightToolbarTemplate} />

      <DataTable
        ref={dt}
        value={customers}
        paginator
        rows={20}
        tableStyle={{minWidth: '100%'}}
        dataKey='id'
        filters={filters}
        filterDisplay='row'
        className='card'
        globalFilterFields={[
          'SNo',
          'username',
          'Email',
          'PhoneNumber',
          'AgeBracket',
          'Role',
          'stateName',
          'CityName',
          'CountryName',
        ]}
        header={header}
        emptyMessage='No customers found.'
        rowClassName='table-row'
        selection={selectedCutomers}
        onSelectionChange={(e) => setSelectedCutomers(e.value)}
      >
        <Column selectionMode='multiple' headerStyle={{width: '3rem'}}></Column>
        <Column field='SNo' header='#' sortable />
        <Column
          body={(rowData) => (
            <div style={{display: 'flex', gap: '10px'}}>
              <div className='symbol symbol-circle symbol-50px overflow-hidden'>
                <Link to='#'>
                  <div className='symbol-label'>
                    {rowData.profileUrl ? (
                      <img src={rowData.profileUrl} alt='Emma Smith' className='w-100' />
                    ) : (
                      <img src={admin} alt='Default Profile' style={{maxWidth: '100px'}} />
                    )}
                  </div>
                </Link>
              </div>
            </div>
          )}
        />
        {visibleColumns.map((col) => (
          <Column
            sortable
            key={col.field}
            field={col.field}
            header={col.header}
            filter
            style={{minWidth: '15rem'}}
            filterField={(() => {
              switch (col.field) {
                case 'Country':
                  return 'CountryName'
                case 'state':
                  return 'stateName'
                case 'City':
                  return 'CityName'

                default:
                  return ''
              }
            })()}
            filterPlaceholder={`Search by ${col.header}`}
            filterElement={(() => {
              switch (col.field) {
                case 'Country':
                  return countryFilter
                case 'state':
                  return stateFilter
                case 'City':
                  return cityFilter
                case 'AgeBracket':
                  return AgeFilter
                case 'Role':
                  return RoleFilter
                default:
                  return ''
              }
            })()}
          />
        ))}
        <Column
          field='id'
          header='Action'
          body={actionTemplate}
          exportable={false}
          style={{marginLeft: '-12rem'}}
        />
      </DataTable>
    </div>
  )
}
