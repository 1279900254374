import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {Button} from '@mui/material'
import {EditOutlined} from '@ant-design/icons'
import Form from 'react-bootstrap/Form'
import {useEffect} from 'react'

import {Link, useNavigate} from 'react-router-dom'

import {useDispatch, useSelector} from 'react-redux'
import FormData from 'form-data'
import {useForm} from 'react-hook-form'

import {GetLessonAction} from '../../../../Redux/Action/createChapterAction'
import {UpdateLessonAction} from '../../../../Redux/Action/createChapterAction'
import CreateIcon from '@mui/icons-material/Create'

function Editlessons(props) {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm()
  const dispatch = useDispatch()

  const getlesson_id = useSelector((state) => {
    return state.login.getlessonidlist
  })

  useEffect(() => {
    dispatch(GetLessonAction(props))
  }, [dispatch])

  const [show, setShow] = useState(false)

  var newURL = window.location

  var splitURL = newURL.href.split('/')

  const chapterId = splitURL[4]

  useEffect(() => {
    setValue('lessonname', getlesson_id.lessonname)
    setValue('desc', getlesson_id.desc)
  }, [getlesson_id])

  const onSubmit = (data) => {
    const formData = new FormData()
    formData.append('lessonname', data.lessonname)
    formData.append('desc', data.desc)
    formData.append('courseId', localStorage.getItem('courseId'))
    formData.append('chapterId', chapterId)

    dispatch(UpdateLessonAction(formData, props))
  }

  return (
    <div>
      <div>
        
          <Link to='#' onClick={() => setShow(true)}>
          <span className='text float-right  border-radius  p-0' style={{color: 'black'}}>
            <CreateIcon />
          </span>
        </Link>
     
      </div>
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              textTransform: 'capitalize',
              letterSpacing: '.2rem',
              fontWeight: 500,
              fontSize: '1rem',
            }}
          >
            Edit Chapter
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              padding: '2rem',
              borderRadius: '.375rem',
            }}
          >
            <div>
              <label>Lessonname</label>
              <input
                className='form-control mb-4'
                type='text'
                name='lessonname'
                {...register('lessonname', {
                  required: 'lessonname Address is required',
                })}
                aria-invalid={errors.lessonname ? 'true' : 'false'}
              />
              {errors.lessonname && (
                <p className='alert_danger' role='alert' style={{marginTop: '1rem'}}>
                  {errors.lessonname?.message}
                </p>
              )}
            </div>
            <div>
              <label>desc</label>
              <input
                className='form-control mb-4'
                type='text'
                name='desc'
                {...register('desc', {
                  required: 'desc Address is required',
                })}
                aria-invalid={errors.desc ? 'true' : 'false'}
              />
              {errors.desc && (
                <p className='alert_danger' role='alert' style={{marginTop: '1rem'}}>
                  {errors.desc?.message}
                </p>
              )}
            </div>
            <Button
              style={{
                textAlign: 'right',
                width: ' 100%',
                color: '#ffff',
                fontSize: '.8rem',
                borderRadius: '.375rem',
                backgroundColor: 'rgb(81, 86, 190)',
                textTransform: 'capitalize',
                letterSpacing: '.2rem',
              }}
              type='submit'
              className=' btn-warning'
              disableRipple
            >
              save
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default Editlessons
