import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import './adduser.css'
import {DatePicker} from 'antd'
import {useForm} from 'react-hook-form'

import {Link, useNavigate} from 'react-router-dom'
import {
  GetAllCountriesAction,
  GetStateByCountryAction,
  GetCityByStateAction,
} from '../../../Redux/Action/locationAction'
import {
  GetallCourseAction,
  GetallCoursesBundlesAction,
  PreviewDetailsAction,
} from '../../../Redux/Action/courseAction'
import TimezoneSelect from 'react-timezone-select'
import {AddInstructorAdminAction} from '../../../Redux/Action/usersAction'
import {GetallteacherCourseAction} from '../../../Redux/Action/courseAction'
import {GetallbundleactionAction} from '../../../Redux/Action/bundleAction'
import {AllRolesAction} from '../../../Redux/Action/usersAction'

import mr from '../../../assets/images/female.jpg'
import admin from '../../../assets/images/maleimg.jpg'
import {ArrowLeftOutlined} from '@ant-design/icons'

const AddUser = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [selectedCountryISOCode, setSelectedCountryISOCode] = useState('')
  const [selectedStateISOCode, setSelectedStateISOCode] = useState('')
  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  )
  const [selectedOptionRole, setSelectedOptionRole] = useState('')
  const [startDate, setStartDate] = useState(new Date())
  const [selectedOptionPrefix, setSelectedOptionPrefix] = useState('')
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({
    courses: {},
  })

  const handleSelectChange = (event) => {
    const selectedOption = event.target.value
    setSelectedOptionRole(selectedOption)
  }

  const handleSelectPrefixChange = (event) => {
    const selectedOptionPre = event.target.value
    setSelectedOptionPrefix(selectedOptionPre)
  }
  const handleStartDateChange = (date) => {
    setStartDate(date)
  }
  const image = admin

  useEffect(() => {
    setSelectedTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)
  }, [])
  const formattedTimezone = selectedTimezone?.value || ''
  const {
    register,
    handleSubmit,
    clearErrors,
    watch,
    formState: {errors},
  } = useForm()

  let roleValue

  const validateEmailFormat = (value) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
    return emailRegex.test(value) || 'Enter a valid email address'
  }

  const onSubmit = (data) => {
    debugger
    const formData = new FormData()
    formData.append('roleid', selectedOptionRole)
    formData.append('PrefixMrMissMrs', selectedOptionPrefix)
    formData.append('FirstName', data.FirstName)
    formData.append('Surname', data.Surname)
    formData.append('Email', data.Email)
    formData.append('PhoneNumber', data.PhoneNumber)
    formData.append('Country', selectedCountryISOCode)
    formData.append('state', selectedStateISOCode)
    formData.append('timezone', formattedTimezone || selectedTimezone)
    formData.append('City', data.City)
    formData.append('Password', data.Password)
    formData.append('mtm_id', data.mtm_id)
    formData.append('comptia_id', data.comptia_id)
    formData.append('other_id', data.other_id)
    formData.append('qualification', data.qualification)
    formData.append('selectedCourses', JSON.stringify(selectedCheckboxes))
    {
      selectedOptionPrefix === 'Mr'
        ? formData.append('profileUrl', image)
        : formData.append('profileUrl', mr)
    }
    formData.append('MicrosoftdatesId', MicrosoftdatesId)
    formData.append('CompTIAdatesId', CompTIAdatesId)
    formData.append('courseDatesId', courseDatesId)
    if (selectedOptionRole === '1') {
      roleValue = 'Super Admin'
    } else if (selectedOptionRole === '2') {
      roleValue = 'Admin'
    } else if (selectedOptionRole === '3') {
      roleValue = 'Student'
    } else if (selectedOptionRole === '4') {
      roleValue = 'Instructor'
    } else if (selectedOptionRole === '5') {
      roleValue = 'Content Writer'
    } else if (selectedOptionRole === '6') {
      roleValue = 'Corporate Manager'
    } else if (selectedOptionRole === '7') {
      roleValue = 'Corporate Employee'
    } else {
      roleValue = ''
    }
    formData.append('Role', roleValue)
    formData.append('experiance', data.experiance)
    formData.append('courses', data.courses)
    formData.append('IBANNumber', data.IBANNumber)
    formData.append('AccountName', data.AccountName)
    formData.append('AccountNumber', data.AccountNumber)
    formData.append('AgeBracket', data.AgeBracket)
    formData.append('companyName', data.companyName)
    formData.append('dayRate', data.dayRate || 0)
    formData.append('YearsOfCyberSecurityExperience', data.YearsOfCyberSecurityExperience)
    formData.append('InterestedInCyberheadRole', data.InterestedInCyberheadRole)
    formData.append('paymentid', data.paymentid)
    formData.append('amount', data.amount)
    formData.append('course_code', selectedOption)
    formData.append('paymenttime', startDate)
    formData.append('Bundle_code', bundleCode || 0)
    formData.append('MicrosoftCourseId', microsoftBootcampId)
    formData.append('CompTIACourseId', comptiaBootcampId)
    formData.append('MicrosoftStartDate', MicrosoftStartDate)
    formData.append('MicrosoftEndDate', MicrosoftEndDate)
    formData.append('CompTIABootcampStartDate', ComptiaStartDate)
    formData.append('CompTIABootcampEndDate', ComptiaEndDate)
    formData.append('MicrosoftTimezone', MicrosoftTimezone)
    formData.append('CompTIATimezone', ComptiaTimezone)
    formData.append('CourseStartDate', courseStartDate)
    formData.append('CourseEndDate', courseEndDate)
    formData.append('CourseId', course?.data?.courseId || null)
    formData.append('courseTimezone', courseTimezone)
    formData.append('paymentlink', data.paymentlink || null)
    if (data.EducationLevel == 'Other') {
      formData.append('EducationLevel', data.OtherEducationLevel)
    } else {
      formData.append('EducationLevel', data.EducationLevel)
    }

    if (data.PurposeOfTakingTheCourse == 'Other') {
      formData.append('PurposeOfTakingTheCourse', data.OtherPurposeOfTakingTheCourse)
    } else {
      formData.append('PurposeOfTakingTheCourse', data.PurposeOfTakingTheCourse)
    }

    if (data.EmploymentStatus == 'Other') {
      formData.append('EmploymentStatus', data.OtherEmploymentStatus)
    } else {
      formData.append('EmploymentStatus', data.EmploymentStatus)
    }

    if (data.HowYouGotToKnow == 'Other') {
      formData.append('HowYouGotToKnow', data.OtherHowYouGotToKnow)
    } else {
      formData.append('HowYouGotToKnow', data.HowYouGotToKnow)
    }

    dispatch(AddInstructorAdminAction(formData, navigate))
  }

  const allcourse = useSelector((state) => {
    return state.login.getteachercourseslist
  })

  const [selectcourseId, setCourseId] = useState(null)
  const [courseStartDate, setCourseStartDate] = useState(null)
  const [courseEndDate, setCourseEndDate] = useState(null)
  const [MicrosoftStartDate, setMicrosoftStartDate] = useState(null)
  const [MicrosoftEndDate, setMicrosoftEndDate] = useState(null)
  const [ComptiaStartDate, setComptiaStartDate] = useState(null)
  const [ComptiaEndDate, setComptiaEndDate] = useState(null)
  const [MicrosoftTimezone, setMicrosoftTimezone] = useState(null)
  const [ComptiaTimezone, setComptiaTimezone] = useState(null)
  const [courseTimezone, setCourseTimezone] = useState(null)
  const [courseDatesId, setcourseDatesId] = useState(null)
  const [MicrosoftdatesId, setMicrosoftDatesId] = useState(null)
  const [CompTIAdatesId, setCompTIADatesId] = useState(null)

  useEffect(() => {
    dispatch(GetallteacherCourseAction())
  }, [dispatch])

  const allCountries = useSelector((state) => {
    return state.login.getAllCountriesList
  })

  useEffect(() => {
    dispatch(GetAllCountriesAction())
  }, [dispatch])

  const state = useSelector((state) => {
    return state.login.getStateByCountrList
  })

  useEffect(() => {
    dispatch(GetStateByCountryAction(selectedCountryISOCode))
  }, [dispatch, selectedCountryISOCode])

  const city = useSelector((state) => {
    return state.login.getCityByStateList
  })

  useEffect(() => {
    dispatch(GetCityByStateAction(selectedCountryISOCode, selectedStateISOCode))
  }, [dispatch, selectedCountryISOCode, selectedStateISOCode])

  const handleChange = (e) => {
    const selectedValue = e.target.value
    setSelectedCountryISOCode(selectedValue)
  }

  const handleStateChange = (e) => {
    const selectedState = e.target.value
    setSelectedStateISOCode(selectedState)
  }

  const allRoles = useSelector((state) => {
    return state.login.getAllRolesList
  })

  const filteredRoles = allRoles.filter((role) => role.id !== 1 && role.id !== '')

  useEffect(() => {
    dispatch(AllRolesAction())
  }, [dispatch])

  const Allcourse = useSelector((state) => {
    return state.login.getcourseslist
  })

  const getallcourse = Allcourse.Published || []

  useEffect(() => {
    dispatch(GetallCourseAction())
  }, [dispatch])

  const [selectedOption, setSelectedOption] = useState('')
  const [selectedType, setSelectedType] = useState('')

  const handleSelectChangee = (event) => {
    const selectedValue = event.target.value
    const isCourse = courses && courses.some((course) => course.props.value === selectedValue)
    const isBundle = bundles && bundles.some((bundle) => bundle.props.value === selectedValue)

    if (isCourse) {
      setSelectedType('course')
    } else if (isBundle) {
      setSelectedType('bundle')
    } else {
      console.log('Selected option is neither a course nor a bundle')
    }
    setSelectedOption(event.target.value)
  }

  const CourseBundle = useSelector((state) => state.login.getallCourseBundleslist)

  const courses = CourseBundle.Courses
    ? CourseBundle.Courses.map((item) => (
        <option key={item.id} value={item.course_code}>
          {item.title} (Course)
        </option>
      ))
    : null

  const bundles = CourseBundle.Bundles
    ? CourseBundle.Bundles.map((item) => (
        <option key={item.id} value={item.bundle_code}>
          {item.Name} (Bundle)
        </option>
      ))
    : null

  useEffect(() => {
    dispatch(GetallCoursesBundlesAction())
  }, [dispatch])

  const course = useSelector((state) => {
    return state.login.getpreviewdetailslist
  })

  useEffect(() => {
    dispatch(PreviewDetailsAction(selectedOption))
  }, [dispatch, selectedOption])

  const courseCategory =
    course.dateRange && course.dateRange[0] ? course.dateRange[0].Course_Category : null
  const courseId = course?.data?.id ?? null

  const bundle = useSelector((state) => {
    return state.login.getBundleall_list
  })
  let bundleCode = null

  if (bundle.data1 && bundle.data1.length > 0) {
    bundleCode = bundle.data1[0].bundle_code
  }
  const microsoftBootcampId = bundle?.categorizedData?.Microsoft_Bootcamp[0]?.id ?? null
  const comptiaBootcampId = bundle?.categorizedData?.Comptia_Bootcamp[0]?.id ?? null

  useEffect(() => {
    dispatch(GetallbundleactionAction(selectedOption))
  }, [dispatch, selectedOption])

  const handleDateSelection = (id, start, end, timezone, datesId) => {
    setCourseId(id)
    setCourseStartDate(start)
    setCourseEndDate(end)
    setCourseTimezone(timezone)
    setcourseDatesId(datesId)
  }

  const handleMicrosoftDate = (start, end, timezone, datesId) => {
    setMicrosoftStartDate(start)
    setMicrosoftEndDate(end)
    setMicrosoftTimezone(timezone)
    setMicrosoftDatesId(datesId)
  }

  const handleComptiaDate = (start, end, timezone, datesId) => {
    setComptiaStartDate(start)
    setComptiaEndDate(end)
    setComptiaTimezone(timezone)
    setCompTIADatesId(datesId)
  }

  const handleCheckboxChange = (id, value) => {
    setSelectedCheckboxes((prevSelected) => {
      const updatedCourseIds = {...prevSelected.courses}

      if (value.checked) {
        updatedCourseIds[value.id] = value.title
      } else {
        delete updatedCourseIds[value.id]
      }

      return {
        ...prevSelected,
        courses: updatedCourseIds,
      }
    })
  }
  const [yesNoValue, setYesNoValue] = useState('yes')
  const handleInputChange = (event) => {
    setYesNoValue(event.target.value)
  }

  function disabledDate(current) {
    const startOfToday = new Date()
    startOfToday.setHours(0, 0, 0, 0)
    return current && current.valueOf() < startOfToday.valueOf()
  }

  return (
    <div className='user-editorss'>
      <div className='card mb-5 mb-xl-10'>
        <form
          onSubmit={handleSubmit(onSubmit)}
          id='kt_account_profile_details_form'
          className='form'
        >
          <div className='card-body border-top border-0 p-9'>
            <div className='card-title m-0'>
              <h3 className='fw-bold m-0'>Add New User</h3>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                <span className='required'>Role</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <div className='col-lg-8 fv-row'>
                  <select
                    name='role'
                    data-placeholder='Select a Role...'
                    className='form-select form-select-solid form-select-lg fw-semibold'
                    value={selectedOptionRole}
                    onChange={handleSelectChange}
                  >
                    {errors.roleid && (
                      <p className='alert_danger' role='alert'>
                        {errors.roleid?.message}
                      </p>
                    )}
                    <option value=''>Select role........</option>
                    {filteredRoles.map((role) => (
                      <option key={role.id} value={role.id}>
                        {role.rolename}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                <span>Prefix</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <div className='col-lg-8 fv-row'>
                  <select
                    name='role'
                    data-placeholder='Select a Role...'
                    value={selectedOptionPrefix}
                    className='form-select form-select-solid form-select-lg fw-semibold'
                    onChange={handleSelectPrefixChange}
                  >
                    <option value=''>Select Prefix..</option>

                    <option value='Mr'>Mr</option>
                    <option value="Mr's">Mrs</option>
                    <option value='Miss'>Miss</option>
                  </select>
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-semibold fs-6'>Full Name</label>

              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      name='fname'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='First name'
                      {...register('FirstName', {
                        required: 'This is required field',
                      })}
                      aria-invalid={errors.FirstName ? 'true' : 'false'}
                    />
                    {errors.FirstName && (
                      <p className='alert_danger' role='alert'>
                        {errors.FirstName?.message}
                      </p>
                    )}
                  </div>

                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      name='fname'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Last name'
                      {...register('Surname', {
                        required: 'This is required field',
                      })}
                      aria-invalid={errors.Surname ? 'true' : 'false'}
                    />
                    {errors.Surname && (
                      <p className='alert_danger' role='alert'>
                        {errors.Surname?.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                <span className='required'>Telephone</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='tel'
                  name='phone'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Phone number'
                  {...register('PhoneNumber', {
                    required: 'This is a required field',
                  })}
                  aria-invalid={errors.PhoneNumber ? 'true' : 'false'}
                  onKeyPress={(e) => {
                    const isNumeric = /^[0-9]$/
                    if (!isNumeric.test(e.key)) {
                      e.preventDefault()
                    }
                  }}
                />
                {errors.PhoneNumber && (
                  <p className='alert_danger' role='alert'>
                    {errors.PhoneNumber?.message}
                  </p>
                )}
              </div>
            </div>{' '}
            <>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                  <span className='required'>Email</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    name='email'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Email'
                    {...register('Email', {
                      required: 'This is a required field',
                      validate: validateEmailFormat,
                    })}
                    aria-invalid={errors.Email ? 'true' : 'false'}
                  />
                  {errors.Email && (
                    <p className='alert_danger' role='alert'>
                      {errors.Email.message}
                    </p>
                  )}
                </div>
              </div>
            </>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                <span className='required'>Country</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <select
                  name='country'
                  aria-label='Select Country'
                  data-control='select2'
                  data-placeholder='Select a country...'
                  className='form-select'
                  {...register('Country', {required: 'This field is required'})}
                  aria-invalid={errors.Country ? 'true' : 'false'}
                  onChange={(e) => {
                    handleChange(e)
                    clearErrors('Country')
                  }}
                >
                  <option value=''>Select Country...</option>
                  {Object.values(allCountries).map((countryArr) =>
                    countryArr.map((country, i) => (
                      <option key={i} value={country.isoCode}>
                        {country.name}
                      </option>
                    ))
                  )}
                </select>

                {errors.Country && errors.Country.type === 'required' && (
                  <p className='alert_danger' role='alert' style={{marginTop: '1rem'}}>
                    This field is required
                  </p>
                )}
              </div>
            </div>
            {state.count > 0 && (
              <>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                    <span className='required'>State/County</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <select
                      name='country'
                      aria-label='Select Country'
                      data-control='select2'
                      data-placeholder='Select a country...'
                      className='form-select form-select-solid form-select-lg fw-semibold'
                      aria-invalid={errors.Country ? 'true' : 'false'}
                      value={selectedStateISOCode}
                      onChange={handleStateChange}
                    >
                      <option value=''>Select State...</option>
                      {state.states.map((item) => (
                        <option key={item.isoCode} value={item.isoCode}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                    <span>City</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <select
                      name='City'
                      aria-label='Select a City'
                      data-control='select2'
                      data-placeholder='Select a City...'
                      className='form-select form-select-solid form-select-lg fw-semibold'
                      {...register('City')}
                      aria-invalid={errors.City ? 'true' : 'false'}
                    >
                      <option value=''>Select a City...</option>
                      {city.message &&
                        city.message.map((city, index) => (
                          <option key={index} value={city.name}>
                            {city.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </>
            )}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                <span className='required'>Timezone</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <TimezoneSelect
                  //options={[{label: 'Select Timezone', value: selectedTimezone}]}
                  value={selectedTimezone}
                  onChange={setSelectedTimezone}
                  placeholder='Select Timezone'
                />
              </div>
            </div>
            {selectedOptionRole === '3' && (
              <>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                    <span className='required'>Age</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <select
                      name='AgeBracket'
                      aria-label='Select a AgeBracket'
                      data-control='select2'
                      data-placeholder='Select a AgeBracket...'
                      className='form-select form-select-solid form-select-lg fw-semibold'
                      {...register('AgeBracket', {
                        required: 'This is a required field',
                      })}
                      aria-invalid={errors.AgeBracket ? 'true' : 'false'}
                      onChange={() => {
                        clearErrors('AgeBracket')
                      }}
                    >
                      <option value=''>Select</option>
                      <option value='18-25'>18-25</option>
                      <option value='26-30'>26-30</option>
                      <option value='31-35'>31-35</option>
                      <option value='36-40'>36-40</option>
                      <option value='40+'>40+</option>
                    </select>
                    {errors.AgeBracket && (
                      <p className='alert_danger' role='alert'>
                        {errors.AgeBracket?.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                    <span className='required'>Education Level</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <select
                      name='EducationLevel'
                      aria-label='Select an EducationLevel'
                      data-control='select2'
                      data-placeholder='Select an EducationLevel...'
                      className='form-select form-select-solid form-select-lg fw-semibold'
                      {...register('EducationLevel', {
                        required: 'This is a required field',
                      })}
                      aria-invalid={errors.EducationLevel ? 'true' : 'false'}
                    >
                      <option value=''>Select an Education Level...</option>
                      <option value='High-school'>High-school</option>
                      <option value='University Degree'>University Degree</option>
                      <option value='Other'>Other</option>
                    </select>
                    {errors.EducationLevel && (
                      <p className='alert_danger' role='alert'>
                        {errors.EducationLevel?.message}
                      </p>
                    )}
                    {watch('EducationLevel') == 'Other' && (
                      <input
                        type='text'
                        placeholder='Please specify your education level'
                        className='form-control'
                        {...register('OtherEducationLevel', {required: 'This is a required field'})}
                        aria-invalid={errors.OtherEducationLevel ? 'true' : 'false'}
                      />
                    )}
                    {errors.OtherEducationLevel && (
                      <p className='alert_danger' role='alert'>
                        {errors.OtherEducationLevel?.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                    <span className='required'>Years of Cyber Security Experience</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <select
                      id='inputState'
                      data-control='select2'
                      data-placeholder='Select a YearsOfCyberSecurityExperience...'
                      className='form-select form-select-solid form-select-lg fw-semibold'
                      {...register('YearsOfCyberSecurityExperience', {
                        required: 'This is a required field',
                      })}
                      aria-invalid={errors.YearsOfCyberSecurityExperience ? 'true' : 'false'}
                      onChange={() => {
                        clearErrors('YearsOfCyberSecurityExperience')
                      }}
                    >
                      <option value='' disabled selected style={{color: '#495057'}}>
                        Select{' '}
                      </option>
                      <option value='None' style={{color: '#495057'}}>
                        None{' '}
                      </option>
                      <option value='1-2'>1-2</option>
                      <option value='3-5'>3-5</option>
                      <option value='5-7'>5-7</option>
                      <option value='7+'>7+</option>
                    </select>
                    {errors.YearsOfCyberSecurityExperience && (
                      <p className='alert_danger' role='alert'>
                        {errors.YearsOfCyberSecurityExperience?.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                    <span className='required'>Purpose of taking the Course</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <select
                      id='inputState'
                      data-control='select2'
                      data-placeholder='Select a City...'
                      className='form-select form-select-solid form-select-lg fw-semibold'
                      {...register('PurposeOfTakingTheCourse', {
                        required: 'This is required field',
                      })}
                      aria-invalid={errors.dayRate ? 'true' : 'false'}
                    >
                      <option value='' disabled selected style={{color: '#495057'}}>
                        Select
                      </option>
                      <option value='To enhance my Skills' style={{color: '#495057'}}>
                        To enhance my Skills
                      </option>
                      <option value='To learn about Microsoft Security'>
                        To learn about Microsoft Security
                      </option>
                      <option value='To improve my chances of getting a job'>
                        To improve my chances of getting a job
                      </option>
                      <option value='Other'>Other</option>
                    </select>
                    {errors.PurposeOfTakingTheCourse && (
                      <p className='alert_danger' role='alert'>
                        {errors.PurposeOfTakingTheCourse?.message}
                      </p>
                    )}
                    {/* Display input field when 'Other' is selected */}
                    {watch('PurposeOfTakingTheCourse') === 'Other' && (
                      <input
                        type='text'
                        placeholder='Please specify your purpose'
                        className='form-control'
                        {...register('OtherPurposeOfTakingTheCourse', {
                          required: 'This is a required field',
                        })}
                        aria-invalid={errors.OtherPurposeOfTakingTheCourse ? 'true' : 'false'}
                      />
                    )}
                    {/* Display error message for 'Other' input field */}
                    {errors.OtherPurposeOfTakingTheCourse && (
                      <p className='alert_danger' role='alert'>
                        {errors.OtherPurposeOfTakingTheCourse?.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                    <span className='required'>Current employment status</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <select
                      id='inputState'
                      data-control='select2'
                      data-placeholder='Select a EmploymentStatus...'
                      className='form-select form-select-solid form-select-lg fw-semibold'
                      {...register('EmploymentStatus', {
                        required: 'This is required field',
                      })}
                      aria-invalid={errors.dayRate ? 'true' : 'false'}
                    >
                      <option value='' disabled selected style={{color: '#495057'}}>
                        Select{' '}
                      </option>
                      <option value='Unemployed' style={{color: '#495057'}}>
                        {' '}
                        Unemployed{' '}
                      </option>
                      <option value='Employed in a cyber security role'>
                        Employed in a cyber security role
                      </option>
                      <option value='Employed in a unrelated cyber security role'>
                        Employed in a unrelated cyber security role
                      </option>
                      <option value='Other'>Other</option>
                    </select>
                    {errors.EmploymentStatus && (
                      <p className='alert_danger' role='alert'>
                        {errors.EmploymentStatus?.message}
                      </p>
                    )}
                    {/* Display input field when 'Other' is selected */}
                    {watch('EmploymentStatus') == 'Other' && (
                      <input
                        type='text'
                        placeholder='Please specify your employment status'
                        className='form-control'
                        {...register('OtherEmploymentStatus', {
                          required: 'This is a required field',
                        })}
                        aria-invalid={errors.OtherEmploymentStatus ? 'true' : 'false'}
                      />
                    )}
                    {/* Display error message for 'Other' input field */}
                    {errors.OtherEmploymentStatus && (
                      <p className='alert_danger' role='alert'>
                        {errors.OtherEmploymentStatus?.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                    <span className='required'>
                      I am interested in Cyberheads finding me a new job
                    </span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <select
                      id='inputState'
                      data-control='select2'
                      data-placeholder='Select a InterestedInCyberheadRole...'
                      className='form-select form-select-solid form-select-lg fw-semibold'
                      {...register('InterestedInCyberheadRole', {
                        required: 'This is required field',
                      })}
                      aria-invalid={errors.dayRate ? 'true' : 'false'}
                      onChange={() => {
                        clearErrors('InterestedInCyberheadRole')
                      }}
                    >
                      <option value='' disabled selected style={{color: '#495057'}}>
                        Select{' '}
                      </option>
                      <option value='Yes' style={{color: '#495057'}}>
                        Yes{' '}
                      </option>
                      <option value='No'>No</option>
                    </select>
                    {errors.InterestedInCyberheadRole && (
                      <p className='alert_danger' role='alert'>
                        {errors.InterestedInCyberheadRole?.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                    <span className='required'>How did you find out about Cyberheads?</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <select
                      id='inputState'
                      data-control='select2'
                      data-placeholder='Select a HowYouGotToKnow...'
                      className='form-select form-select-solid form-select-lg fw-semibold'
                      {...register('HowYouGotToKnow', {
                        required: 'This is required field',
                      })}
                      aria-invalid={errors.dayRate ? 'true' : 'false'}
                    >
                      <option value='' disabled selected style={{color: '#495057'}}>
                        Select{' '}
                      </option>
                      <option value='Word of mouth' style={{color: '#495057'}}>
                        Word of mouth{' '}
                      </option>
                      <option value='Referral'>Referral</option>
                      <option value='Social Media'>Social Media</option>
                      <option value='Google'>Google</option>
                      <option value='Other'>Other</option>
                    </select>
                    {errors.HowYouGotToKnow && (
                      <p className='alert_danger' role='alert'>
                        {errors.HowYouGotToKnow?.message}
                      </p>
                    )}
                    {watch('HowYouGotToKnow') == 'Other' && (
                      <input
                        type='text'
                        placeholder='Please specify how you found out about Cyberheads'
                        className='form-control'
                        {...register('OtherHowYouGotToKnow', {
                          required: 'This is a required field',
                        })}
                        aria-invalid={errors.OtherHowYouGotToKnow ? 'true' : 'false'}
                      />
                    )}
                    {errors.OtherHowYouGotToKnow && (
                      <p className='alert_danger' role='alert'>
                        {errors.OtherHowYouGotToKnow?.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className='d-flex'>
                  <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                    <span className='required'>Include payment link in email</span>
                  </label>
                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='yesNoRadio'
                      id='yesRadio'
                      value='yes'
                      checked={yesNoValue === 'yes'}
                      onChange={handleInputChange}
                    />
                    <label className='form-check-label' htmlFor='yesRadio'>
                      Yes
                    </label>
                  </div>
                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='yesNoRadio'
                      id='noRadio'
                      value='no'
                      checked={yesNoValue === 'no'}
                      onChange={handleInputChange}
                    />
                    <label className='form-check-label' htmlFor='noRadio'>
                      No
                    </label>
                  </div>
                </div>

                {yesNoValue === 'yes' ? (
                  <div>
                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label fw-semibold fs-6 required'>
                        <span>Payment Link</span>
                      </label>

                      <div className='col-lg-8 fv-row'>
                        <input
                          type='paymentlink'
                          name='paymentlink'
                          className='form-control form-control-lg form-control-solid'
                          placeholder='Payment link'
                          {...register('paymentlink', {
                            required: 'This is required field',
                          })}
                          aria-invalid={errors.paymentlink ? 'true' : 'false'}
                        />
                        {errors.paymentlink && (
                          <p className='alert_danger' role='alert'>
                            {errors.paymentlink?.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                ) : yesNoValue === 'no' ? (
                  <div>
                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label fw-semibold fs-6 required'>
                        <span>Payment amount</span>
                      </label>

                      <div className='col-lg-8 fv-row'>
                        <input
                          type='amount'
                          name='amount'
                          className='form-control form-control-lg form-control-solid'
                          placeholder='Payment amount'
                          {...register('amount', {
                            required: 'This is required field',
                          })}
                          aria-invalid={errors.amount ? 'true' : 'false'}
                          onKeyPress={(e) => {
                            const isNumeric = /^[0-9]$/
                            if (!isNumeric.test(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                        {errors.amount && (
                          <p className='alert_danger' role='alert'>
                            {errors.amount?.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label fw-semibold fs-6 required'>
                        <span>Reference </span>
                      </label>

                      <div className='col-lg-8 fv-row'>
                        <input
                          type='paymentid'
                          name='paymentid'
                          className='form-control form-control-lg form-control-solid'
                          placeholder='Reference '
                          {...register('paymentid', {
                            required: 'This is required field',
                          })}
                          aria-invalid={errors.paymentid ? 'true' : 'false'}
                        />
                        {errors.paymentid && (
                          <p className='alert_danger' role='alert'>
                            {errors.paymentid?.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label fw-semibold fs-6 required'>
                        <span>Date Purchased</span>
                      </label>

                      <div className='col-lg-8 fv-row'>
                        <DatePicker
                          className='form-select form-select-solid form-select-lg fw-semibold'
                          selected={startDate}
                          onChange={handleStartDateChange}
                          minDate={new Date()}
                          disabledDate={disabledDate}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                    <span className='required'>Course Name</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <select
                      id='dropdown'
                      value={selectedOption}
                      data-control='select2'
                      onChange={handleSelectChangee}
                      data-placeholder='Select a CourseName...'
                      className='form-select form-select-solid form-select-lg fw-semibold'
                      required
                    >
                      <option
                        value=''
                        style={{
                          color: 'black',
                          fontWeight: '500',
                          background: 'rgb(255, 118, 63)',
                          color: 'white',
                        }}
                      >
                        Courses
                      </option>
                      {courses}
                      <option
                        value=''
                        style={{
                          color: 'black',
                          fontWeight: '500',
                          background: 'rgb(255, 118, 63)',
                          color: 'white',
                        }}
                      >
                        Course bundles
                      </option>
                      {bundles}
                    </select>
                  </div>
                </div>

                <div className='row mb-6'>
                  {selectedType === 'course' && course.dateRange ? (
                    <>
                      <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                        <span>Select Dates ({courseCategory})</span>
                      </label>
                      <div className='col-lg-8 fv-row'>
                        {course.dateRange.length > 0 ? (
                          course.dateRange.map((item) => {
                            function formatDate(datetime) {
                              return new Date(datetime).toLocaleDateString('en-UK', {
                                day: 'numeric',
                                month: 'numeric',
                                year: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                              })
                            }
                            return (
                              <div key={item.id}>
                                {item.start_datetime ? (
                                  <div>
                                    <input
                                      type='radio'
                                      name='dateRadioGroup'
                                      value={formatDate(item.start_datetime)}
                                      // checked={courseStartDate === formatDate(item.start_datetime)}
                                      onChange={() =>
                                        handleDateSelection(
                                          course.id,
                                          item.start_datetime,
                                          item.end_datetime,
                                          item.timezone,
                                          item.datesId
                                        )
                                      }
                                    />
                                    &nbsp; &nbsp;
                                    {formatDate(item.start_datetime)} -{' '}
                                    {formatDate(item.end_datetime)}
                                  </div>
                                ) : (
                                  <span>No dates available</span>
                                )}
                              </div>
                            )
                          })
                        ) : (
                          <span>No dates available</span>
                        )}
                      </div>
                    </>
                  ) : (
                    <div></div>
                  )}
                </div>

                {selectedType === 'bundle' && bundle.categorizedData ? (
                  <div>
                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                        <span>Select Dates (Microsoft Bootcamp)</span>
                      </label>
                      <div className='col-lg-8 fv-row'>
                        {bundle.categorizedData.Microsoft_Bootcamp &&
                          bundle.categorizedData.Microsoft_Bootcamp[0].date_ranges.map((item) => {
                            function formatDate(datetime) {
                              return new Date(datetime).toLocaleDateString('en-US', {
                                month: 'numeric',
                                day: 'numeric',
                                year: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                              })
                            }
                            return (
                              <div key={item.id}>
                                <input
                                  type='radio'
                                  name='microsoftdate'
                                  value={formatDate(item.start_datetime)}
                                  // checked={MicrosoftStartDate === formatDate(item.start_datetime)}
                                  onChange={() =>
                                    handleMicrosoftDate(
                                      item.start_datetime,
                                      item.end_datetime,
                                      item.timezone,
                                      item.datesId
                                    )
                                  }
                                />
                                &nbsp; &nbsp;
                                {formatDate(item.start_datetime)} - {formatDate(item.end_datetime)}
                              </div>
                            )
                          })}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                        <span>Select Dates (CompTIA Bootcamp)</span>
                      </label>
                      <div className='col-lg-8 fv-row'>
                        {bundle.categorizedData.Comptia_Bootcamp &&
                          bundle.categorizedData.Comptia_Bootcamp[0].date_ranges.map((item) => {
                            function formatDate(datetime) {
                              return new Date(datetime).toLocaleDateString('en-US', {
                                month: 'numeric',
                                day: 'numeric',
                                year: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                              })
                            }
                            return (
                              <div key={item.id}>
                                <input
                                  type='radio'
                                  name='comptiadate'
                                  value={formatDate(item.start_datetime)}
                                  // checked={ComptiaStartDate === formatDate(item.start_datetime)}
                                  onChange={() =>
                                    handleComptiaDate(
                                      item.start_datetime,
                                      item.end_datetime,
                                      item.timezone,
                                      item.datesId
                                    )
                                  }
                                />
                                &nbsp; &nbsp;
                                {formatDate(item.start_datetime)} - {formatDate(item.end_datetime)}
                              </div>
                            )
                          })}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </>
            )}
            {selectedOptionRole === '4' && (
              <>
                <div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                      <span>Day Rate(£)</span>
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <input
                        type='dayRate'
                        name='dayRate'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Day Rate'
                        defaultValue='0'
                        {...register('dayRate', {
                          required: 'This is required field',
                        })}
                        aria-invalid={errors.dayRate ? 'true' : 'false'}
                      />
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-semibold fs-6'>MTM ID</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        type='text'
                        name='mtmId'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='MTM ID'
                        {...register('mtm_id')}
                        aria-invalid={errors.mtm_id ? 'true' : 'false'}
                      />
                      {errors.mtm_id && (
                        <p className='alert_danger' role='alert'>
                          {errors.mtm_id?.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-semibold fs-6'>CompTIA ID</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        type='text'
                        name='comptiaId'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='CompTIA ID'
                        {...register('comptia_id')}
                        aria-invalid={errors.comptia_id ? 'true' : 'false'}
                      />
                      {errors.comptia_id && (
                        <p className='alert_danger' role='alert'>
                          {errors.comptia_id?.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-semibold fs-6'>Other IDs</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        type='text'
                        name='otherIds'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Other IDs'
                        {...register('other_id')}
                        aria-invalid={errors.other_id ? 'true' : 'false'}
                      />
                      {errors.other_id && (
                        <p className='alert_danger' role='alert'>
                          {errors.other_id?.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <hr class='solid' />
                <div>
                  <div className='d-flex align-items-center fs-4 fw-bold mb-5'>
                    {' '}
                    Teaching Details
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                      <span>Qualifications / Accreditation</span>
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <input
                        type='text'
                        name='phone'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Qualifications'
                        {...register('qualification')}
                        aria-invalid={errors.qualification ? 'true' : 'false'}
                      />
                      {errors.qualification && (
                        <p className='alert_danger' role='alert'>
                          {errors.qualification?.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                      <span>Years of teaching experience</span>
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <select
                        name='experience'
                        aria-label='Select a experience'
                        data-control='select2'
                        data-placeholder='Select a experience...'
                        className='form-select form-select-solid form-select-lg fw-semibold'
                        {...register('experiance')}
                        aria-invalid={errors.experiance ? 'true' : 'false'}
                      >
                        {errors.experiance && (
                          <p className='alert_danger' role='alert'>
                            {errors.experiance?.message}
                          </p>
                        )}
                        <option value=''>Select a experience...</option>
                        <option value='1'>1 Year</option>
                        <option value='2-5'>2-5 Years</option>
                        <option value='5+'>5+ Years</option>
                      </select>
                    </div>
                  </div>
                  <div class='row mb-6'>
                    <label class='col-lg-4 col-form-label fw-semibold fs-6'>Teaching Courses</label>

                    <div class='col-lg-8 fv-row'>
                      <ul>
                        {allcourse.map((item, index) => (
                          <li
                            style={{margin: 0, padding: 0}}
                            className='form-check'
                            key={`bundle-${index}`}
                          >
                            <input
                              type='checkbox'
                              className='form-check-input'
                              id={`checkboxBundle-${index}`}
                              value={`${item.id}-${item.title}`}
                              checked={selectedCheckboxes.courses[item.id] !== undefined}
                              onChange={() =>
                                handleCheckboxChange(`bundle${item.id}`, {
                                  ...item,
                                  checked: !selectedCheckboxes.courses[item.id],
                                })
                              }
                            />
                            <label
                              className='form-label mt-0 p-1'
                              htmlFor={`checkboxBundle-${index}`}
                            >
                              {item.title}
                            </label>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <hr class='solid' />
                <div>
                  <div className='d-flex align-items-center fs-4 fw-bold mb-5'> Bank Details</div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                      <span>Bank account / IBAN numbers</span>
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <input
                        type='text'
                        name='phone'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Bank account'
                        {...register('IBANNumber')}
                        aria-invalid={errors.IBANNumber ? 'true' : 'false'}
                      />
                      {errors.IBANNumber && (
                        <p className='alert_danger' role='alert'>
                          {errors.IBANNumber?.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                      <span>Account Name</span>
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <input
                        type='text'
                        name='phone'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Account Name'
                        {...register('AccountName')}
                        aria-invalid={errors.AccountName ? 'true' : 'false'}
                      />
                      {errors.AccountName && (
                        <p className='alert_danger' role='alert'>
                          {errors.AccountName?.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                      <span>Account Number / IBAN</span>
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <input
                        type='text'
                        name='phone'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Account Number'
                        {...register('AccountNumber')}
                        aria-invalid={errors.AccountNumber ? 'true' : 'false'}
                      />
                      {errors.AccountNumber && (
                        <p className='alert_danger' role='alert'>
                          {errors.AccountNumber?.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                      <span>Company/Account Name</span>
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <input
                        type='text'
                        name='companyName'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Company Name'
                        {...register('companyName')}
                        aria-invalid={errors.companyName ? 'true' : 'false'}
                      />
                      {errors.companyName && (
                        <p className='alert_danger' role='alert'>
                          {errors.companyName?.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <Link to='/alluserlist'>
              {' '}
              <button type='reset' className='btn cybercancelbtn me-2'>
                Cancel
              </button>
            </Link>
            <button
              type='submit'
              className='btn cyberbtnallpage'
              id='kt_account_profile_details_submit'
              style={{backgroundColor: '#FF763F', color: '#fff'}}
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddUser
