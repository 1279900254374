import React from 'react'

import microsoft from '../../PrivateImage/img/microsoft.png'
import comptia from '../../PrivateImage/img/comptia.png'
import azure from '../../PrivateImage/img/azure.png'

import './Courses.css'
const Certifiedcourse = () => {
  return (
    <section>
      <div>
        <h3 className='Certifiedcoursetext' style={{textAlign: 'center'}}>
          Certified Partners
        </h3>
      </div>
      <section class='brands-collabs' style={{padding: '30px'}}>
        <div class='container' style={{zIndex: '99999999'}}>
          <ul>
            <li>
              {' '}
              <img src={microsoft} alt='microsoft' />{' '}
            </li>
            <li>
              {' '}
              <img src={comptia} alt='comptia' />{' '}
            </li>
            <li>
              {' '}
              <img src={azure} alt='azure' />{' '}
            </li>
          </ul>
        </div>
      </section>
    </section>
  )
}

export default Certifiedcourse
