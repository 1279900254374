import React, {useState, useEffect} from 'react'
import TimezoneSelect from 'react-timezone-select'
import {useDispatch, useSelector} from 'react-redux'
import {zoomMeetingAction} from '../../../Redux/Action/zoomAction'
import {useForm} from 'react-hook-form'
import DatePicker from 'react-datepicker'
import axios from 'axios'
import Loader from '../../../components/Loader/loader'
import {Form, Button, Row, Col} from 'react-bootstrap'
import moment from 'moment'
import swal from 'sweetalert'
const Zoom = () => {
  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  )

  const [showForm, setShowForm] = useState(false)
  const [checkbox, setcheckbox] = useState(false)
  const [selectedOption, setSelectedOption] = useState('')
  const formattedTimezone = selectedTimezone?.value || ''
  const [hourOptions] = useState(Array.from({length: 10}, (_, i) => i + 1))
  const minuteOptions = [0, 15, 30, 45]
  const handleCheckboxChange = (event) => {
    setShowForm((prevShowForm) => !prevShowForm)
    setcheckbox(event.target.checked)
  }

  const handleDropdownChange = (e) => {
    setSelectedOption(e.target.value)
  }
  const [loading, setLoading] = useState(false)
  const [userid, setuserid] = useState('')
  const [topic, setTopic] = useState('')
  const [meetingtype, setmeetingtype] = useState('')

  const [type, setType] = useState(2)
  const [startDate, setStartDate] = useState(new Date())
  //const [endDate, setEndDate] =  useState(newDate)
  const [duration, setDuration] = useState('')
  const [hostVideo, setHostVideo] = useState(false)
  const [participantVideo, setParticipantVideo] = useState(false)
  const [joinBeforeHost, setJoinBeforeHost] = useState(false)
  const [muteUponEntry, setMuteUponEntry] = useState(false)
  const [watermark, setWatermark] = useState(false)

  const [timezone, settimezone] = useState(true)
  const [audio, setAudio] = useState('voip')
  const [autoRecording, setAutoRecording] = useState('cloud')

  const [repeat_interval, setrepeat_interval] = useState('1')
  const [end_times, setend_times] = useState('')
  const [Recurrence, setRecurrence] = useState('1')

  const [end_date_time, setend_date_time] = useState('')
  const currentDateTime = new Date().toISOString().slice(0, 16)
  const [meetingHours, setMeetingHours] = useState('')
  const [meetingMinutes, setMeetingMinutes] = useState('')
  const [startTime, setStartTime] = useState(new Date())
  const [endTime, setEndTime] = useState(new Date())
  const [minEndTime, setMinEndTime] = useState(new Date())
  const [waiting_room, setwaiting_room] = useState('true')
  const handleInputChange = (event) => {
    setwaiting_room(event.target.value)
  }

  const filterPassedTime = (time) => {
    const currentDate = new Date(startDate)
    const selectedDate = new Date(time)
    return currentDate.getTime() <= selectedDate.getTime()
  }

  const handleUserIdChange = (e) => {
    const userIdValue = e.target.value
    setuserid(userIdValue)
  }

  useEffect(() => {
    const storedUserId = localStorage.getItem('userId')
    if (storedUserId) {
      setuserid(storedUserId)
    }
  }, [])

  const handleSubmit = async (e) => {
    debugger
    e.preventDefault()
    const userid = localStorage.getItem('userid')

    const defaultHours = '1'
    const defaultMinutes = '0'

    const hours = meetingHours || defaultHours
    const minutes = meetingMinutes || defaultMinutes
    const durationInMinutes = parseInt(hours, 10) * 60 + parseInt(minutes, 10)
    const duration = `${durationInMinutes}`

    const formData = {
      // meetingtype,
      userid,
      topic,
      type,
      start_Date: startDate,
      //end_time: endTime,
      Start_time: startTime.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}),
      // Start_time: startTime,
      End_Time: endTime.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}),
      duration: durationMinutes,
      timezone: selectedTimezone?.value,
      settings: {
        host_video: hostVideo,
        participant_video: participantVideo,
        join_before_host: joinBeforeHost,
        mute_upon_entry: muteUponEntry,
        watermark,
        audio,
        auto_recording: autoRecording,
        waiting_room: waiting_room,
      },

      recurrence: {
        endDateOptions: selectedDateOption,
        monthDateOption: monthDateOption,
        checkbox: checkbox,
        Recurrence: Recurrence,
        repeat_interval: repeat_interval,

        ...(selectedDateOption == '1' ? {end_times: occurrences} : {}),
        ...(selectedDateOption == '2' ? {end_times: setoccurrences} : {}),
        ...(Recurrence == '3'
          ? {
              monthly_week: weekNumber,
              monthly_week_day: monthDay,
              monthly_day: monthDate,
            }
          : {}),
        ...(Recurrence == '2' ? {weekly_days: setWeekDay} : {}),
      },
    }

    try {
      setLoading(true)
      const baseURL = process.env.REACT_APP_BACKEND_API
      const url = `${baseURL}/zoom/meetings`
      const token = localStorage.getItem('authToken')
      let response
      if (occurrences > 20) {
        swal({
          title: 'Change Dates',
          text: 'Occurrences cannot be more than 20. Please change end dates!',
          icon: 'warning',
          closeOnClickOutside: false,
          buttons: {
            cancel: {
              text: 'OK',
              value: null,
              visible: true,
              className: 'swal-button--confirm cyberbtnallpage',
            },
          },
        })
      } else {
        response = await axios.post(url, formData, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
      }

      if (response.status === 200) {
        const start_url = response.data.start_url
        window.location.href = '/current_upcommimg_classes'
      }
    } catch (error) {
      console.error('Error:', error)
    } finally {
      setLoading(false)
    }
  }

  const durationInMilliseconds = endTime - startTime

  const hours = Math.floor(durationInMilliseconds / (1000 * 60 * 60))
  const minutes = Math.floor((durationInMilliseconds % (1000 * 60 * 60)) / (1000 * 60))
  const durationinMinutes = Math.floor(durationInMilliseconds / (1000 * 60))

  let formattedDuration
  let durationMinutes
  if (startTime && endTime) {
    formattedDuration = `${hours} Hrs ${minutes} Mins`
    durationMinutes = durationinMinutes
  }

  const sevenDaysAhead = new Date(startDate)
  sevenDaysAhead.setDate(startDate.getDate() + 7)

  const [endDate, setEndDate] = useState(sevenDaysAhead)
  let newDailyDate
  let newweekDate
  let newmonthDate
  if (startDate) {
    newDailyDate = new Date(startDate)
    newDailyDate.setDate(newDailyDate.getDate() + 6)

    newweekDate = new Date(startDate)
    newweekDate.setDate(newweekDate.getDate() + 42)

    newmonthDate = new Date(startDate)
    newmonthDate.setMonth(newmonthDate.getMonth() + 6)
  }

  const handleDateChange = (date) => {
    setStartDate(date)

    let initialEndDate

    if (Recurrence === '1') {
      initialEndDate = newDailyDate
    } else if (Recurrence === '2') {
      initialEndDate = newweekDate
    } else if (Recurrence === '3') {
      initialEndDate = newmonthDate
    }

    setEndDate(initialEndDate)
  }

  const [selectedDateOption, setSelectedDateOption] = useState('1')
  const [setoccurrences, setOccurrences] = useState('7')
  const [setWeekDay, setSelectWeekDay] = useState(['Monday'])
  const [monthDate, setMonthDate] = useState('')
  const [monthDateOption, setmonthDateOption] = useState('1')
  const [weekNumber, setWeekNumber] = useState('First')
  const [monthDay, setmonthDayOption] = useState('Monday')
  const handleOptionChange = (event) => {
    setSelectedDateOption(event.target.value)
  }

  const handleWeekDayChange = (event) => {
    setSelectWeekDay(event.target.value)
  }

  const handleMonthDate = (event) => {
    setmonthDateOption(event.target.value)
  }

  const handleWeekCheckboxChange = (day) => {
    if (setWeekDay.includes(day)) {
      setSelectWeekDay(setWeekDay.filter((selectedDay) => selectedDay !== day))
    } else {
      setSelectWeekDay([...setWeekDay, day])
    }
  }

  const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

  useEffect(() => {
    const roundedTime = new Date()

    const minutess = roundedTime.getMinutes()
    if (minutess >= 0 && minutess < 20) {
      roundedTime.setMinutes(30)
    } else {
      roundedTime.setMinutes(0)
      roundedTime.setSeconds(0)
      roundedTime.setHours(roundedTime.getHours() + 1)
    }

    const roundedEndTime = new Date()

    const endMinutess = roundedEndTime.getMinutes()
    if (endMinutess >= 0 && endMinutess < 20) {
      roundedEndTime.setMinutes(0)
      roundedEndTime.setSeconds(0)
      roundedEndTime.setHours(roundedEndTime.getHours() + 1)
    } else {
      roundedEndTime.setMinutes(30)
      roundedEndTime.setSeconds(0)
      roundedEndTime.setHours(roundedEndTime.getHours() + 1)
    }
    console.log(roundedEndTime, 'roundedEndTime')
    //setStartTime(roundedTime);
    setEndTime(roundedEndTime)
    setMinEndTime(roundedEndTime)

    if (startDate) {
      const dayOfMonth = startDate.getDate()
      setMonthDate(dayOfMonth || '')
    }
  }, [startDate])

  const handleRecurrenceChange = (date) => {
    setRecurrence(date)
  }

  useEffect(() => {
    let initialEndDate

    if (Recurrence === '1') {
      initialEndDate = newDailyDate
    } else if (Recurrence === '2') {
      initialEndDate = newweekDate
    } else if (Recurrence === '3') {
      initialEndDate = newmonthDate
    }

    setEndDate(initialEndDate)
  }, [Recurrence])

  function countRepeatedWeekdays(startDate, endDate, weeklyDays) {
    const oneDay = 24 * 60 * 60 * 1000
    let count = 0
    const start = new Date(startDate).getTime()
    const end = new Date(endDate).getTime()
    for (let timestamp = start; timestamp <= end; timestamp += oneDay) {
      const currentDate = new Date(timestamp)
      const dayOfWeek = currentDate.getDay()
      const dayName = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ][dayOfWeek]
      if (weeklyDays.includes(dayName)) {
        count++
      }
    }
    return count
  }

  function countWeekdayOccurrences(startDate, endDate, weekNumber, weekday) {
    let count = 0
    let currentDate = new Date(startDate)
    while (currentDate <= new Date(endDate)) {
      if (
        currentDate.getDay() ===
        ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].indexOf(
          weekday
        )
      ) {
        switch (weekNumber.toLowerCase()) {
          case 'first':
            if (currentDate.getDate() <= 7) {
              count++
            }
            break
          case 'second':
            if (currentDate.getDate() > 7 && currentDate.getDate() <= 14) {
              count++
            }
            break
          case 'third':
            if (currentDate.getDate() > 14 && currentDate.getDate() <= 21) {
              count++
            }
            break
          case 'fourth':
            if (currentDate.getDate() > 21 && currentDate.getDate() <= 28) {
              count++
            }
            break
          case 'last':
            const nextMonth = new Date(currentDate)
            nextMonth.setMonth(nextMonth.getMonth() + 1)
            nextMonth.setDate(0)
            const lastWeekday = nextMonth.getDay()
            if (
              lastWeekday ===
              [
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
              ].indexOf(weekday)
            ) {
              count++
            }
            break
        }
      }
      currentDate.setDate(currentDate.getDate() + 1)
    }
    return count
  }

  function countRepeatedDates(startDate, endDate, dayOfMonth) {
    let count = 0
    let currentDate = new Date(startDate)
    while (currentDate <= new Date(endDate)) {
      if (currentDate.getDate() == dayOfMonth) {
        count++
      }
      currentDate.setDate(currentDate.getDate() + 1)
    }
    return count
  }

  const calculateOccurrences = (startDate, endDate, recurrence, repeatInterval) => {
    //console.log(startDate, endDate, recurrence, repeatInterval,"kkkkkkkkkkkkkkkkkkk")
    const startDateTime = new Date(startDate).getTime()
    const endDateTime = new Date(endDate).getTime()

    const timeDifference = endDateTime - startDateTime

    let occurrences = 0

    switch (recurrence) {
      case '1':
        occurrences = Math.floor(timeDifference / (24 * 60 * 60 * 1000 * repeatInterval)) + 1
        break
      case '2':
        occurrences = Math.floor(
          countRepeatedWeekdays(startDate, endDate, setWeekDay) / parseInt(repeatInterval)
        )
        break
      case '3':
        if (monthDateOption == '1') {
          occurrences = Math.floor(
            countRepeatedDates(startDate, endDate, monthDate) / parseInt(repeatInterval)
          )
        } else if (monthDateOption == '2') {
          occurrences = Math.floor(
            countWeekdayOccurrences(startDate, endDate, weekNumber, monthDay) /
              parseInt(repeatInterval)
          )
        }
        break
      default:
        break
    }

    return occurrences
  }

  const occurrences = calculateOccurrences(startDate, endDate, Recurrence, repeat_interval)

  return (
    <div>
      {loading && (
        <div className='loader-container'>
          <Loader />
        </div>
      )}

      <form
        id='kt_ecommerce_add_product_form'
        className='form d-flex flex-column flex-lg-row'
        onSubmit={handleSubmit}
      >
        <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
          <div className='tab-content'>
            <div
              className='tab-pane fade show active'
              id='kt_ecommerce_add_product_general'
              role='tab-panel'
            >
              <div className='d-flex flex-column gap-7 gap-lg-10'>
                <div className='card card-flush py-4'>
                  <div className='card-body pt-0'>
                    <div className='d-flex align-items-center fs-2 fw-bold mb-5'>
                      {' '}
                      Add New Meeting
                    </div>
                    <Row>
                      <Col>
                        <Form.Group controlId='meetingTitle'></Form.Group>
                        <Form.Label>Topic</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter meeting topic'
                          onChange={(e) => setTopic(e.target.value)}
                          required
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group controlId='meetingStartTime'></Form.Group>
                        <Form.Label>Start Date</Form.Label>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                          <DatePicker
                            className='form-control mb-2'
                            showIcon
                            selected={startDate}
                            onChange={handleDateChange}
                            icon='fa fa-calendar'
                            dateFormat='dd/MM/yyyy'
                            minDate={new Date()}
                          />
                        </div>
                      </Col>
                      <Col>
                        <Form.Group controlId='meetingTitle'></Form.Group>
                        <Form.Label>Start Time</Form.Label>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                          <DatePicker
                            className='form-control mb-2'
                            selected={startTime}
                            onChange={(date) => setStartTime(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={30}
                            timeCaption='Time'
                            dateFormat='HH:mm'
                            timeFormat='HH:mm'
                          />
                        </div>
                      </Col>
                      <Col>
                        <Form.Group controlId='meetingStartTime'></Form.Group>
                        <Form.Label>End Time</Form.Label>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                          <DatePicker
                            className='form-control mb-2'
                            selected={endTime}
                            onChange={(date) => setEndTime(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={30}
                            timeCaption='Time'
                            dateFormat='HH:mm'
                            timeFormat='HH:mm'
                            // filterTime={filterPassedTime}
                          />
                        </div>
                      </Col>
                      <Col>
                        <Form.Group controlId='meetingHours'>
                          <Form.Label>Meeting Duration</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Duration'
                            value={formattedDuration}
                            readOnly
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group controlId='meetingMinutes'>
                          <Form.Label>Timezone</Form.Label>
                          <TimezoneSelect
                            className=''
                            value={selectedTimezone}
                            onChange={setSelectedTimezone}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <br />
                    <div className='mb-10 fv-row'>
                      <div className='form-check'>
                        <input
                          type='checkbox'
                          id='showFormCheckbox'
                          className='form-check-input'
                          // checked={showForm}
                          // value='8'
                          defaultChecked={false}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor='showFormCheckbox' className='form-check-label'>
                          Recurring meeting
                        </label>
                      </div>
                      {showForm && (
                        <div>
                          <span style={{lineHeight: '38px'}}>
                            {parseInt(Recurrence) === 1 && (
                              <div>
                                {selectedDateOption === '1' && (
                                  <>
                                    &nbsp;&nbsp;&nbsp;&nbsp;Every day, until{' '}
                                    {endDate.toDateString()}, {occurrences} occurrence(s)
                                  </>
                                )}
                                {selectedDateOption === '2' && (
                                  <>
                                    &nbsp;&nbsp;&nbsp;&nbsp;Every day, {setoccurrences}{' '}
                                    occurrence(s)
                                  </>
                                )}
                              </div>
                            )}

                            {parseInt(Recurrence) === 2 && (
                              <div>
                                {selectedDateOption === '1' && (
                                  <>
                                    &nbsp;&nbsp;&nbsp;&nbsp;Every week on {setWeekDay.join(', ')},
                                    until {endDate.toDateString()}, {occurrences} occurrence(s)
                                  </>
                                )}
                                {selectedDateOption === '2' && (
                                  <>
                                    &nbsp;&nbsp;&nbsp;&nbsp;Every week on {setWeekDay.join(', ')},{' '}
                                    {setoccurrences} occurrence(s)
                                  </>
                                )}
                              </div>
                            )}

                            {parseInt(Recurrence) === 3 && (
                              <div>
                                {selectedDateOption === '1' && (
                                  <div>
                                    {monthDateOption == '1' && (
                                      <>
                                        &nbsp;&nbsp;&nbsp;&nbsp;Every month on the {monthDate} of
                                        the month, until {endDate.toDateString()}, {occurrences}{' '}
                                        occurrence(s)
                                      </>
                                    )}

                                    {monthDateOption == '2' && (
                                      <>
                                        &nbsp;&nbsp;&nbsp;&nbsp;Every month on the {weekNumber}{' '}
                                        {monthDay}, until {endDate.toDateString()}, {occurrences}{' '}
                                        occurrence(s)
                                      </>
                                    )}
                                  </div>
                                )}
                                {selectedDateOption === '2' && (
                                  <div>
                                    {monthDateOption == '1' && (
                                      <>
                                        &nbsp;&nbsp;&nbsp;&nbsp;Every month on the {monthDate} of
                                        the month, {setoccurrences} occurrence(s)
                                      </>
                                    )}

                                    {monthDateOption == '2' && (
                                      <>
                                        &nbsp;&nbsp;&nbsp;&nbsp;Every month on the {weekNumber}{' '}
                                        {monthDay}, {setoccurrences} occurrence(s)
                                      </>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </span>
                          <div className='bg-light p-3'>
                            <div
                              className='col-md-12'
                              style={{display: 'flex', gap: '20px', paddingTop: '2rem'}}
                            >
                              <label className='fw-semibold fs-6' htmlFor='dropdown'>
                                Recurrence:
                              </label>

                              <select
                                className='form-control mb-2'
                                name='Recurrence'
                                value={Recurrence}
                                data-placeholder='Select a Auto Recurrence:...'
                                style={{width: '200px'}}
                                onChange={(e) => handleRecurrenceChange(e.target.value)}
                              >
                                <option value='1'>Daily</option>
                                <option value='2'>Weekly</option>
                                <option value='3'>Monthly</option>
                              </select>
                            </div>
                            {Recurrence == '1' ? (
                              <div
                                className='col-md-12'
                                style={{display: 'flex', gap: '20px', paddingTop: '2rem'}}
                              >
                                <label className='fw-semibold fs-6' htmlFor='dropdown'>
                                  Repeat Every:
                                </label>
                                <select
                                  className='form-control mb-2'
                                  name='Recurrence'
                                  data-placeholder='Select an Auto Recurrence...'
                                  style={{width: '150px'}}
                                  value={repeat_interval}
                                  onChange={(e) => setrepeat_interval(e.target.value)}
                                >
                                  <option value=''></option>
                                  <option value='1'>1</option>
                                  <option value='2'>2</option>
                                  <option value='3'>3</option>
                                  <option value='4'>4</option>
                                  <option value='5'>5</option>
                                  <option value='6'>6</option>
                                  <option value='7'>7</option>
                                  <option value='8'>8</option>
                                  <option value='9'>9</option>
                                  <option value='10'>10</option>
                                </select>
                                <span style={{lineHeight: '38px'}}>day(s)</span>
                              </div>
                            ) : Recurrence == '2' ? (
                              <div>
                                <div
                                  className='col-md-12'
                                  style={{display: 'flex', gap: '20px', paddingTop: '2rem'}}
                                >
                                  <label className='fw-semibold fs-6' htmlFor='dropdown'>
                                    Repeat Every:
                                  </label>
                                  <select
                                    className='form-control mb-2'
                                    name='Recurrence'
                                    data-placeholder='Select an Auto Recurrence...'
                                    style={{width: '150px'}}
                                    value={repeat_interval}
                                    onChange={(e) => setrepeat_interval(e.target.value)}
                                  >
                                    <option value=''></option>
                                    <option value='1'>1</option>
                                    <option value='2'>2</option>
                                    <option value='3'>3</option>
                                    <option value='4'>4</option>
                                    <option value='5'>5</option>
                                    <option value='6'>6</option>
                                    <option value='7'>7</option>
                                    <option value='8'>8</option>
                                    <option value='9'>9</option>
                                    <option value='10'>10</option>
                                    <option value='10'>11</option>
                                    <option value='10'>12</option>
                                  </select>
                                  <span style={{lineHeight: '38px'}}>week(s)</span>
                                </div>
                                <div
                                  className='col-md-12'
                                  style={{display: 'flex', gap: '20px', paddingTop: '2rem'}}
                                >
                                  <label className='fw-semibold fs-6' htmlFor='dropdown'>
                                    Occurs On:
                                  </label>
                                  <div>
                                    <Form>
                                      {weekdays.map((day) => (
                                        <Form.Check
                                          key={day}
                                          inline
                                          label={day}
                                          type='checkbox'
                                          id={`inline-checkbox-${day}`}
                                          onChange={() => handleWeekCheckboxChange(day)}
                                          checked={setWeekDay.includes(day)}
                                        />
                                      ))}
                                    </Form>
                                  </div>
                                </div>
                              </div>
                            ) : Recurrence == '3' ? (
                              <div>
                                <div
                                  className='col-md-12'
                                  style={{display: 'flex', gap: '20px', paddingTop: '2rem'}}
                                >
                                  <label className='fw-semibold fs-6' htmlFor='dropdown'>
                                    Repeat Every:
                                  </label>
                                  <select
                                    className='form-control mb-2'
                                    name='Recurrence'
                                    data-placeholder='Select an Auto Recurrence...'
                                    style={{width: '150px'}}
                                    value={repeat_interval}
                                    onChange={(e) => setrepeat_interval(e.target.value)}
                                  >
                                    <option value=''></option>
                                    <option value='1'>1</option>
                                    <option value='2'>2</option>
                                    <option value='3'>3</option>
                                  </select>
                                  <span style={{lineHeight: '38px'}}>month(s)</span>
                                </div>
                                <div className='col-md-12'>
                                  <div className='d-flex justify-content-start align-items-center gap-3'>
                                    <label className='fw-semibold fs-6' htmlFor='dropdown'>
                                      Occurs On:
                                    </label>
                                    <input
                                      type='radio'
                                      name='monthDateOption'
                                      value='1'
                                      id='1'
                                      checked={monthDateOption === '1'}
                                      onChange={handleMonthDate}
                                    />

                                    <div className='d-flex justify-content-start align-items-center gap-3'>
                                      <span>Day</span>
                                      <select
                                        className='form-control mb-2'
                                        name='Recurrence'
                                        data-placeholder='Select an Auto Recurrence...'
                                        style={{width: '100px'}}
                                        value={monthDate}
                                        onChange={(e) => setMonthDate(e.target.value)}
                                      >
                                        <option value=''></option>
                                        <option value='1'>1</option>
                                        <option value='2'>2</option>
                                        <option value='3'>3</option>
                                        <option value='4'>4</option>
                                        <option value='5'>5</option>
                                        <option value='6'>6</option>
                                        <option value='7'>7</option>
                                        <option value='8'>8</option>
                                        <option value='9'>9</option>
                                        <option value='10'>10</option>
                                        <option value='11'>11</option>
                                        <option value='12'>12</option>
                                        <option value='13'>13</option>
                                        <option value='14'>14</option>
                                        <option value='15'>15</option>
                                        <option value='16'>16</option>
                                        <option value='17'>17</option>
                                        <option value='18'>18</option>
                                        <option value='19'>19</option>
                                        <option value='20'>20</option>
                                        <option value='21'>21</option>
                                        <option value='22'>22</option>
                                        <option value='23'>23</option>
                                        <option value='24'>24</option>
                                        <option value='25'>25</option>
                                        <option value='26'>26</option>
                                        <option value='27'>27</option>
                                        <option value='28'>28</option>
                                        <option value='29'>29</option>
                                        <option value='30'>30</option>
                                        <option value='31'>31</option>
                                      </select>
                                      <span>of the month</span>
                                    </div>
                                  </div>
                                  <div className='d-flex justify-content-start align-items-center gap-3'>
                                    <label
                                      className='fw-semibold fs-6'
                                      htmlFor='dropdown'
                                      style={{width: '80px'}}
                                    ></label>
                                    <input
                                      type='radio'
                                      name='monthDateOption'
                                      value='2'
                                      id='2'
                                      checked={monthDateOption == '2'}
                                      onChange={handleMonthDate}
                                    />
                                    <select
                                      className='form-control mb-2'
                                      name='weekNumber'
                                      data-placeholder='Select an Auto Recurrence...'
                                      style={{width: '200px'}}
                                      value={weekNumber}
                                      onChange={(e) => setWeekNumber(e.target.value)}
                                    >
                                      <option value=''></option>
                                      <option value='First'>First</option>
                                      <option value='Second'>Second</option>
                                      <option value='Third'>Third</option>
                                      <option value='Fourth'>Fourth</option>
                                      <option value='Last'>Last</option>
                                    </select>
                                    <select
                                      className='form-control mb-2'
                                      name='monthDay'
                                      data-placeholder='Select an Auto Recurrence...'
                                      style={{width: '200px'}}
                                      value={monthDay}
                                      onChange={(e) => setmonthDayOption(e.target.value)}
                                    >
                                      <option value=''></option>
                                      <option value='Sunday'>Sunday</option>
                                      <option value='Monday'>Monday</option>
                                      <option value='Tuesday'>Tuesday</option>
                                      <option value='Wednesday'>Wednesday</option>
                                      <option value='Thursday'>Thursday</option>
                                      <option value='Friday'>Friday</option>
                                      <option value='Saturday'>Saturday</option>
                                    </select>

                                    <span>of the month</span>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}

                            <div style={{display: 'flex', paddingTop: '2rem', gap: '20px'}}>
                              <div className='col-md-12'>
                                <div className='mb-10 fv-row d-flex align-items-center justify-content-start gap-10'>
                                  <label className='fw-semibold fs-6 required'>End Date:</label>
                                  <div className='d-flex align-items-center justify-content-start gap-3'>
                                    <input
                                      type='radio'
                                      name='paymentOption'
                                      value='1'
                                      id='1'
                                      checked={selectedDateOption === '1'}
                                      onChange={handleOptionChange}
                                    />
                                    <span>By</span>
                                    <DatePicker
                                      className='form-control mb-2'
                                      showIcon
                                      minDate={new Date()}
                                      selected={endDate}
                                      onChange={(date) => setEndDate(date)}
                                      icon='fa fa-calendar'
                                      dateFormat='dd/MM/yyyy'
                                    />
                                  </div>

                                  <div className='d-flex align-items-center justify-content-start gap-3'>
                                    <input
                                      type='radio'
                                      name='paymentOption'
                                      value='2'
                                      id='2'
                                      checked={selectedDateOption === '2'}
                                      onChange={handleOptionChange}
                                    />
                                    <span>After</span>
                                    <select
                                      className='form-control mb-2'
                                      name='Occurrences'
                                      data-placeholder='Select an Auto Recurrence...'
                                      style={{width: '100%'}}
                                      value={setoccurrences}
                                      onChange={(e) => setOccurrences(e.target.value)}
                                    >
                                      <option value=''></option>
                                      <option value='1'>1</option>
                                      <option value='2'>2</option>
                                      <option value='3'>3</option>
                                      <option value='4'>4</option>
                                      <option value='5'>5</option>
                                      <option value='6'>6</option>
                                      <option value='7'>7</option>
                                      <option value='8'>8</option>
                                      <option value='9'>9</option>
                                      <option value='10'>10</option>
                                      <option value='11'>11</option>
                                      <option value='12'>12</option>
                                      <option value='13'>13</option>
                                      <option value='14'>14</option>
                                      <option value='15'>15</option>
                                      <option value='16'>16</option>
                                      <option value='17'>17</option>
                                      <option value='18'>18</option>
                                      <option value='19'>19</option>
                                      <option value='20'>20</option>
                                    </select>
                                    <span>Occurrences</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className='row mb-6' style={{flexDirection: 'column'}}>
                      <label className='col-lg-4 col-form-label  fw-semibold fs-6'>
                        Settings:-
                      </label>
                      <p>Select all the video hosting options</p>

                      <div className='d-flex align-items-center mt-3'>
                        <div className='form-check form-check-custom form-check-inline form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            name='Host-video[]'
                            type='checkbox'
                            defaultChecked={false}
                            onClick={() => setHostVideo(true)}
                            onChange={(e) => setHostVideo(e.target.checked)}
                          />
                          <label className='form-check-label fw-semibold ps-2 fs-6 text-gray-800'>
                            Host video
                          </label>
                        </div>

                        <div className='form-check form-check-custom form-check-inline form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            name='  Participant-video[]'
                            type='checkbox'
                            defaultChecked={false}
                            onClick={() => setParticipantVideo(true)}
                            onChange={(e) => setParticipantVideo(e.target.checked)}
                          />
                          <label className='form-check-label fw-semibold ps-2 fs-6 text-gray-800'>
                            Participant video
                          </label>
                        </div>

                        <div className='form-check form-check-custom form-check-inline form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            name=' Join-before-host[]'
                            type='checkbox'
                            defaultChecked={false}
                            onClick={() => setJoinBeforeHost(true)}
                            onChange={(e) => setJoinBeforeHost(e.target.checked)}
                          />
                          <label className='form-check-label fw-semibold ps-2 fs-6 text-gray-800'>
                            Join before host
                          </label>
                        </div>

                        <div className='form-check form-check-custom form-check-inline form-check-solid'>
                          <input
                            className='form-check-input'
                            name=' Mute-upon-entry[]'
                            type='checkbox'
                            defaultChecked={false}
                            onClick={() => setMuteUponEntry(true)}
                            onChange={(e) => setMuteUponEntry(e.target.checked)}
                          />
                          <label className='form-check-label fw-semibold ps-2 fs-6 text-gray-800'>
                            Mute upon entry
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className='d-flex'>
                      <label className='col-lg-2 col-form-label fw-semibold fs-6'>
                        <span>Enable meeting room</span>
                      </label>
                      <div style={{paddingTop: '10px'}}>
                        <div className='form-check form-check-inline'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='yesNoRadio'
                            id='yesRadio'
                            value='true'
                            checked={waiting_room === 'true'}
                            onChange={handleInputChange}
                          />
                          <label className='form-check-label' htmlFor='yesRadio'>
                            True
                          </label>
                        </div>
                        <div className='form-check form-check-inline'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='yesNoRadio'
                            id='noRadio'
                            value='false'
                            checked={waiting_room === 'false'}
                            onChange={handleInputChange}
                          />
                          <label className='form-check-label' htmlFor='noRadio'>
                            False
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className='mb-10 fv-row'>
                      <label className='required form-label'>Audio</label>
                      <select
                        className='form-control mb-2'
                        name='audio'
                        data-placeholder='Select a Audio...'
                        value={audio}
                        onChange={(e) => setAudio(e.target.value)}
                      >
                        <option value=''>Select audio..</option>

                        <option value='voip'>VoIP</option>
                        <option value='telephony'>Telephony</option>
                      </select>
                    </div>

                    <div className='mb-10 fv-row'>
                      <label className='required form-label'>Auto Recording:</label>
                      <select
                        className='form-control mb-2'
                        name='audio'
                        data-placeholder='Select a Auto Recording:...'
                        value={autoRecording}
                        onChange={(e) => setAutoRecording(e.target.value)}
                      >
                        <option value=''>Select a Auto Recording..</option>

                        <option value='cloud'>Cloud</option>
                        <option value='local'>Local</option>
                        <option value='none'>None</option>
                      </select>
                    </div>
                  </div>
                  <div style={{marginLeft: '2rem'}}>
                    <button type='submit' className='btn cyberbtnallpage'>
                      Add Class
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Zoom
