import axios from 'axios'
import swal from 'sweetalert'
import {
  getallchapterslice,
  getallLesson_idslice,
  getchapteridslice,
  getlessonidslice,
  getvideoprogressslice,
  getallcoursedetailsslice,
  getallchaptereditslice,
} from '../Slice/authSlice'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export const CreateChapterAction = (formData, course_id) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const crestechap = process.env.REACT_APP_BACKEND_API + '/createChapter'

    const res = await axios.post(crestechap, formData, config)
    if (res.status === 200) {
      swal({
        title: 'Success',
        text: 'Chapter Created',
        icon: 'success',
        buttons: {
          cancel: {
            text: 'OK',
            value: null,
            visible: true,
            className: 'swal-button--confirm cyberbtnallpage', 
          },
        },
      })
      window.location.reload()
    }

    dispatch(res())
  } catch (e) {
    console.log(e)
  }
}

export const GetallchapterAction = (courseId) => async (dispatch) => {
  // debugger
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getallchapter_id = `${process.env.REACT_APP_BACKEND_API}/getallChapter/${courseId}`

    const res = await axios.get(getallchapter_id, config, '')

    await dispatch(getallchapterslice(res))
  } catch (e) {
    // console.log(e)
  }
}

export const GetallchapterEdit = (selectedInstructorId) => async (dispatch) => {
  // debugger
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getallchapter_id = `${process.env.REACT_APP_BACKEND_API}/getChapter/${selectedInstructorId}`

    const res = await axios.get(getallchapter_id, config, '')

    await dispatch(getallchaptereditslice(res))
  } catch (e) {
    console.log(e)
  }
}

export const DeleteChapterAction = (id) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const deletechapter = `${process.env.REACT_APP_BACKEND_API}/deleteChapter/${id}`
    const res = await axios.delete(deletechapter, config)

    dispatch(res())
    if (res.status === 200) {
      swal({
        title: 'Success',
        text: 'Chapter Deleted',
        icon: 'success',
        buttons: {
          cancel: {
            text: 'OK',
            value: null,
            visible: true,
            className: 'swal-button--confirm cyberbtnallpage', 
          },
        },
      })
    }
  } catch (e) {
    console.log(e)
  }
}

export const DeleteLessonAction = (id) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const deleteLesson = `${process.env.REACT_APP_BACKEND_API}/deleteLesson/${id}`
    const res = await axios.delete(deleteLesson, config)

    dispatch(res())
    if (res.status === 200) {
      swal({
        title: 'Success',
        text: 'Lesson Deleted',
        icon: 'success',
        buttons: {
          cancel: {
            text: 'OK',
            value: null,
            visible: true,
            className: 'swal-button--confirm cyberbtnallpage', 
          },
        },
      })
    }
  } catch (e) {
    console.log(e)
  }
}

export const CreateLessonrAction = (formData, navigate, chapter_slug) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const createLesso = process.env.REACT_APP_BACKEND_API + '/createLesson'

    const res = await axios.post(createLesso, formData, config)
    if (res.status === 200) {
      swal({
        title: 'Success',
        text: 'Lesson Created',
        icon: 'success',
        buttons: {
          cancel: {
            text: 'OK',
            value: null,
            visible: true,
            className: 'swal-button--confirm cyberbtnallpage', 
          },
        },
      })
      window.location.reload()
    }

    dispatch(res())
  } catch (e) {
    console.log(e)
  }
}

export const GetallLesson_idAction = (chapter_slug) => async (dispatch) => {
  try {
    // debugger
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getallLesson_byid = `${process.env.REACT_APP_BACKEND_API}/getallLesson/${chapter_slug}`

    const res = await axios.get(getallLesson_byid, config, '')
    await dispatch(getallLesson_idslice(res))
  } catch (e) {
    console.log(e)
  }
}

export const GetChapterAction = (course_id) => async (dispatch) => {
  try {
    // debugger
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getchapterid = `${process.env.REACT_APP_BACKEND_API}/getChapter/${course_id}`

    const res = await axios.get(getchapterid, config, '')

    await dispatch(getchapteridslice(res))
  } catch (e) {
    console.log(e)
  }
}

export const UpdateChapterAction = (formData, props, courseslug) => async (dispatch) => {
  try {
    // debugger
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const updateChapterid = `${process.env.REACT_APP_BACKEND_API}/updateChapter/${props.id}`
    const res = await axios.put(updateChapterid, formData, config)

    if (res.status === 200) {
      swal({
        title: 'Success',
        text: 'Chapter Updated',
        icon: 'success',
        buttons: {
          cancel: {
            text: 'OK',
            value: null,
            visible: true,
            className: 'swal-button--confirm cyberbtnallpage', 
          },
        },
      })
      window.location.reload()
      console.log(res)
    }
  } catch (e) {
    console.log(e)
  }
}

export const GetLessonAction = (props) => async (dispatch) => {
  // debugger
  try {
    // debugger
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getlessonid = `${process.env.REACT_APP_BACKEND_API}/getLesson/${props.id}`

    const res = await axios.get(getlessonid, config)

    await dispatch(getlessonidslice(res))

    if (res.status === 200) {
      console.log(res)
    }
    dispatch(res())
  } catch (e) {
    console.log(e)
  }
}

export const UpdateLessonAction = (formData, props) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const updatelessonid = `${process.env.REACT_APP_BACKEND_API}/updateLesson/${props.id}`
    const res = await axios.put(updatelessonid, formData, config)

    if (res.status === 200) {
      console.log(res)
    }
    dispatch(res())
  } catch (e) {
    console.log(e)
  }
}

export const UpdateVideoTimer = (formData) => async (dispatch) => {
  // debugger
  try {
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const updateVideo = process.env.REACT_APP_BACKEND_API + '/updateVideoProgress'

    const res = await axios.post(updateVideo, formData, config)
    if (res.status === 200) {
    }

    dispatch(res())
  } catch (e) {
    console.log(e)
  }
}

export const VideoProgressAction = (userId, selectedLessonId) => async (dispatch) => {
  // debugger

  try {
    // debugger
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const video_progress = `${process.env.REACT_APP_BACKEND_API}/getVideoProgress/${userId}/${selectedLessonId}`

    const res = await axios.get(video_progress, config, '')

    await dispatch(getvideoprogressslice(res))
  } catch (e) {
    console.log(e)
  }
}

export const AllcourseDetailAction = (course_id) => async (dispatch) => {
  // debugger

  try {
    // debugger
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const allcoursedetails = `${process.env.REACT_APP_BACKEND_API}/getAllcourseDetail/${course_id}`

    const res = await axios.get(allcoursedetails, config, '')

    await dispatch(getallcoursedetailsslice(res))
  } catch (e) {
    console.log(e)
  }
}
