import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import * as am5 from '@amcharts/amcharts5'
import * as am5map from '@amcharts/amcharts5/map'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow'
import * as CountryStateCity from 'country-state-city'

import {
  usersByLocationAction,
  mapStateByCountry,
  allCountryUsers,
} from '../../../../../Redux/Action/admindashboardAction'
import {Col, Row} from 'react-bootstrap'

function Mapppp() {
  const dispatch = useDispatch()
  const [selectState, setSelectState] = useState('')

  const handleChange = (event) => {
    setSelectState(event.target.value)
  }

  const recentCourses = useSelector((state) => {
    return state.login.getUsersByLocationList
  })

  useEffect(() => {
    dispatch(usersByLocationAction())
  }, [dispatch])

  const stateOfCountry = useSelector((state) => {
    return state.login.getMapStateCountryList
  })

  useEffect(() => {
    dispatch(mapStateByCountry(selectState))
  }, [dispatch, selectState])

  const getCountry = useSelector((state) => {
    return state.login.getCountryFromISOList
  })

  useEffect(() => {
    dispatch(allCountryUsers())
  }, [dispatch])

  const [isChartInitialized, setIsChartInitialized] = useState(false) // Add this flag

  useEffect(() => {
    // Define data
    if (recentCourses.length > 0 && !isChartInitialized) {
      // Check if the chart is not initialized
      let groupData = [
        {
          name: 'EU member before 2004',
          data: recentCourses.map((course) => ({
            id: course.id, // Replace with the appropriate identifier for the country
            joined: course.joined, // Replace with the appropriate property for the join date
          })),
        },
      ]

      let eroror = am5.Root.new('chartdiv')
      eroror.setThemes([am5themes_Animated.new(eroror)])

      let chart = eroror.container.children.push(
        am5map.MapChart.new(eroror, {
          homeZoomLevel: 0.5,
          homeGeoPoint: {longitude: 10, latitude: 52},
        })
      )

      let worldSeries = chart.series.push(
        am5map.MapPolygonSeries.new(eroror, {
          geoJSON: am4geodata_worldLow,
          exclude: ['AQ'],
        })
      )

      worldSeries.events.on('datavalidated', () => {
        chart.goHome()
      })

      let legend = chart.children.push(
        am5.Legend.new(eroror, {
          useDefaultMarker: true,
          centerX: am5.p50,
          x: am5.p50,
          centerY: am5.p100,
          y: am5.p100,
          dy: -20,
          background: am5.RoundedRectangle.new(eroror, {
            fill: am5.color(0xffffff),
            fillOpacity: 0.2,
          }),
        })
      )

      legend.valueLabels.template.set('forceHidden', true)

      let colors = am5.ColorSet.new(eroror, {
        step: 2,
      })
      colors.next()

      am5.array.each(groupData, function (group) {
        let countries = []
        let color = colors.next()

        am5.array.each(group.data, function (country) {
          countries.push(country.id)
        })

        let polygonSeries = chart.series.push(
          am5map.MapPolygonSeries.new(eroror, {
            geoJSON: am4geodata_worldLow,
            include: countries,
            name: group.name,
            fill: '#282e66',
          })
        )

        polygonSeries.mapPolygons.template.setAll({
          tooltipText: '[bold]{name}[/]\nTotal user from {name} : {joined}',
          interactive: true,
          fill: '#282e66',
          strokeWidth: 2,
        })

        polygonSeries.mapPolygons.template.states.create('hover', {
          fill: '#282e66',
        })

        polygonSeries.mapPolygons.template.events.on('pointerover', function (ev) {
          ev.target.series.mapPolygons.each(function (polygon) {
            polygon.states.applyAnimate('hover')
          })
        })

        polygonSeries.mapPolygons.template.events.on('pointerout', function (ev) {
          ev.target.series.mapPolygons.each(function (polygon) {
            polygon.states.applyAnimate('default')
          })
        })
        polygonSeries.data.setAll(group.data)
      })

      // Set the flag to true after chart initialization
      setIsChartInitialized(true)
    }
  }, [recentCourses, isChartInitialized, selectState])

  const [countryNames, setCountryNames] = useState({})

  useEffect(() => {
    // Fetch the country names for unique country codes in the data
    const fetchCountryNames = () => {
      const uniqueCountryCodes = [...new Set(getCountry.map((item) => item.Country))]

      const fetchedCountryNames = {}
      uniqueCountryCodes.forEach((code) => {
        const country = CountryStateCity.Country.getCountryByCode(code)
        fetchedCountryNames[code] = country ? country.name : code
      })

      setCountryNames(fetchedCountryNames)
    }

    fetchCountryNames()
  }, [getCountry])

  return (
    <div className='card card-flush map_card' style={{height: '40rem', overFlow: 'auto'}}>

      <div className='card-header'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold text-gray-800'>User By Location</span>
        </h3>
        <div
          className='card-toolbar'
          style={{border: '1px solid #ccc', borderRadius: '4px', padding: '10px', marginBottom: '25px'}}
        >
          <select
            value={selectState}
            onChange={handleChange}
            style={{paddingRight: '10px', border: 'none', outline: 'none', width: '100%'}}
          >
            <option value='' disabled>
              Select a country...
            </option>
            {Object.entries(countryNames).map(([code, name]) => (
              <option key={code} value={code}>
                {name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div id='kt_maps_widget_2_map' class='w-100 h-350px'>
        <div id='chartdiv' style={{width: '100%', height: '100%'}}></div>
      </div>

      <div>
        <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
          {stateOfCountry.length === 0 ? (
            <div>No state found</div>
          ) : (
            <div style={{display: 'flex', flexDirection: 'row', padding: '5px'}}>
              {stateOfCountry.map(
                (state) =>
                  state.state_name && (
                    <div key={state.state_name} style={{display: 'flex', padding: '5px'}}>
                      <div
                        style={{
                          width: '15px',
                          height: '15px',
                          color: '#282e66',
                          backgroundColor: '#282e66',
                          padding: '5px',
                          borderRadius: '3px',
                        }}
                      ></div>
                      <div style={{paddingLeft: '10px', display: 'flex'}}>
                        {state.state_name.name}: {state.state_count}
                      </div>
                    </div>
                  )
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Mapppp
