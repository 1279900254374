import React, {useEffect, useState} from 'react'
import {GetallRoleAction} from '../../../Redux/Action/roleAction'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'

const UserRole = () => {
  const dispatch = useDispatch()

  const allRoles = useSelector((state) => {
    return state.login.getAllRoles_list
  })

  useEffect(() => {
    dispatch(GetallRoleAction())
  }, [dispatch])

  return (
    <div>
      <div id='kt_app_content' class='app-content flex-column-fluid'>
        <div id='kt_app_content_container' class='app-container container-xxl p-0 m-0'>
          <div class='row row-cols-1 row-cols-md-2 row-cols-xl-3 g-5 g-xl-12'>
            {allRoles.map((item, index) => {
              let permissions = item.permission_desc

              return (
                <div className='col-md-4' key={item.id}>
                  <div className='card card-flush h-md-100'>
                    <div className='card-header'>
                      <div className='card-title'>
                        <h2>{item.rolename}</h2>
                      </div>
                    </div>
                    <div className='card-body pt-1'>
                      <div className='d-flex flex-column text-gray-600'>
                        {permissions !== null
                          ? permissions.split('|').map((permission, innerIndex) => (
                              <div key={innerIndex} className='d-flex align-items-center py-2'>
                                <span className='bullet bg-primary me-3'></span>
                                {permission.replace(/'/g, '')}
                              </div>
                            ))
                          : null}
                      </div>
                    </div>
                    <div className='card-footer flex-wrap pt-0'>
                      <Link
                        to={`/view_role/${item.id}`} // Pass the id as a parameter in the URL
                        className='btn cyberbtnallpage my-1 me-2'
                      >
                        View Role
                      </Link>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserRole
