import React from 'react'
import {useDispatch} from 'react-redux'
import {AddltitoolAction} from '../../../Redux/Action/ltiToolsAction'
import {Link} from 'react-router-dom' 

import {useForm} from 'react-hook-form'

const CreateTools = () => {
  const dispatch = useDispatch()
  // const navigate = useNavigate();

  const {
    register,
    handleSubmit,

    formState: {errors},
  } = useForm()

  const onSubmit = (data) => {
    const formData = new FormData()

    formData.append('name', data.name)
    formData.append('url', data.url)
    formData.append('description', data.description)
    formData.append('clientID', data.clientID)
    formData.append('ltiversion', 1.3)
    formData.append('pulickeyset', data.pulickeyset)
    formData.append('redirectionUri', data.redirectionUri)

    formData.append('parameters', data.parameters)
    formData.append('contentSelectionUrl', data.contentSelectionUrl)

    formData.append('intiateLoginUrl', data.intiateLoginUrl)

    dispatch(AddltitoolAction(formData))
  }
  return (
    <div>
      <div className='user-editorss'>
        <div className='card mb-5 mb-xl-10'>
          <form
            onSubmit={handleSubmit(onSubmit)}
            id='kt_account_profile_details_form'
            className='form'
          >
            <div className='card-body border-top border-0 p-9'>
              <div className='d-flex align-items-center fs-4 fw-bold mb-5'>
                {' '}
                Add New LTI 1.3 Tools
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-semibold fs-6'>Name</label>

                    <div className='col-lg-8 fv-row'>
                      <input
                        type='text'
                        name='name'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Name'
                        {...register('name', {
                          required: 'This is required',
                        })}
                        aria-invalid={errors.name ? 'true' : 'false'}
                      />
                      {errors.name && (
                        <p className='alert_danger' role='alert'>
                          {errors.name?.message}
                        </p>
                      )}
                    </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                  <span className='required'>URL</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='url'
                    name='url'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='URL'
                    {...register('url', {
                      required: 'This is required',
                    })}
                    aria-invalid={errors.url ? 'true' : 'false'}
                  />
                  {errors.url && (
                    <p className='alert_danger' role='alert'>
                      {errors.url?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                  <span className='required'>Description</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='description'
                    name='description'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Description'
                    {...register('description', {
                      required: 'This is required',
                    })}
                    aria-invalid={errors.description ? 'true' : 'false'}
                  />
                  {errors.description && (
                    <p className='alert_danger' role='alert'>
                      {errors.description?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                  <span className='required'>Client ID</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='id'
                    name='clientID'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Client ID'
                    {...register('clientID', {
                      required: 'This is required',
                    })}
                    aria-invalid={errors.clientID ? 'true' : 'false'}
                  />
                  {errors.clientID && (
                    <p className='alert_danger' role='alert'>
                      {errors.clientID?.message}
                    </p>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                  <span>LTI Version</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='ltiversion'
                    name='ltiversion'
                    className='form-control form-control-lg form-control-solid'
                    defaultValue='1.3'
                    disabled
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                  <span className='required'>Public Key Set</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    name='pulickeyset'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Pulic Key Set'
                    {...register('pulickeyset', {
                      required: 'This is required',
                    })}
                    aria-invalid={errors.pulickeyset ? 'true' : 'false'}
                  />
                  {errors.pulickeyset && (
                    <p className='alert_danger' role='alert'>
                      {errors.pulickeyset?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                  <span className='required'>Re Direction URL</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='id'
                    name='redirectionUri'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Re Direction URL'
                    {...register('redirectionUri', {
                      required: 'This is required',
                    })}
                    aria-invalid={errors.redirectionUri ? 'true' : 'false'}
                  />
                  {errors.redirectionUri && (
                    <p className='alert_danger' role='alert'>
                      {errors.redirectionUri?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                  <span className='required'>Parameters</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    name='parameters'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Parameters'
                    {...register('parameters', {
                      required: 'This is required',
                    })}
                    aria-invalid={errors.parameters ? 'true' : 'false'}
                  />
                  {errors.parameters && (
                    <p className='alert_danger' role='alert'>
                      {errors.parameters?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                  <span className='required'>Content Selection URL</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='url'
                    name='contentSelectionUrl'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Content Selection URL'
                    {...register('contentSelectionUrl', {
                      required: 'This is required',
                    })}
                    aria-invalid={errors.contentSelectionUrl ? 'true' : 'false'}
                  />
                  {errors.contentSelectionUrl && (
                    <p className='alert_danger' role='alert'>
                      {errors.contentSelectionUrl?.message}
                    </p>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                  <span className='required'>Initiate Login URL</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='url'
                    name='intiateLoginUrl'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Initiate Login URL'
                    {...register('intiateLoginUrl', {
                      required: 'This is required',
                    })}
                    aria-invalid={errors.intiateLoginUrl ? 'true' : 'false'}
                  />
                  {errors.intiateLoginUrl && (
                    <p className='alert_danger' role='alert'>
                      {errors.intiateLoginUrl?.message}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
             
              <Link to='/all_tools'>
                  <button type='reset' className='btn cybercancelbtn me-2'>
                    Cancel
                  </button>
                </Link>
              <button
                type='submit'
                className='btn cyberbtnallpage '
                id='kt_account_profile_details_submit'
                style={{background: 'rgb(255, 118, 63)', color: 'white'}}
              >
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default CreateTools
