import React from 'react'
import bootombanner from '../../PrivateImage/bundleimg/bootombanner.png'
import './Careers.css'
import {Link} from 'react-router-dom'
const Section2 = () => {
  return (
    <div style={{position: 'relative'}}>
      <img className='careerbottom_backgound' src={bootombanner} alt='img' />

      <span className='careerbotombanner'>
        Get on course <br />
        for a successful career in cybersecurity
      </span>

      <Link to='/course'>
        <button
          className='button button-rounded border-0'
          style={{
            position: 'absolute',
            top: 'calc(60% + 60px)', // Adjust the distance from the text
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          View Courses
        </button>
      </Link>
    </div>
  )
}

export default Section2
