import React from 'react'
import {useLocation} from 'react-router-dom'
// import { Link, useNavigate, useParams } from 'react-router-dom'
const Cta = () => {
  const location = useLocation()

  return (
    <section class='cta'>
      <div className='bottom-cta ctaRes'>
        <div className='container'>
          <div class='viewcareers row'>
            {location.pathname === '/course' ? (
              <div className='col-lg-12 text-center'>
                <h2 className='banner-heading'>
                  Take the next step <br />
                  to a successful cybersecurity career
                </h2>
                <a href='/Careers' class='button button-rounded border-0'>
                  VIEW CAREERS
                </a>
              </div>
            ) : location.pathname === '/aboutus' ? (
              <div className='col-lg-12 text-center'>
                <h2 className='banner-heading'>
                  Take a journey into cybersecurity <br />
                  armed with our experts
                </h2>
                <div className='cta_bannerfooter'>
                  <a
                    href='/Consultancy'
                    class='button button-rounded border-0 Consultancy_btn'
                  >
                    CONSULTANCY
                  </a>
                  <a href='/course' class='button button-rounded border-0'>
                    COURSES
                  </a>
                </div>
              </div>
            ) : location.pathname === '/Consultancy' ? (
              <div className='consultancy_res col-lg-12 text-center'>
                <h2 className='banner-heading'>
                  Protect your business
                  <br />
                  against cyber threats
                </h2>
                <div className='cta_bannerfooter'>
                  <a
                    href='/Consultancy'
                    class='button button-rounded border-0'
                    style={{marginRight: '20px'}}
                  >
                    BOOk A CONSULTATION
                  </a>
                </div>
              </div>
            ) : (
              <div className='col-lg-12 text-center'>
                <h2 className='banner-heading'>
                  Take the next step <br />
                  to a successful cybersecurity career
                </h2>
                <a href='/Careers' class='button button-rounded border-0'>
                  VIEW CAREERS
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Cta
