import React, {useState, useEffect} from 'react'
import {Row} from 'react-bootstrap'

//import {DatePicker} from 'antd'
import DatePicker from 'react-datepicker'
//import DatePicker from 'react-multi-date-picker'
import 'react-datepicker/dist/react-datepicker.css'
// import ReactTooltip from 'react-tooltip';
// import 'react-tooltip/dist/react-tooltip.css';
import {Tooltip} from 'antd'
import {Calendar, DateObject} from 'react-multi-date-picker'
import Footer from 'react-multi-date-picker/plugins/range_picker_footer'
import 'react-datepicker/dist/react-datepicker.css'
import {useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import {Tab, Col, Nav} from 'react-bootstrap-v5'
import BookedInstructor from '../AlluserList/BookedInstructor'
import Loader from '../../../components/Loader/loader'
import {Modal} from 'antd'
import {ArrowLeftOutlined} from '@ant-design/icons'
import Table from 'react-bootstrap/Table'
import {
  singledateBookings,
  updateSingleDateAvaibility,
  GetRangeByInstructorId,
  TeacherCoursesAction,
  GetInstructorAvaibilityReservedDetails,
  GetInstructorbyId,
  TeacherDateAction,
} from '../../../Redux/Action/instructorAction'

import {Link, useNavigate, NavLink} from 'react-router-dom'
import {GetAllMeetingAction} from '../../../Redux/Action/zoomAction'
import {PreviewDetailsAction} from '../../../Redux/Action/courseAction'
import moment from 'moment'

const UserAvailabilityset = () => {
  const navigate = useNavigate
  const dispatch = useDispatch()

  var newURL = window.location

  var splitURL = newURL.href.split('/')

  let instid = splitURL[4]
  const [values, setValues] = useState([])
  const [reservedValues, setReservedValues] = useState([])
  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const [isModalOpenmetting, setIsModalOpenmetting] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedCourseId, setSelectedCourseId] = useState('')
  const [selectedInstructorId, setSelectedInstructorId] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [loading, setLoading] = useState(false)
  const [timezone, setTimezone] = useState(null)
  const [courseDatesId, setcourseDatesId] = useState(null)

  const handleDateSelection = (startDate, endDate, timezone, datesId) => {
    setStartDate(startDate)
    setEndDate(endDate)
    setTimezone(timezone)
    setcourseDatesId(datesId)
  }

  const Range = useSelector((state) => state.login.getallRangelist)
  const ReservedRange = useSelector((state) => state.login.getaInstructorAvaibilityReservedlist)

  ///////////////////////////////////////////////////////////////
  const [dates, setSelectedDates] = useState([])
  const [reservedDates, setReservedDates] = useState([])
  const [selectedDate, setSelectedDate] = useState(null);

  
  const allDates = [...dates, ...reservedDates]

  const onSubmit1 = () => {
    const formData = {
      dates: [],
    }

    let hasValidDates = false

    const dateValues = Object.values(dates)

    dateValues.forEach((start) => {
      if (start && start.format && typeof start.format === 'function') {
        formData.dates.push(start.format('YYYY-MM-DDTHH:mm:ss.SSSZ'))
        hasValidDates = true
      }
    })

    if (hasValidDates) {
      dispatch(updateSingleDateAvaibility(formData, instid))
    }
  }

  useEffect(() => {
    // debugger
    if (isDataLoaded && Range.dateArray && Array.isArray(Range.dateArray)) {
      const uniqueDateSet = new Set(Range.dateArray)

      const formattedReservedDates = reservedDates.map((date) => date.toISOString())

      const uniqueDateObjectsWithoutReserved = Array.from(
        uniqueDateSet,
        (dateString) => new Date(dateString)
      ).filter((dateObject) => !formattedReservedDates.includes(dateObject.toISOString()))

      setSelectedDates(uniqueDateObjectsWithoutReserved)
    }
  }, [isDataLoaded, Range.dateArray, reservedDates])

  useEffect(() => {
    if (isDataLoaded && Range.bookedDates && Array.isArray(Range.bookedDates)) {
      const uniqueDateSet = new Set(Range.bookedDates)

      const uniqueDateObjects = Array.from(uniqueDateSet, (dateString) => new Date(dateString))

      setReservedDates(uniqueDateObjects)
    }
  }, [isDataLoaded, Range.bookedDates])

  /////////////////////////////////////////////////////////////////
  let course = useSelector((state) => {
    return state.login.getpreviewdetailslist
  })


  useEffect(() => {
    dispatch(PreviewDetailsAction(selectedCourseId))
  }, [dispatch, selectedCourseId])

  function formatDate(datetime) {
    const isValidDate = moment(datetime).isValid()

    if (isValidDate) {
      const formattedDate = moment(datetime).format('DD/MM/YYYY')
      return formattedDate
    } else {
      return 'Invalid Date'
    }
  }

  const meetings = useSelector((state) => state.login.getmeetingList)

  useEffect(() => {
    dispatch(GetAllMeetingAction())
  }, [dispatch])

  const futureMeetings = meetings ? meetings.futureMeetings : null

  const instructordetails = useSelector((state) => {
    return state.login.getaInstructorIdlist
  })

  useEffect(() => {
    dispatch(GetInstructorbyId(instid))
  }, [dispatch, instid])

  const currentDate = new Date().toISOString().split('T')[0]
  const expiary = new Date(
    new Date(currentDate).getFullYear(),
    new Date(currentDate).getMonth() + 6,
    new Date(currentDate).getDate()
  )
    .toISOString()
    .slice(0, 10)

  const disabledDates = Array.from({length: 31}, (_, index) => {
    const date = new Date()
    date.setDate(date.getDate() - index - 1)
    return new DateObject(date)
  })

  const parseDateString = (dateString) => {
    const [day, month, year] = dateString.split('/')
    return new Date(year, month - 1, day)
  }

  const dateIntervals =
    Range?.dateArray?.map((item) => {
      const Date = parseDateString(item)
      return Date
    }) ?? []

  const handleBookButtonClick = (instid) => {
    setSelectedInstructorId(instid)
    setIsModalOpen(true)
    dispatch(TeacherCoursesAction(instid))
  }
  const handleBookButtonClick1 = (instid) => {
    setSelectedInstructorId(instid)
    setIsModalOpen(true)
  }

  
  const handleCourseChange = (e) => {
    const courseId = e.target.value
    setSelectedCourseId(courseId)
    //setSelectedDate(null);
  }

  const [instructor_id, setInstructorId] = useState('')
  useEffect(() => {
    setInstructorId(instid)
  }, instid)
  
  let teacherCourses = useSelector((state) => {
    return state.login.gitalltechercourseList
  })
  
  useEffect(() => {
    dispatch(TeacherCoursesAction(instructor_id))
  }, [dispatch, instructor_id])
  
  const handleCancel = () => {
    
    setSelectedInstructorId(null)
    setIsModalOpen(false)
    setSelectedCourseId(null)
    setInstructorId(null)
    course = null;
    teacherCourses = null;
  }

  const {
    register,
    handleSubmit,
    control,

    formState: {errors},
  } = useForm()

  const onSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append('Course_id', selectedCourseId)
    formData.append('instructor_id', instid)
    formData.append('start_date', startDate)
    formData.append('end_date', endDate)
    formData.append('formattedstart_date', startDate)
    formData.append('formattedend_date', endDate)
    formData.append('timezone', timezone)
    formData.append('courseDatesId', courseDatesId)
    dispatch(singledateBookings(formData, setLoading))
  }

  useEffect(() => {
    dispatch(GetRangeByInstructorId(instid))
      .then(() => setIsDataLoaded(true))
      .catch((error) => {
        setIsDataLoaded(true)
      })
  }, [dispatch, instid])

  useEffect(() => {
    dispatch(GetInstructorAvaibilityReservedDetails(instid))
      .then(() => setIsDataLoaded(true))
      .catch((error) => {
        setIsDataLoaded(true)
      })
  }, [dispatch, instid])

  useEffect(() => {
    if (isDataLoaded) {
      if (Range.data && Array.isArray(Range.data)) {
        const startDateArray = Range.data.map((item) => item.start_date)
        const endDateArray = Range.data.map((item) => item.end_date)

        const dynamicStartDate = startDateArray.map((date) =>
          new DateObject(date).format('YYYY/MM/DD')
        )

        const dynamicEndDate = endDateArray.map((date) => new DateObject(date).format('YYYY/MM/DD'))

        const inService = dynamicStartDate.map((startDate, index) => [
          startDate,
          dynamicEndDate[index],
        ])

        setValues((prevValues) => [...prevValues, ...inService])
      }
    }
  }, [Range.data, isDataLoaded])



  useEffect(() => {
    if (isDataLoaded) {
      if (ReservedRange.data && Array.isArray(ReservedRange.data)) {
        const startDateArray = ReservedRange.data.map((item) => item.start_date)
        const endDateArray = ReservedRange.data.map((item) => item.end_date)

        const dynamicReservedStartDate = startDateArray.map((date) =>
          new DateObject(date).format('YYYY/MM/DD')
        )

        const dynamicReservedEndDate = endDateArray.map((date) =>
          new DateObject(date).format('YYYY/MM/DD')
        )

        const reserved = dynamicReservedStartDate.map((startDate, index) => [
          startDate,
          dynamicReservedEndDate[index],
        ])

        setReservedValues((prevReservedValues) => [...prevReservedValues, ...reserved])
      }
    }
  }, [ReservedRange.data, isDataLoaded])


  if (!isDataLoaded) {
    return <div>Loading...</div>
  }

  const isDateSelectable = (date) => {
    const dateString = date.toISOString().slice(0, 10)
    return values.some(([start, end]) => dateString >= start && dateString <= end)
  }



  return (
    <div>
      <Tab.Container id='left-tabs-example' defaultActiveKey='techerkey'>
        <Row>
          <Col md={12} style={{display: 'flex', justifyContent: 'center'}}>
            <Nav variant='pills'>
              <Nav.Item className='mb-5'>
                <Nav.Link eventKey='techerkey'>Instructor Availability</Nav.Link>
              </Nav.Item>

              <Nav.Item className='mb-5'>
                <Nav.Link eventKey='bookedInstructor'>Bookings</Nav.Link>
              </Nav.Item>

              <Nav.Item className='mb-5'>
                <Nav.Link eventKey='setTeacherAvailability'>Set Instructor Availability</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>

        <Tab.Content>
          <Tab.Pane eventKey='techerkey'>
            <div>
              <div className='set-availability card p-10 '>

              <div
                  style={{
                    borderBottom: '2px solid #181c32',
                    paddingBottom: '10px',
                    paddingTop: '10px',
                    fontWeight: 'bold',
                    fontSize: '17px',
                    textAlign: 'center',
                  }}
                >
                  Teacher Availability-(
                  <span>
                    {instructordetails.message && instructordetails.message.FirstName}{' '}
                    {instructordetails.message && instructordetails.message.Surname}
                  </span>
                  )
                </div>

                  <Row >
                    <Col sm={9} >
                  <div style={{padding: '16px'}}>
                    <li className='loream' style={{listStyle: 'none'}}>
                      <div class='circle blue'></div>
                      <span class='unbooked' style={{fontWeight: '700'}}>
                        Available For Training -
                      </span>
                      The dates instructors are available for training
                    </li>
                    <li className='loream' style={{listStyle: 'none'}}>
                      {' '}
                      <div class='circle grey'></div>
                      <span class='greyyy' style={{fontWeight: '700'}}>
                        Unavailable For Training -
                      </span>
                      The grey dates
                    </li>

                    <li className='loream' style={{listStyle: 'none'}}>
                      <div class='circle green'></div>
                      <span class='booked' style={{fontWeight: '700'}}>
                        Booked For Training -
                      </span>{' '}
                      The dates allocated/agreed for instructors to offer training
                    </li>
                  </div>
                    </Col>
                    <Col sm={3} style={{display: 'flex', justifyContent: 'flex-end'}}>
                  <div style={{paddingTop: '16px'}}>
                    <button
                      className='btn cyberbtnallpage'
                      style={{
                        color: 'white',

                        width: '108px',
                        padding: '10px',
                        borderRadius: '5px',
                        fontWeight: 500,
                      }}
                      onClick={() => handleBookButtonClick(instid)}
                    >
                      Book
                    </button>
                  </div>
                    </Col>
                  </Row>

                <div
                  className='calender_header'
                  style={{display: 'flex', justifyContent: 'center'}}
                >
                  <div>
                    <div className='rmdp-header-values'>
                      <div className='rmdp-day-picker rmdp-full-year'>
                        {/* <div
                          className='rmdp-wrapper rmdp-shadow custom-calendar'
                          style={{marginLeft: '0px', fontSize: '30px'}}
                        > */}
                          <>
                            <div
                              className='rmdp-month-name rmdp-header'
                              style={{fontSize: '30px', display: 'grid',}}
                            >
                              <Calendar
                                style={{columnGap: '80px'}}
                                className='custom-calendar'
                                fullYear
                                multiple
                                readOnly
                                value={dates}
                                excludeDates={disabledDates}
                                minDate={new DateObject(currentDate)}
                                maxDate={new DateObject(expiary)}
                                onChange={setSelectedDates}
                                mapDays={({date}) => {
                                  const Date = date.format('YYYY/MM/DD')
                                  const formattedDates = reservedDates.map((date) => {
                                    const year = date.getFullYear()
                                    const month = (date.getMonth() + 1).toString().padStart(2, '0')
                                    const day = date.getDate().toString().padStart(2, '0')
                                    return `${year}/${month}/${day}`
                                  })

                                  const isReserved = formattedDates.includes(Date)

                                  if (isReserved) {
                                    return {
                                      disabled: true,
                                      style: {
                                        color: 'white',
                                        backgroundColor: 'green',
                                        cursor: 'pointer',
                                        paddingTop: '3px',
                                      },
                                    }
                                  }

                                  return null
                                }}
                              />
                            </div>
                          </>
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Tab.Pane>
        </Tab.Content>
        <Tab.Content>
          <Tab.Pane eventKey='bookedInstructor'>
            <div>
              <BookedInstructor />
            </div>
          </Tab.Pane>
        </Tab.Content>
        <Tab.Content>
          <Tab.Pane eventKey='setTeacherAvailability'>
            <div>
              <div className='set-availability card p-10 '>


                
              <div
                  style={{
                    borderBottom: '2px solid #181c32',
                    paddingBottom: '10px',
                    paddingTop: '10px',
                    fontWeight: 'bold',
                    fontSize: '17px',
                    textAlign: 'center',
                  }}
                >
                  Set Instructor Availability-(
                  <span>
                    {instructordetails.message && instructordetails.message.FirstName}{' '}
                    {instructordetails.message && instructordetails.message.Surname}
                  </span>
                  )
                </div>

                <div >
                  <Row >
                    <Col sm={9} >
                  <div style={{padding: '16px'}}>
                    <li className='loream' style={{listStyle: 'none'}}>
                      <div class='circle blue'></div>
                      <span class='unbooked' style={{fontWeight: '700'}}>
                        Available For Training -
                      </span>
                      The dates instructors are available for training
                    </li>
                    <li className='loream' style={{listStyle: 'none'}}>
                      {' '}
                      <div class='circle grey'></div>
                      <span class='greyyy' style={{fontWeight: '700'}}>
                        Unavailable For Training -
                      </span>
                      The grey dates
                    </li>

                    <li className='loream' style={{listStyle: 'none'}}>
                      <div class='circle green'></div>
                      <span class='booked' style={{fontWeight: '700'}}>
                        Booked For Training -
                      </span>{' '}
                      The dates allocated/agreed for instructors to offer training
                    </li>
                  </div>
                    </Col>
                    <Col sm={3} style={{display: 'flex', justifyContent: 'flex-end'}}>
                  <div style={{paddingTop: '16px'}}>
                    <button
                      onClick={onSubmit1}
                      className='btn cyberbtnallpage'
                    >
                      Update Availability
                    </button>
                  </div>
                    </Col>
                  </Row>
                </div>


                <div
                  className='calender_header'
                  style={{display: 'flex', justifyContent: 'center', marginTop: '10px'}}
                >
                  <div>
                    <div className='rmdp-header-values'>
                      <div className='rmdp-day-picker rmdp-full-year'>
                        <div
                          className='rmdp-wrapper rmdp-shadow custom-calendar'
                          style={{marginLeft: '0px', fontSize: '30px'}}
                        >
                          <>
                            <div
                              className='rmdp-month-name rmdp-header'
                              style={{fontSize: '30px', padding: '0px', margin: '0px'}}
                            >
                              <Calendar
                                style={{columnGap: '80px'}}
                                className='custom-calendar'
                                fullYear
                                multiple
                                value={dates}
                                excludeDates={disabledDates}
                                minDate={new DateObject(currentDate)}
                                maxDate={new DateObject(expiary)}
                                onChange={setSelectedDates}
                                mapDays={({date}) => {
                                  const Date = date.format('YYYY/MM/DD')
                                  const formattedDates = reservedDates.map((date) => {
                                    const year = date.getFullYear()
                                    const month = (date.getMonth() + 1).toString().padStart(2, '0')
                                    const day = date.getDate().toString().padStart(2, '0')
                                    return `${year}/${month}/${day}`
                                  })

                                  const isReserved = formattedDates.includes(Date)

                                  if (isReserved) {
                                    return {
                                      disabled: true,
                                      style: {
                                        color: 'white',
                                        backgroundColor: 'green',
                                        cursor: 'pointer',
                                        paddingTop: '3px',
                                      },
                                    }
                                  }

                                  return null
                                }}
                              />
                            </div>
                          </>
                        </div>
                      </div>
                    </div>
                  </div>

                  <>
                    <Modal
                      title='Book Instructor'
                      centered
                      visible={isModalOpen}
                      onCancel={handleCancel}
                      footer={null}
                    >
                      {loading && (
                        <div className='loader-container'>
                          <Loader />
                        </div>
                      )}
                      <form style={{paddingTop: '2rem', paddingLeft: '1rem'}}>
                        <div>
                          <div>
                            <label
                              className='selectcountry'
                              style={{fontWeight: 'bold', fontSize: '15px'}}
                            >
                              Select a Course
                            </label>

                            <select
                              required
                              className='slect_course_lable form-control mb-4'
                              value={selectedCourseId}
                              onChange={handleCourseChange}
                              style={{
                                height: '4%',
                                border: '1px solid #ccc',
                                borderRadius: '5px',
                              }}
                            >
                              <option value=''>Select a course</option>
                              {teacherCourses.map((teacherCourse) => (
                                <option key={teacherCourse.id} value={teacherCourse.course_id}>
                                  {teacherCourse.title}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div>
                            <label
                              className='selectcountry'
                              style={{fontWeight: 'bold', fontSize: '15px'}}
                            >
                              Select Date :
                            </label>

                            <Table
                              bordered
                              id='Elevenplusyear_castomer'
                              style={{
                                tableLayout: 'fixed',
                              }}
                            >
                              <tbody style={{border: '1px solid #CECFCF', fontSize: '15px'}}>
                                <tr>
                                  <td width={40}></td>
                                  <th>Start Date & Time</th>
                                  <th>End Date & Time</th>
                                  <th>Timezone</th>
                                </tr>

                                {course?.dateRange && course.dateRange.length > 0 ? (
                                  course.dateRange.map((item, index) => {
                                    const start_datetime = moment
                                      .utc(item?.start_datetime || '')
                                  
                                    const end_datetime = moment
                                      .utc(item?.end_datetime || '')
                                      

                                    let isDisabled = false

                                    for (
                                      let date = moment(start_datetime);
                                      date.isSameOrBefore(end_datetime);
                                      date.add(1, 'day')
                                    ) {
                                      if (
                                        !allDates.some((dateInArray) =>
                                          moment(date).isSame(dateInArray, 'day')
                                        )
                                      ) {

                                        isDisabled = true
                                        
                                        break
                                      }
                                    }

                                    const tooltipContent = isDisabled
                                      ? 'Teacher is not available within this date range'
                                      : ''


                                    return (
                                      <React.Fragment key={item.id}>
                                        <tr style={{border: '1px solid #CECFCF'}}>
                                          <td>
                                            <Tooltip title={tooltipContent} placement='top'>
                                              <input
                                                type='radio'
                                                name={`dateType_${course.data.id}`}
                                                required
                                                onChange={() => {
                                                  handleDateSelection(
                                                    item.start_datetime,
                                                    item.end_datetime,
                                                    item.timezone,
                                                    item.datesId,
                                                  )
                                                  setSelectedDate(item.datesId);
                                                }}
                                                disabled={isDisabled}
                                              />
                                            </Tooltip>
                                          </td>
                                          <td>{start_datetime.format('DD/MM/YYYY, HH:mm')}</td>
                                          <td>{end_datetime.format('DD/MM/YYYY, HH:mm')}</td>
                                          <td>{item.timezone}</td>
                                        </tr>
                                      </React.Fragment>
                                    )
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan='2'>No Dates Available</td>
                                  </tr>
                                )}
                                
                              </tbody>
                            </Table>
                          </div>

                          <button
                            style={{
                              textAlign: 'center',
                              width: ' 100%',
                              color: '#ffff',
                              fontSize: '1rem',
                              borderRadius: '.375rem',
                              backgroundColor: selectedDate ? '#FF763F' : 'rgba(255, 118, 63, 0.5)', 
                              textTransform: 'capitalize',
                              border: 'none',
                              padding: '7px',
                              marginTop: '20px',
                            }}
                            type='submit'
                            className='slect_course_lable btn-warning'
                            onClick={onSubmit}
                            disabled={!selectedDate}
                          >
                            submit
                          </button>
                        </div>
                      </form>
                    </Modal>
                  </>
                </div>
              </div>
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  )
}

export default UserAvailabilityset
