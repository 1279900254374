import React, {useState, useEffect} from 'react'

import {Button} from '@mui/material'

import {Link, useNavigate} from 'react-router-dom'
import axios from 'axios'
import {useDispatch, useSelector} from 'react-redux'
import FormData from 'form-data'
import {useForm} from 'react-hook-form'

import {CreateLessonrAction} from '../../../../Redux/Action/createChapterAction'

function Createlesson() {
  const dispatch = useDispatch()

  const navigate = useNavigate()
  const [paymentLink, setPaymentLink] = useState('')
  const [show, setShow] = useState(false)
  const {
    register,
    handleSubmit,

    formState: {errors},
  } = useForm()

  var newURL = window.location


  var splitURL = newURL.href.split('/')

  const chapterId = splitURL[4]

  const onSubmit = (data) => {
    const formData = new FormData()
    formData.append('lessonname', data.lessonname)
    formData.append('desc', data.desc)
    formData.append('courseId', localStorage.getItem('courseId'))
    formData.append('pre_video', data.pre_video[0])

    formData.append('chapterId', chapterId)

    dispatch(CreateLessonrAction(formData, navigate))
  }
  return (
    <div>
      <div></div>

      <div>
        <form onSubmit={handleSubmit(onSubmit)} style={{padding: '2rem', borderRadius: '.375rem'}}>
          <div>
            <label>Lesson Name</label>
            <input
              className='form-control mb-4'
              type='text'
              name='lessonname'
              {...register('lessonname', {
                required: 'This field is required',
              })}
              aria-invalid={errors.lessonname ? 'true' : 'false'}
            />
            {errors.lessonname && (
              <p className='alert_danger' role='alert' style={{marginTop: '1rem'}}>
                {errors.lessonname?.message}
              </p>
            )}
          </div>
          <div>
            <label>Description</label>
            <input
              className='form-control mb-4'
              type='text'
              name='desc'
              {...register('desc', {
                required: 'This field is required.',
              })}
              aria-invalid={errors.desc ? 'true' : 'false'}
            />
            {errors.desc && (
              <p className='alert_danger' role='alert' style={{marginTop: '1rem'}}>
                {errors.desc?.message}
              </p>
            )}
          </div>

          <div>
            <label>Upload Video:</label>
            <input
                    class='form-control '
                    type='file'
                    name='pre_video'
                    style={{padding: '1rem'}}
                    {...register('pre_video', {
                      required: 'This field is required.',
                    })}
                    aria-invalid={errors.pre_video ? 'true' : 'false'}
                  />
                  {errors.pre_video && (
                    <p className='alert_danger' role='alert'>
                      {errors.pre_video?.message}
                    </p>
                  )}
          </div>



          <Button
            style={{
              textAlign: 'right',
              width: ' 100%',
              color: '#ffff',
              fontSize: '1rem',
              borderRadius: '.375rem',
              backgroundColor: 'rgb(81, 86, 190)',
              textTransform: 'capitalize',
            }}
            type='submit'
            className=' btn-warning'
            disableRipple
          >
            submit
          </Button>
        </form>
      </div>
    </div>
  )
}
export default Createlesson
