import React, { useState,useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "@mui/material";




import { Link, useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import FormData from "form-data";
import { useForm } from "react-hook-form";

import { CreateChapterAction } from "../../../../Redux/Action/createChapterAction";

import "../coursedetails.css"


function Createchapter() {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [paymentLink, setPaymentLink] = useState("");
  const [show, setShow] = useState(false);
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm();

  var newURL = window.location;

  var splitURL = newURL.href.split("/");

  const course_id = splitURL[4];


  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("chaptername", data.chaptername);
    formData.append("desc", data.desc);
    formData.append("course_slug", course_id);

    dispatch(CreateChapterAction(formData, navigate, course_id));
  };
  return (
    <div>
      

      <div >
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ padding: "2rem", borderRadius: ".375rem" }}
        >
          <div>
            <label>Chapter Name</label>
            <input
              className="form-control mb-4"
              type="text"
              name="chaptername"
              {...register("chaptername", {
                required: "This is required field",
              })}
              aria-invalid={errors.chaptername ? "true" : "false"}
            />
            {errors.chaptername && (
              <p
                className="alert_danger"
                role="alert"
                style={{ marginTop: "1rem" }}
              >
                {errors.chaptername?.message}
              </p>
            )}
          </div>
          <div>
            <label>Description</label>
            <input
              className="form-control mb-4"
              type="text"
              name="desc"
              {...register("desc", {
                required: "This is required field",
              })}
              aria-invalid={errors.desc ? "true" : "false"}
            />
            {errors.desc && (
              <p
                className="alert_danger"
                role="alert"
                style={{ marginTop: "1rem" }}
              >
                {errors.desc?.message}
              </p>
            )}
          </div>

          <Button
            style={{
              textAlign: "right",
              width: " 100%",
              color: "#ffff",
              fontSize: "1rem",
              borderRadius: ".375rem",
              backgroundColor: "rgb(81, 86, 190)",
              textTransform: "capitalize",
            }}
            type="submit"
            className=" btn-warning"
            disableRipple
          >
            submit
          </Button>
        </form>
      </div>
    </div>
  );
}
export default Createchapter;
