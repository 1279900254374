import React from 'react'
import cyberheadlogo from '../../assets/images/cyberheadlogo.png'
import './Commingsoon.css'
import {NavLink} from 'react-router-dom'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
function ComingSoon() {
  return (
    <div>
      <Navbar />
      <div
        className='commingsoon '
        style={
          {
            // backgroundImage: `url(${cyberheadlogo})`,
            // height: "100vh",
            // filter: "blur(15px)",
            // background: "radial-gradient(circle at 50% 50%,rgba(255,118,63),rgba(0,0,0))",
            // background: "black",
          }
        }
      >
        {/* Content */}
      </div>

      {/* <div className='commimgsoondfdgdg'> */}
      <h1 className='text-center p-10'>coming soon...</h1>
      {/* </div> */}
      {/* 
      <div class="commimg">
        <h1>Coming soon...</h1>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
        <NavLink to="/">
        <button
            className="btn w-35 mt-0 mt-sm-4 button "
            type="submit"
            id="login"
            style={{
              background: "",
              color: "white",
              padding: "10px",
            }}
          >
            Back
          </button>
        </NavLink>
          
        </div>
      </div> */}
      <Footer />
      <div></div>
    </div>
  )
}

export default ComingSoon
