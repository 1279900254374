import React from 'react'

import './verifymail.css'

import {NavLink} from 'react-router-dom'
import swal from 'sweetalert'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import {useEffect} from 'react'

import emailVerify from '../../assets/images/VerifyEmail.png'
import {useLocation} from 'react-router-dom'
const Verifymail = () => {
  const location = useLocation()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const message = searchParams.get('message')
    if (message) {
      swal({
        title: 'Success!',
        text: 'Payment successful. Thank you for your purchase!',
        icon: 'success',
        buttons: {
          cancel: {
            text: 'OK',
            value: null,
            visible: true,
            className: 'swal-button--confirm cyberbtnallpage', 
          },
        },
      }).then(() => {
        const newUrl = window.location.pathname
        window.history.pushState({}, '', newUrl)
      })
    }
  }, [location.search])
  return (
    <div>
      <Navbar />

      <div className='container' style={{padding: '60px', paddingBottom: '13rem', marginTop: '13rem'}}>
        <div className='row emailstyle' style={{}}>
          <div className='emailverfication_box'>
            <div className='col-md-12 text-center'>
              <img src={emailVerify} alt='img' style={{width: '250px'}} />
            </div>
            <div className='col-md-12 text-center'>
              <h2>Please Verify Your Email</h2>
            </div>

            <p style={{fontSize: '20px', fontWeight: '400'}}>
              You're almost there! We Sent an email to registered email.
            </p>
            <p style={{fontSize: '20px'}}>
              Just click on the link in that email to that complete your signup.
              <br />
              if you don't see it, you may need to check your spam folder.
            </p>
            <p></p>
            <div
              className='col-md-6  emailverfiy_btn'
              style={{justifyContent: 'center', width: '25%'}}
            >
              <div className>
                <NavLink to='/login'>
                  <button
                    type='submit'
                    className='button button-rounded border-0'
                    style={{width: '100%', background: '#ff763f'}}
                  >
                    Login
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default Verifymail
