import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {thisMonthTopCourses} from '../../../../../Redux/Action/admindashboardAction'
import {Link} from 'react-router-dom'
import {FaAngleLeft, FaAngleRight} from 'react-icons/fa'

const MonthlyPurchases = () => {
  const dispatch = useDispatch()

  const [currentPage, setCurrentPage] = useState(1)
  function nextPage() {
    if (currentPage !== nPage) {
      setCurrentPage(currentPage + 1)
    }
  }

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  function changeCPage(id) {
    setCurrentPage(id)
  }
  const topThisMonthCourses = useSelector((state) => {
    return state.login.getThisMonthTopCoursesList
  })

  useEffect(() => {
    dispatch(thisMonthTopCourses())
  }, [dispatch])

  const recordsPerPage = 10

  const lastIndex = currentPage * recordsPerPage

  const firstIndex = lastIndex - recordsPerPage

  const records = topThisMonthCourses.slice(firstIndex, lastIndex)

  const nPage = Math.ceil(topThisMonthCourses.length / recordsPerPage)

  const number = [...Array(nPage + 1).keys()].slice(1)

  const recentSevendaysCoursesLength = topThisMonthCourses.length
  return (
    <div>
      <div>
        <div>
          <div class='card card-flush h-xl-100'>
            <div class='card-header pt-7'>
              <h3 class='card-title align-items-start flex-column'>
                <span class='card-label fw-bold text-gray-800'>Top Courses - This Month </span>
                <span class='text-gray-400 mt-1 fw-semibold fs-6'>
                  Total {recentSevendaysCoursesLength} Purchases
                </span>
              </h3>
            </div>

            <div class='card-body pt-3 pb-4'>
              <div class='table-responsive'>
                <table class='table table-row-dashed align-middle gs-0 gy-4 my-0'>
                  <thead>
                    <tr class='fs-7 fw-bold text-gray-500 border-bottom-0'>
                      <th class='p-0 w-200px w-xxl-450px'>#</th>
                      <th class='p-0 w-200px w-xxl-450px'>Course Name</th>
                      <th class='p-0 min-w-150px'>No of Purchases</th>
                    </tr>
                  </thead>
                  {recentSevendaysCoursesLength === 0 ? (
                    <tbody class='text-gray-600 fw-semibold'>
                      <tr>
                        <td colSpan={4} className='text-center fw-700'>
                          No Record Found
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {records.map((topThisMonthCourses, index) => {
                        const serialNumber = (currentPage - 1) * recordsPerPage + index + 1
                        return (
                          <tr>
                            <td>{serialNumber}</td>
                            <td>
                              <div class='d-flex align-items-center'>
                                <div class='d-flex justify-content-start flex-column'>
                                  {topThisMonthCourses.title}
                                </div>
                              </div>
                            </td>
                            <td class='text-start'>
                              <span class='text-gray-800 fw-bold d-block mb-1 fs-6'>
                                {topThisMonthCourses.purchase_count}
                              </span>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  )}
                </table>
                {recentSevendaysCoursesLength === 0 ? (
                  ''
                ) : (
                  <nav>
                    <ul className='pagination' style={{marginBottom: '20px'}}>
                      <li className='page-item'>
                        <Link to='#' className='page-link' onClick={prePage}>
                          <FaAngleLeft />
                        </Link>
                      </li>
                      {number.map((n, i) => (
                        <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                          <Link to='#' className='page-link' onClick={() => changeCPage(n)}>
                            {n}
                          </Link>
                        </li>
                      ))}
                      <li className='page-item'>
                        <Link to='#' className='page-link' onClick={nextPage}>
                          <FaAngleRight />
                        </Link>
                      </li>
                    </ul>
                  </nav>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MonthlyPurchases
