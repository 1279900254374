import React from 'react'
import './Footer.css'
import {Link, NavLink} from 'react-router-dom'
import EmailIcon from '@mui/icons-material/Email'

import logo from '../../PrivateImage/img/logo.png'

import facebook from '../../PrivateImage/img/facebook.svg'
import twitter from '../../PrivateImage/img/twitter4.png'
import linkedin from '../../PrivateImage/img/linkedin.svg'
import {FaXTwitter} from 'react-icons/fa6'
import {FaFacebookF} from 'react-icons/fa'
import {FaLinkedin} from 'react-icons/fa'
import { right } from '@popperjs/core'

function Footer() {
  const currentYear = new Date().getFullYear()
  return (
    <div className='footer_res'>
      <footer class='footer' style={{fontSize: '24px'}}>
        <div class='container'>
          <div class='row footerstart'>
            <div class='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 footertitle footer_content'>
              <Link to='/' class='brand'>
                <img src={logo} alt='' />
              </Link>
            </div>

            <div class='col-12 col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2 footer_content'>
              <ul className='footer_ul'>
                <li>
                  <NavLink to='/aboutus'>About Us</NavLink>
                </li>
                <li>
                  <NavLink to='/'>FAQs</NavLink>
                </li>
                <li>
                  <NavLink to='/blogs'>Blog</NavLink>
                </li>
              </ul>
            </div>
            <div class='col-12 col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2 footer_content'>
              <ul className='footer_ul1'>
                <li>
                  <NavLink to='/course'>Courses</NavLink>
                </li>
                <li>
                  <NavLink to='/Careers'>Careers</NavLink>
                </li>
                <li>
                  <NavLink to='/Consultancy'>Consulting</NavLink>
                </li>
              </ul>
            </div>
            <div class='col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 footer_content_email'>
              <a href='mailto:info@cyberheads.com' class='email'>
                <EmailIcon style={{fontSize:'25px' ,marginRight:"7px" ,marginBottom:"3px"}} />
                info@cyberheads.com 
              </a>
            </div>
          </div>

          <div class='copyright'>
            <div className='copyright_degine'>
              <p>&copy; Cyberheads, Inc. {currentYear}</p>
              <div
                className='copyright_degineterm'
                style={{fontSize: '17px', color: 'white', paddingLeft: '2rem'}}
              >
                <a href='#' style={{color: 'white'}}>
                  Terms of Service
                </a>{' '}
                |{' '}
                <a href='#' style={{color: 'white'}}>
                  Privacy Policy
                </a>{' '}
                |{' '}
                <a href='#' style={{color: 'white'}} className='legal'>
                  Legal
                </a>
              </div>
            </div>

            <ul className='socai-icons'>
              <li>
                <FaFacebookF style={{fontSize: '50px', color: 'white'}} />
              </li>
              <li>
                <FaXTwitter style={{fontSize: '50px', color: 'white'}} />
              </li>
              <li>
                <FaLinkedin style={{fontSize: '50px', color: 'white'}} />
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer
