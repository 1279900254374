import React, { useEffect, useState, useRef } from 'react'
import { GetMeeting_byidAction } from '../../../Redux/Action/zoomAction'
import { useDispatch, useSelector } from 'react-redux'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import AddIcon from '@mui/icons-material/Add'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'

const Meetingsdetailsidd = () => {
  const newUrl = window.location.href
  const splitUrl = newUrl.split('/')
  const dispatch = useDispatch()
  const meetingid = splitUrl[4]
  const dayMapping = {
    1: 'Sunday',
    2: 'Monday',
    3: 'Tuesday',
    4: 'Wednesday',
    5: 'Thursday',
    6: 'Friday',
    7: 'Saturday',
  };
  const meetingdetails = useSelector((state) => {
    return state.login.getMeetingbyIdList
  })

  useEffect(() => {
    dispatch(GetMeeting_byidAction(meetingid))
  }, [dispatch])

  let cdata = ''

  const [copyStatuses, setCopyStatuses] = useState(new Array(meetingdetails.length).fill(''))
  const [startUrlCopyStatuses, setStartUrlCopyStatuses] = useState(
    new Array(meetingdetails.length).fill('')
  )
  const [joinUrlCopyStatuses, setJoinUrlCopyStatuses] = useState(
    new Array(meetingdetails.length).fill('')
  )

  // Function to handle copying for a specific meeting and URL type (start or join)
  const handleCopy = (item, urlKey, setCopyStatuses) => {
    return () => {
      setCopyStatuses((prevStatuses) => {
        const newStatuses = [...prevStatuses]
        newStatuses[item] = 'Copied'
        setTimeout(() => {
          newStatuses[item] = ''
          setCopyStatuses(newStatuses)
        }, 2000) // Reset the message after 2 seconds
        return newStatuses
      })
    }
  }

  const convertIntoHours = (duration) =>{
    const hours = Math.floor(duration / 60)
    const minutes = duration % 60
    const resultDuration = parseInt(hours) <= 0 ? `${minutes} Mins` : `${hours} Hrs ${minutes} Mins`
    return resultDuration;
  }

  const mapWeekDays = (weekdays) => {
    const weeklyDays = weekdays.split(',').map(dayNumber => dayMapping[dayNumber]);
    return weeklyDays.join(', ')
  }


  function formatDateString(originalDateString) {
    const originalDate = new Date(originalDateString);
  
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour12: true,
      timeZone: 'UTC',
    };
  
    return originalDate.toLocaleString('en-UK', options);
  }


  function convertTimestamp(timestamp) {
    const dateTimeParts = timestamp.split('T');
    const dateParts = dateTimeParts[0].split('-');
    const timeParts = dateTimeParts[1].split(':');

    const day = dateParts[2];
    const month = dateParts[1];
    const year = dateParts[0];

    const hour = timeParts[0];
    const minute = timeParts[1];

    return `${day}/${month}/${year}, ${hour}:${minute}`;
}

  return (
    <div className='row'>
      <div className='col-md-12'>
        <div className='card shadow-none'>
          <div className="card-header">
            <h2 className='card-title'>
              Meeting Details
            </h2>
          </div>
          <div className='card-body'>
            {console.log(meetingdetails, "meetingdetailsmeetingdetailsmeetingdetailsmeetingdetails")}
            {meetingdetails.map((meeting, item) => (
              <div className='container' key={item}>
                <div className="row mb-7">
                  <label className="col-lg-4 fw-semibold text-muted fs-6">Meeting Title</label>
                  <div className="col-lg-8 fw-semibold fv-row fs-6"><span>{meeting.topic}</span></div>
                </div>

                <div className="row mb-7">
                  <label className="col-lg-4 fw-semibold text-muted fs-6">Host Email</label>
                  <div className="col-lg-8 fw-semibold fv-row fs-6"><span>{meeting.host_email}</span></div>
                </div>

                <div className="row mb-7">
                  <label className="col-lg-4 fw-semibold text-muted fs-6">Start Date</label>
                  <div className="col-lg-8 fw-semibold fv-row fs-6">
                    <span>{convertTimestamp(meeting?.start_time || meeting?.occurrences[0]?.start_time || '')}
                    {/* , {meeting.startTime} */}
                    </span>
                    
                  </div>

                </div>


                <div className="row mb-7">
                  <label className="col-lg-4 fw-semibold text-muted fs-6">Duration</label>
                  <div className="col-lg-8 fw-semibold fv-row fs-6"><span>{meeting?.recurrence ? convertIntoHours(meeting?.occurrences[0]?.duration) : convertIntoHours(meeting.duration) }</span></div>
                </div>


                <div className="row mb-7">
                  <label className="col-lg-4 fw-semibold text-muted fs-6">Recurring</label>
                  <div className="col-lg-8 fw-semibold fv-row fs-6"><span>{meeting?.recurrence ? 'Yes' : 'No'}</span>

                  {meeting?.recurrence && (
                    <div className="col-lg-8 fw-semibold fv-row fs-6 mt-3">
                    <p>Type: {meeting.recurrence.type === 1 ? 'Daily' : (meeting.recurrence.type === 2 ? 'Weekly' : 'Monthly')}</p>
                    <p>Repeat Interval: Every {meeting.recurrence.repeat_interval} {meeting.recurrence.type === 1 ? 'Day(s)' : (meeting.recurrence.type === 2 ? 'Week(s)' : 'Month(s)')}</p>
                    {meeting.recurrence.type === 2 && (
                    <p>Weekly Days: {mapWeekDays(meeting.recurrence.weekly_days)}</p>
                    )}
                    {/* <p>End Date: {formatDate(meeting.occurrences[meeting.occurrences.length - 1].start_time)}, {meeting.endTime}</p>                    */}
                    <p>End Time: {meeting.endTime}</p>                   
                    </div>
                  )}
                  </div>
                </div>

                <div className="row mb-7">
                  <label className="col-lg-4 fw-semibold text-muted fs-6">Timezone</label>
                  <div className="col-lg-8 fw-semibold fv-row fs-6"><span>{meeting.timezone}</span></div>
                </div>

                <div className="row mb-7">
                  <label className="col-lg-4 fw-semibold text-muted fs-6">Join URL</label>
                  <div className="col-lg-8 fw-semibold fv-row fs-6"><span><Link to='#'>
                    <div style={{ display: 'flex' }}>
                      {meeting.join_url}
                      <CopyToClipboard
                        text={meeting.join_url}
                        onCopy={handleCopy(item, 'joinUrl', setJoinUrlCopyStatuses)}
                      >
                        <div className='copy'>
                          <span>
                            <i
                              style={{ fontSize: '20px' }}
                              className={`fa-regular fa-copy`}
                              disabled={
                                !window.ethereum?.selectedAddress ||
                                !localStorage.getItem('authToken')
                              }
                            ></i>
                          </span>
                        </div>
                      </CopyToClipboard>
                    </div>
                    {joinUrlCopyStatuses[item] && (
                      <span style={{ color: 'green', marginLeft: '5px' }}>
                        {joinUrlCopyStatuses[item]}
                      </span>
                    )}
                  </Link></span></div>
                </div>

                <div className="row mb-7">
                  <label className="col-lg-4 fw-semibold text-muted fs-6">Start URL</label>
                  <div className="col-lg-8 fw-semibold fv-row fs-6"><span>
                    <Link to='#'>
                    <div style={{ display: 'flex' }}>
                      {meeting.start_url.slice(0, 150)}
                      <CopyToClipboard
                        text={meeting.start_url}
                        onCopy={handleCopy(item, 'startUrl', setStartUrlCopyStatuses)}
                      >
                        <div className='copy'>
                          <span>
                            <i
                              style={{ fontSize: '20px' }}
                              className={`fa-regular fa-copy`}
                              disabled={
                                !window.ethereum?.selectedAddress ||
                                !localStorage.getItem('authToken')
                              }
                            ></i>
                          </span>
                        </div>
                      </CopyToClipboard>
                    </div>
                    {startUrlCopyStatuses[item] && (
                      <span style={{ color: 'green', marginLeft: '5px' }}>
                        {startUrlCopyStatuses[item]}
                      </span>
                    )}
                  </Link></span></div>
                </div>
              </div>
            ))}
          </div>
        </div>
       
      </div>

    </div>
  )
}

export default Meetingsdetailsidd
