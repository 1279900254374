import {lazy, FC, useEffect, useState} from 'react'

import {Route, Routes, Navigate, useNavigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'

import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import Pagenotfound from '../../components/Pagenotfound/Pagenotfound'
import {
  Adduser,
  AlluserList,
  Voucher,
  AdminInstructorUserList,
  Allcourse,
  AllPayements,
  UserRole,
  ViewRole,
  Permissions,
  AllTools,
  CreateTools,
  AddCourse,
  EditCourse,
  CreateBundle,
  UpdateBundle,
  GetallBundlelist,
  AddBundlecourse,
  CheckInstrcutorAvaibility,
  InstructorDetails,
  EditInstructor,
  AdminPro,
  Zoom,
  Reports,
  Edituser,
  AllRecentPurchases,
  TodayRecent,
  AllPurchases,
  MonthlyRecent,
  SevenDaysRecent,
  YesterdayRecent,
  MonthlyPurchases,
  LastMonthPurchases,
  LastSixMonthPurchases,
  AllMeetings,
  Meetingsdetails,
  CourseDetails,
  ChapterDetails,
  Editltitool,
  EditMeeting,
  Filtertable,
  Archivepastmeeting,
  UserDetails,
  Invoices,
  UserAvailabilityset,
  Meetingsdetailsidd,
  InstructorAvaibility,
  AddCareer,
  AllCareers,
  EditCareer,
  AllBlog,
  AddBlog,
  AllTestimonial,
  AddTestimonial,
  EditTestimonial,
  EditBlog,
  Deleteduserlist,
  AllChallengeLabs,
  AddChallengeLab,
  EditLab,
  SwitchBookings,
  CourseDates
} from '../../Dashboard/Admin_Dashboard/index'
import Student from '../../Dashboard/Student_Dashboard/Student'
import {MyCourse, MyBundles} from '../../Dashboard/Student_Dashboard/index'
import Purchasehistory from '../../Dashboard/Student_Dashboard/Pages/PurchaseHistory/Purchasehistory'
import {Dashboard} from '../../Dashboard/Admin_Dashboard/Dashboard/index'
import AllPurchaseHistory from '../../Dashboard/Student_Dashboard/Dashboard/Sections/History/History/AllPurchaseHistory'
import {Contentwriter} from '../../Dashboard/Content_Writer/Content_Writer'
import {CorporateManger} from '../../Dashboard/CorporateManger/CorporateManger'
import {CorporateEmployees} from '../../Dashboard/Corporate_Employees/Corporate_Employees'
import Mentoring from '../../Dashboard/Student_Dashboard/Dashboard/Sections/Section2/Mentoring'
import InactivityTimer from '../../Dashboard/utils/InactivityTimer'
import swal from 'sweetalert'

// CorporateManger
import {
  SetAvailibility,
  InsAllCourses,
  Invoice,
  InstructorDashboardMain,
} from '../../Dashboard/Instructor_Dashboard/index'

const PrivateRoutes = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('authToken'))
  const [roleid, setRoleid] = useState(localStorage.getItem('roleid'))

  const inactivityDuration = 15 * 60 * 1000;
  const navigate = useNavigate()
  const forceSet = localStorage.getItem('forceReset')
  useEffect(() => {
    setIsAuthenticated(!!localStorage.getItem('authToken'))
    setRoleid(localStorage.getItem('roleid'))
  }, [])

  if (!isAuthenticated) {
    return <Navigate to='/login' replace={true} />
  }

  const handleLogout = () => {
    localStorage.removeItem('authToken')
    localStorage.removeItem('forceResetOrg')
    localStorage.removeItem('forceReset')
    localStorage.clear();
    console.log(localStorage, "locaStoe=rage");
    swal({
      title: 'Login Again',
      text: 'You have been idle for more than 15 mins. Please login again to continue.!',
      icon: 'warning',
      closeOnClickOutside: false,
      buttons: {
        cancel: {
          text: 'OK',
          value: null,
          visible: true,
          className: 'swal-button--confirm cyberbtnallpage',
        },
      },
    }).then(() => {
      window.location.href = '/login'
    })
   
  }

  return (
    <div>

      <InactivityTimer onLogout={handleLogout} inactivityDuration={inactivityDuration} />


      <Routes>
        <Route element={<MasterLayout />}>
          {(roleid === '2' || roleid === '1') &&
          (forceSet === '0' || forceSet === 'null' || forceSet === '1') ? (
            <>
              {/* Redirect to Dashboard after successful login/registration */}
              {/* frontend  */}
              {/* Admin Dashboard */}
              <Route path='/dashboard' element={<Dashboard />} />
              <Route path='/builder' element={<BuilderPageWrapper />} />
              <Route path='/adduser' element={<Adduser />} />
              <Route path='/alluserlist' element={<AlluserList />} />
              <Route path='/instructor_userlist' element={<AdminInstructorUserList />} />
              <Route path='/voucher' element={<Voucher />} />
              <Route path='/Allcourse' element={<Allcourse />} />
              <Route path='/AllChallengeLabs' element={<AllChallengeLabs />} />
              <Route path='/AddChallengeLab' element={<AddChallengeLab  placeholder='Add ChallengeLab' />} />
              <Route path='/ChallengeLabs/edit/:id' element={<EditLab placeholder='Edit Lab' />} />
              <Route path='/allpayments' element={<AllPayements />} />
              <Route path='/user_role' element={<UserRole />} />
              <Route path='/view_role/:id' element={<ViewRole />} />
              <Route path='/permissions' element={<Permissions />} />
              <Route path='/all_tools' element={<AllTools />} />
              <Route path='/create_tools' element={<CreateTools />} />
              <Route path='/create_bundle' element={<CreateBundle />} />
              <Route path='/all_blog' element={<AllBlog />} />
              <Route path='/all_testimonial' element={<AllTestimonial />} />
              <Route path='/editBundle/:bundle_slug' element={<UpdateBundle />} />
              <Route path='/addbundle/:bundle_code' element={<AddBundlecourse />} />
              <Route path='/filter_table' element={<Filtertable />} />
              <Route path='/past_meetings' element={<Archivepastmeeting />} />
              <Route path='/meetingsdetails_byid/:id' element={<Meetingsdetailsidd />} />
              <Route path='/deleteduserlist' element={<Deleteduserlist />} />
              <Route
                path='/checkInstructorAvaibility/:courseId'
                element={<CheckInstrcutorAvaibility />}
              />
              <Route path='/TranferBookings/:courseId/:Insid/:Bookingid' element={<SwitchBookings/>} />
              <Route
                path='/addcourse'
                element={<AddCourse placeholder='Enter placeholder text' />}
              />
              <Route path='/add_blog' element={<AddBlog placeholder='Enter placeholder text' />} />
              <Route
                path='/add_testimonial'
                element={<AddTestimonial placeholder='Enter placeholder text' />}
              />
              <Route
                path='/edit_testimonial/:id'
                element={<EditTestimonial placeholder='Enter placeholder text' />}
              />
              <Route path='/edit_blog/:id' element={<EditBlog />} />
              <Route
                path='/addWebCareer'
                element={<AddCareer placeholder='Enter placeholder text' />}
              />
              <Route
                path='/webCareer/edit/:Id'
                element={<EditCareer placeholder='Enter placeholder text' />}
              />
              <Route path='/allWebCareers' element={<AllCareers />} />
              <Route path='/viewDetails/:Id' element={<InstructorDetails />} />
              <Route path='/UserDetails/:Id' element={<UserDetails />} />
              <Route path='/bundlelist' element={<GetallBundlelist />} />
              <Route path='/My_profile' element={<AdminPro />} />
              <Route path='/edit_users/:id' element={<Edituser />} />
              <Route path='/My_profile' element={<AdminPro />} />
              <Route path='/zoom' element={<Zoom />} />
              <Route path='/edit_Instructor/:Id' element={<EditInstructor />} />
              <Route path='/all_reports' element={<Reports />} />
              <Route path='/course_details_ch_less/:courseId' element={<CourseDetails />} />
              <Route
                path='/api/instructor/course/edit/:courseId'
                element={<EditCourse placeholder='Enter placeholder text' />}
              />
              <Route path='/getall_lessons/:chapter_slug' element={<ChapterDetails />} />
              <Route path='/AllRecent' element={<AllRecentPurchases />} />
              <Route path='/current_upcommimg_classes' element={<AllMeetings />} />
              <Route path='/allmeetingsdetails/:meetingid' element={<Meetingsdetails />} />
              <Route path='/todayRecent' element={<TodayRecent />} />
              <Route path='/AllPurchases' element={<AllPurchases />} />
              <Route path='/user-availabilityset/:id' element={<UserAvailabilityset />} />
              <Route path='/InstructorAvaibility/:id' element={<InstructorAvaibility />} />
              <Route path='/monthlyRecent' element={<MonthlyRecent />} />
              <Route path='/sevenDaysRecent' element={<SevenDaysRecent />} />
              <Route path='/yesterdayRecent' element={<YesterdayRecent />} />
              <Route path='/edit_meeting/:meetingId' element={<EditMeeting />} />
              <Route path='/thisMonthTopCourses' element={<MonthlyPurchases />} />
              <Route path='/LastMonthTopCourses' element={<LastMonthPurchases />} />
              <Route path='/LastSixMonthRecent' element={<LastSixMonthPurchases />} />
              <Route path='/edit_lti/:id' element={<Editltitool />} />
              <Route path='/invoices' element={<Invoices />} />
              <Route path='/CourseDates' element={<CourseDates />} />
            </>
          ) : roleid === '3' && (forceSet === '0' || forceSet === 'null' || forceSet === '1') ? (
            <>
              {/* Student Dashboard */}
              <Route path='/mydashboard' element={<Student />} />
              <Route path='/My_profile' element={<AdminPro />} />
              <Route path='/MyCourse' element={<MyCourse />} />
              <Route path='/MyBundles' element={<MyBundles />} />
              <Route path='/Purchasehistory' element={<Purchasehistory />} />
              <Route path='/AllPurchasehistory' element={<AllPurchaseHistory />} />
              <Route path='/Mentoring' element={<Mentoring />} />
              {/* <Route path="/Purchasehistory" element={<Purchasehistoryroutes />} /> */}
            </>
          ) : roleid === '4' && (forceSet === '0' || forceSet === 'null' || forceSet === '1') ? (
            <>
              {/* Instructor Dashboard */}
              <Route path='/instructors' element={<InstructorDashboardMain />} />
              <Route path='/Set_availability' element={<SetAvailibility />} />

              <Route path='/My_profile' element={<AdminPro />} />
              <Route path='/Ins/allCourses' element={<InsAllCourses />} />
              <Route path='/Invoice' element={<Invoice />} />

              {/* My_profile */}
            </>
          ) : roleid === '5' && (forceSet === '0' || forceSet === 'null' || forceSet === '1') ? (
            <>
              {/* Content_Writers Dashboard */}
              <Route path='/Content_Writers' element={<Contentwriter />} />
              <Route path='/My_profile' element={<AdminPro />} />
            </>
          ) : roleid === '6' && (forceSet === '0' || forceSet === 'null' || forceSet === '1') ? (
            <>
              {/* Corporates_Manager Dashboard */}
              <Route path='/Corporates_Manager' element={<CorporateManger />} />
              <Route path='/My_profile' element={<AdminPro />} />

              {/* My_profile */}
            </>
          ) : roleid === '7' && (forceSet === '0' || forceSet === 'null' || forceSet === '1') ? (
            <>
              {/* Corporate_Employees Dashboard */}
              <Route path='/Corporate_Employees' element={<CorporateEmployees />} />
              <Route path='/My_profile' element={<AdminPro />} />
            </>
          ) : (
            <>
              {/* Lazy Modules */}

              {/* Page Not Found */}
              {/* <Route path='*' element={<Pagenotfound />} /> */}
            </>
          )}
        </Route>
        <Route path='*' element={<Pagenotfound />} />
        

      </Routes>
    </div>
  )
}

export {PrivateRoutes}
