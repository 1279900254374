
import swal from 'sweetalert'
import axios from 'axios'
import {createAsyncThunk} from '@reduxjs/toolkit'

import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {getAllVoucherslice,getvoucheridslice,getvoucheruserbyIdslice} from "../Slice/authSlice"









export const ExamVouchers = (studentid) => async (dispatch) => {
  // debugger;
  try {
    const token = localStorage.getItem("authToken");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const getuserid = `${process.env.REACT_APP_BACKEND_API}/getVouchersby/${studentid}`;

    const res = await axios.get(getuserid, config);

    await dispatch(getvoucheridslice(res));
  } catch (e) {
    console.log(e);
  }
};

export const ExamVouchersPut = (studentid, course) => async (dispatch) => {

  // debugger;
  try {
    const token = localStorage.getItem("authToken");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const updatevoucherid = `${process.env.REACT_APP_BACKEND_API}/updateexpiary/${studentid}/${course}`;
    const res = await axios.put(updatevoucherid, config);

    if (res.status === 200) {
      window.location.reload();
    }
  } catch (e) {
    console.log(e, "eeror in api");
  }
};




export const AddExamVoucher = (formData) => async (dispatch) => {
   
    try {
      const token = localStorage.getItem("authToken");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
  
      const url = process.env.REACT_APP_BACKEND_API + "/voucher";
      const res = await axios.post(url, formData, config);
      if (res.status === 200) {
        window.location.reload();
        swal("Success", res.data.message, "success");
      }
    } catch (e) {
      if (e?.response?.data.message) {
        swal("error", e.response.data.message, "error");
      }
    }
  };


  export const AllExamVoucher = () => async (dispatch) => {
  
  
    try {
   
      const token = localStorage.getItem("authToken");
  
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
  
      const getuserid = `${process.env.REACT_APP_BACKEND_API}/getvoucherdetails`;
  
      const res = await axios.get(getuserid, "", config);
  
      await dispatch(getAllVoucherslice(res));
    } catch (e) {
      console.log(e);
    }
  };


  export const DeleteVoucherAction = (props) => async (dispatch) => {
    try {
      const token = localStorage.getItem("authToken");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
  
      const deleteuser = `${process.env.REACT_APP_BACKEND_API}/deleteVoucherById/${props}`;
      const res = await axios.delete(deleteuser, config);
  
      if (res.status === 200) {
 
       
        window.location.reload();
        swal("Success", res.data.message, "success");
      }
    } catch (e) {
      console.log(e);
    }
  };



  
  export const ExamVouchersbyUserId = (studentid) => async (dispatch) => {

  
    try {
   
      const token = localStorage.getItem("authToken");
  
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
  
      const getuserid = `${process.env.REACT_APP_BACKEND_API}/checkVouchers/${studentid}`;
  
      const res = await axios.get(getuserid, config);
  
      await dispatch(getvoucheruserbyIdslice(res));
    } catch (e) {
      console.log(e);
    }
  };



  export const UpdateExamVoucher = (formData, id) => async (dispatch) => {
    try {
      debugger;
      const token = localStorage.getItem("authToken");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
  
      const url = process.env.REACT_APP_BACKEND_API + `/updateVoucherbyCode/${id}`;
      const res = await axios.put(url, formData, config);
      if (res.status === 200) {
        swal({
          title: 'Success',
          text: 'Vouchers Updated',
          icon: 'success',
          buttons: {
            cancel: {
              text: 'OK',
              value: null,
              visible: true,
              className: 'swal-button--confirm cyberbtnallpage',
            },
          },
        }).then(() => {
          window.location.reload();
        })
      }
    } catch (e) {
      if (e?.response?.data.message) {
        // swal("error", e.response.data.message, "error");
        swal({
          title: 'error',
          text: e.response.data.message,
          icon: 'error',
          buttons: {
            cancel: {
              text: 'OK',
              value: null,
              visible: true,
              className: 'swal-button--confirm cyberbtnallpage',
            },
          },
        })
      }
    }
  };