import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Dropdown from 'react-bootstrap/Dropdown'
import AddIcon from '@mui/icons-material/Add'
import {
  GetAllMeetingAction,
  deleteMeeting,
  GetMeeting_byidAction,
} from '../../../Redux/Action/zoomAction'
import {FaEdit, FaTrash, FaPlus} from 'react-icons/fa'

import {TbListDetails} from 'react-icons/tb'
import {Tooltip} from 'antd'

import {CopyToClipboard} from 'react-copy-to-clipboard'
import {Link, useNavigate} from 'react-router-dom'

import {AiOutlineLeft, AiOutlineRight} from 'react-icons/ai'
import {FilterMatchMode} from 'primereact/api'
import {MultiSelect} from 'primereact/multiselect'
import {Toolbar} from 'primereact/toolbar'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {MdOutlineContentCopy} from 'react-icons/md'

export default function AllMeetings() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [filters, setFilters] = useState({
    global: {value: null, matchMode: FilterMatchMode.CONTAINS},
    title: {value: null, matchMode: FilterMatchMode.CONTAINS},
  })

  const columns = [{field: 'title', header: 'Title'}]

  const onColumnToggle = (event) => {
    let selectedColumns = event.value
    let orderedSelectedColumns = columns.filter((col) =>
      selectedColumns.some((sCol) => sCol.field === col.field)
    )

    setVisibleColumns(orderedSelectedColumns)
  }

  const [loading, setLoading] = useState(true)
  const [globalFilterValue, setGlobalFilterValue] = useState('')
  const [visibleColumns, setVisibleColumns] = useState(columns)

  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    let _filters = {...filters}

    _filters['global'].value = value

    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const meeting = useSelector((state) => {
    const meetings = state.login.getmeetingList?.futureMeetings || []

    const meeting = meetings.map((item, index) => ({
      ...item,
      SNo: index + 1,
      status: 'Published',
    }))

    return meeting
  })

  useEffect(() => {
    dispatch(GetAllMeetingAction()).then(() => {
      setLoading(false)
    })
  }, [dispatch])

  const deleteHandler = (props) => {
    dispatch(deleteMeeting(props))
  }

  const meetingdetails = useSelector((state) => {
    return state.login.getMeetingbyIdList
  })

  const [fetchedUrl, setFetchedUrl] = useState(null)
  const [clickedMeetingId, setClickedMeetingId] = useState(null)
  const [copied, setCopied] = useState(false)

  const handleClick = (meetingId) => {
    setClickedMeetingId(meetingId)
    dispatch(GetMeeting_byidAction(meetingId))
  }

  const copyToClipboard = (text) => {
    if (text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          console.log('Copied to clipboard successfully!')
          setCopied(true)
          setTimeout(() => setCopied(false), 2000)
        })
        .catch((err) => {
          console.error('Failed to copy text to clipboard:', err)
        })
    }
  }

  useEffect(() => {
    if (clickedMeetingId) {
      const meeting = meetingdetails.find((meeting) => meeting.id == clickedMeetingId)
      const url = meeting?.start_url
      setFetchedUrl(url)
    }
  }, [clickedMeetingId, meetingdetails])

  const renderBody = (rowData) => {
    const meetingId = rowData.meetingId
    return (
      <div>
        <span
          onClick={() => {
            handleClick(meetingId)
          }}
          style={{cursor: 'pointer', color: 'blue'}}
        >
          {meetingId === clickedMeetingId && fetchedUrl !== null ? (
            <>
              {fetchedUrl?.slice(0, 50)}....
              <MdOutlineContentCopy
                style={{
                  fontSize: '20px',
                  paddingRight: '5px',
                  color: 'rgb(126, 130, 153)',
                }}
                onClick={() => copyToClipboard(fetchedUrl)}
              />
            </>
          ) : (
            'Show Url'
          )}
        </span>
        {copied && meetingId === clickedMeetingId && <div>Copied</div>}
      </div>
    )
  }

  const rightToolbarTemplate = () => {
    return (
      <div class='card-toolbar'>
        <div class='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
          <Link to='/zoom' type='button' class='btn cyberbtnallpage'>
            <AddIcon /> Add Meeting
          </Link>
        </div>
      </div>
    )
  }

  const leftToolbarTemplate = () => {
    return (
      <MultiSelect
        value={visibleColumns}
        options={columns}
        optionLabel='header'
        onChange={onColumnToggle}
        className='w-full sm:w-20rem'
        display='chip'
      />
    )
  }

  const actionTemplate = (rowData) => {
    return (
      <div style={{display: 'flex', gap: '0px', marginLeft: '-2rem'}}>
        <div style={{fontSize: '15px'}}>
          <Tooltip title='Edit'>
            <button
              onClick={() => navigate(`/edit_meeting/${rowData.meetingId}`)}
              style={{border: 'none', background: 'white'}}
            >
              <span
                style={{
                  fontSize: '18px',
                  paddingRight: '5px',
                  color: 'rgb(126, 130, 153)',
                }}
              >
                <FaEdit />
              </span>
            </button>
          </Tooltip>

          <Tooltip title='View'>
            <button
              onClick={() => navigate(`/meetingsdetails_byid/${rowData.meetingId}`)}
              style={{border: 'none', background: 'white'}}
            >
              <span
                style={{
                  fontSize: '18px',
                  paddingRight: '5px',
                  color: 'rgb(126, 130, 153)',
                }}
              >
                <TbListDetails />
              </span>
            </button>
          </Tooltip>
          <Tooltip title='Delete'>
            <Link to='#' onClick={() => deleteHandler(rowData.meetingId)}>
              <span
                style={{
                  fontSize: '18px',
                  paddingRight: '5px',
                  color: 'rgb(126, 130, 153)',
                }}
              >
                <FaTrash />
              </span>
            </Link>
          </Tooltip>
        </div>
      </div>
    )
  }

  return (
    <div>
      <Toolbar className='mb-4' left={leftToolbarTemplate} right={rightToolbarTemplate} />
      <DataTable
        value={meeting}
        paginator
        rows={20}
        tableStyle={{minWidth: '100%'}}
        dataKey='id'
        filters={filters}
        filterDisplay='row'
        className='card'
        globalFilterFields={['title']}
        emptyMessage='No meetings found.'
        rowClassName='table-row'
      >
        <Column field='SNo' header='#' sortable />
        {visibleColumns.map((col) => (
          <Column
            sortable
            key={col.field}
            field={col.field}
            header={col.header}
            filter
            style={{minWidth: '15rem'}}
            filterPlaceholder={`Search by ${col.header}`}
          />
        ))}
        <Column
          field='meetingId'
          header='Meeting Id'
          filterPlaceholder='Search by status'
          style={{minWidth: '12rem'}}
        />
        <Column
          field='startDateTime'
          header='Time'
          filterPlaceholder='Search by status'
          style={{minWidth: '12rem'}}
        />
        {/* <Column
          field='startUrl'
          header='Start Url'
          body={(rowData) => (
            <span
              onClick={() => handleClick(rowData.meetingId)}
              style={{cursor: 'pointer', color: 'blue'}}
            >
              Show Url
            </span>
          )}
          filterPlaceholder='Search by status'
          style={{minWidth: '12rem'}}
        /> */}

        <Column
          field='startUrl'
          header='Start Url'
          body={renderBody}
          filterPlaceholder='Search by status'
          style={{width: '300px'}}
        />
        <Column
          field='joinUrl'
          header='Join Url'
          body={(rowData) => rowData.joinUrl.slice(0, 50)}
          filterPlaceholder='Search by status'
          style={{minWidth: '12rem'}}
        />
        <Column
          field='id'
          header='Action'
          body={actionTemplate}
          exportable={false}
          style={{marginLeft: '-12rem'}}
        />
      </DataTable>
    </div>
  )
}
// const AllMeetings = () => {
//   const dispatch = useDispatch()

//   const navigate = useNavigate()

//   const meetings = useSelector((state) => {
//     return state.login.getmeetingList
//   })

//   const futureMeetings = meetings.futureMeetings

//   const meeting = futureMeetings || []

//   useEffect(() => {
//     dispatch(GetAllMeetingAction())
//   }, [dispatch])

//   const meetingid = localStorage.getItem('userid')

//   const handleDeleteButtonClick = (meetingId) => {
//     // debugger;
//     dispatch(deleteMeeting(meetingId))
//   }

//   const [copyStatuses, setCopyStatuses] = useState(new Array(meetings.length).fill(''))
//   const [startUrlCopyStatuses, setStartUrlCopyStatuses] = useState(
//     new Array(meeting.length).fill('')
//   )
//   const [joinUrlCopyStatuses, setJoinUrlCopyStatuses] = useState(new Array(meeting.length).fill(''))

//   // Function to handle copying for a specific meeting and URL
//   const handleCopy = (item, urlKey, setCopyStatuses) => {
//     return () => {
//       setCopyStatuses((prevStatuses) => {
//         const newStatuses = [...prevStatuses]
//         newStatuses[item] = 'Copied'
//         setTimeout(() => {
//           newStatuses[item] = ''
//           setCopyStatuses(newStatuses)
//         }, 2000) // Reset the message after 2 seconds
//         return newStatuses
//       })
//     }
//   }

//   const [selectedmeetingId, setSelectedmeetingId] = useState(null)

//   const handleCourseSelection = (meetingid) => {
//     setSelectedmeetingId(meetingid)
//   }

//   function nextPage() {
//     if (currentPage !== nPage) {
//       setCurrentPage(currentPage + 1)
//     }
//   }

//   function prePage() {
//     if (currentPage !== 1) {
//       setCurrentPage(currentPage - 1)
//     }
//   }

//   function changeCPage(id) {
//     setCurrentPage(id)
//   }

//   const [currentPage, setCurrentPage] = useState(1)

//   const recordsPerPage = 10

//   const lastIndex = currentPage * recordsPerPage

//   const firstIndex = lastIndex - recordsPerPage

//   const records = meeting.slice(firstIndex, lastIndex)

//   const nPage = Math.ceil(meeting.length / recordsPerPage)

//   const number = [...Array(nPage + 1).keys()].slice(1)

//   function getDateFromDateTimestamp(timestamp) {
//     const dateObject = new Date(timestamp)
//     const year = dateObject.getFullYear()
//     const month = String(dateObject.getMonth() + 1).padStart(2, '0')
//     const day = String(dateObject.getDate()).padStart(2, '0')
//     const hours = String(dateObject.getHours()).padStart(2, '0')
//     const minutes = String(dateObject.getMinutes()).padStart(2, '0')

//     return `${day}/${month}/${year} ${hours}:${minutes}`
//   }

//   return (
//     <div className='user-list'>
//       <div id='kt_app_content_container'>
//         <div class='card'>
//           <div className='card-header border-0 pt-6'>
//             <div className='card-title'>
//               <h3 className='fw-bold m-0'>Meetings List</h3>
//             </div>
//             <div class='card-toolbar'>
//               <div class='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
//                 <Link to='/zoom' type='button' class='btn cyberbtnallpage'>
//                   <AddIcon /> Add Meeting
//                 </Link>
//               </div>
//             </div>
//           </div>
//           <div class='card-body py-4'>
//             <div className='table-responsive'>
//               <table class='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>
//                 <thead>
//                   <tr class='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
//                     <th class='min-w-50px'>#</th>

//                     <th class='min-w-125px'>Meeting ID</th>
//                     <th class='min-w-125px'>Title</th>
//                     <th class='min-w-125px'>Time</th>
//                     <th class='min-w-125px'>start url</th>
//                     <th class='min-w-125px'>join url</th>
//                     <th class='min-w-125px'>Actions</th>
//                   </tr>
//                 </thead>

//                 <tbody class='text-gray-600 fw-semibold'>
//                   {records.map((meeting, item) => (
//                     <tr key={item}>
//                       <td>{firstIndex + item + 1}</td>

//                       <td>
//                         <span style={{color: '#7e8299'}}>{meeting.meetingId}</span>
//                       </td>

//                       <td>
//                         <Link to={`/meetingsdetails_byid/${meeting.meetingId}`}>
//                           {meeting.title}
//                         </Link>
//                       </td>

//                       <td>{meeting.startDateTime}</td>

//                       {/* <td>{meeting.duration}min</td> */}

//                       <td className='text-center'>
//                         <div style={{display: 'flex'}}>
//                           {meeting.startUrl.slice(0, 20)}
//                           <CopyToClipboard
//                             text={meeting.startUrl}
//                             onCopy={handleCopy(item, 'startUrl', setStartUrlCopyStatuses)}
//                           >
//                             <div className='copy'>
//                               <a>
//                                 <i
//                                   style={{fontSize: '20px'}}
//                                   className={`fa-regular fa-copy`}
//                                   disabled={
//                                     !window.ethereum?.selectedAddress ||
//                                     !localStorage.getItem('authToken')
//                                   }
//                                 ></i>
//                               </a>
//                             </div>
//                           </CopyToClipboard>
//                         </div>
//                         {startUrlCopyStatuses[item] && (
//                           <span style={{color: 'green', marginLeft: '5px'}}>
//                             {startUrlCopyStatuses[item]}
//                           </span>
//                         )}
//                       </td>

//                       <td className='text-center'>
//                         <div style={{display: 'flex'}}>
//                           {meeting.joinUrl.slice(0, 20)}
//                           <CopyToClipboard
//                             text={meeting.joinUrl}
//                             onCopy={handleCopy(item, 'joinUrl', setJoinUrlCopyStatuses)}
//                           >
//                             <div className='copy'>
//                               <a>
//                                 <i
//                                   style={{fontSize: '20px'}}
//                                   className={`fa-regular fa-copy`}
//                                   disabled={
//                                     !window.ethereum?.selectedAddress ||
//                                     !localStorage.getItem('authToken')
//                                   }
//                                 ></i>
//                               </a>
//                             </div>
//                           </CopyToClipboard>
//                         </div>
//                         {joinUrlCopyStatuses[item] && (
//                           <span style={{color: 'green', marginLeft: '5px'}}>
//                             {joinUrlCopyStatuses[item]}
//                           </span>
//                         )}
//                       </td>

//                       <td>
//                         <div style={{fontSize: '15px'}}>
//                           <Tooltip title='Edit'>
//                             <button
//                               onClick={() => navigate(`/edit_meeting/${meeting.meetingId}`)}
//                               style={{border: 'none', background: 'white'}}
//                             >
//                               <span
//                                 style={{
//                                   fontSize: '18px',
//                                   paddingRight: '5px',
//                                   color: 'rgb(126, 130, 153)',
//                                 }}
//                               >
//                                 <FaEdit />
//                               </span>
//                             </button>
//                           </Tooltip>
//                           {/* <Link to={`/avilable_Instructor/${course.id}`}> */}
//                           <Tooltip title='View'>
//                             <button
//                               onClick={() => navigate(`/meetingsdetails_byid/${meeting.meetingId}`)}
//                               style={{border: 'none', background: 'white'}}
//                             >
//                               <span
//                                 style={{
//                                   fontSize: '18px',
//                                   paddingRight: '5px',
//                                   color: 'rgb(126, 130, 153)',
//                                 }}
//                               >
//                                 <TbListDetails />
//                               </span>
//                             </button>
//                           </Tooltip>
//                           <Tooltip title='Delete'>
//                             <Link to='#' onClick={() => handleDeleteButtonClick(meeting.meetingId)}>
//                               <span
//                                 style={{
//                                   fontSize: '18px',
//                                   paddingRight: '5px',
//                                   color: 'rgb(126, 130, 153)',
//                                 }}
//                               >
//                                 <FaTrash />
//                               </span>
//                             </Link>
//                           </Tooltip>
//                         </div>

//                         <div
//                           class='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
//                           data-kt-menu='true'
//                         >
//                           <div class='menu-item px-3'></div>
//                         </div>
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </div>
//             <nav className='w-0'>
//               <ul className='pagination'>
//                 <li className='page-item'>
//                   <a href='#' className='page-link' onClick={prePage}>
//                     <AiOutlineLeft />
//                   </a>
//                 </li>
//                 {number.map((n, i) => (
//                   <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
//                     <a href='#' className='page-link' onClick={() => changeCPage(n)}>
//                       {n}
//                     </a>
//                   </li>
//                 ))}
//                 <li className='page-item'>
//                   <a href='#' className='page-link' onClick={nextPage}>
//                     <AiOutlineRight />
//                   </a>
//                 </li>
//               </ul>
//             </nav>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default AllMeetings
