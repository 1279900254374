import React from "react";


import ProfileDetail from "./ProfileDetail/ProfileDetails";
const AdminProfile = () => {
  return (
    <div className="overview-overview">
      <div className="profile-detail">
        <ProfileDetail />
      </div>
    </div>
  );
};

export default AdminProfile;


