import { createSlice } from '@reduxjs/toolkit'

const authSlice = createSlice({
  name: 'user',
  initialState: {
    login: [],
    userlist: [],
    ltitoolslist: [],
    lunchlablist: [],
    getuseridlist: [],
    getteachercourseslist: [],
    getcourseslist: [],
    boughtcourseslist: [],
    checkcourseslist: [],
    getcoursebyidlist: [],
    getpreviewdetailslist: [],
    getallchapterlist: [],
    getalllessonlist: [],
    getchapterlist: [],
    getlessonidlist: [],
    getvoucherbyidlist: [],
    getavailibilitylist: [],
    getavailibilityInstlist: [],
    getavaibilityCourselist: [],
    getuserIdlist: [],
    getvoucheruserIdlist: [],
    getaInstructorCourseslist: [],
    getaInstructorIdlist: [],
    getaInstructorAdminlist: [],
    getallRangelist: [],
    getaInstructorAvaibilityReservedlist: [],
    getaInstructorAvaibilityAdminlist: [],
    getAllVoucherList: [],
    getAllPaymentList: [],
    getbundlelist: [],
    getbundlebyid: [],
    getbuycoursesandbundlelist: [],
    getAllInstructorList: [],
    getAllContentCreatorList: [],
    getAllStudentList: [],
    getAllEmployeesList: [],
    getCorporateMangerList: [],
    getCyberHeadTeamAdminList: [],
    getCoursesCountList: [],
    getPublicCoursesList: [],
    getDraftCoursesList: [],
    gettodayRecentList: [],
    getyesterdayRecentList: [],
    getAllBundlesList: [],
    getPublishBundlesList: [],
    getDraftBundlesList: [],
    getBundleSalesList: [],
    getTopCoursesList: [],
    getRecentCoursesList: [],
    getRecentSevenDaysCoursesList: [],
    getRecentMontlyCoursesList: [],
    getUsersByLocationList: [],
    getAllCountriesList: [],
    getStateByCountrList: [],
    getCityByStateList: [],
    getallusercountlist: [],
    getBundleCourselist: [],
    getboughtbundlelist: [],
    getStudentPaymentHistoryList: [],
    getBundleUserIdList: [],
    getInstructorCoursesList: [],
    getInstructorInvoiceCoursesList: [],
    getInstructorCalanderDatesList: [],
    getCalanderCourseSheduleList: [],
    getCountryFromISOList: [],
    getAllInvoiceList: [],
    getAllRolesList: [],
    getThisMonthTopCoursesList: [],
    getLastMonthTopCoursesList: [],
    getLastSixMonthTopCoursesList: [],
    getMapStateCountryList: [],
    getcheckbundleList: [],
    getmeetingList: [],
    getallmeeting_idList: [],
    getvideoprogess_list: [],
    getallcoursedeatils_list: [],
    getedituser_list: [],
    MaxRegistrationList: [],
    getMonthlySales_list: [],
    getLtiToolbyIdList: [],
    getallchapteredit_list: [],
    getMeetingbyIdList: [],
    getstudent_list: [],
    getcourseAvailability_list: [],
    getAllinvoices_list: [],
    getAllinvoicess_list: [],
    gitalltechercourseList: [],
    getInstructorfilterList: [],
    getAllAvaibility_list: [],
    getAllRoles_list: [],
    getuserbyrole_list: [],
    getparticipant_list: [],
    getBundleall_list: [],
    getuserDateslist: [],
    getuserSessionlist: [],
    getallCourseBundleslist: [],
    getTopPurchasedCourseslist: [],
    getStuentDateslist: [],
    addCareerPath: [],
    getcareersslicelist: [],
    getcareerbyidlist: [],
    getAllPublishedCareerslist: [],
    getblogbyidlist: [],
    getblogslist: [],
    gettestimoniallist: [],
    gettestimonialbyidlist: [],
    getAllPublishedTestimonialslist: [],
    getblogbysluglist: [],
    getpublishedbloglist: [],
    deletedUserList: [],
    emailPreviewList: [],
    sessionBysessionIdList: [],
    voucherByIdList: [],
    getLabsList: [],
    getPublishedLabsList: [],
    getLabByIdList: [],
    getDateList: [],
    paymentDetailList: [],
    payDetailList: [],
    courseDatesList: []
  },

  reducers: {
    loginslice: (state, action) => {
      state.login = action?.payload?.data?.data
    },

    getLtiToolByIdSlice: (state, action) => {
      state.getLtiToolbyIdList = action?.payload?.data
    },

    userlistslice: (state, action) => {
      state.userlist = action?.payload?.data
    },

    getltitoolslistslice: (state, action) => {
      state.ltitoolslist = action?.payload?.data
    },

    getlunchlabslice: (state, action) => {
      state.lunchlablist = action?.payload?.data
    },

    getuseridslice: (state, action) => {
      state.getuseridlist = action?.payload?.data.message
    },

    getcourseslice: (state, action) => {
      state.getcourseslist = action?.payload?.data
    },

    getteachercourseslice: (state, action) => {
      state.getteachercourseslist = action?.payload?.data
    },
    boughtcourseslice: (state, action) => {
      state.boughtcourseslist = action?.payload?.data
    },

    checkcourseslice: (state, action) => {
      state.checkcourseslist = action?.payload?.data
    },

    getcoursebyidslice: (state, action) => {
      state.getcoursebyidlist = action?.payload?.data
    },

    getpreviewdetailslice: (state, action) => {
      state.getpreviewdetailslist = action?.payload?.data
    },
    getallchapterslice: (state, action) => {
      state.getallchapterlist = action?.payload?.data
    },
    getallLesson_idslice: (state, action) => {
      state.getalllessonlist = action?.payload?.data
    },
    getchapteridslice: (state, action) => {
      state.getchapterlist = action?.payload?.data
    },
    getlessonidslice: (state, action) => {
      state.getlessonidlist = action?.payload?.data.data[0]
    },

    getvoucheridslice: (state, action) => {
      state.getvoucherbyidlist = action?.payload?.data
    },

    getavailibilityslice: (state, action) => {
      state.getavailibilitylist = action?.payload?.data
    },

    getavailibilityInstslice: (state, action) => {
      state.getavailibilityInstlist = action?.payload?.data
    },

    getavailibilityCourseslice: (state, action) => {
      state.getavaibilityCourselist = action?.payload?.data
    },

    getuserbyIdslice: (state, action) => {
      state.getuserIdlist = action?.payload?.data
    },

    getvoucheruserbyIdslice: (state, action) => {
      state.getvoucheruserIdlist = action?.payload?.data
    },

    getInstructorAdminListslice: (state, action) => {
      state.getaInstructorAdminlist = action?.payload?.data
    },

    getInsstrructorIdslice: (state, action) => {
      state.getaInstructorIdlist = action?.payload?.data
    },
    getInsstrructorCoursesslice: (state, action) => {
      state.getaInstructorCourseslist = action?.payload?.data
    },

    getRangeslice: (state, action) => {
      state.getallRangelist = action?.payload?.data
    },

    getInsstrructorAvaibilityReservedslice: (state, action) => {
      state.getaInstructorAvaibilityReservedlist = action?.payload?.data
    },

    getInsstrructorAvaibilityCoursesslice: (state, action) => {
      // debugger
      state.getaInstructorAvaibilityAdminlist = action?.payload?.data
    },
    getAllVoucherslice: (state, action) => {
      // debugger
      state.getAllVoucherList = action?.payload?.data
    },
    getAllPaymentslice: (state, action) => {
      // debugger
      state.getAllPaymentList = action?.payload?.data
    },
    getbundleslice: (state, action) => {
      state.getbundlelist = action?.payload?.data
    },

    getbundlebyidslice: (state, action) => {
      state.getbundlebyid = action?.payload?.data
    },
    buycoursesandbundleslice: (state, action) => {
      state.getbuycoursesandbundlelist = action?.payload?.data
    },

    getAllInstructorCountslice: (state, action) => {
      // debugger
      state.getAllInstructorList = action?.payload?.data
    },

    getAllContentCreatorCountslice: (state, action) => {
      // debugger
      state.getAllContentCreatorList = action?.payload?.data
    },

    getAllStudentCountslice: (state, action) => {
      // debugger
      state.getAllStudentList = action?.payload?.data
    },
    getAllEmployessCountslice: (state, action) => {
      // debugger
      state.getAllEmployeesList = action?.payload?.data
    },
    getAllCorporateManagerCountslice: (state, action) => {
      // debugger
      state.getCorporateMangerList = action?.payload?.data
    },
    getAllCyberHeadTeamAdminCountslice: (state, action) => {
      // debugger
      state.getCyberHeadTeamAdminList = action?.payload?.data
    },

    getAllCoursesslice: (state, action) => {
      state.getCoursesCountList = action?.payload?.data
    },

    getPublicCoursesslice: (state, action) => {
      state.getPublicCoursesList = action?.payload?.data
    },

    getDraftCoursesslice: (state, action) => {
      state.getDraftCoursesList = action?.payload?.data
    },

    getAllBundlesslice: (state, action) => {
      state.getAllBundlesList = action?.payload?.data
    },
    getPublishBundlesslice: (state, action) => {
      state.getPublishBundlesList = action?.payload?.data
    },
    getDraftBundlesslice: (state, action) => {
      state.getDraftBundlesList = action?.payload?.data
    },
    getTopCoursesslice: (state, action) => {
      state.getTopCoursesList = action?.payload?.data
    },

    getRecentCoursesslice: (state, action) => {
      state.getRecentCoursesList = action?.payload?.data
    },
    getRecentSevenDaysCoursesslice: (state, action) => {
      state.getRecentSevenDaysCoursesList = action?.payload?.data
    },
    getRecentMonthlyCoursesslice: (state, action) => {
      state.getRecentMontlyCoursesList = action?.payload?.data
    },

    getusersByLocationslice: (state, action) => {
      state.getUsersByLocationList = action?.payload?.data
    },
    getRecentslice: (state, action) => {
      state.gettodayRecentList = action?.payload?.data
    },
    getyesterdayRecentslice: (state, action) => {
      state.getyesterdayRecentList = action?.payload?.data
    },

    getAllCountriesslice: (state, action) => {
      // debugger
      state.getAllCountriesList = action?.payload?.data
    },

    getStateByCountryslice: (state, action) => {
      // debugger
      state.getStateByCountrList = action?.payload?.data
    },
    getCityByStateslice: (state, action) => {
      // debugger
      state.getCityByStateList = action?.payload?.data
    },

    getAllUserCountslice: (state, action) => {
      // debugger
      state.getallusercountlist = action?.payload?.data
    },
    getBundleCourseslice: (state, action) => {
      // debugger
      state.getBundleCourselist = action?.payload?.data
    },
    boughtbundleslice: (state, action) => {
      // debugger
      state.getboughtbundlelist = action?.payload?.data
    },
    getStudentPaymentHistoryslice: (state, action) => {
      // debugger
      state.getStudentPaymentHistoryList = action?.payload?.data
    },
    getBundlesByUserIdslice: (state, action) => {
      // debugger
      state.getBundleUserIdList = action?.payload?.data
    },

    getInstructorCoursesslice: (state, action) => {
      state.getInstructorCoursesList = action?.payload?.data
    },

    getInstructorInvoiceCoursesslice: (state, action) => {
      state.getInstructorInvoiceCoursesList = action?.payload?.data
    },

    getInstructorCalanderDatesslice: (state, action) => {
      state.getInstructorCalanderDatesList = action?.payload?.data
    },

    getCalanderCourseSheduleslice: (state, action) => {
      state.getCalanderCourseSheduleList = action?.payload?.data
    },

    getallInvoiceslice: (state, action) => {
      state.getAllInvoiceList = action?.payload?.data
    },
    getAllRolesslice: (state, action) => {
      // debugger
      state.getAllRolesList = action?.payload?.data
    },

    getThisMonthTopCoursesslice: (state, action) => {
      // debugger
      state.getThisMonthTopCoursesList = action?.payload?.data
    },

    getLastMonthTopCoursesslice: (state, action) => {
      // debugger
      state.getLastMonthTopCoursesList = action?.payload?.data
    },

    getLastSixMonthTopCoursesslice: (state, action) => {
      // debugger
      state.getLastSixMonthTopCoursesList = action?.payload?.data
    },

    getMapStateCountryslice: (state, action) => {
      // debugger
      state.getMapStateCountryList = action?.payload?.data
    },
    getCountryFromISOslice: (state, action) => {
      // debugger
      state.getCountryFromISOList = action?.payload?.data
    },
    getcheckbundleslice: (state, action) => {
      // debugger
      state.getcheckbundleList = action?.payload?.data
    },

    getBundleSalesslice: (state, action) => {
      // debugger
      state.getBundleSalesList = action?.payload?.data
    },
    getmeetingslice: (state, action) => {
      state.getmeetingList = action?.payload?.data
    },
    getmeeting_byidslice: (state, action) => {
      state.getallmeeting_idList = action?.payload?.data
    },

    getvideoprogressslice: (state, action) => {
      state.getvideoprogess_list = action?.payload?.data
    },
    getallcoursedetailsslice: (state, action) => {
      state.getallcoursedeatils_list = action?.payload?.data
    },
    getedit_usersidIdslice: (state, action) => {
      state.getedituser_list = action?.payload?.data
    },

    MaxRegistrationslice: (state, action) => {
      state.MaxRegistrationList = action?.payload?.data
    },
    getMonthlySalesSlice: (state, action) => {
      state.getMonthlySales_list = action?.payload?.data
    },
    getallchaptereditslice: (state, action) => {
      state.getallchapteredit_list = action?.payload?.data
    },

    getMeetingByIdSlice: (state, action) => {
      state.getMeetingbyIdList = action?.payload?.data
    },
    getstudentlistslice: (state, action) => {
      state.getstudent_list = action?.payload?.data
    },

    getcourseAvailabilityslice: (state, action) => {
      state.getcourseAvailability_list = action?.payload?.data
    },

    getAllinvoicesslice: (state, action) => {
      state.getAllinvoices_list = action?.payload?.data
    },

    getavailibilityyInstslice: (state, action) => {
      state.getAllinvoicess_list = action?.payload?.data
    },

    getallteachercourseslice: (state, action) => {
      state.gitalltechercourseList = action?.payload?.data
    },

    getInstructorFilterslice: (state, action) => {
      state.getInstructorfilterList = action?.payload?.data
    },

    getAllAvaibilityslice: (state, action) => {
      state.getAllAvaibility_list = action?.payload?.data
    },

    getAllRoleslice: (state, action) => {
      state.getAllRoles_list = action?.payload?.data
    },

    getuserbyroleslice: (state, action) => {
      state.getuserbyrole_list = action?.payload?.data
    },

    getparticipantslistslice: (state, action) => {
      state.getparticipant_list = action?.payload?.data
    },
    getBundledetailsslice: (state, action) => {
      state.getBundleall_list = action?.payload?.data
    },
    getuserdatesslice: (state, action) => {
      state.getuserDateslist = action?.payload?.data
    },
    getuserSessionslice: (state, action) => {
      state.getuserSessionlist = action?.payload?.data
    },
    getallCoursesBundlesslice: (state, action) => {
      state.getallCourseBundleslist = action?.payload?.data
    },

    getTopPurchasedCoursesSlice: (state, action) => {
      state.getTopPurchasedCourseslist = action?.payload?.data
    },

    getStuentDatesSlice: (state, action) => {
      state.getStuentDateslist = action?.payload?.data
    },
    addCareerPath: (state, action) => {
      state.addCareerPath = action?.payload?.data
    },

    getcareersslice: (state, action) => {
      state.getcareersslicelist = action?.payload?.data
    },

    getcareerbyidslice: (state, action) => {
      state.getcareerbyidlist = action?.payload?.data
    },
    getAllPublishedCareersslice: (state, action) => {
      state.getAllPublishedCareerslist = action?.payload?.data
    },

    getblogsbyidslice: (state, action) => {
      state.getblogbyidlist = action?.payload?.data
    },

    getblogsbyslugslice: (state, action) => {
      state.getblogbysluglist = action?.payload?.data
    },
    getpublishedblogsslice: (state, action) => {
      state.getpublishedbloglist = action?.payload?.data
    },
    getblogsslice: (state, action) => {
      state.getblogslist = action?.payload?.data
    },

    gettestimonialslice: (state, action) => {
      state.gettestimoniallist = action?.payload?.data
    },

    gettestimonialbyidslice: (state, action) => {
      state.gettestimonialbyidlist = action?.payload?.data
    },
    getAllPublishedTestimonialslice: (state, action) => {
      state.getAllPublishedTestimonialslist = action?.payload?.data
    },
    deletedUserListSlice: (state, action) => {
      state.deletedUserList = action?.payload?.data
    },
    sessionBysessionSlice: (state, action) => {
      state.sessionBysessionIdList = action?.payload?.data
    },
    getVouchherSlice: (state, action) => {
      state.voucherByIdList = action?.payload?.data
    },
    emailPreviewSlice: (state, action) => {
      state.emailPreviewList = action?.payload?.data
    },


    getLabsSlice: (state, action) => {
      state.getLabsList = action?.payload?.data
    },

    getPublishedLabsslice: (state, action) => {
      state.getPublishedLabsList = action?.payload?.data
    },

    getlabbyidslice: (state, action) => {
      state.getLabByIdList = action?.payload?.data
    },

    getDateSlice: (state, action) => {
      state.getDateList = action?.payload?.data
    },
    paymentDetailSlice: (state, action) => {
      state.paymentDetailList = action?.payload?.data
    },
    payDetailSlice: (state, action) => {
      state.payDetailList = action?.payload?.data
    },
    getCoursesDateSlice: (state, action) => {
      state.courseDatesList = action?.payload?.data
    },
  }
})

export const authReducer = authSlice.reducer

export const {
  payDetailSlice,
  paymentDetailSlice,
  getVouchherSlice,
  sessionBysessionSlice,
  getStuentDatesSlice,
  getTopPurchasedCoursesSlice,
  getvideoprogressslice,
  getuserdatesslice,
  getBundledetailsslice,
  getuserbyroleslice,
  getAllAvaibilityslice,
  getstudentlistslice,
  getallchaptereditslice,
  getLtiToolByIdSlice,
  getMonthlySalesSlice,
  MaxRegistrationslice,
  getedit_usersidIdslice,
  getallcoursedetailsslice,
  getBundleSalesslice,
  getmeeting_byidslice,
  getmeetingslice,
  getCountryFromISOslice,
  getInsstrructorAvaibilityReservedslice,
  getRangeslice,
  getMapStateCountryslice,
  getBundleCourseslice,
  getInsstrructorCoursesslice,
  getInsstrructorIdslice,
  getInstructorAdminListslice,
  loginslice,
  userlistslice,
  getltitoolslistslice,
  getlunchlabslice,
  getuseridslice,
  getcourseslice,
  boughtcourseslice,
  checkcourseslice,
  getcoursebyidslice,
  getBundlesByUserIdslice,
  getStudentPaymentHistoryslice,
  getpreviewdetailslice,
  getallchapterslice,
  getallLesson_idslice,
  getThisMonthTopCoursesslice,
  getchapteridslice,
  getlessonidslice,
  getavailibilityInstslice,
  getavailibilityCourseslice,
  getavailibilityslice,
  getvoucheridslice,
  getuserbyIdslice,
  getvoucheruserbyIdslice,
  getAllVoucherslice,
  getAllPaymentslice,
  getInsstrructorAvaibilityCoursesslice,
  getbundleslice,
  getbundlebyidslice,
  buycoursesandbundleslice,
  getAllStudentCountslice,
  getAllEmployessCountslice,
  getAllCorporateManagerCountslice,
  getAllCyberHeadTeamAdminCountslice,
  getAllCoursesslice,
  getPublicCoursesslice,
  getDraftCoursesslice,
  getAllBundlesslice,
  getPublishBundlesslice,
  getDraftBundlesslice,
  getTopCoursesslice,
  getRecentCoursesslice,
  getRecentSevenDaysCoursesslice,
  getRecentMonthlyCoursesslice,
  getusersByLocationslice,
  getRecentslice,
  getyesterdayRecentslice,
  getAllCountriesslice,
  getStateByCountryslice,
  getCityByStateslice,
  getAllInstructorCountslice,
  getAllContentCreatorCountslice,
  getAllUserCountslice,
  boughtbundleslice,
  getInstructorCoursesslice,
  getInstructorInvoiceCoursesslice,
  getInstructorCalanderDatesslice,
  getCalanderCourseSheduleslice,
  getallInvoiceslice,
  getAllRolesslice,
  getLastMonthTopCoursesslice,
  getLastSixMonthTopCoursesslice,
  getcheckbundleslice,
  getMeetingByIdSlice,
  getcourseAvailabilityslice,
  getAllinvoicesslice,
  getteachercourseslice,
  getavailibilityyInstslice,
  getallteachercourseslice,
  getInstructorFilterslice,
  getAllRoleslice,
  getparticipantslistslice,
  getuserSessionslice,
  getallCoursesBundlesslice,
  addCareerPath,
  getcareersslice,
  getcareerbyidslice,
  getAllPublishedCareersslice,
  getblogsbyidslice,
  getblogsbyslugslice,
  getpublishedblogsslice,
  getblogsslice,
  gettestimonialslice,
  gettestimonialbyidslice,
  getAllPublishedTestimonialslice,
  deletedUserListSlice,
  emailPreviewSlice,
  getLabsSlice,
  getPublishedLabsslice,
  getlabbyidslice,
  getDateSlice,
  getCoursesDateSlice
} = authSlice.actions
