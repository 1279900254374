import React from 'react'
import Employee from '../../../PrivateImage/img/Employee.png'
const CorporateEmployees = () => {
  return (
    <div>
      <div className='row'>
        <div className='col-md-3'>
          <div className='card' style={{backgroundColor: '#021F59', height: '350px'}}>
            <div className='card-body d-flex flex-center flex-column py-9 px-5'>
              <div className='symbol symbol-75px symbol-circle mb-5'>
                <img src={Employee} alt='image' />
              </div>

              <div className='fs-4 text-white-800 text fw-bold mb-0' style={{color: 'white'}}>
                Corporate Employees
              </div>

              <div className='fw-semibold text-white-400 mb-6' style={{color: 'white'}}>
                {' '}
                CorporateEmployees@gmail.com
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CorporateEmployees
