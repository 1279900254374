import React, {useEffect, useRef} from 'react'

import {useDispatch, useSelector} from 'react-redux'
import {GetPublishedBlogsAction} from '../../Redux/Action/blogAction'
import blog_default from '../../assets/images/blog_default.png'
import Flickity from 'react-flickity-component'
import 'flickity/css/flickity.css'
import {AiOutlineRight} from 'react-icons/ai'
import {Link} from 'react-router-dom'
const Blog = () => {
  const dispatch = useDispatch()
  const blogs = useSelector((state) => {
    return state.login.getpublishedbloglist
  })

  const allblogs = blogs.blog || []

  useEffect(() => {
    dispatch(GetPublishedBlogsAction())
  }, [dispatch])

  const flickityRef = useRef(null)

  useEffect(() => {
    flickityRef.current = new Flickity('.your-slider-container', {
      contain: true,
      wrapAround: true,
      // Add other Flickity options as needed
    })
  }, [])

  return (
    <div>
      <section className='blog'>
        <div className='container'>
          <div className='heading text-center'>
            <span>BLOG</span>
            <h2>Cyber Cell</h2>
          </div>

          <ul className='blog-grid d-flex flex-wrap'>
            {allblogs.map((item, index) => {
              if (index < 3) {
                return (
                  <li className='list-item' key={index}>
                    <Link to={`/blogdetail/${item.slug}`}>
                      <div className='thumb-wrapper'>
                        <img src={item.image_link ? item.image_link : blog_default} alt='' />
                      </div>

                      <h6>
                        {item.title}
                        <AiOutlineRight style={{color: 'rgb(255, 118, 63)'}} />
                      </h6>
                    </Link>
                  </li>
                )
              }
            })}
          </ul>
        </div>
      </section>
      <div className='responsive_blog'>
        <div className='responsive_blog_text'>
          <span
            className='blogresposnsive_title'
            style={{color: '#FF763F', fontSize: '17px', fontWeight: '500'}}
          >
            BLOG
          </span>
          <h2 className='blog_responsive_text' style={{fontSize: '30px'}}>
            Cyber Cell
          </h2>
        </div>
        <Flickity
          className={'your-slider-container'}
          options={{
            contain: true,
            wrapAround: true,
          }}
        >
          {allblogs.map((item, index) => (
            <div key={index} className='slider-item'>
              <section>
                <div className='container'>
                  <div className='thumb-wrapper'>
                    <img src={item.image_link ? item.image_link : blog_default} alt='' />
                  </div>
                  <span className='pt-20' style={{fontSize: '18px'}}>
                    {item.title}
                    <AiOutlineRight style={{color: 'rgb(255, 118, 63)'}} />
                  </span>
                </div>
              </section>
            </div>
          ))}
        </Flickity>
      </div>
    </div>
  )
}

export default Blog
