import React from 'react'
import AllSections from './AllSections'
import Section2 from './Section2'
import Section3 from './Section3'
import Section4 from './Section4'
import Section5 from './Section5'

const Microsoft = () => {
  return (
    <div>
      <AllSections />
      {/* <Section2 />

      <Section3 />

      <Section4 />

      <Section5 /> */}
    </div>
  )
}

export default Microsoft
