import React from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import Section from './Section'
import Section2 from './Section2'

// import Try from './Try'
const Careers = () => {
  return (
    <div>
      <div style={{background: 'white'}}>
        <Navbar />
      </div>
      <div style={{background: 'white'}}>
        <Section />

        <Section2 />
      </div>
      <Footer />
    </div>
  )
}

export default Careers
