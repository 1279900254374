import React, {useState, useEffect} from 'react'
import {DatePicker} from 'antd'
import {useDispatch, useSelector} from 'react-redux'
import {InstructorFilterAction} from '../../../Redux/Action/instructorAction'
import techer from '../../../dashimg/techer.jpg'
const Instructorsearch = () => {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [instructorQuery, setInstructorQuery] = useState('')
  const dispatch = useDispatch()

  var newURL = window.location
  var splitURL = newURL.href.split('/')
  const courseId = splitURL[4]

  const handleInstructorSearch = (e) => {
    setInstructorQuery(e.target.value)
  }

  let Instructorfilter = useSelector((state) => {
    return state.login.getInstructorfilterList
  })

  const handleFetchData = () => {
    dispatch(InstructorFilterAction(courseId, startDate, endDate))
  }
  let cdata = ''

  const handleStartDateChange = (date) => {
    setStartDate(date)
  }

  const handleEndDateChange = (date) => {
    setEndDate(date)
  }

  function disabledDate(current) {
    const startOfToday = new Date();
    startOfToday.setHours(0, 0, 0, 0);
    return current && current.valueOf() < startOfToday.valueOf();
  }

  return (
    <div>
      <div
        class='card col-md-4'
        style={{height: '342px', display: 'flex', justifyContent: 'center', padding: '40px'}}
      >
        <div style={{display: 'flex'}}>
          <label
            className='selectcountry'
            style={{fontSize: '15px', width: '25%', paddingTop: '10px'}}
          >
            Start Date:
          </label>
          <DatePicker
            className='form-control mb-2'
            selected={startDate}
            onChange={handleStartDateChange}
            placeholderText='Start Date'
            //minDate={new Date()}
            disabledDate={disabledDate}
          />
        </div>
        <div style={{display: 'flex'}}>
          <label
            className='selectcountry'
            style={{fontSize: '15px', width: '25%', paddingTop: '10px'}}
          >
            End Date:
          </label>
          <DatePicker
            className='form-control mb-2'
            selected={endDate}
            onChange={handleEndDateChange}
            placeholderText='End Date'
            //minDate={new Date()}
            disabledDate={disabledDate}
          />
        </div>

        <div
          className='book-view'
          style={{
            display: 'flex',
            gap: '30px',
            marginTop: '13px',
          }}
        >
          <button
            className='btn cyberbtnallpage'
            style={{
              color: 'white',

              width: '108px',
              padding: '10px',
              borderRadius: '5px',
              fontWeight: 500,
            }}
            onClick={handleFetchData}
          >
            Filter
          </button>
        </div>
      </div>

      <div className='col-md-8'>
        <ul style={{listStyle: 'none', padding: 0, display: 'flex', flexWrap: 'wrap'}}>
          {Object.entries(Instructorfilter).map(([key, course], i) => {
            if (key === 'data') {
              cdata = course
            }
          })}
          {cdata.length > 0 ? (
            cdata.map((instructor, i) => (
              <li style={{flexBasis: '30%', margin: '10px'}}>
                <div
                  class='card'
                  style={{
                    border: '1px solid #e1e3ea',
                    boxShadow: 'none',
                    height: '35vh',
                    textAlign: 'center',
                  }}
                >
                  <div class='symbol symbol-65px symbol-circle mb-10 mt-10'>
                    <img
                      src={instructor.Profile ? instructor.Profile : instructor.Profile}
                      alt='imagesdfsf'
                    />
                  </div>

                  <a href='#' class='fs-4 text-gray-800 text-hover-primary fw-bold mb-0'>
                    {instructor.name}
                  </a>

                  <div class='fw-semibold text-gray-400 mb-3'>{instructor.Email}</div>

                  <div class='fw-semibold text-gray-400'>DayRate:{instructor.dayRate}</div>
                  <div class='fw-semibold text-gray-400 pb-5'>Country: {instructor.Country}</div>

                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <button
                      className='btn cyberbtnallpage'
                      style={{
                        color: 'white',
                        textAlign: 'center',
                        width: '108px',
                        padding: '10px',
                        borderRadius: '5px',
                        fontWeight: 500,
                      }}
                      onClick={() => { window.location.href = `/user-availabilityset/${instructor.id}`; }}
                    >
                      Book
                    </button>
                  </div>
                </div>
              </li>
            ))
           ) : (

              <div className='flex-center flex-column pt-12 p-9'>
                <p>No instructors available.</p>
              </div>
          )} 
        </ul>
      </div>
    </div>
  )
}

export default Instructorsearch

// getInstructorFilterslice,getInstructorfilterList
