import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'

import './emailverfication.css'

import emailVerify from '../../../assets/images/email_verify.png'

import Navbar from '../../Navbar/Navbar'
import Footer from '../../Footer/Footer'
import {UpdatemailAction} from '../../../Redux/Action/authAction'
import {Link} from 'react-router-dom'
import {useDispatch} from 'react-redux'
const EmailVerfication = () => {
  const {token} = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(UpdatemailAction(token))
  }, [dispatch, token])


  return (
    <div>
      <Navbar />

      {/* useEffect */}

      <div className='container' style={{padding: '60px', paddingBottom: '13rem', marginTop:'13rem'}}>
        <div className='row emailstyle' style={{}}>
          <div className='emailverfication_box'>
            <img src={emailVerify} alt='img' style={{width: '30%'}} />

            <h2>Email Verified!</h2>

            <p>Your email has been verified.</p>
            <p>Now you can login in to your account.</p>

            <Link to='/login'>
              <button className='button button-rounded border-0'>Login</button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default EmailVerfication
