import React from 'react'
import Manger from "../../../PrivateImage/img/Manger.png"
const CorporateManger = () => {
  return (
    <div>
      <div className='row'>
    <div className='col-md-3'>

      <div class='card' style={{backgroundColor: '#021F59', height: '350px'}}>
        <div class='card-body d-flex flex-center flex-column py-9 px-5'>
          <div class='symbol symbol-40px symbol-circle mb-5' style={{width: "121px"}}>
            <img src={Manger} alt='image' style={{width:"100%",height:"120px"}} />
          </div>

          <div class='fs-4 text-white-800 text fw-bold mb-0' style={{color: 'white'}}>
          Corporate Manger
          </div>

          <div class='fw-semibold text-white-400 mb-6' style={{color: 'white'}}>
            {' '}
            CorporateManger@gmail.com
          </div>

         
        </div>
      </div>
      </div>
    </div>
    </div>
  )
}

export default CorporateManger
