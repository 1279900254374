import React from 'react'
import {Link} from 'react-router-dom'

const Pagenotfound = () => {
  return (
    <div
      style={{
        backgroundColor: '#181c32',
        color: 'white',
        textAlign: 'center',
        height: '100vh',
        width: '100%',
      }}
      className='sectionpage_notfound'
    >
      <div className='found_section ' style={{position: 'relative', top: '20rem'}}>
        <h2 className='oops' style={{color: 'white'}}>
          Oops! Page not found.
        </h2>
        <h1 className='pagenotfound' style={{fontSize: '186px', fontWeight: '900', color: 'white'}}>
          404
        </h1>
        <p style={{fontSize: '150px', color: 'white'}}>:(</p>
        <Link to='/'>
          <button className='btn cyberbtnallpage'>Go back home</button>{' '}
        </Link>
      </div>
    </div>
  )
}

export default Pagenotfound
