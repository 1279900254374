import React, {useEffect} from 'react'

import './previewlearningbody.css'
import Role from './AboutRole/Role'
import WhoSuited from './Whosuited/Whosuited'
import Whyconsider from './Whyconsider/Whyconsider'
import Securitypath from './Securitypath/Securitypath'

import {PreviewDetailsAction} from '../../../Redux/Action/courseAction'

import {useDispatch} from 'react-redux'

const Previewlearningbody = () => {
  var newURL = window.location

  var splitURL = newURL.href.split('/')

  const courseslug = splitURL[5]

  const dispatch = useDispatch()

  useEffect(() => {
    // debugger
    dispatch(PreviewDetailsAction(courseslug))
  }, [dispatch])

  return (
    <div className='container'>
      <div className='row'>
        <div className='row'>
          <div className='col-md-12 Previewlearning_background'>
            <div className='preview_sadow'>
              <ul
                class='nav nav-tabs'
                style={{
                  dispaly: 'flex',

                  gap: '1px',
                }}
              >
                <li class='nav-itemm'>
                  <a class='nav-link active' data-bs-toggle='tab' href='#home'>
                    <span style={{fontSize: '18px'}}>About The Role:</span>
                  </a>
                </li>
                <li class='nav-itemm'>
                  <a class='nav-link' data-bs-toggle='tab' href='#profile'>
                    <span style={{fontSize: '18px'}}>Who's it suited for?</span>
                  </a>
                </li>
                <li class='nav-itemm'>
                  <a class='nav-link' data-bs-toggle='tab' href='#contact'>
                    <span style={{fontSize: '18px'}}>Why should you Consider it ?</span>
                  </a>
                </li>
              </ul>

              <div class='tab-content' style={{paddingBottom: '5rem', background: 'white'}}>
                <div class='tab-pane active' id='home'>
                  <div class='col p-4'>
                    <Role />
                  </div>
                </div>
                <div class='tab-pane' id='profile'>
                  <div class='col p-4'>
                    <WhoSuited />
                  </div>
                </div>
                <div class='tab-pane' id='contact'>
                  <div class='col p-4'>
                    <Whyconsider />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-3 Previewlearning_background'></div>
        </div>
      </div>
      {/* ///dddddd */}

      <div className='row' style={{paddingTop: '2rem'}}>
        <div className='col-md-12 Previewlearning_background'>
          <div className='preview_sadow'>
            <ul
              class='nav nav-tabs'
              style={{
                dispaly: 'flex',

                gap: '1px',
              }}
            >
              <li class='nav-itemm'>
                <a class='nav-link active' data-bs-toggle='tab' href='#home'>
                  <span style={{fontSize: '18px'}}>Common Job Titles:</span>
                </a>
              </li>
              <li class='nav-itemm'>
                <a class='nav-link' data-bs-toggle='tab' href='#profile'>
                  <span style={{fontSize: '18px'}}>Top Certifications Requested:</span>
                </a>
              </li>
              <li class='nav-itemm'>
                <a class='nav-link' data-bs-toggle='tab' href='#contact'>
                  <span style={{fontSize: '18px'}}>Top Skills Requested:</span>
                </a>
              </li>
            </ul>

            {/* <div class='tab-content' style={{paddingBottom: '5rem', background: 'white'}}>
              <div class='tab-pane active' id='home'>
                <div class='col p-4'>
                  <Role />
                </div>
              </div>
              <div class='tab-pane' id='profile'>
                <div class='col p-4'>
                  <WhoSuited />
                </div>
              </div>
              <div class='tab-pane' id='contact'>
                <div class='col p-4'>
                  <Whyconsider />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-10'>
          <div className='row'>
            <div className='col-md-11'>
              <div className='row' style={{paddingTop: '1rem'}}>
                {/* <div className='col-md-2'>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: '#ff763f24',
                      padding: '1rem',
                    }}
                  >
                    <img src={uk} alt='img' style={{width: '100px'}} />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection:"column",
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: '#ff763f24',
                      padding: '1rem',
                      marginTop:"1rem"
                    }}
                  >
                    <span style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                      UK
                    </span>
                    <h1 style={{width: '288px', fontSize: '15px', textAlign: 'center'}}>
                      GBP: £35,000 - £60,000
                    </h1>
                  </div>
                </div>

                <div className='col-md-2'>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: '#ff763f24',
                      padding: '2rem',
                    }}
                  >
                    <img src={usa} alt='img' style={{width: '100px'}} />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection:"column",
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: '#ff763f24',
                      padding: '1rem',
                      marginTop:"1rem"
                    }}
                  >
                  <span style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    USA
                  </span>
                  <h1 style={{width: '288px', fontSize: '15px', textAlign: 'center'}}>
                    $60,000 - $110,000
                  </h1>
                  </div>
                </div>

                <div className='col-md-2'>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: '#ff763f24',
                      padding: '1rem',
                    }}
                  >
                    <img src={Uae} alt='img' style={{width: '100px'}} />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection:"column",
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: '#ff763f24',
                      padding: '1rem',
                      marginTop:"1rem"
                    }}
                  >
                  <span style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    UAE
                  </span>
                  <h1 style={{width: '288px', fontSize: '15px', textAlign: 'center'}}>
                    AED: 120,000 - AED 240,000
                  </h1>
                  </div>
                </div>

                <div className='col-md-2'>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: '#ff763f24',
                      padding: '2rem',
                    }}
                  >
                    <img src={india} alt='img' style={{width: '100px'}} />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection:"column",
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: '#ff763f24',
                      padding: '1rem',
                      marginTop:"1rem"
                    }}
                  > 
                  <span style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    INR
                  </span>

                  <h1 style={{width: '288px', fontSize: '15px', textAlign: 'center'}}>
                    ₹500,000 - ₹1,500,000
                  </h1>
                  </div>
                </div>

                <div className='col-md-2'>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: '#ff763f24',
                      padding: '2rem',
                    }}
                  >
                    <img src={Nigeria} alt='img' style={{width: '100px'}} />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection:"column",
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: '#ff763f24',
                      padding: '1rem',
                      marginTop:"1rem"
                    }}
                  >
                  <span style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    Nigeria
                  </span>

                  <h1 style={{width: '288px', fontSize: '15px', textAlign: 'center'}}>
                    ₦2,500,000 - ₦7,500,000
                  </h1>
                  </div>


                </div> */}

                {/* <div className='col-md-12'>
                  <div className='jobTile jobtext-color'>
                    {' '}
                    <span className='jobtext-color'>Typical Annual Salary*:</span>
                  </div>

                  {Object.entries(previewdetails).map(([key, course], i) => {
                    if (key == 'data') {
                      cdata = course
                    }
                  })}

                  {Object.entries(cdata).map(([key, item], i) => {
                    const truncatedDescription =
                      item.salary.length > 900 ? `${item.salary.slice(0, 900000)}` : item.salary
                    return (
                      <p
                        dangerouslySetInnerHTML={{__html: truncatedDescription}}
                        style={{
                          fontFamily: 'Sora, sans-serif',
                          fontSize: '16px',
                          paddingTop: '20px',
                        }}
                      />
                    )
                  })}
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <Securitypath />
      </div>
    </div>
  )
}

export default Previewlearningbody
