import swal from 'sweetalert'
import axios from 'axios'
import {createAsyncThunk} from '@reduxjs/toolkit'

import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  getcourseslice,
  getcoursebyidslice,
  getpreviewdetailslice,
  boughtcourseslice,
  getavailibilityCourseslice,
  buycoursesandbundleslice,
  getcourseAvailabilityslice,
  getteachercourseslice,
  getallCoursesBundlesslice,
  getStuentDatesSlice,
  sessionBysessionSlice,
  getVouchherSlice,
  getDateSlice,
  getCoursesDateSlice
} from '../Slice/authSlice'

export const GetCourseAction = (courseId) => async (dispatch) => {
  try {
    debugger
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getcoursebyid = `${process.env.REACT_APP_BACKEND_API}/getCourse/${courseId.courseId}`

    const res = await axios.get(getcoursebyid, config)

    await dispatch(getcoursebyidslice(res))
  } catch (e) {
    // console.log(e)
  }
}

export const CreateCourseAction = (formData, fields, navigate, setLoading) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken');

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    };

    const createcourse = process.env.REACT_APP_BACKEND_API + '/createCourse';

    const res = await axios.post(createcourse, formData, config);

    if (res.status === 200) {
      try {
        setLoading(true);
        const baseURL = process.env.REACT_APP_BACKEND_API;
        const url = `${baseURL}/courseMeeting`;
        const token = localStorage.getItem('authToken');

        const response = await axios.post(url, fields, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          swal({
            title: 'Success',
            text: 'Course and meeting created successfully',
            icon: 'success',
            buttons: {
              cancel: {
                text: 'OK',
                value: null,
                visible: true,
                className: 'swal-button--confirm cyberbtnallpage',
              },
            },
          });
          navigate('/Allcourse');
        }
      } catch (error) {
        console.error('Error:', error);
        swal('Error', 'Failed to create meeting', 'error');
      } finally {
        setLoading(false);
      }
    }
  } catch (e) {
    console.error('Error:', e);
    if (e?.response?.data?.message) {
      swal('Error', e.response.data.message, 'error');
    } else {
      swal('Error', 'An unexpected error occurred', 'error');
    }
    setLoading(false);
  }
};

export const CheckusercourseAction = (props, navigate, setMessage) => async (dispatch) => {
  let useridd = localStorage.getItem('userid')
  let category = localStorage.getItem('category')

  // debugger
  try {
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const checkcourses = `${process.env.REACT_APP_BACKEND_API}/checkusercourse/${useridd}/${props.coursecode}/${category}`
    const res = await axios.get(checkcourses, config)

    if (res.status === 200) {
      if (res.data.message === 1) {
        // debugger;

        if (res.data.category === 'platform') {
          navigate(`/Learning?/${props.coursid}`)
        } else if (res.data.category === 'comptia') {
          navigate(`/Compita?/${props.coursid}`)
          // navigate('/Compita')
        }
      } else {
        navigate(`/learningprev?/${props.coursecode}/${props.coursid}`)
      }
    }
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e.response.data.message, 'error')
    }
  }
}

export const GetallCourseAction = () => async (dispatch) => {
  try {
    // debugger;
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getcourses = process.env.REACT_APP_BACKEND_API + '/getallCourse'
    const res = await axios.get(getcourses, config)

    await dispatch(getcourseslice(res))
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e?.response?.data?.message, 'error')
    }
  }
}

export const GetallteacherCourseAction = () => async (dispatch) => {
  try {
    // debugger;
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getcourses = process.env.REACT_APP_BACKEND_API + '/getAllTeachercourses'
    const res = await axios.get(getcourses, config)

    await dispatch(getteachercourseslice(res))
    // console.log(res)
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e?.response?.data?.message, 'error')
    }
  }
}

export const CourseDeleteAction = (props) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const confirmResult = await swal({
      title: 'Delete Course',
      text: 'Are you sure you want to delete this Course?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          value: null,
          visible: true,
          className: 'swal-button--cancel cybercancelbtn',
        },
        confirm: {
          text: 'Delete',
          value: true,
          visible: true,
          className: 'swal-button--confirm swal-button--danger cyberbtnallpage',
        },
      },
      dangerMode: true,
    })
    if (confirmResult) {
      const deletecourse = `${process.env.REACT_APP_BACKEND_API}/deleteCourse/${props}`
      const res = await axios.delete(deletecourse, config)

      if (res.status === 200) {
        swal({
          title: 'Success',
          text: 'Course Deleted',
          icon: 'success',
          buttons: {
            cancel: {
              text: 'OK',
              value: null,
              visible: true,
              className: 'swal-button--confirm cyberbtnallpage',
            },
          },
        }).then(() => {
          window.location.reload()
        })
      }
    }
  } catch (e) {
    // console.log(e)
  }
}

export const UpdateCourseAction =
  (formData, courseId, navigate, setLoading) => async (dispatch) => {
    try {
      setLoading(true)
      const token = localStorage.getItem('authToken')
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }

      const updateCourseid = `${process.env.REACT_APP_BACKEND_API}/updateCourse/${courseId.courseId}`
      const res = await axios.put(updateCourseid, formData, config)

      if (res.status === 200) {
        swal({
          title: 'Success',
          text: 'Course Updated',
          icon: 'success',
          buttons: {
            cancel: {
              text: 'OK',
              value: null,
              visible: true,
              className: 'swal-button--confirm cyberbtnallpage',
            },
          },
        })
        navigate('/Allcourse')
      }
      dispatch()
    } catch (e) {
      if (e?.response?.data.message) {
        swal('Error', e.response.data.message, 'error')
      } else {
      }
    } finally {
      setLoading(false)
    }
  }

export const PreviewDetailsAction = (courseslug) => async (dispatch) => {
  debugger
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getpreviewdetailsyid = `${process.env.REACT_APP_BACKEND_API}/getCourse/${courseslug}`

    const res = await axios.get(getpreviewdetailsyid, config)

    await dispatch(getpreviewdetailslice(res))
  } catch (e) {
    // console.log(e)
  }
}

export const InsidebuycourseAction = (formData) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const insidecourse = process.env.REACT_APP_BACKEND_API + '/insidebuycourse'

    const res = await axios.post(insidecourse, formData, config)

    if (res.status === 200) {
      if (res.data.paymethod === '1') {
        window.open(`${res.data.paymentLink}`, '_self')
      } else {
        if (res.data.paymethod === '2') {
          window.location = res.data.redirect_url
        }
      }
    }
  } catch (e) {
    // console.log(e)
  }
}

export const BoughtcourseAction = (studentid) => async (dispatch) => {
  //debugger
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getuserid = `${process.env.REACT_APP_BACKEND_API}/boughtcourse/${studentid}`

    const res = await axios.get(getuserid, config)

    await dispatch(boughtcourseslice(res))
  } catch (e) {
    // console.log(e)
  }
}

export const UpdateAvailabilityStatus =
  (courseId, inst, item, state, navigate) => async (dispatch) => {
    try {
      const token = localStorage.getItem('authToken')

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }

      const requestBody = {state}

      const updateavailability = `${process.env.REACT_APP_BACKEND_API}/updateAvaibalitybyCourseid/${courseId}/${inst}`
      const res = await axios.put(updateavailability, requestBody, config)

      if (res.status == 200) {
        window.location.reload()
      }
    } catch (e) {
      // console.log(e, 'eeror in api')
    }
  }

export const GetAvaibilityByCourseId = (courseId) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getcourseid = `${process.env.REACT_APP_BACKEND_API}/checkAvaibalitybyCourseid/${courseId}`

    const res = await axios.get(getcourseid, config)

    await dispatch(getavailibilityCourseslice(res))
  } catch (e) {
    // console.log(e)
  }
}

export const SetAvailibilityAction = (formData, navigate) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const setavaibility = process.env.REACT_APP_BACKEND_API + '/available'

    const res = await axios.post(setavaibility, formData, config)

    if (res.status === 200) {
      window.location = '/my-availability'
    }
    dispatch(res())
  } catch (e) {
    // console.log(e)
  }
}

export const BuycoursesandbundlesAction = (studentid) => async (dispatch) => {
  try {
    // debugger
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getuserid = `${process.env.REACT_APP_BACKEND_API}/buycoursesandbundles/${studentid}`

    const res = await axios.get(getuserid, config, '')

    await dispatch(buycoursesandbundleslice(res))
  } catch (e) {
    console.log(e)
  }
}

export const GetCourseAvailabilityAction = (CoursesId) => async (dispatch) => {
  try {
    // debugger
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getcoursebyid = `${process.env.REACT_APP_BACKEND_API}/getCourseAvailability/${CoursesId}`

    const res = await axios.get(getcoursebyid, config)

    await dispatch(getcourseAvailabilityslice(res))
  } catch (e) {
    console.log(e)
  }
}

export const GetallCoursesBundlesAction = () => async (dispatch) => {
  try {
    // debugger;
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const allCoursesBundles = process.env.REACT_APP_BACKEND_API + '/allCoursesBundles'
    const res = await axios.get(allCoursesBundles, config)

    await dispatch(getallCoursesBundlesslice(res))
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e?.response?.data?.message, 'error')
    }
  }
}

export const GetStudentDates = (id) => async (dispatch) => {
  try {
    // debugger;
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const studentDates = process.env.REACT_APP_BACKEND_API + `/getstudentDates/${id}`
    const res = await axios.get(studentDates, config)

    await dispatch(getStuentDatesSlice(res))
  } catch (e) {
    console.log(e, 'Error')
  }
}

export const updateStudentDates = (id, formData, setLoading) => async (dispatch) => {
  try {
    //debugger
    setLoading(true)
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const updateStudentDates = process.env.REACT_APP_BACKEND_API + `/updatestudentDates/${id}`
    const res = await axios.put(updateStudentDates, formData, config)

    if (res.status == 200) {
      swal({
        title: 'Success',
        text: 'Dates Updated',
        icon: 'success',
        buttons: {
          cancel: {
            text: 'OK',
            value: null,
            visible: true,
            className: 'swal-button--confirm cyberbtnallpage',
          },
        },
      }).then(() => {
        window.location.reload()
      })
    }
    dispatch()
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e?.response?.data?.message, 'error')
    }
  } finally {
    setLoading(false)
  }
}

export const removeStudentCourse = (instid, courseId, type) => async (dispatch) => {
  try {
    debugger
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const removeStudentCourse =
      process.env.REACT_APP_BACKEND_API + `/removeStudentCourse/${instid}/${courseId}/${type}`
    const res = await axios.delete(removeStudentCourse, config)

    if (res.status == 200) {
      swal({
        title: 'Success',
        text: 'Course Removed',
        icon: 'success',
        buttons: {
          cancel: {
            text: 'OK',
            value: null,
            visible: true,
            className: 'swal-button--confirm cyberbtnallpage',
          },
        },
      }).then(() => {
        window.location.reload()
      })
    }
  } catch (e) {
    console.log(e, 'Error')
  }
}

export const removeStudentSession = (sessionID) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const removeStudentSession =
      process.env.REACT_APP_BACKEND_API + `/removeStudentSession/${sessionID}`
    const res = await axios.delete(removeStudentSession, config)

    if (res.status == 200) {
      swal({
        title: 'Success',
        text: 'Session Removed',
        icon: 'success',
        buttons: {
          cancel: {
            text: 'OK',
            value: null,
            visible: true,
            className: 'swal-button--confirm cyberbtnallpage',
          },
        },
      }).then(() => {
        window.location.reload()
      })
    }
  } catch (e) {
    console.log(e, 'Error')
  }
}

export const deleteStudentVoucher = (voucherID) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const deleteStudentVoucher =
      process.env.REACT_APP_BACKEND_API + `/deleteStudentVoucher/${voucherID}`
    const res = await axios.delete(deleteStudentVoucher, config)

    if (res.status == 200) {
      swal({
        title: 'Success',
        text: 'Voucher Removed',
        icon: 'success',
        buttons: {
          cancel: {
            text: 'OK',
            value: null,
            visible: true,
            className: 'swal-button--confirm cyberbtnallpage',
          },
        },
      }).then(() => {
        window.location.reload()
      })
    }
  } catch (e) {
    console.log(e, 'Error')
  }
}


export const GetSessionbySessionId = (id) => async (dispatch) => {
  try {
    // debugger;
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const session = process.env.REACT_APP_BACKEND_API + `/singleSession/${id}`
    const res = await axios.get(session, config)

    await dispatch(sessionBysessionSlice(res))
  } catch (e) {
    console.log(e, 'Error')
  }
}

export const GetVoucherById = (id) => async (dispatch) => {
  try {
    // debugger;
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const voucher = process.env.REACT_APP_BACKEND_API + `/voucher/${id}`
    const res = await axios.get(voucher, config)

    await dispatch(getVouchherSlice(res))
  } catch (e) {
    console.log(e, 'Error')
  }
}




export const GetdateById = (courseid, studentid, bundleid) => async (dispatch) => {
  try {
    // debugger;
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const voucher = process.env.REACT_APP_BACKEND_API + `/dates/${courseid}/${studentid}/${bundleid}`
    const res = await axios.get(voucher, config)

    await dispatch(getDateSlice(res))
  } catch (e) {
    console.log(e, 'Error')
  }
}


export const GetallCourseDatesAction = () => async (dispatch) => {
  try {
    // debugger;
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const coursesDates = process.env.REACT_APP_BACKEND_API + `/coursesdates`
    const res = await axios.get(coursesDates, config)

    await dispatch(getCoursesDateSlice(res))
  } catch (e) {
    console.log(e, 'Error')
  }
}