import React from 'react'

const Progress_bar = ({bgcolor, progress, height}) => {
  const Parentdiv = {
    height: height,
    width: '80%',
    backgroundColor: 'whitesmoke',
    borderRadius: 40,
    marginTop: 30,
  }

  const Childdiv = {
    height: '110%',
    width: `${progress}%`,
    backgroundColor: bgcolor,
    borderRadius: 40,
    textAlign: 'right',
  }

  const progresstext = {
    padding: 10,

    color: 'black',

    // fontWeight: 900
  }

  return (
    <div style={Parentdiv}>
      <div style={Childdiv}>
        <span style={progresstext} className='prsazdczscfogresssss' >{`${progress}%`} complete</span>
      </div>
    </div>
  )
}

export default Progress_bar
