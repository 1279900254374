import {loadStripe} from '@stripe/stripe-js'
import {Elements} from '@stripe/react-stripe-js'

import {CheckoutForm} from './CheckoutForm'

const key = "pk_live_51Q08jBP4cuEWV1gxf7VZ3xWVIUVVsFdJ8IuLNyUrjx65sLYDdHi3YDxPiYCl4U9LXDZmndOCntyE4vehMi4bWMiw005OBE2NBo"

const stripeTestPromise = loadStripe(key)

const Stripe = ({setCardComplete}) => {
  return (
    <Elements stripe={stripeTestPromise}>
      <CheckoutForm setCardComplete={setCardComplete} />
    </Elements>
  )
}

export default Stripe
