import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'
import './activity.css'
import admin from '../../../../../assets/images/default.png'

import logo from '../../../../../assets/images/logo.png'
import {useDispatch, useSelector} from 'react-redux'
// import { BoughtcourseAction } from "../../../../../../src/Redux//Action/authAction";
import {BoughtcourseAction} from '../../../../../Redux/Action/courseAction'

import {BoughtBundleByUserAction} from '../../../../../Redux/Action/bundleAction'
// import stddash from "../../PrivateImage/bundleimg/stddash.png"
import stddash from '../../../../../PrivateImage/bundleimg/stddash.png'
import {GetUserById} from '../../../../../Redux/Action/profileAction'

const MyActivity = () => {

  const dispatch = useDispatch()
  // debugger
  const studentid = localStorage.getItem('userid')

  const mycoures = useSelector((state) => {
    return state.login.boughtcourseslist
  })

  useEffect(() => {
    dispatch(BoughtcourseAction(studentid))
  }, [dispatch])

  const myBundles = useSelector((state) => {
    return state.login.getBundleUserIdList
  })

  useEffect(() => {
    dispatch(BoughtBundleByUserAction(studentid))
  }, [dispatch])

  const userdetails = useSelector((state) => {
    return state.login.getuserIdlist
  })

  useEffect(() => {
    dispatch(GetUserById(studentid))
  }, [dispatch, studentid])

  const forceReset = localStorage.getItem('forceReset')
  const forceResetOrg = localStorage.getItem('forceResetOrg')
  let welcomeMsg = 'Welcome Back!'
  if(parseInt(forceResetOrg) === 1 ) {
    welcomeMsg = 'Welcome to Cyberheads!'
  }
 

  return (
    <div className='row'>
      <div className='myactivity'>
        <div class='card card-flush h-xl-100 shadow-none'>
          <div
            class='rounded'
            style={{
              backgroundColor: ' #264aca',
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
            }}
            data-bs-theme='light'
          >
            <div style={{padding: '15px'}}>
              {userdetails.message?.profileUrl ? (
                <img
                  src={
                    userdetails.message &&
                    (userdetails.message.profileUrl
                      ? userdetails.message.profileUrl
                      : userdetails.message.profilePic)
                  }
                  alt='img'
                  style={{borderRadius: '50%', width: '75px', border: '3px solid #fff'}}
                />
              ) : (
                <img
                  src={admin}
                  alt='Default Profile'
                  style={{borderRadius: '50%', width: '75px', border: '3px solid #fff'}}
                />
              )}
            </div>
            <div>
              <h3 class='card-title text-white'>
                <span class='fw-bold mb-3'>
                  Hello, {userdetails?.message?.FirstName} {userdetails?.message?.Surname}
                </span>
              </h3>
              <h6>
                <span class='card-subtitle mb-2 text-white'>
                  {welcomeMsg}
                  
                </span>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MyActivity
