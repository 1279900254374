import React, {useState, useEffect, useRef} from 'react'
import 'primereact/resources/themes/lara-light-indigo/theme.css' // theme

import 'primeicons/primeicons.css'
import 'primereact/resources/primereact.css'
import {useDispatch, useSelector} from 'react-redux'

import {UserListAction, DeleteUserAction} from '../../../../Redux/Action/authAction'
import {GetallPaymentAction} from '../../../../Redux/Action/paymentAction'
import {FilterMatchMode} from 'primereact/api'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {InputText} from 'primereact/inputtext'
import {Link, useNavigate} from 'react-router-dom'
import * as CountryStateCity from 'country-state-city'
import {MultiSelect} from 'primereact/multiselect'
import {FaEdit, FaTrash} from 'react-icons/fa'
import './section.css'

import {Tooltip} from 'antd'
import ExcelJS from 'exceljs'

import {Button} from 'primereact/button'
import {Toolbar} from 'primereact/toolbar'

export default function AlluserList() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  
  const dt = useRef(null)
  const multiSelectRef = useRef(null);
  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({selectionOnly})
  }

  const resetFilters = () => {
    if (dt.current) {
      dt.current.reset();
      if (multiSelectRef.current) {
        multiSelectRef.current.value = null;
        setSelectedCountries([])
        setSelectedStates([])
      }
    }
	}
  const exportExcel = (selectionOnly) => {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('User Data')

    // Define columns for the Excel sheet
    const columns = [
      {header: 'User Name', key: 'UserName'},
      {header: 'Email', key: 'Email'},
      {header: 'Payment ID', key: 'paymentid'},
      {header: 'payment Time', key: 'paymenttime'},
      {header: 'Country', key: 'Country'},
      {header: 'State/County', key: 'state'},
      {header: 'City', key: 'City'},
    ]

    const exportColumns = visibleColumns.map((col) => ({ header: col.header, key: col.field }));

    // Add the columns to the worksheet
    worksheet.columns = exportColumns

    // Export selected data or all data based on 'selectionOnly' flag
    const dataToExport = selectionOnly ? allpayments : allpayments

    // Add data to the worksheet
    dataToExport.forEach((item) => {
      worksheet.addRow(item)
    })

    // Generate a unique filename
    const fileName = `user_data_${new Date().getTime()}.xlsx`

    // Create a blob from the workbook and save it as a file
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = fileName
      a.click()
      window.URL.revokeObjectURL(url)
    })
  }

  const [filters, setFilters] = useState({
    global: {value: null, matchMode: FilterMatchMode.CONTAINS},
    UserName: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
    Email: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
    paymentid: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
    paymenttime: {value: null, matchMode: FilterMatchMode.STARTS_WITH},

    AgeBracket: {value: null, matchMode: FilterMatchMode.IN},
    amountpaid: {value: null, matchMode: FilterMatchMode.IN},
    courseTitle: {value: null, matchMode: FilterMatchMode.IN},
    stateName: {value: null, matchMode: FilterMatchMode.IN},
    CityName: {value: null, matchMode: FilterMatchMode.IN},
    CountryName: {value: null, matchMode: FilterMatchMode.IN},
  })

  const columns = [
    {field: 'UserName', header: 'User Name'},
    {field: 'Email', header: 'Email'},
    {field: 'paymentid', header: 'Payment ID'},
    {field: 'paymenttime', header: 'Purchase Date'},
    {field: 'Country', header: 'Country'},
    {field: 'state', header: 'State/County'},
    {field: 'City', header: 'City'},
  ]
  const allColumns = [
    {field: 'UserName', header: 'User Name'},
    {field: 'Email', header: 'Email'},
    {field: 'paymentid', header: 'Payment ID'},
    {field: 'paymenttime', header: 'Purchase Date'},
    {field: 'AgeBracket', header: 'Age Group'},
    {field: 'amountpaid', header: 'Amount Paid'},
    {field: 'courseTitle', header: 'Course Name'},
    {field: 'Country', header: 'Country'},
    {field: 'state', header: 'State/County'},
    {field: 'City', header: 'City'},
  ]
  const onColumnToggle = (event) => {
    let selectedColumns = event.value
    let orderedSelectedColumns = allColumns.filter((col) =>
      selectedColumns.some((sCol) => sCol.field === col.field)
    )

    setVisibleColumns(orderedSelectedColumns)
  }

  const [loading, setLoading] = useState(true)
  const [globalFilterValue, setGlobalFilterValue] = useState('')
  const [visibleColumns, setVisibleColumns] = useState(columns)
  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    let _filters = {...filters}
    _filters['global'].value = value
    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const renderHeader = () => {
    return (
      <div className='flex justify-content-end'>
        <span className='p-input-icon-left'>
          <i className='pi pi-search' style={{marginTop: '-5px'}}/>
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder='Keyword Search'
          />
        </span>
        <div className='resfilter_btn'>
        <button class="btn btn-md btn-secondary mx-2 filter_btn" label="Clear Filters" onClick={resetFilters}>Reset Filters</button></div>
      </div>
    )
  }

  const header = renderHeader()

  const allpayments = useSelector((state) => {
    return state.login.getAllPaymentList.map((item, index) => {
      const fullName =
        item.FirstName && item.Surname ? item.FirstName + ' ' + item.Surname : 'User Deleted'
      return {
        ...item,
        SNo: index + 1,
        UserName: fullName,
      }
    })
  })

  useEffect(() => {
    dispatch(GetallPaymentAction()).then(() => {
      setLoading(false)
    })
  }, [dispatch])

  const [countryNames, setCountryNames] = useState({})
  const [stateNames, setStateNames] = useState({})
  const [cityNames, setCityNames] = useState({})
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);

  const countriesArray = Object.keys(countryNames).map((countrydata) => ({
    name: countrydata,
  }))

  const stateArray = Object.keys(stateNames).map((state) => ({
    name: state,
  }))

  const cityArray = Object.keys(cityNames).map((city) => ({
    name: city,
  }))

  const handleCountryChange = (selectedCountry) => {
    setSelectedCountries(selectedCountry);
    const fetchedStateNames = {}
    Object.values(selectedCountry).forEach((country) => {
      const countryCode = countryNames[country.name]
      const states = CountryStateCity.State.getStatesOfCountry(countryCode)

      Object.values(states).forEach((statesData) => {
        if (!fetchedStateNames[statesData.name]) {
          fetchedStateNames[statesData.name] = {}
        }

        fetchedStateNames[statesData.name]['isoCode'] = statesData.isoCode
        fetchedStateNames[statesData.name]['countryCode'] = statesData.countryCode
      })
      setStateNames(fetchedStateNames)
    })
  }
  
  

  const handleSatateChange = (selectedState) => {
    setSelectedStates(selectedState);
    const fetchedCityNames = {}
    Object.values(selectedState).forEach((state) => {
      const stateCode = stateNames[state.name]['isoCode']
      const countryCode = stateNames[state.name]['countryCode']
      const cities = CountryStateCity.City.getCitiesOfState(countryCode, stateCode)

      Object.values(cities).forEach((cityData) => {
        fetchedCityNames[cityData.name] = cityData.isoCode
      })
      setCityNames(fetchedCityNames)
    })
  }

  useEffect(() => {
    const fetchCountryAndStateNames = () => {

      const uniqueCountryCodes = CountryStateCity.Country.getAllCountries()

      const fetchedCountryNames = {}

      Object.values(uniqueCountryCodes).forEach((countrydata) => {
        fetchedCountryNames[countrydata.name] = countrydata.isoCode
      })

      setCountryNames(fetchedCountryNames)
    }
    fetchCountryAndStateNames()
  }, [])

  const deleteHandler = (props) => {
    dispatch(DeleteUserAction(props))
  }

  const AgeFilter = (props) => {
    const options = ['18-25', '26-30', '31-35', '36-40', '40+']

    return (
      <MultiSelect
        value={props.value}
        options={options}
        onChange={(e) => {
          props.filterApplyCallback(e.value)
        }}
        optionLabel=''
        placeholder='Any'
        className='p-column-filter'
        maxSelectedLabels={1}
        style={{minWidth: '10rem'}}
      />
    )
  }

  const countryFilter = (options) => {
   
    return (
      <MultiSelect
        value={selectedCountries}
        ref={multiSelectRef}
        options={countriesArray}
        onChange={(e) => {
          options.filterApplyCallback(e.value)
          handleCountryChange(e.value)
        }}
        optionLabel='name'
        placeholder='Any'
        className='p-column-filter'
        maxSelectedLabels={1}
        style={{minWidth: '10rem'}}
        filter={true}
      />
    )
  }

  const stateFilter = (options) => {
    return (
      <MultiSelect
        value={selectedStates}
        //options={stateArray}
        options={selectedCountries.length === 1 ? stateArray : []}
        onChange={(e) => {
          options.filterApplyCallback(e.value)
          handleSatateChange(e.value)
        }}
        optionLabel='name'
        placeholder='Any'
        className='p-column-filter'
        maxSelectedLabels={1}
        style={{minWidth: '10rem'}}
      />
    )
  }

  const cityFilter = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={cityArray}
        onChange={(e) => {
          options.filterApplyCallback(e.value)
        }}
        optionLabel='name'
        placeholder='Any'
        className='p-column-filter'
        maxSelectedLabels={1}
        style={{minWidth: '10rem'}}
      />
    )
  }

  const rightToolbarTemplate = () => {
    return (
      <>
        <Button
          label='CSV'
          icon='pi pi-upload'
          className='p-button-secondary cyberbtnallpage csv_btn'
          onClick={() => exportCSV(false)}
        />
        <div style={{margin: '0 10px'}}></div>
        <Button
          label='Excel'
          icon='pi pi-upload'
          className='p-button-secondary cyberbtnallpage csv_btn'
          onClick={() => exportExcel(false)}
        />
      </>
    )
  }
  const leftToolbarTemplate = () => {
    return (
      <MultiSelect
        value={visibleColumns}
        options={allColumns}
        optionLabel='header'
        onChange={onColumnToggle}
        className='w-full sm:w-20rem'
        display='chip'
      />
    )
  }

  const actionTemplate = (rowData) => {
    return (
      <div style={{display: 'flex', gap: '0px', marginLeft: '-2rem'}}>
        <Tooltip title='Delete'>
          <button
            onClick={() => deleteHandler(rowData.id)}
            style={{border: '0', padding: '5px', background: 'white'}}
          >
            <span style={{fontSize: '18px', color: 'rgb(126,130,153)'}}>
              <FaTrash />
            </span>
          </button>
        </Tooltip>

        {/* Rest of the code */}
      </div>
    )
  }

  let serialNumber = 0
  return (
    <div>
      <Toolbar className='mb-4' left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
      <DataTable
        ref={dt}
        value={allpayments}
        paginator
        rows={10}
        dataKey='payID'
        filters={filters}
        filterDisplay='row'
        className=''
        tableStyle={{minWidth: '100%'}}
        globalFilterFields={[
          'SNo',
          'UserName',
          'Email',
          'paymentid',
          'paymenttime',
          'AgeBracket',
          'amountpaid',
          'courseTitle',
          'Role',
          'state',
          'City',
          'Country',
        ]}
        header={header}
        emptyMessage='No Record found.'
        rowClassName='table-row'
      >
        <Column sortable field='SNo' header='#' />

        {visibleColumns.map((col) => (
          <Column
            key={col.field}
            sortable
            field={col.field}
            header={col.header}
            filter
            style={{minWidth: '15rem'}}
            filterField={(() => {
              switch (col.field) {
                case 'Country':
                  return 'CountryName'
                case 'state':
                  return 'stateName'
                case 'City':
                  return 'CityName'

                default:
                  return ''
              }
            })()}
            filterPlaceholder={`Search by ${col.header}`}
            filterElement={(() => {
              switch (col.field) {
                case 'Country':
                  return countryFilter
                case 'state':
                  return stateFilter
                case 'City':
                  return cityFilter
                case 'AgeBracket':
                  return AgeFilter

                default:
                  return ''
              }
            })()}
          />
        ))}
      </DataTable>
    </div>
  )
}
