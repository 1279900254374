import React from 'react'
import Navbar from './Navbar/Navbar'
import Footer from './Footer/Footer'
import Login from './Login/Login'

const Loginpage = () => {
  return (
    <div>
      <Navbar/>
      <Login/>
      <Footer/>
    </div>
  )
}

export default Loginpage
