import React, {useState, useRef, useMemo, useEffect} from 'react'
import JoditEditor from 'jodit-react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {GetBlogAction, EditBlogAction} from '../../../Redux/Action/blogAction'
import {useForm, Controller} from 'react-hook-form'
import Loader from '../../../components/Loader/loader'

const EditBlog = () => {
  const params = useParams()
  const blogID = params.id

  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState(null)
  const [short_description, Setshort_description] = useState('')
  const [long_description, Setlong_description] = useState('')

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: {errors},
  } = useForm()

  const onSubmit = (data) => {
    //debugger
    const formData = new FormData()
    setLoading(true)
    if (data.poster_file[0]) {
      formData.append('image_link', data.poster_file[0])
    }
    formData.append('title', data.title)
    formData.append('short_description', data.short_description)
    formData.append('long_description', data.long_description)
    formData.append('status', data.status)

    dispatch(EditBlogAction(formData, blogID, navigate, setLoading))
  }

  useEffect(() => {
    dispatch(GetBlogAction(blogID))
  }, [dispatch])

  const blogDetail = useSelector((state) => {
    return state.login.getblogbyidlist
  })
  const allblogs = blogDetail.blog || []

  useEffect(() => {
    if (blogDetail?.blog) {
      const data = allblogs
      setValue('image_link', data.image_link)
      setValue('title', data.title)
      setValue('status', data.status)

      setValue('short_description', data.short_description)
      setValue('long_description', data.long_description)
    }
  }, [allblogs])

  return (
    <div>
      <div id='kt_app_content' class='app-content flex-column-fluid'>
        {loading && (
          <div className='loader-container'>
            <Loader />
          </div>
        )}
        <form
          id='kt_ecommerce_add_product_form'
          class='form d-flex flex-column flex-lg-row'
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className='course_category col-md-4'>
            <div class='d-flex flex-column gap-7 gap-lg-10 w-100 mb-7 me-lg-10'>
              <div className='card card-flush py-4'>
                <div className='card-header'>
                  <div className='card-title'>
                    <h2>Blog Image</h2>
                  </div>
                </div>

                <div class='card-body'>
                  <div className='d-flex flex-column align-items-center'>
                    {blogDetail?.blog ? (
                      <div>
                        <img
                          src={blogDetail.blog.image_link}
                          alt='Current Profile'
                          style={{maxWidth: '300px'}}
                          onError={(e) => {
                            console.error('Error loading image:', e)
                          }}
                        />
                      </div>
                    ) : (
                      <p>No image available</p>
                    )}

                    <input
                      type='file'
                      className='form-control'
                      {...register('poster_file')}
                      onChange={(e) => {
                        const selectedFile = e.target.files[0]
                        setFile(URL.createObjectURL(selectedFile))
                      }}
                    />

                    {file && <img src={file} alt='Selected' style={{maxWidth: '300px'}} />}
                    <div className='form-text'>Allowed file types: png, jpg, jpeg.</div>
                    {errors.poster_file && (
                      <p className='alert_danger' role='alert'>{errors.poster_file.message}</p>
                    )}
                  </div>
                </div>

                <div className='card-body pt-0'></div>
              </div>
              <div class='card card-flush py-4'>
                <div class='card-header'>
                  <div class='card-title'>
                    <h2>Status</h2>
                  </div>
                </div>

                <div class='card-body pt-0'>
                  <select
                    class='form-select mb-2'
                    data-control='select2'
                    data-hide-search='true'
                    data-placeholder='Select an option'
                    {...register('status', {
                      required: true,
                    })}
                  >
                    aria-invalid={errors.status ? 'true' : 'false'}
                    <option value='Published'>Published</option>
                    <option value='Draft' selected='selected' default>
                      Draft
                    </option>
                  </select>
                  {errors.status?.status === 'required' && (
                    <p className='alert_danger' role='alert'>
                      status is required
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-8'>
            <div class='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
              <div class='tab-content'>
                <div
                  class='tab-pane fade show active'
                  id='kt_ecommerce_add_product_general'
                  role='tab-panel'
                >
                  <div class='d-flex flex-column gap-7 gap-lg-10'>
                    <div class='card card-flush py-4'>
                      <div class='card-body pt-0'>
                        <div class='mb-10 fv-row'>
                          <label class='required form-label'>Title</label>

                          <input
                            type='text'
                            name='title'
                            class='form-control mb-2'
                            placeholder='Title'
                            aria-invalid={errors.title ? 'true' : 'false'}
                            {...register('title', {required: true})}
                          />
                          {errors.title?.type === 'required' && (
                            <p className='alert_danger' role='alert'>
                              This Field is required
                            </p>
                          )}
                        </div>

                        {/* <div class='mb-10 fv-row'>
                          <label class='required form-label'>Slug</label>

                          <input
                            type='text'
                            name='slug'
                            class='form-control mb-2'
                            placeholder='Slug'
                            aria-invalid={errors.slug ? 'true' : 'false'}
                            {...register('slug', {required: true})}
                          />
                          {errors.slug?.type === 'required' && (
                            <p className='alert_danger' role='alert'>
                              This Field is required
                            </p>
                          )}
                        </div> */}

                        <div>
                          <label class='required form-label'>Short Description:</label>

                          <Controller
                            control={control}
                            name='short_description'
                            defaultValue=''
                            rules={{required: true}}
                            render={({field}) => {
                              return (
                                <JoditEditor
                                  ref={field.ref}
                                  value={field.value}
                                  placeholder='start typing'
                                  aria-invalid={errors.short_description ? 'true' : 'false'}
                                  tabIndex={1} // tabIndex of textarea
                                  
                                  onBlur={(newContent) => {
                                    if(newContent === '<p><br></p>')
                                    newContent = ''
                                    Setshort_description(newContent);

                                    field.onChange(newContent)
                                  }}
                                  onChange={(newContent) => {
                                  }}
                                />
                              )
                            }}
                          />
                          {errors.short_description?.type === 'required' && (
                            <p className='alert_danger' role='alert'>
                              This Field is required
                            </p>
                          )}
                          
                        </div>
                        <div>
                          <label class='required form-label'>Long Description:</label>

                          <Controller
                            control={control}
                            name='long_description'
                            defaultValue=''
                            rules={{required: true}}
                            render={({field}) => {
                              return (
                                <JoditEditor
                                  ref={field.ref}
                                  value={field.value}
                                  placeholder='start typing'
                                  aria-invalid={errors.long_description ? 'true' : 'false'}
                                  tabIndex={1} // tabIndex of textarea
                                  
                                  onBlur={(newContent) => {
                                    if(newContent === '<p><br></p>')
                                    newContent = ''
                                    Setlong_description(newContent);

                                    field.onChange(newContent)
                                  }}
                                  onChange={(newContent) => {
                                  }}
                                />
                              )
                            }}
                          />
                          {errors.long_description?.type === 'required' && (
                            <p className='alert_danger' role='alert'>
                              This Field is required
                            </p>
                          )}
                         
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class='d-flex justify-content-end'>
                <Link
                  to='/all_blog'
                  id='kt_ecommerce_add_product_cancel'
                  class='btn cybercancelbtn me-5'
                >
                  Cancel
                </Link>

                <button
                  type='submit'
                  id='kt_ecommerce_add_product_submit'
                  className='btn cyberbtnallpage'
                >
                  <span class='indicator-label'>Save Changes</span>
                  <span class='indicator-progress'>
                    Please wait...
                    <span class='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default EditBlog
