import courseimg from '../../../../../../src/assets/images/courseimg.png'
import challageimg from '../../../../../../src/assets/images/challenge_labs.jpg'
import mentroing1 from '../../../../../../src/assets/images/Mentoring1.png'
import mentroing2 from '../../../../../../src/assets/images/Mentoring2.png'
import voucher from '../../../../../assets/images/voucher.jpg'
import comptia_learn from '../../../../../../src/assets/images/comptia_learn.png.jpg'
import ms_learn from '../../../../../../src/assets/images/microsoft_learns.jpg'
import AssignmentIcon from '@mui/icons-material/Assignment'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import React, {useEffect, useState, useRef} from 'react'
import dummy from '../../../../../assets/images/dummy.pdf'
import {Document, Page, pdfjs} from 'react-pdf'

import {Link, useNavigate} from 'react-router-dom'

import {CheckusercourseAction} from '../../../../../Redux/Action/courseAction'
import {BoughtBundleAction, GetUserSessionction} from '../../../../../Redux/Action/bundleAction'
import {uploadCVaction} from '../../../../../Redux/Action/authAction'
import {Modal} from 'antd'
import {useDispatch, useSelector} from 'react-redux'
import {BsQuestionSquare} from 'react-icons/bs'
import {Tooltip} from 'antd'
import {MdEventRepeat, MdLaunch, MdOutlineArrowForward} from 'react-icons/md'
import {ExamVouchers, ExamVouchersPut} from '../../../../../Redux/Action/voucherAction'
import {TbNotes} from 'react-icons/tb'
import moment from 'moment-timezone';
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {DeletedCVAction} from '../../../../../Redux/Action/usersAction'
import {TbEye} from 'react-icons/tb'
import {FaTrash} from 'react-icons/fa'
const Section2 = () => {
  const dispatch = useDispatch()
  const [message, setMessage] = useState('')
  const [isClicked, setIsClicked] = useState([])
  const [cvVisible, setCvVisible] = useState(false)
  const studentid = localStorage.getItem('userid')
  const fileInputRef = useRef(null)
  const myvouchers = useSelector((state) => {
    return state.login.getvoucherbyidlist
  })

  useEffect(() => {
    dispatch(ExamVouchers(studentid))
  }, [dispatch])

  const mybundle = useSelector((state) => {
    return state.login.getboughtbundlelist
  })

  useEffect(() => {
    dispatch(BoughtBundleAction(studentid))
  }, [dispatch])

  let cdata = ''

  useEffect(() => {
    dispatch(CheckusercourseAction())
  }, [dispatch])

  const learningprev = (props) => {
    dispatch(CheckusercourseAction(props, navigate, setMessage))
  }

  const onSubmit = (item) => {
    //debugger
    const course = item.bundle_code

    dispatch(ExamVouchersPut(studentid, course))
  }
  const handleClick = (item) => {
    let clicked = isClicked.slice()

    clicked[item.id] = true
    setIsClicked(clicked)
    const course = item.bundle_code
  }
  const Comptia = (props) => {
    dispatch(CheckusercourseAction(props, navigate, setMessage))
  }
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)

  const showModal = () => {
    setOpen(true)
  }

  const hideModal = () => {
    setOpen(false)
  }

  const showModalReschedule = () => {
    setOpen(true)
  }

  const hideModalReschedule = () => {
    setOpen(false)
  }

  const changeDateFormat = (dateRange) => {
    const [startDateStr, endDateStr] = dateRange.split(' - ')
    const startDate = new Date(startDateStr)
    const endDate = new Date(endDateStr)

    const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}

    const formatSingleDate = (date) => {
      return new Intl.DateTimeFormat('en-UK', options).format(date)
    }

    const formattedStartDate = formatSingleDate(startDate)
    const formattedEndDate = formatSingleDate(endDate)

    const startDay = startDate.getDate()
    const endDay = endDate.getDate()

    const startSuffix = getOrdinalSuffix(startDay)
    const endSuffix = getOrdinalSuffix(endDay)

    return `Start Date : ${formattedStartDate.split(',')[0]} ${startDay}${startSuffix} ${
      formattedStartDate.split(' ')[1]
    } ${formattedStartDate.split(' ')[3]}\nEnd Date : ${
      formattedEndDate.split(',')[0]
    } ${endDay}${endSuffix} ${formattedEndDate.split(' ')[1]} ${formattedEndDate.split(' ')[3]}`
  }

  const handleUpload = () => {
    const formData = new FormData()
    formData.append('cv_file', selectedFile)

    dispatch(uploadCVaction(userId, formData, () => {}))
  }

  const getOrdinalSuffix = (day) => {
    const suffixes = ['th', 'st', 'nd', 'rd']
    const digit = day % 10
    return day === 11 || day === 12 || day === 13 ? 'th' : suffixes[digit] || 'th'
  }

  function formatTimeFromDate(dateString) {
    const dateObject = new Date(dateString)
    const timeString = dateObject.toLocaleTimeString('en-UK', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    })
    return timeString
  }

  const [selectedFile, setSelectedFile] = useState(null)
  const [uploading, setUploading] = useState(false)
  const userId = localStorage.getItem('userid')
  const handleFileChange = (event) => {
    const file = event.target.files[0]
    setSelectedFile(file)
  }

  const Session = useSelector((state) => {
    return state.login.getuserSessionlist
  })

  const handleCVToggle = () => {
    setCvVisible(!cvVisible) // Toggle the visibility state
  }

  useEffect(() => {
    dispatch(GetUserSessionction(studentid))
  }, [dispatch])

  const userLocale = navigator.language || 'en-UK'
  let asiaTimezone = Intl.DateTimeFormat(userLocale).resolvedOptions().timeZone

  function formatDate(dateString, timezone) {
    if (dateString) {
      const inputDate = moment.tz(dateString, 'YYYY-MM-DD HH:mm:ss', timezone);
      const localDate = inputDate.clone().local();
    
      return localDate.format('YYYY-MM-DD HH:mm:ss');
    } else {
      return null
    }
  }

  const currentDateISOString = new Date().toISOString()

  const formatDateString = (date) => {
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true // To use 12-hour format
    };
    return new Date(date).toLocaleDateString('en-GB', options);
  };

 

  function formatMentoringDate(datetime) {
    const isValidDate = moment(datetime).isValid()

    if (isValidDate) {
      const formattedDate = moment(datetime).format('DD/MM/YYYY')
      return formattedDate
    } else {
      return 'Invalid Date'
    }
  }

  const [joinUrlCopyStatuses, setJoinUrlCopyStatuses] = useState([])

  const handleCopy = (meetingLink, index) => {
    setJoinUrlCopyStatuses((prevStatuses) => {
      const newStatuses = [...prevStatuses]
      newStatuses[index] = 'Copied!'
      return newStatuses
    })

    setTimeout(() => {
      setJoinUrlCopyStatuses((prevStatuses) => {
        const newStatuses = [...prevStatuses]
        newStatuses[index] = undefined // or null, or an empty string
        return newStatuses
      })
    }, 1000)
  }

  const handleDeleteCV = () => {
    dispatch(DeletedCVAction(studentid))
  }

//   function convertUtcToLocalDateTime(utcDateTimeString) {
//     // Create a Date object from the UTC date string
//     const utcDateTime = new Date(utcDateTimeString);

//     // Get the user's timezone offset in minutes
//     const userTimezoneOffset = new Date().getTimezoneOffset();

//     // Convert the UTC date time to the local date time of the user
//     const localDateTime = new Date(utcDateTime.getTime() - (userTimezoneOffset * 60000));

//     return localDateTime;
// }

  return (
    <div>
      {Object.entries(mybundle).map(([key, course], i) => {
        if (key == 'data') {
          cdata = course
        }
      })}

      {Object.entries(cdata).map(([key, item], i) => {
        return (
          <div>
            <div className='row'>
              <div className='col'>
                <div className='p-5'>
                  <span className='badge badge-warning' style={{background: '#ff763f'}}>
                    Course Bundle
                  </span>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='fw-bold fs-1 mb-3'>{item.Name}</span>
                  </h3>
                </div>
              </div>
            </div>

            {currentDateISOString < item.expiration_time ? (
              <div class='row equal-height-row p-3'>
                <div class='col col-sm-12 col-md-6 col-lg-4  equal-height-col mb-5'>
                  <div class='card shadow-none h-100'>
                    <h4
                      class='card-header text-white justify-content-start align-items-center p-3'
                      style={{
                        background: '#264aca',
                      }}
                    >
                      <CalendarMonthIcon
                        style={{fontSize: '24px', color: '#fff', marginRight: '10px'}}
                      />
                      My Schedule
                    </h4>
                    <div className='card-body bg-light p-2'>
                      <div className='d-flex justify-content-between flex-lg-row flex-md-column align-items-md-center flex-wrap'>
                        <div>
                          <button class='btn btn-sm bg-secondary m-0 mb-5 mt-5' onClick={showModal}>
                            <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                              <BsQuestionSquare style={{fontSize: '12px'}} /> How to Join a virtual
                              class?
                            </div>
                          </button>
                        </div>
                        <div className='d-flex justify-content-end  '>
                          <button
                            class='btn btn-sm bg-secondary m-0 '
                            onClick={showModalReschedule}
                          >
                            <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                              <MdEventRepeat style={{fontSize: '12px'}} /> How to reschedule class?
                            </div>
                          </button>
                        </div>
                      </div>

                      <div className='mt-3'>
                        <div
                          className='pb-5'
                          style={{
                            padding: '5px',

                            fontWeight: 600,
                            justifyContent: 'space-between',
                            borderBottom: '1px solid #264aca',
                            color: '#264aca',
                          }}
                        >
                          <span className='mb-5'>
                            You are Booked! {item.comptia_bootcampCourse?.course_title ?? null}{' '}
                            Bootcamp:
                          </span>

                          <div className='d-flex justify-content-between align-items-center flex-wrap mt-5'>
                            <p
                              style={{
                                color: 'rgb(255, 118, 63)',
                                fontWeight: 600,
                                whiteSpace: 'pre-line',
                              }}
                            >
                              Start Date :{' '}
                              {formatDateString(formatDate(item?.comptia_bootcampCourse?.start_datetime ?? null, item?.comptia_bootcampCourse?.timezone))}
                              {/* {convertUtcToLocalDateTime(item?.start_datetime || '')} */}
                            </p>

                            {item.comptia_bootcampCourse?.joinUrl && item.comptia_bootcampCourse?.joinUrl != 'null' ? (
                              <a
                                href={item.comptia_bootcampCourse?.joinUrl}
                                className='purchedcourse button-rounded border-0 d-flex align-items-center'
                                style={{height: '30px'}}
                                target='_blank'
                              >
                                <MdLaunch />
                                Join
                              </a>
                            ) : (
                              <a
                                href={item.comptia_bootcampCourse?.joinUrl}
                                className='purchedcourse disabled button-rounded border-0 d-flex align-items-center '
                                style={{
                                  backgroundColor: 'rgb(100 116 149)',
                                  height: '30px',
                                }}
                              >
                                <MdLaunch />
                                Join
                              </a>
                            )}
                          </div>

                          <p
                            style={{
                              color: 'rgb(255, 118, 63)',
                              fontWeight: 600,
                              whiteSpace: 'pre-line',
                            }}
                          >
                            End Date :{' '}
                            {formatDateString(formatDate(item?.comptia_bootcampCourse?.end_datetime ?? null, item?.comptia_bootcampCourse?.timezone))}
                          </p>
                          <p
                            style={{
                              color: 'rgb(255, 118, 63)',
                              fontWeight: 600,
                              whiteSpace: 'pre-line',
                            }}
                          >
                            Timezone: {asiaTimezone}
                          </p>
                        </div>
                      </div>
                      <div className='mt-3'>
                        <div
                          className='pb-5'
                          style={{
                            padding: '5px',

                            fontWeight: 600,
                            justifyContent: 'space-between',
                            borderBottom: '1px solid #264aca',
                            color: '#264aca',
                          }}
                        >
                          <span className='mb-5'>
                            You are Booked! {item.microsoft_bootcampCourse?.course_title ?? null}{' '}
                            Bootcamp:
                          </span>
                          <div className='d-flex justify-content-between align-items-center flex-wrap mt-5'>
                            <p
                              style={{
                                color: 'rgb(255, 118, 63)',
                                fontWeight: 600,
                                whiteSpace: 'pre-line',
                              }}
                            >
                              Start Date :{' '}
                              {formatDateString(formatDate(item?.microsoft_bootcampCourse?.start_datetime ?? null, item?.microsoft_bootcampCourse?.timezone))}
                              
                            </p>

                            {item.microsoft_bootcampCourse?.joinUrl && item.microsoft_bootcampCourse?.joinUrl != 'null' ? (
                              <a
                                href={item.microsoft_bootcampCourse?.joinUrl}
                                className='purchedcourse button-rounded border-0 d-flex align-items-center'
                                style={{height: '30px'}}
                                target='_blank'
                              >
                                <MdLaunch />
                                Join
                              </a>
                            ) : (
                              <a
                                href={item.microsoft_bootcampCourse?.joinUrl}
                                className='purchedcourse disabled button-rounded border-0 d-flex align-items-center '
                                style={{
                                  backgroundColor: 'rgb(100 116 149)',
                                  height: '30px',
                                }}
                              >
                                <MdLaunch />
                                Join
                              </a>
                            )}
                          </div>
                          <p
                            style={{
                              color: 'rgb(255, 118, 63)',
                              fontWeight: 600,
                              whiteSpace: 'pre-line',
                            }}
                          >
                            End Date :{' '}
                            {formatDateString(formatDate(item?.microsoft_bootcampCourse?.end_datetime ?? null, item?.microsoft_bootcampCourse?.timezone))}
                          </p>

                          <p
                            style={{
                              color: 'rgb(255, 118, 63)',
                              fontWeight: 600,
                              whiteSpace: 'pre-line',
                            }}
                          >
                            Timezone: {asiaTimezone}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class='col col-sm-12 col-md-6 col-lg-4  equal-height-col mb-5'>
                  <div class='card shadow-none h-100'>
                    <h4
                      class='card-header text-white justify-content-start align-items-center p-3'
                      style={{
                        background: '#264aca',
                      }}
                    >
                      <AssignmentIcon
                        style={{fontSize: '24px', color: '#fff', marginRight: '10px'}}
                      />
                      My E-Learning Resources
                    </h4>
                    <div class='card-body bg-light p-2'>
                      <div class='row no-gutters mb-5'>
                        <div className='col-md-5 col-sm-12'>
                          <img className='card-img' src={comptia_learn} alt='' />
                        </div>
                        <div
                          className='col-md-7 col-sm-12  mt-1 mb-7 text-gray-800'
                          style={{fontWeight: 700}}
                        >
                          <p style={{color: '#264aca'}}>CompTIA - Learn, Labs & Practice</p>
                          <div class='flex-wrap' style={{display: 'flex', gap: '20px'}}>
                            <a
                              href='https://login.comptia.org/'
                              target='_blank'
                              rel='noreferrer'
                              class='btn btn-sm bg-secondary m-0'
                              style={{display: 'flex'}}
                            >
                              <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                                <MdLaunch style={{fontSize: '12px'}} /> Launch
                              </div>
                            </a>

                            <button
                              class='btn btn-sm bg-secondary m-0 d-flex'
                              onClick={showModalReschedule}
                            >
                              <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                                <TbNotes style={{fontSize: '12px'}} /> Instructions
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className='row no-gutters mb-5'>
                        <div className='col-md-5'>
                          <img src={ms_learn} alt='' />
                        </div>
                        <div className='col-md-7 mt-1 mb-7 text-gray-800' style={{fontWeight: 700}}>
                          <p style={{color: '#264aca'}}>Microsoft Learning Resources</p>

                          <button
                            class='btn btn-sm bg-secondary m-0 d-flex'
                            onClick={showModalReschedule}
                          >
                            <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                              <TbNotes style={{fontSize: '12px'}} /> Instructions
                            </div>
                          </button>
                        </div>
                      </div>

                      <div class='row no-gutters mb-5'>
                        <div className='col-md-5'>
                          <img src={challageimg} alt='' />
                        </div>
                        <div className='col-md-7 mt-1 mb-7 text-gray-800' style={{fontWeight: 700}}>
                          <p style={{color: '#264aca'}}>Challenge Labs</p>
                          <button
                            class='btn btn-sm bg-secondary m-0 d-flex'
                            onClick={showModalReschedule}
                          >
                            <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                              <TbNotes style={{fontSize: '12px'}} /> Instructions
                            </div>
                          </button>
                        </div>
                      </div>
                      <div class='row no-gutters '>
                        <div className='col-md-5'>
                          <img src={voucher} alt='' />
                        </div>
                        <div className='col-md-7 mt-1 mb-7 text-gray-800' style={{fontWeight: 700}}>
                          <p style={{color: '#264aca'}}>Exam Voucher</p>

                          {item.code ? (
                            <div>
                              {item.exam_code === 'Reveal_Code' ? (
                                <div style={{display: 'flex', gap: '60px'}}>
                                  <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                                    <span
                                      className='badge badge-secondary mb-4'
                                      onClick={() => {
                                        handleClick(item)
                                        onSubmit(item)
                                      }}
                                      style={{cursor: 'pointer'}}
                                    >
                                      Reveal Code
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  <label>Code: </label> <span>{item.code}</span>
                                </div>
                              )}

                              {item.exam_code != 'Reveal_Code' ? (
                                <div
                                  className='mb-5'
                                  style={{display: 'flex', alignItems: 'center', gap: '5px'}}
                                >
                                  <label>Expiry: </label>{' '}
                                  <span>
                                    {new Date(item.voucherExpiary).toLocaleDateString('en-GB', {
                                      day: '2-digit',
                                      month: '2-digit',
                                      year: 'numeric',
                                    })}
                                  </span>
                                </div>
                              ) : (
                                <div></div>
                              )}
                            </div>
                          ) : (
                            <div>
                              <p>Voucher not added yet</p>
                            </div>
                          )}
                          <button
                            class='btn btn-sm bg-secondary m-0 d-flex'
                            onClick={showModalReschedule}
                          >
                            <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                              <TbNotes style={{fontSize: '12px'}} /> Instructions
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class='col col-md-6 col-lg-4 col-sm-12 equal-height-col mb-5'>
                  <div class='card shadow-none h-100'>
                    <h4
                      class='card-header text-white justify-content-start align-items-center p-3'
                      style={{
                        background: '#264aca',
                      }}
                    >
                      <CalendarMonthIcon
                        style={{fontSize: '24px', color: '#fff', marginRight: '10px'}}
                      />
                      My Mentoring
                    </h4>

                    <div className='card-body bg-light p-2 h-100'>
                      <div class='row no-gutters mb-5'>
                        <div className='col-md-5'>
                          <img src={mentroing2} alt='' />
                        </div>
                        <div className='col-md-7 mt-1 mb-7 text-gray-800' style={{fontWeight: 700}}>
                          <p style={{color: '#264aca'}}>Mentoring Sessions</p>

                          {Session && Array.isArray(Session[item.bundle_id]) ? (
                            <div>
                              {Session[item.bundle_id].map((ses, index) => (
                                <div key={index}>
                                  <p style={{color: '#FF763F'}}>Session {index + 1}</p>
                                  <p>Start Date: - {formatMentoringDate(ses.datetime)}, {ses.startTime}</p>
                                  <p className='d-flex'>
                                    Meeting Link: &nbsp;&nbsp;
                                    <CopyToClipboard
                                      text={ses.meetingLink}
                                      onCopy={() => handleCopy(ses.meetingLink, index)}
                                    >
                                      <div className='copy' style={{cursor: 'pointer'}}>
                                        <a
                                          disabled={
                                            !window.ethereum?.selectedAddress ||
                                            !localStorage.getItem('authToken')
                                          }
                                        >
                                          Copy Link
                                        </a>
                                      </div>
                                    </CopyToClipboard>
                                    {joinUrlCopyStatuses[index] && (
                                      <span style={{color: 'green', marginLeft: '5px'}}>
                                        {joinUrlCopyStatuses[index]}
                                      </span>
                                    )}
                                  </p>
                                </div>
                              ))}
                            </div>
                          ) : null}
                          {(Session &&
                            Array.isArray(Session[item.bundle_id]) &&
                            Session[item.bundle_id].length < 2) ||
                          (Session && !Array.isArray(Session[item.bundle_id])) ? (
                            <div>
                              <Link to='/Mentoring' class='btn btn-sm bg-secondary m-0'>
                                <EventAvailableOutlinedIcon />
                                {Array.isArray(Session[item.bundle_id]) ? (
                                  <span>Book Session {Session[item.bundle_id].length + 1}</span>
                                ) : (
                                  <span>Book Session 1</span>
                                )}
                              </Link>
                            </div>
                          ) : (
                            <p style={{color: '#FF763F'}}>No more sessions to book.</p>
                          )}
                        </div>
                      </div>
                      <div class='row no-gutters mb-5'>
                        <div className='col-md-5'>
                          <img src={mentroing1} alt='' />
                        </div>
                        <div className='col-md-7 mt-1 mb-7 text-gray-800' style={{fontWeight: 700}}>
                          <p style={{color: '#264aca'}}>Upload CV</p>
                          <p>
                            If you would like your CV reviewed during your mentoring session, please
                            upload your CV 72 hours ahead of the meeting.
                          </p>

                          <div style={{gap: '5rem'}}>
                            {item && item?.fileUrl ? (
                              <div className='d-flex' style={{gap: '5rem'}}>
                                <p>{item?.fileName}</p>
                                <div>
                                  <Tooltip title='View CV'>
                                    <a
                                      href={item?.fileUrl}
                                      style={{height: '30px'}}
                                      target='_blank'
                                    >
                                      <span style={{fontSize: '18px', color: 'rgb(126, 130, 153)'}}>
                                        <TbEye />
                                      </span>
                                    </a>
                                  </Tooltip>
                                  <Tooltip title='Delete CV'>
                                    <button
                                      style={{
                                        border: 'none',
                                        height: '30px',
                                        background: 'transparent',
                                      }}
                                      onClick={handleDeleteCV}
                                    >
                                      <span style={{fontSize: '18px', color: 'rgb(126, 130, 153)'}}>
                                        <FaTrash />
                                      </span>
                                    </button>
                                  </Tooltip>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                            <button
                              className='btn btn-sm bg-secondary m-0'
                              onClick={handleCVToggle}
                            >
                              <FileUploadOutlinedIcon />
                              Upload my CV
                            </button>
                          </div>
                        </div>
                        {cvVisible && (
                          <div className='col-md-12'>
                            <div className='bg-white p-5 mt-5'>
                              <input
                                type='file'
                                onChange={handleFileChange}
                                ref={fileInputRef}
                                className='mb-3 form-control'
                              />
                              {/* <button className='form-control' onClick={() => fileInputRef.current.click()}>Select File</button> */}
                              <button
                                className='btn btn-sm bg-secondary m-0'
                                onClick={handleUpload}
                                disabled={!selectedFile}
                              >
                                Upload
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <h4 className='card-title align-items-center flex-column'>
                <span className='mb-3'>Your Course is Expired!</span>
              </h4>
            )}
          </div>
        )
      })}

      <Modal
        title='Date Available'
        open={open}
        onCancel={hideModal}
        footer={null}
        centered
        wrapClassName='custom-modal'
      >
        <Document file={dummy} onLoadError={(error) => console.error('PDF load error:', error)}>
          <Page pageNumber={1} />
        </Document>
      </Modal>

      <Modal
        title='Reschedule'
        visiopenble={open}
        onCancel={hideModalReschedule}
        footer={null}
        centered
        wrapClassName='custom-modal'
      >
        <Document file={dummy} onLoadError={(error) => console.error('PDF load error:', error)}>
          <Page pageNumber={1} />
        </Document>
      </Modal>
    </div>
  )
}

export default Section2
