import React from 'react'
import StripeContainer from './Stripe/StripeContainer'

const Stripepay = ({setCardComplete}) => {
  return (
    <div className='App'>
      <StripeContainer setCardComplete={setCardComplete} />
    </div>
  )
}

export default Stripepay
