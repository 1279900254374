import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import './purchasehistory.css'
import {useDispatch, useSelector} from 'react-redux'

import {BoughtcourseAction} from '../../../../Redux/Action/courseAction'
const Purchasehistory = () => {
  const dispatch = useDispatch()

  const studentid = localStorage.getItem('userid')

  const mycoures = useSelector((state) => {
    return state.login.boughtcourseslist
  })

  useEffect(() => {
    dispatch(BoughtcourseAction(studentid))
  }, [dispatch, studentid])

  let cdata = ''

  return (
    <div>
      <div className='purchase-history'>
        <div className='card'>
          <div className='card-header card-header-stretch border-bottom border-gray-200'>
            <div className='card-title'>
              <h3 className='fw-bold m-0'>Purchase History</h3>
            </div>
          </div>

          <div className='tab-content'>
            <div
              id='kt_billing_months'
              className='card-body p-0 tab-pane fade show active'
              role='tabpanel'
              aria-labelledby='kt_billing_months'
            >
              <div className='table-responsive'>
                <table className='table table-row-bordered align-middle gy-4 gs-9'>
                  <thead className='border-bottom border-gray-200 fs-6 text-gray-600 fw-bold bg-light bg-opacity-75'>
                    <tr>
                      <td className='min-w-150px'>Date</td>
                      <td className='min-w-250px'>Course</td>
                      <td className='min-w-150px'>Amount</td>
                      <td className='min-w-150px'>Expire On</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody className='fw-semibold text-gray-600'>
                    {Object.entries(mycoures).map(([key, course], i) => {
                      if (key == 'data') {
                        cdata = course
                      }
                      {
                      }
                    })}

                    {Object.entries(cdata).map(([key, item], i) => {
                      return (
                        <tr>
                          <td>{item.paymenttime}</td>
                          <td>
                            <Link to='#'> {item.title}</Link>
                          </td>
                          <td>$ {item.amountpaid}</td>
                          <td>Feb 01, 2020</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Purchasehistory
