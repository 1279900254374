// InactivityTimer.js
import {useEffect, useState} from 'react'

function InactivityTimer({onLogout, inactivityDuration}) {
  const [userActive, setUserActive] = useState(true)

  useEffect(() => {
    let inactivityTimeout

    const resetTimer = () => {
      clearTimeout(inactivityTimeout)
      inactivityTimeout = setTimeout(() => {
        setUserActive(false)
      }, inactivityDuration)
    }

    const onUserActivity = () => {
      setUserActive(true)
      resetTimer()
    }

    window.addEventListener('mousemove', onUserActivity)
    window.addEventListener('keydown', onUserActivity)

    resetTimer()

    return () => {
      clearTimeout(inactivityTimeout)
      window.removeEventListener('mousemove', onUserActivity)
      window.removeEventListener('keydown', onUserActivity)
    }
  }, [inactivityDuration])

  useEffect(() => {
    if (!userActive) {
      onLogout()
    }
  }, [userActive, onLogout])

  return null
}

export default InactivityTimer
