import React from 'react'
import Coursesaleschart from '../Dashboard/Sections/Coursesaleschart/Coursesaleschart'
import BundleSaleCharts from './Sections/BundleSalesCharts/BundleSaleCharts'

import ApexChart from '../Dashboard/Sections/ApexChart'
import Map from '../Dashboard/Sections/Map/Map'

import Studentlist from '../Dashboard/Sections/Studentlist'

const Reports = () => {
  return (
    <div>
      <div class='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
        <h1
          class='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'
          style={{paddingBottom: '20px'}}
        >
          Reports
        </h1>
      </div>
      <div className='row' style={{paddingTop: '2rem'}}>
        <div className='col-md-12'>
          <div className='row'>
            <div className='col'>
              <div className='card taller-card' style={{height: '500px'}}>
                <div className='card-body' style={{overflow: 'auto'}}>
                  <h3 className='fw-bold m-0 pt-4 pb-4' style={{color: '#021F59'}}>
                    Monthly Sales
                  </h3>
                  <ApexChart />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row mb-10'>
        <div className='col-md-12'>
          <div className='row'>
            <div className='col'>
              <div>
                <div>
                  <h3 className='fw-bold m-0 pt-4 pb-4' style={{color: '#021F59'}}>
                    All Students
                  </h3>
                  <Studentlist />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row pt-10 '>
        <div className='col-md-12'>
          <div>
            <BundleSaleCharts />
          </div>
        </div>
      </div>
      <div className='row' style={{paddingTop: '2rem'}}>
        <div className='col-md-12'>
          <div className='row'>
            <div className='col-md-6'>
              <Coursesaleschart />
            </div>
            <div className='col-md-6'>
              <div>
                <Map />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className='row' style={{paddingTop: '2rem'}}>
        <div className='col-md-12'>
          <div className='row'>
            <div className='col-md-6'>
              <MaxRegistration />
            </div>
            <div className='col-md-6'>
              <div>
                <MaxRegistration />
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className='col-md-6 pb-10'>
        <Maxpurchasedcourse />
      </div>
      <div className='col-md-6 pb-10'>
        <MaxRegistration />
      </div> */}
    </div>
  )
}

export default Reports
