import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import ReactApexChart from 'react-apexcharts'
import {GetAllBundleSalesCount} from '../../../../../Redux/Action/admindashboardAction'

const BundleSaleCharts = () => {
  const dispatch = useDispatch()
  const bundleSales = useSelector((state) => {
    return state.login.getBundleSalesList
  })



  useEffect(() => {
    dispatch(GetAllBundleSalesCount())
  }, [dispatch])

  const colors = ['#ffc700', '#f1416c', '#47be7d', '#0095e8', '#050f29']

  const state = {
    series: [
      {
        data: bundleSales.data,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'bar',
        events: {
          click: function (chart, w, e) {
            console.log(chart, w, e)
          },
        },
      },
      colors: colors,
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: bundleSales.bundleTitles,
        labels: {
          style: {
            colors: colors,
            fontSize: '12px',
          },
        },
      },
    },
  }
  return (
    <div>
      <div className='card card-flush'>
        <div
          className='card-label fw-bold text-dark'
          style={{fontSize: '1.275rem', padding: '30px'}}
        >
          Bundle Sales
        </div>
        <ReactApexChart
          title='Bundle Sale'
          options={state.options}
          series={state.series}
          type='bar'
          height={600}
        />
      </div>
    </div>
  )
}

export default BundleSaleCharts
