import React, {useEffect} from 'react'

import {useDispatch, useSelector} from 'react-redux'
import {GetUserById} from '../../../../Redux/Action/profileAction'
import {ArrowLeftOutlined} from '@ant-design/icons'
import {Link} from 'react-router-dom'

const Adminwlcome = () => {
  const firstname = localStorage.getItem('FirstName')
  const lastname = localStorage.getItem('Surname')
  const dispatch = useDispatch()
  // debugger
  const studentid = localStorage.getItem('userid')
  const userdetails = useSelector((state) => {
    return state.login.getuserIdlist
  })

  useEffect(() => {
    dispatch(GetUserById(studentid))
  }, [dispatch, studentid])
  return (
    <div>
      <div className='responsive_arrow'>
        <Link
          to='/'
          style={{
            fontSize: '20px',
            color: '#FF763F',
            fontWeight: 'bold',
          }}
        >
          <ArrowLeftOutlined />
        </Link>
      </div>
      <div className='adminwlcom'>
        <div className='myactivity'>
          <div class='card card-flush h-xl-100 shadow-sm mb-5'>
            <div
              class='rounded p-3'
              style={{
                backgroundColor: ' #264aca',
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
              }}
              data-bs-theme='light'
            >
              <div style={{padding: '20px'}}>
                <img
                  src={
                    userdetails.message &&
                    (userdetails.message.profileUrl
                      ? userdetails.message.profileUrl
                      : userdetails.message.profilePic)
                  }
                  alt='img'
                  style={{
                    borderRadius: '50%',
                    width: '100px',
                    height: '100px',
                    border: '3px solid #fff',
                  }}
                />
              </div>
              <div>
                <h2 class='card-title text-white'>
                  <span class='fw-bold mb-3'>
                    Hello, {firstname} {lastname}
                  </span>
                </h2>
                <h4>
                  <span class='card-subtitle mb-2 text-white'>Welcome Back!</span>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Adminwlcome
