import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Col, Container, Row} from 'react-bootstrap'
import {FaAngleLeft, FaAngleRight} from 'react-icons/fa'
import {RecentSevenDaysCoursesAction} from '../../../../../Redux/Action/admindashboardAction'
import {Link} from 'react-router-dom'

const SevenDaysRecent = () => {
  const dispatch = useDispatch()

  const [currentPage, setCurrentPage] = useState(1)
  function nextPage() {
    if (currentPage !== nPage) {
      setCurrentPage(currentPage + 1)
    }
  }

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  function changeCPage(id) {
    setCurrentPage(id)
  }
  const recentSevenDaysCourses = useSelector((state) => {
    // debugger;
    return state.login.getRecentSevenDaysCoursesList
  })

  useEffect(() => {
    dispatch(RecentSevenDaysCoursesAction())
  }, [dispatch])

  const recordsPerPage = 10

  const lastIndex = currentPage * recordsPerPage

  const firstIndex = lastIndex - recordsPerPage

  const records = recentSevenDaysCourses.slice(firstIndex, lastIndex)

  const nPage = Math.ceil(recentSevenDaysCourses.length / recordsPerPage)

  const number = [...Array(nPage + 1).keys()].slice(1)

  const recentSevendaysCoursesLength = recentSevenDaysCourses.length
  return (
    <div>
      <div>
        <div class='card card-flush h-xl-100'>
          <div class='card-header pt-7'>
            <h3 class='card-title align-items-start flex-column'>
              <span class='card-label fw-bold text-gray-800'>Recent Purchases - 7 Days</span>
              <span class='text-gray-400 mt-1 fw-semibold fs-6'>
                Total {recentSevendaysCoursesLength} Purchases
              </span>
            </h3>
          </div>

          <div class='card-body pt-3 pb-4'>
            <div class='table-responsive'>
              <table class='table table-row-dashed align-middle gs-0 gy-4 my-0'>
                <thead>
                  <tr class='fs-7 fw-bold text-gray-500 border-bottom-0'>
                    <th class='p-0 w-200px w-xxl-450px'>#</th>
                    <th class='p-0 w-200px w-xxl-450px'>Student Name</th>
                    <th class='p-0 w-200px w-xxl-450px'>Course Name</th>
                    <th class='p-0 min-w-150px'>Price</th>
                    <th class='p-0 min-w-125px'>Purchase Time</th>
                  </tr>
                </thead>
                {recentSevendaysCoursesLength === 0 ? (
                  <tbody class='text-gray-600 fw-semibold'>
                    <tr>
                      <td colSpan={4} className='text-center fw-700'>
                        No Record Found
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {records.map((recentSevendays, index) => {
                      const serialNumber = (currentPage - 1) * recordsPerPage + index + 1
                      return (
                        <tr>
                          <td>{serialNumber}</td>
                          <td>
                            <div class='d-flex align-items-center'>
                              <div class='d-flex justify-content-start flex-column'>
                                {recentSevendays.FirstName}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class='d-flex align-items-center'>
                              <div class='d-flex justify-content-start flex-column'>
                                {recentSevendays.title}
                              </div>
                            </div>
                          </td>
                          <td class='text-start'>
                            <span class='text-gray-800 fw-bold d-block mb-1 fs-6'>
                            £{recentSevendays.paid_price}
                            </span>
                          </td>
                          <td class='text-start'>
                            <span class='fw-semibold text-gray-400 d-block'>
                              {new Date(recentSevendays.paymenttime).toLocaleString('en-US', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                              })}
                            </span>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                )}
              </table>
              {recentSevendaysCoursesLength === 0 ? (
                ''
              ) : (
                <nav>
                  <ul className='pagination' style={{marginBottom: '20px'}}>
                    <li className='page-item'>
                      <Link to='#' className='page-link' onClick={prePage}>
                        <FaAngleLeft />
                      </Link>
                    </li>
                    {number.map((n, i) => (
                      <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                        <Link to='#' className='page-link' onClick={() => changeCPage(n)}>
                          {n}
                        </Link>
                      </li>
                    ))}
                    <li className='page-item'>
                      <Link to='#' className='page-link' onClick={nextPage}>
                        <FaAngleRight />
                      </Link>
                    </li>
                  </ul>
                </nav>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SevenDaysRecent
