import React from 'react'
import {NavLink, useParams} from 'react-router-dom'
import {useState} from 'react'
import './navbar.css'
import Blank from '../../PrivateImage/img/Blank.png'
import BundleBannerBtn from '../Bundle/BundleDetails/BundlesPages/BundleBannerBtn'
import BannerBuyBtn from '../Courses/Categorycourse/MicrosoftBootCamp/BannerBtn'
import coursebanner from '../../PrivateImage/img/coursebanner.png'
import {useLocation} from 'react-router-dom'
import logo from '../../PrivateImage/img/logo.png'

import swal from 'sweetalert'
import consultation from '../../PrivateImage/bundleimg/consultation.png'

import consultancyres from '../../PrivateImage/bundleimg/consultancyres.png'

import homebanner from '../../PrivateImage/bundleimg/homebanner.png'

import careerbanner from '../../PrivateImage/img/careerbanner.png'
import Aboutus from '../../PrivateImage/img/Aboutus.png'

import responsivebanner from '../../PrivateImage/bundleimg/responsivebanner.png'
import reponsivebg from '../../PrivateImage/img/reponsivebg.png'
import MaskGroup from '../../PrivateImage/img/MaskGroup.png'
import ElearningBannerBuyBtn from '../Courses/Categorycourse/ComptiaElearning/ElearningBannerBtn'
import careerresp from '../../PrivateImage/img/careerresp.png'
// consultancyres

const Navbar = () => {
  const location = useLocation()

  const [isVideoPlaying, setVideoPlaying] = useState(false)

  const handleVideoClick = () => {
    setVideoPlaying(!isVideoPlaying)
  }

  const [showNavbar, setShowNavbar] = useState(false)

  const logoutHandler = async () => {
    const confirmResult = await swal({
      title: 'Log Out',
      text: 'Are you sure you want to log out?',
      buttons: {
        cancel: {
          text: 'No',
          value: null,
          visible: true,
          className: 'swal-button--cancel cybercancelbtn',
        },
        confirm: {
          text: 'Yes',
          value: true,
          visible: true,
          className: 'swal-button--confirm cyberbtnallpage',
        },
      },
      dangerMode: false,
    })
    if (confirmResult) {
      localStorage.clear()
      window.location.reload()
    }
  }
  const forceSet = localStorage.getItem('forceReset')
  // debugger;
  let roleid = localStorage.getItem('roleid')

  let dblink

  if (roleid == 1) {
    dblink = '/dashboard'
  } else if (roleid == 2) {
    dblink = '/dashboard'
  } else if (roleid == 3) {
    dblink = '/mydashboard'
  } else if (roleid == 4) {
    dblink = '/instructors'
  } else if (roleid == 5) {
    dblink = '/Content_Writers'
  } else if (roleid == 6) {
    dblink = '/Corporates_Manager'
  } else if (roleid == 7) {
    dblink = '/Corporate_Employees'
  } else if (roleid == 8) {
    dblink = '/hellooo'
  } else {
    dblink = '/'
  }
  const bannerStyle = {
    backgroundImage: `url(${homebanner})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '1080px',

    opacity: '1',
  }

  const responsivebannerStyle = {
    backgroundImage: `url(${responsivebanner})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '1080px',

    opacity: '1',
  }
  const bannerStyle1 = {
    backgroundImage: `url(${consultation})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '1080px',

    opacity: '1',
  }

  const bannerStyle1res = {
    backgroundImage: `url(${consultancyres})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '900px',

    opacity: '1',
  }
  
  const { token } = useParams();
  return (
    <div>
      <div class='header'>
      <nav className={`navbar navbar-expand-lg d-flex justify-content-between ${location.pathname === '/success' || location.pathname === `/verified/${token}` ? 'fixed-color' : ''}`}>
          <a class='navbar-brand' href='#'>
            {' '}
            <NavLink to='/'>
              <img src={logo} alt='logo' />{' '}
            </NavLink>
          </a>
          <button
            class='navbar-toggler'
            type='button'
            data-toggle='collapse'
            data-target='#navbarTogglerDemo01'
            aria-controls='navbarTogglerDemo01'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span class='navbar-toggler-icon'></span>
          </button>
          <div class='collapse navbar-collapse' id='navbarTogglerDemo01'>
            <ul class='navbar-nav'>
              <li class='nav-item'>
                <NavLink className='nav-link' to='/course' activeclassname='active-link'>
                  COURSES
                </NavLink>
              </li>
              <li class='nav-item'>
                <NavLink className='nav-link' to='/aboutus' activeclassname='active-link'>
                  ABOUT US
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink className='nav-link' to='/Careers' activeclassname='active-link'>
                  CAREERS
                </NavLink>
              </li>

              <li class='nav-item'>
                <NavLink className='nav-link' to='/Consultancy' activeclassname='active-link'>
                  CONSULTING
                </NavLink>
              </li>

              {localStorage.getItem('authToken') && (forceSet === '0' || forceSet === 'null') && (
                <li class='nav-item'>
                  <NavLink className='nav-link' to={dblink} activeclassname='active-link'>
                    MY DASHBOARD
                  </NavLink>
                </li>
              )}
            </ul>
            {localStorage.getItem('authToken') && (forceSet === '0' || forceSet === 'null') ? (
              <NavLink to='/' style={{color: 'white'}} onClick={() => logoutHandler()}>
                <a className='button button-rounded logoutBtn' onClick={() => logoutHandler()}>
                  <span className="logout-span">LOG OUT</span>
                </a>
              </NavLink>
            ) : (
              <NavLink to='/login' style={{color: 'white'}}>
                <a className='button button-rounded logoutBtn'>
                  <span className="logout-span">Log in</span>
                </a>
              </NavLink>
            )}
          </div>
        </nav>
        {(location.pathname !== '/success' && location.pathname !== `/verified/${token}`) && (
          <>
            {location.pathname === '/' ? (
              <div>
                <div className='homeBanner'>
                  <div className='hero-video position-relative' style={bannerStyle}>
                    <video
                      loop
                      className={isVideoPlaying ? 'play' : ''}
                      onClick={handleVideoClick}
                    ></video>
                    <div className='row d-flex align-items-center'>
                      <div className='col-md-1'></div>
                      <div
                        className='have_goal col-md-5 position-absolute start-0'
                        style={{top: '25rem', padding: '5rem'}}
                      >
                        <span className='have_goal_sapn' style={{color: 'white', fontSize: '55px'}}>
                          Have goals in <br /> Cybersecurity?{' '}
                        </span>
                        <br />
                        <span
                          className='have_goal_secsapn'
                          style={{color: 'white', fontSize: '30px'}}
                        >
                          We'll help accelerate them
                        </span>
                        <div className='startnow pt-10'>
                          <NavLink to='/course'>
                            <button className='button button-rounded border-0'>START NOW</button>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='responsive_banner'>
                  <div className='hero-video position-relative' style={responsivebannerStyle}>
                    <video
                      loop
                      className={isVideoPlaying ? 'play' : ''}
                      onClick={handleVideoClick}
                    ></video>
                    <div className='row d-flex align-items-center'>
                      <div className='col-md-1'></div>
                      <div
                        className=' have_goal col-md-5 position-absolute start-0'
                        style={{top: '23rem'}}
                      >
                        <span style={{color: 'white', fontSize: '42px'}}>
                          Have goals in <br /> Cybersecurity?{' '}
                        </span>
                        <br />
                        <span style={{color: 'white', fontSize: '22px'}}>
                          We'll help accelerate them
                        </span>
                        <div className='col-md-5  pt-10'>
                          <NavLink to='/course'>
                            <button
                              className='button button-rounded border-0'
                              style={{width: '261px', padding: '16px'}}
                            >
                              START NOW
                            </button>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : location.pathname === '/Consultancy' ? (
              <div>
                <div className='consult'>
                  <div className='hero-video position-relative' style={bannerStyle1}>
                    <video
                      loop
                      className={isVideoPlaying ? 'play' : ''}
                      onClick={handleVideoClick}
                    ></video>
                    <div className='row d-flex align-items-center'>
                      <div className='col-md-1'></div>
                      <div
                        className='focus_business col-md-5 position-absolute start-0'
                        style={{top: '25rem', padding: '5rem'}}
                      >
                        <span className='focusbusiness' style={{color: 'white', fontSize: '35px'}}>
                          Focus on building your business,
                        </span>
                        <br />
                        <span
                          className='protecting'
                          style={{color: 'white', fontSize: '55px', whiteSpace: 'normal'}}
                        >
                          While we focus on <br /> protecting it
                        </span>
                        <br />

                        <div className='col-md-5 pt-10'>
                          <a
                            className='button button-rounded border-0'
                            href='https://calendly.com/cyberheads-mentoring/'
                            style={{whiteSpace: 'nowrap'}}
                          >
                            BOOK A CONSULTATION
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='bannerresonsv'>
                  <div className='hero-video position-relative' style={bannerStyle1res}>
                    <video
                      loop
                      className={isVideoPlaying ? 'play' : ''}
                      onClick={handleVideoClick}
                    ></video>
                    <div className='row d-flex align-items-center'>
                      <div className='col-md-1'></div>
                    </div>
                  </div>
                </div>
              </div>
            ) : location.pathname.startsWith('/course') ? (
              <div>
                <div className='about_page'>
                  <div className='hero-video'>
                    <video width='100%' poster={coursebanner}></video>
                  </div>
                </div>
                <div className='responsvive_aboutus'>
                  <div className='hero-video'>
                    <video width='100%' poster={MaskGroup}></video>
                  </div>
                </div>
              </div>
            ) : location.pathname.startsWith('/aboutus') ? (
              <div>
                <div className='about_page'>
                  <div className='hero-video'>
                    <video width='100%' poster={Aboutus}></video>
                  </div>
                </div>
                <div className='responsvive_aboutus'>
                  <div className='hero-video'>
                    <video width='100%' poster={reponsivebg}></video>
                  </div>
                </div>
              </div>
            ) : location.pathname.startsWith('/Careers') ? (
              <div>
                <div className='about_page'>
                  <div className='hero-video'>
                    <video width='100%' poster={careerbanner}></video>
                  </div>
                </div>
                <div className='responsvive_aboutus'>
                  <div className='hero-video'>
                    <video width='100%' poster={careerresp}></video>
                  </div>
                </div>
              </div>
            ) : location.pathname.startsWith('/success') ? (
              <div>

              </div>
            ) : location.pathname.startsWith('/bundle_details') ? (
              <div
                className='hero-video'
                style={{
                  background: `url(${Blank})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: ' no-repeat',
                  height: '600px',
                  textAlign: 'center',
                  color: '#fff',
                  padding: '100px 0',
                }}
              >
                <BundleBannerBtn />
              </div>
            ) : location.pathname.startsWith('/microsoft-bootcamp') ||
              location.pathname.startsWith('/compitia_bootcamp') ? (
              <div
                className='hero-video'
                style={{
                  background: `url(${Blank})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: ' no-repeat',
                  height: '600px',
                  textAlign: 'center',
                  color: '#fff',
                  padding: '100px 0',
                }}
              >
                <BannerBuyBtn />
              </div>
            ) : location.pathname.startsWith('/comptia-learning') ? (
              <div
                className='hero-video'
                style={{
                  background: `url(${Blank})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: ' no-repeat',
                  height: '600px',
                  textAlign: 'center',
                  color: '#fff',
                  padding: '100px 0',
                }}
              >
                <ElearningBannerBuyBtn />
              </div>
            ) : (
              <div className='hero-video'>
                <video width='100%' poster={Blank}></video>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default Navbar
