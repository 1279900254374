import React, {useEffect, useState} from 'react'
import security from '../../../assets/images/security.jpg'
import FormatAlignLeftOutlinedIcon from '@mui/icons-material/FormatAlignLeftOutlined'
import Progressbar from './Progress_bar'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import {useParams} from 'react-router-dom'
import {GetCourseAction} from '../../../Redux/Action/courseAction'
import {
  GetallchapterAction,
  DeleteChapterAction,
  UpdateChapterAction,
  GetallchapterEdit,
} from '../../../Redux/Action/createChapterAction'

import Createchapter from './Modalforms/Createchapter'
import {Button, Modal} from 'antd'
import {UploadOutlined} from '@ant-design/icons'

import DeleteIcon from '@mui/icons-material/Delete'
import EditChapter from './Modalforms/EditChapter'
import Form from 'react-bootstrap/Form'

import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import {useForm} from 'react-hook-form'

import NoteAddIcon from '@mui/icons-material/NoteAdd'
import DateRangeIcon from '@mui/icons-material/DateRange'
import {FaPlus} from 'react-icons/fa'
import './coursedetails.css'

const CourseDetails = () => {
  const itemStyle = {
    display: 'flex',
    gap: '1rem',
    marginBottom: '1rem',
    padding: '1rem',
    borderRadius: '8px',
    backgroundColor: '#fff',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    justifyContent: 'space-between',
  }
  const [show, setShow] = useState(false)
  const courseId = useParams()

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm()

  var newURL = window.location

  var splitURL = newURL.href.split('/')
  const course_id = splitURL[4]
  const coursedetails = useSelector((state) => {
    return state.login.getcoursebyidlist
  })

  useEffect(() => {
    // debugger
    dispatch(GetCourseAction(courseId))
  }, [dispatch])
  let cdata = ''
  const [selectedInstructorId, setSelectedInstructorId] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [chaptername, setChaptername] = useState(null)
  const [desc, setDesc] = useState(null)
  const showModal = () => {
    setIsModalOpen(true)
  }

  const getallChapter = useSelector((state) => {
    // debugger
    return state.login.getallchapterlist
  })
  useEffect(() => {
    dispatch(GetallchapterAction(course_id))
  }, [dispatch])

  const DeleteChapter = (id) => {
    dispatch(DeleteChapterAction(id))
  }

  const getallChapterid = useSelector((state) => {
    // debugger
    return state.login.getallchapteredit_list
  })

  useEffect(() => {
    // debugger
    dispatch(GetallchapterEdit(selectedInstructorId))
  }, [dispatch, selectedInstructorId])

  const handleCancel = () => {
    setShow(false)
    window.location.reload()
  }
  useEffect(() => {
    if (getallChapterid?.data) {
      setValue('chaptername', getallChapterid.data[0].chaptername || '')
      setValue('desc', getallChapterid.data[0].desc || '')
    }
  }, [getallChapterid?.data, setValue])

  const onSubmit = (data) => {
    // debugger
    const formData = new FormData()
    formData.append('chaptername', data.chaptername)
    formData.append('desc', data.desc)
    formData.append('course_id', course_id)

    dispatch(UpdateChapterAction(formData))
  }

  const handleBookButtonClick = (id) => {
    setSelectedInstructorId(id)
    // localStorage.setItem('selectedInstructorId', id)
    setIsModalOpen(true)
  }

  const handleEditButtonClick = (chapterSlug) => {
    localStorage.setItem('selectedInstructorId', chapterSlug)
  }
  const maxDescriptionLength = 300 // Set your desired maximum length

  // Assuming coursedetails.data.description contains HTML content
  const description = (coursedetails?.data?.description || '').slice(0, maxDescriptionLength)

  return (
    <div>
      <div className='chapter_dash row' style={{gap: '10px'}}>
        <div className='col-md-3'>
          <div className='row'>
            <div className='card h-600px' style={{boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'}}>
              <img src={security} alt='/' />

              {coursedetails && coursedetails.data && (
                <div className='pt-10'>
                  <h1
                    style={{
                      fontSize: '15px',
                      width: '83%',
                      textAlign: 'center',
                    }}
                  >
                    {coursedetails.data.title}
                  </h1>
                  <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
                    <p
                      dangerouslySetInnerHTML={{__html: description}}
                      style={{
                        fontFamily: 'Sora, sans-serif',
                        fontSize: '12px',
                      }}
                    />
                  </div>
                </div>
              )}

              {/* <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '-2rem',
                }}
              >
                <Progressbar bgcolor='#FF763F' progress={100} height={10} />
              </div> */}

              {/* <div style={{paddingTop: '3rem'}}>
                <div
                  style={{
                    padding: '5px',
                    background: 'rgb(245, 243, 243)',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <LibraryBooksIcon
                    style={{width: '50px', fontSize: '30px', color: 'rgb(255, 118, 63)'}}
                  />
                  <span className='text-500 fw-bold fs-6'>All Chapters(23)</span>
                </div>
              </div> */}

              {/* <div style={{paddingTop: '1rem'}}>
                <div
                  style={{
                    padding: '5px',
                    background: 'rgb(245, 243, 243)',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <NoteAddIcon
                    style={{width: '50px', fontSize: '30px', color: 'rgb(255, 118, 63)'}}
                  />
                  <span className='text-500 fw-bold fs-6'>All Lesson(5)</span>
                </div>
              </div> */}

              {/* <div style={{paddingTop: '1rem'}}>
                <div
                  style={{
                    padding: '5px',
                    background: 'rgb(245, 243, 243)',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <DateRangeIcon
                    style={{width: '50px', fontSize: '30px', color: 'rgb(255, 118, 63)'}}
                  />
                  <span className='text-500 fw-bold fs-6'>Durations(5weeks)</span>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div className='col-md-8'>
          <div className='row'>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              {coursedetails && coursedetails.data && (
                <div className='pt-20'>
                  <h3>{coursedetails.data.title}</h3>
                  {localStorage.setItem('courseId', coursedetails.data.id)}
                </div>
              )}

              {/* localStorage.setItem('courseId', cdata.id) */}
              <div className='pt-20'>
                <Button
                  className='btn btn-sm fw-bold'
                  onClick={showModal}
                  shape='square'
                  size='large'
                  icon={<UploadOutlined />}
                  style={{background: 'rgb(255, 118, 63)', color: 'white'}}
                >
                  Add Chapter
                </Button>

                <div>
                  <Modal
                    title='+ Add Chapter'
                    centered
                    open={isModalOpen}
                    onCancel={handleCancel}
                    footer={null}
                  >
                    <Createchapter />
                  </Modal>
                </div>
              </div>
            </div>

            <div className='row' style={{paddingTop: '5rem'}}>
              <h3>Getting Start</h3>
            </div>

            <div>
              {Object.entries(getallChapter).map(([key, course], i) => {
                if (key == 'data') {
                  cdata = course
                }
              })}

              {Object.entries(cdata).map(([key, item], i) => {
                localStorage.setItem('chapter_slug', item.chapter_slug)

                return (
                  <div key={item.id} style={itemStyle}>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <div>
                        <FormatAlignLeftOutlinedIcon />
                      </div>
                      <div>
                        <Link to={`/getall_lessons/${item.chapter_slug}`}>
                          <span style={{color: 'black', fontSize: '15px', fontWeight: '370'}}>
                            {item.chaptername}
                            {/* {item.id} */}
                            {/* <span style={{color: 'red'}}>{item.chapter_slug}</span> */}
                          </span>
                        </Link>
                      </div>
                    </div>

                    <div style={{gap: '10px', display: 'flex'}}>
                      <button
                        style={{border: '0px'}}
                        onClick={() => {
                          handleBookButtonClick(item.chapter_slug)
                          localStorage.setItem('selectedInstructorIssssssssdid', item.chapter_slug)
                        }}
                      >
                        <FaPlus />
                      </button>

                      <EditChapter onEditButtonClick={handleEditButtonClick} />

                      <button style={{border: '0px'}}>
                        <span>
                          <DeleteIcon onClick={() => DeleteChapter(item.chapter_slug)} />
                        </span>
                      </button>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>

      <div>
        {/* <div>
        <Link to='#' onClick={()=> setShow(true)}>
          <span className='text float-right  border-radius  p-0' style={{color: 'black'}}>
            <CreateIcon />
          </span>
        </Link>
      </div> */}

        <Modal show={show} onHide={handleCancel} centered>
          <Modal.Header closeButton>
            <Modal.Title
              style={{
                textTransform: 'capitalize',
                letterSpacing: '.2rem',
                fontWeight: 500,
                fontSize: '1rem',
              }}
            >
              Edit Chapter
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                padding: '2rem',
                borderRadius: '.375rem',
              }}
            >
              <div>
                <label>chaptername</label>
                <input
                  className='form-control mb-4'
                  type='text'
                  name='chaptername' // Make sure this matches the name in setValue
                  {...register('chaptername', {
                    required: 'chaptername Address is required',
                  })}
                  aria-invalid={errors.chaptername ? 'true' : 'false'}
                />
              </div>

              <div>
                <label>desc</label>
                <input
                  className='form-control mb-4'
                  type='text'
                  name='desc'
                  {...register('desc', {
                    required: 'desc Address is required',
                  })}
                  aria-invalid={errors.desc ? 'true' : 'false'}
                />
                {errors.desc && (
                  <p className='alert_danger' role='alert' style={{marginTop: '1rem'}}>
                    {errors.desc?.message}
                  </p>
                )}
              </div>
              <Button
                style={{
                  textAlign: 'right',
                  width: ' 100%',
                  color: '#ffff',
                  fontSize: '.8rem',
                  borderRadius: '.375rem',
                  backgroundColor: 'rgb(81, 86, 190)',
                  textTransform: 'capitalize',
                  letterSpacing: '.2rem',
                }}
                type='submit'
                className=' btn-warning'
                disableRipple
              >
                save
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  )
}

export default CourseDetails
