import swal from 'sweetalert'
import axios from 'axios'

import 'react-toastify/dist/ReactToastify.css'
import {getAllPaymentslice} from '../Slice/authSlice'

export const GetallPaymentAction = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getallpayment = process.env.REACT_APP_BACKEND_API + '/getpaymentdetails'
    const res = await axios.get(getallpayment, '', config)

    await dispatch(getAllPaymentslice(res))
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e?.response?.data?.message, 'error')
    }
  }
}
