import React, {useEffect, useState} from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import {Link, useNavigate} from 'react-router-dom'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {useDispatch, useSelector} from 'react-redux'
import * as CountryStateCity from 'country-state-city'
import {
  GetInstructorAdminList,
  DeleteInstructorAction,
} from '../../../Redux/Action/instructorAction'
import AddIcon from '@mui/icons-material/Add'

const AdminInstructorUserList = () => {
  const dispatch = useDispatch()

  const navigate = useNavigate()
  const user = useSelector((state) => {
    return state.login.getaInstructorAdminlist
  })

  const [countryNames, setCountryNames] = useState({})
  const [stateNames, setStateNames] = useState({})

  useEffect(() => {
   
    const fetchCountryAndStateNames = () => {
      const uniqueCountryCodes = [...new Set(user.map((item) => item.Country))]
      const uniqueStateCodes = [...new Set(user.map((item) => item.state))]

      const fetchedCountryNames = {}
      const fetchedStateNames = {}

      uniqueCountryCodes.forEach((code) => {
        const country = CountryStateCity.Country.getCountryByCode(code)
        fetchedCountryNames[code] = country ? country.name : code
      })

      uniqueStateCodes.forEach((code) => {
        const state = CountryStateCity.State.getStateByCode(code)
        fetchedStateNames[code] = state ? state.name : code
      })

      setCountryNames(fetchedCountryNames)
      setStateNames(fetchedStateNames)
    }

    fetchCountryAndStateNames()
  }, [user])

  useEffect(() => {
    dispatch(GetInstructorAdminList())
  }, [dispatch])

  const deleteHandler = (props) => {
    dispatch(DeleteInstructorAction(props))
  }

  function nextPage() {
    if (currentPage !== nPage) {
      setCurrentPage(currentPage + 1)
    }
  }

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  function changeCPage(id) {
    setCurrentPage(id)
  }

  const [currentPage, setCurrentPage] = useState(1)

  const recordsPerPage = 20

  const lastIndex = currentPage * recordsPerPage

  const firstIndex = lastIndex - recordsPerPage

  const records = user.slice(firstIndex, lastIndex)

  const nPage = Math.ceil(user.length / recordsPerPage)

  const number = [...Array(nPage + 1).keys()].slice(1)

  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className='instructor-list'>
              <div className='user-list'>
                <div id='kt_app_content_container'>
                  <div className='card'>
                    <div className='card-header border-0 pt-6'>
                      <div className='card-title'>
                        <div className='d-flex align-items-center position-relative my-1'>
                          <input
                            type='text'
                            data-kt-user-table-filter='search'
                            className='form-control form-control-solid w-250px ps-14'
                            placeholder='Search user'
                          />
                        </div>
                      </div>

                      <div className='card-toolbar'>
                        <div
                          className='d-flex justify-content-end'
                          data-kt-user-table-toolbar='base'
                        >
                          <div
                            className='menu menu-sub menu-sub-dropdown w-300px w-md-325px'
                            data-kt-menu='true'
                          >
                            <div className='separator border-gray-200'></div>

                            <div className='px-7 py-5' data-kt-user-table-filter='form'>
                              <div className='mb-10'>
                                <label className='form-label fs-6 fw-semibold'>Role:</label>
                                <select
                                  className='form-select form-select-solid fw-bold'
                                  data-kt-select2='true'
                                  data-placeholder='Select option'
                                  data-allow-clear='true'
                                  data-kt-user-table-filter='role'
                                  data-hide-search='true'
                                >
                                  <option></option>
                                  <option value='Administrator'>Administrator</option>
                                  <option value='Analyst'>Analyst</option>
                                  <option value='Developer'>Developer</option>
                                  <option value='Support'>Support</option>
                                  <option value='Trial'>Trial</option>
                                </select>
                              </div>

                              <div className='mb-10'>
                                <label className='form-label fs-6 fw-semibold'>
                                  Two Step Verification:
                                </label>
                                <select
                                  className='form-select form-select-solid fw-bold'
                                  data-kt-select2='true'
                                  data-placeholder='Select option'
                                  data-allow-clear='true'
                                  data-kt-user-table-filter='two-step'
                                  data-hide-search='true'
                                >
                                  <option></option>
                                  <option value='Enabled'>Enabled</option>
                                </select>
                              </div>

                              <div className='d-flex justify-content-end'>
                                <button
                                  type='reset'
                                  className='btn btn-light btn-active-light-primary fw-semibold me-2 px-6'
                                  data-kt-menu-dismiss='true'
                                  data-kt-user-table-filter='reset'
                                >
                                  Reset
                                </button>
                                <button
                                  type='submit'
                                  className='btn btn-primary fw-semibold px-6'
                                  data-kt-menu-dismiss='true'
                                  data-kt-user-table-filter='filter'
                                >
                                  Apply
                                </button>
                              </div>
                            </div>
                          </div>
                          <Link to='/adduser'>
                            <button
                              type='button'
                              className='btn cyberbtnallpage'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_add_user'
                            >
                              <AddIcon /> Add User
                            </button>
                          </Link>
                        </div>

                        <div
                          className='d-flex justify-content-end align-items-center d-none'
                          data-kt-user-table-toolbar='selected'
                        >
                          <div className='fw-bold me-5'>
                            <span
                              className='me-2'
                              data-kt-user-table-select='selected_count'
                            ></span>
                            Selected
                          </div>
                          <button
                            type='button'
                            className='btn btn-danger'
                            data-kt-user-table-select='delete_selected'
                          >
                            Delete Selected
                          </button>
                        </div>

                        <div
                          className='modal fade'
                          id='kt_modal_export_users'
                          tabindex='-1'
                          aria-hidden='true'
                        >
                          <div className='modal-dialog modal-dialog-centered mw-650px'>
                            <div className='modal-content'>
                              <div className='modal-header'>
                                <h2 className='fw-bold'>Export Users</h2>

                                <div
                                  className='btn btn-icon btn-sm btn-active-icon-primary'
                                  data-kt-users-modal-action='close'
                                >
                                  <span className='svg-icon svg-icon-1'>
                                    <svg
                                      width='24'
                                      height='24'
                                      viewBox='0 0 24 24'
                                      fill='none'
                                      xmlns='http://www.w3.org/2000/svg'
                                    >
                                      <rect
                                        opacity='0.5'
                                        x='6'
                                        y='17.3137'
                                        width='16'
                                        height='2'
                                        rx='1'
                                        transform='rotate(-45 6 17.3137)'
                                        fill='currentColor'
                                      />
                                      <rect
                                        x='7.41422'
                                        y='6'
                                        width='16'
                                        height='2'
                                        rx='1'
                                        transform='rotate(45 7.41422 6)'
                                        fill='currentColor'
                                      />
                                    </svg>
                                  </span>
                                </div>
                              </div>

                              <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                                <form id='kt_modal_export_users_form' className='form' action='#'>
                                  <div className='fv-row mb-10'>
                                    <label className='fs-6 fw-semibold form-label mb-2'>
                                      Select Roles:
                                    </label>

                                    <select
                                      name='role'
                                      data-control='select2'
                                      data-placeholder='Select Link role'
                                      data-hide-search='true'
                                      className='form-select form-select-solid fw-bold'
                                    >
                                      <option></option>
                                      <option value='Teacher'>Teacher</option>
                                      <option value='Instructor'>Instructor</option>
                                      <option value='Employee'>Employee</option>
                                      <option value='Content Creater'>Content Creater</option>
                                    </select>
                                  </div>

                                  <div className='fv-row mb-10'>
                                    <label className='required fs-6 fw-semibold form-label mb-2'>
                                      Select Export Format:
                                    </label>

                                    <select
                                      name='format'
                                      data-control='select2'
                                      data-placeholder='Select Link format'
                                      data-hide-search='true'
                                      className='form-select form-select-solid fw-bold'
                                    >
                                      <option></option>
                                      <option value='excel'>Excel</option>
                                      <option value='pdf'>PDF</option>
                                      <option value='cvs'>CVS</option>
                                      <option value='zip'>ZIP</option>
                                    </select>
                                  </div>

                                  <div className='text-center'>
                                    <button
                                      type='reset'
                                      className='btn cybercancelbtn me-3'
                                      data-kt-users-modal-action='cancel'
                                    >
                                      Discard
                                    </button>
                                    <button
                                      type='submit'
                                      className='btn btn-primary cyberbtnallpage'
                                      data-kt-users-modal-action='submit'
                                    >
                                      <span className='indicator-label'>Submit</span>
                                      <span className='indicator-progress'>
                                        Please wait...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                      </span>
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='card-body py-4'>
                      <table
                        className='table align-middle table-row-dashed fs-6 gy-5'
                        id='kt_table_users'
                      >
                        <thead>
                          <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                            <th className='w-10px pe-2'>
                              <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  data-kt-check='true'
                                  data-kt-check-target='#kt_table_users .form-check-input'
                                  value='1'
                                />
                              </div>
                            </th>

                            <th className='min-w-125px'>username</th>
                            <th className='min-w-125px'>Email</th>
                            <th className='min-w-125px'>PhoneNumber</th>
                            <th className='min-w-125px'>Country</th>
                            <th className='min-w-125px'>State</th>
                            <th className='min-w-125px'>City</th>
                            <th className='text-end min-w-100px'>Actions</th>
                          </tr>
                        </thead>

                        <tbody className='text-gray-600 fw-semibold'>
                          {records.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                  <input className='form-check-input' type='checkbox' value='1' />
                                </div>
                              </td>

                              <td className='d-flex align-items-center'>
                                <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                                  <Link to='#'>
                                    <div className='symbol-label'>
                                      <img src={item.profileUrl} alt='Emma Smith' class='w-100' />
                                    </div>
                                  </Link>
                                </div>

                                <div className='d-flex flex-column'>
                                  <span>{item.FirstName}</span>
                                </div>
                              </td>

                              <td>{item.Email}</td>

                              <td>
                                <div className='badge badge-light fw-bold'>{item.PhoneNumber}</div>
                              </td>
                              {item.Country === 'undefined' ? (
                                <td></td>
                              ) : (
                                <td>{countryNames[item.Country] || item.Country}</td>
                              )}
                              {item.state === 'undefined' ? (
                                <td></td>
                              ) : (
                                <td>{stateNames[item.state] || item.state}</td>
                              )}
                              {item.City === 'undefined' ? <td></td> : <td>{item.City}</td>}

                              <td className='text-end'>
                                <div className='dropdown'>
                                  <button className='btn btn-light btn-active-light-primary btn-sm'>
                                    Actions <KeyboardArrowDownIcon />
                                  </button>

                                  <div
                                    className='dropdown-content'
                                    style={{padding: '0.65rem 0.5rem'}}
                                  >
                                    <div className='text-center' >
                                      <button
                                        className='border-0'
                                        style={{background:"none"}}
                                        onClick={() => navigate(`/edit_Instructor/${item.insid}`)}
                                      >
                                        Edit
                                      </button>
                                    </div>
                                    <div
                                      style={{color: '#333', padding: '10px 8px'}}
                                    >
                                      <button
                                        className='border-0'
                                        style={{ width: '96px',background:"none"}}
                                        onClick={() => navigate(`/viewDetails/${item.insid}`)}
                                      >
                                        View Details
                                      </button>
                                    </div>
                                    <div >
                                      <Link
                                        to='#'
                                        className='text-center'
                                        onClick={() => deleteHandler(item.insid)}
                                      >
                                        Delete
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
                                  data-kt-menu='true'
                                >
                                  <div className='menu-item px-3'></div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <nav>
                        <ul className='pagination'>
                          <li className='page-item'>
                            <Link to='#' className='page-link' onClick={prePage}>
                              Prev
                            </Link>
                          </li>
                          {number.map((n, i) => (
                            <li
                              className={`page-item ${currentPage === n ? 'active' : ''}`}
                              key={i}
                            >
                              <Link to='#' className='page-link' onClick={() => changeCPage(n)}>
                                {n}
                              </Link>
                            </li>
                          ))}
                          <li className='page-item'>
                            <Link to='#' className='page-link' onClick={nextPage}>
                              Next
                            </Link>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default AdminInstructorUserList
