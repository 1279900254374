import swal from 'sweetalert'
import axios from 'axios'

import 'react-toastify/dist/ReactToastify.css'

import {getcareersslice, getcareerbyidslice, getAllPublishedCareersslice} from '../Slice/authSlice'

export const CreateCareerAction = (formData, navigate) => async (dispatch) => {
  try {
    // debugger
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',

        Authorization: `Bearer ${token}`,
      },
    }

    const createcourse = process.env.REACT_APP_BACKEND_API + '/createCareer'

    const res = await axios.post(createcourse, formData, config)

    if (res.status === 200) {
      swal({
        title: 'Success',
        text: 'Career Created',
        icon: 'success',
        buttons: {
          cancel: {
            text: 'OK',
            value: null,
            visible: true,
            className: 'swal-button--confirm cyberbtnallpage', 
          },
        },
      })
      navigate('/allWebCareers')
    }
    dispatch(res())
  } catch (e) {
    // console.log(e)
  }
}
export const GetCareerAction = (careerId) => async (dispatch) => {
  try {
    // debugger
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getcoursebyid = `${process.env.REACT_APP_BACKEND_API}/getCareer/${careerId}`

    const res = await axios.get(getcoursebyid, config)

    await dispatch(getcareerbyidslice(res))
  } catch (e) {
    // console.log(e)
  }
}

export const EditCareerAction = (formData, careerId, navigate) => async (dispatch) => {
  try {
    // debugger

    console.log(careerId)
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const updateCourseid = `${process.env.REACT_APP_BACKEND_API}/editCareer/${careerId}`
    const res = await axios.put(updateCourseid, formData, config)
    // console.log(res)

    if (res.status === 200) {
      swal({
        title: 'Success',
        text: 'Career Updated',
        icon: 'success',
        buttons: {
          cancel: {
            text: 'OK',
            value: null,
            visible: true,
            className: 'swal-button--confirm cyberbtnallpage', 
          },
        },
      })
      navigate('/allWebCareers')
    }
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e.response.data.message, 'error')
    }
  }
}

export const GetallCareersAction = () => async (dispatch) => {
  try {
    // debugger;
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getCareers = process.env.REACT_APP_BACKEND_API + '/getAllCareers'
    const res = await axios.get(getCareers, config)

    await dispatch(getcareersslice(res))
    // console.log(res)
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e?.response?.data?.message, 'error')
    }
  }
}

export const GetAllPublishedCareersAction = () => async (dispatch) => {
  try {
    // debugger;
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getAllPublishedCareers = process.env.REACT_APP_BACKEND_API + '/getAllPublishedCareers'
    const res = await axios.get(getAllPublishedCareers, config)

    await dispatch(getAllPublishedCareersslice(res))
    // console.log(res)
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e?.response?.data?.message, 'error')
    }
  }
}

export const CareerDeleteAction = (careerId) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const confirmResult = await swal({
      title: 'Delete Web Career',
      text: 'Are you sure you want to delete this?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          value: null,
          visible: true,
          className: 'swal-button--cancel cybercancelbtn', 
        },
        confirm: {
          text: 'Delete',
          value: true,
          visible: true,
          className: 'swal-button--confirm swal-button--danger cyberbtnallpage', 
        },
      },
      dangerMode: true,
    })
    if (confirmResult) {
      const deletecourse = `${process.env.REACT_APP_BACKEND_API}/deleteCareer/${careerId}`
      const res = await axios.delete(deletecourse, config)

      if (res.status === 200) {
        swal({
          title: 'Success',
          text: 'Career Deleted',
          icon: 'success',
          buttons: {
            cancel: {
              text: 'OK',
              value: null,
              visible: true,
              className: 'swal-button--confirm cyberbtnallpage', 
            },
          },
        }).then(() => {
          window.location.reload()
        })
      }
    }
  } catch (e) {
    // console.log(e)
  }
}
