import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {
  AllCoursesCount,
  PublishCoursesCount,
  DraftCoursesCount,
} from '../../../../Redux/Action/admindashboardAction'

import {ImBooks} from 'react-icons/im'

const Totalcourse = () => {
  const dispatch = useDispatch()
  const coursesCount = useSelector((state) => {
    // debugger;
    return state.login.getCoursesCountList
  })

  useEffect(() => {
    dispatch(AllCoursesCount())
  }, [dispatch])

  return (
    <div>
      <div className='dashboards_userlist' style={{marginRight: '6px'}}>
        <div className='card card-flush h-md-100 mb-5 mb-xl-10'>
          <div className='card-header pt-5 pb-4' style={{borderLeft: '3px solid #FF763F'}}>
            <div className='card-title d-flex flex-column'>
              <div className='d-flex align-items-center'>
                <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>
                  {' '}
                  {coursesCount.totalCount}
                </span>

                <span className='badge badge-light-success fs-base'></span>
              </div>
              <span className='text-gray-400 pt-1 fw-semibold fs-2'>Total Single Courses</span>
              <div className='d-flex fw-semibold align-items-center'>
                <div className='bullet w-8px h-3px rounded-2 bg-grey me-3'></div>
                <div className='text-gray-500 flex-grow-1 me-4'>Published</div>
                <div className='fw-bolder text-gray-700 text-xxl-end'>
                  {' '}
                  {coursesCount.publishCount}
                </div>
              </div>
              <div className='d-flex fw-semibold align-items-center'>
                <div className='bullet w-8px h-3px rounded-2 bg-grey me-3'></div>
                <div className='text-gray-500 flex-grow-1 me-4'>Draft</div>
                <div className='fw-bolder text-gray-700 text-xxl-end'>
                  {' '}
                  {coursesCount.draftCount}
                </div>
              </div>
            </div>
            <div>
              <ImBooks style={{fontSize: '3.5rem', marginTop: '100px', color: '#FF763F'}} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Totalcourse
