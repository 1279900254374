import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher} from '../../../partials'
import {useLayout} from '../../core'
import axios from 'axios'
import {Tooltip} from 'antd'
import {Link} from 'react-router-dom'
import {HiOutlineMail} from 'react-icons/hi'
import {BsQuestionLg} from 'react-icons/bs'
import admin from '../../../../assets/images/default.png'
import {RiGraduationCapFill} from 'react-icons/ri'

const itemClass = 'ms-1 ms-lg-3'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'fs-1'

const Navbar = () => {
  const {config} = useLayout()
  const studentid = localStorage.getItem('userid')
  interface UserDetails {
    id: number
    PrefixMrMissMrs: string
    FirstName: string
    Surname: string
    Email: string
    PhoneNumber: string
    Token: string
    Country: string
    City: string
    state: string
    timezone: string
    roleid: number
    AgeBracket: string
    YearsOfCyberSecurityExperience: string
    EducationLevel: string
    PurposeOfTakingTheCourse: string
    InterestedInCyberheadRole: string
    EmploymentStatus: string
    Password: string
    is_verified: number
    Role: string
    Rating: null | number
    HowYouGotToKnow: string
    profilePic: string
    profileUrl: string
    fileName: null | string
    fileUrl: null | string
    fileKey: null | string
    courseCreate: null | string
    courseRead: null | string
    courseUpdate: null | string
    courseDelete: null | string
    payCreate: null | string
    payRead: null | string
    payUpdate: null | string
    payDelete: null | string
    roleCreate: null | string
    roleRead: null | string
    roleUpdate: null | string
    roleDelete: null | string
    ltiCreate: null | string
    ltiRead: null | string
    ltiUpdate: null | string
    ltiDelete: null | string
    userStatus: null | string
  }
  const [userdetails, setUserDetails] = useState<UserDetails | null>(null)

  useEffect(() => {
    const token = localStorage.getItem('authToken')

    // Include the token in the headers
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }

    axios
      .get(`https://backend.cyberheads.in/getUser/${studentid}`, {headers})
      .then((response) => {
        setUserDetails(response.data.message)
      })
      .catch((error) => {
        console.error('Error fetching user details:', error)
      })
  }, [])

  if (!userdetails) {
    return <div>Loading...</div>
  }
  const roleid = localStorage.getItem('roleid')

  // Check if roleId is equal to 3
  const shouldRenderSection = roleid === '3'

  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item', itemClass)}>
        {shouldRenderSection && (
          <Link
            to='/course'
            title='Buy Courses'
            className='btn btn-icon btn-custom btn-icon-muted w-35px h-35px nvIcons'
            style={{
              color: ' #fff',
              borderRadius: '50%',
              border: '1px solid #ff763f',
              background: '#ff763f',
            }}
          >
            <RiGraduationCapFill style={{fontSize: '20px'}} />{' '}
          </Link>
        )}
      </div>
      <div className={clsx('app-navbar-item', itemClass)}>
        {' '}
        <a
          href='mailto:admin@cyberheads.com'
          className='btn btn-icon btn-custom btn-icon-muted  w-35px h-35px nvIcons'
          style={{
            color: ' #fff',
            borderRadius: '50%',
            border: '1px solid #ff763f',
            background: '#ff763f',
          }}
        >
          <HiOutlineMail style={{fontSize: '20px'}} title='Contact Us' />{' '}
        </a>
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        {' '}
        <Link
          to='/faq'
          title='FAQ'
          className='btn btn-icon btn-custom btn-icon-muted w-35px h-35px nvIcons'
          style={{
            color: ' #fff',
            borderRadius: '50%',
            border: '1px solid #ff763f',
            background: '#ff763f',
          }}
        >
          <BsQuestionLg style={{fontSize: '20px'}} />{' '}
        </Link>
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        {' '}
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          {userdetails.profileUrl ? (
            <img
              src={userdetails.profileUrl ? userdetails.profileUrl : userdetails.profilePic}
              alt='profile'
              title='My Profile'
              style={{
                borderRadius: '50%',
                border: '2px solid #ff763f',
              }}
              className='nvIcons'
            />
          ) : (
            // Display a default image if profileUrl is null
            <img src={admin} 
            alt='Default Profile'
            title='My Profile'
               className='nvIcons'  style={{
              borderRadius: '50%',
              border: '2px solid #ff763f',
            }}/>
          )}
        </div>
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        // <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
        //   <div
        //     className='btn btn-icon btn-active-color-primary w-35px h-35px'
        //     id='kt_app_header_menu_toggle'
        //   >
        //     <KTIcon iconName='text-align-left' className={btnIconClass} />
        //   </div>
        // </div>
        <div></div>
      )}
    </div>
  )
}

export {Navbar}
