import React, { useState, useEffect } from 'react'
import JoditEditor from 'jodit-react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import { GetLabAction, EditLabAction } from '../../../Redux/Action/challengeLabAction'
import { useForm, Controller } from 'react-hook-form'
import Loader from '../../../components/Loader/loader'

const AddChallengeLab = ({ placeholder }) => {
  const params = useParams()
  const labID = params.id
  const navigate = useNavigate()
  const dispatch = useDispatch()
 
  const [loading, setLoading] = useState(false)
  const [description, Setdescription] = useState('')
  const [Learnings, SetLearnings] = useState('')
  const [Delivery_Mechanism, SetDelivery_Mechanism] = useState('')
  const [resources, SetResources] = useState('')
  const [duration, SetDuration] = useState('')

  const { 
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm()

  const onSubmit = (data) => {
debugger
    const formData = new FormData()
    setLoading(true)
console.log(data.learnings,"kkkkkkkkkkkk")
    formData.append('title', data.title)
    formData.append('description', data.description)
    formData.append('status', data.status)
    formData.append('vouchers', data.vouchers)
    formData.append('handsonlabs', data.handsonlabs)
    formData.append('learnings', data.learnings)
    formData.append('Delivery_Mechanism', data.Delivery_Mechanism)
    formData.append('resources', data.resources)
    formData.append('duration', data.duration)

    dispatch(EditLabAction(formData, labID, navigate, setLoading))
  }

  useEffect(() => {
    dispatch(GetLabAction(labID))
  }, [dispatch])

  const labDetail = useSelector((state) => state.login.getLabByIdList)

  useEffect(() => {
    if (labDetail?.labs) {
      const data = labDetail.labs 
 console.log(data,"data.vouchersdata.vouchers")
 console.log(data.handsonlabs,"data.handsonlabsdata.handsonlabs")
      setValue('title', data.title)
      setValue('status', data.status)
      setValue('Delivery_Mechanism', data.Delivery_Mechanism)
      setValue('resources', data.resources)
      setValue('learnings', data.learnings)
      setValue('duration', data.duration)
      setValue('description', data.description)
      setValue('vouchers', (data.voucher === 'Yes') ? true : false)
      setValue('handsonlabs', (data.handsonlab === 'Yes')? true : false)
     
    }
  }, [labDetail])


  return (
    <div>
      <div id='kt_app_content' class='app-content flex-column-fluid'>
        {loading && (
          <div className='loader-container'>
            <Loader />
          </div>
        )}
        <form
          id='kt_ecommerce_add_product_form'
          class='form d-flex flex-column flex-lg-row'
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className='course_category col-md-4'>
            <div class='d-flex flex-column gap-7 gap-lg-10 w-100 mb-7 me-lg-10'>


              <div class='card card-flush py-4'>
                <div class='card-header'>
                  <div class='card-title'>
                    <h2>Status</h2>
                  </div>
                </div>

                <div class='card-body pt-0'>
                  <select
                    class='form-select mb-2'
                    data-control='select2'
                    data-hide-search='true'
                    data-placeholder='Select an option'
                    {...register('status', {
                      required: true,
                    })}
                  >
                    aria-invalid={errors.status ? 'true' : 'false'}
                    <option value='Published'>Published</option>
                    <option value='Draft' selected='selected' default>
                      Draft
                    </option>
                  </select>
                  {errors.status?.status === 'required' && (
                    <p style={{ color: 'red' }} role='alert'>
                      status is required
                    </p>
                  )}
                </div>
              </div>
              <div class='card card-flush py-4'>
                <div class='card-header'>
                  <div class='card-title'>
                    <h2>Features</h2>
                  </div>
                </div>

                <div class='card-body pt-0'>
                  <input
                    type='checkbox'
                    id='vouchersCheckbox'
                    name='vouchers'
                    //defaultChecked={true}
                    value='yes'
                    defaultChecked ={watch('vouchers') === 'Yes'}
                    {...register('vouchers', {
                      required: false,
                    })}
                  />
                  <label htmlFor='vouchersCheckbox' style={{ marginLeft: '5px' }}>
                    Exam vouchers
                  </label>
                  {errors.vouchers?.vouchers === 'required' && (
                    <p style={{ color: 'red' }} role='alert'>
                      Vouchers is required
                    </p>
                  )}
                  <br />
                  <input
                    type='checkbox'
                    id='handsonlabsCheckbox'
                    name='handsonlabs'
                    //defaultChecked={true}
                    defaultChecked ={watch('handsonlabs') === 'Yes'}
                    {...register('handsonlabs', {
                      required: false,
                    })}
                  />

                  <label htmlFor='handsonlabsCheckbox' style={{ marginLeft: '5px' }}>
                    Hands-On Lab Training
                  </label>
                  {errors.handsonlabs?.handsonlabs === 'required' && (
                    <p style={{ color: 'red' }} role='alert'>
                      This field is required
                    </p>
                  )}

                </div>
              </div>
            </div>
          </div>
          <div className='add_course_section_snd col-md-8'>
            <div class='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>

              <div class='d-flex flex-column gap-7 gap-lg-10'>
                <div class='card card-flush py-4'>
                  <div class='card-body pt-0'>
                    <div class='mb-10 fv-row'>
                      <label class='required form-label'>Title</label>

                      <input
                        type='text'
                        name='title'
                        class='form-control mb-2'
                        placeholder='Course name'
                        aria-invalid={errors.title ? 'true' : 'false'}
                        {...register('title', { required: true })}
                      />
                      {errors.title?.type === 'required' && (
                        <p style={{ color: 'red' }} role='alert'>
                          Tile is required
                        </p>
                      )}
                    </div>
                    <div>
                      <label class='form-label'>Delivery Mechanism:</label>

                      <Controller
                        control={control}
                        name='Delivery_Mechanism'
                        defaultValue=''
                        rules={{ required: true }}
                        render={({ field }) => {
                          return (
                            <JoditEditor
                              ref={field.ref}
                              value={field.value}
                              placeholder='start typing'
                              aria-invalid={errors.Delivery_Mechanism ? 'true' : 'false'}
                              tabIndex={1} // tabIndex of textarea
                              //onBlur={(newContent) => SetDelivery_Mechanism(newContent)}
                              onBlur={(newContent) => {
                                if(newContent === '<p><br></p>')
                                newContent =''
                                field.onChange(newContent);
                                SetDelivery_Mechanism(newContent)
                              }}
                              onChange={newContent => {}}
                            />
                          )
                        }}
                      />
                      {errors.Delivery_Mechanism?.type === 'required' && (
                        <p style={{ color: 'red' }} role='alert'>
                          This field is required
                        </p>
                      )}

                    </div>
                    <div>
                      <label class='form-label'>Resources:</label>

                      <Controller
                        control={control}
                        name='resources'
                        defaultValue=''
                        rules={{ required: true }}
                        render={({ field }) => {
                          return (
                            <JoditEditor
                              ref={field.ref}
                              value={field.value}
                              placeholder='Start typing'
                              aria-invalid={errors.resources ? 'true' : 'false'}
                              tabIndex={1} // tabIndex of textarea
                              onBlur={(newContent) => {
                                if(newContent === '<p><br></p>')
                                newContent =''
                                field.onChange(newContent);
                                SetResources(newContent)
                              }}
                              onChange={newContent => {}}
                            />
                          )
                        }}
                      />
                      {errors.resources?.type === 'required' && (
                        <p style={{ color: 'red' }} role='alert'>
                          This field is required
                        </p>
                      )}

                    </div>
                    <div>
                      <label class='form-label'>Duration:</label>

                      <Controller
                        control={control}
                        name='duration'
                        defaultValue=''
                        rules={{ required: true }}
                        render={({ field }) => {
                          return (
                            <JoditEditor
                              ref={field.ref}
                              value={field.value}
                              placeholder='Start typing'
                              aria-invalid={errors.duration ? 'true' : 'false'}
                              tabIndex={1} // tabIndex of textarea
                              //onBlur={(newContent) => SetDuration(newContent)}
                              onBlur={(newContent) => {
                                if(newContent === '<p><br></p>')
                                newContent =''
                                field.onChange(newContent);
                                SetDuration(newContent)
                              }}
                              onChange={newContent => {}}
                            />
                          )
                        }}
                      />
                      {errors.duration?.type === 'required' && (
                        <p style={{ color: 'red' }} role='alert'>
                          This field is required
                        </p>
                      )}

                    </div>
                    <div>
                      <label class='form-label'>Overview</label>

                      <Controller
                        control={control}
                        name='description'
                        defaultValue=''
                        rules={{ required: true }}
                        render={({ field }) => {
                          return (
                            <JoditEditor
                              ref={field.ref}
                              value={field.value}
                              placeholder='start typing'
                              aria-invalid={errors.description ? 'true' : 'false'}
                              tabIndex={1} // tabIndex of textarea
                             // onBlur={(newContent) => Setdescription(newContent)}
                              onBlur={(newContent) => {
                                if(newContent === '<p><br></p>')
                                newContent =''
                                field.onChange(newContent);
                                Setdescription(newContent)
                              }}
                              onChange={newContent => {}}
                            />
                          )
                        }}
                      />
                      {errors.description?.type === 'required' && (
                        <p style={{ color: 'red' }} role='alert'>
                          Description is required
                        </p>
                      )}

                    </div>


                    <div>
                      <label class='form-label'> What’s included: </label>

                      <Controller
                        control={control}
                        name='learnings'
                        defaultValue=''
                        rules={{ required: true }}
                        render={({ field }) => {
                          return (
                            <JoditEditor
                              ref={field.ref}
                              value={field.value}
                              placeholder='start typing'
                              aria-invalid={errors.learnings ? 'true' : 'false'}
                              tabIndex={1} // tabIndex of textarea
                             // onBlur={(newContent) => SetLearnings(newContent)}
                              onBlur={(newContent) => {
                                if(newContent === '<p><br></p>')
                                newContent =''
                                field.onChange(newContent);
                                SetLearnings(newContent)
                              }}
                              onChange={newContent => {}}
                            />
                          )
                        }}
                      />
                      {errors.learnings?.type === 'required' && (
                        <p style={{ color: 'red' }} role='alert'>
                          This field is required
                        </p>
                      )}

                    </div>

                  </div>
                </div>
              </div>

              <div class='d-flex justify-content-end'>
                <Link
                  to='/AllChallengeLabs'
                  id='kt_ecommerce_add_product_cancel'
                  class='btn cybercancelbtn me-5'
                >
                  Cancel
                </Link>

                <button
                  type='submit'
                  id='kt_ecommerce_add_product_submit'
                  className='btn cyberbtnallpage'
                >
                  <span class='indicator-label'>Save Changes</span>
                  <span class='indicator-progress'>
                    Please wait...
                    <span class='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddChallengeLab
