import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useForm} from 'react-hook-form'
import {
  InstructorCoursesInvoiceAction,
  InstructorInvoiceUpdateAction,
  GenerteInvoiceAction,
  InstructorCoursesComplitionUpdateAction,
  GetInstructorbyId,
  FeedbackAction,
} from '../../../Redux/Action/instructorAction'
import './instructorDashboardMain.css'
import {Modal} from 'antd'
import {AiOutlineLeft, AiOutlineRight} from 'react-icons/ai'
import {getParticipantsAction} from '../../../Redux/Action/authAction'

import {CopyToClipboard} from 'react-copy-to-clipboard'

import {FaFilePdf} from 'react-icons/fa'

const Deliveredclasses = () => {
  const instid = localStorage.getItem('userid')

  const dispatch = useDispatch()
  const [isInvoiceClicked, setIsInvoiceIsClicked] = useState([])

  const instructorInvoiceCourse = useSelector((state) => {
    return state.login.getInstructorInvoiceCoursesList
  })

  const classes = instructorInvoiceCourse.deliveredCourses

  const instructorInvoiceCourses = classes || []

  const [selectedCourseId, setSelectedCourseId] = useState(null)

  const handleCourseSelection = (courseId) => {
    setSelectedCourseId(courseId)
  }

  useEffect(() => {
    dispatch(InstructorCoursesInvoiceAction(instid))
  }, [dispatch])

  const handleInvoiceClick = (invoice) => {
    let invoiceClick = isInvoiceClicked.slice()

    invoiceClick[invoice.id] = true
    setIsInvoiceIsClicked(invoiceClick)
  }

  const {
    // register,
    // handleSubmit,

    formState: {errors},
  } = useForm()

  const calculateSubtotal = (daysWorkedValue, dayRate) => {
    return daysWorkedValue * dayRate
  }

  const calculateTax = (subtotal, paymentType) => {
    return subtotal * (paymentType === 'local' ? 0.2 : 0)
  }

  const calculateTotalAmount = (subtotal, tax) => {
    return subtotal + tax
  }

  const onSubmits = (coursedata) => {
    const formData = new FormData()
    const rsideDate = new Date()
    const course = coursedata.Course_id

    formData.append('Course_id', course)
    formData.append('raisedate', rsideDate)
    formData.append('roleid', localStorage.getItem('roleid'))
    formData.append('staffId', localStorage.getItem('userid'))
    formData.append('name', localStorage.getItem('FirstName'))

    formData.append('courseName', coursedata.title)
    // formData.append('id', coursedata.id)
    formData.append('delieveryDate', coursedata.end_date)
    formData.append('rate', instructordetails.message && instructordetails.message.dayRate)

    formData.append('paymentType', selectedOption)

    formData.append('bankName', instructordetails.message && instructordetails.message.AccountName)
    formData.append(
      'accNumber',
      instructordetails.message && instructordetails.message.AccountNumber
    )
    formData.append('ibanNumber', instructordetails.message && instructordetails.message.IBANNumber)
    formData.append(
      'Company_Name',
      instructordetails.message && instructordetails.message.Company_Name
    )

    const subtotal = calculateSubtotal(
      Number(daysWorkedValue),
      Number(instructordetails.message && instructordetails.message.dayRate)
    )

    formData.append('subtotal', subtotal)
    const tax = calculateTax(subtotal, selectedOption)

    formData.append('tax', tax)
    const totalAmount = calculateTotalAmount(subtotal, tax)
    formData.append('total', totalAmount)

    formData.append('daysWorked', daysWorkedValue)

    formData.append('invoiceid', selectedCourseId)

    dispatch(GenerteInvoiceAction(formData))
  }
  const invoiceUpdateSubmit = (item) => {
    const Id = item.id

    dispatch(InstructorInvoiceUpdateAction(Id))
  }

  const [isModalOpen, setIsModalOpen] = useState(false)

  const showfeedbackModal = (course, id) => {
    //debugger
    setIsModalOpen(true)

    setCoursedata(course)
    dispatch(getParticipantsAction(id))
  }

  const participants = useSelector((state) => {
    return state.login.getparticipant_list
  })

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const showModal = () => {
    setIsModalOpen(true)
  }

  const [isClicked, setIsClicked] = useState([])
  const handleClick = (item) => {
    let clicked = isClicked.slice()

    clicked[item.id] = true
    setIsClicked(clicked)
  }

  const onSubmit = (item) => {
    const Id = item.id

    dispatch(InstructorCoursesComplitionUpdateAction(Id))
  }
  const instructordetails = useSelector((state) => {
    return state.login.getaInstructorIdlist
  })

  useEffect(() => {
    dispatch(GetInstructorbyId(instid))
  }, [dispatch])

  // Assuming you have an array of courses like this:
  const courses = [
    {meetingLink: 'link1'},
    {meetingLink: 'link2'},
    // ... other courses
  ]

  // Create an array to store copy statuses for each course
  const [copyStatuses, setCopyStatuses] = useState(new Array(courses.length).fill(''))

  // Function to handle copying for a specific course
  const handleCopy = (index) => {
    return () => {
      setCopyStatuses((prevStatuses) => {
        const newStatuses = [...prevStatuses]
        newStatuses[index] = 'Copied'
        setTimeout(() => {
          newStatuses[index] = ''
          setCopyStatuses(newStatuses)
        }, 2000) // Reset the message after 2 seconds
        return newStatuses
      })
    }
  }
  const [selectedNumber1, setSelectedNumber1] = useState('')
  const [selectedNumber2, setSelectedNumber2] = useState('')
  const [selectedNumber3, setSelectedNumber3] = useState('')
  const [selectedNumber4, setSelectedNumber4] = useState('')
  const [selectedNumber5, setSelectedNumber5] = useState('')

  const numbers = Array.from({length: 51}, (_, index) => (index === 0 ? 'NA' : index - 1))

  const handleNumberChange1 = (event) => {
    setSelectedNumber1(event.target.value)
  }
  const handleNumberChange2 = (event) => {
    setSelectedNumber2(event.target.value)
  }
  const handleNumberChange3 = (event) => {
    setSelectedNumber3(event.target.value)
  }
  const handleNumberChange4 = (event) => {
    setSelectedNumber4(event.target.value)
  }
  const handleNumberChange5 = (event) => {
    setSelectedNumber5(event.target.value)
  }

  ///5 Sptemper

  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false) // State for invoice modal
  const [coursedata, setCoursedata] = useState('')

  const handleInvoiceCancel = () => {
    setIsInvoiceModalOpen(false)
  }

  const showInvoiceModal = (course) => {
    setIsInvoiceModalOpen(true)
    setCoursedata(course)
  }

  const [selectedOption, setSelectedOption] = useState('international')
  const [daysWorkedValue, setDaysWorkedValue] = useState('')

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value)
  }

  let percentage = 0
  if (selectedOption === 'local') {
    percentage = 20
  }

  const firstname = localStorage.getItem('FirstName')
  const surname = localStorage.getItem('Surname')

  const [paymentType, setPaymentType] = useState('international')
  const [daysWorked, setDaysWorked] = useState(0)

  //5september update code

  const [feedback, setFeedback] = useState('')
  const [improvement, setImprovement] = useState('')

  const onSubmitsfedback = (coursedata, selectedNumber) => {
    const formData = new FormData()
    const rsideDate = new Date()
    const courseId = coursedata.Course_id

    formData.append('Course_id', courseId)
    formData.append('raisedate', rsideDate)
    formData.append('roleid', localStorage.getItem('roleid'))
    formData.append('insId', localStorage.getItem('userid'))
    //formData.append('studentCount', '22')

    formData.append('improvement', improvement)
    formData.append('feedback', feedback)
    formData.append('day1', selectedNumber1)
    formData.append('day2', selectedNumber2)
    formData.append('day3', selectedNumber3)
    formData.append('day4', selectedNumber4)
    formData.append('day5', selectedNumber5)

    dispatch(FeedbackAction(formData))
  }

  //8sep updated code

  function nextPage() {
    if (currentPage !== nPage) {
      setCurrentPage(currentPage + 1)
    }
  }

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  function changeCPage(id) {
    setCurrentPage(id)
  }

  const [currentPage, setCurrentPage] = useState(1)

  const recordsPerPage = 10

  const lastIndex = currentPage * recordsPerPage

  const firstIndex = lastIndex - recordsPerPage

  const records = instructorInvoiceCourses.slice(firstIndex, lastIndex)

  const nPage = Math.ceil(instructorInvoiceCourses.length / recordsPerPage)

  const number = [...Array(nPage + 1).keys()].slice(1)

  function formatDate(dateString) {
    const options = {day: '2-digit', month: '2-digit', year: 'numeric'}
    const date = new Date(dateString)
    return date.toLocaleDateString('en-GB', options)
  }

  return (
    <div>
      {' '}
      <div
        style={{
          borderBottom: '2px solid #181c32',
          paddingBottom: '10px',
          paddingTop: '10px',
          fontWeight: 'bold',
          fontSize: '17px',
          textAlign: 'center',
        }}
      >
        DELIVERED CLASSES
      </div>
      <div style={{paddingTop: '30px'}}>
        <div className='table-responsive'>
          <table className='table align-middle table-row-bordered table-row-solid gy-4 gs-9'>
            {' '}
            <thead className='border-gray-200 fs-5 fw-semibold bg-lighten'>
              <tr>
                <th className='min-w-50px'>#</th>
                <th className='min-w-250px'>Course</th>
                <th className='min-w-150px'>Date</th>
                {/* <th className='min-w-100px'>Timezone</th> */}
                {/* <th className='min-w-100px'>Zoom Call</th> */}
                {/* <th className='min-w-100px'>Status</th> */}
                <th className='min-w-100px'>Feedback</th>
                <th className='min-w-100px'>View/ Generate Invoice</th>
                <th className='min-w-100px'>Invoice Status</th>
              </tr>
            </thead>
            <tbody className='fw-6 fw-semibold text-gray-600'>
              {records.length === 0 ? ( // Check if there are no records
                <tr>
                  <td colSpan='8' className='text-center'>
                    NO DELIVERED CLASSES
                  </td>
                </tr>
              ) : (
                <>
                  {records.map((course, index) => (
                    <tr key={course.id}>
                      <td>{firstIndex + index + 1}</td>
                      <td>{course.title}</td>
                      <td>
                        {' '}
                        {new Date(course.start_date).toLocaleDateString('en-UK', {
                          month: 'numeric',
                          day: 'numeric',
                          year: 'numeric',
                        })}{' '}
                        -
                        {new Date(course.end_date).toLocaleDateString('en-UK', {
                          month: 'numeric',
                          day: 'numeric',
                          year: 'numeric',
                        })}{' '}
                      </td>

                      {/* {course.meetingLink == null ? (
                        <td>NA</td>
                      ) : (
                        <td>
                          <div style={{display: 'flex'}}>
                            {course.meetingLink.slice(0, 20)}
                            <CopyToClipboard text={course.meetingLink} onCopy={handleCopy(index)}>
                              <div className='copy'>
                                <a>
                                  <i
                                    style={{fontSize: '20px'}}
                                    className={`fa-regular fa-copy`}
                                    disabled={
                                      window.ethereum?.selectedAddress &&
                                      localStorage.getItem('authToken')
                                        ? false
                                        : true
                                    }
                                  ></i>
                                </a>
                              </div>
                            </CopyToClipboard>
                          </div>
                          {copyStatuses[index] && (
                            <span style={{color: 'green', marginLeft: '5px'}}>
                              {copyStatuses[index]}
                            </span>
                          )}
                        </td>
                      )} */}
                      {/* {course.CompletedOn === null ? <td>Yet To Start</td> : <td></td>} */}
                      <td>
                        <button
                          className='btn btn-sm fw-bold cyberbtnallpage'
                          disabled={course.CourseStatus === null}
                          onClick={() => {
                            showfeedbackModal(course)
                          }}
                        >
                          Send Feedback
                        </button>
                      </td>

                      <td>    
                        <div>
                          {course.InvoiceStatus === null ? (
                            <button
                              type='button'
                              className='btn btn-sm fw-bold cyberbtnallpage'
                              disabled={course.CourseStatus === null}
                              onClick={() => {
                                showInvoiceModal(course)
                                handleCourseSelection(course.id)
                              }}
                            >
                              Generate Invoice
                            </button>
                          ) : (
                            <a href={course.pdfLink} download>
                              <FaFilePdf style={{fontSize: '25px', color: 'red'}} />
                            </a>
                          )}
                        </div>
                      </td>

                      <td>
                        <div>
                          {course.InvoiceStatus === null ? (
                             ' Not Generated '
                          ) : (
                            ' Invoice Generated '
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
            <Modal
              title='Send Feedback'
              centered
              open={isModalOpen}
              onCancel={handleCancel}
              footer={null}
            >
              <div>
                <div>
                  <form style={{padding: '2rem', borderRadius: '.375rem'}}>
                    <div class='form-group row'>
                      <label for='staticEmail' class='col-sm-4 col-form-label'>
                        Instructor Name
                      </label>
                      <div class='col-sm-8'>
                        <input
                          type='text'
                          readonly
                          class='form-control-plaintext'
                          id='staticEmail'
                          value={firstname + ' ' + surname}
                        />
                      </div>
                    </div>

                    <div class='form-group row'>
                      <label for='staticEmail' class='col-sm-4 col-form-label'>
                        Course Name
                      </label>
                      <div class='col-sm-8' style={{marginTop: '10px'}}>
                        <div class='col-sm-8'>{coursedata.title}</div>
                      </div>
                    </div>

                    <div class='form-group row'>
                      <label for='staticEmail' class='col-sm-4 col-form-label'>
                        Dates delivered
                      </label>
                      <div class='col-sm-8' style={{marginTop: '10px'}}>
                        {new Date(coursedata.start_date).toLocaleDateString('en-UK', {
                          month: 'numeric',
                          day: 'numeric',
                          year: 'numeric',
                        })}{' '}
                        -
                        {new Date(coursedata.end_date).toLocaleDateString('en-UK', {
                          month: 'numeric',
                          day: 'numeric',
                          year: 'numeric',
                        })}{' '}
                      </div>
                    </div>

                    {/* <div class='form-group row'>
                      <label for='staticEmail' class='col-sm-4 col-form-label'>
                        Number of students
                      </label>
                      <div class='col-sm-8' style={{marginTop: '10px'}}>
                        {participants.inMeetingCount}
                      </div>
                    </div> */}

                    <div class='form-group row'>
                      <label for='staticEmail' class='col-sm-12 col-form-label'>
                        Number of students actually attended each day?
                      </label>
                      <div class='col-sm-12' style={{marginTop: '10px'}}>
                        <div class='custom-control custom-radio custom-control-inline'>
                          <label
                            class='custom-control-label'
                            for='customRadioInline1'
                            style={{marginRight: '10px'}}
                          >
                            Day 1
                          </label>
                          <select
                            id='numberSelect'
                            value={selectedNumber1}
                            onChange={handleNumberChange1}
                            style={{
                              border: '1px solid rgb(204, 204, 204)',
                              borderRadius: '4px',
                              padding: '5px',
                            }}
                          >
                            <option value=''>Select an option</option>
                            {numbers.map((number) => (
                              <option key={number} value={number}>
                                {number}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div class='col-sm-12' style={{marginTop: '10px'}}>
                        <div class='custom-control custom-radio custom-control-inline'>
                          <label
                            class='custom-control-label'
                            for='customRadioInline2'
                            style={{marginRight: '10px'}}
                          >
                            Day 2
                          </label>
                          <select
                            id='numberSelect'
                            value={selectedNumber2}
                            onChange={handleNumberChange2}
                            style={{
                              border: '1px solid rgb(204, 204, 204)',
                              borderRadius: '4px',
                              padding: '5px',
                            }}
                          >
                            <option value=''>Select an option</option>
                            {numbers.map((number) => (
                              <option key={number} value={number}>
                                {number}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div class='col-sm-12' style={{marginTop: '10px'}}>
                        <div class='custom-control custom-radio custom-control-inline'>
                          <label
                            class='custom-control-label'
                            for='customRadioInline2'
                            style={{marginRight: '10px'}}
                          >
                            Day 3
                          </label>
                          <select
                            id='numberSelect'
                            value={selectedNumber3}
                            onChange={handleNumberChange3}
                            style={{
                              border: '1px solid rgb(204, 204, 204)',
                              borderRadius: '4px',
                              padding: '5px',
                            }}
                          >
                            <option value=''>Select an option</option>
                            {numbers.map((number) => (
                              <option key={number} value={number}>
                                {number}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div class='col-sm-12' style={{marginTop: '10px'}}>
                        <div class='custom-control custom-radio custom-control-inline'>
                          <label
                            class='custom-control-label'
                            for='customRadioInline2'
                            style={{marginRight: '10px'}}
                          >
                            Day 4
                          </label>
                          <select
                            id='numberSelect'
                            value={selectedNumber4}
                            onChange={handleNumberChange4}
                            style={{
                              border: '1px solid rgb(204, 204, 204)',
                              borderRadius: '4px',
                              padding: '5px',
                            }}
                          >
                            <option value=''>Select an option</option>
                            {numbers.map((number) => (
                              <option key={number} value={number}>
                                {number}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div class='col-sm-12' style={{marginTop: '10px'}}>
                        <div class='custom-control custom-radio custom-control-inline'>
                          <label
                            class='custom-control-label'
                            for='customRadioInline2'
                            style={{marginRight: '10px'}}
                          >
                            Day 5
                          </label>

                          <select
                            id='numberSelect'
                            value={selectedNumber5}
                            onChange={handleNumberChange5}
                            style={{
                              border: '1px solid rgb(204, 204, 204)',
                              borderRadius: '4px',
                              padding: '5px',
                            }}
                          >
                            <option value=''>Select an option</option>
                            {numbers.map((number) => (
                              <option key={number} value={number}>
                                {number}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class='form-group row'>
                      <label for='staticEmail' class='col-sm-12 col-form-label'>
                        Is there anything that the Cyberheads team can do to improve the way this
                        course is delivered in the future?
                      </label>

                      <div class='col-sm-12'>
                        <textarea
                          type='textarea'
                          id='improvement'
                          className='form-control'
                          placeholder='Enter improvement'
                          value={improvement}
                          onChange={(e) => setImprovement(e.target.value)}
                        />
                      </div>
                    </div>

                    <div class='form-group row' style={{paddingBottom: '2rem'}}>
                      <label for='staticEmail' class='col-sm-12 col-form-label'>
                        Is there any other observation or feedback you wish to provide to our team
                        at this stage?
                      </label>
                      <div className='col-sm-12'>
                        <textarea
                          className='form-control'
                          id='feedback'
                          placeholder='Enter feedback'
                          name='feedback'
                          value={feedback}
                          onChange={(e) => setFeedback(e.target.value)}
                        />
                      </div>
                    </div>

                    <button
                      style={{
                        textAlign: 'center',
                        width: ' 100%',
                        color: '#ffff',
                        fontSize: '1rem',
                        borderRadius: '.375rem',
                        backgroundColor: '#FF763F',
                        textTransform: 'capitalize',
                        border: 'none',
                        padding: '10px',
                      }}
                      type='submit'
                      className=' btn-warning'
                      disableRipple
                      onClick={(event) => {
                        event.preventDefault()
                        onSubmitsfedback(coursedata)
                      }}
                    >
                      submit
                    </button>
                  </form>
                </div>
              </div>
            </Modal>
            <Modal
              title='Generate Invoice'
              centered
              open={isInvoiceModalOpen}
              onCancel={handleInvoiceCancel}
              footer={null}
            >
              <div style={{width: '500px'}}>
                <div>
                  <form style={{padding: '2rem', borderRadius: '.375rem'}}>
                    <div class='form-group row'>
                      <label for='staticEmail' class='col-sm-4 col-form-label'>
                        Instructor Name
                      </label>
                      <div class='col-sm-8'>
                        <input
                          type='text'
                          readonly
                          class='form-control-plaintext text-gray-400 pt-1 fw-semibold fs-2'
                          id='staticEmail'
                          value={firstname + ' ' + surname}
                        />
                      </div>
                    </div>

                    <div class='form-group row'>
                      <label for='staticEmail' class='col-sm-4 col-form-label'>
                        Payment Type
                      </label>
                      <div class='col-sm-4' style={{marginTop: '10px'}}>
                        <div class='custom-control custom-radio custom-control-inline'>
                          <input
                            type='radio'
                            id='customRadioInline1'
                            name='customRadioInline1'
                            class='custom-control-input'
                            value='international'
                            checked={selectedOption === 'international'}
                            onChange={handleOptionChange}
                            style={{marginRight: '5px'}}
                          />
                          <label class='custom-control-label' for='customRadioInline1'>
                            International
                          </label>
                        </div>
                      </div>
                      <div class='col-sm-4' style={{marginTop: '10px'}}>
                        <div class='custom-control custom-radio custom-control-inline'>
                          <input
                            type='radio'
                            id='customRadioInline2'
                            name='customRadioInline1'
                            class='custom-control-input'
                            value='local'
                            checked={selectedOption === 'local'}
                            onChange={handleOptionChange}
                            style={{marginRight: '5px'}}
                          />
                          <label class='custom-control-label' for='customRadioInline2'>
                            UK
                          </label>
                        </div>
                      </div>
                    </div>

                    <div class='form-group row'>
                      <label for='staticEmail' class='col-sm-4 col-form-label'>
                        Day Rate
                      </label>
                      <div class='col-sm-8' style={{marginTop: '10px'}}>
                        <div class='col-sm-8'>
                          {instructordetails.message && instructordetails.message.dayRate}
                        </div>
                      </div>
                    </div>

                    <div>
                      <div class='form-group row'>
                        <label for='staticEmail' class='col-sm-4 col-form-label'>
                          Bank Name
                        </label>
                        <div class='col-sm-8' style={{marginTop: '10px'}}>
                          {' '}
                          {instructordetails.message && instructordetails.message.AccountName}{' '}
                        </div>
                      </div>

                      <div class='form-group row'>
                        <label for='staticEmail' class='col-sm-4 col-form-label'>
                          Account Number
                        </label>
                        <div class='col-sm-8' style={{marginTop: '10px'}}>
                          {instructordetails.message && instructordetails.message.AccountNumber}{' '}
                        </div>
                      </div>

                      <div class='form-group row'>
                        <label for='staticEmail' class='col-sm-4 col-form-label'>
                          IBAN Number
                        </label>
                        <div class='col-sm-8' style={{marginTop: '10px'}}>
                          {instructordetails.message && instructordetails.message.IBANNumber}{' '}
                        </div>
                      </div>
                      <div class='form-group row'>
                        <label for='staticEmail' class='col-sm-4 col-form-label'>
                          Company/Account Name
                        </label>
                        <div class='col-sm-8' style={{marginTop: '10px'}}>
                          {instructordetails.message && instructordetails.message.Company_Name}{' '}
                        </div>
                      </div>
                    </div>

                    <div class='form-group row'>
                      <label for='staticEmail' class='col-sm-4 col-form-label'>
                        Course Name Delivered
                      </label>
                      <div class='col-sm-8' style={{marginTop: '10px'}}>
                        {coursedata.title}
                      </div>
                    </div>

                    <div class='form-group row'>
                      <label for='staticEmail' class='col-sm-4 col-form-label'>
                        Course Delivery Dates
                      </label>
                      <div class='col-sm-8' style={{marginTop: '10px'}}>
                        {formatDate(coursedata.end_date)}
                      </div>
                    </div>

                    <div class='form-group row' style={{paddingBottom: '20px'}}>
                      <label class='col-sm-4 col-form-label'>Days Worked</label>
                      <div class='col-sm-8'>
                        <input
                          type='text'
                          class='form-control'
                          daysWorkedValue
                          placeholder='Days Worked'
                          name='Days Worked'
                          value={daysWorkedValue}
                          onChange={(e) => setDaysWorkedValue(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className='form-group row' style={{marginBottom: '10px'}}>
                      <label className='col-sm-4 col-form-label'>Subtotal</label>
                      <div className='col-sm-8'>
                        <input
                          type='text'
                          className='form-control'
                          value={`$${calculateSubtotal(
                            Number(daysWorkedValue),
                            Number(instructordetails.message && instructordetails.message.dayRate)
                          ).toFixed(2)}`}
                          readOnly
                        />
                      </div>
                    </div>

                    <div className='form-group row' style={{marginBottom: '10px'}}>
                      <label className='col-sm-4 col-form-label'>Tax</label>
                      <div className='col-sm-8'>
                        <input
                          type='text'
                          className='form-control'
                          value={`$${calculateTax(
                            calculateSubtotal(
                              Number(daysWorkedValue),
                              Number(instructordetails.message && instructordetails.message.dayRate)
                            ),
                            selectedOption
                          ).toFixed(2)}`}
                          readOnly
                        />
                      </div>
                    </div>

                    <div className='form-group row' style={{marginBottom: '10px'}}>
                      <label className='col-sm-4 col-form-label'>Total Amount</label>
                      <div className='col-sm-8'>
                        <input
                          type='text'
                          className='form-control'
                          value={`$${calculateTotalAmount(
                            calculateSubtotal(
                              Number(daysWorkedValue),
                              Number(instructordetails.message && instructordetails.message.dayRate)
                            ),
                            calculateTax(
                              calculateSubtotal(
                                Number(daysWorkedValue),
                                Number(
                                  instructordetails.message && instructordetails.message.dayRate
                                )
                              ),
                              selectedOption
                            )
                          ).toFixed(2)}`}
                          readOnly
                        />
                      </div>
                    </div>

                    <button
                      style={{
                        textAlign: 'center',
                        width: ' 100%',
                        color: '#ffff',
                        fontSize: '1rem',
                        borderRadius: '.375rem',
                        backgroundColor: '#FF763F',
                        textTransform: 'capitalize',
                        border: 'none',
                        padding: '10px',
                      }}
                      type='submit'
                      className=' btn-warning'
                      disableRipple
                      onClick={(event) => {
                        event.preventDefault()
                        onSubmits(coursedata)
                        invoiceUpdateSubmit(coursedata)
                        handleInvoiceClick(coursedata)
                      }}
                    >
                      submit
                    </button>
                  </form>
                </div>
              </div>
            </Modal>
          </table>
        </div>

        <nav className='w-0'>
          <ul className='pagination'>
            <li className='page-item'>
              <a href='#' className='page-link' onClick={prePage}>
                <AiOutlineLeft />
              </a>
            </li>
            {number.map((n, i) => (
              <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                <a href='#' className='page-link' onClick={() => changeCPage(n)}>
                  {n}
                </a>
              </li>
            ))}
            <li className='page-item'>
              <a href='#' className='page-link' onClick={nextPage}>
                <AiOutlineRight />
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default Deliveredclasses
