// import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

import {useIntl} from 'react-intl'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined'
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined'
import CollectionsBookmarkOutlinedIcon from '@mui/icons-material/CollectionsBookmarkOutlined'
import LogoutIcon from '@mui/icons-material/Logout'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined'
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined'
import PaymentRoundedIcon from '@mui/icons-material/PaymentRounded'
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined'
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import CardGiftcardOutlinedIcon from '@mui/icons-material/CardGiftcardOutlined'
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined'
import PageviewOutlinedIcon from '@mui/icons-material/PageviewOutlined'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import GroupsIcon from '@mui/icons-material/Groups'
import HistoryIcon from '@mui/icons-material/History';
import swal from 'sweetalert';
import DateRangeIcon from '@mui/icons-material/DateRange';

const SidebarMenuMain = () => {
  const intl = useIntl()

  let roleid = localStorage.getItem('roleid')
  const logoutHandler = async () => {
    const confirmResult = await swal({
      title: 'Log Out',
      text: 'Are you sure you want to log out?',
      //icon: '<i class="fas fa-exclamation-triangle" style="color: orange;"></i>', // Customize the icon and color
      buttons: {
        cancel: {
          text: 'No',
          value: null,
          visible: true,
          className: 'swal-button--cancel cybercancelbtn', // Customize the class name for the No button
        },
        confirm: {
          text: 'Yes',
          value: true,
          visible: true,
          className: 'swal-button--confirm cyberbtnallpage', // Customize the class name for the Yes button
        },
      },
      dangerMode: false,
    })
    if (confirmResult) {
      localStorage.clear()
      window.location.reload()
    }
  }

  return (
    <div>
      {roleid === '2' || roleid === '1' ? (
        <div>
          <div>
            <SidebarMenuItem
              to='/dashboard'
              customIcon={<DashboardOutlinedIcon style={{fontSize: '20px'}} />}
              title='Dashboard'
            />
          </div>


          <div className='menu-item'>
            <div
              className='menu-content pt-2 pb-2'
              style={{
                padding: '10px',
                borderRadius: '5px',

                background: 'rgb(226, 232, 242)',
              }}
            >
              <span
                className='menu-section text-uppercase '
                style={{
                  color: 'rgb(2, 31, 89) !important',
                  fontWeight: '600 !important',
                  fontSize: '14px',
                }}
              >
                Users
              </span>
            </div>
          </div>

          <SidebarMenuItem
            to='/alluserlist'
            title='All Users'
            customIcon={<PageviewOutlinedIcon style={{fontSize: '20px'}} />}
            hasBullet={false}
          />
          
          <SidebarMenuItem
            to='/adduser'
            title='Add New User'
            customIcon={<PersonAddAltOutlinedIcon style={{fontSize: '20px'}} />}
            hasBullet={false}
          />
         
          <SidebarMenuItem
            to='/deleteduserlist'
            title='Deleted Users'
            customIcon={<DeleteOutlineIcon style={{fontSize: '20px'}} />}
            hasBullet={false}
          />
          <SidebarMenuItem
            to='/user_role'
            customIcon={<ManageAccountsOutlinedIcon style={{fontSize: '20px'}} />}
            title='User Roles'
            fontIcon='bi-app-indicator'
          />
          <SidebarMenuItem
            to='/permissions'
            customIcon={<SecurityOutlinedIcon style={{fontSize: '20px'}} />}
            title='Permissions'
            fontIcon='bi-app-indicator'
          />

          {/* <SidebarMenuItem
              to='/filter_table'
              title='filterTable'
              customIcon={<FaChalkboardTeacher style={{fontSize: '20px'}} />}
              hasBullet={false}
            /> */}

          <div className='menu-item'>
            <div
              className='menu-content pt-2 pb-2'
              style={{
                padding: '10px',
                borderRadius: '5px',

                background: 'rgb(226, 232, 242)',
              }}
            >
              <span
                className='menu-section  text-uppercase '
                style={{
                  color: 'rgb(2, 31, 89) !important',
                  fontWeight: '600 !important',
                  fontSize: '14px',
                }}
              >
                Bundles
              </span>
            </div>
          </div>

          <SidebarMenuItem
            to='/bundlelist'
            customIcon={<CollectionsBookmarkOutlinedIcon style={{fontSize: '20px'}} />}
            title='All Bundle'
            fontIcon='bi-app-indicator'
          />
          <SidebarMenuItem
            to='/create_bundle'
            customIcon={<AddCircleOutlineOutlinedIcon style={{fontSize: '20px'}} />}
            title='Add New Bundle'
            fontIcon='bi-app-indicator'
          />

          


          {/* <SidebarMenuItem
              to='/instructor_userlist'
              title='Instructors'
              customIcon={<FaChalkboardTeacher style={{fontSize: '20px'}} />}
              hasBullet={false}
            /> */}

          {/* Course Menu Sections */}
          <div className='menu-item'>
            <div
              className='menu-content pt-2 pb-2'
              style={{
                padding: '10px',
                borderRadius: '5px',

                background: 'rgb(226, 232, 242)',
              }}
            >
              <span
                className='menu-section  text-uppercase '
                style={{
                  color: 'rgb(2, 31, 89) !important',
                  fontWeight: '600 !important',
                  fontSize: '14px',
                }}
              >
                Courses
              </span>
            </div>
          </div>
          <SidebarMenuItem
            to='/Allcourse'
            customIcon={<LibraryBooksOutlinedIcon style={{fontSize: '20px'}} />}
            title='All Courses'
            fontIcon='bi-app-indicator'
          />
          <SidebarMenuItem
            to='/addcourse'
            customIcon={<AddCircleOutlineOutlinedIcon style={{fontSize: '20px'}} />}
            title='Add New Course'
            fontIcon='bi-app-indicator'
          />
          <SidebarMenuItem
            to='/CourseDates'
            customIcon={<DateRangeIcon style={{fontSize: '20px'}} />}
            title='Courses Dates'
            fontIcon='bi-app-indicator'
          />

<div className='menu-item'>
            <div
              className='menu-content pt-2 pb-2'
              style={{
                padding: '10px',
                borderRadius: '5px',

                background: 'rgb(226, 232, 242)',
              }}
            >
              <span
                className='menu-section  text-uppercase '
                style={{
                  color: 'rgb(2, 31, 89) !important',
                  fontWeight: '600 !important',
                  fontSize: '14px',
                }}
              >
                Challenge Labs
              </span>
            </div>
          </div>
          <SidebarMenuItem
            to='/AllChallengeLabs'
            customIcon={<LibraryBooksOutlinedIcon style={{fontSize: '20px'}} />}
            title='All Challenge Labs'
            fontIcon='bi-app-indicator'
          />
          <SidebarMenuItem
            to='/AddChallengeLab'
            customIcon={<AddCircleOutlineOutlinedIcon style={{fontSize: '20px'}} />}
            title='Add New Challenge Lab'
            fontIcon='bi-app-indicator'
          />
          {/* Course Menu Sections */}
          <div className='menu-item'>
            <div
              className='menu-content pt-2 pb-2'
              style={{
                padding: '10px',
                borderRadius: '5px',

                background: 'rgb(226, 232, 242)',
              }}
            >
              <span
                className='menu-section  text-uppercase '
                style={{
                  color: 'rgb(2, 31, 89) !important',
                  fontWeight: '600 !important',
                  fontSize: '14px',
                }}
              >
                Web Careers
              </span>
            </div>
          </div>
          <SidebarMenuItem
            to='/allWebCareers'
            customIcon={<LibraryBooksOutlinedIcon style={{fontSize: '20px'}} />}
            title='All Web Careers'
            fontIcon='bi-app-indicator'
          />
          <SidebarMenuItem
            to='/addWebCareer'
            customIcon={<AddCircleOutlineOutlinedIcon style={{fontSize: '20px'}} />}
            title='Add New Web Career'
            fontIcon='bi-app-indicator'
          />
          {/* Bundle Menu Sections */}
          <div className='menu-item'>
            <div
              className='menu-content pt-2 pb-2'
              style={{
                padding: '10px',
                borderRadius: '5px',

                background: 'rgb(226, 232, 242)',
              }}
            >
              <span
                className='menu-section text-uppercase '
                style={{
                  color: 'rgb(2, 31, 89) !important',
                  fontWeight: '600 !important',
                  fontSize: '14px',
                }}
              >
                Virtual Classroom
              </span>
            </div>
          </div>
          <SidebarMenuItem
            to='/current_upcommimg_classes'
            customIcon={<GroupsIcon style={{fontSize: '20px'}} />}
            title='View All'
          />
          <SidebarMenuItem
            to='/zoom'
            customIcon={<VideocamOutlinedIcon style={{fontSize: '20px'}} />}
            title='Add New'
          />
         
          <SidebarMenuItem
            to='/past_meetings'
            customIcon={<HistoryIcon style={{fontSize: '20px'}} />}
            title='Archive'
          />

          {/* MyBlog Menu Sections */}
          <div className='menu-item'>
            <div
              className='menu-content pt-2 pb-2'
              style={{
                padding: '10px',
                borderRadius: '5px',

                background: 'rgb(226, 232, 242)',
              }}
            >
              <span
                className='menu-section  text-uppercase '
                style={{
                  color: 'rgb(2, 31, 89) !important',
                  fontWeight: '600 !important',
                  fontSize: '14px',
                }}
              >
                Blogs
              </span>
            </div>
          </div>

          <SidebarMenuItem
            to='/all_blog'
            customIcon={<CollectionsBookmarkOutlinedIcon style={{fontSize: '20px'}} />}
            title='All Blog'
            fontIcon='bi-app-indicator'
          />
          <SidebarMenuItem
            to='/add_blog'
            customIcon={<AddCircleOutlineOutlinedIcon style={{fontSize: '20px'}} />}
            title='Add New Blog'
            fontIcon='bi-app-indicator'
          />

          {/* Testimonial Menu Sections */}
          <div className='menu-item'>
            <div
              className='menu-content pt-2 pb-2'
              style={{
                padding: '10px',
                borderRadius: '5px',

                background: 'rgb(226, 232, 242)',
              }}
            >
              <span
                className='menu-section  text-uppercase '
                style={{
                  color: 'rgb(2, 31, 89) !important',
                  fontWeight: '600 !important',
                  fontSize: '14px',
                }}
              >
                Testimonial
              </span>
            </div>
          </div>

          <SidebarMenuItem
            to='/all_testimonial'
            customIcon={<CollectionsBookmarkOutlinedIcon style={{fontSize: '20px'}} />}
            title='All Testimonial'
            fontIcon='bi-app-indicator'
          />
          <SidebarMenuItem
            to='/add_testimonial'
            customIcon={<AddCircleOutlineOutlinedIcon style={{fontSize: '20px'}} />}
            title='Add New Testimonial'
            fontIcon='bi-app-indicator'
          />

          {/* Payments Menu Sections */}
          <div className='menu-item'>
            <div
              className='menu-content pt-2 pb-2'
              style={{
                padding: '10px',
                borderRadius: '5px',

                background: 'rgb(226, 232, 242)',
              }}
            >
              <span
                className='menu-section  text-uppercase '
                style={{
                  color: 'rgb(2, 31, 89) !important',
                  fontWeight: '600 !important',
                  fontSize: '14px',
                }}
              >
                Payment
              </span>
            </div>
          </div>

          <SidebarMenuItem
            to='/allpayments'
            customIcon={<PaymentRoundedIcon style={{fontSize: '20px'}} />}
            title='Student Payments'
            fontIcon='bi-app-indicator'
          />

          <SidebarMenuItem
            to='/invoices'
            customIcon={<PaymentRoundedIcon style={{fontSize: '20px'}} />}
            title='Instructor Invoices'
            fontIcon='bi-app-indicator'
          />

          {/* Reports Menu Sections */}
          <div className='menu-item'>
            <div
              className='menu-content pt-2 pb-2'
              style={{
                padding: '10px',
                borderRadius: '5px',

                background: 'rgb(226, 232, 242)',
              }}
            >
              <span
                className='menu-section  text-uppercase '
                style={{
                  color: 'rgb(2, 31, 89) !important',
                  fontWeight: '600 !important',
                  fontSize: '14px',
                }}
              >
                Reports
              </span>
            </div>
          </div>

          <SidebarMenuItem
            to='/all_reports'
            customIcon={<AssessmentOutlinedIcon style={{fontSize: '20px'}} />}
            title='All Reports'
            fontIcon='bi-app-indicator'
          />

          {/* Lti Menu Sections */}
          <div className='menu-item'>
            <div
              className='menu-content pt-2 pb-2'
              style={{
                padding: '10px',
                borderRadius: '5px',

                background: 'rgb(226, 232, 242)',
              }}
            >
              <span
                className='menu-section  text-uppercase '
                style={{
                  color: 'rgb(2, 31, 89) !important',
                  fontWeight: '600 !important',
                  fontSize: '14px',
                }}
              >
                LTI Tools
              </span>
            </div>
          </div>

          <SidebarMenuItem
            to='/all_tools'
            customIcon={<ChecklistRtlIcon style={{fontSize: '20px'}} />}
            title='All Tools'
            fontIcon='bi-app-indicator'
          />
          <SidebarMenuItem
            to='/create_tools'
            customIcon={<AddCircleOutlineOutlinedIcon style={{fontSize: '20px'}} />}
            title='Add New Tool'
            fontIcon='bi-app-indicator'
          />
          {/* Vouchers Settings Menu Sections */}
          {/* <div className='menu-item'>
            <div
              className='menu-content pt-2 pb-2'
              style={{
                padding: '10px',
                borderRadius: '5px',

                background: 'rgb(226, 232, 242)',
              }}
            >
              <span
                className='menu-section  text-uppercase '
                style={{
                  color: 'rgb(2, 31, 89) !important',
                  fontWeight: '600 !important',
                  fontSize: '14px',
                }}
              >
                Vouchers
              </span>
            </div>
          </div> */}

          {/* <SidebarMenuItem
            to='/voucher'
            customIcon={<CardGiftcardOutlinedIcon style={{fontSize: '20px'}} />}
            title='All Vouchers'
            fontIcon='bi-app-indicator'
          /> */}
          {/* General Settings Menu Sections */}

          <SidebarMenuItem to='/alltool' icon='element-11' title='' fontIcon='bi-app-indicator' />

          <SidebarMenuItem
            to='#'
            onClick={logoutHandler}
            customIcon={<LogoutIcon style={{fontSize: '20px'}} />}
            title='Log Out'
            fontIcon='bi-app-indicator'
          />
        </div>
      ) : roleid === '3' ? (
        <div>
          <SidebarMenuItem
            to='/mydashboard'
            customIcon={<DashboardOutlinedIcon style={{fontSize: '25px'}} />}
            title='Dashboard'
            // fontIcon='bi-app-indicator'
          />

          <SidebarMenuItem
            to='/AllPurchasehistory'
            customIcon={<HistoryOutlinedIcon style={{fontSize: '25px'}} />}
            title='Purchase History'
            // fontIcon='bi-app-indicator'
          />

          {/* <SidebarMenuItem
            to='/MyCourse'
            customIcon={<LibraryBooksOutlinedIcon style={{fontSize: '25px'}} />}
            title='My Courses'
            // fontIcon='bi-app-indicator'
          />

          <SidebarMenuItem
            to='/MyBundles'
            customIcon={<CollectionsBookmarkOutlinedIcon style={{fontSize: '25px'}} />}
            title='My Bundles'
            // fontIcon='bi-app-indicator'
          /> */}

          <SidebarMenuItem
            to='#'
            customIcon={<LogoutIcon style={{fontSize: '25px'}} />}
            title='Log Out'
            // fontIcon='bi-app-indicator'
            onClick={logoutHandler}
          />
        </div>
      ) : roleid === '4' ? (
        <div>
          <SidebarMenuItem
            to='/instructors'
            customIcon={<DashboardOutlinedIcon style={{fontSize: '20px'}} />}
            title='Dashboard'
            fontIcon='bi-app-indicator'
          />

          <SidebarMenuItem
            to='/Set_availability'
            customIcon={<CalendarMonthOutlinedIcon style={{fontSize: '20px'}} />}
            title='Set Availability'
            fontIcon='bi-app-indicator'
          />
          <SidebarMenuItem
            to='/Invoice'
            customIcon={<DescriptionOutlinedIcon style={{fontSize: '20px'}} />}
            title='Invoice'
            fontIcon='bi-app-indicator'
          />
          <SidebarMenuItem
            to='#'
            onClick={logoutHandler}
            customIcon={<LogoutIcon style={{fontSize: '20px'}} />}
            title='Log Out'
            fontIcon='bi-app-indicator'
          />
        </div>
      ) : roleid === '5' ? (
        <div>
          <SidebarMenuItem
            to='/Content_Writers'
            customIcon={<DashboardOutlinedIcon style={{fontSize: '20px'}} />}
            title='Dashboard'
            fontIcon='bi-app-indicator'
          />

          <SidebarMenuItem
            to='#'
            onClick={logoutHandler}
            customIcon={<LogoutIcon style={{fontSize: '20px'}} />}
            title='Log Out'
            fontIcon='bi-app-indicator'
          />
        </div>
      ) : roleid === '6' ? (
        <div>
          <SidebarMenuItem
            to='/Corporates_Manager'
            customIcon={<DashboardOutlinedIcon style={{fontSize: '20px'}} />}
            title='Dashboard'
            fontIcon='bi-app-indicator'
          />

          <SidebarMenuItem
            to='#'
            onClick={logoutHandler}
            customIcon={<LogoutIcon style={{fontSize: '20px'}} />}
            title='Log Out'
            fontIcon='bi-app-indicator'
          />
        </div>
      ) : roleid === '7' ? (
        <div>
          <SidebarMenuItem
            to='/Corporate_Employees'
            customIcon={<DashboardOutlinedIcon style={{fontSize: '20px'}} />}
            title='Dashboard'
            fontIcon='bi-app-indicator'
          />

          <SidebarMenuItem
            to='#'
            onClick={logoutHandler}
            customIcon={<LogoutIcon style={{fontSize: '20px'}} />}
            title='Log Out'
            fontIcon='bi-app-indicator'
          />
        </div>
      ) : (
        <div>hhhhhhhhh</div>
      )}
    </div>
  )
}

export {SidebarMenuMain}
