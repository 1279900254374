import swal from 'sweetalert'
import axios from 'axios'
import {createAsyncThunk} from '@reduxjs/toolkit'

import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {getltitoolslistslice,getLtiToolByIdSlice} from '../Slice/authSlice'

export const GetAllLtiToolsAction = () => async (dispatch) => {
  // debugger
  try {
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const ltilisturl = process.env.REACT_APP_BACKEND_API + '/getAllLtiTools'
    const res = await axios.get(ltilisturl, config, '')

    await dispatch(getltitoolslistslice(res))
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e?.response?.data?.message, 'error')
    }
  }
}

export const AddltitoolAction = (formData, navigate) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const url = process.env.REACT_APP_BACKEND_API + '/addLtiTool'
    const res = await axios.post(url, formData, config)
    if (res.status === 200) {
      window.location = '/all_tools'
    }

    dispatch(res())
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e.response.data.message, 'error')
    }
  }
}




export const updateltitoolAction = (formData, ltiID) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const url = process.env.REACT_APP_BACKEND_API + `/updateLtiToolbyId/${ltiID}`
    const res = await axios.put(url, formData, config)
    if (res.status === 200) {
      swal({
        title: 'Success',
        text: 'LTI Tool Updated',
        icon: 'success',
        buttons: {
          cancel: {
            text: 'OK',
            value: null,
            visible: true,
            className: 'swal-button--confirm cyberbtnallpage', 
          },
        },
      })
      window.location = '/all_tools'
    }

    dispatch(res())
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e.response.data.message, 'error')
    }
  }
}

export const GetToolByIdAction = (ltiID) => async (dispatch) => {
  // debugger
  try {
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const ltilisturl = process.env.REACT_APP_BACKEND_API + `/getLtiTool/${ltiID}`
    const res = await axios.get(ltilisturl, config, '')

    await dispatch(getLtiToolByIdSlice(res))
  } catch (e) {
    if (e?.response?.data.message) {
      swal('Error', e?.response?.data?.message, 'error')
    }
  }
}



export const LTIDeleteAction = (props) => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const confirmResult = await swal({
      title: 'Delete LTI Tool',
      text: 'Are you sure you want to delete this Tool?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          value: null,
          visible: true,
          className: 'swal-button--cancel cybercancelbtn', 
        },
        confirm: {
          text: 'Delete',
          value: true,
          visible: true,
          className: 'swal-button--confirm swal-button--danger cyberbtnallpage', 
        },
      },
      dangerMode: true,
    })
    if (confirmResult) {
      const deletelti = `${process.env.REACT_APP_BACKEND_API}/deleteLtiToolbyId/${props}`
      const res = await axios.delete(deletelti, config)

      if (res.status === 200) {
        swal({
          title: 'Success',
          text: 'LTI Tool Deleted',
          icon: 'success',
          buttons: {
            cancel: {
              text: 'OK',
              value: null,
              visible: true,
              className: 'swal-button--confirm cyberbtnallpage', 
            },
          },
        }).then(() => {
          window.location.reload()
        })
      }
    }
  } catch (e) {
    console.log(e)
  }
}