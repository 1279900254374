import React, {useEffect, useState} from 'react'
import {Col, Container, Row} from 'react-bootstrap'

import './mycoures.css'

import {Link, useNavigate} from 'react-router-dom'

import {BoughtcourseAction, CheckusercourseAction} from '../../../../Redux/Action/courseAction'

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'

import {useDispatch, useSelector} from 'react-redux'

const MyCourse = () => {
  const dispatch = useDispatch()
  const [message, setMessage] = useState('')
  const studentid = localStorage.getItem('userid')

  const mycoures = useSelector((state) => {
    return state.login.boughtcourseslist
  })

  useEffect(() => {
    dispatch(BoughtcourseAction(studentid))
  }, [dispatch])
  let cdata = ''

  useEffect(() => {
    dispatch(CheckusercourseAction())
  }, [dispatch])

  const learningprev = (props) => {
    dispatch(CheckusercourseAction(props, navigate, setMessage))
  }

  const Comptia = (props) => {
    dispatch(CheckusercourseAction(props, navigate, setMessage))
  }
  const navigate = useNavigate()
  return (
    <div>
      <div className='card'>
        {mycoures &&
          Object.entries(mycoures).map(([key, course], i) => {
            if (key === 'data') {
              cdata = course
            }
          })}

        {cdata && Object.entries(cdata).length === 0 ? (
          <div class='d-flex flex-column flex-center flex-column-fluid'>
            <div class='d-flex flex-column flex-center text-center p-10'>
              <div class='card-body py-15 py-lg-20' style={{height: '15rem', marginTop: '69px'}}>
                <h2 class='fw-bolder fs-2hx text-gray-900 mb-4'>No Course</h2>
              </div>
            </div>
          </div>
        ) : (
          Object.entries(cdata).map(([key, item], i) => {
            const truncatedDescription =
              item.description.length > 100
                ? `${item.description.slice(0, 250)}...`
                : item.description

            return (
              <div className='course-list-item row' key={i}>
                <div className='courseimg_list col-md-4'>
                  <img src={item.image_link} alt='img' style={{borderRadius: '1rem'}} />
                </div>
                <div className='course-list-info col-md-8'>
                  <h4>{item.title}</h4>
                  <div
                    className='course-list-info__meta pt-1'
                    style={{display: 'flex', gap: '1rem'}}
                  >
                    <span style={{display: 'flex', gap: '1rem'}}>
                      <CalendarMonthIcon style={{color: '#FF763F'}} />
                      <span className='text-gray-600'>
                        Expire On: <span className='fw-bold'>{item.expiration_time}</span>
                      </span>
                    </span>
                  </div>
                  <div className='course-list-info__description text-gray-600 fw-bold  bg-opacity-75 pt-3'>
                    <div dangerouslySetInnerHTML={{__html: truncatedDescription}} />
                  </div>
                </div>
                <div className='pt-4'>
                  <a
                    className='buttonlaunch button'
                    onClick={() => {
                      localStorage.setItem('paid_price', item.paid_price)
                      localStorage.setItem('category', item.category)

                      if (localStorage.getItem('userid') !== null) {
                        if (item.category === 'comptia') {
                          Comptia({
                            userId: localStorage.getItem('userid'),
                            coursecode: item.course_code,
                            coursid: item.courseslug,
                            category: item.category,
                          })
                        } else {
                          learningprev({
                            userId: localStorage.getItem('userid'),
                            coursecode: item.course_code,
                            coursid: item.courseslug,
                          })
                        }
                      } else {
                        navigate(`/learningprev?/${item.course_code}/${item.courseslug}`)
                      }
                    }}
                    style={{cursor: 'pointer'}}
                  >
                    View Course
                  </a>
                </div>
              </div>
            )
          })
        )}

        {/* View Course Button */}
      </div>
    </div>
  )
}

export default MyCourse
