import React, {useEffect, useState} from 'react'
import security from '../../../../assets/images/security.jpg'
import FormatAlignLeftOutlinedIcon from '@mui/icons-material/FormatAlignLeftOutlined'
// import Progressbar from './Progress_bar'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import {useParams} from 'react-router-dom'

import {GetChapterAction} from '../../../../Redux/Action/createChapterAction'
import {
  GetallLesson_idAction,
  DeleteLessonAction,
} from '../../../../Redux/Action/createChapterAction'
import DeleteIcon from '@mui/icons-material/Delete'
import {Button, Modal} from 'antd'
import {UploadOutlined} from '@ant-design/icons'
import Createlesson from '../Modalformslesson/Createlesson'
import Editlessons from '../Modalformslesson/Editlessons'
import Progressbar from '../Progress_bar'
import DateRangeIcon from '@mui/icons-material/DateRange'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'

import NoteAddIcon from '@mui/icons-material/NoteAdd'

const ChapterDetails = () => {
  const itemStyle = {
    display: 'flex',
    gap: '1rem',
    marginBottom: '1rem',
    padding: '1rem',
    borderRadius: '8px',
    backgroundColor: '#fff',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    justifyContent: 'space-between',
  }

  const id = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  var newURL = window.location

  var splitURL = newURL.href.split('/')

  const chapter_slug = splitURL[4]
  const course_id = splitURL[4]

  const getallLesson = useSelector((state) => {
    // debugger;
    return state.login.getalllessonlist
  })

  useEffect(() => {
    // debugger;
    dispatch(GetallLesson_idAction(chapter_slug))
  }, [dispatch])

  let cdata = ''

  const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const getChapter = useSelector((state) => {
    return state.login.getchapterlist
  })
  useEffect(() => {
    dispatch(GetChapterAction(course_id))
  }, [dispatch, course_id])

  const DeleteLesson = (id) => {
    dispatch(DeleteLessonAction(id))
  }
  return (
    <div>
      <div className='row' style={{gap: '10px'}}>
        <div className='col-md-3'>
          <div className='row'>
            <div className='card h-600px' style={{boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'}}>
              <img src={security} alt='/' />
              {Object.entries(getChapter).map(([key, course], i) => {
                if (key == 'data') {
                  cdata = course
                }
              })}

              {Object.entries(cdata).map(([key, item], i) => {
                return (
                  <div className='pt-10' style={{textAlign: 'center'}}>
                    <h1
                      style={{
                        fontSize: '20px',
                        width: '83%',
                        textAlign: 'center',
                        marginLeft: '2rem',
                      }}
                    >
                      {item.chaptername}
                    </h1>

                    <p>{item.desc}</p>
                  </div>
                )
              })}
              {/* <div>
                <Progressbar bgcolor='#FF763F' progress='100' height={10} />
              </div> */}

              {/* <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '-2rem',
                }}
              >
                <Progressbar bgcolor='#FF763F' progress={100} height={10} />
              </div> */}

              {/* <div style={{paddingTop: '3rem'}}>
                <div
                  style={{
                    padding: '5px',
                    background: 'rgb(245, 243, 243)',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <LibraryBooksIcon
                    style={{width: '50px', fontSize: '30px', color: 'rgb(255, 118, 63)'}}
                  />
                  <span className='text-500 fw-bold fs-6'>All Chapters(23)</span>
                </div>
              </div> */}

              {/* <div style={{paddingTop: '1rem'}}>
                <div
                  style={{
                    padding: '5px',
                    background: 'rgb(245, 243, 243)',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <NoteAddIcon
                    style={{width: '50px', fontSize: '30px', color: 'rgb(255, 118, 63)'}}
                  />
                  <span className='text-500 fw-bold fs-6'>All Lesson(5)</span>
                </div>
              </div> */}

              {/* <div style={{paddingTop: '1rem'}}>
                <div
                  style={{
                    padding: '5px',
                    background: 'rgb(245, 243, 243)',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <DateRangeIcon
                    style={{width: '50px', fontSize: '30px', color: 'rgb(255, 118, 63)'}}
                  />
                  <span className='text-500 fw-bold fs-6'>Durations(5weeks)</span>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div className='col-md-8'>
          <div className='row'>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              {Object.entries(getChapter).map(([key, course], i) => {
                if (key == 'data') {
                  cdata = course
                }
              })}

              {Object.entries(cdata).map(([key, item], i) => {
                localStorage.setItem('course_id', item.chapter_slug)
                return (
                  <div className='pt-20'>
                    <h3>{item.chaptername}</h3>
                  </div>
                )
              })}
              <div className='pt-20'>
                {/* <button
                 
                  
                >
                  Add Chapter
                </button> */}

                <Button
                  className='btn btn-sm fw-bold '
                  onClick={showModal}
                  shape='square'
                  size='large'
                  icon={<UploadOutlined />}
                  style={{background: 'rgb(255, 118, 63)', color: 'white'}}
                >
                  Add Lesson
                </Button>

                <div>
                  <Modal
                    title='+Add Lesson'
                    centered
                    open={isModalOpen}
                    onCancel={handleCancel}
                    footer={null}
                  >
                    <Createlesson />
                  </Modal>
                </div>
              </div>
            </div>

            <div className='row' style={{paddingTop: '5rem'}}>
              <h3>Getting Start</h3>
            </div>

            <div>
              {Object.entries(getallLesson).map(([key, course], i) => {
                if (key == 'data') {
                  cdata = course
                }
              })}

              {Object.entries(cdata).map(([key, item], i) => (
                <div key={item.id} style={itemStyle}>
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div>
                      <FormatAlignLeftOutlinedIcon />
                    </div>
                    <div>
                      <span>{item.lessonname}</span>
                    </div>
                  </div>

                  <div style={{gap: '10px', display: 'flex'}}>
                    <button style={{border: '0px'}}>
                      <Editlessons id={item.lesson_slug} />
                    </button>
                    <button style={{border: '0px'}}>
                      <span>
                        <DeleteIcon onClick={() => DeleteLesson(item.lesson_slug)} />
                      </span>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChapterDetails
