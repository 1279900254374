/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {Header} from './Header'
import {Navbar} from './Navbar'
import {HiOutlineMail} from 'react-icons/hi'
import {BsQuestionLg} from 'react-icons/bs'

export function HeaderWrapper() {
  const {config, classes} = useLayout()
  if (!config.app?.header?.display) {
    return null
  }
  const roleid = localStorage.getItem('roleid')
  return (
    <div style={{flexDirection: 'column', display: 'flex'}}>
      <div
        id='kt_app_header'
        className='app-header'
        style={{
          boxShadow: 'rgba(22, 22, 26, 0.18) 2px 6px 8px 0px',
          borderBottom: 'none',
          background: '#ffff',
        }}
      >
        <div
          id='kt_app_header_container'
          className={clsx(
            'app-container flex-lg-grow-1',
            classes.headerContainer.join(' '),
            config.app?.header?.default?.containerClass
          )}
        >
          
					
          <div className="d-flex align-items-center d-lg-none ms-n3 me-1 me-md-2" title="Show sidebar menu">
              <div className="btn btn-icon btn-active-color-primary w-35px h-35px" id="kt_app_sidebar_mobile_toggle">
                  
                  <span className="svg-icon svg-icon-2 svg-icon-md-1">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="currentColor" />
                          <path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="currentColor" />
                  </svg>
                  </span>
                  
              </div>
          </div>
          

          <div
            id='kt_app_header_wrapper'
            className='d-flex align-items-stretch justify-content-end flex-lg-grow-1 pt-3'
          >
            {config.app.header.default?.content === 'menu' &&
              config.app.header.default.menu?.display && (
                <div
                  className='app-header-menu app-header-mobile-drawer align-items-stretch'
                  data-kt-drawer='true'
                  data-kt-drawer-name='app-header-menu'
                  data-kt-drawer-activate='{default: true, lg: false}'
                  data-kt-drawer-overlay='true'
                  data-kt-drawer-width='225px'
                  data-kt-drawer-direction='end'
                  data-kt-drawer-toggle='#kt_app_header_menu_toggle'
                  data-kt-swapper='true'
                  data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
                  data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
                >
                  {/* {roleid === '3' && (
                    <div className='d-flex' style={{gap: '10px'}}>
                      <a href='mailto:admin@cyberheads.com'>
                        <div
                          style={{
                            backgroundColor: 'rgb(244, 246, 250)', // Background color
                            border: '1px solid rgb(244, 246, 250)', // Border color and width
                            borderRadius: '50%', // Makes the container a circle
                            padding: '5px', // Add padding around the icon
                            display: 'inline-block', // Keeps the container inline with text
                          }}
                        >
                          <HiOutlineMail
                            style={{color: 'black', fontSize: '25px', cursor: 'pointer'}}
                          />{' '}
                        </div>
                      </a>

                      <Link to='/faq'>
                        <div
                          style={{
                            backgroundColor: 'rgb(244, 246, 250)', // Background color
                            border: '1px solid rgb(244, 246, 250)', // Border color and width
                            borderRadius: '50%', // Makes the container a circle
                            padding: '5px', // Add padding around the icon
                            display: 'inline-block', // Keeps the container inline with text
                          }}
                        >
                          <BsQuestionLg
                            style={{color: 'black', fontSize: '25px', cursor: 'pointer'}}
                          />{' '}
                        </div>
                      </Link>
                    </div>
                  )} */}
                  <Header />
                </div>
              )}

            <Navbar />

            {/* Conditional rendering based on roleid */}
          </div>
        </div>
      </div>
    </div>
  )
}
