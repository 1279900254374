import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetPublishedBlogsAction } from '../../Redux/Action/blogAction'
import blog_default from "../../assets/images/blog_default.png"

const Blogspage = () => {
  const dispatch = useDispatch()
  const blogs = useSelector((state) => {
    return state.login.getpublishedbloglist
  })

  const allblogs = blogs.blog || []
  useEffect(() => {
    dispatch(GetPublishedBlogsAction())
  }, [dispatch])

  return (
    <div>
      <div className='banner-content'>
        <h1
          className='banner-title'
          style={{
            position: 'absolute',
            top: '30%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            textAlign: 'center',
            color: 'white',
            fontSize: '30px',
            zIndex: 99,
          }}
        >
          Blogs
        </h1>
      </div>
      <div>
        <section className='blog' style={{ zIndex: '99999999', fontSize: '24px' }}>
          <div className='container'>

            <ul className='blog-grid d-flex flex-wrap'>
              {allblogs.map((item, index) => {
               
                  return (

                    <li className='list-item' key={index}>
                      <a href={`/blogdetail/${item.slug}`}>
                        <div className='thumb-wrapper'>
                          <img src={item.image_link ? item.image_link : blog_default} alt='' />
                        </div>

                        <h6>{item.title}</h6>
                      </a>
                    </li>
                  )
              
              })}

            </ul>

          </div>
        </section>
      </div>
    </div>
  )
}

export default Blogspage
