import {useState} from 'react'
import Navbar from '../Navbar/Navbar'

import Footer from '../Footer/Footer'
import {useDispatch} from 'react-redux'
import {ResetPasswordAction} from '../../Redux/Action/authAction'
import {useForm} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'

const ResetPassword = () => {
  // const [loading, setLoading] = useState(false);
  const dispatch = useDispatch()
  const [password, setPassword] = useState('')
  const [isPasswordValid, setIsPasswordValid] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm()

  const OnSubmit = (data) => {
    const formData = new FormData()
    // setLoading(true);
    formData.append('password', data.password)
    formData.append('password2', data.password2)

    dispatch(ResetPasswordAction(formData, userid, resettoken, navigate))
  }

  var newURL = window.location

  var splitURL = newURL.pathname.split('/')

  const userid = splitURL[2]
  const resettoken = splitURL[3]

  const validatePassword = (value) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,}$/
    const errors = []

    if (value.length < 8) {
      errors.push('at least 8 characters')
    }
    if (!/(?=.*[a-z])/.test(value)) {
      errors.push('at least one lowercase letter')
    }
    if (!/(?=.*[A-Z])/.test(value)) {
      errors.push('at least one uppercase letter')
    }
    if (!/(?=.*\d)/.test(value)) {
      errors.push('at least one number')
    }
    if (!/(?=.*[@#$%^&+=!])/.test(value)) {
      errors.push('at least one special character')
    }

    return {
      valid: passwordRegex.test(value),
      errors: errors.join(', '),
    }
  }

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value
    setPassword(newPassword)
    const validation = validatePassword(newPassword)
    if (!validation.valid) {
      setErrorMessage(`Password should contain ${validation.errors}.`)
      setIsPasswordValid(false) // Password is not valid, disable the button
    } else {
      setErrorMessage('')
      setIsPasswordValid(true) // Password is valid, enable the button
    }
  }

  return (
    <div>
      <div>
        <div className='banner-content'>
          <h1
            className='banner-title'
            style={{
              position: 'absolute',
              top: '30%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
              color: 'white',
              fontSize: '30px',
            }}
          >
            Reset Password
          </h1>
        </div>
      </div>
      <Navbar />

      <>
        <div className='container fluid'>
          {/* {loading && (
            <div className='loader-container'>
              <Loader />
            </div>
          )} */}
          <form onSubmit={handleSubmit(OnSubmit)}>
            <div className='row login_row'>
              <div
                className='login_box'
                style={{boxShadow: '0 0 20px 0 rgb(0 0 0 / 10%)', padding: '2rem'}}
              >
                <div className='col-12'>
                  <div className='form-group mt-3'>
                    <label for='inputFirstName' class='form-label'>
                      New Password
                    </label>
                    <input
                      type='password'
                      className={`form-control ${
                        password && !validatePassword(password).valid ? 'is-invalid' : ''
                      }`}
                      id='password'
                      style={{border: '1px solid grey'}}
                      placeholder='Enter New Password'
                      {...register('password', {
                        required: 'This is required field',
                      })}
                      onChange={handlePasswordChange}
                      aria-invalid={errors.password ? 'true' : 'false'}
                    />
                    {password && !validatePassword(password).valid && (
                      <p className='alert_danger' role='alert' style={{marginTop: '1rem'}}>
                        Password should contain {validatePassword(password).errors}.
                      </p>
                    )}
                  </div>
                </div>

                <div className='col-12'>
                  <div className='form-group mt-3' style={{paddingBottom: '2rem'}}>
                    <label for='inputFirstName' class='form-label'>
                      Confirm Password
                    </label>
                    <input
                      type='password'
                      class='form-control'
                      id='inputFirstName'
                      style={{border: '1px solid grey'}}
                      placeholder='Confirm Password'
                      {...register('password2', {
                        required: 'This is required field',
                      })}
                      aria-invalid={errors.password2 ? 'true' : 'false'}
                    />
                    {errors.password2 && (
                      <p className='alert_danger' role='alert'>
                        {errors?.password2?.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className='col-12'>
                  <button
                    className='btn w-100 mt-3 mt-sm-4'
                    type='submit'
                    style={{background: '#FF763F', color: 'white'}}
                    disabled={!isPasswordValid || (!password && !errors.password2)}
                  >
                    <i className='icon-paper-plane mr-2' />
                    Reset Password
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </>
      <Footer />
    </div>
  )
}

export default ResetPassword
