import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Table from 'react-bootstrap/Table'
import {Col, Container, Row} from 'react-bootstrap'
import * as CountryStateCity from 'country-state-city'
import {GetUseridAction} from '../../../Redux/Action/authAction'
import {Link} from 'react-router-dom'
import {BoughtBundleAction, BookSessionAction} from '../../../Redux/Action/bundleAction'
import {
  BoughtcourseAction,
  GetStudentDates,
  updateStudentDates,
  GetallCoursesBundlesAction,
  GetSessionbySessionId,
} from '../../../Redux/Action/courseAction'
import {BoughtBundleByUserAction} from '../../../Redux/Action/bundleAction'
import {UpdateExamVoucher} from '../../../Redux/Action/voucherAction'
import {GetUserSessionction} from '../../../Redux/Action/bundleAction'
import {ExamVouchers} from '../../../Redux/Action/voucherAction'
import {useForm} from 'react-hook-form'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {
  PreviewDetailsAction,
  removeStudentCourse,
  removeStudentSession,
  deleteStudentVoucher,
  GetCourseAction,
  GetVoucherById,
  GetdateById,
} from '../../../Redux/Action/courseAction'
import {GetallbundleactionAction} from '../../../Redux/Action/bundleAction'
import {FaEdit, FaTrash} from 'react-icons/fa'
import {ArrowLeftOutlined} from '@ant-design/icons'

import {Tooltip} from 'antd'
import {Modal} from 'antd'
import moment from 'moment'
import Loader from '../../../components/Loader/loader'
import {color} from '@amcharts/amcharts5'
import Select from 'react-select'
import {right} from '@popperjs/core'
import {
  paymentDetailsbyIdAction,
  payDetailsbyIdAction,
  Updatepaydetail,
} from '../../../Redux/Action/usersAction'
import {format} from 'date-fns'
const UserDetails = () => {
  const dispatch = useDispatch()
  var newURL = window.location

  var splitURL = newURL.href.split('/')
  const [selectedVoucher, setSelectedvoucher] = useState('')
  const instid = splitURL[4]
  const [countryName, setCountryName] = useState('')
  const [stateName, setStateName] = useState('')

  const usersession = useSelector((state) => {
    return state.login.getuserSessionlist
  })
  let serialNumber = 1
  useEffect(() => {
    dispatch(GetUserSessionction(instid))
  }, [dispatch])

  const usersdetails = useSelector((state) => {
    return state.login.getuseridlist
  })
  const allCourse = useSelector((state) => {
    return state.login.boughtcourseslist
  })

  useEffect(() => {
    dispatch(BoughtcourseAction(instid))
  }, [dispatch])

  const allBundle = useSelector((state) => {
    return state.login.getBundleUserIdList
  })

  const uservoucher = useSelector((state) => {
    return state.login.getvoucherbyidlist
  })

  useEffect(() => {
    //debugger
    dispatch(ExamVouchers(instid))
  }, [dispatch])

  useEffect(() => {
    dispatch(BoughtBundleByUserAction(instid))
  }, [dispatch])
  const courses = allCourse?.data
    ? allCourse.data.map((item) => (
        <option key={item.id} value={item.courseCode}>
          {item.title}
        </option>
      ))
    : null

  const bundles = allBundle?.data
    ? allBundle.data.map((item) => (
        <option key={item.id} value={item.bundle_code}>
          {item.Name}
        </option>
      ))
    : null

  useEffect(() => {
    dispatch(GetUseridAction(instid))
  }, [dispatch, instid])

  useEffect(() => {
    if (usersdetails.Country) {
      const country = CountryStateCity.Country.getCountryByCode(usersdetails.Country)
      const state = CountryStateCity.State.getStateByCodeAndCountry(
        usersdetails.state,
        usersdetails.Country
      )

      const fetchedCountryName = country ? country.name : usersdetails.Country || ''
      const fetchedStateName = state ? state.name : usersdetails.state || ''

      setCountryName(fetchedCountryName)
      setStateName(fetchedStateName)
    }
  }, [usersdetails])

  //Mentory course book

  const sessionData = useSelector((state) => {
    return state.login.sessionBysessionIdList
  })

  const voucherData = useSelector((state) => {
    return state.login.voucherByIdList
  })

  const currentDate = new Date()

  const [startDate, setStartDate] = useState('')
  const [sessionId, setSelectedSessionId] = useState('')
  const [voucherId, setselectedVoucherId] = useState('')
  const [voucherCode, setvoucherCode] = useState('')
  const [startVoucherDate, setStartVoucherDate] = useState('')
  const [sessionDate, setSessionDate] = useState('')
  const [sessionLink, setSessionLink] = useState('')

  const handleSessionlinkChange = (event) => {
    //debugger
    const newLink = event.target.value
    setSessionLink(newLink)
  }

  const handleSessionEdit = () => {
    setIsModalSessionOpen(true)
  }

  const handleVoucherEdit = () => {
    setIsModalVoucherOpen(true)
  }

  useEffect(() => {
    if (sessionData && sessionData.id) {
      setSessionDate(new Date(sessionData?.datetime))
      setSessionLink(sessionData?.meetingLink)
      setstartTimeEdit(sessionData?.startTime)
    }
  }, [sessionData])

  useEffect(() => {
    if (voucherData && voucherData.id) {
      setStartVoucherDate(new Date(voucherData?.expiary_date))
      setvoucherCode(voucherData?.code)
    }
  }, [voucherData])

  const handleSessionDateChange = (date) => {
    setSessionDate(date)
  }

  useEffect(() => {
    // debugger;
    dispatch(GetSessionbySessionId(sessionId))
  }, [dispatch, sessionId])

  useEffect(() => {
    //debugger;
    dispatch(GetVoucherById(voucherId))
  }, [dispatch, voucherId])

  const [selectedbundleId, setSelectedbundleId] = useState('')
  const [selectedOption, setSelectedOption] = useState('')
  const [selectedlink, setSelectedlink] = useState('')
  const [sessionDateee, setSessionStartDate] = useState('')
  const [selectedType, setSelectedType] = useState('')
  const [voucherCourse, setVoucherCourse] = useState('')
  const [courselink, setSelectedCourselink] = useState('')
  const [microsoftlink, setMicrosoftlink] = useState('')
  const [comptialink, setComptialinklink] = useState('')
  const [startTime, setstartTime] = useState('')
  const [startTimeEdit, setstartTimeEdit] = useState('')

  const handleVoucherCourseChange = (event) => {
    const selectedValue = event.target.value
    const isCourse = Courses && Courses.some((course) => course.props.value === selectedValue)
    const isBundle = Bundles && Bundles.some((bundle) => bundle.props.value === selectedValue)

    if (isCourse) {
      setSelectedType('course')
    } else if (isBundle) {
      setSelectedType('bundle')
    } else {
      console.log('Selected option is neither a course nor a bundle')
    }

    setVoucherCourse(selectedValue)
  }

  const handleSelectChangee = (event) => {
    const selectedValue = event.target.value
    const isCourse = Courses && Courses.some((course) => course.props.value === selectedValue)
    const isBundle = Bundles && Bundles.some((bundle) => bundle.props.value === selectedValue)

    if (isCourse) {
      setSelectedType('course')
    } else if (isBundle) {
      setSelectedType('bundle')
    } else {
      console.log('Selected option is neither a course nor a bundle')
    }

    setSelectedOption(selectedValue)
  }

  const handleSessionStartDateChange = (date) => {
    setSessionStartDate(date)
  }

  const handleStartDateChange = (date) => {
    setStartDate(date)
  }

  const handlebundleChange = (e) => {
    const bundleId = e.target.value
    setSelectedbundleId(bundleId)
  }

  const handlelinkChange = (event) => {
    //debugger
    const newLink = event.target.value
    setSelectedlink(newLink)
  }

  const handleCourselinkChange = (event) => {
    setSelectedCourselink(event.target.value)
  }
  const handleMicrosoftlinkChange = (event) => {
    setMicrosoftlink(event.target.value)
  }
  const handleComptialinkChange = (event) => {
    setComptialinklink(event.target.value)
  }

  const handleVoucherDateChange = (date) => {
    setStartVoucherDate(date)
  }

  const mybundle = useSelector((state) => {
    return state.login.getboughtbundlelist
  })
  const firstBundle = mybundle?.data?.[0]

  const bundle_code = firstBundle?.bundle_code
  useEffect(() => {
    dispatch(BoughtBundleAction(instid))
  }, [dispatch])

  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
  } = useForm()

  const userLocale = navigator.language || 'en-UK'
  let asiaTimezone = Intl.DateTimeFormat(userLocale).resolvedOptions().timeZone

  const onSubmit = (e) => {
    //debugger
    e.preventDefault()
    const formData = new FormData()
    setLoading(true)
    formData.append('bundle_code', selectedbundleId || null)

    formData.append('date', sessionDateee || sessionDate)

    formData.append('link', selectedlink || sessionLink)

    formData.append('startTime', startTime || startTimeEdit)

    formData.append('sessionId', sessionId)

    formData.append('adminTimezone', asiaTimezone)

    dispatch(BookSessionAction(formData, instid, setLoading))
    setSelectedbundleId('')
    setSelectedlink('')
    setSessionStartDate('')
    setstartTime('')
  }

  const onSubmit1 = (e) => {
    //debugger
    e.preventDefault()
    const formData = new FormData()
    formData.append('course_code', voucherCourse || null)
    formData.append('voucher_code', selectedVoucher || voucherCode)
    formData.append('expiry_date', startDate || startVoucherDate)
    formData.append('voucherId', voucherId)
    dispatch(UpdateExamVoucher(formData, instid))
    setStartVoucherDate('')
    setvoucherCode('')
    setStartDate('')
    setSelectedvoucher('')
    setVoucherCourse('')
  }

  function formatDate(datetime) {
    const isValidDate = moment(datetime).isValid()

    if (isValidDate) {
      const formattedDate = moment(datetime).format('DD/MM/YYYY')
      return formattedDate
    } else {
      return 'Invalid Date'
    }
  }

  const [copyStatuses, setCopyStatuses] = useState(new Array(usersession.length).fill(''))
  const [startUrlCopyStatuses, setStartUrlCopyStatuses] = useState(
    new Array(usersession.length).fill('')
  )
  const [joinUrlCopyStatuses, setJoinUrlCopyStatuses] = useState(
    new Array(usersession.length).fill('')
  )
  let roleid = localStorage.getItem('roleid')

  const handleCopy = (item, urlKey, setCopyStatuses) => {
    return () => {
      setCopyStatuses((prevStatuses) => {
        const newStatuses = [...prevStatuses]
        newStatuses[item] = 'Copied!'
        setTimeout(() => {
          newStatuses[item] = ''
          setCopyStatuses(newStatuses)
        }, 2000) // Reset the message after 2 seconds
        return newStatuses
      })
    }
  }

  const studentDates = useSelector((state) => {
    return state.login.getStuentDateslist
  })

  useEffect(() => {
    //debugger
    dispatch(GetStudentDates(instid))
  }, [dispatch, instid])

  const course = useSelector((state) => {
    return state.login.getpreviewdetailslist
  })

  useEffect(() => {
    debugger
    dispatch(PreviewDetailsAction(selectedOption || voucherCourse))
  }, [dispatch, selectedOption || voucherCourse])

  const courseCategory =
    course.dateRange && course.dateRange[0] ? course.dateRange[0].Course_Category : null
  const courseId = course?.data?.courseId ?? null

  const bundle = useSelector((state) => {
    return state.login.getBundleall_list
  })

  let bundleCode = null

  if (bundle.data1 && bundle.data1.length > 0) {
    bundleCode = bundle.data1[0].bundle_code
  }
  const microsoftBootcampId = bundle?.categorizedData?.Microsoft_Bootcamp[0]?.id ?? null
  const comptiaBootcampId = bundle?.categorizedData?.Comptia_Bootcamp[0]?.id ?? null

  const categorizedData = useSelector((state) => state.login.getBundleall_list.categorizedData)
  const microsoftBootcampData = categorizedData && categorizedData.Microsoft_Bootcamp
  const Comptia_Bootcamp = categorizedData && categorizedData.Comptia_Bootcamp

  useEffect(() => {
    dispatch(GetallbundleactionAction(selectedOption || voucherCourse))
  }, [dispatch, selectedOption || voucherCourse])

  const [selectedStartDate, setSelectedStartDate] = useState(null)
  const [selectedEndDate, setSelectedEndDate] = useState(null)
  const [MicrosofStarttDate, setMicrosoftStartDate] = useState(null)
  const [MicrosoftEndDate, setMicrosoftEndDate] = useState(null)
  const [ComptiaStartDate, setComptiaStartDate] = useState(null)
  const [ComptiaEndDate, setComptiaEndDate] = useState(null)
  const [MicrosoftTimezone, setMicrosoftTimezone] = useState(null)
  const [ComptiaTimezone, setComptiaTimezone] = useState(null)
  const [courseTimezone, setCourseTimezone] = useState(null)
  const [MicrosoftdatesId, setMicrosoftDatesId] = useState(null)
  const [CompTIAdatesId, setCompTIADatesId] = useState(null)
  const [courseDatesId, setcourseDatesId] = useState(null)

  const handleDateSelection = (start, end, timezone, datesId) => {
    setSelectedStartDate(start)
    setSelectedEndDate(end)
    setCourseTimezone(timezone)
    setTimezone(timezone)
    setcourseDatesId(datesId)
  }

  const handleMicrosoftDate = (start, end, timezone, datesId) => {
    setMicrosoftStartDate(start)
    setMicrosoftEndDate(end)
    setMicrosoftTimezone(timezone)
    setMicrosoftDatesId(datesId)
  }

  const handleComptiaDate = (start, end, timezone, datesId) => {
    setComptiaStartDate(start)
    setComptiaEndDate(end)
    setComptiaTimezone(timezone)
    setCompTIADatesId(datesId)
  }

  const onSubmit2 = (e) => {
    e.preventDefault()
    //debugger
    const formData = new FormData()
    setLoading(true)
    formData.append('CourseId', courseId)
    formData.append('CourseStartDate', selectedStartDate)
    formData.append('CourseEndDate', selectedEndDate)
    formData.append('MicrosoftCourseId', microsoftBootcampId)
    formData.append('CompTIACourseId', comptiaBootcampId)
    formData.append('MicrosoftStartDate', MicrosofStarttDate)
    formData.append('MicrosoftEndDate', MicrosoftEndDate)
    formData.append('CompTIABootcampStartDate', ComptiaStartDate)
    formData.append('CompTIABootcampEndDate', ComptiaEndDate)
    formData.append('MicrosoftTimezone', MicrosoftTimezone)
    formData.append('CompTIATimezone', ComptiaTimezone)
    formData.append('courseTimezone', courseTimezone)
    formData.append('Bundle_code', bundleCode || 0)
    formData.append('timezone', timezone)
    formData.append('Reference', Reference)
    formData.append('amount', amount)
    formData.append('paymenttime', Datee)
    formData.append('courselink', courselink)
    formData.append('microsoftlink', microsoftlink)
    formData.append('comptialink', comptialink)
    formData.append('MicrosoftdatesId', MicrosoftdatesId)
    formData.append('CompTIAdatesId', CompTIAdatesId)
    formData.append('courseDatesId', courseDatesId)
    dispatch(updateStudentDates(instid, formData, setLoading))
  }

  const CourseBundle = useSelector((state) => state.login.getallCourseBundleslist)

  const Courses = CourseBundle.Courses
    ? CourseBundle.Courses.map((item) => (
        <option key={item.id} value={item.course_code}>
          {item.title} (Course)
        </option>
      ))
    : null

  const Bundles = CourseBundle.Bundles
    ? CourseBundle.Bundles.map((item) => (
        <option key={item.id} value={item.bundle_code}>
          {item.Name} (Bundle)
        </option>
      ))
    : null

  useEffect(() => {
    dispatch(GetallCoursesBundlesAction())
  }, [dispatch])

  ////////////////////////////////////////////////////////////////

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModal1Open, setIsModal1Open] = useState(false)
  const [isModal2Open, setIsModal2Open] = useState(false)
  const [isModalSessionOpen, setIsModalSessionOpen] = useState(false)
  const [isModalVoucherOpen, setIsModalVoucherOpen] = useState(false)
  const [selectedInstructorId, setSelectedInstructorId] = useState(null)
  const [setcourseId, setSelectedCourseId] = useState(null)
  const [setcoursetype, setSelectedCoursetype] = useState(null)


  const handleBookButtonClick = (instid) => {
    setSelectedInstructorId(instid)
    setIsModalOpen(true)
  }

  const handleCancel = () => {
    setSelectedInstructorId(null)
    setIsModalOpen(false)
    setAmount('')
    setSelectedType('')
    setReference('')
  }

  useEffect(() => {
    if (isModalOpen === false) {
      setSelectedType('')
      setSelectedOption('')
    }
  })
  const handleCancel1 = () => {
    setSelectedInstructorId(null)
    setIsModal1Open(false)
  }

  const handleVoucherCancel = () => {
    setIsModalVoucherOpen(false)
  }

  const handleSessionCancel = () => {
    setIsModalSessionOpen(false)
  }

  const deleteHandler = (courseId, type) => {
    dispatch(removeStudentCourse(instid, courseId, type))
  }

  const deleteVoucherHandler = (recordId) => {
    dispatch(deleteStudentVoucher(recordId))
  }

  const deleteSessionrHandler = (recordID) => {
    dispatch(removeStudentSession(recordID))
  }

  const coursedetails = useSelector((state) => {
    return state.login.getcoursebyidlist
  })

  const datesbyId = useSelector((state) => {
    return state.login.getDateList
  })

  console.log(datesbyId,"datesbyIddatesbyId")

  useEffect(() => {
    //debugger
    if (datesbyId.length != 0) {
      if (datesbyId.data.type == 'course') {
        setSelectedEndDate(datesbyId.data.courseEndDate)
        setSelectedCourselink(datesbyId.data.courseJoinUrl)
        setSelectedStartDate(datesbyId.data.courseStartSate)
        setTimezone(datesbyId.data.timezone)
      } else if (datesbyId.data.type == 'bundle') {
        setMicrosoftlink(datesbyId.data.microsoftjoinUrl)
        setComptialinklink(datesbyId.data.comptiajoinUrl)
        setSelectedMicrosoftStartDate(datesbyId.data.microsoftStartDate)
        setSelectedCompTIABootcampStartDate(datesbyId.data.comptiaStartDate)
        setSelectedMicrosoftEndDate(datesbyId.data.microsoftEndDate)
        setSelectedCompTIABootcampEndDate(datesbyId.data.comptiaendDate)
        setSelectedMicrosoftDateCourseId(datesbyId.data.microsoftId)
        setCompTIABootcampDateCourseId(datesbyId.data.comptiaId)
        setSelectedMicrosoftTimezone(datesbyId.data.microsoftTimezone)
        setSelectedCompTIATimezone(datesbyId.data.comptiaTimezone)
        
      }
    }
  }, [datesbyId])

  const [type, setType] = useState('')
  const handleEditButtonClick = (CourseId, Type) => {
    setIsModal1Open(true)
    setSelectedCourseId(CourseId)
    setSelectedCoursetype(type)
    const CourseID = {
      courseId: CourseId,
    }
    if (Type == 'Course') {
      setType('Course')
      dispatch(GetCourseAction(CourseID))
      dispatch(GetdateById(CourseId, instid, 0))
    } else if (Type == 'Bundle') {
      setType('Bundle')
      dispatch(GetallbundleactionAction(CourseId))
      dispatch(GetdateById(CourseId, instid, CourseId))
    }
  }

  const [loading, setLoading] = useState(false)

  const onSubmit3 = (e) => {
    debugger
    e.preventDefault()
    const formData = new FormData()
    setLoading(true)
    if (type == 'Course') {
      formData.append('CourseId', setcourseId)
      formData.append('CourseStartDate', selectedStartDate)
      formData.append('CourseEndDate', selectedEndDate)
      formData.append('Bundle_code', 0)
      formData.append('courseTimezone', timezone)
      formData.append('courselink', courselink)
      formData.append('courseDatesId', courseDatesId)
      formData.append('prevCourseDatesId', datesbyId.data.CoursedatesId)
    } else if (type == 'Bundle') {
      formData.append('MicrosoftCourseId', MicrosoftDateCourseId)
      formData.append('CompTIACourseId', CompTIABootcampDateCourseId)
      formData.append('MicrosoftStartDate', selectedMicrosoftStartDate)
      formData.append('MicrosoftEndDate', selectedMicrosoftEndDate)
      formData.append('CompTIABootcampStartDate', selectedCompTIABootcampStartDate)
      formData.append('CompTIABootcampEndDate', selectedCompTIABootcampEndDate)
      formData.append('Bundle_code', bundleCode)
      formData.append('microsoftlink', microsoftlink)
      formData.append('comptialink', comptialink)
      formData.append('MicrosoftTimezone', microsoftTimezone)
      formData.append('CompTIATimezone', CompTIATimezone)
      formData.append('MicrosoftdatesId', MicrosoftbootdatesId)
      formData.append('CompTIAdatesId', CompTIAbootdatesId)
      formData.append('prevMicrosoftDatesId', datesbyId.data.microsoftdatesId)
      formData.append('prevComptiaDatesId', datesbyId.data.comptiadatesId)
    }
    dispatch(updateStudentDates(instid, formData, setLoading))
  }

  const [Datee, setDate] = useState(new Date())
  const [amount, setAmount] = useState(null)
  const [Reference, setReference] = useState(null)
  const handleDateChange = (date) => {
    setDate(date)
  }

  const handleAmountChange = (event) => {
    const newAmount = event.target.value
    setAmount(newAmount)
  }

  const handleReferenceChange = (event) => {
    const newReference = event.target.value
    setReference(newReference)
  }
  const [selectedMicrosoftStartDate, setSelectedMicrosoftStartDate] = useState(null)
  const [selectedMicrosoftEndDate, setSelectedMicrosoftEndDate] = useState(null)
  const [MicrosoftDateCourseId, setSelectedMicrosoftDateCourseId] = useState(null)
  const [selectedCompTIABootcampStartDate, setSelectedCompTIABootcampStartDate] = useState(null)
  const [selectedCompTIABootcampEndDate, setSelectedCompTIABootcampEndDate] = useState(null)
  const [CompTIABootcampDateCourseId, setCompTIABootcampDateCourseId] = useState(null)
  const [timezone, setTimezone] = useState(null)
  const [microsoftTimezone, setSelectedMicrosoftTimezone] = useState(null)
  const [CompTIATimezone, setSelectedCompTIATimezone] = useState(null)
  const [MicrosoftbootdatesId, setMicrosoftbootDatesId] = useState(null)
  const [CompTIAbootdatesId, setCompTIAbootDatesId] = useState(null)


  const handleMicrosoftDateChange = (courseId, formattedStartDate, formattedEndDate, timezone, datesId) => {
    //debugger
    setSelectedMicrosoftStartDate(formattedStartDate)
    setSelectedMicrosoftEndDate(formattedEndDate)
    setSelectedMicrosoftDateCourseId(courseId)
    setSelectedMicrosoftTimezone(timezone)
    setMicrosoftbootDatesId(datesId)
  }

  const handleCompTIABootcampDateChange = (
    courseId,
    formattedStartDate,
    formattedEndDate,
    asiaTimezone,
    datesId
  ) => {
    setSelectedCompTIABootcampStartDate(formattedStartDate)
    setSelectedCompTIABootcampEndDate(formattedEndDate)
    setCompTIABootcampDateCourseId(courseId)
    setSelectedCompTIATimezone(asiaTimezone)
    setCompTIAbootDatesId(datesId)
  }

  function formatSingleDate(date, timezone) {
    const datetime = moment.utc(date || '')

    const start_datetimeHour = datetime.hour().toString().padStart(2, '0')
    const start_datetimeMin = datetime.minute().toString().padStart(2, '0')
    const start_datetimeDate = datetime.date()
    const start_datetimeMonth = (datetime.month() + 1).toString().padStart(2, '0')
    const start_datetimeYear = datetime.year()

    const formattedDatetime = `${start_datetimeDate}/${start_datetimeMonth}/${start_datetimeYear}, ${start_datetimeHour}:${start_datetimeMin}`

    return formattedDatetime
  }

  const options = [
    {value: '', label: ''},
    {value: '00:00', label: '00:00'},
    {value: '00:30', label: '00:30'},
    {value: '01:00', label: '01:00'},
    {value: '01:30', label: '01:30'},
    {value: '02:00', label: '02:00'},
    {value: '02:30', label: '02:30'},
    {value: '03:00', label: '03:00'},
    {value: '03:30', label: '03:30'},
    {value: '04:00', label: '04:00'},
    {value: '04:30', label: '04:30'},
    {value: '05:00', label: '05:00'},
    {value: '05:30', label: '05:30'},
    {value: '06:00', label: '06:00'},
    {value: '06:30', label: '06:30'},
    {value: '07:00', label: '07:00'},
    {value: '07:30', label: '07:30'},
    {value: '08:00', label: '08:00'},
    {value: '08:30', label: '08:30'},
    {value: '09:00', label: '09:00'},
    {value: '09:30', label: '09:30'},
    {value: '10:00', label: '10:00'},
    {value: '10:30', label: '10:30'},
    {value: '11:00', label: '11:00'},
    {value: '11:30', label: '11:30'},
    {value: '12:00', label: '12:00'},
    {value: '12:30', label: '12:30'},
    {value: '13:00', label: '13:00'},
    {value: '13:30', label: '13:30'},
    {value: '14:00', label: '14:00'},
    {value: '14:30', label: '14:30'},
    {value: '15:00', label: '15:00'},
    {value: '15:30', label: '15:30'},
    {value: '16:00', label: '16:00'},
    {value: '16:30', label: '16:30'},
    {value: '17:00', label: '17:00'},
    {value: '17:30', label: '17:30'},
    {value: '18:00', label: '18:00'},
    {value: '18:30', label: '18:30'},
    {value: '19:00', label: '19:00'},
    {value: '19:30', label: '19:30'},
    {value: '20:00', label: '20:00'},
    {value: '20:30', label: '20:30'},
    {value: '21:00', label: '21:00'},
    {value: '21:30', label: '21:30'},
    {value: '22:00', label: '22:00'},
    {value: '22:30', label: '22:30'},
    {value: '23:00', label: '23:00'},
    {value: '23:30', label: '23:30'},
    {value: '24:00', label: '24:00'},
  ]

  const paymentDetail = useSelector((state) => {
    return state.login.paymentDetailList
  })

  useEffect(() => {
    dispatch(paymentDetailsbyIdAction(instid))
  }, [dispatch])

  const payDetail = useSelector((state) => {
    return state.login.payDetailList
  })

  const handlePaymentEdit = (Id) => {
    setIsModal2Open(true)
    setpaymentId(Id)
    dispatch(payDetailsbyIdAction(Id))
  }

  useEffect(() => {
    if (payDetail.data) {
      const date2 = new Date(
        format(new Date(payDetail?.data?.paymenttime), "yyyy-MM-dd'T'HH:mm:ss")
      )
      setAmount1(payDetail?.data?.amountpaid)
      setReference1(payDetail?.data?.reference)
      setpaymentDate(date2)
    }
  }, [payDetail])

  const handle2Cancel = () => {
    setIsModal2Open(false)
  }
  const [paymentid, setpaymentId] = useState(null)
  const [paymentDate, setpaymentDate] = useState(null)
  const [amount1, setAmount1] = useState('')
  const [Reference1, setReference1] = useState('')
  const handleAmountChange1 = (event) => {
    const newAmount = event.target.value
    setAmount1(newAmount)
  }

  const handleReferenceChange1 = (event) => {
    const newReference = event.target.value
    setReference1(newReference)
  }

  const handlepaymentDateChange = (date) => {
    setpaymentDate(date)
  }

  const onSubmit4 = (e) => {
    //debugger
    e.preventDefault()
    const formData = new FormData()
    formData.append('amount', amount1)
    formData.append('date', paymentDate || new Date())
    formData.append('reference', Reference1)
    dispatch(Updatepaydetail(paymentid, formData))
    setpaymentDate('')
    setAmount1('')
    setReference1('')
  }

  return (
    <div className='instructor-lisss'>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div>
              <div>
                <div className='card mb-5 ' id='kt_profile_details_view'>
                  <div className='card-header cursor-pointer'>
                    <div className='card-title m-0'>
                      <Link
                        to='/alluserlist'
                        style={{fontSize: '20px', color: '#FF763F', fontWeight: 'bold'}}
                      >
                        <ArrowLeftOutlined />
                      </Link>
                      <h3 className='fw-bold m-0'>Profile Details</h3>
                    </div>

                    <div className='card-toolbar'>
                      {usersdetails.fileUrl ? (
                        <a
                          className='btn cyberbtnallpage'
                          href={usersdetails.fileUrl}
                          target='_blank'
                        >
                          Review CV
                        </a>
                      ) : (
                        <button className='btn cyberbtnallpage' title='CV not available' disabled>
                          Review CV
                        </button>
                      )}
                    </div>
                  </div>{' '}
                  <div className='card-body p-9'>
                    <>
                      <div className='d-flex align-items-center fs-4 fw-bold mb-5'>
                        {' '}
                        Contact Details
                      </div>
                      <div className='row mb-7'>
                        <label className='col-lg-4 fw-semibold text-muted fs-6'>Full Name</label>
                        <div className='col-lg-8 fv-row'>
                          <span>
                            {usersdetails.FirstName} {usersdetails.Surname}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-7'>
                        <label className='col-lg-4 fw-semibold text-muted fs-6'>Telephone</label>
                        <div className='col-lg-8 d-flex align-items-center'>
                          <span>{usersdetails.PhoneNumber}</span>
                        </div>
                      </div>

                      <div className='row mb-7'>
                        <label className='col-lg-4 fw-semibold text-muted fs-6'>Timezone</label>
                        <div className='col-lg-8 d-flex align-items-center'>
                          <span>{usersdetails.timezone}</span>
                        </div>
                      </div>

                      <div className='row mb-7'>
                        <label className='col-lg-4 fw-semibold text-muted fs-6'>Email</label>
                        <div className='col-lg-8 fv-row'>
                          <span>{usersdetails.Email}</span>
                        </div>
                      </div>
                      <div className='row mb-7'>
                        <label className='col-lg-4 fw-semibold text-muted fs-6'>Country</label>
                        <div className='col-lg-8'>
                          <span> {countryName === 'undefined' ? '' : countryName}</span>
                        </div>
                      </div>
                      {stateName && stateName != 'undefined' ? (
                        <div className='row mb-7'>
                          <label className='col-lg-4 fw-semibold text-muted fs-6'>
                            State/County
                          </label>
                          <div className='col-lg-8'>
                            <span>{stateName}</span>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      {usersdetails &&
                      usersdetails.City &&
                      usersdetails.City != 'undefined' &&
                      usersdetails.City != null ? (
                        <div className='row mb-7'>
                          <label className='col-lg-4 fw-semibold text-muted fs-6'>City</label>
                          <div className='col-lg-8'>
                            <span> {usersdetails && usersdetails.City}</span>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}

                      <div className='row mb-7'>
                        <label className='col-lg-4 fw-semibold text-muted fs-6'>
                          Years of Cyber Security Experience
                        </label>
                        <div className='col-lg-8 d-flex align-items-center'>
                          <span>{usersdetails.YearsOfCyberSecurityExperience}</span>
                        </div>
                      </div>
                      <div className='row mb-7'>
                        <label className='col-lg-4 fw-semibold text-muted fs-6'>
                          Education Level
                        </label>
                        <div className='col-lg-8 d-flex align-items-center'>
                          <span>{usersdetails.EducationLevel}</span>
                        </div>
                      </div>
                      <div className='row mb-7'>
                        <label className='col-lg-4 fw-semibold text-muted fs-6'>
                          Purpose of taking the Course
                        </label>
                        <div className='col-lg-8 d-flex align-items-center'>
                          <span>{usersdetails.PurposeOfTakingTheCourse}</span>
                        </div>
                      </div>
                      <div className='row mb-7'>
                        <label className='col-lg-4 fw-semibold text-muted fs-6'>
                          I am interested in Cyberheads finding me a new job
                        </label>
                        <div className='col-lg-8 d-flex align-items-center'>
                          <span>{usersdetails.InterestedInCyberheadRole}</span>
                        </div>
                      </div>
                      <div className='row mb-7'>
                        <label className='col-lg-4 fw-semibold text-muted fs-6'>
                          How did you find out about Cyberheads?
                        </label>
                        <div className='col-lg-8 d-flex align-items-center'>
                          <span>{usersdetails.HowYouGotToKnow}</span>
                        </div>
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Container>
        <div className='card mb-5 p-10' id='kt_profile_details_view'>
          <div className='card-header p-0'>
            <h2>Booked Courses</h2>
            <div>
              <button
                className='btn cyberbtnallpage'
                style={{
                  color: 'white',
                  width: '108px',
                  padding: '10px',
                  borderRadius: '5px',
                  fontWeight: 500,
                }}
                onClick={() => handleBookButtonClick(instid)}
              >
                Add Course
              </button>
            </div>
          </div>

          <div className='card-body pb-4'>
            <div className='table-responsive'>
              <table className='table table-row-dashed align-middle gs-0 gy-4 my-0'>
                <thead>
                  <tr className='fs-7 fw-bold text-gray-500 border-bottom-0'>
                    <th className='p-0 '>#</th>
                    <th className='p-0 w-200px w-xxl-450px'>Course</th>
                    <th className='p-0 min-w-150px'>Type</th>
                    <th className='p-0 min-w-150px'>Start Date</th>
                    {/* <th className='p-0 min-w-150px'>End Date</th> */}
                    <th className='p-0 min-w-150px'>Actions</th>
                  </tr>
                </thead>
                <>
                  <tbody>
                    {studentDates?.Data ? (
                      studentDates?.Data.map((studentDate, index) => (
                        <tr key={index}>
                          <td className='text-start'>
                            <span className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                              {index + 1}
                            </span>
                          </td>
                          <td>
                            <div className='text-start'>
                              <span className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                                {studentDate.Name}
                              </span>
                            </div>
                          </td>
                          <td className='text-start'>
                            <span className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                              {studentDate.type}
                            </span>
                          </td>
                          {studentDate.type == 'Bundle' || studentDate.type == 'Course' ? (
                            <td className='text-start'>
                              {studentDate &&
                                Array.isArray(studentDate.dateArray) &&
                                studentDate.dateArray.map((dates, dateIndex) => (
                                  <React.Fragment key={dateIndex}>
                                    {(() => {
                                      

                                      return (
                                        <>
                                          <h5 className='fs-6' style={{color: '#264aca'}}>
                                            {dates.CourseName}
                                          </h5>

                                          {new Date(dates.end_datetime) >= new Date() ? (
                                            <div className='mb-5'>
                                              <p className='text-gray-800 fw-bold d-block mb-1 fs-6 me-2 w-100'>
                                                Start Time :{' '}
                                                {formatSingleDate(
                                                  dates.start_datetime,
                                                  dates.timezone
                                                ).toString() ?? 'N/A'}
                                              </p>
                                              <p className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                                                End Time :{' '}
                                                {formatSingleDate(
                                                  dates.end_datetime,
                                                  dates.timezone
                                                ).toString() ?? 'N/A'}
                                              </p>
                                            </div>
                                          ) : (
                                            <div className='mb-5'>
                                              <p className='fw-semibold text-muted mb-1 fs-6 w-100'>
                                                Start Time :{' '}
                                                {formatSingleDate(
                                                  dates.start_datetime,
                                                  dates.timezone
                                                ).toString() ?? 'N/A'}
                                              </p>
                                              <p className='fw-semibold text-muted mb-1 fs-6'>
                                                End Time :{' '}
                                                {formatSingleDate(
                                                  dates.end_datetime,
                                                  dates.timezone
                                                ).toString() ?? 'N/A'}
                                              </p>
                                            </div>
                                          )}
                                        </>
                                      )
                                    })()}
                                  </React.Fragment>
                                ))}
                            </td>
                          ) : (
                            <td>
                            <div className='text-start'>
                              <span className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                                NA
                              </span>
                            </div>
                          </td>
                          )}

                          <td>
                            {console.log(studentDate,"studentDatestudentDatestudentDate")}
                          {studentDate.type == 'Bundle' || studentDate.type == 'Course' ? (
                            <Tooltip title='Edit'>
                              <button
                                onClick={() => {
                                  handleEditButtonClick(studentDate.CourseId, studentDate.type)
                                }}
                                style={{border: '0', padding: '5px', background: 'white'}}
                              >
                                <span style={{fontSize: '18px', color: 'rgb(126, 130, 153)'}}>
                                  <FaEdit />
                                </span>
                              </button>
                            </Tooltip>
                          ) : (
                              <div></div>
                          )}

                            <Tooltip title='Delete'>
                              <button
                                onClick={() => {
                                  deleteHandler(studentDate.CourseId, studentDate.type)
                                }}
                                style={{border: '0', padding: '5px', background: 'white'}}
                              >
                                <span style={{fontSize: '18px', color: 'rgb(126, 130, 153)'}}>
                                  <FaTrash />
                                </span>
                              </button>
                            </Tooltip>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan='5' className='text-center'>
                          <span className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                            No Data Available
                          </span>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </>
              </table>
            </div>
          </div>
        </div>

        <div>
          <Modal
            title='Assign course to student'
            centered
            open={isModalOpen}
            onCancel={handleCancel}
            footer={null}
          >
            <form onSubmit={onSubmit2}>
              <div style={{paddingTop: '1rem', paddingLeft: '1rem'}}>
                {loading && (
                  <div className='loader-container'>
                    <Loader />
                  </div>
                )}
                <div>
                  <label className='form-label' style={{fontSize: '15px'}}>
                    Select a Course
                  </label>

                  <select
                    id='dropdown'
                    value={selectedOption}
                    data-control='select2'
                    onChange={handleSelectChangee}
                    data-placeholder='Select a CourseName...'
                    className='form-control mb-4'
                    required
                  >
                    <option
                      value=''
                      style={{
                        color: 'black',
                        fontWeight: '500',
                        background: 'rgb(255, 118, 63)',
                        color: 'white',
                      }}
                    >
                      Courses
                    </option>
                    {Courses}
                    <option
                      value=''
                      style={{
                        color: 'black',
                        fontWeight: '500',
                        background: 'rgb(255, 118, 63)',
                        color: 'white',
                      }}
                    >
                      Course bundles
                    </option>
                    {Bundles}
                  </select>

                  <div>
                    {selectedType === 'course' ? (
                      <>
                        <label className='form-label' style={{fontSize: '15px'}}>
                          <span>Select Dates ({courseCategory}):</span>
                        </label>
                        <div className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                          {course?.dateRange?.length > 0 ? (
                            course.dateRange.map((item) => {
                              function formatDate(datetime) {
                                const isValidDate = moment(datetime).isValid()

                                if (isValidDate) {
                                  const formattedDate = moment(datetime).format('DD/MM/YYYY, HH:mm')
                                  return formattedDate
                                } else {
                                  return 'Invalid Date'
                                }
                              }

                              return (
                                <div key={item.id}>
                                  {item.start_datetime ? (
                                    <>
                                      <div>
                                        <input
                                          type='radio'
                                          name='dateRadioGroup'
                                          value={formatDate(item.start_datetime)}
                                          // checked={true}
                                          onChange={() =>
                                            handleDateSelection(
                                              item.start_datetime,
                                              item.end_datetime,
                                              item.timezone,
                                              item.datesId
                                            )
                                          }
                                        />
                                        &nbsp; &nbsp;
                                        {formatDate(item.start_datetime)} -{' '}
                                        {formatDate(item.end_datetime)}
                                      </div>
                                    </>
                                  ) : (
                                    <span>No dates available</span>
                                  )}
                                </div>
                              )
                            })
                          ) : (
                            <span>No dates available</span>
                          )}
                        </div>
                      </>
                    ) : (
                      <div></div>
                    )}
                  </div>

                  {selectedType === 'bundle' ? (
                    <div>
                      <div>
                        <label className='form-label' style={{fontSize: '15px'}}>
                          <span>Select Dates (Microsoft Bootcamp):</span>
                        </label>
                        <div className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                          {bundle?.categorizedData?.Microsoft_Bootcamp &&
                            bundle?.categorizedData?.Microsoft_Bootcamp[0]?.date_ranges.map(
                              (item) => {
                                function formatDate(datetime) {
                                  const isValidDate = moment(datetime).isValid()

                                  if (isValidDate) {
                                    const formattedDate =
                                      moment(datetime).format('DD/MM/YYYY, HH:mm')
                                    return formattedDate
                                  } else {
                                    return 'Invalid Date'
                                  }
                                }

                                return (
                                  <>
                                    <div key={item.id}>
                                      <input
                                        type='radio'
                                        name='microsoftdate'
                                        value={formatDate(item.start_datetime)}
                                        // checked={true}
                                        onChange={() =>
                                          handleMicrosoftDate(
                                            item.start_datetime,
                                            item.end_datetime,
                                            item.timezone,
                                            item.datesId
                                          )
                                        }
                                      />
                                      &nbsp; &nbsp;
                                      {formatDate(item.start_datetime)} -{' '}
                                      {formatDate(item.end_datetime)}
                                    </div>
                                  </>
                                )
                              }
                            )}
                        
                        </div>
                      </div>

                      <div>
                        <label className='form-label' style={{fontSize: '15px'}}>
                          <span>Select Dates (CompTIA Bootcamp):</span>
                        </label>
                        <div className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                          {bundle?.categorizedData?.Comptia_Bootcamp &&
                            bundle?.categorizedData?.Comptia_Bootcamp[0]?.date_ranges.map(
                              (item) => {
                                function formatDate(datetime) {
                                  const isValidDate = moment(datetime).isValid()

                                  if (isValidDate) {
                                    const formattedDate =
                                      moment(datetime).format('DD/MM/YYYY, HH:mm')
                                    return formattedDate
                                  } else {
                                    return 'Invalid Date'
                                  }
                                }

                                return (
                                  <>
                                    <div key={item.id}>
                                      <input
                                        type='radio'
                                        name='comptiadate'
                                        value={formatDate(item.start_datetime)}
                                        // checked={true}
                                        onChange={() =>
                                          handleComptiaDate(
                                            item.start_datetime,
                                            item.end_datetime,
                                            item.timezone,
                                            item.datesId
                                          )
                                        }
                                      />
                                      &nbsp; &nbsp;
                                      {formatDate(item.start_datetime)} -{' '}
                                      {formatDate(item.end_datetime)}
                                    </div>
                                  </>
                                )
                              }
                            )}

                        </div>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>

                <div>
                  <label className='form-label required' style={{fontSize: '15px'}}>
                    Amount
                  </label>
                  <input
                    type='text'
                    className={`form-control mb-2 ${errors.amount ? 'is-invalid' : ''}`}
                    {...register('amount', {required: 'Amount is required'})}
                    aria-invalid={errors.amount ? 'true' : 'false'}
                    value={amount}
                    onChange={handleAmountChange}
                    required
                  />
                  {errors.amount && (
                    <p className='alert_danger' role='alert' style={{marginTop: '1rem'}}>
                      {errors.amount?.message}
                    </p>
                  )}

                  <label className='form-label required' style={{fontSize: '15px'}}>
                    Reference
                  </label>
                  <input
                    type='text'
                    className={`form-control mb-2 ${errors.Reference ? 'is-invalid' : ''}`}
                    {...register('Reference', {required: 'Reference is required'})}
                    aria-invalid={errors.Reference ? 'true' : 'false'}
                    value={Reference}
                    onChange={handleReferenceChange}
                    required
                  />
                  {errors.Reference && (
                    <p className='alert_danger' role='alert' style={{marginTop: '1rem'}}>
                      {errors.Reference?.message}
                    </p>
                  )}
                </div>

                <div style={{paddingBottom: '2rem'}}>
                  <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                    <span>Date Purchased</span>
                  </label>

                  <div>
                    <DatePicker
                      className='form-control mb-2'
                      selected={Datee}
                      onChange={handleDateChange}
                      selectsStart
                      dateFormat='dd/MM/yyyy'
                      minDate={new Date()}
                    />
                  </div>
                </div>
                <button type='submit' className='btn cyberbtnallpage'>
                  Save Course
                </button>
              </div>
            </form>
          </Modal>
        </div>

        <div>
          <Modal
            title='Edit Courses Dates'
            centered
            open={isModal1Open}
            onCancel={handleCancel1}
            footer={null}
          >
            <form onSubmit={onSubmit3}>
              <div style={{paddingTop: '2rem', paddingLeft: '1rem'}}>
                {loading && (
                  <div className='loader-container'>
                    <Loader />
                  </div>
                )}
                {type == 'Course' ? (
                  <div>
                    <div>
                      <div
                        className='book-view'
                        style={{
                          display: 'flex',
                          gap: '10px',
                          padding: '5px',
                          borderRadius: '4px',
                          background: 'rgb(38, 74, 202)',
                        }}
                      >
                        <h4 class='card-title align-items-start flex-column'>
                          <span class='card-label fw-bold text-800' style={{color: '#fff'}}>
                            Select Date
                          </span>
                        </h4>
                      </div>

                      <Table
                        bordered
                        id='Elevenplusyear_castomer'
                        style={{
                          tableLayout: 'fixed',
                        }}
                      >
                        <tbody style={{border: '1px solid #CECFCF', fontSize: '15px'}}>
                          <tr>
                            <td width={40}></td>
                            <th>Start Date & Time</th>
                            <th>End Date & Time</th>
                            <th>Timezone</th>
                          </tr>

                          {coursedetails?.dateRange && coursedetails.dateRange.length > 0 ? (
                            coursedetails.dateRange.map((item, index) => {
                              const start_datetime = moment
                                .utc(item?.start_datetime || '')
                                
                              const end_datetime = moment
                                .utc(item?.end_datetime || '')
                               

                              return (
                                <React.Fragment key={item.id}>
                                  <tr style={{border: '1px solid #CECFCF'}}>
                                    <td>
                                      <input
                                        type='radio'
                                        name={`dateType_${coursedetails.data.id}`}
                                        value={formatDate(item.start_datetime)}
                                        checked={item.start_datetime == selectedStartDate}
                                        required
                                        onChange={() => {
                                          const value = formatDate(item.start_datetime)
                                          handleDateSelection(
                                            item.start_datetime,
                                            item.end_datetime,
                                            item.timezone,
                                            item.datesId
                                          )
                                        }}
                                      />
                                    </td>
                                    <td>{start_datetime.format('DD/MM/YYYY, HH:mm')}</td>
                                    <td>{end_datetime.format('DD/MM/YYYY, HH:mm')}</td>
                                    <td>{item.timezone}</td>
                                  </tr>
                                </React.Fragment>
                              )
                            })
                          ) : (
                            <tr>
                              <td colSpan='2'>No Dates Available</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      
                    </div>
                  </div>
                ) : type == 'Bundle' ? (
                  <div>
                    <div className='container' style={{display: 'flex', flexDirection: 'column'}}>
                      <div className='Mylearning' style={{width: '100%'}}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div
                            className='book-view'
                            style={{
                              display: 'flex',
                              gap: '10px',
                              padding: '5px',
                              borderRadius: '4px',
                              background: 'rgb(38, 74, 202)',
                            }}
                          >
                            <h4 class='card-title align-items-start flex-column'>
                              <span class='card-label fw-bold text-800' style={{color: '#fff'}}>
                                Microsoft Bootcamp
                              </span>
                            </h4>
                          </div>

                          <Table
                            bordered
                            id='Elevenplusyear_castomer'
                            style={{
                              tableLayout: 'fixed',
                            }}
                          >
                            <tbody style={{border: '1px solid #CECFCF', fontSize: '15px'}}>
                              <tr>
                                <td width={40}></td>
                                <th>Start Date & Time</th>
                                <th>End Date & Time</th>
                                <th>Timezone</th>
                              </tr>
                              {microsoftBootcampData ? (
                                microsoftBootcampData.map((course) => (
                                  <React.Fragment key={course.id}>
                                    {course.date_ranges &&
                                      course.date_ranges.map((dateRange, index) => {
                                        const start_datetime = moment
                                          .utc(dateRange?.start_datetime || '')
                                          .tz(dateRange?.timezone)
                                        const end_datetime = moment
                                          .utc(dateRange?.end_datetime || '')
                                          .tz(dateRange.timezone)
                                        return (
                                          <tr key={index} style={{border: '1px solid #CECFCF'}}>
                                            <td colSpan='2'>
                                              <input
                                                type='radio'
                                                name={`dateType_${microsoftBootcampData[0].id}`}
                                                //value={formatDate(dateRange.start_datetime)}
                                                checked={
                                                  dateRange.start_datetime ==
                                                  selectedMicrosoftStartDate
                                                }
                                                required
                                                onChange={() =>
                                                  handleMicrosoftDateChange(
                                                    course.id,
                                                    dateRange.start_datetime,
                                                    dateRange.end_datetime,
                                                    dateRange.timezone,
                                                    dateRange.datesId
                                                  )
                                                }
                                              />
                                              &nbsp; &nbsp;
                                              {start_datetime.format('DD/MM/YYYY, HH:mm')}
                                            </td>
                                            <td>{end_datetime.format('DD/MM/YYYY, HH:mm')}</td>
                                            <td>{dateRange.timezone}</td>
                                          </tr>
                                        )
                                      })}
                                  </React.Fragment>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan='2'>No dates available</td>
                                </tr>
                              )}
                            </tbody>
                          </Table>


                          <div
                            className='book-view'
                            style={{
                              display: 'flex',
                              gap: '10px',
                              padding: '5px',
                              borderRadius: '4px',
                              background: 'rgb(38, 74, 202)',
                            }}
                          >
                            <h4 class='card-title align-items-start flex-column'>
                              <span class='card-label fw-bold text-800' style={{color: '#fff'}}>
                                CompTIA Bootcamp
                              </span>
                            </h4>
                          </div>

                          <Table
                            bordered
                            id='Elevenplusyear_castomer'
                            style={{
                              tableLayout: 'fixed',
                              maxHeight: '300px',
                              overflowY: 'auto',
                            }}
                          >
                            <tbody
                              style={{
                                border: '1px solid #CECFCF',
                                fontSize: '15px',
                              }}
                            >
                              <tr>
                                <td width={40}></td>
                                <th>Start Date & Time</th>
                                <th>End Date & Time</th>
                                <th>Timezone</th>
                              </tr>
                              {Comptia_Bootcamp ? (
                                Comptia_Bootcamp.map((course) => (
                                  <React.Fragment key={course.id}>
                                    {course &&
                                      course.date_ranges.map((dateRange, index) => {
                                        const start_datetime = moment
                                          .utc(dateRange?.start_datetime || '')
                                          .tz(dateRange?.timezone)
                                        const end_datetime = moment
                                          .utc(dateRange?.end_datetime || '')
                                          .tz(dateRange.timezone)
                                        return (
                                          <tr key={index} style={{border: '1px solid #CECFCF'}}>
                                            <td colSpan='2'>
                                              <input
                                                type='radio'
                                                name={`dateType_${Comptia_Bootcamp[0].id}`}
                                                //value={formatDate(dateRange.start_datetime)}
                                                checked={
                                                  dateRange.start_datetime ==
                                                  selectedCompTIABootcampStartDate
                                                }
                                                // checked={
                                                //   selectedCompTIABootcampStartDate ==
                                                //   formatDate(dateRange.start_datetime)
                                                // }
                                                required
                                                onChange={() =>
                                                  handleCompTIABootcampDateChange(
                                                    course.id,
                                                    dateRange.start_datetime,
                                                    dateRange.end_datetime,
                                                    dateRange.timezone,
                                                    dateRange.datesId
                                                  )
                                                } // Pass courseId and formattedDate
                                              />
                                              &nbsp; &nbsp;
                                              {start_datetime.format('DD/MM/YYYY, HH:mm')}
                                            </td>
                                            <td>{end_datetime.format('DD/MM/YYYY, HH:mm')}</td>
                                            <td>{dateRange.timezone}</td>
                                          </tr>
                                        )
                                      })}
                                  </React.Fragment>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan='2'>No dates available</td>
                                </tr>
                              )}
                            </tbody>
                          </Table>

                        </form>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
                <br />
                <button
                  style={{
                    textAlign: 'center',
                    width: ' 100%',
                    color: '#ffff',
                    fontSize: '1rem',
                    borderRadius: '.375rem',
                    backgroundColor: '#FF763F',
                    textTransform: 'capitalize',
                    border: 'none',
                    padding: '7px',
                  }}
                  type='submit'
                  className=' btn-warning'
                  //onClick={handleClick()}
                  // onClick={onSubmit3}
                >
                  Save Dates
                </button>
              </div>
            </form>
          </Modal>
        </div>
      </Container>

      <Container>
        <div className='card mb-5 p-10' id='kt_profile_details_view'>
          <div className='card-header p-0'>
            <h2>Payment Details</h2>
          </div>

          <div className='card-body pb-4'>
            <div className='table-responsive'>
              <table className='table table-row-dashed align-middle gs-0 gy-4 my-0'>
                <thead>
                  <tr className='fs-7 fw-bold text-gray-500 border-bottom-0'>
                    <th className='p-0 '>#</th>
                    <th className='p-0 w-200px w-xxl-450px'>Course</th>
                    <th className='p-0 min-w-150px'>Amount</th>
                    <th className='p-0 min-w-150px'>Payment Date</th>
                    <th className='p-0 min-w-150px'>Reference</th>
                    <th className='p-0 min-w-150px'>Actions</th>
                  </tr>
                </thead>
                <>
                  <tbody>
                    {paymentDetail?.data ? (
                      paymentDetail?.data.map((detail, index) => (
                        <tr key={index}>
                          <td className='text-start'>
                            <span className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                              {index + 1}
                            </span>
                          </td>
                          <td>
                            <div className='text-start'>
                              <span className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                                {detail.title}
                              </span>
                            </div>
                          </td>
                          <td className='text-start'>
                            <span className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                              {detail.amountpaid}
                            </span>
                          </td>
                          <td className='text-start'>
                            <span className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                              {formatDate(detail.paymenttime)}
                            </span>
                          </td>
                          <td className='text-start'>
                            <span className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                              {detail.reference}
                            </span>
                          </td>
                          <td>
                            <Tooltip title='Edit'>
                              <button
                                onClick={() => {
                                  handlePaymentEdit(detail.id)
                                }}
                                style={{border: '0', padding: '5px', background: 'white'}}
                              >
                                <span style={{fontSize: '18px', color: 'rgb(126, 130, 153)'}}>
                                  <FaEdit />
                                </span>
                              </button>
                            </Tooltip>

                            {/* <Tooltip title='Delete'>
                              <button
                                onClick={() => {
                                  deleteHandler(studentDate.CourseId, studentDate.type)
                                }}
                                style={{border: '0', padding: '5px', background: 'white'}}
                              >
                                <span style={{fontSize: '18px', color: 'rgb(126, 130, 153)'}}>
                                  <FaTrash />
                                </span>
                              </button>
                            </Tooltip> */}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan='5' className='text-center'>
                          <span className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                            No Data Available
                          </span>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </>
              </table>
            </div>
          </div>
        </div>

        <div>
          <Modal
            title='Edit Payment Details'
            centered
            open={isModal2Open}
            onCancel={handle2Cancel}
            footer={null}
          >
            <form style={{paddingLeft: '1rem'}} onSubmit={onSubmit4}>
              <div style={{paddingLeft: '1rem'}}>
                {loading && (
                  <div className='loader-container'>
                    <Loader />
                  </div>
                )}

                <div>
                  <label className='form-label' style={{fontSize: '15px'}}>
                    Amount
                  </label>
                  <input
                    type='text'
                    className={`form-control mb-2 ${errors.Amount ? 'is-invalid' : ''}`}
                    {...register('Amount', {required: 'Amount is required'})}
                    aria-invalid={errors.Amount ? 'true' : 'false'}
                    value={amount1}
                    onChange={handleAmountChange1}
                  />
                  {errors.Amount && (
                    <p className='alert_danger' role='alert' style={{marginTop: '1rem'}}>
                      {errors.Amount?.message}
                    </p>
                  )}

                  <label className='form-label' style={{fontSize: '15px'}}>
                    Reference
                  </label>
                  <input
                    type='text'
                    className={`form-control mb-2 ${errors.reference ? 'is-invalid' : ''}`}
                    {...register('reference', {required: 'reference is required'})}
                    aria-invalid={errors.reference ? 'true' : 'false'}
                    value={Reference1}
                    onChange={handleReferenceChange1}
                    required
                  />
                  {errors.reference && (
                    <p className='alert_danger' role='alert' style={{marginTop: '1rem'}}>
                      {errors.reference?.message}
                    </p>
                  )}
                </div>

                <div>
                  <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                    <span>Date Purchased</span>
                  </label>

                  <div>
                    <DatePicker
                      className='form-control mb-2'
                      selected={paymentDate}
                      onChange={handlepaymentDateChange}
                      selectsStart
                      dateFormat='dd/MM/yyyy'
                      minDate={new Date()}
                    />
                  </div>
                </div>
                <button type='submit' className='btn cyberbtnallpage'>
                  Save Course
                </button>
              </div>
            </form>
          </Modal>
        </div>
      </Container>

      <Container>
        <div className='card mb-5 p-10'>
          <div className='card-header p-0'>
            <h2>Mentoring Session</h2>
          </div>
          <div className='card-body'>
            <div className='row'>
              <div className='col-4'>
                {bundle_code ? (
                  <form onSubmit={onSubmit}>
                    <div>
                      <div className='d-flex pt-10'>
                        <label className='form-label required' style={{fontSize: '15px'}}>
                          Select a Course:
                        </label>

                        <select
                          className='form-control mb-4'
                          value={selectedbundleId}
                          onChange={handlebundleChange}
                          style={{height: '4%'}}
                          required
                        >
                          <option value=''>Select an option</option>
                          {mybundle && mybundle?.data && mybundle?.data?.length > 0 ? (
                            mybundle?.data.map((bundle) => (
                              <option key={bundle.bundle_id} value={bundle.bundle_id}>
                                {bundle.Name}
                              </option>
                            ))
                          ) : (
                            <option value=''>No courses available</option>
                          )}
                        </select>
                      </div>

                      <div className='d-flex '>
                        <label
                          className='form-label required'
                          style={{fontSize: '15px', width: '20%'}}
                        >
                          Meeting Link :
                        </label>

                        <input
                          type='text'
                          className={`form-control mb-2${errors.link ? 'is-invalid' : ''}`}
                          {...register('link', {
                            required: 'This is required field',
                          })}
                          aria-invalid={errors.link ? 'true' : 'false'}
                          value={selectedlink}
                          onChange={handlelinkChange}
                          required
                        />

                        {errors.link && (
                          <p className='alert_danger' role='alert' style={{marginTop: '1rem'}}>
                            {errors.link?.message}
                          </p>
                        )}
                      </div>
                      <div className='d-flex '>
                        <div className='d-flex'>
                          <label
                            className='slect_course_lablel form-label required'
                            style={{fontSize: '15px', width: '20%'}}
                          >
                            Start Date:
                          </label>
                          <DatePicker
                            className='form-control mb-2'
                            selected={sessionDateee}
                            onChange={handleSessionStartDateChange}
                            selectsStart
                            dateFormat='dd/MM/yyyy'
                            minDate={new Date()}
                          />
                          <select
                            name='startTime'
                            value={startTime}
                            onChange={(e) => setstartTime(e.target.value)}
                            className='form-control mb-2'
                            style={{width: 'auto', height: 'fit-content', marginLeft: '5px'}}
                            required
                          >
                            {options.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div>
                        <button className='btn cyberbtnallpage' type='submit'>
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                ) : null}
              </div>
              <div className='col-8'>
                {bundle_code ? (
                  <div className='card mb-5 p-10' id='kt_profile_details_view'>
                    <div className='cyb-admin-title'>
                      <h4>Booked Mentoring Sessions</h4>
                    </div>
                    <div className='card-body pb-4'>
                      <div className='table-responsive'>
                        <table className='table table-row-dashed align-middle gs-0 gy-4 my-0'>
                          <thead>
                            <tr className='fs-7 fw-bold text-gray-500 border-bottom-0'>
                              <th className='p-0 '>#</th>
                              <th className='p-0 w-200px w-xxl-450px'>Bundle Name</th>
                              <th className='p-0 min-w-150px'>Session</th>
                              <th className='p-0 min-w-150px'>Date</th>
                              <th className='p-0 min-w-150px'>Time</th>
                              <th className='p-0 min-w-150px'>Link</th>
                              <th className='p-0 min-w-150px'>Action</th>
                            </tr>
                          </thead>{' '}
                          <tbody>
                            {Object.keys(usersession).length > 0 ? (
                              <>
                                {Object.values(usersession).map(
                                  (nestedArray, outerIndex) =>
                                    nestedArray.length > 0 &&
                                    nestedArray.map((sess, innerIndex) => (
                                      <tr key={`${outerIndex}-${innerIndex}`}>
                                        <td className='text-start'>
                                          <span className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                                            {serialNumber++}
                                          </span>
                                        </td>
                                        <td>
                                          <div className='text-start'>
                                            <span className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                                              {sess.Name}
                                            </span>
                                          </div>
                                        </td>
                                        <td className='text-start'>
                                          <span className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                                            Session {sess.session}
                                          </span>
                                        </td>
                                        <td className='text-start'>
                                          <span className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                                            {formatDate(sess.datetime)}
                                          </span>
                                        </td>
                                        <td className='text-start'>
                                          <span className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                                            {sess.startTime}
                                          </span>
                                        </td>
                                        <td className='text-start'>
                                          <span className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                                            <div style={{display: 'flex'}}>
                                              {sess.meetingLink.slice(0, 20)}
                                              <CopyToClipboard
                                                text={sess.meetingLink}
                                                onCopy={handleCopy(
                                                  innerIndex,
                                                  'joinUrl',
                                                  setJoinUrlCopyStatuses
                                                )}
                                              >
                                                <div className='copy'>
                                                  <a>
                                                    <i
                                                      style={{fontSize: '20px'}}
                                                      className={`fa-regular fa-copy`}
                                                      disabled={
                                                        !window.ethereum?.selectedAddress ||
                                                        !localStorage.getItem('authToken')
                                                      }
                                                    ></i>
                                                  </a>
                                                </div>
                                              </CopyToClipboard>
                                            </div>
                                            {joinUrlCopyStatuses[innerIndex] && (
                                              <span style={{color: 'green', marginLeft: '5px'}}>
                                                {joinUrlCopyStatuses[innerIndex]}
                                              </span>
                                            )}
                                          </span>
                                        </td>
                                        <td>
                                          <Tooltip title='Edit'>
                                            <button
                                              onClick={() => {
                                                setSelectedSessionId(sess.id)
                                                handleSessionEdit(sess.id)
                                              }}
                                              style={{
                                                border: '0',
                                                padding: '5px',
                                                background: 'white',
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: '18px',
                                                  color: 'rgb(126, 130, 153)',
                                                }}
                                              >
                                                <FaEdit />
                                              </span>
                                            </button>
                                          </Tooltip>

                                          <Tooltip title='Delete'>
                                            <button
                                              onClick={() => {
                                                deleteSessionrHandler(sess.id)
                                              }}
                                              style={{
                                                border: '0',
                                                padding: '5px',
                                                background: 'white',
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: '18px',
                                                  color: 'rgb(126, 130, 153)',
                                                }}
                                              >
                                                <FaTrash />
                                              </span>
                                            </button>
                                          </Tooltip>
                                        </td>
                                      </tr>
                                    ))
                                )}
                              </>
                            ) : (
                              <tr>
                                <td colSpan={6} className='fs-6 text-center'>
                                  No Data Available
                                </td>
                              </tr>
                            )}{' '}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                    <h4>No Data Available</h4>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Container>
        <div className='card mb-5 p-10'>
          <div className='card-header p-0'>
            <h2>Exam Voucher</h2>
          </div>

          <div className='card-body'>
            <div className='row'>
              <div className='selectcourse_voucher col-5'>
                <form onSubmit={onSubmit1}>
                  <div className='d-flex pt-10'>
                    <label
                      className='slect_course_lablel form-label required'
                      style={{fontSize: '15px', width: '29%'}}
                    >
                      Select Course:
                    </label>

                    <select
                      id='dropdown'
                      value={voucherCourse}
                      data-control='select2'
                      onChange={handleVoucherCourseChange}
                      data-placeholder='Select Course'
                      className='form-control mb-4'
                      required
                    >
                      <option
                        value=''
                        style={{
                          color: 'black',
                          fontWeight: '500',
                          background: 'rgb(255, 118, 63)',
                          color: 'white',
                        }}
                      >
                        Select a Course
                      </option>
                      {courses}
                      <option
                        value=''
                        style={{
                          color: 'black',
                          fontWeight: '500',
                          background: 'rgb(255, 118, 63)',
                          color: 'white',
                        }}
                      >
                        Select a Course Bundle
                      </option>
                      {bundles}
                    </select>
                  </div>

                  <div className='d-flex'>
                    <label
                      className='slect_course_lablel form-label required'
                      style={{fontSize: '14px', width: '155px'}}
                    >
                      Voucher Code :
                    </label>
                    <input
                      value={selectedVoucher}
                      type='text'
                      className={`form-control mb-2 ${errors.voucher ? 'is-invalid' : ''}`}
                      {...register('voucher', {required: true})} // Connect the input to the form
                      aria-invalid={errors.voucher ? 'true' : 'false'}
                      onChange={(e) => setSelectedvoucher(e.target.value)}
                      required
                    />
                    {errors.voucher && (
                      <div className='invalid-feedback'>This is required field</div>
                    )}{' '}
                  </div>
                  <div className='d-flex'>
                    <label
                      className='slect_course_lablel form-label required'
                      style={{fontSize: '15px', width: '20%'}}
                    >
                      Expiry Date:
                    </label>
                    <DatePicker
                      className='form-control mb-2'
                      selected={startDate}
                      onChange={handleStartDateChange}
                      selectsStart
                      dateFormat='dd/MM/yyyy'
                      minDate={new Date()}
                    />
                  </div>
                  <div>
                    <button className='btn cyberbtnallpage' type='submit'>
                      Save
                    </button>
                  </div>
                </form>
              </div>

              <div className='student_vochers col-7'>
                <div className='card mb-5 p-10' id='kt_profile_details_view'>
                  <div className='cyb-admin-title'>
                    <h4>Student Vouchers</h4>
                  </div>
                  <div className='card-body pb-4'>
                    <div className='table-responsive'>
                      <table className='table table-row-dashed align-middle gs-0 gy-4 my-0'>
                        <thead>
                          <tr className='fs-7 fw-bold text-gray-500 border-bottom-0'>
                            <th className='p-0 '>#</th>
                            <th className='p-0 w-200px w-xxl-450px'>Bundle Name</th>
                            <th className='p-0 min-w-150px'>Voucher Code</th>
                            <th className='p-0 min-w-150px'>Expiry Date</th>
                            <th className='p-0 min-w-150px'>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {uservoucher?.data && uservoucher?.data?.length > 0 ? (
                            <>
                              {uservoucher.data.map((uservoucher, index) => (
                                <tr key={`uservoucher-${index}`}>
                                  <td className='text-start'>
                                    <span className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                                      {index + 1}
                                    </span>
                                  </td>
                                  <td>
                                    <div className='text-start'>
                                      <span className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                                        {uservoucher.Name || uservoucher.title}
                                      </span>
                                    </div>
                                  </td>
                                  <td className='text-start'>
                                    <span className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                                      {uservoucher.code}
                                    </span>
                                  </td>
                                  <td className='text-start'>
                                    <span className='text-gray-800 fw-bold d-block mb-1 fs-6'>
                                      {formatDate(uservoucher.expiary_date)}
                                    </span>
                                  </td>
                                  <td>
                                    <Tooltip title='Edit'>
                                      <button
                                        onClick={() => {
                                          handleVoucherEdit(uservoucher.id)
                                          setselectedVoucherId(uservoucher.id)
                                        }}
                                        style={{border: '0', padding: '5px', background: 'white'}}
                                      >
                                        <span
                                          style={{fontSize: '18px', color: 'rgb(126, 130, 153)'}}
                                        >
                                          <FaEdit />
                                        </span>
                                      </button>
                                    </Tooltip>

                                    <Tooltip title='Delete'>
                                      <button
                                        onClick={() => {
                                          deleteVoucherHandler(uservoucher.id)
                                        }}
                                        style={{border: '0', padding: '5px', background: 'white'}}
                                      >
                                        <span
                                          style={{fontSize: '18px', color: 'rgb(126, 130, 153)'}}
                                        >
                                          <FaTrash />
                                        </span>
                                      </button>
                                    </Tooltip>
                                  </td>
                                </tr>
                              ))}
                            </>
                          ) : (
                            <tr>
                              <td colSpan={5} className='fs-6 text-center'>
                                No Data Available
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Modal
        title='Edit Mentoring Session'
        centered
        open={isModalSessionOpen}
        onCancel={handleSessionCancel}
        footer={null}
      >
        <div className='row'>
          <div className='col-12'>
            {loading && (
              <div className='loader-container'>
                <Loader />
              </div>
            )}
            {bundle_code ? (
              <form onSubmit={onSubmit}>
                <div>
                  <div className='d-flex'>
                    <label className='form-label required' style={{fontSize: '15px', width: '20%'}}>
                      Meeting Link :
                    </label>

                    <input
                      type='text'
                      className={`form-control mb-2${errors.link ? 'is-invalid' : ''}`}
                      {...register('session_link', {
                        required: 'This is required field',
                      })}
                      aria-invalid={errors.link ? 'true' : 'false'}
                      defaultValue={sessionLink}
                      onChange={handleSessionlinkChange}
                      required
                    />

                    {errors.link && (
                      <p className='alert_danger' role='alert' style={{marginTop: '1rem'}}>
                        {errors.link?.message}
                      </p>
                    )}
                  </div>

                  <div className='d-flex'>
                    <label className='form-label required' style={{fontSize: '15px', width: '17%'}}>
                      Start Date :
                    </label>

                    <DatePicker
                      className='form-control mb-2'
                      selected={sessionDate}
                      onChange={handleSessionDateChange}
                      selectsStart
                      dateFormat='dd/MM/yyyy'
                      // Additional props or styles if needed
                    />
                    <select
                      name='startTime'
                      value={startTimeEdit}
                      onChange={(e) => setstartTimeEdit(e.target.value)}
                      className='form-control mb-2 mr-5'
                      style={{width: 'auto', marginLeft: '5px'}}
                      minDate={new Date()}
                      required
                    >
                      {options.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <button className='btn cyberbtnallpage' type='submit'>
                      Save
                    </button>
                  </div>
                </div>
              </form>
            ) : null}
          </div>
        </div>
      </Modal>

      <Modal
        title='Edit Voucher Details'
        centered
        open={isModalVoucherOpen}
        onCancel={handleVoucherCancel}
        footer={null}
      >
        <form onSubmit={onSubmit1}>
          <div className='d-flex'>
            <label
              className='slect_course_lablel form-label required'
              style={{fontSize: '14px', width: '137px'}}
            >
              Voucher Code :
            </label>
            <input
              type='text'
              className={`form-control mb-2 ${errors.voucher ? 'is-invalid' : ''}`}
              {...register('session_voucher', {required: true})} // Connect the input to the form
              aria-invalid={errors.voucher ? 'true' : 'false'}
              defaultValue={voucherCode}
              onChange={(e) => setvoucherCode(e.target.value)}
              required
            />
            {errors.voucher && <div className='invalid-feedback'>This is required field</div>}{' '}
          </div>

          <div className='d-flex'>
            <label className='form-label required' style={{fontSize: '15px', width: '17%'}}>
              Expiry Date:
            </label>
            <DatePicker
              className='form-control mb-2'
              selected={startVoucherDate}
              onChange={handleVoucherDateChange}
              selectsStart
              dateFormat='dd/MM/yyyy'
              minDate={new Date()}
            />
          </div>

          <div>
            <button className='btn cyberbtnallpage' type='submit'>
              Save
            </button>
          </div>
        </form>
      </Modal>
    </div>
  )
}

export default UserDetails
