import React from 'react'

import targetcuslt from '../../PrivateImage/bundleimg/targetcuslt.png'
import circleculsut from '../../PrivateImage/bundleimg/circleculsut.png'
import adminculs from '../../PrivateImage/bundleimg/adminculs.png'

import admin from '../../PrivateImage/bundleimg/admin.png'
import Joinnow from '../../PrivateImage/bundleimg/Joinnow.png'
import Cta from '../Cta/Cta'

// Groupone

import Groupone from '../../PrivateImage/bundleimg/Groupone.png'
import Grouptwo from '../../PrivateImage/bundleimg/Grouptwo.png'
import Groupthree from '../../PrivateImage/bundleimg/Groupthree.png'

import './Consultancy.css'
const Consultancypage = () => {
  return (
    <div>
      <div className='Consultancypage'>
        <section class='you-know text-center'>
          <div class='container'>
            <h2>Did You Know</h2>
            <div class='row consultancy'>
              <div class='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                <div class='cyber-consultancy1'>
                  <img src={targetcuslt} alt='' />
                </div>
              </div>
              <div class='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 '>
                <div class='cyber-consultancy2'>
                  <img src={circleculsut} alt='' />
                </div>
              </div>
              <div class='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4'>
                <div class='cyber-consultancy3'>
                  <img src={adminculs} alt='' />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className='Consultancypageresponsive'>
        <div
          className='text-center'
          style={{paddingTop: '20px', paddingBottom: '20px', fontSize: '25px'}}
        >
          <h2 style={{color: '#264ACA', fontSize: '25px'}}>Did You Know</h2>
        </div>
        <div class=''>
          <div class='group-consultancy1'>
            <img src={Grouptwo} alt='' />
          </div>

          <div class='group-consultancy2 pt-20'>
            <img src={Groupthree} alt='' />
          </div>

          <div class='group-consultancy3 pt-20 '>
            <img src={Groupone} alt='' />
          </div>
        </div>
      </div>

      <section class='why-cyber text-center '>
        <div class='container'>
          <h2>Why Cyberheads?</h2>
          <p className='consultancy_page_praph'>
            When it comes to cybersecurity, there are major challenges that must be continually
            addressed. This includes ever evolving threats, data breaches and even risk management.
            At Cyberheads, we work to protect your business while staying compliant to regulations
            and data privacy laws.
          </p>

          <div class='row'>
            <div class='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
              <div class='wrapper'>
                <div class='count'>
                  <span>01</span>
                  <div class='border-w'></div>
                </div>
                <h3>Best practices to keep you ahead</h3>
                <p className='wrapper_text'>
                  Our immersive methodologies are built on security best practices aligned to
                  internationally recognised standards - NIST ISO27001.
                </p>
              </div>
            </div>

            <div class='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
              <div class='wrapper'>
                <div class='count'>
                  <span>02</span>
                  <div class='border-w'></div>
                </div>
                <h3>Stay Safe with the Experts</h3>
                <p className='wrapper_text'>
                  With over 40 years of combined experience, our team is led by the best security
                  experts in the UK, including the ex-Head Chair of the UK’s National Cyber Security
                  Centre and CBIE.
                </p>
              </div>
            </div>

            <div class='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
              <div class='wrapper'>
                <div class='count'>
                  <span>03</span>
                  <div class='border-w'></div>
                </div>
                <h3>Our support. Your growth.</h3>
                <p className='wrapper_text'>
                  We aim to raise the digital security profile of organisations in their security
                  maturity journey, while reducing the risks associated with cybersecurity concerns
                  and data theft.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class='our-service'>
        <div class='container'>
          <h2 class='text-center'>Our Services</h2>

          <div class='container'>
            <div class='accordion accordion-flush' id='our_service'>
              <div class='images'>
                <div class='accordion-item'>
                  <div
                    id='service-collapseOne'
                    class='accordion-collapse collapse show'
                    aria-labelledby='service-headingOne'
                    data-bs-parent='#our_service'
                  >
                    <div class='img-wrapper'>
                      <img src={admin} alt='' />
                    </div>
                  </div>
                </div>
                <div class='accordion-item'>
                  <div
                    id='service-collapseTwo'
                    class='accordion-collapse collapse'
                    aria-labelledby='service-headingTwo'
                    data-bs-parent='#our_service'
                  >
                    <div class='img-wrapper'>
                      <img src={admin} alt='' />
                    </div>
                  </div>
                </div>
                <div class='accordion-item'>
                  <div
                    id='service-collapseThree'
                    class='accordion-collapse collapse'
                    aria-labelledby='service-headingThree'
                    data-bs-parent='#our_service'
                  >
                    <div class='img-wrapper'>
                      <img src={admin} alt='' />
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Images added here --> */}

              {/* <!-- content added here --> */}
              <div class='content'>
                <div class='accordion-item'>
                  <h3 class='accordion-header' id='service-headingOne'>
                    <button
                      class='accordion-button'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#service-collapseOne'
                      aria-expanded='true'
                      aria-controls='service-collapseOne'
                    >
                      360 Degree Assessment
                    </button>
                  </h3>
                  <div
                    id='service-collapseOne'
                    class='accordion-collapse collapse show'
                    aria-labelledby='service-headingOne'
                    data-bs-parent='#our_service'
                  >
                    <div class='img-wrapper'>
                      <img src='img/assesment.png' alt='' />
                    </div>
                    <p>
                      This entails a comprehensive 360 degree security assessment of the
                      organisation. How this works is, we look into the security framework, provide
                      a maturity score, highlight the key risks and provide a detailed roadmap on
                      how to improve your security maturity posture.
                    </p>
                  </div>
                </div>
                <div class='accordion-item'>
                  <h3 class='accordion-header' id='service-headingTwo'>
                    <button
                      class='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#service-collapseTwo'
                      aria-expanded='false'
                      aria-controls='service-collapseTwo'
                    >
                      <p>Pen Testing Services</p>
                    </button>
                  </h3>
                  <div
                    id='service-collapseTwo'
                    class='accordion-collapse collapse'
                    aria-labelledby='service-headingTwo'
                    data-bs-parent='#our_service'
                  >
                    <div class='img-wrapper'>
                      <img src='img/testing.png' alt='' />
                    </div>
                    <p>
                      We offer a range of pen testing services to provide a level of assurance that
                      will reduce the risk of cyber threats and data breaches.
                    </p>
                  </div>
                </div>
                <div class='accordion-item'>
                  <h3 class='accordion-header' id='service-headingThree'>
                    <button
                      class='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#service-collapseThree'
                      aria-expanded='false'
                      aria-controls='service-collapseThree'
                    >
                      Data Privacy Officer
                    </button>
                  </h3>
                  <div
                    id='service-collapseThree'
                    class='accordion-collapse collapse'
                    aria-labelledby='service-headingThree'
                    data-bs-parent='#our_service'
                  >
                    <div class='img-wrapper'>
                      <img src='img/privacy.png' alt='' />
                    </div>
                    <p>
                      In a world where data privacy and compliances are critical, where Google and
                      Facebook are paying hefty fines - we provide a monthly service to ensure an
                      organisation is compliant with regulations and the modalities within which it
                      is meant to operate in.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Cta />
    </div>
  )
}

export default Consultancypage
