import React, { useEffect, useState } from 'react'

import pricingIcon from '../../../../../assets/BundleImages/bundle_icon3.svg'
import deliveryIcon from '../../../../../assets/BundleImages/bundle_icon4.svg'
import validityIcon from '../../../../../assets/BundleImages/bundle_icon5.svg'
import durationIcon from '../../../../../assets/BundleImages/bundle_icon9.svg'
import cvIcon from '../../../../../assets/BundleImages/bundle_icon10.svg'
import { GetBundleaction } from '../../../../../Redux/Action/bundleAction'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import { useParams } from 'react-router-dom'
import BundlyBuyBtn from '../BundlyBuyBtn'

const Mentoring = () => {
  const dispatch = useDispatch()

  const bundle_slug = useParams()

  const getbundle = useSelector((state) => {
    return state.login.getbundlebyid
  })

  useEffect(() => {
    dispatch(GetBundleaction(bundle_slug.bundle_slug))
  }, [dispatch, bundle_slug])
  return (
    <div className='container'>
      <div className='row'>
        <div className='col-md-8'>
          <div className='bundle-left-sec'>
            <div className='bundle-overview p-5'>
              <h2 className='heading-blue'>Overview</h2>
              <p className='bundle_detailsprag'>
                The cyber security industry can often seem like a complex and daunting behemoth. To
                aid your progression, each of our bundles includes a guidance programme with
                experienced industry leaders that can prove invaluable in the rapidly evolving field
                of technology and digital security.
              </p>
            </div>

            <div className='bundle-keyTopics p-5'>
              <h2 className='heading-blue'>What’s included:</h2>
              <p>Here are some advantages of working with a Cyberheads mentor: </p>
              <ol className='bundle_detailsprag'>
                <li>Understanding of industry trends</li>
                <li>Guidance for upskilling</li>
                <li>Real-world perspective</li>
                <li>Career guidance</li>
                <li>Personalised advice</li>
                <li>Knowledge of best practices</li>
              </ol>
              {getbundle && getbundle.data && getbundle.data.length > 0 && getbundle.data[0].bundle_pdf ? (
                  <p><a
                    href={getbundle.data[0].bundle_pdf}
                    style={{color: "#264ACA"}}
                    download={getbundle.data[0].bundle_pdf}
                  >
                    Download </a>the full course syllabus to find out more!
                  </p>
                ) : (
                  <p>Download the full course syllabus to find out more!</p>
                )}
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='bundle-right-sec'>
            <div className='bundle-benefits p-5'>
              <div className='heading-icon d-flex'>
                <img src={deliveryIcon} alt='bundle price' className='feature-icon' />
                <div className='feature-content'>
                  <h2 className='heading-orange'>Delivery Mechanism</h2>
                  <ul className='bundle_detailsprag'>
                    <li>Online – Delivered Via Zoom</li>
                  </ul>
                </div>
              </div>
              <div className='heading-icon d-flex'>
                <img src={durationIcon} alt='bundle price' className='feature-icon' />
                <div className='feature-content'>
                  <h2 className='heading-orange'>Duration</h2>
                  <ul className='bundle_detailsprag'>
                    <li>2 x 1 Hour Slots</li>
                  </ul>
                </div>
              </div>
              <div className='heading-icon d-flex'>
                <img src={validityIcon} alt='bundle price' className='feature-icon' />
                <div className='feature-content'>
                  <h2 className='heading-orange'>Validity</h2>
                  <ul className='bundle_detailsprag'>
                    <li>12 months from the date of purchase</li>
                    <li>Book your slot with a mentor via the Cyberheads portal dashboard.</li>
                  </ul>
                </div>
              </div>
              <div className='heading-icon d-flex'>
                <img src={cvIcon} alt='bundle price' className='feature-icon' />
                <div className='feature-content'>
                  <h2 className='heading-orange'>CV Reviews?</h2>
                  <ul className='bundle_detailsprag'>
                    <li>Included</li>
                  </ul>
                </div>
              </div>
              <div className='heading-icon d-flex'>
                <img src={pricingIcon} alt='bundle price' className='feature-icon' />
                <div className='feature-content'>
                  <h2 className='heading-orange'>Total Course Bundle Price</h2>
                  <BundlyBuyBtn />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Mentoring
