import React, {useState, useEffect} from 'react'

import Footer from '../Footer/Footer'

import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined'
import DownloadDoneOutlinedIcon from '@mui/icons-material/DownloadDoneOutlined'
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined'
import {useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import FormData from 'form-data'
import {useForm} from 'react-hook-form'
import StripePay from '../Signup/StripePay/Stripepay'
import paypall from '../../assets/images/paypall.png'
import str from '../../assets/images/str.png'
import {LoginandPayAction} from '../../Redux/Action/authAction'
import Navbar from '../Navbar/Navbar'
import Loader from '../Loader/loader'
import {GetallbundleactionAction} from '../../Redux/Action/bundleAction'
import Spinner from 'react-bootstrap/Spinner'
const Loginandpayment = () => {
  const dispatch = useDispatch()

  const navigate = useNavigate()

  var newURL = window.location
  const [loading, setLoading] = useState(false)
  var splitURL = newURL.href.split('/')

  const course_code = splitURL[4]

  const name = splitURL[3]

  const id = localStorage.getItem('id')

  const [selectedOption, setSelectedOption] = useState('1')

  const type = localStorage.getItem('type')

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value)
  }

  const handleSubmit2 = (event) => {
    event.preventDefault()

    let paymethod

    if (selectedOption === 'paypal') {
      paymethod = 1
    } else if (selectedOption === 'stripe') {
      paymethod = 2
    }
  }

  const {
    handleSubmit,

    formState: {errors},
  } = useForm()

  const courseCode = localStorage.getItem('courseCode')

  const categorizedData = useSelector((state) => state.login.getBundleall_list.categorizedData)

  const microsoftBootcampData = categorizedData && categorizedData.Microsoft_Bootcamp
  const Comptia_Bootcamp = categorizedData && categorizedData.Comptia_Bootcamp

  let MicrosoftDateCourseId
  let CompTIABootcampDateCourseId
  if (microsoftBootcampData && microsoftBootcampData.length > 0) {
    MicrosoftDateCourseId = microsoftBootcampData[0].id
  }

  if (microsoftBootcampData && microsoftBootcampData.length > 0) {
    CompTIABootcampDateCourseId = Comptia_Bootcamp[0].id
  }

  useEffect(() => {
    // debugger
    dispatch(GetallbundleactionAction(localStorage.getItem('courseCode')))
  }, [dispatch, localStorage.getItem('courseCode')])

  const onSubmit = (data) => {
    const formData = new FormData()
    setLoading(true)
    formData.append('email', localStorage.getItem('email'))
    formData.append('password', localStorage.getItem('password'))
    formData.append('userid', localStorage.getItem('userid'))
    formData.append('Token', localStorage.getItem('authToken'))
    formData.append('timezone', localStorage.getItem('timezone'))

    if (course_code) {
      formData.append('paid_price', localStorage.getItem('price'))
      formData.append('course_code', localStorage.getItem('courseCode'))
    } else {
      formData.append('course_code', localStorage.getItem('courseCode'))
      formData.append('paid_price', localStorage.getItem('price'))
    }

    formData.append('paymethod', selectedOption)
    formData.append('id', localStorage.getItem('id'))

    if (type == 'bundle') {
      formData.append('coursetype', 'bundle')
      formData.append('Bundle_code', localStorage.getItem('courseCode'))
      formData.append('MicrosoftCourseId', MicrosoftDateCourseId)
      formData.append('CompTIACourseId', CompTIABootcampDateCourseId)
      formData.append('MicrosoftStartDate', localStorage.getItem('microsoftStartdate'))
      formData.append('MicrosoftEndDate', localStorage.getItem('microsoftEnddate'))
      formData.append('CompTIABootcampStartDate', localStorage.getItem('comptiaStartdate'))
      formData.append('CompTIABootcampEndDate', localStorage.getItem('comptiaEnddate'))
      formData.append('MicrosoftTimezone', localStorage.getItem('MicrosoftTimezone'))
      formData.append('CompTIATimezone', localStorage.getItem('CompTIATimezone'))
      formData.append('MicrosoftdatesId', localStorage.getItem('MicrosoftdatesId'))
      formData.append('CompTIAdatesId', localStorage.getItem('CompTIAdatesId'))
    } else if (type == 'course') {
      formData.append('coursetype', 'course')
      formData.append('Bundle_code', 0)
      formData.append('CourseId', localStorage.getItem('CourseId'))
      formData.append('CourseStartDate', localStorage.getItem('startDate'))
      formData.append('CourseEndDate', localStorage.getItem('endDate'))
      formData.append('courseTimezone', localStorage.getItem('courseTimezone'))
      formData.append('courseDatesId', localStorage.getItem('courseDatesId'))
    } else {
      formData.append('coursetype', 'Elearning')
    }

    dispatch(LoginandPayAction(formData, navigate, setLoading))
  }
  const [cardComplete, setCardComplete] = useState(false)

  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    let timer
    if (selectedOption === '2' && cardComplete) {
      setIsLoading(true)
      setIsButtonDisabled(true)
      timer = setTimeout(() => {
        setIsButtonDisabled(false)
        setIsLoading(false)
      }, 1500)
    } else if (selectedOption === '1'){
      setIsButtonDisabled(false)
    } else {
      setIsButtonDisabled(true)
      setIsLoading(false)
      clearTimeout(timer)
    }

    return () => clearTimeout(timer)
  }, [selectedOption, cardComplete])

  return (
    <div className={loading ? 'blur-form' : ''}>
      <Navbar />

      <div className='banner-content'>
        <h1
          className='banner-title'
          style={{
            position: 'absolute',
            top: '30%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            color: 'white',
            fontSize: '30px',
          }}
        >
          Checkout
        </h1>
      </div>
      <div className='container' style={{paddingBottom: '3rem'}}>
        <div className='row'>
          <div className='col-md-2'></div>
          <div className='col-md-8'>
            {loading && (
              <div className='loader-container'>
                <Loader />
              </div>
            )}

            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{padding: '2rem', borderRadius: '.375rem'}}
            >
              <div className='Insidebuy_course right_course'>
                <div className='row'>
                  <div className='col-md-6 col-sm-12'>
                    <div className='card bg-light shadow-none'>
                      <div className='card-body'>
                        {type == 'bundle' ? (
                          <div>
                            <h3>Your Order</h3>
                            <div>
                              <h3 className='pyment_text'>
                                Bundle : {localStorage.getItem('Name')}
                              </h3>
                              <div>
                                <div>
                                  <h3>Microsoft Bootcamp:</h3>

                                  {microsoftBootcampData ? (
                                    microsoftBootcampData.map((course) => (
                                      <h6>Course: {course.title}</h6>
                                    ))
                                  ) : (
                                    <div></div>
                                  )}
                                  <h6>
                                    Dates: {localStorage.getItem('userMicrosoftStartDate')} -{' '}
                                    {localStorage.getItem('userMicrosoftEndDate')}
                                  </h6>
                                </div>
                                <div>
                                  <h3>CompTIA Bootcamp:</h3>
                                  {Comptia_Bootcamp ? (
                                    Comptia_Bootcamp.map((course) => (
                                      <h6>Course: {course.title}</h6>
                                    ))
                                  ) : (
                                    <div></div>
                                  )}
                                  <h6>
                                    Dates: {localStorage.getItem('userCompTIABootcampStartDate')} -{' '}
                                    {localStorage.getItem('userCompTIABootcampEndDate')}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : type == 'course' ? (
                          <div>
                            <div>
                              <h3 className='pyment_text'>Your Order</h3>

                              <div>
                                <div>
                                  <h5>Course: {localStorage.getItem('title')}</h5>
                                  <h5>
                                    Dates: {localStorage.getItem('UserstartDate')} -{' '}
                                    {localStorage.getItem('UserendDate')}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className='mb-5 pb-10'>
                              <div className='text mb-5'>
                                <h3 className='pyment_text mb-5'>Your Order</h3>
                                <h4 style={{color: '#ff763f'}}>
                                  Course: {localStorage.getItem('title')}
                                </h4>
                                <h4 style={{color: '#ff763f'}} className=' mb-5'>
                                  Validity: 1 Year
                                </h4>
                              </div>
                            </div>
                          </div>
                        )}

                        <div className='col-md-12 mt-10'>
                          <h4 className='total-price d-flex justify-content-between'>
                            <span>Sub Total: </span>£{localStorage.getItem('price')}
                          </h4>
                          <h4 className='total-price d-flex justify-content-between'>
                            <span>Tax: </span>£0
                          </h4>
                          <hr />
                          <h4 className='total-price d-flex justify-content-between'>
                            <span> Total: </span>£{localStorage.getItem('price')}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6 col-sm-12 pt-10'>
                    <div className='card'>
                      <div className='card-body'>
                        <h3 className='pyment_text'>Payment method</h3>
                        <form onSubmit={handleSubmit2} style={{paddingTop: '1rem'}}>
                          <label htmlFor='paypal' style={{paddingTop: '1rem', fontSize: '13px'}}>
                            <div style={{display: 'flex', gap: '1rem'}}>
                              <div>
                                <input
                                  type='radio'
                                  name='paymentOption'
                                  value='1'
                                  id='1'
                                  checked={selectedOption === '1'}
                                  onChange={handleOptionChange}
                                  style={{fontSize: '20px', marginTop: '10px'}}
                                />
                              </div>
                              <div style={{display: 'flex', gap: '1rem'}}>
                                <div>
                                  <img src={paypall} alt='img' style={{width: '120px'}} />
                                </div>
                              </div>
                            </div>
                          </label>

                          <label
                            htmlFor='stripe'
                            style={{
                              fontSize: '13px',
                              display: 'flex',
                              paddingBottom: '1rem',
                              gap: '1rem',
                            }}
                          >
                            <input
                              type='radio'
                              name='paymentOption'
                              value='2'
                              checked={selectedOption === '2'}
                              onChange={handleOptionChange}
                            />
                            <div style={{display: 'flex', gap: '0rem'}}>
                              <div>
                                <img src={str} alt='img' style={{width: '80px'}} />
                              </div>
                            </div>
                          </label>

                          {selectedOption === '2' && (
                            <StripePay setCardComplete={setCardComplete} />
                          )}
                        </form>
                        <div className='text-end mt-10'>
                          <button
                            type='submit'
                            className='btn cyberbtnallpage'
                            disabled={isButtonDisabled}
                          >
                            {isLoading && (
                              <div>
                                {' '}
                                <Spinner
                                  as='span'
                                  animation='border'
                                  role='status'
                                  aria-hidden='true'
                                  variant='secondary'
                                />
                              </div>
                            )}
                            { !isLoading && (
                              <div>
                                Proceed
                              </div>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className='col-md-2'></div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Loginandpayment
