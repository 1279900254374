import React from 'react'
import './Courses.css'
import {useForm} from 'react-hook-form'
const Sectioncourse = () => {
  return (
    <div className='container' style={{maxWidth: '1300px', padding: '4rem 1rem'}}>
      <div className='row'>
        <div className='crriculum col-md-6'>
          <div className='course_sectiontext'>
            <h1>A curriculum that’s outcome oriented at every step.</h1>
          </div>
        </div>
        <div className='crriculum col-md-6'>
          <ul className='sectionimg'>
            <li className='textli'>
              <span className='section_imgtext'>
                Role-based training for specific cybersecurity designations
              </span>
            </li>
          </ul>
          <ul className='sectionimg'>
            <li className='textli'>
              <span className='section_imgtext'>Globally recognized certification</span>
            </li>
          </ul>

          <ul className='sectionimg'>
            <li className='textli'>
              <span className='section_imgtext'>Salary as per industry standards</span>
            </li>
          </ul>
          <ul className='sectionimg'>
            <li className='textli'>
              <span className='section_imgtext'>
                Live foundational knowledge given by the best in the industry{' '}
              </span>
            </li>
          </ul>
          <ul className='sectionimg'>
            <li className='textli'>
              <span className='section_imgtext'>
                Fast track your cybersecurity certification: 4 weeks vs 6 months
              </span>
            </li>
          </ul>
        </div>
      </div>

      <div className='row' style={{paddingTop: '6rem'}}>
        <div className='section2' style={{textAlign: 'center'}}>
          <h3 className='cybersecuirty'>CYBERSECURITY TRAINING COURSES</h3>
          <div style={{paddingTop: '2rem'}}>
            <span className='section2_firsttext'>Thoughtfully Designed Courses </span>
            <br />
            <span className='section3_firsttext'>
              to create the next generation of leaders in cybersecurity
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sectioncourse
