import React, {useEffect, useState} from 'react'

import {Link, useNavigate} from 'react-router-dom'
import JoditEditor from 'jodit-react'
import {useDispatch, useSelector} from 'react-redux'
import Loader from '../../../components/Loader/loader'
import {CreateBundleAction} from '../../../Redux/Action/bundleAction'
import {GetallPublishedLabsAction} from '../../../Redux/Action/challengeLabAction'
import {useForm, Controller} from 'react-hook-form'

const CreateBundle = () => {
  const navigate = useNavigate()

  const dispatch = useDispatch()
  // const [description, Setdescription] = useState('')
  // const [jobOpportunity, SetjobOpportunity] = useState('')
  const [bundle_description, Setbundle_description] = useState('')
  const [includes, Setincludes] = useState('')
  const [benefits, Setbenefits] = useState('')
  const [selectedCheckboxes, setSelectedCheckboxes] = useState()
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    control,

    formState: {errors},
  } = useForm()

  const handleCheckboxChange = (id, value) => {
    setSelectedCheckboxes(id)
  }

  const onSubmit = (data) => {
    const formData = new FormData()
    setLoading(true)

    formData.append('poster_file', data.poster_file[0])
    formData.append('Name', data.Name)
    formData.append('duration', data.duration)
    formData.append('price', data.price)
    formData.append('status', data.status)
    formData.append('bundle_description', data.bundle_description)
    formData.append('includes', data.includes)
    formData.append('benefits', data.benefits)
    formData.append('challengeLabs', selectedCheckboxes)
    if (data.course_pdf) {
      formData.append('bundle_pdf', data.bundle_pdf[0])
    }

    dispatch(CreateBundleAction(formData, navigate, setLoading))
  }
  useEffect(() => {
    dispatch(GetallPublishedLabsAction())
  }, [dispatch])

  const labList = useSelector((state) => {
    return state.login.getPublishedLabsList
  })
  const [selectedFile, setSelectedFile] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const handleFileChange = (e) => {
    debugger
    const file = e.target.files[0]

    if (file) {
      const fileNameParts = file.name.split('.')
      const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase()

      if (fileExtension === 'pdf') {
        setSelectedFile(file)
        setErrorMessage('')
      } else {
        console.log('Invalid file type. Please select a PDF file.')
        e.target.value = null
        setSelectedFile(null)
        setErrorMessage('Invalid file type. Please select a PDF file.')
      }
    }
  }

  return (
    <div>
      <div id='kt_app_content' className='app-content flex-column-fluid'>
        {loading && (
          <div className='loader-container'>
            <Loader />
          </div>
        )}

        <form
          id='kt_ecommerce_add_product_form'
          className='form d-flex flex-column flex-lg-row'
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className='course_category col-md-4'>
            <div className='d-flex flex-column gap-7 gap-lg-10 w-100 mb-7 me-lg-10'>
              <div className='card card-flush py-4'>
                <div className='card-header'>
                  <div className='card-title'>
                    <h2 className='required'>Upload Image</h2>
                  </div>

                  <div className='card-body'>
                    <input
                      className='form-control '
                      type='file'
                      name='image'
                      {...register('poster_file', {
                        required: 'This is required field',
                      })}
                      aria-invalid={errors.poster_file ? 'true' : 'false'}
                    />
                    {errors.poster_file && (
                      <p className='alert_danger' role='alert'>
                        {errors.poster_file?.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className='card-body pt-0'></div>
              </div>

              <div class='card card-flush py-4'>
                <div class='card-header'>
                  <div class='card-title'>
                    <h2>Upload Pdf</h2>
                  </div>
                  <input
                    className='form-control'
                    type='file'
                    name='bundle_pdf'
                    style={{padding: '1rem'}}
                    onChange={handleFileChange}
                    aria-invalid={selectedFile === null ? 'true' : 'false'}
                  />
                </div>

                <div className='card-body pt-0'>
                  {errorMessage && (
                    <p className='alert_danger' role='alert'>
                      {errorMessage}
                    </p>
                  )}
                </div>
              </div>
              <div className='card card-flush py-4'>
                <div className='card-header'>
                  <div className='card-title'>
                    <h2>Challenge Labs</h2>
                  </div>
                </div>
                <div class='card-body pt-0'>
                  <div className='mt-2 bg-white'>
                    {console.log(labList?.labs?.length,"labList?.labs")}
                  {labList?.labs?.length > 0 ? (
                    <>
                      <p>Select Challenge Labs</p>
                      <ul>
                        {labList?.labs?.map((lab, index) => (
                          <li className='form-check' key={`bundle-${index}`}>
                            <input
                              type='radio'
                              className='form-check-input'
                              id={`checkboxBundle-${index}`}
                              value={lab.id}
                              checked={selectedCheckboxes == lab.id}
                              onChange={() => handleCheckboxChange(lab.id)}
                            />
                            <label className='form-label mt-0' htmlFor={`checkboxBundle-${index}`}>
                              {lab.title}
                            </label>
                          </li>
                        ))}
                      </ul>
                    </>
                   ) : (
                    <p>No published labs found</p>
                  )} 
                  </div>
                </div>
              </div>
              <div className='card card-flush py-4'>
                <div className='card-header'>
                  <div className='card-title'>
                    <h2>Status</h2>
                  </div>
                </div>

                <div className='card-body pt-0'>
                  <select
                    className='form-select mb-2'
                    data-control='select2'
                    data-hide-search='true'
                    data-placeholder='Select an option'
                    {...register('status', {
                      required: true,
                    })}
                    defaultValue='Draft' // Move defaultValue inside the opening tag
                    aria-invalid={errors.status ? 'true' : 'false'}
                  >
                    <option></option>
                    <option value='Draft'>Draft</option>
                    <option value='Published'>Published</option>
                  </select>
                  {errors.status?.status === 'required' && (
                    <p className='alert_danger' role='alert'>
                      status is required
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='add_course_section_snd col-md-8'>
            <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
              <div className='tab-content'>
                <div
                  className='tab-pane fade show active'
                  id='kt_ecommerce_add_product_general'
                  role='tab-panel'
                >
                  <div className='d-flex flex-column gap-7 gap-lg-10'>
                    <div className='card card-flush py-4'>
                      <div className='card-body pt-0'>
                        <div className='mb-10 fv-row'>
                          <label className='required form-label'> Bundle Name</label>

                          <input
                            type='text'
                            name='Name'
                            className='form-control mb-2'
                            placeholder='Enter Bundle name'
                            style={{padding: '1rem'}}
                            {...register('Name', {
                              required: 'This is required field',
                            })}
                            aria-invalid={errors.Name ? 'true' : 'false'}
                          />
                          {errors.Name && (
                            <p className='alert_danger' role='alert'>
                              {errors.Name?.message}
                            </p>
                          )}
                        </div>

                        <div className='mb-10 fv-row'>
                          <label className='required form-label'>Price</label>

                          <input
                            type='text'
                            name='price'
                            className='form-control mb-2'
                            placeholder='Enter Price'
                            style={{padding: '1rem'}}
                            {...register('price', {
                              required: 'This field is required',
                              pattern: {
                                value: /^[0-9]+$/,
                                message: 'Please enter only numeric values',
                              },
                            })}
                            aria-invalid={errors.price ? 'true' : 'false'}
                            onKeyPress={(e) => {
                              const isNumeric = /^[0-9]$/
                              if (!isNumeric.test(e.key)) {
                                e.preventDefault()
                              }
                            }}
                          />
                          {errors.price && (
                            <p className='alert_danger' role='alert'>
                              {errors.price?.message}
                            </p>
                          )}
                        </div>

                        <div className='mb-10 fv-row'>
                          <label className='required form-label'>Duration</label>

                          <input
                            type='text'
                            name='duration'
                            className='form-control mb-2'
                            placeholder='Enter Duration'
                            style={{padding: '1rem'}}
                            {...register('duration', {
                              required: 'This is required field',
                            })}
                            aria-invalid={errors.duration ? 'true' : 'false'}
                          />
                          {errors.duration && (
                            <p className='alert_danger' role='alert'>
                              {errors.duration?.message}
                            </p>
                          )}
                        </div>

                        <div>
                          <label className='required form-label'>Bundle Description</label>

                          <Controller
                            control={control}
                            name='bundle_description'
                            defaultValue={bundle_description}
                            rules={{required: true}}
                            render={({field}) => {
                              return (
                                <JoditEditor
                                  ref={field.ref}
                                  value={field.value}
                                  placeholder='start typing'
                                  aria-invalid={errors.bundle_description ? 'true' : 'false'}
                                  tabIndex={1} // tabIndex of textarea
                                  onBlur={(newContent) => {
                                    if (newContent === '<p><br></p>') newContent = ''
                                    Setbundle_description(newContent)

                                    field.onChange(newContent)
                                  }}
                                  onChange={(newContent) => {}}
                                />
                              )
                            }}
                          />
                          {errors.bundle_description?.type === 'required' && (
                            <p className='alert_danger' role='alert'>
                              This is required field
                            </p>
                          )}
                        </div>

                        <div>
                          <label className='required form-label'> What’s Included</label>

                          <Controller
                            control={control}
                            name='includes'
                            defaultValue={includes}
                            rules={{required: true}}
                            render={({field}) => {
                              return (
                                <JoditEditor
                                  ref={field.ref}
                                  value={field.value}
                                  placeholder='start typing'
                                  aria-invalid={errors.includes ? 'true' : 'false'}
                                  tabIndex={1} // tabIndex of textarea
                                  onBlur={(newContent) => {
                                    if (newContent === '<p><br></p>') newContent = ''
                                    Setincludes(newContent)

                                    field.onChange(newContent)
                                  }}
                                  onChange={(newContent) => {}}
                                />
                              )
                            }}
                          />
                          {errors.includes?.type === 'required' && (
                            <p className='alert_danger' role='alert'>
                              This is required field
                            </p>
                          )}
                        </div>

                        <div>
                          <label className='required form-label'>Course Bundle Benefits</label>

                          <Controller
                            control={control}
                            name='benefits'
                            defaultValue={benefits}
                            rules={{required: true}}
                            render={({field}) => {
                              return (
                                <JoditEditor
                                  ref={field.ref}
                                  value={field.value}
                                  placeholder='start typing'
                                  aria-invalid={errors.benefits ? 'true' : 'false'}
                                  tabIndex={1} // tabIndex of textarea
                                  onBlur={(newContent) => {
                                    if (newContent === '<p><br></p>') newContent = ''
                                    Setbenefits(newContent)

                                    field.onChange(newContent)
                                  }}
                                  onChange={(newContent) => {}}
                                />
                              )
                            }}
                          />
                          {errors.benefits?.type === 'required' && (
                            <p className='alert_danger' role='alert'>
                              This is required field
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='d-flex justify-content-end'>
                <Link
                  to='/bundlelist'
                  id='kt_ecommerce_add_product_cancel'
                  className='btn cybercancelbtn me-5'
                >
                  Cancel
                </Link>

                <button
                  type='submit'
                  id='kt_ecommerce_add_product_submit'
                  className='btn cyberbtnallpage'
                >
                  <span className='indicator-label'>Save Changes</span>
                  <span className='indicator-progress'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default CreateBundle
