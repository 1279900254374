import React, {useEffect, useState} from 'react'
import Adminwlcome from '../Sections/Adminwlcome'
import Totalcourse from './Totalcourse'
import Totaluser from './Totaluser'
import Totalbandles from './Totalbandles'
import Coursesaleschart from './Coursesaleschart/Coursesaleschart'
import Topsellingcourses from './Topsellingcourses'
// import Recentpurchescourse from "./Recentpurchescourse/Recentpurchescourse"
import Selectrange from './Recentpurchescourse/Selectrange'
import Allusers from './Allusers'
import Map from './Map/Map'
import ProgressBar from './ProgressBar'
import ApexChart from './ApexChart'
import './section.css'

import Studentlist from '../Sections/Studentlist'
const Dashboard = () => {
  const testData = [
    {bgcolor: '#6a1b9a', completed: 30},
    {bgcolor: '#00695c', completed: 40},
    {bgcolor: '#ef6c00', completed: 50},
    {bgcolor: 'blue', completed: 60},
    {bgcolor: 'rgb(2, 31, 89)', completed: 70},
  ]
  return (
    <div>
      <div className='row'>
        <div className='col-md-12'>
          <div className='admin_dash_res row'>
            <div className='admin_dash col-lg-8 col-md-12 col-md-12'>
              <Adminwlcome />

              {/* <div className='alluser_admin d-flex flex-wrap'>
                <div className='alluser_cards '>
                  <Allusers />
                </div>
                <div className='totalcourse_dash '>
                  <Totalcourse />
                </div>
                <div className='totalbandles_dash '>
                  <Totalbandles />
                </div>
              </div> */}

              <div className='alluser_admin d-flex flex-wrap card-container'>
                <div className='alluser_cards card-item'>
                  <Allusers />
                </div>
                <div className='totalcourse_dash card-item'>
                  <Totalcourse />
                </div>
                <div className='totalbandles_dash card-item'>
                  <Totalbandles />
                </div>
              </div>
            </div>
            <div className='totaluser_dash col-lg-4 col-md-12 col-md-12'>
              <div className='card' style={{borderLeft: '3px solid #FF763F'}}>
                <div className='card-body'>
                  <Totaluser />
                </div>
              </div>
            </div>
          </div>
          <div className='sales_overview row mt-10'>
            <div className='col-md-12'>
              <div className='card' style={{height: '500px'}}>
                <div className='card-body' style={{overflow: 'auto'}}>
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <h3 className='fw-bold m-0 pt-4 pb-4' style={{color: '#021F59'}}>
                      Sales Overview
                    </h3>
                    <h3 className='fw-bold m-0 pt-4 pb-4' style={{color: '#021F59'}}>
                      Custom Range
                    </h3>
                  </div>
                  <ApexChart />
                </div>
              </div>
            </div>
          </div>
          <div className='studentlist row pt-5' style={{width: '100vw', overflowX: 'auto'}}>
            <div className='col-md-12'>
              <div className='card'>
                <div className='card-body'>
                  <h3 className='fw-bold m-0 pt-4 pb-4' style={{color: '#021F59'}}>
                    All Purchases
                  </h3>
                  <Studentlist />
                </div>
              </div>
            </div>
          </div>

          <div className='row pt-10'>
            <div className='col-md-12'>
              <div className='corse_chart row'>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                  <Coursesaleschart />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                  <div className='map_resposnive margin'>
                    <Map />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row pt-10'>
            <div className='col-md-12'>
              <div className='topselling_course row'>
                <div className='col-md-6 order-md-2'>
                  {' '}
                  {/* Order 2 on medium-sized screens */}
                  <Topsellingcourses />
                </div>
                <div className='col-md-12 col-lg-6 order-md-1'>
                  {' '}
                  {/* Order 1 on medium-sized screens */}
                  <Selectrange />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className='row'>
        <div className='col-md-4'>
          <div className='card taller-card'>
            <div className='card-body'>
            <h3 className='fw-bold m-0 pt-4 pb-4' style={{color: '#021F59'}}>
            Progress Tracker
            </h3>
              {testData.map((item, idx) => (
                <div>
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <span className='progress-label'>Server Migration</span>
                    <span>{item.completed}%</span>
                  </div>
                  <ProgressBar key={idx} bgcolor={item.bgcolor} completed={item.completed} />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div>

        <div className='col-md-6'>
          <div className='card taller-card'>
            <div className='card-body'>
            <h3 className='fw-bold m-0 pt-4 pb-4' style={{color: '#021F59'}}>
            Progress Tracker
            </h3>
            <ApexChart />
            </div>
          </div>
        </div>


       
        </div>
      </div> */}
    </div>
  )
}

export default Dashboard
