import React from 'react'
import profill from '../../assets/images/profill.png'
import './Tablelinetab.css'
// D:\srishty\Cyberhead\cyberheads\client\src\assets\images\profill.png
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary'
import PermMediaIcon from '@mui/icons-material/PermMedia'

import Rating from './Rating/Rating'

const Overview = () => {
  return (
    <div className='container'>
      <div className='row' style={{paddingTop: '3rem'}}>
        <div className='col-md-8'>
          <div className='row'>
            <div className='col-md-6'>
              <p>INSTRUCTOR</p>
              <div className='row'>
                <div style={{display: 'flex'}}>
                  <img src={profill} alt='img' style={{width: '95px'}} />
                  <div>
                    <p>Dennis Taylor</p>
                    <h6 className='base-main-card__subtitle body-text text-color-text overflow-hidden'>
                      Excel expert 25+ years of experience
                    </h6>
                    <button className='btnfollow'>+ follow on Linkedin</button>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <p style={{fontSize: '15px'}}>RELATED TO THIS COURSE</p>
              <div style={{display: 'flex', gap: '1rem'}}>
                <LocalLibraryIcon style={{fontSize: '20px'}} />
                <div style={{display: 'flex', gap: '1rem'}}>
                  <div>
                    <p style={{fontSize: '12px', fontWeight: '500'}}>Learning Grops</p>
                  </div>

                  <div>
                    <p style={{color: 'orange', fontSize: '14px'}}>Show all</p>
                  </div>
                </div>
              </div>
              <div style={{display: 'flex', gap: '1rem'}}>
                <PermMediaIcon style={{fontSize: '20px'}} />
                <div style={{display: 'flex', gap: '1rem'}}>
                  <div>
                    <p style={{fontSize: '12px', fontWeight: '500'}}>Exercise Files(1)</p>
                  </div>

                  <div>
                    <p
                      style={{
                        color: 'orange',
                        fontSize: '12px',
                        fontWeight: '500',
                      }}
                    >
                      Show all
                    </p>
                  </div>
                </div>
              </div>
              <div style={{display: 'flex', gap: '1rem'}}>
                <LocalLibraryIcon style={{fontSize: '20px'}} />
                <div style={{display: 'flex', gap: '1rem'}}>
                  <div>
                    <p style={{fontSize: '12px', fontWeight: '500'}}>Certificates</p>
                  </div>

                  <div>
                    <p
                      style={{
                        color: 'orange',
                        fontSize: '12px',
                        fontWeight: '500',
                      }}
                    >
                      Show all
                    </p>
                  </div>
                </div>
              </div>
              <div style={{display: 'flex', gap: '1rem'}}>
                <LocalLibraryIcon style={{fontSize: '20px'}} />
                <div style={{display: 'flex', gap: '1rem'}}>
                  <div>
                    <p style={{fontSize: '12px', fontWeight: '500'}}>Certifying Organizations</p>
                  </div>

                  <div>
                    <p
                      style={{
                        color: 'orange',
                        fontSize: '12px',
                        fontWeight: '500',
                      }}
                    >
                      Show all
                    </p>
                  </div>
                </div>
              </div>
              <div style={{display: 'flex', gap: '1rem'}}>
                <LocalLibraryIcon style={{fontSize: '20px'}} />
                <div style={{display: 'flex', gap: '1rem'}}>
                  <div>
                    <p style={{fontSize: '12px', fontWeight: '500'}}>Exam</p>
                  </div>

                  <div>
                    <p
                      style={{
                        color: 'orange',
                        fontSize: '12px',
                        fontWeight: '500',
                      }}
                    >
                      Take exam
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='overview_header' style={{fontSize: '15px'}}>
              <h3>Course details</h3>
              <ul
                className=''
                style={{display: 'flex', gap: '1rem', padding: '0px', listStyle: 'none'}}
              >
                <li> 1h 13m</li>
                <li>Advanced</li>
                <li>Updated:4/12/2022</li>
              </ul>
            </div>
            {/* <div className="classroom-body__workspace-overview-ratings-container">
              <button
                className="classroom-body__workspace-overview-scroll-reviews-btn"
                style={{ display: "flex" }}
              >
                <span className="t-18 t-normal" style={{ fontSize: "15px" }}>
                  4.8
                </span>
                <div className="ratings-display classroom-body__workspace-overview-ratings-display">
                  <StarIcon style={{ fontSize: "20px", color: "#c37d16" }} />
                  <StarIcon style={{ fontSize: "20px", color: "#c37d16" }} />
                  <StarIcon style={{ fontSize: "20px", color: "#c37d16" }} />
                  <StarIcon style={{ fontSize: "20px", color: "#c37d16" }} />
                  <StarIcon style={{ fontSize: "20px", color: "#c37d16" }} />
                  <span className="t-black--light t-normal t-16">(863)</span>
                </div>
              </button>
            </div> */}
            <div className='row'>
              <div
                className='classroom-workspace-overview__description
               _bodyText_1e5nen _default_1i6ulk _sizeMedium_1e5nen'
                style={{fontSize: '15px'}}
              >
                <p>
                  React Hooks embed logic into functions in new React applications. In this course,
                  instructor Eve Porcello covers various styles of Hooks usage and ways to integrate
                  them into your programming workflow. She tells you how Hooks came to be part of
                  the library, then walks you through how to install Create React App. Eve covers
                  how you can use the useState Hook to handle different types of state variables and
                  work with component trees. The useEffect Hook can be used for several application
                  side effects, whenever the state of the app changes. Eve teaches you how to work
                  with the dependency array effect and fetch data with the useEffect Hook. Next, she
                  explains several additional Hooks and steps through the process of creating custom
                  Hooks. In conclusion, Eve shows you some different directions you can pursue to
                  improve your knowledge of the React ecosystem.
                </p>
              </div>
            </div>
            <div className='col' style={{paddingTop: '2rem', flexDirection: 'column'}}>
              <h3 className='classroom-workspace-overview__header t-25 t-bold'>Skills covered</h3>
              <button
                style={{
                  border: '1px solid grey',
                  borderRadius: '2rem',
                  padding: '10px',
                  fontSize: '14px',
                }}
              >
                Skills covered
              </button>
            </div>
            <div className='row' style={{paddingTop: '2rem'}}>
              <div className='learner_review'>
                <h3>Learner reviews</h3>
              </div>
            </div>

            <Rating />
          </div>
        </div>

        <div className='col-md-4'>
          <div className='row'>
            <p>Related Courses</p>
            <div className='col-md-6'>
              <div>
                <video controls width='100%' style={{borderRadius: '4px'}}>
                  <source src='/video-example.webm' type='video/webm' />
                  <source src='/video-example.mp4' type='video/mp4' />
                  Sorry, your browser doesn't support videos.
                </video>
              </div>
            </div>

            <div className='col-md-6'>
              <h6 className='base-aside-card__title font-sans text-sm font-bold text-color-text relative'>
                COURSE
                <br /> Excel:Power Query <br />
                (Get & Transform)
              </h6>
              <span style={{fontSize: '10px'}}>202,874 Learners</span>
            </div>

            <div className='col-md-6' style={{paddingTop: '1rem'}}>
              <div>
                <video controls width='100%' style={{borderRadius: '4px'}}>
                  <source src='/video-example.webm' type='video/webm' />
                  <source src='/video-example.mp4' type='video/mp4' />
                  Sorry, your browser doesn't support videos.
                </video>
              </div>
            </div>

            <div className='col-md-6' style={{paddingTop: '1rem'}}>
              <h6 className='base-aside-card__title font-sans text-sm font-bold text-color-text relative'>
                COURSE
                <br /> Excel:Power Query
                <br /> (Get & Transform)
              </h6>
              <span style={{fontSize: '10px'}}>202,874 Learners</span>
            </div>
            <div className='col-md-6' style={{paddingTop: '1rem'}}>
              <div>
                <video controls width='100%' style={{borderRadius: '4px'}}>
                  <source src='/video-example.webm' type='video/webm' />
                  <source src='/video-example.mp4' type='video/mp4' />
                  Sorry, your browser doesn't support videos.
                </video>
              </div>
            </div>
            <div className='col-md-6' style={{paddingTop: '1rem'}}>
              <h6 className='base-aside-card__title font-sans text-sm font-bold text-color-text relative'>
                COURSE
                <br /> Excel:Power Query <br />
                (Get & Transform)
              </h6>
              <span style={{fontSize: '10px'}}>202,874 Learners</span>
            </div>
            <div className='col-md-6' style={{paddingTop: '1rem'}}>
              <div>
                <video controls width='100%' style={{borderRadius: '4px'}}>
                  <source src='/video-example.webm' type='video/webm' />
                  <source src='/video-example.mp4' type='video/mp4' />
                  Sorry, your browser doesn't support videos.
                </video>
              </div>
            </div>
            <div className='col-md-6' style={{paddingTop: '1rem'}}>
              <h6 className='base-aside-card__title font-sans text-sm font-bold text-color-text relative'>
                COURSE <br />
                Excel:Power Query <br />
                (Get & Transform)
              </h6>
              <span style={{fontSize: '10px'}}>202,874 Learners</span>
            </div>
            <div className='col-md-6' style={{paddingTop: '1rem'}}>
              <div>
                <video controls width='100%' style={{borderRadius: '4px'}}>
                  <source src='/video-example.webm' type='video/webm' />
                  <source src='/video-example.mp4' type='video/mp4' />
                  Sorry, your browser doesn't support videos.
                </video>
              </div>
            </div>
            <div className='col-md-6' style={{paddingTop: '1rem'}}>
              <h6 className='base-aside-card__title font-sans text-sm font-bold text-color-text relative'>
                COURSE <br />
                Excel:Power Query
                <br /> (Get & Transform)
              </h6>
              <span style={{fontSize: '10px'}}>202,874 Learners</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Overview

{
  /* <video controls width="100%">
              <source src="/video-example.webm" type="video/webm" />
              <source src="/video-example.mp4" type="video/mp4" />
              Sorry, your browser doesn't support videos.
            </video> */
}
