import React, {useEffect, useState} from 'react'
import {useRef} from 'react'
import {Link, useNavigate} from 'react-router-dom'

import {useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'

import {
  GetBundleaction,
  GetallbundleactionAction,
  StudentAction,
} from '../../../../Redux/Action/bundleAction'
import {Modal} from 'antd'

import Table from 'react-bootstrap/Table'
import swal from 'sweetalert'
import moment from 'moment-timezone'


function BundleBannerBtn() {
  const dispatch = useDispatch()
  var newURL = window.location

  const [open, setOpen] = useState(false)

  const showModal = () => {
    setOpen(true)
  }

  const hideModal = () => {
    setOpen(false)
  }
  const navigate = useNavigate()
  var splitURL = newURL.href.split('/')

  const bundle_slug = splitURL[4]
  const Bundle_code = splitURL[5]

  const getbundle = useSelector((state) => {
    return state.login.getbundlebyid
  })
  let cdata = ''
  useEffect(() => {
    // debugger
    dispatch(GetBundleaction(bundle_slug))
  }, [dispatch, bundle_slug])

  const categorizedData = useSelector((state) => state.login.getBundleall_list.categorizedData)
  const microsoftBootcampData = categorizedData && categorizedData.Microsoft_Bootcamp
  const Comptia_Bootcamp = categorizedData && categorizedData.Comptia_Bootcamp

  useEffect(() => {
    dispatch(GetallbundleactionAction(bundle_slug))
  }, [dispatch, bundle_slug])

  const [selectedMicrosoftStartDate, setSelectedMicrosoftStartDate] = useState(null)
  const [selectedMicrosoftEndDate, setSelectedMicrosoftEndDate] = useState(null)
  const [selectedCompTIABootcampStartDate, setSelectedCompTIABootcampStartDate] = useState(null)
  const [selectedCompTIABootcampEndDate, setSelectedCompTIABootcampEndDate] = useState(null)
  const [MicrosoftDateCourseId, setSelectedMicrosoftDateCourseId] = useState(null)
  const [CompTIABootcampDateCourseId, setCompTIABootcampDateCourseId] = useState(null)
  const [MicrosoftTimezone, setMicrosoftTimezone] = useState(null)
  const [ComptiaTimezone, setComptiaTimezone] = useState(null)
  const [selectedPrice, setSelectedBundlePrice] = useState(null)
  const [selectedTimezone, setSelectedtimezone] = useState(null)
  const [MicrosoftdatesId, setMicrosoftDatesId] = useState(null)
  const [CompTIAdatesId, setCompTIADatesId] = useState(null)
  const [userMicrosoftStartDate, setUserMicrosoftStartDate] = useState(null)
  const [userMicrosoftEndDate, setUserMicrosoftEndDate] = useState(null)
  const [userCompTIABootcampStartDate, setUserCompTIABootcampStartDate] = useState(null)
  const [userCompTIABootcampEndDate, setUserCompTIABootcampEndDate] = useState(null)
  const [microsoftseats, setMicrosoftSeats] = useState(null)
  const [comptiaseats, setComptiaSeats] = useState(null)
  const [microsoftsales, setMicrosoftSales] = useState(null)
  const [comptiasales, setComptiaSales] = useState(null)

  localStorage.setItem('price', selectedPrice)

  const {
    register,
    handleSubmit,

    formState: {errors},
  } = useForm()
  const handleMicrosoftDateChange = (
    courseId,
    formattedStartDate,
    formattedEndDate,
    timezone,
    datesId,
    seats,
    sales
  ) => {
    setSelectedMicrosoftStartDate(formattedStartDate)
    setSelectedMicrosoftEndDate(formattedEndDate)
    setSelectedMicrosoftDateCourseId(courseId)
    setMicrosoftTimezone(timezone)
    setMicrosoftDatesId(datesId)
    setMicrosoftSeats(seats)
    setMicrosoftSales(sales)
  }

  const handleCompTIABootcampDateChange = (
    courseId,
    formattedStartDate,
    formattedEndDate,
    timezone,
    datesId,
    seats,
    sales
  ) => {
    setSelectedCompTIABootcampStartDate(formattedStartDate)
    setSelectedCompTIABootcampEndDate(formattedEndDate)
    setCompTIABootcampDateCourseId(courseId)
    setComptiaTimezone(timezone)
    setCompTIADatesId(datesId)
    setComptiaSeats(seats)
    setComptiaSales(sales)
  }
  const studentId = localStorage.getItem('userid')

  const authToken = localStorage.getItem('authToken')
  const data = {
    Bundle_code: Bundle_code,
    MicrosoftCourseId: MicrosoftDateCourseId,
    CompTIACourseId: CompTIABootcampDateCourseId,
    selectedMicrosoftStartDate: selectedMicrosoftStartDate,
    selectedMicrosoftEndDate: selectedMicrosoftEndDate,
    selectedCompTIABootcampStartDate: selectedCompTIABootcampStartDate,
    selectedCompTIABootcampEndDate: selectedCompTIABootcampEndDate,
  }

  const isFormFilled = () => {
    return (
      data.Bundle_code &&
      data.MicrosoftCourseId &&
      data.CompTIACourseId &&
      data.selectedMicrosoftStartDate &&
      data.selectedMicrosoftEndDate &&
      data.selectedCompTIABootcampStartDate &&
      data.selectedCompTIABootcampEndDate
    )
  }

  const onSubmit = () => {
    debugger
    if (comptiasales < comptiaseats && microsoftsales < microsoftseats) {
      const formData = new FormData()
      formData.append('studentId', studentId)
      formData.append('coursetype', 'bundle')
      formData.append('Bundle_code', Bundle_code)
      formData.append('MicrosoftCourseId', MicrosoftDateCourseId) // Include Microsoft course ID
      formData.append('CompTIACourseId', CompTIABootcampDateCourseId) // Include CompTIA Bootcamp course ID
      formData.append('MicrosoftStartDate', selectedMicrosoftStartDate)
      formData.append('MicrosoftEndDate', selectedMicrosoftEndDate)
      formData.append('CompTIABootcampStartDate', selectedCompTIABootcampStartDate)
      formData.append('CompTIABootcampEndDate', selectedCompTIABootcampEndDate)
      formData.append('MicrosoftTimezone', MicrosoftTimezone)
      formData.append('CompTIATimezone', ComptiaTimezone)
      formData.append('MicrosoftdatesId', MicrosoftdatesId)
      formData.append('CompTIAdatesId', CompTIAdatesId)

      dispatch(StudentAction(formData, Bundle_code, navigate, onSubmit))
    } else if (comptiasales == comptiaseats || microsoftsales == microsoftseats) {
      swal({
        title: 'Seats not available',
        text: 'Seats not available for this date. Please contact admin.',
        icon: 'warning',
        closeOnClickOutside: false,
        buttons: {
          cancel: {
            text: 'OK',
            value: null,
            visible: true,
            className: 'swal-button--confirm cyberbtnallpage',
          },
        },
      })
    }
  }
  const bundletitleRef = useRef()

  const scrollToBundletitle = () => {
    const element = document.getElementById('bundletitle')
    if (element) {
      element.scrollIntoView({behavior: 'smooth'})
    }
  }

  useEffect(() => {
    scrollToBundletitle()
  }, [])

  localStorage.setItem('microsoftStartdate', selectedMicrosoftStartDate)
  localStorage.setItem('microsoftEnddate', selectedMicrosoftEndDate)
  localStorage.setItem('comptiaStartdate', selectedCompTIABootcampStartDate)
  localStorage.setItem('comptiaEnddate', selectedCompTIABootcampEndDate)
  localStorage.setItem('type', 'bundle')
  localStorage.setItem('courseCode', Bundle_code)
  localStorage.setItem('timezone', selectedTimezone)
  localStorage.setItem('MicrosoftTimezone', MicrosoftTimezone)
  localStorage.setItem('CompTIATimezone', ComptiaTimezone)
  localStorage.setItem('userMicrosoftStartDate', userMicrosoftStartDate)
  localStorage.setItem('userMicrosoftEndDate', userMicrosoftEndDate)
  localStorage.setItem('userCompTIABootcampStartDate', userCompTIABootcampStartDate)
  localStorage.setItem('userCompTIABootcampEndDate', userCompTIABootcampEndDate)
  localStorage.setItem('MicrosoftdatesId', MicrosoftdatesId)
  localStorage.setItem('CompTIAdatesId', CompTIAdatesId)

  const userLocale = navigator.language || 'en-UK'
  let asiaTimezone = Intl.DateTimeFormat(userLocale).resolvedOptions().timeZone

  function formatDate(dateString, timezone) {
    if (dateString) {
      const inputDate = moment.tz(dateString, 'YYYY-MM-DD HH:mm:ss', timezone)
      const localDate = inputDate.clone().local()

      return localDate.format('YYYY-MM-DD HH:mm:ss')
    } else {
      return null
    }
  }

  const formatDateString = (date) => {
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true, // To use 12-hour format
    }
    return new Date(date).toLocaleDateString('en-GB', options)
  }



  return (
    <div className='container'>
      {getbundle &&
        Object.entries(getbundle).map(([key, course], i) => {
          if (key == 'data') {
            cdata = course
          }
        })}

      {cdata &&
        Object.entries(cdata).map(([key, item], index) => {
          return (
            <div className='row'>
              <div className='col-md-12'>
                <h1
                  style={{color: '#fff', marginTop: '10%', fontSize: '45px', marginBottom: '30px'}}
                >
                  {item.Name}
                </h1>

                <Link
                  to='#'
                  className='button button-rounded'
                  type='primary'
                  style={{marginTop: '20px'}}
                  onClick={() => {
                    setSelectedBundlePrice(item.price)
                    showModal()
                  }}
                >
                  BUY NOW
                </Link>
              </div>
            </div>
          )
        })}

      <Modal
        title='Dates Available'
        open={open}
        onCancel={hideModal}
        footer={null}
        centered
        wrapClassName='custom-modal'
      >
        <div>
          <div className='container'>
            <div className='Mylearning' style={{width: '100%'}}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <div
                    className='book-view'
                    style={{
                      display: 'flex',
                      gap: '10px',
                      padding: '5px',
                      borderRadius: '4px',
                      background: '#264ACA',
                    }}
                  >
                    <h4 class='card-title align-items-start flex-column'>
                      <span class='card-label fw-bold text-800' style={{color: '#fff'}}>
                        Microsoft Bootcamp
                      </span>
                    </h4>
                  </div>

                  <Table
                    bordered
                    id='Elevenplusyear_castomer'
                    style={{
                      tableLayout: 'fixed',
                    }}
                  >
                    <tbody style={{border: '1px solid #CECFCF', fontSize: '15px'}}>
                      <tr>
                        <td width={40}></td>
                        <th>Start Date & Time</th>
                        <th>End Date & Time</th>
                        <th>Timezone</th>
                      </tr>
                      {microsoftBootcampData ? (
                        microsoftBootcampData.map((course) => (
                          <React.Fragment key={course.id}>
                            {course.date_ranges &&
                              course.date_ranges.map((dateRange, index) => {
                                
                                return (
                                  <tr key={index} style={{border: '1px solid #CECFCF'}}>
                                    <td colSpan='2'>
                                      <input
                                        type='radio'
                                        name={`Microsoft`}
                                        value={formatDateString(
                                          formatDate(dateRange.start_datetime, dateRange.timezone)
                                        )}
                                        onChange={
                                          () => {
                                            setUserMicrosoftStartDate(
                                              formatDateString(
                                                formatDate(
                                                  dateRange.start_datetime,
                                                  dateRange.timezone
                                                )
                                              )
                                            )
                                            setUserMicrosoftEndDate(
                                              formatDateString(
                                                formatDate(
                                                  dateRange.end_datetime,
                                                  dateRange.timezone
                                                )
                                              )
                                            )
                                            handleMicrosoftDateChange(
                                              course.id,
                                              dateRange.start_datetime,
                                              dateRange.end_datetime,
                                              dateRange.timezone,
                                              dateRange.datesId,
                                              dateRange.SeatsCount,
                                              dateRange.salesCount
                                            )
                                          } // Pass courseId and formattedDate
                                        }
                                      />
                                      &nbsp; &nbsp;
                                      {formatDateString(
                                        formatDate(dateRange.start_datetime, dateRange.timezone)
                                      )}
                                    </td>
                                    <td>
                                      {formatDateString(
                                        formatDate(dateRange.end_datetime, dateRange.timezone)
                                      )}
                                    </td>
                                    <td>{asiaTimezone}</td>
                                  </tr>
                                )
                              })}
                          </React.Fragment>
                        ))
                      ) : (
                        <tr>
                          <td colSpan='2'>No dates available</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>

                  <div className='Mylearning' style={{width: '100%'}}>
                    <div>
                      <div
                        className='book-view'
                        style={{
                          display: 'flex',
                          gap: '10px',
                          padding: '5px',
                          borderRadius: '4px',
                          background: '#264ACA',
                        }}
                      >
                        <h4 class='card-title align-items-start flex-column'>
                          <span class='card-label fw-bold text-800' style={{color: '#fff'}}>
                            CompTIA Bootcamp
                          </span>
                        </h4>
                      </div>

                      <Table
                        bordered
                        id='Elevenplusyear_castomer'
                        style={{
                          tableLayout: 'fixed',
                          maxHeight: '300px', // Set the maximum height as needed
                          overflowY: 'auto', // Add a vertical scrollbar when content overflows
                        }}
                      >
                        <tbody
                          style={{
                            border: '1px solid #CECFCF',
                            fontSize: '15px',
                          }}
                        >
                          <tr>
                            <td width={40}></td>
                            <th>Start Date & Time</th>
                            <th>End Date & Time</th>
                            <th>Timezone</th>
                          </tr>
                          {Comptia_Bootcamp ? (
                            Comptia_Bootcamp.map((course) => (
                              <React.Fragment key={course.id}>
                                {course &&
                                  course.date_ranges.map((dateRange, index) => {

                                    return (
                                      <tr key={index} style={{border: '1px solid #CECFCF'}}>
                                        <td colSpan='2'>
                                          <input
                                            type='radio'
                                            name={`Comptia`}
                                            value={formatDateString(
                                              formatDate(dateRange.start_datetime, dateRange.timezone)
                                            )}
                                            // checked={
                                            //   selectedCompTIABootcampStartDate ===
                                            //   formattedStartDate
                                            // }
                                            onChange={
                                              () => {
                                                setUserCompTIABootcampStartDate(formatDateString(
                                                  formatDate(dateRange.start_datetime, dateRange.timezone)
                                                ))
                                                setUserCompTIABootcampEndDate(formatDateString(
                                                  formatDate(dateRange.end_datetime, dateRange.timezone)
                                                ))
                                                handleCompTIABootcampDateChange(
                                                  course.id,
                                                  dateRange.start_datetime,
                                                  dateRange.end_datetime,
                                                  dateRange.timezone,
                                                  dateRange.datesId,
                                                  dateRange.SeatsCount,
                                                  dateRange.salesCount
                                                )
                                              } // Pass courseId and formattedDate
                                            }
                                          />
                                          &nbsp; &nbsp;
                                          {formatDateString(
                                            formatDate(dateRange.start_datetime, dateRange.timezone)
                                          )}
                                        </td>
                                        <td>
                                          {formatDateString(
                                            formatDate(dateRange.end_datetime, dateRange.timezone)
                                          )}
                                        </td>
                                        <td>{asiaTimezone}</td>
                                      </tr>
                                    )
                                  })}
                              </React.Fragment>
                            ))
                          ) : (
                            <tr>
                              <td colSpan='2'>No dates available</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
                {/* <div style={{paddingLeft: '83%'}}>
                    <button class='buttonlaunch button b-0px' type='submit'>
                      submit
                    </button>
                  </div> */}

                <div>
                  {authToken && isFormFilled() ? (
                    <button className='buttonlaunch button b-0px' 
                    style={{
                      width: '20%',
                      justifyContent: 'end',
                      display: 'flex',
                      justifyContent: 'center',
                    }}>Submit</button>
                  ) : comptiasales == comptiaseats || microsoftsales == microsoftseats ? (
                    <div>
                      <button
                        className='buttonlaunch button b-0px'
                        style={{
                          width: '20%',
                          justifyContent: 'end',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                        onClick={() => {
                          swal({
                            title: 'Seats not available',
                            text: 'Seats not available for this date. Please contact admin.',
                            icon: 'warning',
                            closeOnClickOutside: false,
                            buttons: {
                              cancel: {
                                text: 'OK',
                                value: null,
                                visible: true,
                                className: 'swal-button--confirm cyberbtnallpage',
                              },
                            },
                          })
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  ) : isFormFilled() ? (
                    <Link
                      to={`/signup?&${data.Bundle_code}&${data.MicrosoftCourseId}&${data.CompTIACourseId}&${data.selectedMicrosoftStartDate}&${data.selectedMicrosoftEndDate}&${data.selectedCompTIABootcampStartDate}&${data.selectedCompTIABootcampEndDate}`}
                      className='buttonlaunch button b-0px'
                      style={{
                        width: '20%',
                        justifyContent: 'end',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      Submit
                    </Link>
                  ) : (
                    <div>
                      <span style={{width: '200px', fontSize: '15px'}}>
                        Please Select The Dates.
                      </span>
                      <button
                        className='buttonlaunch button b-0px'
                        disabled
                        style={{
                          width: '20%',
                          justifyContent: 'end',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default BundleBannerBtn
