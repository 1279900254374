import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
// import './purchasehistory.css'
import {useDispatch, useSelector} from 'react-redux'
import {PaymentHistoryAction} from '../../../../../../Redux/Action/authAction'

const AllPurchaseHistory = () => {
  const dispatch = useDispatch()
  // debugger
  const studentid = localStorage.getItem('userid')

  const mycoures = useSelector((state) => {
    return state.login.getStudentPaymentHistoryList
  })

  const payment = mycoures.combinedData || []

  useEffect(() => {
    dispatch(PaymentHistoryAction(studentid))
  }, [dispatch, studentid])

  return (
    <div>
      <div className='purchase-history'>
        <div className='card'>
          <div className='card-header card-header-stretch border-bottom border-gray-200'>
            <div className='card-title'>
              <h3 className='fw-bold m-0'>Purchase History</h3>
            </div>
          </div>

          <div className='tab-content'>
            <div
              id='kt_billing_months'
              className='card-body p-5 tab-pane fade show active'
              role='tabpanel'
              aria-labelledby='kt_billing_months'
            >
              <div className='table-responsive'>
                <table className='table table-row-bordered align-middle gy-4 gs-9'>
                  <thead className='border-bottom border-gray-200 fs-6 text-gray-600 fw-bold bg-light bg-opacity-75'>
                    <tr style={{background: '#264aca'}} className='text-white'>
                      <td className='min-w-250px'>Course</td>
                      <td className='min-w-150px'>Purchase Date</td>
                      <td className='min-w-150px'>Amount</td>
                      <td className='min-w-150px'>Expire On</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody className='fw-bold text-gray-900'>
                    {mycoures && Object.keys(payment).length > 0 ? (
                      <>
                        {payment.map((payment, index) => (
                          <tr>
                            {payment.type === 'bundle' && <td>{payment.details.Name}</td>}
                            {payment.type === 'course' && <td>{payment.details.title}</td>}

                            <td>
                              {new Date(payment.paymenttime)
                                .toLocaleDateString('en-GB', {
                                  day: '2-digit',
                                  month: '2-digit',
                                  year: 'numeric',
                                })
                                .split('/')
                                .join('/')}
                            </td>
                            <td style={{color: '#ff763f'}}>$ {payment.amountpaid}</td>
                            <td>
                              {new Date(payment.expiration_time)
                                .toLocaleDateString('en-GB', {
                                  day: '2-digit',
                                  month: '2-digit',
                                  year: 'numeric',
                                })
                                .split('/')
                                .join('/')}
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <div
                        className='fw-semibold text-gray-600 fs-2 mb-8 d-block'
                        style={{
                          paddingTop: '100px',
                          paddingBottom: '50px',
                          textAlign: 'center',
                        }}
                      >
                        No payment history found.
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AllPurchaseHistory
