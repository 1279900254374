import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {Button} from '@mui/material'

import {useEffect} from 'react'

import {Link, useNavigate} from 'react-router-dom'

import {useDispatch} from 'react-redux'
import FormData from 'form-data'
import {useForm} from 'react-hook-form'
import Loader from '../Loader/loader'

import {LoginandPayAction} from '../../Redux/Action/authAction'
function Loginandpay() {
  const dispatch = useDispatch()

  const navigate = useNavigate()
  const [formError, setFormError] = useState('')
  const [paymentLink, setPaymentLink] = useState('')
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const {
    register,
    handleSubmit,

    formState: {errors},
  } = useForm()

  useEffect(() => {
    if (paymentLink) {
      window.open(window.open(paymentLink))
    }
  })

  var newURL = window.location
  var splitURL = newURL.href.split('/')

  //const course_code = splitURL[4]
  const course_code = localStorage.getItem('course_code')
  const bundle_code = splitURL[4]
  // const coursecode = splitURL[4]

  const onSubmit = (data) => {
    const formData = new FormData()
    setLoading(true)
    formData.append('email', data.email)
    formData.append('password', data.password)

    if (course_code) {
      formData.append('paid_price', localStorage.getItem('paid_price'))
      formData.append('course_code', course_code)
    } else {
      formData.append('course_code', bundle_code)
      formData.append('paid_price', localStorage.getItem('price'))
    }

    localStorage.setItem('email', data.email)
    localStorage.setItem('password', data.password)

    // localStorage.setItem('course_code', course_code)
    // localStorage.setItem('course_code', bundle_code)
    dispatch(LoginandPayAction(formData, setLoading, navigate, setPaymentLink, setFormError))
  }

  const handleBuyCourse = (e) => {
    e.preventDefault();
    localStorage.getItem('paid_price')
  }

  const handleClick = () => {
    localStorage.removeItem('course_code')
  }

  return (
    <div>
      <div
        style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
      >
        <p style={{fontSize:'16px', marginBottom:'0'}}>
        Already have an account ? 
        <Link to={`/signup?/${course_code}`} onClick={() => setShow(true)}> 
          <span
            onClick={handleBuyCourse}
            style={{color: '#264ACA', fontSize:'16px'}}
          >  Click here to login.
          </span>
        </Link>
        </p>
      </div>

      <Modal show={show} onHide={() => setShow(false)} centered onClick={handleClick}>
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              textTransform: 'capitalize',
              letterSpacing: '.2rem',
              fontWeight: 500,
              fontSize: '1rem',
            }}
          >
            Login and Pay
          </Modal.Title>
        </Modal.Header>

        <Modal.Body props>
          {loading && (
            <div className='loader-container'>
              <Loader />
            </div>
          )}
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{padding: '2rem', borderRadius: '.375rem'}}
          >
            <div>
              <label>Email</label>
              <input
                className='form-control mb-4'
                type='text'
                name='email'
                {...register('email', {
                  required: 'This is required field',
                })}
                aria-invalid={errors.email ? 'true' : 'false'}
              />
              {errors.email && (
                <p className='alert_danger' role='alert' style={{marginTop: '1rem'}}>
                  {errors.email?.message}
                </p>
              )}
            </div>

            <div>
              <label>Password</label>
              <input
                className='form-control mb-4'
                type='password'
                name='password'
                {...register('password', {
                  required: 'This is required field',
                })}
                aria-invalid={errors.password ? 'true' : 'false'}
              />
              {errors.password && (
                <p className='alert_danger' role='alert' style={{marginTop: '1rem'}}>
                  {errors.password?.message}
                </p>
              )}
            </div>

            <Button type='submit' className=' btn cyberbtnallpage' disableRipple>
              <span style={{textTransform: 'capitalize'}}>Login and Pay</span>
            </Button>
            {formError && (
              <p className='alert_danger' role='alert'>
                {formError}
              </p>
            )}
          </form>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default Loginandpay
