import React, {useState, useEffect} from 'react'
import {getTopPurchasedCourses} from '../../../../../Redux/Action/admindashboardAction'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {FaAngleLeft, FaAngleRight} from 'react-icons/fa'

const AllPurchases = () => {
  const dispatch = useDispatch()

  const [selectRange, setSelectRange] = useState('ThisMonth')

  const topPurchasedCourses = useSelector((state) => {
    return state.login.getTopPurchasedCourseslist
  })

  const handleSelectChange = (event) => {
    //debugger
    const value = event.target.value
    setSelectRange(value)
    dispatch(getTopPurchasedCourses(value))
  }

  useEffect(() => {
    const defaultValue = 'ThisMonth'
    dispatch(getTopPurchasedCourses(defaultValue))
  }, [dispatch])

  const [currentPage, setCurrentPage] = useState(1)
  function nextPage() {
    if (currentPage !== nPage) {
      setCurrentPage(currentPage + 1)
    }
  }

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  function changeCPage(id) {
    setCurrentPage(id)
  }
  const topCourses = useSelector((state) => {
    return state.login.getTopPurchasedCourseslist
  })


  const recordsPerPage = 10

  
  let records = [];
  let nPage = 0;
  let number = [];
  let firstIndex
  let lastIndex
  if (topPurchasedCourses.salesData && topPurchasedCourses.salesData.length > 0) {
    lastIndex = currentPage * recordsPerPage;
    firstIndex = lastIndex - recordsPerPage;
  
    records = topPurchasedCourses.salesData.slice(firstIndex, lastIndex);
  
    nPage = Math.ceil(topPurchasedCourses.salesData.length / recordsPerPage);
    number = [...Array(nPage + 1).keys()].slice(1);
  }
  
  const recentSevendaysCoursesLength = topCourses?.length;
  return (
    <div>
      <div>
        <div>
          <div class='card card-flush h-xl-100'>
          <div className='card-header pt-7'>
          <h3 className='card-title align-items-start flex-column' style={{paddingBottom: '1rem'}}>
            <span className='card-label fw-bold text-gray-800'>Top Courses Purchased</span>
          </h3>

          <div
            className='card-toolbar'
            style={{border: '1px solid #ccc', borderRadius: '4px', padding: '10px'}}
          >
            <select
              value={selectRange}
              onChange={handleSelectChange}
              style={{
                border: 'none',
                outline: 'none',
                fontSize: '12px',
                backgroundColor: 'white',
                borderRadius: '4px',

                width: '100%',
              }}
            >
              <option>Select Date Range</option>
              <option value='Last24hrs'>Last 24 Hrs</option>
              <option value='Last7days'>Last 7 Days</option>
              <option value='ThisMonth'>This Month</option>
              <option value='LastMonth'>Last Month</option>
              <option value='LastSixMonth'>Last 6 Month</option>
              <option value='LastYear'>Last Year</option>
            </select>
          </div>
        </div>


            <div class='card-body pt-3 pb-4'>
              <div class='table-responsive'>
                <table class='table table-row-dashed align-middle gs-0 gy-4 my-0'>
                  <thead>
                    <tr class='fs-7 fw-bold text-gray-500 border-bottom-0'>
                      <th class='p-0 w-200px w-xxl-450px'>#</th>
                      <th class='p-0 w-200px w-xxl-450px'>Course Name</th>
                      <th class='p-0 min-w-150px'>No of Purchases</th>
                    </tr>
                  </thead>
                  {recentSevendaysCoursesLength === 0 ? (
                    <tbody class='text-gray-600 fw-semibold'>
                      <tr>
                        <td colSpan={4} className='text-center fw-700'>
                          No Record Found
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {records.map((topCourse, index) => {
                        const recordNumber = firstIndex + index + 1
                        return (
                          <tr>
                            <td>{recordNumber}</td>

                            <td>
                              <div class='d-flex align-items-center'>
                                <div class='d-flex justify-content-start flex-column'>
                                  {topCourse.title}
                                </div>
                              </div>
                            </td>
                            <td class='text-start'>
                              <span class='text-gray-800 fw-bold d-block mb-1 fs-6'>
                                {topCourse.purchase_count}
                              </span>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  )}
                </table>
                {recentSevendaysCoursesLength === 0 ? (
                  ''
                ) : (
                  <nav>
                    <ul className='pagination' style={{marginBottom: '20px'}}>
                      <li className='page-item'>
                        <Link to='#' className='page-link' onClick={prePage}>
                          <FaAngleLeft />
                        </Link>
                      </li>
                      {number.map((n, i) => (
                        <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                          <Link to='#' className='page-link' onClick={() => changeCPage(n)}>
                            {n}
                          </Link>
                        </li>
                      ))}
                      <li className='page-item'>
                        <Link to='#' className='page-link' onClick={nextPage}>
                          <FaAngleRight />
                        </Link>
                      </li>
                    </ul>
                  </nav>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AllPurchases
