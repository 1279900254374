import React, {useEffect, useState} from 'react'
import {Col, Container, Row} from 'react-bootstrap'

import './InsAllCourses.css'

import {Link, useNavigate} from 'react-router-dom'

import {GetallCourseAction, CheckusercourseAction} from '../../../Redux/Action/courseAction'

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'

import {useDispatch, useSelector} from 'react-redux'

const InsAllCourses = () => {
  const dispatch = useDispatch()
  const [message, setMessage] = useState('')
  const navigate = useNavigate()

  const Allcourse = useSelector((state) => {
    return state.login.getcourseslist
  })

  const allcourse = Allcourse.Published || []
  var newURL = window.location
  var splitURL = newURL.href.split('/')

  const titlename = splitURL[3]

  useEffect(() => {
    dispatch(GetallCourseAction())
  }, [dispatch])

  const chekcourses = useSelector((state) => {
    return state.login.checkcourseslist
  })

  useEffect(() => {
    dispatch(CheckusercourseAction())
  }, [dispatch])

  const learningprev = (props) => {
    dispatch(CheckusercourseAction(props, navigate, setMessage))
  }

  const Comptia = (props) => {
    dispatch(CheckusercourseAction(props, navigate, setMessage))
  }

  return (
    <>
      <div className='card card-flush' style={{margin: '0px'}}>
        <div class='card-header pt-5'>
          <h3 class='card-title align-items-start flex-column '>
            <span
              class='card-label fw-bold text-gray-800  border-gray-200 pb-1 col-md-12 m-0'
              style={{fontSize: '20px'}}
            >
              Courses
            </span>
            <div className='card-toolbar m-0'> </div>
          </h3>
        </div>
      </div>
      <div className='col-md-12' style={{paddingTop: '10px', margin: '0px'}}>
        <div className='card'>
          {allcourse.slice(0, 3).map((course, index) => {
            return (
              <div className='course-list-item  row   '>
                <div className='courseimg_list col-md-4'>
                  <img src={course.image_link} alt='img' style={{borderRadius: '1rem'}} />
                </div>
                <div className='course-list-info col-md-8'>
                  <h4
                    onClick={() => {
                      localStorage.setItem('paid_price', course.paid_price)
                      localStorage.setItem('category', course.category)

                      if (localStorage.getItem('userid') !== null) {
                        if (course.category === 'comptia') {
                          Comptia({
                            userId: localStorage.getItem('userid'),
                            coursecode: course.course_code,
                            coursid: course.courseslug,
                            category: course.category,
                          })
                        } else {
                          learningprev({
                            userId: localStorage.getItem('userid'),
                            coursecode: course.course_code,
                            coursid: course.courseslug,
                          })
                        }
                      } else {
                        navigate(`/learningprev?/${course.course_code}/${course.courseslug}`)
                      }
                    }}
                  >
                    {course.title}
                  </h4>
                  <div
                    className='course-list-info__meta pt-1'
                    style={{display: 'flex', gap: '1rem'}}
                  >
                    <span style={{display: 'flex', gap: '1rem'}}>
                      <CalendarMonthIcon style={{color: '#FF763F'}} />
                      <span className='text-gray-600'>
                        {' '}
                        Expire On: <span className='fw-bold'>{course.expiration_time}</span>
                      </span>
                    </span>
                  </div>
                  <div className='course-list-info__description text-gray-600 fw-bold  bg-opacity-75 pt-3'>
                    {course.description}
                  </div>
                  <div className='pt-4'>
                    <a
                      class='buttonlaunch button'
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        localStorage.setItem('paid_price', course.paid_price)
                        localStorage.setItem('category', course.category)

                        if (localStorage.getItem('userid') !== null) {
                          if (course.category === 'comptia') {
                            Comptia({
                              userId: localStorage.getItem('userid'),
                              coursecode: course.course_code,
                              coursid: course.courseslug,
                              category: course.category,
                            })
                          } else {
                            learningprev({
                              userId: localStorage.getItem('userid'),
                              coursecode: course.course_code,
                              coursid: course.courseslug,
                            })
                          }
                        } else {
                          navigate(`/learningprev?/${course.course_code}/${course.courseslug}`)
                        }
                      }}
                    >
                      View Course
                    </a>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default InsAllCourses
