import React from 'react'
import {Container, Tab, Row, Col, Nav} from 'react-bootstrap-v5'
import SetAvailability from '../SetAvailibility/SetAvailibility'
import InsCalander from './InsCalander'
import './instructorDashboardMain.css'
import MyShedule from './MyShedule'
import Deliveredclasses from './Deliveredclasses'

const InstructorDashboardMain = () => {
  return (
    <div>
      <div>
        <Tab.Container id='left-tabs-example' defaultActiveKey='training'>
          <Row>
            <Col md={12} style={{display: 'flex', justifyContent: 'center'}}>
              <Nav variant='pills'>
                <Nav.Item className='mb-5'>
                  <Nav.Link eventKey='training'>OVERVIEW</Nav.Link>
                </Nav.Item>
                {/* <Nav.Item className='mb-5'>
                  <Nav.Link eventKey='product'>MY CALANDER</Nav.Link>
                </Nav.Item> */}
                <Nav.Item className='my_calender mb-5'>
                  <Nav.Link eventKey='virtual'>MY CALENDAR</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>{' '}
          </Row>
          <Row>
            <Col md={12}>
              <Tab.Content>
                <Tab.Pane eventKey='training'>
                  <div className='card p-10'>
                    <MyShedule />
                  </div>
                  <div className='card p-10' style={{marginTop: '2rem'}}>
                    <Row>
                      <Deliveredclasses />
                    </Row>
                  </div>
                </Tab.Pane>

                {/* <Tab.Pane eventKey='product'>
                  <InsCalander />
                </Tab.Pane> */}
                <Tab.Pane eventKey='virtual'>
                  <div>
                    <div>
                      <SetAvailability />
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </div>
  )
}

export default InstructorDashboardMain
