import React, {useEffect, useState} from 'react'
import {
  GetAllCountriesAction,
  GetStateByCountryAction,
  GetCityByStateAction,
} from '../../../../../Redux/Action/locationAction'
import {GetallteacherCourseAction} from '../../../../../Redux/Action/courseAction'
import {
  UpdateInstructorByidAction,
  GetInstructorCoursesDetails,
  GetInstructorbyId,
} from '../../../../../Redux/Action/instructorAction'
import {useForm} from 'react-hook-form'
import {useNavigate, Link} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import TimezoneSelect from 'react-timezone-select'
const InstructorEdit = () => {
  const instid = localStorage.getItem('userid')
  const [file, setFile] = useState(null)
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: {errors},
    setValue,
    control,
    getValues,
  } = useForm({})

  const instructorCoursesdetails = useSelector((state) => {
    return state.login.getaInstructorCourseslist
  })

  useEffect(() => {
    // debugger;
    dispatch(GetInstructorCoursesDetails(instid))
  }, [dispatch])

  const instructordetails = useSelector((state) => {
    return state.login.getaInstructorIdlist
  })

  useEffect(() => {
    // debugger;
    dispatch(GetInstructorbyId(instid))
  }, [dispatch])

  const [selectedCountryISOCode, setSelectedCountryISOCode] = useState(
    instructordetails.message && instructordetails.message.Country
  )
  const [selectedStateISOCode, setSelectedStateISOCode] = useState(
    instructordetails.message && instructordetails.message.state
  )
  const [selectedCity, setSelectedCity] = useState(
    instructordetails.message && instructordetails.message.City
  )
  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  )
  useEffect(() => {
    setValue('FirstName', instructordetails.message && instructordetails.message.FirstName)
    setValue('Surname', instructordetails.message && instructordetails.message.Surname)
    setValue('PhoneNumber', instructordetails.message && instructordetails.message.PhoneNumber)
    setValue('Country', instructordetails.message && instructordetails.message.Country)
    setValue('state', instructordetails.message && instructordetails.message.state)
    setValue('City', instructordetails.message && instructordetails.message.City)

    setValue('mtm_id', instructordetails.message && instructordetails.message.mtm_id)
    setValue('comptia_id', instructordetails.message && instructordetails.message.comptia_id)
    setValue('other_id', instructordetails.message && instructordetails.message.other_id)
    setValue('qualification', instructordetails.message && instructordetails.message.qualification)
    setValue('experiance', instructordetails.message && instructordetails.message.experiance)
    setValue('IBANNumber', instructordetails.message && instructordetails.message.IBANNumber)
    setValue('AccountName', instructordetails.message && instructordetails.message.AccountName)
    setValue('AccountNumber', instructordetails.message && instructordetails.message.AccountNumber)
    setValue('profileUrl', instructordetails.message && instructordetails.message.profileUrl)
    setValue('dayRate', instructordetails.message && instructordetails.message.dayRate)
    setValue('companyName', instructordetails.message && instructordetails.message.Company_Name)
    setSelectedTimezone(
      instructordetails.message?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
    )
    if (instructorCoursesdetails.data) {
      const formattedData = instructorCoursesdetails.data.reduce(
        (acc, course) => {
          acc.courses[course.course_id.toString()] = course.title
          return acc
        },
        {courses: {}}
      )

      setSelectedCheckboxes(formattedData)
    }
  }, [instructordetails])

  const allcourse = useSelector((state) => {
    return state.login.getteachercourseslist
  })

  const handleCancelClick = () => {
    // Perform your desired action here
    window.location.reload() // Reload the current page
  }

  useEffect(() => {
    dispatch(GetallteacherCourseAction())
  }, [dispatch])

  const onSubmit = (data) => {
    // data.preventDefault()
    const formData = new FormData()
    if (data.profileUrl[0]) {
      formData.append('profileUrl', data.profileUrl[0])
    }
    formData.append('FirstName', data.FirstName)
    formData.append('Surname', data.Surname)

    formData.append('PhoneNumber', data.PhoneNumber)
    formData.append('Country', data.Country)
    formData.append('state', selectedStateISOCode)
    formData.append('City', data.City)
    formData.append('timezone', formattedTimezone || selectedTimezone)
    formData.append('mtm_id', data.mtm_id)
    formData.append('comptia_id', data.comptia_id)
    formData.append('other_id', data.other_id)
    formData.append('qualification', data.qualification)
    formData.append('experiance', data.experiance)
    formData.append('courses', data.courses)
    formData.append('IBANNumber', data.IBANNumber)
    formData.append('AccountName', data.AccountName)
    formData.append('AccountNumber', data.AccountNumber)
    formData.append('dayRate', data.dayRate)
    formData.append('companyName', data.companyName)
    formData.append('selectedCourses', JSON.stringify(selectedCheckboxes))

    dispatch(UpdateInstructorByidAction(formData, instid, navigate))
  }

  useEffect(() => {
    setSelectedTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)
  }, [])

  const formattedTimezone = selectedTimezone?.value || ''

  const allCountries = useSelector((state) => {
    return state.login.getAllCountriesList
  })

  useEffect(() => {
    dispatch(GetAllCountriesAction())
  }, [dispatch])

  const state = useSelector((state) => {
    return state.login.getStateByCountrList
  })
  useEffect(() => {
    if (selectedCountryISOCode) {
      dispatch(GetStateByCountryAction(selectedCountryISOCode))
    }
  }, [dispatch, selectedCountryISOCode])

  useEffect(() => {
    dispatch(GetStateByCountryAction(selectedCountryISOCode))
  }, [dispatch, selectedCountryISOCode])

  const city = useSelector((state) => {
    return state.login.getCityByStateList
  })
  useEffect(() => {
    if (selectedCountryISOCode && selectedStateISOCode) {
      dispatch(GetCityByStateAction(selectedCountryISOCode, selectedStateISOCode))
    }
  }, [dispatch, selectedCountryISOCode, selectedStateISOCode])
  useEffect(() => {
    dispatch(GetCityByStateAction(selectedCountryISOCode, selectedStateISOCode))
  }, [dispatch, selectedCountryISOCode, selectedStateISOCode])

  const handleChange = (e) => {
    const selectedValue = e.target.value
    setSelectedCountryISOCode(selectedValue)
  }

  const handleStateChange = (e) => {
    const selectedState = e.target.value
    setSelectedStateISOCode(selectedState)
  }

  useEffect(() => {
    if (instructordetails.message) {
      setSelectedCountryISOCode(instructordetails.message.Country)
      setSelectedStateISOCode(instructordetails.message.state)
    }
  }, [instructordetails.message])

  useEffect(() => {
    if (instructordetails.message) {
      setSelectedCity(instructordetails.message && instructordetails.message.City)
    }
  }, [instructordetails.message])

  useEffect(() => {
    setValue('City', selectedCity)
  }, [selectedCity, setValue])

  const handleCityChange = (event, formData) => {
    const selectedCityValue = event.target.value
    setSelectedCity(selectedCityValue)
    //formData.set('City', selectedCityValue)
  }

  const [selectedCheckboxes, setSelectedCheckboxes] = useState({
    courses: {},
  })

  const handleCheckboxChange = (id, value) => {
    setSelectedCheckboxes((prevSelected) => {
      const updatedCourseIds = {...prevSelected.courses}

      if (value.checked) {
        updatedCourseIds[value.id] = value.title
      } else {
        delete updatedCourseIds[value.id]
      }

      return {
        ...prevSelected,
        courses: updatedCourseIds,
      }
    })
  }

  return (
    <div
      className='user-editor'
      style={{
        marginTop: '47px',
      }}
    >
      <div className='card mb-5 mb-xl-10 shadow-none'>
        <form id='kt_account_profile_details_form' className='form'>
          <div className='card-body border-top p-9'>
            <div className='d-flex align-items-center fs-4 fw-bold mb-5'> Contact Details</div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-semibold fs-6'>Avatar</label>

              <div className='col-lg-8'>
                {/* Display current profile picture */}
                {instructordetails.message?.profileUrl && (
                  <img
                    src={instructordetails.message.profileUrl}
                    alt='Current Profile'
                    style={{maxWidth: '100px'}}
                  />
                )}

                {/* Input for selecting a new profile picture */}
                <input
                  type='file'
                  className='form-control mt-10 w-50'
                  {...register('profileUrl')}
                  onChange={(e) => {
                    const selectedFile = e.target.files[0]
                    setFile(URL.createObjectURL(selectedFile))
                  }}
                />
                {file && <img src={file} alt='Selected' style={{maxWidth: '100px'}} />}
                <div className='form-text'>Allowed file types: png, jpg, jpeg.</div>
                {errors.profileUrl && <p style={{color: 'red'}}>{errors.profileUrl.message}</p>}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-semibold fs-6'>Full Name</label>

              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      name='fname'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='First name'
                      {...register('FirstName', {
                        required: 'This  is required field',
                      })}
                      aria-invalid={errors.FirstName ? 'true' : 'false'}
                    />
                    {errors.FirstName && (
                      <p
                        className='alert_danger'
                        role='alert'
                        style={{marginTop: '1rem', color: 'crimson'}}
                      >
                        {errors.FirstName?.message}
                      </p>
                    )}
                  </div>

                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      name='fname'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='First name'
                      {...register('Surname', {
                        required: 'This  is required field',
                      })}
                      aria-invalid={errors.Surname ? 'true' : 'false'}
                    />
                    {errors.Surname && (
                      <p
                        className='alert_danger'
                        role='alert'
                        style={{marginTop: '1rem', color: 'crimson'}}
                      >
                        {errors.Surname?.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                <span className='required'>Telephone</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='tel'
                  name='phone'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Phone number'
                  {...register('PhoneNumber', {
                    required: 'This  is required field',
                  })}
                  aria-invalid={errors.PhoneNumber ? 'true' : 'false'}
                />
                {errors.PhoneNumber && (
                  <p
                    className='alert_danger'
                    role='alert'
                    style={{marginTop: '1rem', color: 'crimson'}}
                  >
                    {errors.PhoneNumber?.message}
                  </p>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                <span className='required'>Timezone</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <TimezoneSelect value={selectedTimezone} onChange={setSelectedTimezone} />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                <span className='required'>Country</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  name='country'
                  aria-label='Select a Country'
                  data-control='select2'
                  data-placeholder='Select a country...'
                  className='form-select form-select-solid form-select-lg fw-semibold'
                  {...register('Country', {
                    required: 'This  is required field',
                  })}
                  aria-invalid={errors.Country ? 'true' : 'false'}
                  value={selectedCountryISOCode}
                  onChange={handleChange}
                >
                  {errors.Country && (
                    <p className='alert_danger' role='alert'>
                      {errors.Country?.message}
                    </p>
                  )}
                  <option value=''>Select a Country...</option>
                  {Object.values(allCountries).map((countryArr) =>
                    countryArr.map((country, i) => (
                      <option key={i} value={country.isoCode}>
                        {country.name}
                      </option>
                    ))
                  )}
                </select>
              </div>
            </div>
            {state.count > 0 && (
              <>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                    <span className='required'>State/County</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <select
                      name='country'
                      aria-label='Select Country'
                      data-control='select2'
                      data-placeholder='Select a country...'
                      className='form-select form-select-solid form-select-lg fw-semibold'
                      aria-invalid={errors.Country ? 'true' : 'false'}
                      value={selectedStateISOCode}
                      onChange={handleStateChange}
                    >
                      <option value=''>Select State...</option>
                      {state.states.map((item) => (
                        <option key={item.isoCode} value={item.isoCode}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                    <span>City</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <select
                      name='City'
                      aria-label='Select a City'
                      data-control='select2'
                      data-placeholder='Select a City...'
                      className='form-select form-select-solid form-select-lg fw-semibold'
                      {...register('City')}
                      aria-invalid={errors.City ? 'true' : 'false'}
                      value={selectedCity}
                      onChange={(e) => handleCityChange(e)}
                    >
                      <option value=''>Select a City...</option>
                      {city.message &&
                        city.message.map((city, index) => (
                          <option key={index} value={city.name}>
                            {city.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </>
            )}

            <div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-semibold fs-6 required'>MTM ID</label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    name='mtmId'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='MTM ID'
                    {...register('mtm_id', {
                      required: 'This  is required field',
                    })}
                    aria-invalid={errors.mtm_id ? 'true' : 'false'}
                  />
                  {errors.mtm_id && (
                    <p
                      className='alert_danger'
                      role='alert'
                      style={{marginTop: '1rem', color: 'crimson'}}
                    >
                      {errors.mtm_id?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-semibold fs-6 required'>
                  CompTIA ID
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    name='comptiaId'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='CompTIA ID'
                    {...register('comptia_id', {
                      required: 'This  is required field',
                    })}
                    aria-invalid={errors.comptia_id ? 'true' : 'false'}
                  />
                  {errors.comptia_id && (
                    <p
                      className='alert_danger'
                      role='alert'
                      style={{marginTop: '1rem', color: 'crimson'}}
                    >
                      {errors.comptia_id?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-semibold fs-6 required'>
                  Other IDs
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    name='otherIds'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Other IDs'
                    {...register('other_id', {
                      required: 'This  is required field',
                    })}
                    aria-invalid={errors.other_id ? 'true' : 'false'}
                  />
                  {errors.other_id && (
                    <p
                      className='alert_danger'
                      role='alert'
                      style={{marginTop: '1rem', color: 'crimson'}}
                    >
                      {errors.other_id?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                  <span className='required'>Day Rate(£)</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    name='phone'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Bank account'
                    {...register('dayRate', {
                      required: 'This  is required field',
                    })}
                    aria-invalid={errors.dayRate ? 'true' : 'false'}
                  />
                  {errors.dayRate && (
                    <p
                      className='alert_danger'
                      role='alert'
                      style={{marginTop: '1rem', color: 'crimson'}}
                    >
                      {errors.dayRate?.message}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <hr className='solid' />
            <div>
              <div className='d-flex align-items-center fs-4 fw-bold mb-5'> Teaching Details</div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                  <span className='required'>Qualifications / Accreditation</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    name='phone'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Qualifications'
                    {...register('qualification', {
                      required: 'This  is required field',
                    })}
                    aria-invalid={errors.qualification ? 'true' : 'false'}
                  />
                  {errors.qualification && (
                    <p
                      className='alert_danger'
                      role='alert'
                      style={{marginTop: '1rem', color: 'crimson'}}
                    >
                      {errors.qualification?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                  <span className='required'>Years of teaching experience</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <select
                    name='experience'
                    aria-label='Select a experience'
                    data-control='select2'
                    data-placeholder='Select a experience...'
                    className='form-select form-select-solid form-select-lg fw-semibold'
                    {...register('experiance', {
                      required: 'This  is required field',
                    })}
                    aria-invalid={errors.experiance ? 'true' : 'false'}
                  >
                    {errors.experiance && (
                      <p
                        className='alert_danger'
                        role='alert'
                        style={{marginTop: '1rem', color: 'crimson'}}
                      >
                        {errors.experiance?.message}
                      </p>
                    )}
                    <option value=''>Select a experience...</option>
                    <option value='1'>1 Year</option>
                    <option value='2-5'>2-5 Years</option>
                    <option value='5+'>5+ Years</option>
                  </select>
                  {errors.experiance && (
                    <p
                      className='alert_danger'
                      role='alert'
                      style={{marginTop: '1rem', color: 'crimson'}}
                    >
                      {errors.experiance?.message}
                    </p>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label  fw-semibold fs-6'>
                  <span className='required'>Teaching Courses</span>
                  <div>
                    <span style={{fontSize: '10px'}}>
                      Please confirm the courses you are accredited to teach:
                      <br />
                    </span>
                  </div>
                </label>

                <div className='col-lg-8 fv-row'>
                  <ul>
                    {allcourse.map((item, index) => (
                      <li
                        style={{margin: 0, padding: 0}}
                        className='form-check'
                        key={`bundle-${index}`}
                      >
                        <input
                          type='checkbox'
                          className='form-check-input'
                          id={`checkboxBundle-${index}`}
                          value={`${item.id}-${item.title}`}
                          checked={selectedCheckboxes.courses[item.id] !== undefined}
                          onChange={() =>
                            handleCheckboxChange(`bundle${item.id}`, {
                              ...item,
                              checked: !selectedCheckboxes.courses[item.id],
                            })
                          }
                        />
                        <label className='form-label mt-0 p-1' htmlFor={`checkboxBundle-${index}`}>
                          {item.title}
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <hr className='solid' />
            <div>
              <div className='d-flex align-items-center fs-4 fw-bold mb-5'> Bank Details</div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                  <span>Company/Account Name</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    name='companyName'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Company Name'
                    {...register('companyName')}
                    aria-invalid={errors.companyName ? 'true' : 'false'}
                  />
                  {errors.companyName && (
                    <p className='alert_danger' role='alert'>
                      {errors.companyName?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                  <span className='required'>Bank account / IBAN numbers</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    name='phone'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Bank account'
                    {...register('IBANNumber', {
                      required: 'This  is required field',
                    })}
                    aria-invalid={errors.IBANNumber ? 'true' : 'false'}
                  />
                  {errors.IBANNumber && (
                    <p className='alert_danger' role='alert'>
                      {errors.IBANNumber?.message}
                    </p>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                  <span className='required'>Account Name</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    name='phone'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Account Name'
                    {...register('AccountName', {
                      required: 'This  is required field',
                    })}
                    aria-invalid={errors.AccountName ? 'true' : 'false'}
                  />
                  {errors.AccountName && (
                    <p className='alert_danger' role='alert'>
                      {errors.AccountName?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                  <span className='required'>Account Number / IBAN</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    name='phone'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Account Number'
                    {...register('AccountNumber', {
                      required: 'This  is required field',
                    })}
                    aria-invalid={errors.AccountNumber ? 'true' : 'false'}
                  />
                  {errors.AccountNumber && (
                    <p className='alert_danger' role='alert'>
                      {errors.AccountNumber?.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <Link to=''>
              <button
                id='kt_password_cancel'
                type='button'
                className='btn cybercancelbtn me-2'
                style={{
                  backgroundColor: 'white',
                }}
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </Link>
            <button
              type='submit'
              className='btn cyberbtnallpage'
              id='kt_account_profile_details_submit'
              onClick={handleSubmit(onSubmit)}
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default InstructorEdit
