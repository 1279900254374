import React, {useEffect, useState} from 'react'

import {useDispatch, useSelector} from 'react-redux'

import {GetUserById} from '../../../../../../Redux/Action/profileAction'
import {
  GetInstructorCoursesDetails,
  GetInstructorbyId,
} from '../../../../../../Redux/Action/instructorAction'
import * as CountryStateCity from 'country-state-city'
const ProfileDetails = () => {
  const dispatch = useDispatch()
  const roleid = localStorage.getItem('roleid')
  const studentid = localStorage.getItem('userid')
  const instid = localStorage.getItem('userid')
  const userdetails = useSelector((state) => {
    return state.login.getuserIdlist
  })

  useEffect(() => {
    dispatch(GetUserById(studentid))
  }, [dispatch, studentid])

  const instructordetails = useSelector((state) => {
    return state.login.getaInstructorIdlist
  })
  useEffect(() => {
    // debugger;
    dispatch(GetInstructorbyId(instid))
  }, [dispatch, instid])

  const instructorCoursesdetails = useSelector((state) => {
    return state.login.getaInstructorCourseslist
  })

  useEffect(() => {
    // debugger;
    dispatch(GetInstructorCoursesDetails(instid))
  }, [dispatch, instid])

  let vdata = ''

  const [countryName, setCountryName] = useState('')
  const [stateName, setStateName] = useState('')
  useEffect(() => {
    if (userdetails && userdetails.message && userdetails.message.Country) {
      const country = CountryStateCity.Country.getCountryByCode(userdetails.message.Country)
      const state = CountryStateCity.State.getStateByCodeAndCountry(
        userdetails.message.state,
        userdetails.message.Country
      )

      const fetchedCountryName = country ? country.name : userdetails.message.Country || ''
      const fetchedStateName = state ? state.name : userdetails.message.state || ''

      setCountryName(fetchedCountryName)
      setStateName(fetchedStateName)
    } else {
      setCountryName('NA')
      setStateName('NA')
    }
  }, [userdetails])

  return (
    <div>
      <div className='card mb shadow-none' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bold m-0'>Profile Details</h3>
          </div>
        </div>
        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-semibold text-muted fs-6'>Full Name</label>
            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6 text-gray-800'>
                {userdetails.message &&
                  userdetails.message.FirstName + ' ' + userdetails.message.Surname}
              </span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-semibold text-muted fs-6'>Telephone</label>
            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bold fs-6 text-gray-800'>
                {' '}
                {userdetails.message && userdetails.message.PhoneNumber}
              </span>
              {/* <span className="badge badge-success">Verified</span> */}
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-semibold text-muted fs-6'>Email</label>
            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6 text-gray-800'>
                {' '}
                {userdetails.message && userdetails.message.Email}
              </span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-semibold text-muted fs-6'>Country</label>
            <div className='col-lg-8'>
              <span className='fw-bold fs-6 text-gray-800'> {countryName}</span>
            </div>
          </div>

          {stateName && stateName != 'undefined' && stateName != null && stateName != 'null' ? (
            <div className='row mb-7'>
              <label className='col-lg-4 fw-semibold text-muted fs-6'>State/County</label>
              <div className='col-lg-8'>
                <span className='fw-bold fs-6 text-gray-800'>{stateName}</span>
              </div>
            </div>
          ) : (
            <div></div>
          )}
          {userdetails.message &&
          userdetails.message.City &&
          userdetails.message.City != 'undefined' &&
          userdetails.message.City != 'null' &&
          userdetails.message.City != null ? (
            <div className='row mb-7'>
              <label className='col-lg-4 fw-semibold text-muted fs-6'>City</label>
              <div className='col-lg-8'>
                <span className='fw-bold fs-6 text-gray-800'>
                  {' '}
                  {userdetails.message && userdetails.message.City}
                </span>
              </div>
            </div>
          ) : (
            <div></div>
          )}
          <div className='row mb-7'>
            <label className='col-lg-4 fw-semibold text-muted fs-6'>Timezone</label>
            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6 text-gray-800'>
                {' '}
                {userdetails.message && userdetails.message.timezone}
              </span>
            </div>
          </div>
          {roleid === '3' && (
            <>
              {/* <div className='row mb-7'>
            <label className='col-lg-4 fw-semibold text-muted fs-6'>Timezone</label>
            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6 text-gray-800'>
                {' '}
                {userdetails.message && userdetails.message.timezone}
              </span>
            </div>
          </div> */}
              <div className='row mb-7'>
                <label className='col-lg-4 fw-semibold text-muted fs-6'>Age</label>
                <div className='col-lg-8 fv-row'>
                  <span className='fw-bold fs-6 text-gray-800'>
                    {' '}
                    {userdetails.message && userdetails.message.AgeBracket}
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-semibold text-muted fs-6'>
                  Years Of CyberSecurity Experience
                </label>
                <div className='col-lg-8 fv-row'>
                  <span className='fw-bold fs-6 text-gray-800'>
                    {' '}
                    {userdetails.message && userdetails.message.YearsOfCyberSecurityExperience}
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-semibold text-muted fs-6'>Education Level</label>
                <div className='col-lg-8 fv-row'>
                  <span className='fw-bold fs-6 text-gray-800'>
                    {' '}
                    {userdetails.message && userdetails.message.EducationLevel}
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-semibold text-muted fs-6'>
                  Purpose Of Taking The Course
                </label>
                <div className='col-lg-8 fv-row'>
                  <span className='fw-bold fs-6 text-gray-800'>
                    {' '}
                    {userdetails.message && userdetails.message.PurposeOfTakingTheCourse}
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-semibold text-muted fs-6'>
                  Interested In Cyberhead Role
                </label>
                <div className='col-lg-8 fv-row'>
                  <span className='fw-bold fs-6 text-gray-800'>
                    {' '}
                    {userdetails.message && userdetails.message.InterestedInCyberheadRole}
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-semibold text-muted fs-6'>Employment Status</label>
                <div className='col-lg-8 fv-row'>
                  <span className='fw-bold fs-6 text-gray-800'>
                    {' '}
                    {userdetails.message && userdetails.message.EmploymentStatus}
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-semibold text-muted fs-6'>How You Got To Know</label>
                <div className='col-lg-8 fv-row'>
                  <span className='fw-bold fs-6 text-gray-800'>
                    {' '}
                    {userdetails.message && userdetails.message.HowYouGotToKnow}
                  </span>
                </div>
              </div>
            </>
          )}
          {roleid === '4' && (
            <>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-semibold text-muted fs-6'> MTM ID</label>
                <div className='col-lg-8'>
                  <span className='fw-bold fs-6 text-gray-800'>
                    {' '}
                    {instructordetails.message && instructordetails.message.mtm_id
                      ? instructordetails.message.mtm_id
                      : 'NA'}
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-semibold text-muted fs-6'>CompTIA ID</label>
                <div className='col-lg-8'>
                  <span className='fw-bold fs-6 text-gray-800'>
                    {' '}
                    {instructordetails.message && instructordetails.message.comptia_id
                      ? instructordetails.message.comptia_id
                      : 'NA'}
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-semibold text-muted fs-6'>Other IDs</label>
                <div className='col-lg-8'>
                  <span className='fw-bold fs-6 text-gray-800'>
                    {' '}
                    {instructordetails.message && instructordetails.message.other_id
                      ? instructordetails.message.other_id
                      : 'NA'}
                  </span>
                </div>
              </div>
              <div className='d-flex align-items-center fs-4 fw-bold mb-5'> Teaching Details</div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-semibold text-muted fs-6'>
                  Qualifications / Accreditation
                </label>
                <div className='col-lg-8'>
                  <span className='fw-bold fs-6 text-gray-800'>
                    {' '}
                    {instructordetails.message && instructordetails.message.qualification
                      ? instructordetails.message.qualification
                      : 'NA'}
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-semibold text-muted fs-6'>
                  Years of teaching experience
                </label>

                <div className='col-lg-8'>
                  <span className='fw-semibold text-gray-800 fs-6'>
                    {instructordetails.message && instructordetails.message.experiance
                      ? instructordetails.message.experiance == 1
                        ? `${instructordetails.message.experiance} year`
                        : `${instructordetails.message.experiance} years`
                      : 'NA'}
                  </span>
                </div>
              </div>
              {Object.entries(instructorCoursesdetails).map(([key, course], i) => {
                if (key === 'data') {
                  vdata = course
                }
              })}{' '}
              <div className='row mb-7'>
                <label className='col-lg-4 fw-semibold text-muted fs-6'>Teaching Courses</label>
                <div className='col-lg-8'>
                  <ol>
                    {Object.entries(vdata).map(([key, list], i) => {
                      return (
                        <li key={`bundle-${key}`} className='fw-bold fs-6 text-gray-800'>
                          {list.title}
                        </li>
                      )
                    })}
                  </ol>
                </div>
              </div>
              <div className='d-flex align-items-center fs-4 fw-bold mb-5'>Bank Details</div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-semibold text-muted fs-6'>Company Name</label>
                <div className='col-lg-8 fv-row'>
                  <span className='fw-bold fs-6 text-gray-800'>
                    {instructordetails.message && instructordetails.message.Company_Name
                      ? instructordetails.message.Company_Name
                      : 'NA'}
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-semibold text-muted fs-6'>
                  Bank account / IBAN numbers
                </label>
                <div className='col-lg-8'>
                  <span className='fw-bold fs-6 text-gray-800'>
                    {' '}
                    {instructordetails.message && instructordetails.message.IBANNumber
                      ? instructordetails.message.IBANNumber
                      : 'NA'}{' '}
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-semibold text-muted fs-6'>Account Name</label>
                <div className='col-lg-8'>
                  <span className='fw-bold fs-6 text-gray-800'>
                    {' '}
                    {instructordetails.message && instructordetails.message.AccountName
                      ? instructordetails.message.AccountName
                      : 'NA'}{' '}
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-semibold text-muted fs-6'>
                  Account Number / IBAN
                </label>
                <div className='col-lg-8'>
                  <span className='fw-bold fs-6 text-gray-800'>
                    {' '}
                    {instructordetails.message && instructordetails.message.AccountNumber
                      ? instructordetails.message.AccountNumber
                      : 'NA'}{' '}
                  </span>
                </div>
              </div>{' '}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProfileDetails
